import React from "react";
import {Loader, Modal} from "rsuite";

interface IProps {
    isDownloading: boolean
    title: string
}

export default function LoadingModal({ isDownloading, title }: IProps) {

    const titleToShow = title + ' file, please wait...';
    return (
        <Modal
            data-testid='div-report-generator-downloading-modal'
            show={isDownloading}
            onHide={() => {}}
            size="xs"
        >
            <Modal.Body className="flex justify-center ">
                <Loader
                    content={titleToShow}
                    vertical
                    size="md"
                />
            </Modal.Body>
        </Modal>
    )
}