import Whisper from 'rsuite/lib/Whisper'
import Popover from 'rsuite/lib/Popover/Popover'
import Dropdown from 'rsuite/lib/Dropdown'
import { ReactNode } from 'react'
import React from 'react'

type WhisperProps = {
	children: ReactNode
	dropdownItems: ReactNode[]
}

let tableBody: HTMLElement

export const CustomWhisper = ({ children, dropdownItems }: WhisperProps) => {
	let trigger: any = null
	const handleSelectMenu = (eventKey: any, event: any) => {
		trigger.hide()
	}

	return (
		<Whisper
			placement='topEnd'
			trigger='click'
			triggerRef={(ref) => {
				trigger = ref
			}}
			container={() => {
				return tableBody
			}}
			speaker={
				<Popover full>
					<Dropdown.Menu onSelect={handleSelectMenu}>
						{dropdownItems}
					</Dropdown.Menu>
				</Popover>
			}
		>
			{children}
		</Whisper>
	)
}
