export enum typeOneSections {
  'philanthropic' = 'philanthropic',
  'influencers' = 'influencers'
}

export const TWITTER_PREFIX = 'twitter.com/'
export const WIKIPEDIA_PREFIX = 'en.wikipedia.org/wiki/'
export const AIGeneratorBoundaryValues = {
  minValue: 0,
  maxValue: 10
}
export const QUERY_TYPE_SECTION = {
  philanthropicDescriptionText: `Please enter any philanthropic or charitable organizations that are relevant to your brand. Ex: Red Cross Society, Wounded Warrior Project, etc.`,
  influencersDescriptionText: `Please enter any celebrities, influencers, and activists who are
	relevant to your brand.`,
  philanthropicHeaderText: 'Philanthropic Organizations',
  influencersHeaderText: 'Influencers'
}

export const CHILD_STEPS = {
  brands: 'brands',
  influencers: 'influencers',
  events: 'events',
  social: 'social',
  philanthropic: 'philanthropic'
}

export const BRAND_PROFILE_RELATIONSHIP_LOOKUP = {
  TARGET: 'I want to associate with',
  MONITOR: 'I want to monitor / gather intelligence on',
  BLOCK: 'I want to avoid'
}

export const EVENT_SECTION = {
	headerText: 'Events',
	descriptionText: `Please enter any events which are relevant to your brand. Ex: NBA
	All-star Game, New York Fashion Week, etc.`
}

export const SOCIAL_SECTION = {
	headerText: 'Social Causes',
	descriptionText: "Please share the social or environmental relevant to your brand.\
	 Feel free to share any causes you'd like to even steer clear of. If you don't spot \
	 a cause you care about in our list, feel free to share more about it in the optional fields."
}