import React, { useEffect, useState } from 'react'
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

import { BoardsFilter } from '@/views/Discover/Moments/v2/TargetMoment/BoardsFilter'
import SearchBox from '@/components/SearchBox'
import { TargetviewIo } from '@/classes/brandProfile'
import Tooltip from '@/components/TailwindTooltip'
import BulkAssignIOPersona from '@/views/Discover/Moments/v2/TargetMoment/BulkAssignIOPersona'
import { IoOptionsType } from '@/views/Discover/Moments/v2/TargetMoment/index'

interface IProps {
  boardsOptions: ListedBoardsType
  ioOptions: IoOptionsType[]
  handleFilterBoards: (filteredBoardIds: number[]) => void
  handleSearchMoments: (searchKey: string) => void
  selectedMomentsCount: number
  handleApplyBulkPersonas: (ioToPersonas: Map<number, Set<string>>) => void
  setDeleteBulkMoments: React.Dispatch<React.SetStateAction<boolean>>
}

function ToolBar({
  boardsOptions,
  ioOptions,
  handleFilterBoards,
  handleSearchMoments,
  selectedMomentsCount,
  handleApplyBulkPersonas,
  setDeleteBulkMoments,
}: IProps) {

  const [searchKey, setSearchKey] = useState("")

  useEffect(() => {
    handleSearchMoments(searchKey);
  }, [searchKey])

  const [showBulkAssignmentModal, setShowBulkAssignmentModal] = React.useState<boolean>(false)

  return (
    <section
      className="flex flex-col gap-4"
      data-testid="act-mgr-target-moment-btn-panel"
    >
      <div className="flex justify-between">
        <div className="pl-6 w-36" id='pendo-act-mgr-boards-filter'>
          <BoardsFilter
            boardsOptions={boardsOptions}
            handleFilterBoards={handleFilterBoards}
          />
        </div>
        <div className="pr-6 w-[35%]" id='pendo-act-mgr-search-moments'>
          <SearchBox
            handleSearch={(searchKey) => setSearchKey(searchKey)}
            placeholder="Search Moments"
          />
        </div>
      </div>
      {selectedMomentsCount > 0 && (
        <div className="flex justify-between mx-6 rounded-lg bg-gray-50">
          <div className="flex justify-start gap-2">
            <p
              data-testid="act-mgr-target-moment-selected-target-count"
              className="p-4 pr-0 m-auto"
            >
              <strong>{selectedMomentsCount}</strong> selected
            </p>
            <div className="px-2 py-4">
              <Button
                dataTestId="act-mgr-target-moment-edit-assignments-btn"
                icon={<PencilSquareIcon className="w-6 h-w-6" />}
                label="Edit Assignments"
                disabled={selectedMomentsCount <= 0}
                handleClick={() => {
                  setShowBulkAssignmentModal(true)
                }}
              />
            </div>
          </div>
          <div className="px-2 py-4">
            <Button
              dataTestId="act-mgr-target-moment-bulk-delete-btn"
              icon={<TrashIcon className="w-6 h-w-6" />}
              handleClick={() => setDeleteBulkMoments(true)}
              disabled={selectedMomentsCount <= 0}
              label="Delete Moments"
            />
          </div>
        </div>
      )}

      <BulkAssignIOPersona
        handleApply={handleApplyBulkPersonas}
        options={ioOptions}
        show={showBulkAssignmentModal}
        setShow={setShowBulkAssignmentModal}
      />
    </section>
  )
}

const ButtonStyleWrapper = (props: { children: React.ReactNode; className?: string; handleClick: () => void }) => (
  <div
    onClick={props.handleClick}
    className={`p-2 h-[38px] flex-nowrap rounded-lg flex justify-evenly gap-2 bg-white
    items-center border-gray-300 border ${props.className}`}
  >
    {props.children}
  </div>
)

// hiding as it's a placeholder for layout future implementations
export const Button = (props: {
  label: string
  icon: React.ReactNode
  handleClick: () => void
  disabled?: boolean
  dataTestId?: string
}) => (
  <Tooltip
    disabled={!props.disabled}
    content={
      <div
        data-testid="act-mgr-target-bulk-warning-tooltip"
        className="text-left"
      >
        Select moments for bulk operation.
      </div>
    }
  >
    <div data-testid={props.dataTestId || ''} id={`pendo-${props.dataTestId || 'button'}`}>
      <ButtonStyleWrapper
        handleClick={() => {
          !props.disabled && props.handleClick()
        }}
        className={`${props.disabled ? 'cursor-not-allowed opacity-50 ' : 'cursor-pointer hover:shadow-md'}`}
      >
        {props.icon}
        <p className="text-sm align-baseline">{props.label}</p>
      </ButtonStyleWrapper>
    </div>
  </Tooltip>
)

export type ListedBoardsType = Array<{ boardId: number; boardName: string }>

export default ToolBar