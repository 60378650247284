import React, { ReactNode } from 'react';
import { Dropdown, Popover, Whisper } from 'rsuite';
import { useNavigate } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import useUser from '@/hooks/useUser';
import { PATH_MOMENTS_V2, PATH_MOMENTS_V2_AI_EDIT, PATH_MOMENTS_V2_EDIT_CUSTOM } from '@/routes';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';



type BoardItemMoreOptionsProps = {
    children: ReactNode
    boardId: number
    handleDelete: () => void
    handleRename: () => void
    brandProfileId: number | undefined | null
    boardType: string
}

let tableBody: HTMLElement

export const BoardItemMoreOptions = ({ children, handleDelete, handleRename, boardId, brandProfileId, boardType }: BoardItemMoreOptionsProps) => {
    let trigger: any = null
    const navigate = useNavigate<MyLocationGenerics>()
    const handleSelectMenu = (eventKey: any, event: any) => {
        trigger.hide()
    }

    const { currentAccount, products, perms, userPermissions, userProducts } = useUser()
    
    const currentAccountId = currentAccount?.accountId

    const handleOpen = () => {
        navigate({
            to: PATH_MOMENTS_V2,
            search: (old) => ({
                ...old,
                boardIds: [boardId],
                brandProfileId: old?.brandProfileId,
                accountId: currentAccountId,
                timeRange: 1,
                searchTerm: '',
                sortBy: 'ranking',
                boardsView: 'My Boards'
            })
        })
    }

    const handleEdit = (boardType: string) => {
        if(boardType === 'naturalLanguage') {
            navigate({
              to: `${PATH_MOMENTS_V2_AI_EDIT}/${boardId}`,
              search: (search: any) => ({
                ...search,
                boardId: boardId,
                editingBoard: true
              })
            })
          } else {
            navigate({
              to: `${PATH_MOMENTS_V2_EDIT_CUSTOM}/${boardId}`
            })
          }
    }

    return (
        <Whisper
            placement="topEnd"
            trigger="click"
            triggerRef={(ref) => {
                trigger = ref
            }}
            container={() => {
                return tableBody
            }}
            speaker={
                <Popover full>
                    <Dropdown.Menu data-testid={'card-more-menu'} onSelect={handleSelectMenu}>
                        {
                            (boardType === 'custom' || boardType === 'naturalLanguage') && userCanPermissionProductQuota({
                                requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                                requiredProductValue: products.BOARDS,
                                userPermissions: userPermissions,
                                userProducts: userProducts,
                                checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                              }) &&
                            <Dropdown.Item data-testid={'card-more-edit'} onClick={() => handleEdit(boardType)}>Edit board</Dropdown.Item>
                        }
                        {
                            userCanPermissionProductQuota({
                                requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                                requiredProductValue: products.BOARDS,
                                userPermissions: userPermissions,
                                userProducts: userProducts,
                                checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                              }) &&
                            <Dropdown.Item data-testid={'card-more-rename'} onClick={handleRename}>Rename board</Dropdown.Item>
                        }
                        {
                            userCanPermissionProductQuota({
                                requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                                requiredProductValue: products.BOARDS,
                                userPermissions: userPermissions,
                                userProducts: userProducts,
                                checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                              }) &&
                            <Dropdown.Item data-testid={'card-more-delete'} onClick={handleDelete}>Delete board</Dropdown.Item>
                        }
                        <Dropdown.Item data-testid={'card-more-open'} onClick={handleOpen}>View</Dropdown.Item>
                    </Dropdown.Menu>
                </Popover>
            }
        >
            {children}
        </Whisper>
    )
}


