import React from 'react'
import { useQuery } from '@tanstack/react-query'
import InputGroup from 'rsuite/lib/InputGroup'
import AutoComplete from 'rsuite/lib/AutoComplete'
import toast from 'react-hot-toast'
import { joinClasses } from '@/utils'
import { userAccountAxios } from '@/axiosInstances'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { FormError } from "@/components/Sightly/SightlyFormElements/SightlyFormError";
import { SightlyModernFormError } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormError'

interface IProps {
    name: string
    disabled?: boolean
    handleSetWikiUrl: any
    handleSetName: any
    nameValue: string
    error?: string
    dataTestId?: string
    handleFieldEmpty?: any
    textSize?: string
    shiftText?: boolean
}

const NameSearchField = ({ name, handleSetWikiUrl, handleSetName, nameValue, error, dataTestId, disabled, handleFieldEmpty, textSize, shiftText }: IProps) => {
    const [wikiSearchTerm, setWikiSearchTerm] = React.useState('')

    type WikiResult = {
        name: string
        wikiUrl: string
    }

    const getWikiOptions = async (wikiSearchTerm: string): Promise<WikiResult[]> => {
        const url = `/wiki-search?searchTerm=${wikiSearchTerm}`
        const res = await userAccountAxios.get<WikiResult[]>(url)
        return res.data
    }

    const wikiObj = useQuery(rqKeys.wikiOptions(wikiSearchTerm), () => getWikiOptions(wikiSearchTerm), {
        enabled: wikiSearchTerm.length > 0
    })

    const handleNameSelect = (name: string) => {
        if (!wikiObj || !wikiObj.data) return
        try {
            const wikiUrl = wikiObj.data.filter(item => item.name === name)[0].wikiUrl
            const sanitizedWikiUrl = decodeURIComponent(wikiUrl).replace('https://en.wikipedia.org/wiki/', '')
            handleSetWikiUrl(sanitizedWikiUrl)
        } catch (err) {
            console.error(err)
            toast.error('An unexpected error occurred. Please try again')
        }
    }

    const handleNameChange = (val: string) => {
        setWikiSearchTerm(val)
        handleSetName(val)
    }

    return (
        <div
            data-testid={dataTestId}
            className='relative w-full'
            style={shiftText ? { marginTop: '5px' } : {}}
        >
            <div className={joinClasses('input-label', 'required')} style={{fontSize: textSize || '16px'}}>{name}</div>
            <InputGroup style={{ marginBottom: 8 }}>
                <AutoComplete
                    value={nameValue}
                    placeholder="Type Here"
                    disabled={disabled}
                    onChange={val => handleNameChange(val)}
                    onSelect={(val: any) => handleNameSelect(val.value)}
                    data={wikiObj.data?.map(item => item.name)}
                />
            </InputGroup>
            <SightlyModernFormError
                key={name}
                id={name}
                error={error ? error : undefined}
              />
        </div>
    )
}

export default NameSearchField
