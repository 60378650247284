import React from 'react';

import MetabaseIFrame from "@/views/Discover/MetabaseIFrame";
import useUser from "@/hooks/useUser";
import useEmbedUrls from "@/hooks/useEmbedUrls";

export const SocialTopics = () => {
  const { currentAccount } = useUser();
  const { data: embedUrls } = useEmbedUrls(currentAccount?.accountId);


  return (
      <MetabaseIFrame
        id="social-topics-iframe"
        title="Social Topics"
        sourceUrl={embedUrls?.metabaseSocialDataEmbedUrl}
      />
  )
}
