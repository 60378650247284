import React from 'react'
import ButtonToolbar from 'rsuite/lib/ButtonToolbar'
import Loader from 'rsuite/lib/Loader'
import { connect } from 'react-redux'
import { targetTypes, objectives } from './constants'
import { routes } from '../../../routes'
import Input from 'rsuite/lib/Input'
import debounce from 'just-debounce-it'
import SelectPicker from 'rsuite/lib/SelectPicker'
import { postListService } from '../../../services/lists'
import useBrandProfiles from '../../../hooks/brandProfile/useBrandProfiles'
import SightlyButton from '../../../components/Sightly/SightlyButton'
import PortalModal from '../../../components/PortalModal/PortalModal'
import Icon from 'rsuite/lib/Icon'
import { useMatch, useNavigate } from '@tanstack/react-location'
import useUser from '../../../hooks/useUser'

const contentTypes = [
    { label: 'Videos', value: 'videos' },
    { label: 'Channels', value: 'channels' }
]

const mapStateToProps = (state) => {
    return {
        smartListVersionUnderEdit: state.engage.smartListVersionUnderEdit
    }
}

function CreateNewListModal(props) {
    const navigate = useNavigate()

    const { currentAccount } = useUser()
    const currentAccountId = currentAccount?.accountId

    const { data: brandProfiles, isLoading: brandProfilesIsLoading } = useBrandProfiles({
        submittedOnly: true
    })

    const [posting, setPosting] = React.useState(false)

    const handleCreateClick = async () => {
        setPosting(true)
        let list = {
            smartListName: formObject.smartListName,
            objectiveId: formObject.objectiveId,
            targetTypeId: formObject.targetTypeId,
            isVideo: formObject.contentType === 'videos' ? true : false
        }

        let data = {
            list,
            brandProfileId: formObject.brandProfileId,
            accountId: currentAccountId
        }

        const res = await postListService(data)

        setPosting(false)

        if (res.isVideo) {
            navigate({ to: `/app/engage/lists/videoListBuilder/${res.versionId}/edit` })
        } else {
            navigate({ to: `/app/engage/lists/channelListBuilder/${res.versionId}/edit` })
        }
    }

    const handleCancelClick = () => {
        navigate({ to: routes.app.engage.lists.lists.path })
    }

    const [formObject, setFormObject] = React.useState({
        objectiveId: objectives[0].objectiveId,
        targetTypeId: targetTypes[3].targetTypeId,
        brandProfileId: null,
        smartListName: '',
        contentType: 'videos'
    })

    const isClean = React.useMemo(() => {
        if (formObject.smartListName.length < 1) return false
        if (!formObject.brandProfileId) return false
        if (formObject.brandProfileId.length < 1) return false
        return true
    }, [formObject])

    React.useEffect(() => {
        if (brandProfiles?.length > 0) {
            handleChangeExecute('brandProfileId', brandProfiles[0].brandProfileId)
        }
    }, [brandProfiles])

    const handleChangeExecute = (property, value) => {
        switch (property) {
            case 'smartListName':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        smartListName: value
                    }
                    return newState
                })
                break

            case 'objectiveId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        objectiveId: value
                    }
                    return newState
                })
                break

            case 'targetTypeId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        targetTypeId: value
                    }
                    return newState
                })
                break

            case 'brandProfileId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        brandProfileId: value
                    }
                    return newState
                })
                break

            case 'contentType':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        contentType: value
                    }
                    return newState
                })
                break

            default:
                break
        }
    }

    const handleChange = debounce((property, value) => {
        handleChangeExecute(property, value)
    }, 500)

    if (brandProfilesIsLoading) {
        return (
            <Loader
                vertical
                center
            />
        )
    }

    return (
        <div
            style={{
                width: 500,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                paddingTop: 48
            }}
        >
            <PortalModal
                footerButtons={[]}
                handleClose={() => {}}
                open={posting}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                    }}
                >
                    <h3 style={{ marginBottom: 24, textAlign: 'center' }}>This could take up to a minute</h3>
                    <div style={{ width: 350, marginBottom: 24 }}>
                        We are combing through hundreds of millions of videos to create your custom SmartList.
                    </div>

                    <Icon
                        icon="spinner"
                        pulse
                        size="4x"
                    />
                </div>
            </PortalModal>
            <div>
                <div className="isRequired">SmartList Name</div>
                <Input onChange={(val) => handleChange('smartListName', val)} />
            </div>
            <div>
                <div>Objective</div>
                <SelectPicker
                    preventOverflow
                    labelKey={'objectiveName'}
                    valueKey={'objectiveId'}
                    placeholder={'Select'}
                    data={objectives}
                    defaultValue={formObject.objectiveId}
                    onChange={(val) => {
                        handleChange('objectiveId', val)
                    }}
                    cleanable={false}
                    block
                    searchable={false}
                />
            </div>
            <div>
                <div>Target Type</div>
                <SelectPicker
                    preventOverflow
                    labelKey={'targetTypeName'}
                    valueKey={'targetTypeId'}
                    placeholder={'Select'}
                    data={targetTypes}
                    defaultValue={formObject.targetTypeId}
                    onChange={(val) => {
                        handleChange('targetTypeId', val)
                    }}
                    cleanable={false}
                    block
                    searchable={false}
                />
            </div>
            <div>
                <div className="isRequired">Brand Profile</div>
                <SelectPicker
                    preventOverflow
                    key={formObject.brandProfileId}
                    labelKey={'brandProfileName'}
                    valueKey={'brandProfileId'}
                    placeholder={'Select'}
                    defaultValue={formObject.brandProfileId}
                    data={brandProfiles}
                    onChange={(val) => {
                        handleChange('brandProfileId', val)
                    }}
                    cleanable={false}
                    block
                    searchable={true}
                />
            </div>
            <div>
                <div>Content Type</div>
                <SelectPicker
                    labelKey={'label'}
                    valueKey={'value'}
                    placeholder={'Select'}
                    defaultValue={formObject.contentType}
                    data={contentTypes}
                    onChange={(val) => {
                        handleChange('contentType', val)
                    }}
                    cleanable={false}
                    block
                    searchable={false}
                />
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
                <SightlyButton
                    disabled={posting || !isClean}
                    handleClick={handleCreateClick}
                    loading={posting}
                    text={`Let's go!`}
                />

                <SightlyButton
                    type="cancel"
                    handleClick={handleCancelClick}
                    text={`Cancel`}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(CreateNewListModal)
