// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 8px;
    height: 40px;
}

.tags-input:focus {
    border: 1px solid #0052cc;
}

.myInput {
    border: none !important;
    border-color: transparent !important;
}

.myInput:focus {
    outline: transparent;
}

.tag-close-icon {
    display: block;
    width: 14px;
    height: 14px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Sightly/sightlyTagInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".tags-input {\n    display: flex;\n    align-items: flex-start;\n    flex-wrap: wrap;\n    padding: 0 8px;\n    height: 40px;\n}\n\n.tags-input:focus {\n    border: 1px solid #0052cc;\n}\n\n.myInput {\n    border: none !important;\n    border-color: transparent !important;\n}\n\n.myInput:focus {\n    outline: transparent;\n}\n\n.tag-close-icon {\n    display: block;\n    width: 14px;\n    height: 14px;\n    line-height: 16px;\n    text-align: center;\n    font-size: 14px;\n    margin-left: 8px;\n    color: #0052cc;\n    border-radius: 50%;\n    background: #fff;\n    cursor: pointer;\n}\n\n@media screen and (max-width: 567px) {\n    .tags-input {\n        width: calc(100vw - 32px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
