import React from "react";
import { Input } from "rsuite";

export default function ReportNameSection({ handleOnChange }) {
    return (
      <div data-testid='div-report-generator-report-name' className="w-5/12 space-y-1">
        <label
          htmlFor="report-name-input"
          className="text-gray-700 font-bold m-1"
        >
          Report Name
        </label>
        <Input
          aria-labelledby="report-name-input"
          onChange={(val) => handleOnChange('reportName', val)}
          placeholder="Enter Report Name"
          disabled={false}
        />
      </div>
    )
}
