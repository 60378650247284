import React, { useEffect, useState } from 'react'
import { Table, Popover, Dropdown, IconButton, Icon } from 'rsuite';
const { HeaderCell, Cell } = Table;
import {getCalendarDate, TableColumnJs} from '@/utils';
import Whisper from "rsuite/lib/Whisper";
import { accentColor } from '@/assets/jss/colorConstants';
import { useQuery } from "@tanstack/react-query";
import { activationReportController } from '@/views/ActivationReports/controller/ActivationReportController';
// import TablePagination from 'rsuite/lib/Table/TablePagination';
import { TablePagination } from './TablePagination';
import { InformationModal } from './InformationModal';
import LoadingModal from './LoadingModal';
import { useNavigate } from '@tanstack/react-location';
import { routes } from '@/routes';
import { ActivationReportResult } from '@/views/ActivationReports/schemas/activation-report.schema'
import {
    CONVERSION,
    GENERIC_DIMENSION,
    GRANULARITY,
    GROUPING,
    METRIC,
    REPORT_TYPE,
    TEMPLATE_TYPE
} from '@/views/ActivationReports/types/activation-report.types';
import { Notification } from 'rsuite';

interface IProps {
    tableData: any,
    accountId: number
}

const tableHeaderStyle = {
    fontWeight: 600,
    fontSize: '16px',
    color: '#000000',
    backgroundColor: '#FAFAFA'
}
const tableStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px 4px 0 0'
}

export const ReportViewTable = ({ accountId }: IProps) => {
    const limit = 10;

    const [page, setPage] = useState(1);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [loadingTitleModal, setLoadingTitleModal] = useState('');
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const navigate = useNavigate();


    const { data: queryData, refetch } = useQuery(
        ['getReportConfigurations', accountId, page, limit],
        () => activationReportController.getReportConfigurations({ queryKey: ['getReportConfigurations', accountId, page, limit] }),
        {
            keepPreviousData: true,
        }
    );

    const [tableData, setTableData] = useState(queryData);

    useEffect(() => {

        setTableData(queryData);

    }, [queryData]);

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages);
        setIsErrorModalVisible(true);
    }


    const handleDownload = async (rowData: ActivationReportResult) => {

        const dataToDownload = {
            name: rowData.configuration.name,
            insertionOrders: rowData.configuration.insertionOrders,
            reports: rowData.configuration.reports.map(report => ({
                ...report,
                type: report.type as REPORT_TYPE,
                templateType: report.templateType as TEMPLATE_TYPE,
                columns: report.columns as (GENERIC_DIMENSION | METRIC | CONVERSION)[],
              })),
            granularity: rowData.configuration.granularity as GRANULARITY,
            grouping: rowData.configuration.grouping as GROUPING,
            startDate: new Date(rowData.configuration.startDate) ,
            endDate:  new Date(rowData.configuration.endDate)
        }

        try {
            setLoadingTitleModal('Downloading')
            setIsDownloading(true)

            const response = await activationReportController.downloadReport(dataToDownload);

            if (response!.status == 200) {
                showErrorModal( 'Success',['Report downloaded successfully'])
            } else {
                showErrorModal( 'Error',['Error while downloading the report.'])
            }

        } catch (error) {
            showErrorModal( 'Error', ['Error while downloading the report.'])
        } finally {
            setIsDownloading(false)
        }

    }

    const handleDelete = async (rowData: any) => {
        try {
            setLoadingTitleModal('Deleting')
            setIsDownloading(true)

            const result = await activationReportController.handleDeleteReport(rowData['id']);

            if (result) {
                Notification.open({
                    title: 'Success',
                    description: <div>The report was successfully deleted</div>
                });

                const updatedTableData = tableData?.results.filter((row: any) => row.id !== rowData['id']);

                if (updatedTableData?.length === 0 && page > 1) {

                    if (tableData && tableData.totalCount !== undefined) {
                        tableData.totalCount = tableData.totalCount - 1;
                      }

                    setPage(prevPage => Math.max(prevPage - 1, 1));

                } else {

                    await refetch();
                }

            } else {
                showErrorModal('Error', ['Error while deleting the report.'])
            }

        } catch (error) {
            showErrorModal('Error', ['Error while deleting the report.'])
            console.log(error);

        } finally {
            setIsDownloading(false)
        }
    }

    const handleCopyEdit = async (rowData: any, copyFunction: boolean) => {

        navigate({
            to: routes.app.activationReport.new.path,
            search: (prev) => ({
                ...prev,
                reportId: rowData['id'],
                copy: copyFunction
            })
        });
    }

    return (
        <div>
            <Table minHeight={500} data={tableData?.results ?? []} style={tableStyle} autoHeight={true} data-testid="activation-reports-table-view">
                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="activation-reports-table-view-name-header">Report Name</HeaderCell>
                    <Cell data-testid="activation-reports-table-view-name">
                        {(rowData: any) => {
                            return (
                                rowData.configuration.name
                            );
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="activation-reports-table-view-date-header">Last Modified Date</HeaderCell>
                    <Cell data-testid="activation-reports-table-view-date">
                        {(rowData: any) => {
                            return <p data-testid={`activations-activations-results-table-date-column-${rowData.activationId}`}>{getCalendarDate(rowData.updatedAt)}</p>
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="activation-reports-table-view-modified-header">Last Modified By</HeaderCell>
                    <Cell data-testid="activation-reports-table-view-modified">
                        {(rowData: any) => {
                            return (
                                rowData.updatedBy.email
                            );
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="activation-reports-table-view-created-header">Created by</HeaderCell>
                    <Cell data-testid="activation-reports-table-view-created">
                        {(rowData: any) => {
                            return (
                                rowData.createdBy.email
                            );
                        }}
                    </Cell>
                </TableColumnJs>
                <TableColumnJs width={75}>
                    <HeaderCell style={tableHeaderStyle} data-testid="activation-reports-table-view-header"></HeaderCell>
                    <Cell data-testid="activation-reports-table-view-actions">
                        {(rowData: ActivationReportResult , rowIndex: number) => {
                            return (
                                <div
                                    data-testid={`div-report-generator-actions-dropdown-${rowIndex}`}
                                    className="more"
                                >
                                    <Whisper
                                    data-testid="activation-reports-table-view-whisper"
                                        placement="topEnd"
                                        trigger="click"
                                        speaker={
                                            <Popover full>
                                                <Dropdown.Menu >
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-download-button'
                                                        onClick={() => handleDownload(rowData)}>
                                                        Download
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-download-button'
                                                        onClick={() => handleCopyEdit(rowData, false)}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-download-button'
                                                        onClick={() => handleCopyEdit(rowData, true)}>
                                                        Copy
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        data-testid='div-report-generator-delete-button'
                                                        onClick={() => handleDelete(rowData)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Popover>
                                        }
                                    >
                                        <IconButton
                                            data-testid={`activation-reports-table-view-whisper-more-${rowIndex}`}
                                            size="lg"
                                            appearance="default"
                                            icon={
                                                <Icon
                                                    icon="more"
                                                    color={accentColor}
                                                />
                                            }
                                        />
                                    </Whisper>
                                </div>
                            );
                        }}
                    </Cell>
                </TableColumnJs>
            </Table>
            <div className='mt-2 w-full flex justify-end'>
                <TablePagination
                    activePage={page}
                    total={tableData?.totalCount ?? 0}
                    onChangePage={setPage}
                    limit={limit}
                />
            </div>
            <InformationModal title={titleInfomationModal} errorMessages={errorMessages} isErrorModalVisible={isErrorModalVisible} setIsErrorModalVisible={setIsErrorModalVisible} />
            <LoadingModal isDownloading={isDownloading} title={loadingTitleModal} />
        </div>
    );
}
