const constants =  {
  PAGE_TITLE : 'Media Responsibility',
  AD_FONTES_TEXT: 'powered by',
  PAGE_DESCRIPTION: 'Establish clear policies that prohibit the display of content that is politically biased or unreliable.',
  RELIABILITY_SECTION_CAPTION:'Reliability Preference',
  RELIABILITY_DESCRIPTION:'These options allow you to choose the level of reliability you want to \
  surround your content around.',
  POLITICAL_BIAS_SECTION_CAPTION: 'Political Bias',
  POLITICAL_BIAS_DESCRIPTION:'These options allow you to choose the level of bias you \
  want to surround your content around. You can either avoid all biased \
  content on both the left and right or only avoid biased content from \
  one side, depending on your preferences.',
  POLITICAL_BIAS_SCALE_CAPTION:'Choose your Political Bias Scale',
  DEFAULT_RELIABILITY_PREFERENCE:[1],
  DEFAULT_POLITICAL_BIAS_PREFERENCE:[3,4,5],

}
export default constants