import { useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { userAccountAxios } from '@/axiosInstances'
import { rqKeys } from './queries-rqKeys'
import { logError } from '@/utils'
import useUser from '@/hooks/useUser'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { products } from '@/staticData/products'
import { AxiosResponse } from 'axios'
import { QueryTypeOneAIGenerationType } from './QueryTypeOneAIGenerationForm'
import { BRAND_PROFILE_RELATIONSHIP_LOOKUP } from './constants'

const generateQueryTypeOne = async (args: { brandProfileId: number; payload: QueryTypeOneAIGenerationType }) => {
  const url = `/brand-profile/${args.brandProfileId}/${args.payload.queryType}/ai-generator`
  const { queryType, ...payload } = args.payload
  return await userAccountAxios.post(url, payload)
}

const useQueryTypeOne = (brandProfileId: number, queryType: string) => {
  const queryClient = useQueryClient()
  const { currentAccount } = useUser()

  const postProcess = () => {
    queryClient.invalidateQueries({ queryKey: rqKeys.brandProfileQuery(queryType, Number(brandProfileId)) })
    queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
    queryClient.fetchQuery(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
    queryClient.invalidateQueries(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.AI_CREDITS))
  }

  const handleSuccess = async (response: AxiosResponse<any>) => {
    if (response.data && response.data.failure.length > 0) {
      toast.error(`Error occured for ${response.data.failure.length} item/s`)
      return
    }
    toast.success(`${queryType.charAt(0).toUpperCase() + queryType.slice(1)}  Response(s) Generated`)
    postProcess()
  }

  const handleError = (error: unknown) => {
    toast.error('Something went wrong. Please try again later.')
    logError(error)
    postProcess()
  }
  const queryTypeOneMutation = useMutation(generateQueryTypeOne, {
    mutationKey: rqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
    onSuccess: async (response) => {
      await handleSuccess(response)
    },
    onError: (error) => {
      handleError(error)
    }
  })

  const isMutatingQueryType = useIsMutating({
    mutationKey: rqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
  })

  return {
    isMutatingQueryType,
    queryTypeOneMutation,
    handleError
  }
}

export default useQueryTypeOne
