import { SerializableMap } from '@/utils/classes/SerializableMap'
import { TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'

export const selectedActivationReducer = (
  selectedActivations: Map<string, Set<number>>,
  action: SelectedActivationsActionType
) => {
  switch (action.type) {
    case SELECTED_ACTIVATIONS_ACTIONS.SELECT_ACTIVATION: {
      const { persona, activation, checked } = action.payload
      const next = new Map(selectedActivations)
      if (checked) {
        const existingActivationGroup = next.get(persona)
        if (existingActivationGroup) {
          existingActivationGroup.add(activation.activationItemId)
          next.set(persona, existingActivationGroup)
        } else {
          next.set(persona, new Set([activation.activationItemId]))
        }
      } else {
        next.get(persona)?.delete(activation.activationItemId)
        if (!next.get(persona)?.size) {
          next.delete(persona)
        }
      }
      return next
    }

    case SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL: {
      const nextState = new Map(selectedActivations)
      const { checked, targetedActivationList, personaFilters, searchedMomentsIds } = action.payload
      if (checked) {
        targetedActivationList.forEach((activations, persona) => {
          if (
            (!personaFilters.length || personaFilters.includes(persona)) &&
            hasSearchedActivations(activations, searchedMomentsIds)
          ) {
            nextState.set(persona, getSelectableActivations(activations, searchedMomentsIds))
          }
        })
        return nextState
      }
      return new Map<string, Set<number>>()
    }

    case SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL_PERSONA_GROUP: {
      const nextState = new Map(selectedActivations)
      const { checked, searchedMomentsIds, activations, persona } = action.payload
      if (checked) {
        nextState.set(persona, getSelectableActivations(activations, searchedMomentsIds))
      } else nextState.delete(persona)
      return nextState
    }

    case SELECTED_ACTIVATIONS_ACTIONS.REMOVE_PERSONA: {
      const nextState = new Map(selectedActivations)
      const { persona } = action.payload
      nextState.delete(persona)
      return nextState
    }

    case SELECTED_ACTIVATIONS_ACTIONS.REMOVE_ACTIVATION: {
      const nextState = new Map(selectedActivations)
      const { activation } = action.payload
      const [persona, currentActivationItemId] = activation
      const prevActivationGroup = nextState.get(persona)
      if (prevActivationGroup) {
        const nextActivationGroup = new Set(prevActivationGroup)
        nextActivationGroup.delete(currentActivationItemId)
        if (!nextActivationGroup.size) nextState.delete(persona)
        else nextState.set(persona, nextActivationGroup)
      }
      return nextState
    }

    case SELECTED_ACTIVATIONS_ACTIONS.RESET: {
      return new Map<string, Set<number>>()
    }

    default: {
      return selectedActivations
    }
  }
}

export enum SELECTED_ACTIVATIONS_ACTIONS {
  SELECT_ACTIVATION = 'SELECT_ACTIVATION',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_ALL_PERSONA_GROUP = 'SELECT_ALL_PERSONA_GROUP',
  REMOVE_PERSONA = 'REMOVE_PERSONA',
  REMOVE_ACTIVATION = 'REMOVE_ACTIVATION',
  RESET = 'RESET'
}

type SelectActivationPayload = {
  checked: boolean
  persona: string
  activation: TargetedActivationListType
}

type SelectAllPayload = {
  checked: boolean
  searchedMomentsIds: number[]
  personaFilters: string[]
  targetedActivationList: SerializableMap<string, TargetedActivationListType[]>
}

type SelectAllPersonaGroupPayload = {
  checked: boolean
  persona: string
  activations: Array<TargetedActivationListType>
  searchedMomentsIds: number[]
}

type RemovePersonaPayload = {
  persona: string
}

type RemoveActivationPayload = {
  activation: [string, number]
}

export type SelectedActivationsActionType =
  | { type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ACTIVATION; payload: SelectActivationPayload }
  | { type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL; payload: SelectAllPayload }
  | { type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL_PERSONA_GROUP; payload: SelectAllPersonaGroupPayload }
  | { type: SELECTED_ACTIVATIONS_ACTIONS.REMOVE_PERSONA; payload: RemovePersonaPayload }
  | { type: SELECTED_ACTIVATIONS_ACTIONS.REMOVE_ACTIVATION; payload: RemoveActivationPayload }
  | { type: SELECTED_ACTIVATIONS_ACTIONS.RESET }

const getSelectableActivations = (activations: TargetedActivationListType[], searchedMomentsIds: number[]) => {
  const selectableActivations = new Set<number>()
  activations.forEach(({ activationItemId }) => {
    if (!searchedMomentsIds.length || searchedMomentsIds.includes(activationItemId))
      selectableActivations.add(activationItemId)
  })
  return selectableActivations
}

const hasSearchedActivations = (activations: TargetedActivationListType[], searchedMomentsIds: number[]) =>
  !searchedMomentsIds.length ||
  activations.some(({ activationItemId }) => searchedMomentsIds.includes(activationItemId))
