import React from 'react';

type PanelProps = {
  headerText: string
  subHeaderText: string
  children?: React.ReactElement
  dataTestId?: string
  className?: string
}

export const Panel = ({ headerText, subHeaderText, children, dataTestId, className }: PanelProps) => {
  return (
    <div data-testid={dataTestId} className={className}>
      <div className="border-b p-6">
        <h5>{headerText}</h5>
        <p className="text-gray-500">
          {subHeaderText}
        </p>
      </div>

      <div className="p-6">
        {children}
      </div>
    </div>
  )
}
