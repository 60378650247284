import React from 'react'
import { Button, Modal } from 'rsuite'

/**
 * Modal component for bulk assignment.
 * @param {Object} props - Component props.
 * @param {boolean} props.show - Flag to control the visibility of the modal.
 * @param {() => void} props.handleApply - Function called when the apply button is clicked.
 * @param {() => void} props.handleCancel - Function called when the cancel button is clicked.
 * @param {React.ReactNode} props.header - React node representing the header of the modal.
 * @param {React.ReactNode} props.children - React node representing the content of the modal.
 * @param {string} [props.dataTestId] - Test ID for the modal component.
 * @param {'sm' | 'md' | 'lg' | 'xs'} props.size - Size of the modal ('sm' for small, 'md' for medium, 'lg' for large, 'xs' for extra small).
 * @returns {JSX.Element} The rendered component.
 */
const BulkAssignModal = (props: {
  show: boolean
  handleApply: () => void
  handleCancel: () => void
  header: React.ReactNode
  children: React.ReactNode
  dataTestId?: string
  size: 'sm' | 'md' | 'lg' | 'xs'
}) => {
  const { show, handleApply, handleCancel, children, header, dataTestId, size } = props
  return (
    <div data-testid={dataTestId} id={dataTestId}>
      <Modal
        size={size}
        show={show}
        onHide={handleCancel}
        onExit={handleCancel}
        backdrop={'static'}
        className="top-[20vh]"
      >
        <Modal.Header className='-mx-5 !pr-0'>{header}</Modal.Header>
        <Modal.Body className='!mt-0 !pb-0 !overflow-hidden'>{children}</Modal.Body>

        <Modal.Footer>
          <Footer
            handleApply={handleApply}
            handleCancel={handleCancel}
          />
          </Modal.Footer>
      </Modal>
    </div>
  )
}

const Footer = (props: { handleApply: () => void; handleCancel: () => void }) => (
  <footer className="flex justify-end gap-6 p-4 -mx-5 -mb-5 rounded-b-lg bg-gray-50">
    <div data-testid="act-mgr-target-bulk-assign-cancel-btn" id="pendo-act-mgr-target-bulk-assign-cancel-btn" > 
      <Button
        onClick={props.handleCancel}
        appearance="subtle"
        className="!text-gray-900"
      >
        Cancel
      </Button>
    </div>
    <div data-testid="act-mgr-target-bulk-assign-apply-btn" id="pendo-act-mgr-target-bulk-assign-apply-btn">
      <Button
        onClick={props.handleApply}
        appearance='subtle'
        className="!text-sightlyBlue"
      >
        Apply
      </Button>
    </div>
  </footer>
)

export default BulkAssignModal
