import { PATH_MOMENTS_V2_CREATE_CUSTOM } from '@/routes'
import React, { useState } from 'react'
import { Icon, Panel } from 'rsuite'
import { MyLocationGenerics } from '@/classes/utils';
import { useNavigate } from '@tanstack/react-location';

export type IProps = {
    boardMaxOut?: boolean
}

export const NewCustomBoardCard = ({
    boardMaxOut = false,
}: IProps) => {

    const navigate = useNavigate<MyLocationGenerics>()

    const [showContent, setShowContent] = useState(false);

    const handleMouseEnter = () => {
        setShowContent(true);
    };

    const handleMouseLeave = () => {
        setShowContent(false);
    };
    
    return (
        <div
            className={`relative h-[323px] ${boardMaxOut && showContent ? '' : 'border-[#7B61FF] border-2 cursor-pointer'}  rounded-md`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id="new-custom-board"
            data-testid={"new_custom_board"}
            onClick={() => {
                    if(!boardMaxOut){
                        navigate({
                            to: PATH_MOMENTS_V2_CREATE_CUSTOM,
                            search: (old) => {
                                return { ...old }
                            }
                        })
                    }
                }
            }
        >
            <Panel
                className={`inline-block w-full h-[320px] content-center text-center ${boardMaxOut && showContent ? 'blur-lg' : ''}`}
                shaded
                bodyFill
            >
                <Icon
                    size='3x'
                    icon="plus"
                    color={"#333D47"} />

                <h1 className='text-2xl text-[#333D47] font-medium overflow-hidden whitespace-nowrap overflow-ellipsis mt-5'>
                    New Custom Board
                </h1>
            </Panel>
            {boardMaxOut && showContent &&
                (<div className='absolute top-0 flex w-full h-[320px] items-center justify-center z-10'>
                    <div className='flex justify-end rounded-lg'>   
                        <h4>
                            Board limit reached     
                        </h4>
                    </div>
                </div>)
                
            }
        </div>
        
    )
}
