import { PatchActivationsItemType } from "@/views/Discover/Activations/v2/activations-types"

export const mergeDuplicates = (data: PatchActivationsItemType[]): PatchActivationsItemType[] => {
  let result: PatchActivationsItemType[] = []
  data.forEach((obj) => {
    let existingObj = result.find((item) => item.targetListId === obj.targetListId)

    if (existingObj) {
      // Merge keywordAdGroups and videoAdGroups arrays without duplicates
      existingObj.keywordAdGroups = Array.from(
        new Set(existingObj.keywordAdGroups?.concat(obj.keywordAdGroups || []) || (obj.keywordAdGroups ?? []))
      )
      existingObj.videoAdGroups = Array.from(
        new Set(existingObj.videoAdGroups?.concat(obj.videoAdGroups || []) || (obj.videoAdGroups ?? []))
      )
    } else {
      result.push(obj)
    }
  })

  return result
}