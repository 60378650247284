import React from 'react'
import { HandleSelectProps, ItemDetail, ItemTypes } from '../types'
import { Badge } from '../../../../components/Badge'
import LoadingData from './LoadingData'

interface IProps {
  selectedQueries: ItemDetail[]
  handleSelectItem: ({ itemType, name, id }: HandleSelectProps) => void
  selectedAylienNews: ItemDetail[]
  selectedAylienIndustries: ItemDetail[]
  isLoading: boolean
}

interface SelectedSectionProps {
  title: string
  selectedElements: ItemDetail[]
  handleSelectItem: ({ itemType, name, id }: HandleSelectProps) => void
  itemType: ItemTypes
}

const SelectedSection = ({ title, selectedElements, handleSelectItem, itemType }: SelectedSectionProps) => (
  <div
    data-testid={`custom-board-categories-selection-pane-${title.split(' ').join('-').toLowerCase()}`}
    className="flex flex-col"
  >
    <p className="text-sm text-slate-400">{title}</p>
    <div className="flex flex-col gap-2 py-2">
      {selectedElements.map(({ id, name, groupName }) => {
        return (
          groupName && (
            <Badge
              dataTestId="custom-board-pill-selected"
              color="blue"
              size="medium"
              key={id}
              text={name}
              onClose={() => {
                handleSelectItem({ itemType, id, name: groupName })
              }}
            />
          )
        )
      })}
    </div>
  </div>
)

export default function CategoriesSelectionPane({
  selectedQueries,
  handleSelectItem,
  selectedAylienNews,
  selectedAylienIndustries,
  isLoading
}: IProps) {
  return (
    <section
      data-testid="custom-board-categories-selection-pane"
      className="flex flex-col w-1/3 p-2 divide-y"
    >
      <header
        data-testid="custom-board-categories-selection-pane-header"
        className="flex flex-col gap-2 p-1"
      >
        <div className="text-lg font-bold">Board Selections</div>
        <p className="text-sm text-slate-400">Item(s) listed below will determine news on this board</p>
      </header>
      {isLoading ? (
        <LoadingData message="Loading..." />
      ) : (
        <div
          data-testid="custom-board-categories-selection-area"
          className="flex flex-col gap-2 divide-y"
        >
          {selectedQueries.length > 0 && (
            <SelectedSection
              title="Queries"
              key="Queries"
              selectedElements={selectedQueries}
              handleSelectItem={handleSelectItem}
              itemType={ItemTypes.queries}
            />
          )}
          {selectedAylienNews.length > 0 && (
            <SelectedSection
              title="News Verticals"
              key="News Verticals"
              selectedElements={selectedAylienNews}
              handleSelectItem={handleSelectItem}
              itemType={ItemTypes.aylienNews}
            />
          )}
          {selectedAylienIndustries.length > 0 && (
            <SelectedSection
              title="Industry Verticals"
              key="Industry Verticals"
              selectedElements={selectedAylienIndustries}
              handleSelectItem={handleSelectItem}
              itemType={ItemTypes.aylienIndustries}
            />
          )}
        </div>
      )}
    </section>
  )
}
