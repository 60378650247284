// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    -webkit-user-select: none;
            user-select: none;
}

.Tabs__Tab {
    margin-right: 32px;
    color: #6e8399;
    font-size: 14px;
    cursor: pointer;
    height: 56px;
    display: flex;
    text-align: center;
    align-items: center;
    text-decoration: none !important;
}

.Tabs__Tab:hover {
    color: #333d47;
}

.Tabs__Tab--active {
    font-weight: 700;
    color: #333d47 !important;
    position: relative;
    box-shadow: 0 -2px 0 #26aae1 inset;
    display: flex;
    text-align: center;
    align-items: center;
    margin-right: 32px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tabs/Tabs.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;IACf,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,kCAAkC;IAClC,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".Tabs {\n    position: relative;\n    display: flex;\n    flex-wrap: wrap;\n    user-select: none;\n}\n\n.Tabs__Tab {\n    margin-right: 32px;\n    color: #6e8399;\n    font-size: 14px;\n    cursor: pointer;\n    height: 56px;\n    display: flex;\n    text-align: center;\n    align-items: center;\n    text-decoration: none !important;\n}\n\n.Tabs__Tab:hover {\n    color: #333d47;\n}\n\n.Tabs__Tab--active {\n    font-weight: 700;\n    color: #333d47 !important;\n    position: relative;\n    box-shadow: 0 -2px 0 #26aae1 inset;\n    display: flex;\n    text-align: center;\n    align-items: center;\n    margin-right: 32px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
