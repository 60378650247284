import { useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { Dispatch, SetStateAction } from 'react'
import { BrandAIGenerationType } from './BrandAIGenerationForm'
import { userAccountAxios } from '@/axiosInstances'
import { rqKeys } from './brand-rqKeys'
import { logError } from '@/utils'
import useUser from '@/hooks/useUser'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { rqKeys as queriesRqKeys } from '../queries-rqKeys'
import { products } from '@/staticData/products'
import { AxiosResponse } from 'axios'

const generateBrand = async (args: { brandProfileId: number; payload: BrandAIGenerationType }) => {
  const url = `/brand-profile/${args.brandProfileId}/competitors/ai-generator`
  return await userAccountAxios.post(url, args.payload)
}

const useBrandQueries = (brandProfileId: number) => {
  const queryClient = useQueryClient()
  const { currentAccount } = useUser()

  const postProcess = () => {
    queryClient.invalidateQueries({ queryKey: rqKeys.brandProfileQuery('competitors', Number(brandProfileId)) })
    queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
    queryClient.invalidateQueries(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
    queryClient.invalidateQueries(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.AI_CREDITS))
  }
  const handleSuccess = async (response: AxiosResponse<any>) => {
    if (response.data && response.data.failure.length > 0) {
      toast.error(`Error occured for ${response.data.failure.length} item/s`)
      return
    }
    toast.success('Brands Response(s) Generated')
    postProcess()
  }

  const handleError = async (error: unknown) => {
    toast.error('Error Occured')
    postProcess()
    logError(error)
  }
  const brandQueryMutation = useMutation(generateBrand, {
    mutationKey: queriesRqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
    onSuccess: async (response) => {
      await handleSuccess(response)
    },
    onError: async (error) => {
      await handleError(error)
    }
  })

  const isMutatingBrandQuery = useIsMutating({
    mutationKey: queriesRqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
  })

  return {
    brandQueryMutation,
    isMutatingBrandQuery,
    handleError
  }
}

export default useBrandQueries
