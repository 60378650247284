import { OPINION_SAVING, ADD_OPINION } from '../../action-types/admin/opinions'
import toast from 'react-hot-toast'
import { userAccountAxios } from '../../../axiosInstances'

export function setOpinionSaving(bool) {
    return {
        type: OPINION_SAVING,
        opinionSaving: bool
    }
}

export function addOpinion(opinion) {
    return {
        type: ADD_OPINION,
        opinion
    }
}

export const createOpinion = (opinion) => {
    let url = `/opinions`
    return (dispatch, getState) => {
        dispatch(setOpinionSaving(true))
        userAccountAxios
            .post(url, opinion)
            .then((response) => {
                dispatch(addOpinion(response.data[0]))
                dispatch(setOpinionSaving(false))
                toast.success('Opinion created!')
            })
            .catch((error) => {
                //error
            })
    }
}
