import { BoardsDateRangeType } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import React from 'react';
import { DateRangePicker } from 'rsuite';
const { combine, before, afterToday } = DateRangePicker;
interface IProps {
    onSelectDateRange: (selectedRange: any) => void
    ranges: BoardsDateRangeType[]
    onCalendarStateChange?: (open: boolean) => void
    rangeValue?: [Date, Date]
}

const DateRangeSection = ({ onSelectDateRange, ranges, onCalendarStateChange, rangeValue } : IProps) => {
    const now = new Date();

    // Default value of calendar
    const getLast24Hrs = () => {
        const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        return [yesterday, now] as [(Date | undefined)?, (Date | undefined)?];
    }

    // Lower bound of allowed selectable dates
    const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60));

    const handleCalendarStateChange = (open: boolean) => {
        if (onCalendarStateChange)
        onCalendarStateChange(open);
    };

    const handleDateRangeChange = (dateRange: any) => {
        onSelectDateRange(dateRange)
    };

    return (
        <DateRangePicker
            onChange={handleDateRangeChange}
            disabledDate= {combine(before(sixtyDaysAgo), afterToday())}
            ranges={ranges}
            defaultValue={getLast24Hrs()}
            cleanable={false}
            onOpen={() => handleCalendarStateChange(true)}
            onClose={() => handleCalendarStateChange(false)}
            value={rangeValue ? rangeValue : undefined}
            preventOverflow={true}
        />
    );
};

export default DateRangeSection;
