import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import {
    adGroupReportTemplate,
    baseDeviceReport,
    campaignSummaryTemplate,
    DEVICE_DIMENSION,
    deviceReportTemplate,
    performanceDetailTemplate,
    personaReportTemplate
} from '@/views/ActivationReports/reports/device.report';
import { baseCreativeReport, creativeReportTemplate } from '@/views/ActivationReports/reports/creative.report';
import {
    baseParentalStatusReport,
    parentalStatusReportTemplate
} from '@/views/ActivationReports/reports/parental-status.report';
import { ageRangeReportTemplate, baseAgeReport } from '@/views/ActivationReports/reports/age.report';
import { baseAudienceReport, personaAudienceReportTemplate } from '@/views/ActivationReports/reports/audience.report';
import { baseDmaRegionReport } from '@/views/ActivationReports/reports/dma-region.report';
import { baseGenderReport, genderReportTemplate } from '@/views/ActivationReports/reports/gender.report';
import { baseHouseholdIncomeReport } from '@/views/ActivationReports/reports/household-income.report';
import { baseIndustryCategoryReport } from '@/views/ActivationReports/reports/industry-category.report';
import { baseKeywordReport, personaKeywordReportTemplate } from '@/views/ActivationReports/reports/keyword.report';
import { baseMomentReport } from '@/views/ActivationReports/reports/moment.report';
import { baseMomentKeywordReport } from '@/views/ActivationReports/reports/moment-keyword.report';
import { baseMomentScenarioReport } from '@/views/ActivationReports/reports/moment-scenario.report';
import { baseNewsCategoryReport } from '@/views/ActivationReports/reports/news-category.report';
import { baseTopicReport, personaTopicReportTemplate } from '@/views/ActivationReports/reports/topic.report';
import { baseYoutubePlacementReport } from '@/views/ActivationReports/reports/google-placement.report';
import { GENERIC_DIMENSION, InternalColumn, METRIC, ReportTemplate } from '@/views/ActivationReports/types/activation-report.types';
import { allTemplateReports } from '@/views/ActivationReports/data/ReportTemplates';

interface IProps {
    reportsTemplate: Function,
    open: boolean,
    handleClose: Function,
    initialReport: ReportTemplate[]
}

const CreateModal = ({ open, handleClose, reportsTemplate, initialReport }: IProps) => {
    const [selectedCategory, setSelectedCategory] = useState('Campaign Reports');
    const [selectedReports, setSelectedReports] = useState<ReportTemplate[]>([]);


    useEffect(() => {
        setSelectedReports(initialReport);
    }, [initialReport])

    const reportsByCategory: any = {
        'Campaign Reports': [performanceDetailTemplate],
        'Analytics Reports': [campaignSummaryTemplate, adGroupReportTemplate, personaReportTemplate, personaTopicReportTemplate, personaAudienceReportTemplate, personaKeywordReportTemplate, ageRangeReportTemplate, genderReportTemplate, parentalStatusReportTemplate, creativeReportTemplate, deviceReportTemplate],
        'Custom Reports': [baseAgeReport, baseAudienceReport, baseCreativeReport, baseDeviceReport, baseDmaRegionReport,
            baseGenderReport, baseYoutubePlacementReport, baseHouseholdIncomeReport, baseIndustryCategoryReport, baseKeywordReport,
            baseMomentReport, baseMomentKeywordReport, baseMomentScenarioReport, baseNewsCategoryReport, baseParentalStatusReport, baseTopicReport],
    };

    const toggleReportSelection = (report: ReportTemplate) => {
        setSelectedReports((prevSelectedReports) => {
            const foundReport = prevSelectedReports.find((r) => r.name === report.name);
            return foundReport
                ? prevSelectedReports.filter((r) => r.name !== report.name)
                : [...prevSelectedReports, report];
        });
    };

    const handleContinue = () => {
        const formatColumnName = (column: string, report: ReportTemplate) => {

            const matchingReports = allTemplateReports.filter(reportTemplate =>
                report.name === reportTemplate.name);

            const matchingReport = matchingReports.find(mr => mr.name === report.name);

            if (matchingReport != undefined) {
                const conversionMatch = matchingReport.conversions.find((field: any) => field.value === column);
                if (conversionMatch) {

                    return { name: conversionMatch.name, category: 'Conversions' };
                }

                const dimensionsList = [...matchingReport.dimensions]
                const dimensionMatch = dimensionsList.find((field: any) => field.value === column);
                if (dimensionMatch) {
                    return { name: dimensionMatch.name, category: 'Dimensions' };
                }

                const metricsList = [...matchingReport.metrics]
                const metricMatch = metricsList.find((field: any) => field.value === column);
                if (metricMatch) {

                    return { name: metricMatch.name, category: 'Metrics' };
                }
            }
        };

        const formatColumns = (columns: (string | InternalColumn | undefined)[], report: ReportTemplate) => {
            return columns
                .filter((column): column is string => typeof column === 'string')
                .map((column: string) => {
                    const result = formatColumnName(column.trim(), report)
                    return {
                        name: result?.name,
                        value: column.trim(),
                        category: result?.category
                    }
                });
        };

        const formattedReports = selectedReports.map((report) => {
            if (report && report.columns) {
                return {
                    ...report,
                    columns: formatColumns(report.columns, report),
                };
            }
            return report;
        });

        reportsTemplate(formattedReports);

        handleClose(false);
    };

    const formatMetricName = (column: METRIC | GENERIC_DIMENSION | DEVICE_DIMENSION) => {
        return column
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const handleCloseModal = () => handleClose(false);

    return (
        <Modal show={open} onClose={handleClose} onHide={handleCloseModal} size="lg" data-testid="activation-reports-create-modal">
            <Modal.Header>
            </Modal.Header>

            <Modal.Body className="flex p-4" data-testid="activation-reports-create-modal-body">
                <div className="w-1/4 pr-4 sticky top-0 self-start" data-testid="activation-reports-create-modal-categories">
                    {Object.keys(reportsByCategory).map((category) => (
                        <button
                            key={category}
                            className={`py-2 px-2 text-left font-semibold text-lg w-full rounded-lg mt-2 ${selectedCategory === category
                                ? 'bg-purple-100 text-purple-600'
                                : 'text-gray-600'
                                }`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <div className="w-3/4 pl-3 grid grid-cols-2 gap-4" data-testid="activation-reports-create-modal-reports">
                    {reportsByCategory[selectedCategory].map((report: ReportTemplate) => (
                        <div
                            key={report.name}
                            className={`p-6 border-2 rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 w-72 cursor-pointer ${selectedReports.some((selectedReport: any) => selectedReport.name === report.name) ? 'border-purple-600' : ''
                                }`}
                            onClick={() => toggleReportSelection(report)}
                            data-testid="activation-reports-create-modal-report"
                        >
                            <p className="font-bold flex items-center text-base" data-testid="activation-reports-create-modal-report-name">
                                {report.name}
                            </p>
                            <p className="text-sm text-gray-600" data-testid="activation-reports-create-modal-report-desc">{report.description}</p>
                            {report.columns && report.columns.length > 0 && (
                                <p className="text-xs text-gray-500" data-testid="activation-reports-create-modal-report-metrics">
                                    <span className="text-purple-500 font-semibold">Metrics include: </span>
                                    <span className="text-xs text-gray-500 font-normal">
                                        {report.columns
                                            .filter((column): column is METRIC | GENERIC_DIMENSION | DEVICE_DIMENSION => column !== undefined)
                                            .map((column) => formatMetricName(column))
                                            .join(', ')}
                                    </span>
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer className="border-t p-4 flex justify-end" data-testid="activation-reports-create-modal-footer">
                <button
                    className={`px-4 py-2 rounded-md text-white ${selectedReports.length === 0
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-purple-600 hover:bg-purple-700'
                        }`}
                    disabled={selectedReports.length === 0}
                    onClick={handleContinue}
                    data-testid="activation-reports-create-modal-footer-continue"
                >
                    Continue
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateModal;
