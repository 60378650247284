import React, { SyntheticEvent, useState } from 'react';
import Tooltip from '@/components/TailwindTooltip';
import cubeTransparentSvg from '@/assets/img/boardTemplate/cube_transparent.svg';
import sparklesSvg from '@/assets/img/boardTemplate/sparkles.svg';
import { useNavigate } from '@tanstack/react-location';
import { PATH_MOMENTS_V2_AI } from '@/routes';

export type IProps = {
    boardMaxOut?: boolean,
}

export const BoardGenerateAI = ({ boardMaxOut = false, }: IProps) => {
    const navigateGenerateAI = useNavigate()
    const [inputTextContent, setInputTextContent] = useState('');

    const handleButtonClick = (e: SyntheticEvent<Element, Event>) => {
        if (inputTextContent.trim() === '') return
        navigateGenerateAI({
            to: PATH_MOMENTS_V2_AI,
            search: (old) => (
                {
                    ...old,
                    aiPromptText: inputTextContent
                }
            )
        });
    };

    return (
        // Remove these divs (with todo_remove_in_future) to remove coming soon! option or use feature flag
        <div>
            <div className="mt-5 h-[210px] rounded-lg bg-gradient-to-r from-[#48C294BF] via-[#8F7EFFBF] to-[#4F46E5BF] px-20 py-5">

                <div className="text-center font-semibold text-2xl text-white">Generate a board with AI</div>

                <div className="bg-white h-20 rounded-full mt-4 px-8 py-4 items-center flex flex-row">
                  <p className="text-base text-[#2E4052] ">Build a board with trends about</p>
                  <input
                    className='text-base ml-4 flex flex-grow border-b-2 border-[#828282]  focus:outline-none'
                    value={inputTextContent}
                    onChange={(event) => {
                        setInputTextContent(event.target.value)
                    }}
                  />
                  <button
                    data-tooltip-target="tooltip-light"
                    className='bg-gradient-to-r from-[#48C294BF] via-[#8F7EFFBF] to-[#4F46E5BF] w-[120px] h-[40px] ml-8 rounded-full items-center flex flex-row justify-evenly'
                    id="generate-ai-board-button"
                    onClick={handleButtonClick}
                    disabled={boardMaxOut}
                  >
                    <img data-testid={'cube-image'} src={cubeTransparentSvg} width='16px' />
                    <Tooltip
                      disabled={!boardMaxOut}
                      content={<p>Max Board Limit Reached</p>}
                    >
                      <p className="text-base text-white ">Generate</p>
                    </Tooltip>
                  </button>
                </div>

                <div className='items-center flex flex-row my-6 justify-center '>
                    <img data-testid={'cube-image'} src={sparklesSvg} width='15px' />
                    <div className="text-center font-semibold text-sm text-white ml-3">Describe the types of moments you would like this board to be built around with the help of AI</div>
                </div>
            </div>
        </div>
    )
}
