import { useQuery, useMutation } from '@tanstack/react-query'
import { logError } from '@/utils'
import { api } from '@/api/api'

export default function useGoogleAdsSettings(brandProfileId: number) {
  const contentThemesQuery = useQuery(
    ['contentThemes', brandProfileId],
    async () => {
      return await api.brandProfile.brandExcludedContentThemes.get(brandProfileId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in topic themes query' })
      },
      refetchOnWindowFocus: false,
      enabled: !!brandProfileId
    }
  )

  const sensitiveContentQuery = useQuery(
    ['sensitiveContent', brandProfileId],
    async () => {
      return await api.brandProfile.brandSensitiveContentCategories.get(brandProfileId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'onError in sensitive content query' })
      },
      refetchOnWindowFocus: false,
      enabled: !!brandProfileId
    }
  )

  const updateContentThemeMutation = useMutation(
    async (updatedContentTheme: { brandProfileId: number; id: number; exclude: boolean }) => {
      return await api.brandProfile.brandExcludedContentThemes.update(
        updatedContentTheme.brandProfileId,
        updatedContentTheme.id,
        updatedContentTheme.exclude
      )
    },
    {
      onError: (err) => {
        logError(err, { info: 'onError in update topic theme' })
      }
    }
  )

  const updateSensitiveContentMutation = useMutation(
    async (updatedSensitiveContent: { brandProfileId: number; id: number; exclude: boolean }) => {
      return await api.brandProfile.brandSensitiveContentCategories.update(
        updatedSensitiveContent.brandProfileId,
        updatedSensitiveContent.id,
        updatedSensitiveContent.exclude
      )
    },
    {
      onError: (err) => {
        logError(err, { info: 'onError in update sensitive content' })
      }
    }
  )

  return {
    contentThemesQuery,
    sensitiveContentQuery,
    updateContentThemeMutation,
    updateSensitiveContentMutation
  }
}
