import React from 'react'
import { Channel } from '../../../../../classes/channel'
import { accentColor, brandPinkColor, purpleSecondaryColor } from '../../../../../assets/jss/colorConstants'
import { ReactComponent as YoutubeVideoIcon } from '../../../../../assets/img/youtubevideo.svg'
import { ReactComponent as ViewsIcon } from '../../../../../assets/img/views.svg'
import { ReactComponent as UsersIcon } from '../../../../../assets/img/users.svg'
import numeral from 'numeral'
import { openInNewTab } from '../../../../../utils'
import ActionButton from './ActionButton'
var dayjs = require('dayjs')
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

interface cat {
    text: string
    color: string
}

const Cat: Function = ({ text, color }: cat) => {
    return (
        <div
            style={{
                marginBottom: 4,
                height: 24,
                borderRadius: 3,
                padding: '2px 8px 2px 8px',
                backgroundColor: color,
                color: 'white',
                fontWeight: 600,
                fontSize: 12,
                lineHeight: '20px',
                marginRight: 4
            }}
        >
            {text}
        </div>
    )
}

interface iProps {
    channel: Channel
    isFromViewClick: boolean
    uploaded: boolean
}

const ChannelComponent: Function = ({ channel, isFromViewClick, uploaded }: iProps) => {
    const initialChannel = new Channel(
        channel.actionId,
        channel.id,
        channel.name,
        channel.thumbnail,
        channel.description,
        channel.topTargetCategories,
        channel.topMonitorCategories,
        channel.topBlockCategories,
        channel.videoCount,
        channel.views,
        channel.subscribers,
        channel.latestVideoUploadDate,
        channel.lastTimeActionWasChanged,
        channel.targetPercentage,
        channel.blockPercentage,
        channel.monitorPercentage,
        channel.versionId
    )

    const [compChannel, setCompChannel] = React.useState(initialChannel)

    const [expanded, setExpanded] = React.useState(false)
    const [truncated, setTruncated] = React.useState(false)

    const handleTruncate: Function = (_truncated: boolean) => {
        if (truncated !== _truncated) {
            setTruncated(_truncated)
        }
    }

    const toggleLines: Function = (event: React.MouseEvent) => {
        event.preventDefault()
        setExpanded((prev) => !prev)
    }

    const handleChangeAction: Function = (actionId: number) => {
        if (isFromViewClick) {
            return
        }
        if (actionId === compChannel.actionId) {
            return
        }
        setCompChannel(compChannel.changeAction(actionId))
    }

    const goToChannel: Function = () => {
        openInNewTab(`https://www.youtube.com/channel/${compChannel.id}`)
    }

    return (
        <div
            style={{
                width: '100%',
                minHeight: 158,
                padding: 16,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div
                id="channelThumbnail"
                onClick={() => goToChannel()}
                style={{ cursor: 'pointer', height: 56, width: 56, marginRight: 16 }}
            >
                <img
                    style={{ height: 56, width: 56, borderRadius: 6 }}
                    src={compChannel.thumbnail}
                />
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                    <div
                        id="channelName"
                        onClick={() => goToChannel()}
                        style={{
                            cursor: 'pointer',
                            fontWeight: 700,
                            fontSize: 18,
                            lineHeight: '28px',
                            color: '#333D47'
                        }}
                    >
                        {compChannel.name}
                    </div>

                    {!uploaded && (
                        <div
                            id="percentMatch"
                            style={{
                                marginTop: 3,
                                display: 'flex',
                                marginLeft: 16,
                                fontWeight: 700,
                                fontSize: 12,
                                lineHeight: '22px'
                            }}
                        >
                            <div
                                style={{
                                    color: accentColor
                                }}
                            >
                                {`${compChannel.targetPercentage}% Target Match`}
                            </div>
                            <div
                                style={{
                                    color: purpleSecondaryColor,
                                    marginLeft: 8
                                }}
                            >
                                {`${compChannel.monitorPercentage}% Monitor Match`}
                            </div>
                            <div
                                style={{
                                    color: brandPinkColor,
                                    marginLeft: 8
                                }}
                            >
                                {`${compChannel.blockPercentage}% Block Match`}
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            fontWeight: 600,
                            fontSize: 12
                        }}
                    >
                        <ActionButton
                            id="targetButton"
                            handleChangeAction={() => handleChangeAction(1)}
                            disabled={isFromViewClick}
                            active={compChannel.actionId === 1}
                            activeColor={accentColor}
                            text="Target"
                        />
                        <ActionButton
                            id="monitorButton"
                            handleChangeAction={() => handleChangeAction(3)}
                            disabled={isFromViewClick}
                            active={compChannel.actionId === 3}
                            activeColor={purpleSecondaryColor}
                            text="Monitor"
                        />
                        <ActionButton
                            id="blockButton"
                            handleChangeAction={() => handleChangeAction(2)}
                            disabled={isFromViewClick}
                            active={compChannel.actionId === 2}
                            activeColor={brandPinkColor}
                            text="Block"
                        />
                    </div>
                </div>
                <div
                    id="channelDescription"
                    style={{
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '22px',
                        color: '#696969',
                        marginTop: 4,
                        marginBottom: 16
                    }}
                    className="max-w-lg"
                >
                    <div className={expanded ? 'line-clamp-3 max-w-lg' : 'truncate max-w-sm'}>
                        {compChannel.description}
                    </div>
                    {compChannel.description && (
                        <>
                            {!expanded && (
                                <span
                                    style={{ color: accentColor }}
                                    className="sightlyLink"
                                    onClick={(e) => toggleLines(e)}
                                >
                                    more
                                </span>
                            )}

                            {expanded && (
                                <span
                                    style={{ color: accentColor }}
                                    className="sightlyLink"
                                    onClick={(e) => toggleLines(e)}
                                >
                                    less
                                </span>
                            )}
                        </>
                    )}
                </div>
                <div
                    id="categoryContainer"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start'
                    }}
                >
                    {compChannel.topTargetCategories.map((cat, index) => {
                        return (
                            <Cat
                                key={index}
                                text={cat}
                                color={accentColor}
                            />
                        )
                    })}
                    {compChannel.topMonitorCategories.map((cat, index) => {
                        return (
                            <Cat
                                key={index}
                                text={cat}
                                color={purpleSecondaryColor}
                            />
                        )
                    })}
                    {compChannel.topBlockCategories.map((cat, index) => {
                        return (
                            <Cat
                                key={index}
                                text={cat}
                                color={brandPinkColor}
                            />
                        )
                    })}
                </div>
                <div
                    id="statsContainer"
                    style={{ display: 'flex', marginTop: 12 }}
                >
                    <div
                        id="channelVideosIcon"
                        style={{ display: 'flex', height: 16, alignItems: 'center' }}
                    >
                        <YoutubeVideoIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8
                            }}
                        >
                            {compChannel.videoCount > 999
                                ? numeral(compChannel.videoCount).format('0a')
                                : numeral(compChannel.videoCount).format('0,0')}
                        </div>
                    </div>

                    <div
                        id="channelViewsIcon"
                        style={{
                            display: 'flex',
                            height: 16,
                            alignItems: 'center',
                            marginLeft: 24
                        }}
                    >
                        <ViewsIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8
                            }}
                        >
                            {numeral(compChannel.views).format('0a')}
                        </div>
                    </div>

                    <div
                        id="channelSubscribersIcon"
                        style={{
                            display: 'flex',
                            height: 16,
                            alignItems: 'center',
                            marginLeft: 24
                        }}
                    >
                        <UsersIcon
                            style={{
                                color: '#7F868D',
                                alignSelf: 'center',
                                height: 16,
                                width: 16
                            }}
                        />
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: '20px',
                                marginLeft: 8
                            }}
                        >
                            {numeral(channel.subscribers).format('0a')}
                        </div>
                    </div>

                    <div
                        style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            fontSize: 12,
                            lineHeight: '20px',
                            fontWeight: 500
                        }}
                    >
                        Latest Video Upload:&nbsp;
                        <div style={{ fontWeight: 600, marginRight: 16 }}>
                            {dayjs(compChannel.latestVideoUploadDate).format('MMM DD, YYYY')}
                        </div>
                        {compChannel.lastTimeActionWasChanged && (
                            <>
                                Updated:&nbsp;
                                <div style={{ fontWeight: 600 }}>
                                    {dayjs(compChannel.lastTimeActionWasChanged).format('MMM DD, YYYY h:mm A')}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChannelComponent
