import React from 'react'
import Panel from 'rsuite/lib/Panel'
import { useMatch } from '@tanstack/react-location'
import useGoogleAdsSettings from '@/views/BrandProfiles/BrandProfile/components/GoogleAds/useGoogleAdsSettings'
import ExcludedContentThemeTable from '@/views/BrandProfiles/BrandProfile/components/GoogleAds/ExcludedContentThemeTable'
import { Divider } from 'rsuite'
import ExcludedSensitiveContentTable from '@/views/BrandProfiles/BrandProfile/components/GoogleAds/ExcludedSensitiveContentTable'
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter'
import useSteps from '@/hooks/brandProfile/useSteps'
import { LoadingPage } from '@/components/LoadingPage'
import { InfoPage } from '@/components/InfoPage'
import toast from 'react-hot-toast';

export default function GoogleAds() {
    const { activeStep,
        handleContinueClick,
        handlePreviousClick } = useSteps()
    const { params: { brandProfileId } } = useMatch()
    const { contentThemesQuery,
        sensitiveContentQuery,
        updateContentThemeMutation,
        updateSensitiveContentMutation } = useGoogleAdsSettings(Number(brandProfileId))

    const handleContentThemeCheckbox = (checked: boolean, id: number) => {
        updateContentThemeMutation.mutate({
            brandProfileId: Number(brandProfileId),
            id,
            exclude: checked
        }, {
            onSuccess: () => {
            toast.success('Changes saved');
            },
            onError: () => {
            toast.error('Failed to save changes');
            }
        })
    }

    const handleSensitiveContentCheckbox = (checked: boolean, id: number) => {
        updateSensitiveContentMutation.mutate({
            brandProfileId: Number(brandProfileId),
            id,
            exclude: checked
        }, {
            onSuccess: () => {
            toast.success('Changes saved');
            },
            onError: () => {
            toast.error('Failed to save changes');
            }
        })
    }
    return (
        <Panel>
            <div className="sightlyPanelHeader">Google Ads Content</div>
            <div className="descriptionText">
                Select the sensitive content categories you wish to exclude on Google Ads
            </div>
            <Divider />
            <div className='flex flex-col gap-4'>
                {
                    contentThemesQuery.isLoading ? (
                        <LoadingPage message="Fetching Content Themes" />
                    ) : contentThemesQuery.data ? (
                        <ExcludedContentThemeTable
                            brandContentThemes={contentThemesQuery.data}
                            handleCheckboxChange={handleContentThemeCheckbox} />
                    ) : (
                        <InfoPage message="There was an error fetching Content Themes. Please contact support for help." />
                    )
                }

                {
                    sensitiveContentQuery.isLoading ? (
                        <LoadingPage message="Fetching Sensitive Content Types" />
                    ) : sensitiveContentQuery.data ? (
                        <ExcludedSensitiveContentTable
                            brandSensitiveContent={sensitiveContentQuery.data}
                            handleCheckboxChange={handleSensitiveContentCheckbox} />
                    ) : (
                        <InfoPage message="There was an error fetching Sensitive Content Types. Please contact support for help." />
                    )
                }
            </div>
            <BrandProfileFooter activeStep={activeStep}
                disabled={false}
                handlePrevious={handlePreviousClick}
                handleContinue={handleContinueClick}
            />
        </Panel>
    )
}
