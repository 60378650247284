import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React, { ReactElement } from 'react'


interface IProps {
    show: boolean
    type: 'success' | 'error' | 'warning' | 'loading' | 'info'
    message: string | ReactElement
    handleClose?: () => void
    dataTestId?: string
}

const messageTypes = {
    success: {
        color: 'green',
        icon: <CheckCircleIcon
            data-testid="check-circle-icon"
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
        />
    },
    error: {
        color: 'red',
        icon: <XCircleIcon
            data-testid="x-circle-icon"
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
        />
    },
    warning: {
        color: 'yellow',
        icon: <ExclamationTriangleIcon
            data-testid="exclamation-icon"
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
        />
    },
    loading: {
        color: 'slate',
        icon: <div data-testid="spinning-loader" className="spinning-loader" />
    },
    info: {
        color: 'blue',
        icon: <InformationCircleIcon
            data-testid="question-mark-circle-icon"
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
        />
    },
}
export function MessageTailwind({ show, type, message, handleClose, dataTestId }: IProps) {
    const { color, icon } = messageTypes[type]

    return (
        <>
            {show ? (
                <div
                    data-testid={dataTestId || 'action-status-message'}
                    className={`rounded-md bg-${color}-100 p-4 w-full animate-in slide-in-from-bottom`}
                >
                    <div className="flex">
                        <div className="flex-shrink-0">
                            {icon}
                        </div>

                        <div className="ml-3">
                            <div className={`text-sm font-medium text-${color}-800`}>
                                {message}
                            </div>
                        </div>
                        {handleClose && (
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        onClick={handleClose}
                                        type="button"
                                        className={`inline-flex rounded-md bg-${color}-100 p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-${color}-600 focus:ring-offset-2 focus:ring-offset-${color}-50`}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    )
}
