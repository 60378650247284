import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum GENDER_DIMENSION {
  DEVICE = 'device',
  GENDER = 'gender',
}

interface GenderDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | GENDER_DIMENSION
}

export interface GenderReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | GENDER_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | GenderDimensionOption)[]
}

export const dimensionOptions: Option<GenderDimensionOption> = {
  [GENDER_DIMENSION.DEVICE]: {
    name: 'Device',
    value: GENDER_DIMENSION.DEVICE
  },
  [GENDER_DIMENSION.GENDER]: {
    name: 'Gender Range',
    value: GENDER_DIMENSION.GENDER
  },
}

export const baseGenderReport: GenderReport = {
  name: 'Gender Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.GENDER,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const genderReportTemplate: GenderReport = {
  ...baseGenderReport,
  name: 'Gender Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENDER_DIMENSION.GENDER,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
