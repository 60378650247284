import React, { useState } from 'react';
import { TableColumnJs } from '@/utils';
import Table from 'rsuite/lib/Table';
import { TrashIcon, Bars3Icon } from '@heroicons/react/24/outline';

const { HeaderCell, Cell } = Table;

interface IProps {
    tableData: any[]
    handleTableData: Function
}

const tableHeaderStyle = {
    fontWeight: 500,
    fontSize: '16px',
    color: '#000000',
    backgroundColor: '#FAFAFA'
}
const tableHeaderActionStyle = {
    fontWeight: 500,
    fontSize: '16px',
    color: '#00AEEF',
    backgroundColor: '#FAFAFA'
}

interface RowData {
    id: number;
    name: string;
    value: string
}

export const ReportTable = ({ tableData, handleTableData }: IProps) => {
    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

    // Handle drag start
    const handleDragStart = (index: number) => {
        setDraggingIndex(index);
    };

    // Handle drop and reordering
    const handleDrop = (index: number) => {
        if (draggingIndex === null || draggingIndex === index) return;

        const newData = [...tableData];
        const draggedItem = newData.splice(draggingIndex, 1)[0];
        newData.splice(index, 0, draggedItem);

        handleTableData(newData);
        setDraggingIndex(null); // Reset drag index
    };

    // Handle drag over event (needed to allow dropping)
    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const handleDeleteAction = (rowData: any, rowIndex: number) => {
        handleTableData( tableData.filter((_ : any, index: number) => index !== rowIndex) );
    }

    const handleClearAllColumns = () => {
        handleTableData([]);
    };

    return (
        <div className='mx-11 py-4' data-testid="activation-reports-table">
            <div className='pt-3'>
                <Table
                    data={tableData}
                    autoHeight={true}
                >
                    <TableColumnJs width={64} >
                        <HeaderCell style={tableHeaderStyle} ></HeaderCell>
                        <Cell>
                            {(rowData: RowData, rowIndex: number) => (
                                <div
                                    draggable
                                    onDragStart={() => handleDragStart(rowIndex)}
                                    onDragOver={handleDragOver}
                                    onDrop={() => handleDrop(rowIndex)}
                                    style={{
                                        cursor: 'move',
                                        padding: '8px 0',
                                        background: draggingIndex === rowIndex ? '#f0f0f0' : 'white',
                                    }}
                                    data-testid="activation-reports-table-drag-column"
                                >
                                    <div className='flex justify-center w-[64px]' data-testid="activation-reports-table-drag-icon">
                                        <Bars3Icon className="w-6 h-w-6" />
                                    </div>
                                </div>
                            )}

                        </Cell>
                    </TableColumnJs>
                    <TableColumnJs width={107} flexGrow={1}>
                        <HeaderCell style={tableHeaderStyle} >Column Order</HeaderCell>
                        <Cell data-testid="activation-reports-table-column-order">
                            {(rowData: any, rowIndex: number) => {
                                return (
                                    rowIndex + 1
                                );
                            }}
                        </Cell>
                    </TableColumnJs>
                    <TableColumnJs width={324}>
                        <HeaderCell style={tableHeaderStyle} >Column Name</HeaderCell>
                        <Cell dataKey="name" data-testid="activation-reports-table-column-name"/>
                    </TableColumnJs>
                    <TableColumnJs width={90} flexGrow={1}>
                        <HeaderCell style={tableHeaderActionStyle} className='cursor-pointer'><div onClick={handleClearAllColumns}>Clear columns</div></HeaderCell>
                        <Cell >
                            {(rowData: any, rowIndex: number) => {
                                return (
                                    <div className='cursor-pointer flex justify-center w-[90px]' onClick={() => handleDeleteAction(rowData, rowIndex)} data-testid={`activation-reports-table-delete-column-${rowIndex}`}>
                                        <TrashIcon className="w-6 h-w-6" />
                                    </div>
                                );
                            }}
                        </Cell>
                    </TableColumnJs>
                </Table>
            </div>

        </div>
    )
}
