import { Control, DeepRequired, FieldErrorsImpl, FieldPath, FieldValues, Resolver, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

declare type SightlyRegisterReturn<
    TSightlyFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TSightlyFieldValues> = FieldPath<TSightlyFieldValues>
> = {
    control: Control<TSightlyFieldValues, any>
    name: TFieldName
    errors: FieldErrorsImpl<DeepRequired<TSightlyFieldValues>>

    label?: string
}

declare type UseSightlyFormReturn<
    TSightlyFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TSightlyFieldValues> = FieldPath<TSightlyFieldValues>
> = UseFormReturn<TSightlyFieldValues> & {
    sightlyRegister: (name: TFieldName, label?: string) => SightlyRegisterReturn<TSightlyFieldValues>
}

export function useSightlyForm<TSightlyFieldValues extends FieldValues = FieldValues>
    (zodObject: ZodType<TSightlyFieldValues>,
        props?: UseFormProps<TSightlyFieldValues>)
    : UseSightlyFormReturn<TSightlyFieldValues> {

    const rhfResult = useForm<TSightlyFieldValues>({
        ...props,
        mode: 'all',
        criteriaMode: 'all',
        resolver: zodResolver(zodObject) as Resolver<TSightlyFieldValues, any>
    })

    return {
        ...rhfResult,
        sightlyRegister: (name, label?: string) => {
            return {
                name: name,
                label: label,
                control: rhfResult.control,
                errors: rhfResult.formState.errors,
                isDirty: rhfResult.formState.isDirty,
                dirtyFields: rhfResult.formState.dirtyFields
            }
        }
    }
}
