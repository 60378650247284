import React from 'react'
import { useFlag } from '@unleash/proxy-client-react'
import toast from 'react-hot-toast'
import { RadioGroup } from '@headlessui/react'
import { useNavigate } from '@tanstack/react-location'

import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import { useActivationStatusCount } from '@/views/Discover/Activations/useActivationStatusCount'
import { Todo } from '@/views/Discover/Activations/Todo'
import { LoadingPage } from '@/components/LoadingPage'
import { InProcess } from '@/views/Discover/Activations/InProcess'
import { Implemented } from '@/views/Discover/Activations/Implemented'
import { activationsApi } from '@/views/Discover/Activations/activations-api'
import ActivationsV2 from '@/views/Discover/Activations/v2/Activations'
import config from '@/config'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}


const handleDownloadActivations = async ({brandProfileId, setDownloadingTrends, setShowTooManyTrendsError, selectedMomentIds, activationState}:any) => {
    if (brandProfileId === undefined) return
    setDownloadingTrends(true)
    if (selectedMomentIds.length > 1000) {
        setDownloadingTrends(false)
        setShowTooManyTrendsError(true)
        return
    }
    const args = {
        brandProfileId,
        clusterIds: selectedMomentIds,
        activationState
    }
    try {
        await activationsApi.downloadActivations(args)
    } catch (err) {
        toast.error('There was an error downloading your moments.')
        setDownloadingTrends(false)
    }

    setDownloadingTrends(false)
}

const RadioGroupOptionsList = ({data}: {data: any[] | undefined}) => {
    return <>
        {data &&
        data.map((bp, index) => (
            <RadioGroup.Option
                key={bp.brandProfileId}
                value={bp.brandProfileId}
                className={({ checked }) =>
                    classNames(
                        index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        index === data.length - 1
                            ? 'rounded-bl-md rounded-br-md'
                            : '',
                        checked
                            ? 'bg-blue-100 border-indigo-200 z-10'
                            : 'border-gray-200',
                        'relative rounded  p-4 flex cursor-pointer focus:outline-none'
                    )
                }
            >
                {({ active, checked }) => (
                    <>
                        <span
                            className={classNames(
                                checked
                                    ? 'bg-sightly-blue border-transparent'
                                    : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                        >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col truncate">
                            <RadioGroup.Label
                                as="div"
                                className={classNames(
                                    checked ? 'text-indigo-900' : 'text-gray-900',
                                    'block text-sm font-medium truncate line-clamp-1'
                                )}
                            >
                                <div className="truncate">{bp.brandProfileName}</div>
                            </RadioGroup.Label>
                        </div>
                    </>
                )}
            </RadioGroup.Option>
        ))}
    </>
};

export const Activations = React.memo(function Activations() {
    const isNewActivationManagerEnabled = useFlag(`enable_new_activation_mgr_${config.environment}`)
    const bpQuery = useBrandProfiles({
        submittedOnly: true
    })
    const statusQuery = useActivationStatusCount({ brandProfileId: bpQuery.currentBrandProfile?.brandProfileId })
    const [selectedActivationIds, setSelectedActivationIds] = React.useState<number[]>([])

    const tabs = React.useMemo(() => [
        { id: 'todo', name: 'To-Do', count: statusQuery?.data?.activeCount },
        { id: 'inprocess', name: 'In Process', count: statusQuery?.data?.pendingCount },
        { id: 'implemented', name: 'Implemented', count: statusQuery?.data?.completeCount }
    ], [statusQuery.data]);

    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = React.useState(tabs[0].id)
    if(isNewActivationManagerEnabled){
        return <ActivationsV2 />
    }
    if (bpQuery.isLoading || statusQuery.isLoading) {
        return <LoadingPage message="Fetching data" />
    }

    return (
        <div className="animate-in fade-in flex flex-row bg-slate-100">
            <div className="flex w-64 flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                    <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                        <div className="flex flex-col items-start flex-shrink-0  px-4 ">
                            <div className=" text-gray-900 font-medium text-lg">Set Brand Profile</div>
                            <div className=" text-gray-500 font-medium text-xs">
                                Select a brand profile from list below
                            </div>
                        </div>
                        <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                            <RadioGroup
                                value={bpQuery.currentBrandProfile?.brandProfileId}
                                onChange={(val: number) => {
                                    setSelectedActivationIds([])
                                    navigate({
                                        search: (old) => {
                                            return {
                                                ...old,
                                                brandProfileId: val
                                            }
                                        }
                                    })
                                }}
                            >
                                <RadioGroup.Label className="sr-only"> Privacy setting </RadioGroup.Label>
                                <div className="-space-y-px  bg-white">
                                    <RadioGroupOptionsList data={bpQuery.data} />
                                </div>
                            </RadioGroup>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="py-6 px-10 w-full">
                <h1 className="text-lg font-semibold text-gray-900">Activation Manager</h1>
                <div className="text-gray-500 font-medium text-xs mt-2">
                    Trigger your moments, and stay updated on your activations.
                </div>
                <nav
                    className="-mb-px flex space-x-8"
                    aria-label="Tabs"
                >
                    {tabs.map((tab) => (
                        <div
                            key={tab.name}
                            className={classNames(
                                currentTab === tab.id
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                            )}
                            aria-current={currentTab === tab.id ? 'page' : undefined}
                            onClick={() => {
                                setSelectedActivationIds([])
                                setCurrentTab(tab.id)
                            }}
                        >
                            {tab.name}
                            {tab.count ? (
                                <span
                                    className={classNames(
                                        currentTab === tab.id
                                            ? 'bg-sightly-blue-light text-blue-600'
                                            : 'bg-slate-300 text-gray-900',
                                        ' ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}
                                >
                                    {tab.count}
                                </span>
                            ) : null}
                        </div>
                    ))}
                </nav>
                <div>
                    {currentTab === 'todo' ? (
                        <Todo { ...{
                            defaultBrandProfileId: bpQuery.currentBrandProfile?.brandProfileId,
                            handleDownloadActivations,
                            selectedActivationIds,
                            setSelectedActivationIds
                        }}/>
                    ) : currentTab === 'inprocess' ? (
                        <InProcess />
                    ) : currentTab === 'implemented' ? (
                        <Implemented {...{
                            handleDownloadActivations,
                            selectedActivationIds,
                            setSelectedActivationIds
                        }} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
});
