import axios, { AxiosError } from 'axios';
import { AccountType } from '@/schemas/schemas';
import { location } from '@/RouteContainer';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

export function findAccountNodeByAccountId(accountId: number, array: AccountType[]): AccountType | undefined {
  for (const node of array) {
    if (node.accountId == accountId) return node;
    if (node.children) {
      const child: AccountType | undefined = findAccountNodeByAccountId(accountId, node.children);
      if (child) return child;
    }
  }
}

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

interface PreciseProps {
  numberToTruncate: number,
  significantDigits: number
}

export function precise({ numberToTruncate, significantDigits }: PreciseProps) {
  return numberToTruncate.toPrecision(significantDigits);
}

export function routeIsActive (myRoutes: Array<string>) {
  for (const r of myRoutes) {
    if (location && location?.current?.pathname?.startsWith(r)) {
      return true;
    }
  }
  return false;
}

interface StringObject {
  [key: string]: string;
}

interface MyFunctionOptions {
  myRoutes: string[]
  myParams?: StringObject;
}

export function routeWithParamsIsActive ({ myRoutes, myParams }:MyFunctionOptions) {
  let paramsMatch = true;
  const searchPath = location?.current?.search as StringObject;
  for (const param in myParams) {
    if (myParams[param] !== searchPath[param]) paramsMatch = false;
  }
  return paramsMatch && routeIsActive(myRoutes);
}

// Custom function to serialize array as comma-separated values
export const paramsSerializer = (params:any) => {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((value:any) => `${key}[]=${encodeURIComponent(value)}`).join('&');
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const downloadFile = (data: any, fileName: string) => {
  const fileURL = window.URL.createObjectURL(new Blob([data]))
  const fileLink = document.createElement('a')

  fileLink.href = fileURL

  fileLink.setAttribute('download', fileName)

  document.body.appendChild(fileLink)
  fileLink.click()
  document.body.removeChild(fileLink)
}

export const hasIoIdAndPersona = (ioIdWithPersonas:  Map<number, Set<string>>): boolean => {
    if (ioIdWithPersonas.size !== 0) {
      for (const [key, value] of Array.from(ioIdWithPersonas.entries())) {
          if (value instanceof Set && value.size > 0) {
              for (const item of Array.from(value)) {
                  if (typeof item === 'string') {
                      return true;
                  }
              }
          }
      }
    }
    return false;
}
