import React from 'react'

interface IProps {
    active: boolean
    dataTestId: string
}

const CampaignStatus: React.FunctionComponent<IProps> = React.memo(
    ({ active, dataTestId }) => {
        return (
            <span data-testid={dataTestId}
                className={`h-fit text-xs capitalize rounded-full py-0.5 px-2.5 ${active ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                {active ? 'Active' : 'Inactive'}
            </span>)
    }
)

export default CampaignStatus