import React from 'react'
import { CheckPicker, Icon } from 'rsuite'

type AssigneesProps = {
  options: {email:string, fullName:string}[]
  assignees: string[]
  setAssignees: React.Dispatch<React.SetStateAction<string[]>>
}

export const Assignees: React.FC<AssigneesProps> = (props:AssigneesProps) => {
  const {options, assignees, setAssignees} = props
  return (
    <div data-testid='activations-assignees-filter' id='pendo-activations-assignees-filter'>
      <CheckPicker
        data={options}
        className='w-40 h-[38px]'
        renderMenu={(menu: React.ReactNode) => {
          if (!options) {
            return (
              <p className='p-1 text-[#999] text-center'>
                <Icon
                  icon="spinner"
                  spin
                />{' '}
                Loading...
              </p>
            )
          }
          if (options.length === 0) {
            return <p className='p-1 text-[#999] text-center'>No Results Found</p>
          }
          return menu
        }}
        searchable={!!options}
        labelKey="fullName"
        valueKey="email"
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Assignees"
        block
        value={assignees}
        onChange={(assigneesList: string[]) => {
          setAssignees((prev: string[]) => assigneesList)
        }}
        onClean={() => {
          setAssignees((prev: string[]) => [])
        }}
      />
      <style>
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn {
            height:100%;
          }
        `}
      </style>
    </div>
  )
}
