import { userAccountAxios } from '@/axiosInstances';
import { AdminIabCategoriesSchema } from '@/schemas/schemas';
import { logError } from '@/utils';
import { AdminIabCategory, MapKeywordsProps } from '@/api/api';

export const adminIabCategories = {
  get: async () => {
    const url = `/iab-categories/leaf-nodes`
    const result = await userAccountAxios.get<AdminIabCategory[]>(url)
    if (result.status === 200) {
      let iabCategories = result.data
      const adminIabParsing = AdminIabCategoriesSchema.safeParse(iabCategories)
      if (!adminIabParsing.success) {
        logError(adminIabParsing.error, {
          info: 'We received different API data than expected while fetching admin Iab Category keywords, see the console log for more details.'
        })
      }
      return iabCategories
    }
  },
  mapKeywords: async ({ keywords, iabCategoryId }: MapKeywordsProps) => {
    const url = `/iab-categories/${iabCategoryId}/keywords`
    return await userAccountAxios.patch(url, keywords)
  },
  disregardLeafNode: async ({ iabCategoryId }: { iabCategoryId: string }) => {
    const url = `/iab-categories/${iabCategoryId}/disregard-leaf-node`
    return await userAccountAxios.patch(url)
  }
};
