import React, { useState, useEffect } from 'react'
import { ReportTable } from '@/views/ActivationReports/components/ReportTable'
import { ReportFilters } from '@/views/ActivationReports/components/ReportFilters'
import { ReportTemplate, ActivationReportConfiguration } from '../ActivationReports/types/activation-report.types'

interface IProps {
    reportTemplate: ReportTemplate
    handleOnChangeField: Function
    formData: ActivationReportConfiguration
}

export const ActivationReportForm = ({ reportTemplate, handleOnChangeField , formData }: IProps) => {

    const [tableData, setTableData] = useState( formData.reports.find((report) => report.name === reportTemplate.name)?.columns || [])

    useEffect(() => {
        handleOnChangeField(reportTemplate.name, tableData)
    }, [tableData]);

    return (
        <div className='w-full' data-testid="activation-reports-form-container">
            <div className='w-full flex flex-row h-full' data-testid="activation-reports-form-container-components">
                <div className='w-2/3 overflow-auto' data-testid="activation-reports-form-table">
                    <ReportTable tableData={tableData} handleTableData={setTableData} />
                </div>
                <div className='w-1/3 border-l-4 border-l-[#D4D9D9]+ p-4 max-h-full overflow-auto' data-testid="activation-reports-form-table-filters">
                    <ReportFilters reportTemplate={reportTemplate} tableData={tableData} handleTableData={setTableData} />
                </div>
            </div>
        </div>
    )
}
