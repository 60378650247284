import React from 'react';
import Button from 'rsuite/lib/Button';
import Table from 'rsuite/lib/Table';
import Input from 'rsuite/lib/Input';
import Panel from 'rsuite/lib/Panel';
import toast from 'react-hot-toast';
import useRoles from '@/hooks/useRoles';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import Sidebar from '@/views/Admin/components/Sidebar';

function Roles() {
    const { data: roles, archiveRole, createRole } = useRoles();
    const [role, setRole] = React.useState({name: '', description: '', userType: 'External'});

    const setUserType = (isInternal) => {
        const userType = isInternal ? 'Internal' : 'External'

        setRole({...role, userType})
    }

    const ActionCell = ({ rowData, dataKey, ...cellprops }) => {
        return (
            <Table.Cell
                data-testid="cell-archive-role"
                {...cellprops}
                style={{ padding: 1 }}
            >
                <Button
                    appearance="link"
                    onClick={() => {
                        archiveRole(rowData.id)
                    }}
                >
                    Archive
                </Button>
            </Table.Cell>
        )
    }

    const createRoleClick = () => {
        if (role.name.length < 1) {
            toast.error('Role name must be defined')
            return
        }
        if (role.description.length < 1) {
            toast.error('Role description must be defined')
            return
        }
        createRole(role)
    }

    return (
        <div className="flex">
            <Sidebar />
            <div className="p-6 w-full">
                <Panel
                    header="Create a new role"
                    bordered
                >
                    <div className="flex items-left flex-col gap-4 justify-start">
                        <Input
                            data-testid="input-role-name"
                            value={role.name}
                            onChange={(val) => setRole({...role, name: val})}
                            placeholder="Enter role name here..."
                        />
                        <Input
                            data-testid="input-role-description"
                            value={role.description}
                            onChange={(val) => setRole({...role, description: val})}
                            placeholder="Enter role description here..."
                        />

                        <SightlyCheckbox
                          data-testid="checkbox-user-type"
                          id={'internalRole'}
                          checked={role.userType === 'Internal'}
                          handleChange={(isInternal) => setUserType(isInternal)}
                          label="Internal Role"
                        />

                        <Button
                            data-testid="button-create-role"
                            style={{ width: 200 }}
                            onClick={createRoleClick}
                        >
                            Create Role
                        </Button>
                    </div>
                </Panel>

                <Panel
                    header="Roles"
                    bordered
                    className="mt-2"
                >
                    <Table
                        renderEmpty={() => {
                            return <p style={{ textAlign: 'center', marginTop: 50 }}>fetching data...</p>
                        }}
                        virtualized
                        affixHeader={0}
                        autoHeight
                        loading={roles?.length < 1}
                        data={roles}
                        shouldUpdateScroll={false}
                    >
                        <Table.Column
                            verticalAlign={'middle'}
                        >
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell
                                data-testid="cell-role-id"
                                dataKey="id"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                            flexGrow={1}
                        >
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell
                                data-testid="cell-role-name"
                                dataKey="name"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                            flexGrow={3}
                        >
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.Cell
                                data-testid="cell-role-description"
                                dataKey="description"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                          verticalAlign={'middle'}
                          flexGrow={1}
                        >
                            <Table.HeaderCell>User Type</Table.HeaderCell>
                            <Table.Cell
                                data-testid="cell-user-type"
                                dataKey="userType"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                        >
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>
                </Panel>
            </div>
        </div>
    )
}

export default Roles
