import React, { useState } from 'react';
import Panel from 'rsuite/lib/Panel';
import { useIsMutating, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMatch, useSearch } from '@tanstack/react-location';
import useUser from '@/hooks/useUser';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm';
import { MyLocationGenerics } from '@/classes/utils';
import useBrandProfileBasic, { BasicInfoType, aiGenerateType } from '@/hooks/brandProfile/useBrandProfileBasic';
import { useDebouncedAutoSave } from '@/hooks/useDebouncedAutosave';
import { api } from '@/api/api';
import { KeyTerms } from '@/views/BrandProfiles/BrandProfile/components/Information/KeyTerms';
import GeneratingData from '@/views/BrandProfiles/BrandProfile/components/GeneratingData';
import {
  HEADER_TEXT,
  COMPANY_SUMMARY_PLACEHOLDER,
  COMPANY_MENTALITY_PLACEHOLDER
} from '@/views/BrandProfiles/constants';
import debounce from 'just-debounce-it';
import NameSearchField from '@/views/BrandProfiles/BrandProfile/components/Queries/NameSearchField';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';
import { validationSchema } from '@/schemas/brandProfile_ts';
import { AiGenerateButton } from './AiGenerateButton';
import { products, userActions } from '@/staticData/products';
import { sharedContexts } from '@/sharedContexts/sharedContexts';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import SightlySelectTree from '@/components/Sightly/SightlyFormElements/SightlySelectTree';
const urlRegex = require('url-regex');

const InputFieldsStyleWrapper = (props: { children: React.ReactNode }) => {
  return <div className="flex justify-between items-start w-full gap-6 px-4 py-6 bg-white rounded-md">{props.children}</div>
}

const InputFieldsStyleWrapper_New = (props: { children: React.ReactNode }) => {
  return <div className="grid grid-cols-2 gap-6 px-4 py-6 bg-white rounded-md">{props.children}</div>
}

const HeaderStyleWrapper = (props: { children: React.ReactNode }) => {
  return <div className="w-full px-4 py-6 bg-white rounded-md">{props.children}</div>
}

function BasicInfo() {
  const {
    params: { brandProfileId }
  } = useMatch()

  const { setDisableBrandProfileStepsClick } = React.useContext(sharedContexts);

  const aiInput: aiGenerateType = {
    brandName: '',
    websiteUrl: ''
  }

  const { viewOnly } = useSearch<MyLocationGenerics>()
  const { currentAccount, perms, products, userPermissions, userProducts, subscriptionQuota } = useUser()
  const [disableKeyTerms, setDisableKeyTerms] = React.useState(false)
  const { queryResult, mutationResult, aiGenerate } = useBrandProfileBasic(Number(brandProfileId), setDisableKeyTerms, aiInput)
  const { data: aylienIndustries } = useQuery(rqKeys.aylienIndBasicInfo(), api.aylienIndustries.get)
  const [keyTerms, setKeyTerms] = React.useState<string>()

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.AI_CREDITS)) {
      const quota = subscriptionQuota.get(products.AI_CREDITS)
      quotaIsAllowed = quota.isAllowed
  }

  const {
    activeStep,
    handleContinueClick,
    handlePreviousClick
  } = useSteps();

  const {
    register,
    setValue,
    getValues,
    reset,
    control,
    trigger,
    formState: { errors },
    getFieldState
  } = useForm<BasicInfoType>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      brandProfileId: queryResult.data?.brandProfileId || undefined,
      brandProfileName: queryResult.data?.brandProfileName || '',
      brandInfoName: queryResult.data?.brandInfoName || '',
      wikiUrl: queryResult.data?.wikiUrl ||  '',
      websiteUrl: queryResult.data?.websiteUrl ||  '',
      aylienIndustryId: queryResult.data?.aylienIndustryId ||  undefined,
      twitterProfileUrl: queryResult.data?.twitterProfileUrl ||  ''
    },
    resolver: zodResolver(validationSchema)
  })

  const validateUrl = React.useCallback((url: string) => {    
    setIsValidUrl(urlRegex({exact: true, strict: false}).test(url));
  }, []);
  
   // Populate form fields with initial data and trigger validation
   React.useEffect(() => {
    const initialData:any = queryResult.data;
    
    if (initialData) {
      // Loop through the formData on load and set respective data
      for (const key in initialData) {
        if (initialData.hasOwnProperty(key) && initialData[key] !== undefined) {
          // Use type assertion here to access the properties by a string key
          setValue(key as keyof BasicInfoType, initialData[key]);
        }
      }

      const fieldsToCheck = ['brandProfileName', 'websiteUrl'];
      const fieldsToCheckAiGenerate = ['brandProfileName', 'websiteUrl'];
      const isIndustryVerticalNull = initialData['aylienIndustryId'] === null || initialData['aylienIndustryId'] === 1;      

      const isAnyFieldEmpty = fieldsToCheck.some(field => !initialData[field] || initialData[field].trim() === '');
      const isAnyFieldEmptyAiGenerate = fieldsToCheckAiGenerate.some(field => !initialData[field] || initialData[field].trim() === '');

      setIsAiGenerateButtonDisabled(isAnyFieldEmptyAiGenerate);
      setIsButtonDisabled(isAnyFieldEmpty || isIndustryVerticalNull);
      setDisableBrandProfileStepsClick(isAnyFieldEmpty || isIndustryVerticalNull || !isValidUrl);
    }

    // Trigger validation after setting initial values
    trigger()
  }, [queryResult.data, setValue, validateUrl]);
  
  const { onChange } = useDebouncedAutoSave({
    mutateAsync: mutationResult.mutateAsync,
    validationSchema: validationSchema,
    getValues,
    reset,
    trigger,
    data: queryResult.data,
    debounceInterval: 1000
  });

  const onFormChange = () => {
    const brandInfoName = getValues('brandInfoName');
    const { error: websiteUrlError } = getFieldState('websiteUrl');
    const shouldDisableGenerateButton = !!brandInfoName.length || !!websiteUrlError;
    setIsAiGenerateButtonDisabled(shouldDisableGenerateButton);
    onChange();
  }

  React.useEffect(() => {
    if (queryResult.data && 'keyTerms' in queryResult.data) {
      setKeyTerms((prev) => queryResult.data.keyTerms)
    }
  }, [queryResult.data]);

  if (queryResult.isLoading) {
    return <GeneratingData message="Loading..." />
  }

  const handleKeyTermsChange = React.useCallback(
    debounce((keyTermsValue: string) => {      
      if (keyTerms !== keyTermsValue) {
        setValue('keyTerms', keyTermsValue)
        setDisableKeyTerms(prev => true)
        onChange()
      }
    }, 1000),
    [keyTerms]
  ) 

  const handleAiGenerateClick = () => {
    aiInput.brandName = getValues('brandProfileName')
    aiInput.websiteUrl = getValues('websiteUrl')

    // Validated on aiGenerateButton click
    aiGenerate()
  }

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAiGenerateButtonDisabled, setIsAiGenerateButtonDisabled] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  React.useEffect(() => {
    setDisableBrandProfileStepsClick(!isValidUrl);
  }, [isValidUrl]);

  const isCreatingBrandProfile = useIsMutating({ mutationKey: rqKeys.brandProfileAiGenerateKey(currentAccount?.accountId) })

  // Disabled sidebar when generate process is running
  React.useEffect(() => {
    setDisableBrandProfileStepsClick(!!isCreatingBrandProfile)
  }, [isCreatingBrandProfile])

  React.useEffect(() => {
    setDisableBrandProfileStepsClick(viewOnly ?? false)
  }, [])

  const canBrandProfileUpdate = userCanPermissionProductQuota({
    requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
    userPermissions: userPermissions,
    checkType: PermissionCheckType.PERMISSION_CHECK
  });

  return (
    <Panel
      bodyFill={true}
      className="bg-gray-100 !rounded-none"
    >
      <form onChange={onChange}>
        <div className="flex flex-col gap-6 p-6">
        <HeaderStyleWrapper>
        <h1 className="text-lg font-bold mb-2">Brand Information</h1>
          <p className="text-base mb-4">{HEADER_TEXT}</p>
          <hr className="border-gray-300"/>
        </HeaderStyleWrapper>
          <InputFieldsStyleWrapper_New>
          <NameSearchField
              dataTestId={"input-brand-information-brand-profile-name"}
              name={"Brand Name"}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              handleSetWikiUrl={(val:string) => {
                  setValue("wikiUrl", val, {
                      shouldValidate: true,
                      shouldDirty: true
                  })
                  // Manually trigger change event to auto-save
                  onFormChange()
              }}
              handleSetName={(val:string) => {
                  setValue("brandProfileName", val, {
                      shouldValidate: true,
                      shouldDirty: true
                  })
                  // Manually trigger change event to auto-save
                  onFormChange()
              }}
              nameValue={getValues("brandProfileName")}
              error={errors?.brandProfileName?.message}
              textSize='14px'
              shiftText={true}
          />
            <SightlyInputForm
              dataTestId={'input-brand-information-website-url'}
              error={errors?.websiteUrl?.message}
              name="websiteUrl"
              register={register}
              id="websiteUrl"
              label="Website"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              required={true}
            />
          </InputFieldsStyleWrapper_New>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px'}}>
              <AiGenerateButton
                dataTestId={'ai-generate-button'}
                label="Generate"
                handleAIGenerate={handleAiGenerateClick}
                disabled={
                  viewOnly ||
                  isAiGenerateButtonDisabled ||
                  (getValues("brandProfileName").length < 1) ||
                  (getValues("websiteUrl").length < 1) ||
                  (errors?.websiteUrl?.message === undefined ? false : true) ||
                  !!isCreatingBrandProfile ||
                  !userCanPermissionProductQuota({
                    requiredPermissionValue: perms.AI_CREDITS,
                    requiredProductValue: products.AI_CREDITS,
                    userPermissions: userPermissions,
                    userProducts: userProducts,
                    checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
                    quota_is_allowed: quotaIsAllowed
                  })           
                }
              />
            </div>
            {!!isCreatingBrandProfile && <GeneratingData message='Creating Brand Profile...'/>}
          </div>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-wiki-url'}
              control={control}
              error={errors?.wikiUrl?.message}
              name="wikiUrl"
              register={register}
              id="wikipediaLink"
              label="Wikipedia Link"
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              prefix="en.wikipedia.org/wiki/"
              isValidUrl={true}
            />
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <div className={"w-full flex justify-between"} >
              <div className={"w-full mr-6 mt-1"} >
              <SightlySelectTree
                dataTestId={'select-brand-information-aylien-industry-vertical'}
                options={aylienIndustries}
                name="aylienIndustryId"
                id="aylienIndustryId"
                register={register}
                triggerChange={onChange}
                disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
                label="Industry Vertical"
                placeholder="Select Industry Vertical"
                required={true}
                error=""
                control={control}
                initialValue={queryResult.data?.aylienIndustryId}
              />
              </div>
              <SightlyInputForm
                dataTestId={'input-brand-information-twitter-url'}
                error={errors?.twitterProfileUrl?.message}
                name="twitterProfileUrl"
                register={register}
                id="twitterProfileUrl"
                label="X (Formerly Twitter)"
                control={control}
                disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
                prefix={'x.com/'}
                required={false}
              />
            </div>   
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-company-summary'}
              error={errors?.companySummary?.message}
              name="companySummary"
              register={register}
              id="companySummary"
              label="Company Summary"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              textarea={true}
              placeholder={COMPANY_SUMMARY_PLACEHOLDER}
              // onChange={handleInputChange0}
            />
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-company-mentality'}
              error={errors?.companyMentality?.message}
              name="companyMentality"
              register={register}
              id="companyMentality"
              label="Company Mentality"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              textarea={true}
              placeholder={COMPANY_MENTALITY_PLACEHOLDER}
              // onChange={handleInputChange1}
            />
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-mission-statement'}
              error={errors?.missionStatement?.message}
              name="missionStatement"
              register={register}
              id="missionStatement"
              label="Mission Statement"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              textarea={true}
              required={false}
            />
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-value-statement'}
              error={errors?.valueStatement?.message}
              name="valueStatement"
              register={register}
              id="valueStatement"
              label="Value Statement"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              textarea={true}
              required={false}
            />
          </InputFieldsStyleWrapper>
          <InputFieldsStyleWrapper>
            <SightlyInputForm
              dataTestId={'input-brand-information-vision-statement'}
              error={errors?.visionStatement?.message}
              name="visionStatement"
              register={register}
              id="visionStatement"
              label="Vision Statement"
              control={control}
              disabled={!canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              textarea={true}
              required={false}
            />
          </InputFieldsStyleWrapper>
        </div>
      </form>
      <div className="px-6 pb-6">
        <InputFieldsStyleWrapper>
          {keyTerms !== undefined && (
            <KeyTerms
              keyTerms={keyTerms}
              aiSuggestedKeyTerms={queryResult.data?.aiSuggestedKeyTerms}
              disabled={disableKeyTerms || !canBrandProfileUpdate || viewOnly || !!isCreatingBrandProfile}
              handleKeyTermsChange={handleKeyTermsChange}
            />
          )}
        </InputFieldsStyleWrapper>
      </div>

      <BrandProfileFooter activeStep={activeStep}
        disabled={
          viewOnly
            ? !!(
              errors?.websiteUrl?.message || 
              errors?.aylienIndustryId?.message || 
              // errors?.companyMentality?.message ||   // we leave this code commented because as we discussed in the meeting this code may be required in the future
              // errors?.companySummary?.message || 
              errors?.brandProfileName?.message
            ) 
              || getValues("aylienIndustryId") === null
            : isButtonDisabled || !isValidUrl || 
              !!(
                errors?.websiteUrl?.message || 
                errors?.aylienIndustryId?.message || 
                // errors?.companyMentality?.message ||  // we leave this code commented because as we discussed in the meeting this code may be required in the future
                // errors?.companySummary?.message || 
                errors?.brandProfileName?.message
              ) 
              || getValues("aylienIndustryId") === null
        }
        handlePrevious={handlePreviousClick}
        handleContinue={handleContinueClick}
      />
    </Panel>
  )
}

export default BasicInfo
