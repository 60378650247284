import React from 'react';
import useBrandProfileAylien, { aylienTypes, AylienItem } from './useBrandProfileAylien';
import Panel from 'rsuite/lib/Panel';
import Tree from 'rsuite/lib/Tree';
import InputGroup from 'rsuite/lib/InputGroup';
import Input from 'rsuite/lib/Input';
import Icon from 'rsuite/lib/Icon';
import TagPicker from 'rsuite/lib/TagPicker';
import Node from '@/views/BrandProfiles/BrandProfile/components/Aylien/Node';
import { useMatch, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

const actionIdOptions = [
    {
        label: 'Target',
        id: 'target'
    },
    {
        label: 'Block',
        id: 'block'
    },
    {
        label: 'No Action',
        id: 'noaction'
    }
]
type IProps = {
    aylienType: aylienTypes
}

const Aylien = ({ aylienType }: IProps) => {
    const {
        activeStep,
        handlePreviousClick,
        handleContinueClick
    } = useSteps();
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()
    const { viewOnly: viewOnlyParam } = useSearch<MyLocationGenerics>()
    const viewOnly = viewOnlyParam || false
    const aylien = useBrandProfileAylien(Number(brandProfileId), aylienType)

    const handleExpand = (all: AylienItem[], expandedItem: AylienItem) => {
        aylien.populateChildren(expandedItem)
    }

    const helpText =
        aylienType === aylienTypes.news
            ? 'News categories available from Aylien can be marked for Targeting or Blocking below. Taking this action will override any other conflicting answers in your Brand Profile and Target/Block any related moments that appear on associated Aylien anticipation boards. These settings are available to internal admin users only.'
            : 'Industry verticals available from Aylien can be marked for Targeting or Blocking below. Taking this action will override any other conflicting answers in your Brand Profile and Target/Block any related moments that appear on associated Aylien anticipation boards. These settings are available to internal admin users only.'

    return (
        <Panel
            header={<div className="sightlyPanelHeader">{aylienType === aylienTypes.news ? 'News' : 'Industries'}</div>}
        >
            <div className="descriptionText">{helpText}</div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        marginBottom: 8,
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <SightlyToggle
                        id="toggle"
                        setEnabled={(val) => aylien.handleSortChange(val)}
                        enabled={aylien.sort}
                        enabledText="sort a-z"
                        disabledText="sort z-a"
                    />

                    <TagPicker
                        defaultValue={[]}
                        block
                        style={{ marginLeft: 16, minWidth: 150 }}
                        preventOverflow
                        data={actionIdOptions}
                        labelKey={'label'}
                        valueKey={'id'}
                        placeholder="Filter by action"
                        onChange={(val) => {
                            aylien.handleFilterChange(val)
                        }}
                    />
                </div>

                <InputGroup style={{ marginBottom: 8 }}>
                    <Input
                        key={aylienType + 'search'}
                        placeholder="Search..."
                        onChange={(val) => aylien.handleSearch(val)}
                    />

                    <InputGroup.Button
                        disabled
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <Icon
                            style={{ color: '#0092d1' }}
                            icon="search"
                        />
                    </InputGroup.Button>
                </InputGroup>
                {aylien.isLoading ? (
                    <div style={{ height: 200 }}>
                        <LoadingPage message="Fetching Data" />
                    </div>
                ) : (
                    <Tree
                        style={{ overflowY: 'auto' }}
                        key={`${aylien?.searchTerm}-${aylien?.sort}`}
                        data={aylien?.items}
                        labelKey={'name'}
                        valueKey={'id'}
                        onExpand={handleExpand}
                        renderTreeNode={(nodeProps) => {
                            return (
                                <Node
                                    disabled={viewOnly}
                                    item={nodeProps}
                                    handleClick={aylien.handleActionClick}
                                />
                            )
                        }}
                    />
                )}
            </div>
            <BrandProfileFooter activeStep={activeStep}
                disabled={false}
                handlePrevious={handlePreviousClick}
                handleContinue={handleContinueClick}
            />
        </Panel>
    )
}

export default Aylien
