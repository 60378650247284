import React from "react";
import { TagPicker } from 'rsuite'

export default function InsertionOrderSection({ insertionOrders, handleOnChange }) {
    const ref = React.useRef()

    return (
        <div data-testid='div-report-generator-select-insertion-orders'>
            <h5 className="pt-2">1. Select your Insertion Orders</h5>
            <label className="pl-4 text-gray-500">
                {' '}
                This determines which Insertion Orders will be generated in this report
            </label>
            <div className="pl-4 mb-4 relative" ref={ref}>
                <TagPicker
                    container={() => ref.current}
                    data={insertionOrders}
                    onChange={(value) => handleOnChange('insertionOrderIds', value)}
                    placeholder="Search"
                    virtualized
                />
            </div>
        </div>
    )
}
