import React, { useState } from "react";
import { Button, Icon, IconButton, Loader, Panel } from "rsuite";
import { accentColor } from "@/assets/jss/colorConstants";
import { BoardItemMoreOptions } from "./BoardItemMoreOptions";
import DeleteBoardModal from '@/views/Discover/Boards/DeleteBoardModal';
import RenameModal from '@/views/Discover/Boards/RenamingModal';
import { ItemTypes } from "../types";


export type IProps = {
    id: number,
    name: string,
    img: string,
    description?: JSX.Element,
    updatedByName?: string,
    updatedDate?: string,
    type?: string,
    brandProfileId?: number | undefined | null,
    showOptions?: boolean,
    handleDelete: any,
    handleRename: any,
    activateBlur?: boolean,
    handleHoverClick?: Function,
    boardMaxOut?: boolean,
    boardCreator: any,
    blurButtonName?: string,
    handleBlurButtonOnClick?: Function,
    disableBlurButton?: boolean
}

export const MyBoardItem = ({
    id,
    name,
    img,
    description = undefined,
    updatedByName,
    updatedDate,
    type,
    brandProfileId,
    showOptions = false,
    handleDelete,
    handleRename,
    activateBlur = false,
    boardMaxOut = false,
    boardCreator,
    blurButtonName,
    handleBlurButtonOnClick,
    disableBlurButton = false,
}: IProps) => {
    const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
    const [showRenameModal, setShowRenameModal] = React.useState(false);
    const [renamingBoardId, setRenamingBoardId] = React.useState<number | null>(null);
    const [deletingBoardId, setDeletingBoardId] = React.useState<number | null>(null);

    const [showContent, setShowContent] = useState(false);

    const handleMouseEnter = () => {
        setShowContent(true);
    };

    const handleMouseLeave = () => {
        setShowContent(false);
    };

    const getLastUpdatedText = (updatedDate: string, updatedByName: string) => {        
        const updatedDateObject = new Date(updatedDate);
        const day = updatedDateObject.getDate();
        const month = updatedDateObject.getMonth() + 1;
        const year = updatedDateObject.getFullYear() - 2000;
        const updatedDateWithFormat = `${month}/${day}/${year}`;

        return <h5 data-testid={'card-last-modified'} className='text-xs text-[#828282] font-medium overflow-hidden whitespace-wrap overflow-ellipsis justify-self-end'>
            Last modified by {updatedByName}, {updatedDateWithFormat}
        </h5>;
    }

    return (
        <div
            data-testid={'card-' + name}
            className="relative h-[320px]"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Panel
                className={`inline-block w-full`}
                shaded
                bordered
                bodyFill
            >
                {disableBlurButton && <div className="bg-[#D9D9D9] h-[323px] w-full absolute opacity-75 rounded-md"/>}

                <div className={`${activateBlur && showContent ? 'blur-lg' : ''} h-[173px] content-center justify-center flex bg-[#7B61FF26]`}>
                    <img data-testid={'card-image'} src={img} width='80px' />
                </div>
                <div className='flex h-[150px] items-start flex-col pl-6 pr-3 py-4'>
                    <div className="flex flex-row justify-between w-full h-12 items-end">
                        <h1
                            data-testid={'card-name'}
                            className='text-xl text-[#333D47] font-medium overflow-hidden whitespace-nowrap overflow-ellipsis'
                        >
                            {name}
                        </h1>
                        {showOptions &&
                            <div className="more">
                                <BoardItemMoreOptions
                                    handleRename={() => {
                                        setShowRenameModal(true);
                                        setRenamingBoardId(id);
                                    }}
                                    handleDelete={() => {
                                        setShowDeleteAlert(true);
                                        setDeletingBoardId(id);
                                    }}
                                    boardId={id}
                                    brandProfileId={brandProfileId}
                                    boardType={type!}
                                >
                                    <IconButton
                                        data-testid={'card-more'}
                                        size="sm"
                                        appearance="link"
                                        icon={<Icon
                                            icon="more"
                                            rotate={90}
                                            color={accentColor} />} />
                                </BoardItemMoreOptions>
                            </div>
                        }
                    </div>
                    <div data-testid={'card-description'} className="w-full h-full justify-between flex flex-col pt-3 text-sm pr-3">
                        {description}
                        {
                            updatedDate && updatedByName &&
                            getLastUpdatedText(updatedDate, updatedByName)
                        }
                    </div>
                </div>
            </Panel>

            {activateBlur && showContent && handleBlurButtonOnClick && blurButtonName &&
                (<div
                    className='absolute top-0 flex w-full h-[173px] items-center justify-center'
                >
                    <div
                        className='flex justify-center rounded-lg'
                    >
                        {
                            disableBlurButton
                            ? <h5
                                data-testid={'card-hover-text'}
                                className='text-sightlySlate text-base h-3/4 overflow-hidden overflow-ellipsis w-3/4 self-center text-center' >
                                To create a {name.toLowerCase()} board, please update your brand profile
                            </h5>
                            : boardMaxOut
                                ? <h4 data-testid={'card-limit-reached'}>
                                    Board limit reached
                                </h4>
                                : boardCreator.isCreating
                                    ? <div className="w-full h-full items-center justify-center bg-red">
                                        <Loader size="md" />
                                    </div>
                                    : <Button
                                        id={`#${name.replace(/\s/g, '')}Template`}
                                        data-testid={'card-create-button'}
                                        className='rounded-lg w-[90px]'
                                        appearance="primary"
                                        style={{ backgroundColor: '#7B61FF' }}
                                        onClick={() => handleBlurButtonOnClick()}
                                        disabled={disableBlurButton}
                                    >
                                        {blurButtonName}
                                    </Button>
                        }

                    </div>
                </div>)
            }

            <DeleteBoardModal
                handleCancel={() => setShowDeleteAlert(false)}
                handleClose={() => setShowDeleteAlert(false)}
                handleDelete={() => {
                    if (!deletingBoardId) return;
                    handleDelete(deletingBoardId);
                    setShowDeleteAlert(false);
                }}
                open={showDeleteAlert} />

            <RenameModal
                key={renamingBoardId}
                handleCancel={() => setShowRenameModal(false)}
                handleClose={() => setShowRenameModal(false)}
                handleRename={(val: string) => {
                    if (!renamingBoardId) return;
                    handleRename({ id: renamingBoardId, name: val });
                    setShowRenameModal(false);
                }}
                open={showRenameModal}
                name={name} />
        </div>
    );

};
