import React from 'react'
import {Timeline} from 'rsuite'
import {useNavigate} from "@tanstack/react-location";

export type TimelineStep =  {
  title: string;
  description: string;
  complete: boolean
  initialIcon: React.ReactNode;
  completeIcon: React.ReactNode;
  routePath: string | null;
  disabled: boolean;
}

type TimelineProps = {
  steps: TimelineStep[]
}

export const VerticalTimeline = ({ steps }: TimelineProps) => {
  const navigate = useNavigate()
  const handleClick = (item: TimelineStep) => {
    if (!item.routePath || item.disabled) return

    navigate({to: item.routePath})
  }

  return (
    <Timeline className="custom-timeline" data-testid="vertical-timeline">
      {steps.map((step: TimelineStep, index: number) => (
        <Timeline.Item
          data-testid="timeline-item"
          data-test-is-seen={step.complete}
          key={index}
          className={`pb-4`}
          dot={step.complete ? step.completeIcon : step.initialIcon}
        >
          <div
            className={`group ${step.disabled ? '' : 'hover:cursor-pointer'}`}
            onClick={() => handleClick(step)}
          >
            <div className={`${step.disabled ? '' : 'group-hover:text-sightlyBlue'} text-gray-500 font-semibold`}>{step.title}</div>
            <div className={`${step.disabled ? '' : 'group-hover:text-sightlyBlue'} text-gray-500`}>{step.description}</div>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}
