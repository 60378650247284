import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { z } from 'zod'

export type ListedCategoryType = {
  categoryId: string
  categoryName: string
}[]

export const AdGroupsSchema = z.object({
  adGroupId: z.number(),
  adGroupName: z.string()
})

export type AdGroupsType = z.infer<typeof AdGroupsSchema>

export const CampaignOptionsSchema = z.array(
  z.object({
    campaignId: z.number(),
    campaignName: z.string(),
    adGroups: z.array(AdGroupsSchema)
  })
)

export type CampaignOptionsType = z.infer<typeof CampaignOptionsSchema>

export type TargetedActivationListType = {
  hashtagId: string
  hashtagName: string
  categoryName: string
  campaignsToAdGroups: SerializableMap<number, SerializableSet<number>>
}

export type FinalActivationType = {
  hashtagId: string;
  campaignToAdGroups: {
      campaignId: number;
      adGroupIds: number[];
  }[];
}