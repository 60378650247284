import React from 'react'
import { Popover, Tooltip, Whisper } from 'rsuite'
import AiIcon from '@/views/BrandProfiles/BrandProfile/components/aiIcon.svg'

export const AiInfoWhisper = (props: {
  question: string
  confidenceScore: number
  responseLabel: string
  reason: string
  responseTextColor?: string
  responseBgColor?: string
}) =>{
  return (
    <div data-testid='ai-info-popover'>
    <Whisper
      delayShow={300}
      enterable={true}
      placement="auto"
      trigger="hover"
      speaker={
        <Popover style={{borderRadius: '10px', width:'30em'}}>
          <div className="flex flex-col gap-3 rounded-lg">
            <div className="text-sm font-extrabold text-sightlySlate" data-testid='ai-info-popover-title'>{props.question}</div>
            <div className="flex justify-start gap-2 divide-x ">
              <span data-testid='ai-info-popover-responseLabel'
              className={`p-2 text-xs font-bold rounded-md shadow-md
              ${props.responseBgColor ?? 'bg-gray-100'}
              ${props.responseTextColor ?? 'text-gray-900'}
              `}>
                {props.responseLabel}
              </span>
              <span className="p-2 text-sm font-medium text-green-400 " data-testid='ai-info-popover-confidenceScore'>{props.confidenceScore || 0}% confidence Score</span>
            </div>
            <p data-testid='ai-info-popover-reason'>{props.reason}</p>
          </div>
        </Popover>
      }
    >
      <div data-testid='ai-info-icon' className="w-8 h-8 -m-1 rounded-md flex items-center justify-center bg-gradient-to-b from-[#0047FF] via-[#5B32EF] to-[#333D47]">
        <img
          src={AiIcon}
          className="w-6 h-6"
        />
      </div>
    </Whisper>
    </div>
  )
}
