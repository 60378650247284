import React from 'react';
import { patchBrandProfileKeywords } from '@/redux/actions/brandProfiles';
import { connect } from 'react-redux';
import Panel from 'rsuite/lib/Panel';
import Divider from 'rsuite/lib/Divider';
import ControlLabel from 'rsuite/lib/ControlLabel';
import CreatableTagPicker from '@/components/CreatableTagPicker';
import useBrandProfileKeywords from '@/hooks/brandProfile/useBrandProfileKeywords';
import { useMatch, useSearch } from '@tanstack/react-location';
import useUser from '@/hooks/useUser';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const mapStateToProps = state => {
    return {
        brandProfile: state.brandProfileUnderEdit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        patchBrandProfileKeywords: o => dispatch(patchBrandProfileKeywords(o))
    }
}

function Keywords(props) {
    const {
        activeStep,
        handlePreviousClick,
        handleContinueClick
    } = useSteps();
    const { viewOnly: viewOnlyParam } = useSearch()
    const viewOnly = viewOnlyParam || false
    const {
        params: { brandProfileId }
    } = useMatch()
    const { perms, userPermissions } = useUser();
    const { data: keywords, isFetched } = useBrandProfileKeywords(brandProfileId)

    const [compKeywordsTarget, setCompKeywordsTarget] = React.useState([])
    const [compKeywordsBlock, setCompKeywordsBlock] = React.useState([])
    React.useEffect(() => {
        if (!isFetched) {
            return
        }
        setCompKeywordsTarget(keywords?.filter(k => k.action === 'target'))
        setCompKeywordsBlock(keywords?.filter(k => k.action === 'block'))
    }, [keywords])

    const handleChange = ({ newKeywords, action }) => {
        if (!keywords) {
            return
        }

        if (action === 'target') {
            const newFormatted = newKeywords.map(k => {
                return { action: 'target', keyword: k }
            })

            props.patchBrandProfileKeywords({
                keywords: compKeywordsBlock.concat(newFormatted),
                brandProfileId
            })

            setCompKeywordsTarget(newFormatted)
        }

        if (action === 'block') {
            const newFormattedB = newKeywords.map(k => {
                return { action: 'block', keyword: k }
            })

            props.patchBrandProfileKeywords({
                keywords: compKeywordsTarget.concat(newFormattedB),
                brandProfileId
            })

            setCompKeywordsBlock(newFormattedB)
        }
    }

    const items = React.useMemo(() => {
        let _targeted = []
        let _blocked = []

        if (keywords) {
            keywords.forEach(kw => {
                if (kw.action === 'target') {
                    _targeted.push(kw.keyword)
                }
                if (kw.action === 'block') {
                    _blocked.push(kw.keyword)
                }
            })
        }

        return {
            targeted: _targeted,
            blocked: _blocked
        }
    }, [keywords])

    const canUpdateBrandProfile = userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    });

    return (
        <Panel header={<div className="sightlyPanelHeader">Keywords</div>}>
            <div className="descriptionText">
                Enter custom keywords below to be targeted or blocked based on a text match between your keywords and
                video metadata. These settings affect the SmartLists and are available to internal admin users only.
            </div>
            <ControlLabel>Keywords to target</ControlLabel>
            <CreatableTagPicker
                disabled={!canUpdateBrandProfile || viewOnly}
                backgroundColor="#4CAF50"
                textColor="white"
                items={items.targeted}
                saveChanges={arr =>
                    handleChange({
                        newKeywords: arr,
                        action: 'target'
                    })
                }
            />
            <Divider />

            <ControlLabel>Keywords to block</ControlLabel>
            <CreatableTagPicker
                disabled={!canUpdateBrandProfile || viewOnly}
                backgroundColor="#FF0000"
                textColor="white"
                items={items.blocked}
                saveChanges={arr =>
                    handleChange({
                        newKeywords: arr,
                        action: 'block'
                    })
                }
            />
            <BrandProfileFooter activeStep={activeStep}
                disabled={false}
                handlePrevious={handlePreviousClick}
                handleContinue={handleContinueClick}
            />
        </Panel>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Keywords)
