import { GetActivationsPropsType } from './activations-types'

export const activationsRqKeys = {
    //allActivations:()=> ['']
    activationStatusCount: (brandProfileId: number | undefined) => ['activation_status_count', brandProfileId] as const,
    activations: ({ brandProfileId, page, size, status }: GetActivationsPropsType) =>
        ['activations', brandProfileId, page, size, status] as const,
    todoActivations: ({ brandProfileId }: { brandProfileId: number | undefined }) =>
        ['todoActivations', brandProfileId] as const,
    inProcessActivations: ({ brandProfileId }: { brandProfileId: number | undefined }) =>
        ['inProcessActivations', brandProfileId] as const
}
