import React from 'react';

export const UnauthorizedPage = () => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full h-full gap-8"
      data-testid="need-permission-message"
    >
      <h1 className="text-3xl font-bold text-red-700">UNAUTHORIZED</h1>
      <p className="">Insufficient permission, you do not have permission to view this page</p>
      <a
        className="font-semibold"
        target="_blank"
        rel="noopener"
        href="https://www.sightly.com/contact-us/"
      >
        Please Contact us for assistance.
      </a>
    </div>
  )
}
