import React from 'react'
import SightlyAlert from "../../../../components/SightlyAlert"
import numeral from 'numeral'
import { routes } from "../../../../routes"
import { accentColor } from '../../../../assets/jss/colorConstants'

export default function UploadSuccessAlert({
     uploadStats,
     showSuccessPopup,
     setShowSuccessPopup,
     navigate
    }) {

    const {
        channelIds,
        videoIds
    }  = uploadStats

  const validCount = channelIds?.length + videoIds?.length

    return (
      <SightlyAlert
        headerColor={`linear-gradient(to right, #00A694 0%, ${accentColor} 100%)`}
        text="We’ll send you an email once the list has been enriched and you can return to download it.
              Typically, this process can take up to three hours, but may take longer."
        headerText={numeral(validCount || 0).format('0,0') + ' IDs Uploaded for Processing'}
        topHeaderText="Upload Successful"
        closeButtonText="Return to SmartLists"
        show={showSuccessPopup}
        setShow={setShowSuccessPopup}
        onClose={() => navigate({ to: routes.app.engage.lists.lists.path })}
      />
    )
}
