import React from 'react'
import { TrashIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import { TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'

import { CheckIcon } from '@heroicons/react/20/solid'
import BlueDot from '@/assets/img/blue-dot.svg'

export const PersonaGroup = (props: {
  persona: string
  expandedPersona: Set<string> | null
  isPersonaGroupSelectAllIndeterminate: (persona: string, activations: Array<TargetedActivationListType>) => boolean
  activations: TargetedActivationListType[]
  selectedActivations: Map<string, Set<number>>
  handleSelectAllPersonaGroup: (
    checked: boolean,
    persona: string,
    activations: Array<TargetedActivationListType>
  ) => void
  togglePersona: (persona: string) => void
  setPersonaToBeDeleted: React.Dispatch<React.SetStateAction<string | null>>
}) => {
  const {
    activations,
    expandedPersona,
    handleSelectAllPersonaGroup,
    togglePersona,
    isPersonaGroupSelectAllIndeterminate,
    persona,
    selectedActivations,
    setPersonaToBeDeleted
  } = props

  let uniqueItemsCount = new Set();
  let uniqueKeywordAdGroups = new Set();
  let uniqueVideoAdGroups = new Set();
  let isCompleted = true;

  activations.forEach(obj => {
    if (obj.campaignsToAdGroups.size === 0) {
      isCompleted = false;
      return;
    }

    Array.from(obj.campaignsToAdGroups.entries()).forEach(([key, value]) => {
      const keywordAdGroupsCount = value.keywordAdGroups.size;
      const videoAdGroupsCount = value.videoAdGroups.size;

      if (keywordAdGroupsCount > 0 || videoAdGroupsCount > 0) {
        uniqueItemsCount.add(key);
        value.keywordAdGroups.forEach(id => {
          uniqueKeywordAdGroups.add(id);
        });
        value.videoAdGroups.forEach(id => {
          uniqueVideoAdGroups.add(id);
        });
      } else {
        isCompleted = false;
      }
    });
  });

  const totalUniqueItemsCount = uniqueItemsCount.size;
  const totalKeywordAdGroupsCount = uniqueKeywordAdGroups.size;
  const totalVideoAdGroupsCount = uniqueVideoAdGroups.size;
  
  const colorStyle = isCompleted 
    ? "bg-badgeLightGreen text-badgeDarkGreen"
    : "bg-badgeLightBlue text-badgeDarkPurple"

  return (
    <tr
      key={persona}
      data-testid="act-mgr-persona-group-row"
      id="act-mgr-persona-group-row"
      className="border-b border-gray-200 cursor-pointer hover:bg-gray-50"
    >
      <td
        headers="activations-checkbox"
        className="relative w-12 "
      >
        <div className="flex gap-1 pl-3">
          <div onClick={() => togglePersona(persona)}>
            {expandedPersona?.has(persona) ? (
              <ChevronDownIcon
                height={20}
                width={20}
              />
            ) : (
              <ChevronRightIcon
                height={20}
                width={20}
              />
            )}
          </div>
          <SightlyCheckbox
            id="activationSelector"
            dataTestId="act-mgr-persona-group-checkbox"
            indeterminate={isPersonaGroupSelectAllIndeterminate(persona, activations)}
            checked={activations.every(({ activationItemId }) =>
              selectedActivations.get(persona)?.has(activationItemId)
            )}
            handleChange={(checked: boolean) => {
              handleSelectAllPersonaGroup(checked, persona, activations)
            }}
          />
        </div>
      </td>
      <td
        colSpan={5}
        className="w-auto h-14"
        onClick={() => togglePersona(persona)}
      >
        <div
          className="flex justify-between pl-3"
          data-testid="act-mgr-persona-group"
          id="act-mgr-persona-group"
        >
          <p className={`px-2 m-1 text-xs font-semibold rounded-md flex items-center ${colorStyle} h-8`}>
            {isCompleted && <CheckIcon
              className="h-[20px] text-badgeDarkGreen mr-3"
              aria-hidden="true"
            />}
            {persona}
          </p>
          <div className="flex justify-end p-1">
            <div className='flex flex-row mr-6 items-center'>
              <div className='w-5 mr-2'>
                {totalUniqueItemsCount !== 0 && <img src={BlueDot} alt="roundButtonClose" className="h-5" />}
              </div>
              <p className="w-[143px] h-5 mr-6 align-text-bottom self-center">
                {totalUniqueItemsCount !== 0 && totalUniqueItemsCount + " campaigns"}
              </p>
              <div className='w-5 mr-2'>
                {totalKeywordAdGroupsCount !== 0 && <img src={BlueDot} alt="roundButtonClose" className="mr-2 h-5" />}
              </div>
              <p className="w-[143px] h-5 mr-6 align-text-bottom self-center">
                {totalKeywordAdGroupsCount !== 0 && totalKeywordAdGroupsCount + " keyword ad group"}
              </p>
              <div className='w-5 mr-2'>
                {totalVideoAdGroupsCount !== 0 && <img src={BlueDot} alt="roundButtonClose" className="mr-2 h-5" />}
              </div>
              <p className="w-[150px] h-5 mr-6 align-text-bottom self-center">
                {totalVideoAdGroupsCount !== 0 && totalVideoAdGroupsCount + " video ad group"}
              </p>
            </div>
            <TrashIcon
              data-testid="act-mgr-action-delete-persona-btn"
              id="act-mgr-action-delete-persona-btn"
              onClick={() => {
                setPersonaToBeDeleted(() => persona)
              }}
              className="w-6 h-6 rounded hover:cursor-pointer hover:bg-slate-100"
            />
          </div>
        </div>
      </td>
    </tr>
  )
}
