import React from 'react';
import { Modal } from 'flowbite-react';
import YouTube, { YouTubeProps } from 'react-youtube';

type VideoContent = {
  title: string
  subTitle: string
  videoId: string
}

export type YoutubeVideoPlayerProps = {
  autoplay?: boolean
  content: VideoContent
  isOpen: boolean
  setIsOpen: Function
  dataTestId?: string
}

export function YoutubeVideoPlayer({ autoplay, content, isOpen, setIsOpen, dataTestId  }: YoutubeVideoPlayerProps) {

  /**
   * height and width values are picked to fill the '4xl' Modal body
   * without any black borders
   */
  const opts: YouTubeProps['opts'] = {
    height: 486,
    width: 864,
    playerVars: {
      rel: 0,      // Related videos will come from the same channel as the video that was just played.
      controls: 0, // Player controls do not display in the player.
    },
  };

  if (autoplay) {
    opts.playerVars.autoplay = 1
  }

  return (
    <Modal
      data-testid={dataTestId || "youtube-video-player"}
      size={'4xl'}
      show={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Modal.Header>
        <div className="px-2">
          <div className="font-semibold">
            {content.title}
          </div>
          <div className="text-base text-gray-400">
            {content.subTitle}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 rounded-b-lg">
        <YouTube
          videoId={content.videoId}
          opts={opts}
        />
      </Modal.Body>
    </Modal>
  );
}
