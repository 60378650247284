import React from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { competitorObjValidation } from '@/schemas/brandProfiles'
import toast from 'react-hot-toast'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import NameSearchField from './NameSearchField'
import { Event } from './EventSection'
import DatePickerField from './DatePickerField'
import { userAccountAxios } from '@/axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { getRelationships } from "./queries-api";

import RelationshipSelectForm from "./components/RelationshipSelectForm";
import WebsiteInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/WebsiteInputForm";
import WikiInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/WikiInputForm";
import TwitterInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/TwitterInputForm";
import ResetFieldsButton from "@/views/BrandProfiles/BrandProfile/components/Queries/components/ResetFieldsButton";
import { MyLocationGenerics } from '@/classes/utils'
import { products } from '@/staticData/products'

enum fields {
    'name' = 'name',
    'id' = 'id',
    'twitterProfileUrl' = 'twitterProfileUrl',
    'wikiUrl' = 'wikiUrl',
    'websiteUrl' = 'websiteUrl',
    'date' = 'date'
}

type IProps = {
    item: Event
    open: boolean
    handleClose: () => void
    disabled?: boolean
    type: 'edit' | 'add'
}

const EventModal = ({ item, open, handleClose, disabled, type }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch()
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = React.useState(false)
    const {
        accountId
    } = useSearch<MyLocationGenerics>()
    const {
        data: relationshipOptions
    } = useQuery(['queryRelationships'], getRelationships)

    const { register, handleSubmit, reset, control, formState, setValue, getValues, watch } = useForm<Event>({
        defaultValues: item,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(competitorObjValidation)
    })

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: Partial<Event>) => {
        setIsSaving(true)

        const _args: any = data
        delete _args.id
        delete _args.relationshipName

        _args.day = _args.date ? _args.date.getDate() : null
        _args.month = _args.date ? _args.date.getMonth() + 1 : null
        _args.year = _args.date ? _args.date.getFullYear() : null

        delete _args.date
        try {
            if (type === 'edit') {
                // disable isAIGenerated to false since it's being modified by the user
                _args.isAIGenerated = false
                const url = `/brand-profile/${brandProfileId}/events/${item.id}`
                await userAccountAxios.patch(url, _args)
                toast.success('Saved')
            } else {
                const url = `/brand-profile/${brandProfileId}/events`
                await userAccountAxios.post(url, _args)
                // to fetch the latest product quota
                await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.QUERIES))
                toast.success('Added')
                reset()
            }
            queryClient.invalidateQueries(rqKeys.brandProfileEvents(Number(brandProfileId)))
            queryClient.invalidateQueries(rqKeys.brandProfileBasic(Number(brandProfileId)))
        } catch (err) {
            toast.error('Error')
            setIsSaving(false)
        }
        setIsSaving(false)
    }

    const errors = formState.errors

    React.useEffect(() => {
        if (item && type === 'edit') {
            reset(item)
        }
    }, [item, type])

    const typeInfo = {
        options: relationshipOptions,
        humanName: 'Event'
    }

    const watchName = watch('name')

    return (
        <PortalModal
            hasClose={true}
            handleClose={_handleClose}
            open={open}
            footerButtons={[
                <SightlyButton
                    id="rename"
                    loading={isSaving}
                    disabled={!formState.isValid || !formState.isDirty}
                    text={type === 'edit' ? 'Save' : 'Add'}
                    handleClick={handleSubmit(onSubmit)}
                    type="primary"
                />
            ]}
        >
            <h4 style={{ marginBottom: 30, width: 376 }}>
                {type === 'edit' ? `Edit ${typeInfo?.humanName}` : `Add New ${typeInfo?.humanName}`}
            </h4>

            <div className="form-container">
                <NameSearchField
                    name={"Name"}
                    disabled={disabled}
                    handleSetWikiUrl={(val: never) =>
                        setValue(fields.wikiUrl, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    handleSetName={(val: never) =>
                        setValue(fields.name, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    nameValue={watchName}
                    error={errors?.name?.message}
                />

                <WikiInputForm
                    control={control}
                    error={errors?.wikiUrl?.message}
                    name={fields.wikiUrl}
                    register={register}
                    disabled={disabled}
                />

                <TwitterInputForm
                    control={control}
                    error={errors?.twitterProfileUrl?.message}
                    name={fields.twitterProfileUrl}
                    register={register}
                    disabled={disabled}
                />

                <WebsiteInputForm
                    control={control}
                    error={errors?.websiteUrl?.message}
                    name={fields.websiteUrl}
                    register={register}
                    disabled={disabled}
                />

                <RelationshipSelectForm
                    control={control}
                    disabled={disabled}
                />

                <div>
                    <div className="input-label">Date</div>
                    <DatePickerField
                        dataTestId="date-picker"
                        disabled={disabled}
                        defaultValue={getValues().date || undefined}
                        handleSet={(val: never) => {
                            setValue(fields.date, val, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                    />
                </div>

                {type === 'add' && formState.isDirty && (
                    <ResetFieldsButton
                        reset={reset}
                    />
                )}
            </div>

            <style>{`
				.form-container {
					display: grid;
					gap: 24px;				
					grid-template-columns: repeat(auto-fit);			
				}		
			`}</style>
        </PortalModal>
    )
}

export default EventModal
