import React from 'react'

export enum ChipSize {
    SMALL,
    MEDIUM
}

interface IProps {
    text: string
    size?: ChipSize,
    dataTestId?: string
    onClick?: any,
    textColor?: string,
    borderColor?: string,
    bgColor?: string,
    pointer?: string
}

const Chip: React.FunctionComponent<IProps> = React.memo(
    ({ text, size, dataTestId, onClick, textColor = 'text-purple-600', borderColor = 'border-purple-600', bgColor = 'bg-purple-100', pointer = 'none' }) => {
        switch (size) {
            case ChipSize.SMALL:
                return (
                    <span onClick={onClick}  data-testid={dataTestId} className={`line-clamp-1 px-2 py-0.5 text-[0.6rem] ${textColor} ${bgColor} border ${borderColor} rounded-full font-bold h-fit ${pointer}`}>
                      {text}
                    </span>
                  )
            case ChipSize.MEDIUM:
            default:
                return (<span data-testid={dataTestId} onClick={onClick}
                    className={`line-clamp-1 px-2 py-0.5 text-xs ${textColor} ${bgColor} border ${borderColor} rounded-full font-bold h-fit ${pointer}`}
                >
                    {text}
                </span>)
        }   
    }
)

export default Chip
