import React from 'react';
import { ReactComponent as NoNotifications } from '@/assets/img/noNotifications.svg';
import SightlyButton from '@/components/Sightly/SightlyButton';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import useUser from '@/hooks/useUser';
import { products } from '@/staticData/products';
import { perms } from '@/staticData/permissions';
import Tooltip from '@/components/TailwindTooltip';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import { useNavigate } from '@tanstack/react-location';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const NoBrandProfiles = () => {
  const { userPermissions, userProducts, subscriptionQuota } = useUser();

  const navigate = useNavigate();

  const { createBrandProfileMutation } = useBrandProfiles({ submittedOnly: false });

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.BRAND_PROFILES)) {
      const quota = subscriptionQuota.get(products.BRAND_PROFILES)
      quotaIsAllowed = quota.isAllowed
  }
  let disableCreateButton;

  disableCreateButton = !userCanPermissionProductQuota({requiredPermissionValue: perms.BRAND_PROFILE_CREATE, userPermissions: userPermissions, 
    userProducts: userProducts,requiredProductValue: products.BRAND_PROFILES, checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK, quota_is_allowed: quotaIsAllowed}) || createBrandProfileMutation.isLoading;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <div style={{ width: 500, justifyContent: 'center', textAlign: 'center' }}>
        <NoNotifications />
        <h4 style={{ marginBottom: 48 }}>
          We currently have no brand profiles submitted and processed for this account.
        </h4>
        <Tooltip
          disabled={userCanPermissionProductQuota({requiredPermissionValue: perms.BRAND_PROFILE_CREATE, userPermissions: userPermissions, 
            userProducts: userProducts,requiredProductValue: products.BRAND_PROFILES, checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK, quota_is_allowed: quotaIsAllowed})}
          content={<ProductLimitedAccessInfo />}
        >
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <SightlyButton
              disabled={disableCreateButton}
              id="createNewProfile"
              text="Create New Profile"
              handleClick={() =>{navigate({to:'/app/brandProfiles/new'})}}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default NoBrandProfiles;
