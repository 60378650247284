import {
	SET_EVERGREEN_CHANNELS,
  SET_EVERGREEN_CHANNEL_COUNT,
	SET_EVERGREEN_CHANNELS_LOADING,
  SET_EVERGREEN_CHANNELS_PAGE,
  SET_EVERGREEN_CHANNELS_IAB_CATEGORIES,
  SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE,
  EVERGREEN_CHANNEL_ARCHIVING,
  EVERGREEN_CHANNEL_TO_ARCHIVE,
  EVERGREEN_CHANNEL_SAVING
} from '../../action-types/admin/evergreenChannels'

export function evergreenChannels(state = [], action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNELS:
			return action.evergreenChannels
    case EVERGREEN_CHANNEL_TO_ARCHIVE:
      let newState = [
        ...state.map((channel) =>
          channel.channelId === action.channelId
            ? { ...channel, archived: true }
            : channel
        )
      ]
      return newState
		default:
			return state
	}
}

export function evergreenChannelCount(state = null, action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNEL_COUNT:
			return action.evergreenChannelCount
		default:
			return state
	}
}

export function evergreenChannelsLoading(state = false, action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNELS_LOADING:
			return action.evergreenChannelsLoading
		default:
			return state
	}
}

export function currentEvergreenChannelsPage(state = 1, action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNELS_PAGE:
			return action.evergreenChannelsPage
		default:
			return state
	}
}

export function evergreenChannelIabCategories(state = [], action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNELS_IAB_CATEGORIES:
			return action.evergreenChannelsIabCategories
		default:
			return state
	}
}

export function evergreenChannelsHasNextPage(state = true, action) {
	switch (action.type) {
		case SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE:
			return action.evergreenChannelsHasNextPage
		default:
			return state
	}
}

export function evergreenChannelArchiving(state = '', action) {
	switch (action.type) {
		case  EVERGREEN_CHANNEL_ARCHIVING:
			return action.evergreenChannelArchiving
		default:
			return state
	}
}

export function evergreenChannelSaving(state = false, action) {
	switch (action.type) {
		case EVERGREEN_CHANNEL_SAVING:
			return action.evergreenChannelSaving
		default:
			return state
	}
}
