import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { TargetedActivationListType } from '@/views/TikTok/ActivationManager/types'

export const targetedActivationListReducer = (
  targetedActivationList: SerializableMap<string, TargetedActivationListType>,
  action: TargetedActivationListActionType
) => {
  switch (action.type) {
    case TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN: {
      const { hashtagId, nextCampaignId, prevCampaignId } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      const targetActivation = nextState.get(hashtagId)

      if (targetActivation) {
        if (!targetActivation.campaignsToAdGroups.has(nextCampaignId)) {
          targetActivation.campaignsToAdGroups.delete(prevCampaignId)
          targetActivation.campaignsToAdGroups.set(nextCampaignId, new SerializableSet<number>())
        }
      }
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS: {
      const { adGroups, campaignId, hashtagId } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      const prevCampaignsToAdGroups = nextState.get(hashtagId)?.campaignsToAdGroups
      if (!prevCampaignsToAdGroups) return nextState

      prevCampaignsToAdGroups.set(campaignId, new SerializableSet<number>(adGroups ?? []))

      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATIONS: {
      const { activationsToBeDeleted } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      activationsToBeDeleted.forEach((id) => nextState.delete(id))
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN: {
      const { campaignId, hashtagId } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      const prevCampaignsToAdGroups = nextState.get(hashtagId)?.campaignsToAdGroups
      if (!prevCampaignsToAdGroups) return nextState

      prevCampaignsToAdGroups.delete(campaignId)

      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS: {
      const { selectedActivations, selectedCampaignsToAdGroups } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      selectedActivations.forEach((hashtagId) => {
        const prevActivationGroup = targetedActivationList.get(hashtagId)
        if (prevActivationGroup) {
          //remove campaigns with empty adGroups set
          const filteredSelectedCampaignsToAdGroups = Array.from(selectedCampaignsToAdGroups.entries()).filter(
            ([campaignId, adGroups]) => adGroups.size > 0
          )
          prevActivationGroup.campaignsToAdGroups = new SerializableMap(filteredSelectedCampaignsToAdGroups)
        }
      })
      return nextState
    }

    default: {
      return targetedActivationList
    }
  }
}

export enum TARGETED_ACTIVATION_ACTIONS {
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  ADD_AD_GROUPS = 'ADD_AD_GROUPS',
  REMOVE_ACTIVATIONS = 'REMOVE_ACTIVATION',
  REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN',
  SET_DEFAULT = 'SET_DEFAULT',
  BULK_ASSIGN_CAMPAIGN_ADGROUPS = 'BULK_ASSIGN_CAMPAIGN_ADGROUPS'
}

type NewCampaignPayload = {
  hashtagId: string
  nextCampaignId: number
  prevCampaignId: number
}

type NewAdGroupsPayload = {
  hashtagId: string
  campaignId: number
  adGroups: number[] | undefined
}

type RemoveCampaignPayload = {
  hashtagId: string
  campaignId: number
}

type RemoveActivationPayload = {
  activationsToBeDeleted: string[]
}

type DefaultActivationPayload = {
  targetedActivationList: SerializableMap<string, TargetedActivationListType>
}

type BulkAssignCampaignAdGroupsPayload = {
  selectedActivations: Set<string>
  selectedCampaignsToAdGroups: SerializableMap<number, SerializableSet<number>>
}

export type TargetedActivationListActionType =
  | { type: TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN; payload: NewCampaignPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS; payload: NewAdGroupsPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN; payload: RemoveCampaignPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATIONS; payload: RemoveActivationPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.SET_DEFAULT; payload: DefaultActivationPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS; payload: BulkAssignCampaignAdGroupsPayload }
