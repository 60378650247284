import React from 'react'
import { Icon } from 'rsuite'
import blogsAndForums from '@/assets/img/platforms/blogs-and-forums.svg'
import reviews from '@/assets/img/platforms/reviews.svg'
import youtube from '@/assets/img/platforms/youtube.svg'
import facebook from '@/assets/img/platforms/facebook.svg'
import instagram from '@/assets/img/platforms/instagram.svg'
import reddit from '@/assets/img/platforms/reddit.svg'
import tiktok from '@/assets/img/platforms/tiktok.svg'
import x from '@/assets/img/platforms/x.svg'
import news from '@/assets/img/platforms/news.svg'

const platforms: Record<string, { name: string; url: string; icon: Function }> = {
  'blogs & forums': {
    name: 'blogs & forums',
    url: '',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={blogsAndForums}
        alt="Blogs & Forums"
      />
    )
  },
  reviews: {
    name: 'reviews',
    url: '',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={reviews}
        alt="Reviews"
      />
    )
  },
  youtube: {
    name: 'youtube',
    url: 'https://www.youtube.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={youtube}
        alt="YouTube"
      />
    )
  },
  facebook: {
    name: 'facebook',
    url: 'https://www.facebook.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={facebook}
        alt="Facebook"
      />
    )
  },
  instagram: {
    name: 'instagram',
    url: 'https://www.instagram.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={instagram}
        alt="Instagram"
      />
    )
  },
  reddit: {
    name: 'reddit',
    url: 'https://www.reddit.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={reddit}
        alt="Reddit"
      />
    )
  },
  tiktok: {
    name: 'tiktok',
    url: 'https://www.tiktok.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={tiktok}
        alt="TikTok"
      />
    )
  },
  x: {
    name: 'x',
    url: 'https://x.com',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={x}
        alt="X"
      />
    )
  },
  news: {
    name: 'news',
    url: '',
    icon: (width = '100px', height = '100px') => (
      <img
        style={{ width: width, height: height }}
        src={news}
        alt="News"
      />
    )
  }
}

interface IProps {
  platformName: string
  width?: string
  height?: string
  link?: string
  defaultLink?: boolean
}

const PlatformIcon: React.FunctionComponent<IProps> = React.memo(
  ({ platformName, width, height, link, defaultLink }) => {
    const platform = platforms[platformName.toLowerCase()]

    const iconWidth = width ? width : height ? 'auto' : '1rem'
    const iconHeight = height ? height : width ? 'auto' : '1rem'

    if (platform) {
      const platformIcon = platform.icon(iconWidth, iconHeight)
      if (link || defaultLink) {
        return (
          <a
            href={link ? link : platform.url}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
          >
            {platformIcon}
          </a>
        )
      } else {
        return platformIcon
      }
    } else {
      return (
        <Icon
          icon="image"
          alt={platformName}
          style={{ width: iconWidth, height: iconHeight }}
        />
      )
    }
  }
)

export default PlatformIcon;
