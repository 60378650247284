import React from 'react'
/**
 * Data picker footer component.
 * @param {Object} props - Component props.
 * @param {number} props.rowCount - The number of rows/items in the data picker.
 * @param {string} [props.suffixForCount] - Suffix to display after the row count.
 * @param {() => void} props.handleSelectAll - Function called when the "Select All" button is clicked.
 * @param {() => void} props.handleClear - Function called when the "Clear" button is clicked.
 * @returns {JSX.Element} The rendered component.
 */
const DataPickerFooter = (props: {
  rowCount: number
  suffixForCount?: string
  handleSelectAll: () => void
  handleClear: () => void
}) => {
  return (
    <section
      data-testid="data-picker-footer"
      id="pendo-data-picker-footer"
      className="flex justify-between text-xs font-semibold text-sightlyBlue"
    >
      <div
        className="px-4 py-2 cursor-pointer"
        data-testid="data-picker-footer-select-all-btn"
        id="pendo-data-picker-footer-select-all-btn"
        onClick={props.handleSelectAll}
      >
        Select All ({props.rowCount} {props.suffixForCount ?? ''})
      </div>
      <div
        className="px-4 py-2 cursor-pointer"
        data-testid="data-picker-footer-clear-btn"
        id="pendo-data-picker-footer-clear-btn"
        onClick={props.handleClear}
      >
        Clear
      </div>
    </section>
  )
}

export default DataPickerFooter
