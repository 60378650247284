import { useQuery } from '@tanstack/react-query';
import { keywordsObjValidation } from '@/schemas/brandProfiles';
import { userAccountAxios } from '@/axiosInstances';
import { rqKeys } from '@/ReactQueryKeyFactory';

const getBrandProfileKeywords = async (brandProfileId: number) => {
    let url = `/brand-profile/${brandProfileId}/keywords`
    const result = await userAccountAxios.get(url)

    if (result.status === 200) {
        if (result.data.length > 0) {
            keywordsObjValidation.validate(result.data).catch(function(err) {
                console.log(err.name, err.errors)
                console.error(
                    ' we received different data from the api than expected while fetching brand profile keywords, see console log for more details'
                )
            })
        }
    }

    return result.data
}

export default function useBrandProfileKeywords(brandProfileId: number) {
    return useQuery(rqKeys.brandProfileKeywordsKey(brandProfileId), () => getBrandProfileKeywords(brandProfileId), {
        enabled: !!brandProfileId
    })
}
