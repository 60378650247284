import { useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { userAccountAxios } from '@/axiosInstances'
import { logError } from '@/utils'
import useUser from '@/hooks/useUser'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { products } from '@/staticData/products'
import { AxiosResponse } from 'axios'
import { EventAIGenerationType } from './EventAIGenerationForm'
import { rqKeys } from './queries-rqKeys'
import { SocialAIGenerationType } from './SocialAIGenerationForm'

const generateSocial = async (args: { brandProfileId: number; payload: SocialAIGenerationType }) => {
  const url = `/brand-profile/${args.brandProfileId}/social-and-environmental/ai-generator`
  return await userAccountAxios.post(url, args.payload)
}

const useSocialCauses = (brandProfileId: number) => {
  const queryClient = useQueryClient()
  const { currentAccount } = useUser()

  const postProcess = () => {
    queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileSocial(Number(brandProfileId)) })
    queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
    queryClient.fetchQuery(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.QUERIES))
    queryClient.invalidateQueries(mainRqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.AI_CREDITS))
  }

  const handleSuccess = async (response: AxiosResponse<any>) => {
    if (response.data && response.data.failure.length > 0) {
      toast.error(`Error occured for ${response.data.failure.length} item/s`)
      return
    }
    toast.success('Social Causes Response(s) Generated')
    postProcess()
    
  }

  const handleError = async (error: unknown) => {
    toast.error('Something went wrong. Please try again later.')
    logError(error)
    postProcess()
  }
  
  const brandQueryMutation = useMutation(generateSocial, {
    mutationKey: rqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
    onSuccess: async (response) => {
      await handleSuccess(response)
    },
    onError: async (error) => {
      await handleError(error)
    }
  })

  const isMutatingBrandQuery = useIsMutating({
    mutationKey: rqKeys.brandProfileQueryMutationKey(currentAccount?.accountId),
  })

  return {
    brandQueryMutation,
    isMutatingBrandQuery,
    handleError
  }
}

export default useSocialCauses
