import React from 'react';
import useBoards, { Board } from '@/views/Discover/Boards/useBoards';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import NoBrandProfiles from '@/components/NoBrandProfiles';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import useUser from '@/hooks/useUser';
import { location } from '@/RouteContainer';
import { LoadingPage } from '@/components/LoadingPage';
import { InfoPage } from '@/components/InfoPage';
import { BoardGenerateAI } from '@/views/Discover/Boards/components/BoardGenerateAI';
import Tabs from '@/components/Tabs/Tabs';
import myBoardsIcon from '@/assets/img/boardTemplate/myBoardsIcon.svg';
import boardTemplatesIcon from '@/assets/img/boardTemplate/boardTemplatesIcon.svg';
import customBoardTemplate from '@/assets/img/boardTemplate/custom_board_template.svg';
import BoardSelectSearch from '@/views/Discover/Boards/components/BoardSelectSearch';
import { MyBoardItem } from '@/views/Discover/Boards/components/MyBoardItem';
import useBoardCreator from './useBoardCreator';
import { ItemDetail, ItemTypes } from '@/views/Discover/Boards/types';
import { NewCustomBoardCard } from '@/views/Discover/Boards/components/NewCustomBoardCard';
import { BoardsCreatedGauge } from '@/views/Discover/Boards/components/BoardsCreatedGauge'
import { BoardItemDescription } from '@/views/Discover/Boards/components/BoardItemDescription';
import { PATH_MOMENTS_V2 } from '@/routes';
import { GENERAL_BOARD_DEFAULT_TYPES } from '@/views/BrandProfiles/constants'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';


const MY_BOARDS = { name: 'My Boards', icon: myBoardsIcon, dataTestId: "my_boards_tab" }
const BOARD_TEMPLATES = { name: 'Board Templates', icon: boardTemplatesIcon, dataTestId: "board_templates_tab" }
const TAB_TITLES_FULL = [MY_BOARDS, BOARD_TEMPLATES];
const TAB_TITLES_REDUCE = [MY_BOARDS];

const Dashboard = () => {
  const navigate = useNavigate<MyLocationGenerics>()
  const { currentAccount, products, perms, userPermissions, userProducts } = useUser()
  const currentAccountId = currentAccount?.accountId
  const { data: brandProfiles, currentBrandProfile } = useBrandProfiles({
    submittedOnly: true
  })

  const { brandProfileId } = useSearch<MyLocationGenerics>()

  const { boards, handleDelete, handleRename, boardsIsLoading, boardsQuotaTally } = useBoards()

  const [activeTab, setActiveTab] = React.useState(MY_BOARDS.name);

  const TAB_TITLES =userCanPermissionProductQuota({
    requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
    requiredProductValue: products.BOARDS,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
  }) ? TAB_TITLES_FULL : TAB_TITLES_REDUCE;

  const [tabTitles, setTabTitles] = React.useState(TAB_TITLES);

  const boardCreator = useBoardCreator({
    brandProfileId,
    boardType: 'general'
  })

  const listOfAgregates = boardCreator.aggregates.find(a => a.name === "Suggested Boards")?.items;

  React.useEffect(() => {
    if (currentBrandProfile) {
      navigate({
        search: (search) => ({
          ...search,
          brandProfileId: currentBrandProfile?.brandProfileId
        })
      })
    }
  }, [currentBrandProfile, location.current.search])

  if (brandProfiles?.length === 0) {
    return <NoBrandProfiles />
  }

  if (boardsIsLoading) {
    return <LoadingPage message="Fetching boards" />
  }

  if (!boards || (boards.length === 0 && !userCanPermissionProductQuota({
    requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
    requiredProductValue: products.BOARDS,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
  }))) {
    return (
      <InfoPage message="We currently don’t have any boards created and processed for this Brand Profile." />
    )
  }

  const getImageFrom = (board: {
    type: string,
    defaultType: string | null,
    selectedItems: string[],
    operator: string,
  }) => {
    if (board.type === 'custom' || board.type === 'naturalLanguage') {
      return customBoardTemplate
    } else {
      return listOfAgregates?.find(a => a.defaultType === board.defaultType!)?.img || ""
    }
  }

  const handleNewBoardFromTemplate = async (id: number) => {
      boardCreator.handleSelectItem(({
          itemType: ItemTypes.aggregate,
          name: "Suggested Boards",
          id: id,
      }));
      await boardCreator.createBoardFromTemplate();
  }

  const handleView = (boardId: number) => {
      navigate({
          to: PATH_MOMENTS_V2,
          search: (old) => ({
              ...old,
              boardIds: [boardId],
              brandProfileId: old?.brandProfileId,
              accountId: currentAccountId,
              timeRange: 1,
              searchTerm: '',
              sortBy: 'ranking',
              boardsView: 'My Boards'
          })
      })
  }

  const getTemplateDescription = ( description: string ) =>
    <h3 className='text-xs text-[#333D47] font-medium whitespace-wrap overflow-ellipsis'>
      {description}
    </h3>;

  return (
    <div className="px-12 pt-8 pb-12">
      <div className='flex flex-row w-full justify-between items-center' >
        <div className="font-semibold text-2xl text-[#333D47]">
          Manage Boards
        </div>
        <BoardsCreatedGauge
          tally={boardsQuotaTally}
          backgroundColor="#f5f5f5"
        />

      </div>

      <BoardGenerateAI boardMaxOut={boardsQuotaTally?.isMaxedOut}/>

      <div className='flex flex-row justify-between items-center mt-6'>
        <Tabs
          width={'100%'}
          marginRight={40}
          active={activeTab}
          onChange={(active: string) => {
            setActiveTab(active)
          }}
        >
          {tabTitles.map((tab) => {
            return <div className="flex flex-row items-center" key={tab.name} data-testid={tab.dataTestId}>
              <img className='mr-2 w-6 h-6' src={tab.icon} />
              <div>{tab.name}</div>
            </div>
          })}
        </Tabs>

        <div className='w-96'>
          <BoardSelectSearch
            value={brandProfileId}
            options={brandProfiles}
            label={"Brand Profile"}
            labelKey="brandProfileName"
            valueKey="brandProfileId"
            onChange={(brandProfileId: number) => {
              navigate({
                search: (prev) => ({
                  ...prev,
                  brandProfileId
                })
              })
            }}
          />
        </div>
      </div>

      {activeTab === MY_BOARDS.name && (
        <div className={`grid grid-cols-[repeat(3,_minmax(310px,_1fr))] grid-flow-row gap-x-14 gap-y-8 mt-6 ${activeTab === MY_BOARDS.name && "data-active-my-boards"}`}>
          {boards.map((board: Board) => (
            <MyBoardItem
              key={board.id}
              id={board.id}
              name={board.name}
              type={board.type}
              brandProfileId={board?.brandProfileId}
              img={getImageFrom(board)}
              description={BoardItemDescription(board, listOfAgregates)}
              updatedByName={board.updatedByName}
              updatedDate={board.updatedDate}
              showOptions
              handleDelete={handleDelete}
              handleRename={handleRename}
              boardCreator={boardCreator}
              activateBlur
              blurButtonName={"View"}
              handleBlurButtonOnClick={() => handleView(board.id)}
            />
          ))}
        </div>
      )}

      {activeTab === BOARD_TEMPLATES.name && userCanPermissionProductQuota({
        requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
        requiredProductValue: products.BOARDS,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
      }) && (
        <div className={`grid grid-cols-[repeat(3,_minmax(310px,_1fr))] grid-flow-row gap-x-14 gap-y-8 mt-6 ${activeTab === BOARD_TEMPLATES.name && "data-active-board-templates"}`}>
          {listOfAgregates?.map((aggregate: ItemDetail) => (
            <MyBoardItem
              key={aggregate.id}
              id={aggregate.id}
              name={aggregate.name}
              img={aggregate.img!}
              description={getTemplateDescription(aggregate.description)}
              handleDelete={handleDelete}
              handleRename={handleRename}
              activateBlur
              blurButtonName={"Create"}
              handleBlurButtonOnClick={() => handleNewBoardFromTemplate(aggregate.id)}
              boardMaxOut={boardsQuotaTally?.isMaxedOut}
              boardCreator={boardCreator}
              disableBlurButton={aggregate.alwaysDisabled}
            />
          ))}
          <NewCustomBoardCard
              boardMaxOut={boardsQuotaTally?.isMaxedOut}
          />
        </div>
      )}
    </div >
  )
}

export default Dashboard
