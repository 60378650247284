import React from 'react'
import { ModalFooterProps } from './types'

const NavButton = ({ label, handleClick, disabled }: { label: string; handleClick: Function; disabled: boolean }) => {
  return (
    <div
      data-testid={`moment-insights-modal-footer-${label}-button`}
      onClick={() => handleClick()}
      className={`select-none w-24 h-10 text-center p-2 border border-slate-300 font-semibold rounded-full text-sm
    ${disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
    >
      {label}
    </div>
  )
}

export default function ModalFooter({ handleFooterNav, activeIndex, maxLength }: ModalFooterProps) {
  const [disabledPrevButton, setDisabledPrevButton] = React.useState(false)
  const [disabledNextButton, setDisabledNextButton] = React.useState(false)
  React.useEffect(() => {
    setDisabledPrevButton(activeIndex <= 0)
    setDisabledNextButton(activeIndex >= maxLength)
  }, [activeIndex])
  return (
    <div
      data-testid="moment-insights-modal-footer"
      className="flex flex-row w-full gap-4 -mt-6"
    >
      <NavButton
        label="< Prev"
        handleClick={() => {
          handleFooterNav('prev')
        }}
        disabled={disabledPrevButton}
      />
      <NavButton
        label="Next >"
        handleClick={(setDisabled: Function) => {
          handleFooterNav('next')
        }}
        disabled={disabledNextButton}
      />
    </div>
  )
}
