import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Controller } from 'react-hook-form'
import { FormLabel } from './SightlyFormLabel'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { FormError } from './SightlyFormError'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface IProps {
    label?: string
    options: any
    placeholder?: string
    width?: number
    labelKey: string
    valueKey: string
    maxHeight?: number
    backgroundColor?: string
    disabled?: boolean
    id: string
    error?: string
    hasBorder?: boolean
    loading?: boolean
    name: string
    control: any
    triggerSave?: any
    required?: boolean
    dataTestId?: string
}

const SightlySelectForm = ({
    label,
    options,
    name,
    placeholder,
    width,
    labelKey,
    valueKey,
    disabled,
    id,
    error,
    loading,
    control,
    triggerSave,
    required,
    dataTestId
}: IProps) => {
    return (
        <div
            data-testid={dataTestId}
            className='w-full text-left'
        >
            {loading ? (
                <Skeleton
                    height={34}
                    width={width}
                />
            ) : (
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        const onChangeExtended = (data: any) => {
                            field.onChange(data)
                            triggerSave && triggerSave()
                        }

                        const selectedItem = React.useMemo(() => {
                            if (!options || !field.value) {
                                return {}
                            }
                            return options.filter((option: any) => option[valueKey] == field.value)[0] || {}
                        }, [field.value, options])

                        return (
                            <>
                            <Listbox
                                {...field}
                                onChange={onChangeExtended}
                                disabled={loading || disabled}
                            >
                                {({ open }) => (
                                    <div className="block">
                                        {label && (
                                            <FormLabel
                                                id={id}
                                                text={label}
                                                required={required}
                                            />
                                        )}
                                        <div
                                            className="relative"
                                            style={{ width: width || undefined }}
                                        >
                                            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default form-select focus:border-sightly-blue focus:ring-sightly-blue sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-grey-50 disabled:text-gray-500">
                                                <span
                                                    className={`block truncate ${
                                                        !selectedItem[labelKey] && placeholder && 'text-slate-500'
                                                    }`}
                                                >
                                                    {selectedItem[labelKey] || placeholder}
                                                </span>
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <ChevronDownIcon
                                                        className="w-5 h-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base -translate-y-full bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {options &&
                                                        options.map((option: any) => (
                                                            <Listbox.Option
                                                                key={option[valueKey]}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'text-white bg-sightly-blue-light'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={option[valueKey]}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {option[labelKey]}
                                                                        </span>

                                                                        {selected ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-slate-500'
                                                                                        : 'text-slate-400',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="w-5 h-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </div>
                                )}
                            </Listbox>
                            <FormError
                                id={id}
                                error={error}
                            />
                            </>
                        )
                    }}
                />
            )}
        </div>
    )
}

export default SightlySelectForm
