import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { SubscriptionQuota } from '@/hooks/subscription/useSubscription';
import { ProductQuotaTally } from './types'

export * from './types'

/**
 *
 * @param {accountId} accountId for which quota is to be checked
 * @param {productName} ProductName
 *
 * @returns a tally of the current product usage Counter
 */

export const useProductUsage = (props: { accountId?: number; productName: string }) => {
  const { accountId, productName } = props
  const queryClient = useQueryClient()
  const productQuotaData = queryClient.getQueryData<any>(rqKeys.subscriptionQuotaQueryKey(accountId, productName))
  const productQuotaTally: ProductQuotaTally = React.useMemo(() => {
    if (productQuotaData?.data) {
      const { currentProductCount, productQuota, productBalance }: SubscriptionQuota = productQuotaData?.data
      const tally = { currentProductCount, productQuota, isMaxedOut: false, productBalance }
      if (Math.abs(currentProductCount) > productQuota) tally.productQuota = currentProductCount
      //check for max out
      if (tally.currentProductCount === tally.productQuota) tally.isMaxedOut = true
      return tally
    }
  }, [productQuotaData])

  return {
    productQuotaTally
  }
}
