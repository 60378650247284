import React from 'react';
import Loader from 'rsuite/lib/Loader';

interface IProps {
  title: string
  isLoading?: boolean;
  previewsCount: number;
  previewsCountLongRange: number;
  previewsCountMediumRange: number;
}

const CountSection: Function = ({count, title}: {count:number, title:string}) => {
  return (
    <div className="flex max-w-[33%] items-center flex-nowrap">
      <span className="text-sightlyPurple text-2xl font-bold mr-1 align-middle">
        {count ?? "0"}
      </span>
      <p className="text-sm font-semibold ml-1 align-middle whitespace-nowrap text-ellipsis overflow-hidden">
        {title}
      </p>
    </div>
  )
}

export const TotalPreviewsCard: Function = ({
    title,
    isLoading,
    previewsCount,
    previewsCountLongRange,
    previewsCountMediumRange,
  }: IProps) => {

  if (isLoading) {
    return <div className="flex justify-center items-center grow">
      <Loader size="lg"/>
    </div>
  }

  return (
    <div data-testid="nl-preview-total-preview-module" className="flex flex-row w-full items-center justify-center">
      <div
        data-testid="nl-preview-total-preview-count"
        className="mb-3 bg-white border border-solid border-slate-200 rounded-md shadow-lg pt-5 py-8 px-10 w-9/12 h-[132px] mr-8"
      >
        <h5 className="mb-5 text-2xl text-[#2E4052]">
          {title}
        </h5>

        <div className="flex flex-row justify-between h-8 ">
          <CountSection count={previewsCount} title={'in the last week'} />

          <div className="border-l border-[#D9D9D9] h-8 mx-2"></div>
          <CountSection count={previewsCountMediumRange} title={'in the last month'} />

          <div className="border-l border-[#D9D9D9] h-8 mx-2"></div>
          <CountSection count={previewsCountLongRange} title={'in the last two months'} />
        </div>

      </div>
    </div>
  )
}
