import React from 'react';

interface IProps {
    selectedTabIndex: number
    setSelectedTabIndex: Function
}

interface Tab {
  id: string
  key: string
  title: string
}

const tabs: Tab[] = [
  {
    id: 'pendo-moments-tab',
    key: 'moments',
    title: 'Moments'
  },
  {
    id: 'pendo-conversations-tab',
    key: 'narratives',
    title: 'Narratives'
  }
]

export default function PreviewPageTabs({ selectedTabIndex, setSelectedTabIndex }: IProps) {
  const handleClick = (index:number) => setSelectedTabIndex(index);
  const getActiveTabStyles = (tabIndex:number) => selectedTabIndex === tabIndex ? 'border-sightlyPurple font-semibold' : 'border-transparent';

  return (
    <div data-testid="anticipation-board-header-tabs" className="flex h-16 px-6 pb-2 gap-8 pointer">

      {tabs.map((tab:Tab, index:number) => (
        <button
          id={tab.id}
          key={tab.key}
          className={`flex flex-col justify-center border-b-4 ${getActiveTabStyles(index)}`}
          onClick={() => handleClick(index)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  )
}
