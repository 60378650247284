import { SET_UPLOADED_LIST } from '../../action-types/engage/lists'

export function uploadedList(state = [], action) {
	switch (action.type) {
		case SET_UPLOADED_LIST:
			return action.uploadedList
		default:
			return state
	}
}
