import { BRAND_PROFILE_RELATIONSHIP_LOOKUP } from './constants'

export const rqKeys = {
  brandProfileQuery: (queryType: string, brandProfileId: number) => [queryType, brandProfileId] as const,
  aspirationalBrandMutationKey: (accountId: number | undefined) => ['aspirationalBrandMutation', accountId] as const,
  queryTypeOneTargetMutationKey: (queryType: string, accountId: number | undefined) =>
    [queryType, BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET, accountId] as const,
  queryTypeOneMonitorMutationKey: (queryType: string, accountId: number | undefined) =>
    [queryType, BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR, accountId] as const,
  queryTypeOneBlockMutationKey: (queryType: string, accountId: number | undefined) =>
    [queryType, BRAND_PROFILE_RELATIONSHIP_LOOKUP.BLOCK, accountId] as const,
  eventTargetMutationKey: (accountId: number | undefined) =>
    ['brandEvent', BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET, accountId] as const,
  eventMonitorMutationKey: (accountId: number | undefined) =>
    ['brandEvent', BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR, accountId] as const,
  socialTargetMutationKey: (accountId: number | undefined) =>
    ['brandSocial', BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET, accountId] as const,
  socialMonitorMutationKey: (accountId: number | undefined) =>
    ['brandSocial', BRAND_PROFILE_RELATIONSHIP_LOOKUP.MONITOR, accountId] as const,
  socialBlockMutationKey: (accountId: number | undefined) =>
    ['brandSocial', BRAND_PROFILE_RELATIONSHIP_LOOKUP.TARGET, accountId] as const,
  brandProfileQueryMutationKey: (accountId: number | undefined) =>
    ['brandQuery', accountId] as const,
}
