import React from 'react'
import MomentKeyword from '@/views/Discover/Moments/v2/MomentModal/MomentKeyword'
import LikeDislikeButton from '@/components/LikeDislikeButton'
import { ClusterType, MomentKeywordType, MomentKeywordsType } from '@/views/Discover/Moments/v2/types'
import { useIsMutating } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import { RecActionFeedbackProps } from '@/views/Discover/Moments/v2/api'
import { Loader } from 'rsuite'

interface MomentKeywordsProps {
  keywords: MomentKeywordsType | undefined
  moment: ClusterType
  isLoading: boolean
  postTrendKeywordsFeedback: (args: RecActionFeedbackProps) => void
}

const BLOCK = 'block'
const TARGET = 'target'
const keywordTabs = [
  {
    label: 'Target',
    id: TARGET
  },
  {
    label: 'Block',
    id: BLOCK
  }
]

const MomentKeywords = ({ keywords, moment, isLoading, postTrendKeywordsFeedback }: MomentKeywordsProps) => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const isMutating = useIsMutating()
  const [selectedTab, setSelectedTab] = React.useState(moment.action === BLOCK ? BLOCK : TARGET)
  const selectedTabKeywords = selectedTab === BLOCK ? keywords?.blockKeywords ?? [] : keywords?.targetKeywords ?? []

  return (
    <div
      className="flex flex-col"
      data-testid="moment-modal-keywords-body"
    >
      {isLoading && (
        <div
          className="flex justify-around h-full"
          data-testid="moment-modal-keywords-loader"
        >
          <Loader />
        </div>
      )}
      <div
        className="flex gap-2"
        data-testid="moment-modal-keywords-content"
      >
        {!isLoading &&
          keywordTabs.map((tab) => (
            <div
              data-testid="moment-modal-keywords-tab"
              onClick={() => setSelectedTab(tab.id)}
              className={`p-2 font-semibold border border-transparent cursor-pointer ${
                selectedTab === tab.id ? 'border-b-sightlyBlue' : 'border-b-transparent'
              }`}
            >
              {tab.label}
            </div>
          ))}
      </div>
      <div
        data-testid="moment-modal-body-keywords"
        className="flex flex-col items-center"
      >
        {!isLoading && !selectedTabKeywords?.length && (
          <div
            data-testid="moment-modal-keywords-nocount"
            className="w-auto p-2 mt-2 text-base font-semibold rounded-md bg-blue-50 leading-22px"
          >
            Currently we have no Keywords for this Moment.
          </div>
        )}
        {selectedTabKeywords.length > 10 && !isLoading && (
          <div
            data-testid="moment-modal-keywords-count"
            className="w-auto p-2 mt-2 text-base font-semibold rounded-md bg-blue-50 leading-22px"
          >
            Showing 10 of {selectedTabKeywords.length} keywords. To see all keywords, please download the moment.
          </div>
        )}

        {selectedTabKeywords?.length > 0 && !isLoading && (
          <div
            data-testid="moment-modal-keywords-tab"
            id="keywordsTab"
            className="mt-4 overflow-y-auto"
          >
            <div
              data-testid="moment-modal-keywords-rank-labels"
              className="flex justify-between w-auto pt-3 pl-5 text-base font-semibold leading-6 text-gray-700 bg-gray-200 rounded-t-lg h-11"
            >
              Rank &nbsp; &nbsp; &nbsp; Keywords
              <div className="pl-2 mt-1 mr-6">
                <LikeDislikeButton
                  textColor={null}
                  handleDownClick={null}
                  text={'What do you think about the suggested keywords?'}
                  buttonState={moment?.keywordsFeedback}
                  handleClick={(feedback: string) => {
                    if (!brandProfileId) return
                    postTrendKeywordsFeedback({
                      feedback,
                      clusterId: moment.clusterId,
                      brandProfileId
                    })
                  }}
                  disabled={isMutating > 0}
                />
              </div>
            </div>
            {selectedTabKeywords &&
              selectedTabKeywords.slice(0, 10).map((keyword, index) => {
                return (
                  <MomentKeyword
                    key={index + keyword.rank}
                    keyword={keyword}
                    index={index}
                  />
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export default MomentKeywords
