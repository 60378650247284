import React from 'react'
import { Video } from '../../../../../classes/video'
import useWindowDimensions from '../../../../../useWindowDimensions'
import { postGarmFeedbackService } from '../../../../../services/lists'
import CheckTree from 'rsuite/lib/CheckTree'
import { garmOptions } from '../../../../../staticData/garmOptions'
import PortalModal from '../../../../../components/PortalModal/PortalModal'
import SightlyButton from '../../../../../components/Sightly/SightlyButton'

interface IProps {
	video: Video
	showModal: boolean
	handleClose: Function
}

const GarmFeedback = ({ video, showModal, handleClose }: IProps) => {
	const [compIds, setCompIds] =React.useState<string[] | number[]>(video.garmFeedbackIds)
	const dims = useWindowDimensions()
	const handleSubmitGarm = (_garmIds: string[]) => {
		setCompIds(_garmIds)
		const args = {
			versionId: video.versionId,
			videoId: video.id,
			garmIds: _garmIds
		}
		postGarmFeedbackService(args)
	}

	return (
		<div>
			<PortalModal
				open={showModal}
				handleClose={() => handleClose()}
				footerButtons={[
					<SightlyButton
						id='closeModalButton'
						text='Close'
						handleClick={() => handleClose()}
					/>
				]}>
				<div
					style={{
						height: 540,
						width: dims.width - 200,
						padding: 30
					}}>
					<div style={{ fontWeight: 600, fontSize: 18, marginBottom: 12 }}>
						What sensitive topics does this video contain?
					</div>

					<CheckTree
						defaultValue={compIds}
						uncheckableItemValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
						valueKey='id'
						labelKey='name'
						data={garmOptions}
						defaultExpandAll
						onChange={(arr) => handleSubmitGarm(arr)}
					/>
				</div>
			</PortalModal>
		</div>
	)
}

export default GarmFeedback
