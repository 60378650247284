import { ISocialData } from '@/views/Discover/Boards/types';
import { useQuery } from "@tanstack/react-query"
import { logError } from "@/utils"
import { api } from "@/api/api"

export default function useSocialClusterById(socialClusterId: string) {
    const { data: socialClusterData, isLoading: socialClusterIsLoading, error: socialClusterError } = useQuery(
      [`social-clusters/overview/${socialClusterId}`, socialClusterId],
      async () => {
        return await api.socialCluster.getOverviewById(socialClusterId);
      },
      {
        retry: 1,
        enabled: !!socialClusterId && socialClusterId.length > 0,
        cacheTime: 0,
        onError: (err) => {
          logError(err, { info: 'onError in social posts query' })
        }
      }
    )
    
    return {
      socialClusterData,
      socialClusterIsLoading,
      socialClusterError
    }
  }
  