import React, { useEffect, useState } from 'react'
import { RelevanceClassification } from '@/views/Discover/Moments/v2/types';

import highRelevanceIcon from '@/assets/img/relevance/high_relevance_icon.svg';
import lowRelevanveIcon from '@/assets/img/relevance/low_relevanve_icon.svg';
import mediumRelevanceIcon from '@/assets/img/relevance/medium_relevance_icon.svg';

interface IProps {
  relevanceClassification: RelevanceClassification | null | undefined
  background?: boolean
}

interface RelevanceContent {
  title: string,
  icon: string,
  background?: string
}

export const RelevanceClassificationBadge = ({
  relevanceClassification,
  background = false
}: IProps) => {

  const getRelevanceInformation = (relevanceClassification: RelevanceClassification | null | undefined): RelevanceContent => {
    switch (relevanceClassification) {
      case 'High':
        return {
          title: 'High Relevance',
          icon: highRelevanceIcon,
          ...(background && { background: 'bg-red-100' })
        };
      case 'Medium':
        return {
          title: 'Medium Relevance',
          icon: mediumRelevanceIcon,
          ...(background && { background: 'bg-orange-100' })
        }
      case 'Low':
        return {
          title: 'Low Relevance',
          icon: lowRelevanveIcon,
          ...(background && { background: 'bg-green-100' })
        }
      default:
        return {
          title: '',
          icon: ''
        };
    }
  }
  const clasification = getRelevanceInformation(relevanceClassification)

  return (
    <div>
      {
        relevanceClassification && (
          <div data-testid="anticipation-boards-moment-card-relevance"
            className={`flex items-center text-xs text-gray-500 ${clasification?.background ? `${clasification?.background} p-2 rounded-md w-fit` : ''}`}>
            <img src={clasification?.icon} alt={relevanceClassification} className="pr-1" />
            <p className='font-bold text-sm text-[#575757]'>{clasification?.title}</p>
          </div>
        )
      }
    </div>
  )
}
