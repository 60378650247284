import React, { useState } from 'react'
import { accentColor } from '../../../assets/jss/colorConstants'
import { joinClasses, permissionView } from '../../../utils'
import Icon from 'rsuite/lib/Icon'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { location } from '../../../RouteContainer'
import { MyLocationGenerics } from '@/classes/utils'
import { products } from '@/staticData/products'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import config from '@/config'
import { sharedContexts } from '@/sharedContexts/sharedContexts'

interface Step {
    name: string
    humanName: string
    userCanView: boolean
    active?: boolean
    children?: Step[]
    permissionNeeded?: string
    showLock?: boolean
}

interface IProps {
  steps: Step[]
  activateStep: (name: string) => void
  activeStep: string
}

const BrandProfileSteps = ({ steps, activateStep, activeStep }: IProps) => {
  const { disableBrandProfileStepsClick } = React.useContext(sharedContexts);
  const navigate = useNavigate()
  const { viewOnly } = useSearch<MyLocationGenerics>()
  const { accountId } = useSearch<MyLocationGenerics>()
  const {productQuotaTally : queriesQuotaTally} = useProductUsage({accountId, productName: products.QUERIES})
  const [isHovered, setIsHovered] = useState(false);

  const handleChildClick = (name: string) => {
    let newUrl = location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) + `/${name}`
    navigate({
      to: newUrl,
      search: (prev) => {
        return {
          ...prev
        }
      }
    })
  }

  return (
    <div 
      className='mx-auto'
      style={{ position: 'relative'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >      
    {/* Display popup box if hovered when required fields missing*/}
    {isHovered && disableBrandProfileStepsClick && !viewOnly && (
        <div className="popup-box" 
        >Complete required fields to unlock menu.
        </div>
      )}
      {steps.map((step: Step, index: number) => {
        let customActiveStep = activeStep
        if (activeStep === 'opinions' || activeStep === 'scenarios') {
          customActiveStep = 'brandPositioning'
        }
        if(!config.featureFlag.aiProfiles){
          if (
            activeStep === 'brands' ||
            activeStep === 'influencers' ||
            activeStep === 'events' ||
            activeStep === 'social' ||
            activeStep === 'philanthropic'
          ) {
            customActiveStep = 'queries'
          }
        }
        if (
          activeStep === 'iabCategories' ||
          activeStep === 'topics' ||
          activeStep === 'googleAds' ||
          activeStep === 'news' ||
          activeStep === 'industries' ||
          activeStep === 'keywords'
        ) {
          customActiveStep = 'keywordsGroup'
        }
        const isActive = customActiveStep === step.name
        return (
          <div
            data-testid={`div-brand-profile-step-${step.name}`}
            style={{ marginBottom: 16 }}
            key={index}
          >
            <div
              onClick={() => activateStep(step.name)}
              style={{ display: 'flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }}
            >
              <div className={joinClasses('numberCircle', isActive && 'numberCircleActive')}>{index + 1}</div>
              <div className={joinClasses('stepName', isActive && 'stepNameActive')}>
                {step.humanName}
                {!config.featureFlag.aiProfiles &&  step.humanName === 'Queries' ? (
                  <span
                    data-testid='brand-profile-queries-quota-tally'
                    className={`text-xs ${queriesQuotaTally?.isMaxedOut ? 'text-red-600 ' : 'text-gray-600'}`}
                  >{`  ${`${queriesQuotaTally?.currentProductCount}/ ${queriesQuotaTally?.productQuota}`} used`}</span>
                ) : (
                  ''
                )}
                {permissionView && step.permissionNeeded && <div style={{ color: 'red' }}>{step.permissionNeeded}</div>}
              </div>
              {step.showLock && (
                <div>
                  {' '}
                  <Icon
                    style={{ color: '#a3a3a3' }}
                    size="lg"
                    icon="lock"
                  />
                </div>
              )}
            </div>
            {customActiveStep === step.name && !!step.children && (
              <div>
                {step.children &&
                  step.children.map((child: Step) => {
                    return (
                      <div
                        onClick={() => handleChildClick(child.name)}
                        key={child.name}
                        className={joinClasses('childStep', activeStep === child.name && 'childStepActive')}
                        style={{
                          marginLeft: 36 + 16,
                          marginBottom: 8,
                          fontSize: 14,
                          cursor: 'pointer'
                        }}
                      >
                        <>
                          {child.humanName}
                          {permissionView && child.permissionNeeded && (
                            <div style={{ color: 'red' }}>{child.permissionNeeded}</div>
                          )}
                        </>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        )
      })}

      <style>
        {`
      .stepName {
        font-weight: 600;
        font-size: 16px;
        color: #a3a3a3; 
        margin-right: 16px;
      }
      
      .stepNameActive {
        color: #3d3d3d;
      }
      .childStep {
        font-weight: 500;
        color: #3d3d3d;
        opacity: 50%;

      }
      .childStepActive {
        font-weight: 600;
        color: #3d3d3d;
        opacity: 100%;
      }
      .numberCircle {
        font-size: 16px;
        width: 36px;
        height: 36px;
        color: #a3a3a3;
        font-weight: 700;
        border: 1px solid #a3a3a3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 45px;
        margin-right: 16px;
      }
      .numberCircleActive {
        color: white;
        background-color: ${accentColor};
        border: 2px solid ${accentColor};
      }
`}
      </style>
    </div>
  )
}

export default BrandProfileSteps
