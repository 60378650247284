import { darkBlueColor, darkBlueSecondaryColor } from '@/assets/jss/colorConstants'
import React from 'react'
import styled from 'styled-components'

interface IProps {
    handleClick: Function
    tabImage: React.ReactNode,
    datatestid?: string
    isActive: boolean
}

interface IStyledDivProps {
    isActive: boolean
}

const StyledDiv = styled.div<IStyledDivProps>`
    :hover {
        color: #2D9CDB;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    margin: 0 8px 0 8px;
    color: ${(props) => props.isActive ? '#2D9CDB' : '#828282'};
    border-radius: 6px;
`

export const Tab = (props: IProps) => {
    return (
        <StyledDiv isActive={props.isActive} data-testid={props.datatestid} onClick={() => props.handleClick()}>
            {props.tabImage}
        </StyledDiv>
    )
}
