import BrandMentalityNodesBackground from '@/assets/img/BrandMentalityNodesBackground.png'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import { PaperAirplaneIcon } from '@heroicons/react/20/solid'
import React from 'react'

interface IProps {
  label: string,
  height?: string | number
  onSearch: (input: string) => void
}

const SearchBar = ({ label, height, onSearch }: IProps) => {
  return (
    <div
      className="rounded-md bg-cover bg-center w-full flex flex-col items-center justify-center gap-2 p-8"
      style={{
        backgroundImage: `url(${BrandMentalityNodesBackground})`,
        height: height ? height : undefined
      }}
    >
      <span className='text-white text-xl'>{label}</span>
      <SightlyInput
        dataTestId="topic-trend-overview-page-search-input"
        id={'explore-search-input'}
        defaultValue={''}
        width={'70%'}
        height={'60%'}
        hasButton={true}
        buttonIcon={<PaperAirplaneIcon />}
        onChange={onSearch}
      />
    </div>
  )
}

export default SearchBar
