import { useQuery } from '@tanstack/react-query';
import { userAccountAxios } from '@/axiosInstances';
import { RolesAndPermissionsSchema } from '@/schemas/schemas';
import { rqKeys } from '@/ReactQueryKeyFactory';

const getPermissions = async () => {
    const url = `/v1/users/permissions?pageSize=100&orderBy=name&sort=asc`
    const result = await userAccountAxios.get(url)

    return result.data.results
}

const usePermissions = () => {
    const QUERY_KEY = rqKeys.adminPermissionRoles()
    const permissions = useQuery(QUERY_KEY, getPermissions)


    return {
        ...permissions,
    }
}

export default usePermissions
