import React from 'react';
import { AIIcon } from './AIIcon';

interface AIGenerateButtonProps {
  label: string;
  handleAIGenerate: () => void;
  disabled?: boolean;
  dataTestId?: string;
  pendoId?: string;
}

export const AiGenerateButton: React.FC<AIGenerateButtonProps> = ({
  label,
  handleAIGenerate,
  disabled,
  dataTestId,
  pendoId,
}) => (
  <div
    data-testid={dataTestId}
    id={pendoId}
    onClick={() => {
      !disabled && handleAIGenerate();
    }}
    className={`flex items-center justify-between h-10 w-32 px-4 py-2 rounded-md bg-gradient-to-r from-[#0047FF] via-[#5B32EF] to-[#333D47] 
    ${disabled ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'}`}
  >
    <span className="pr-2 font-bold text-slate-100">{label}</span>
    <span>
      <AIIcon />
    </span>
  </div>
);