import React, { ChangeEvent } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { AdminIabCategory, api } from '@/api/api'
import { LoadingPage } from '@/components/LoadingPage'
import InputGroup from 'rsuite/lib/InputGroup'
import Input from 'rsuite/lib/Input'
import Tag from 'rsuite/lib/Tag'
import Icon from 'rsuite/lib/Icon'
import toast from 'react-hot-toast'
import { logError } from '@/utils'
import TagGroup from 'rsuite/lib/TagGroup'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import Sidebar from '@/views/Admin/components/Sidebar';

function IabCategories() {
    const QUERY_KEY = rqKeys.adminIabCategories()
    const query = useQuery(QUERY_KEY, api.adminIabCategories.get);

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            <div style={{ padding: 24, width: "100%" }}>
                {query.isLoading ? (
                    <LoadingPage message="Fetching Iab Categories" />
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th className="catHeader">IAB Category ID</th>
                                <th className="catHeader">IAB Category Name</th>
                                <th className="catHeader">Remove Original IAB Term</th>
                                <th className="catHeader">Sightly Search Terms</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query?.data?.map((cat, index) => {
                                return (
                                    <CategoryRow
                                        key={index}
                                        cat={cat}
                                    />
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

interface IProps {
    cat: AdminIabCategory
}

const CategoryRow = React.memo(({ cat }: IProps) => {
    const queryClient = useQueryClient()
    const QUERY_KEY = rqKeys.adminIabCategories()
    const [text, setText] = React.useState('')
    const handleAddKeyword = (value: string, iabCategory: AdminIabCategory) => {
        if (!value || value.length === 0) return
        mapKeywordsMutation.mutate({
            keywords: iabCategory.keywords.concat({ keyword: value, archived: false }),
            iabCategoryId: iabCategory.iabCategoryId
        })
    }

    const mapKeywordsMutation = useMutation(api.adminIabCategories.mapKeywords, {
        onMutate: ({ keywords, iabCategoryId }) => {
            queryClient.setQueryData(QUERY_KEY, (old: AdminIabCategory[] | undefined) => {
                if (!old) return []
                return old.map((cat) => {
                    if (cat.iabCategoryId === iabCategoryId) {
                        return {
                            ...cat,
                            keywords
                        }
                    }
                    return cat
                })
            })
        },
        onSuccess: () => toast.success('Changes saved'),
        onError: (err) => {
            toast.error('Unable to save changes')
            logError(err, { info: 'error mapping admin iab cats' })
        },
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })

    const disregardLeafNodeMutation = useMutation(api.adminIabCategories.disregardLeafNode, {
        onMutate: ({ iabCategoryId }) => {
            queryClient.setQueryData(QUERY_KEY, (old: AdminIabCategory[] | undefined) => {
                if (!old) return []
                return old.map((cat) => {
                    if (cat.iabCategoryId === iabCategoryId) {
                        return {
                            ...cat,
                            disregardLeafNode: !cat.disregardLeafNode
                        }
                    }
                    return cat
                })
            })
        },
        onSuccess: () => toast.success('Changes saved'),
        onError: (err) => {
            toast.error('Unable to save changes')
            logError(err, { info: 'error disregardingLeafNode iab cats' })
        },
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY)
        }
    })

    return (
        <tr className="catRow">
            <td>{cat.iabCategoryId}</td>
            <td>{cat.iabCategoryName}</td>
            <td>
                <SightlyCheckbox
                    id="disregardCheckbox"
                    disabled={cat?.keywords?.filter((k) => !k.archived).length < 1}
                    checked={cat.disregardLeafNode}
                    handleChange={() => {
                        disregardLeafNodeMutation.mutate({ iabCategoryId: cat.iabCategoryId })
                    }}
                />
            </td>
            <td
                style={{
                    width: 700,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                    justifyContent: 'center',
                    margin: 10
                }}
            >
                <div style={{ flex: 1 }}>
                    <InputGroup>
                        <Input
                            className="tag-input"
                            size="xs"
                            value={text}
                            onPressEnter={() => {
                                handleAddKeyword(text, cat)
                            }}
                            onInput={(e: ChangeEvent<{ value: string }>) => setText(e.target.value)}
                        />
                        <InputGroup.Button
                            appearance="link"
                            size="xs"
                            onClick={() => {
                                handleAddKeyword(text, cat)
                            }}
                        >
                            <Icon icon="save" />
                        </InputGroup.Button>
                    </InputGroup>
                </div>
                <div style={{ flex: 3 }}>
                    <TagGroup>
                        {cat.keywords
                            .filter((k) => !k.archived)
                            .map((kw, index) => {
                                return (
                                    <Tag
                                        key={index}
                                        closable={
                                            !(
                                                cat.disregardLeafNode &&
                                                cat.keywords.filter((t) => !t.archived).length === 1
                                            )
                                        }
                                        onClose={() => {
                                            if (
                                                cat.disregardLeafNode &&
                                                cat.keywords.filter((t) => !t.archived).length === 1
                                            ) {
                                                toast.error(
                                                    'You cannot remove the last search term while "Remove Original IAB Term" is checked.'
                                                )
                                                return
                                            }
                                            mapKeywordsMutation.mutate({
                                                keywords: cat.keywords.map((k) => {
                                                    if (k.keyword === kw.keyword) {
                                                        return {
                                                            ...k,
                                                            archived: true
                                                        }
                                                    }
                                                    return k
                                                }),
                                                iabCategoryId: cat.iabCategoryId
                                            })
                                        }}
                                    >
                                        {kw.keyword}
                                    </Tag>
                                )
                            })}
                    </TagGroup>
                </div>
            </td>
        </tr>
    )
})

export default IabCategories
