import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC,
  CONVERSION,
  GenericDimensionOption, MetricOption,
} from "@/views/ActivationReports/types/activation-report.types";

export enum MOMENT_SCENARIO_DIMENSION {
  ACTION = 'action',
  BRAND_PROFILE_NAME = 'brandProfileName',
  CLUSTER_ID = 'clusterId',
  CLUSTER_NAME = 'clusterName',
  IMPLEMENTED_AT = 'implementedAt',
  MAX_PUBLISHED_AT = 'maxPublishedAt',
  MIN_PUBLISHED_AT = 'minPublishedAt',
  PERSONAS = 'personas',
  SCENARIO_NAME = 'scenarioName',
  SCENARIO_RESPONSE = 'scenarioResponse',
  STORY_TITLES = 'storyTitles',
}
export enum MOMENT_SCENARIO_METRIC {
  STORY_COUNT = 'storyCount',
  VIDEO_COUNT = 'videoCount',
}
interface MomentScenarioDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | MOMENT_SCENARIO_DIMENSION
}

interface MomentScenarioMetricOption extends Omit<MetricOption, 'value'> {
  name: string
  value: METRIC | MOMENT_SCENARIO_METRIC
}

export interface MomentScenarioReport extends Omit<ReportTemplate, 'columns' | 'dimensions' | 'metrics'> {
  columns: (GENERIC_DIMENSION | MOMENT_SCENARIO_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | MomentScenarioDimensionOption)[]
  metrics: (MetricOption | MomentScenarioMetricOption)[]
}

export const dimensionOptions: Option<MomentScenarioDimensionOption> = {
  [MOMENT_SCENARIO_DIMENSION.ACTION]: {
    name: 'Action',
    value: MOMENT_SCENARIO_DIMENSION.ACTION
  },
  [MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME
  },
  [MOMENT_SCENARIO_DIMENSION.CLUSTER_ID]: {
    name: 'Moment Id',
    value: MOMENT_SCENARIO_DIMENSION.CLUSTER_ID
  },
  [MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME]: {
    name: 'Moment Name',
    value: MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME
  },
  [MOMENT_SCENARIO_DIMENSION.IMPLEMENTED_AT]: {
    name: 'Moment Implemented At',
    value: MOMENT_SCENARIO_DIMENSION.IMPLEMENTED_AT
  },
  [MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT]: {
    name: 'Max Published At',
    value: MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT
  },
  [MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT]: {
    name: 'Min Published At',
    value: MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT
  },
  [MOMENT_SCENARIO_DIMENSION.PERSONAS]: {
    name: 'Personas',
    value: MOMENT_SCENARIO_DIMENSION.PERSONAS
  },
  [MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME]: {
    name: 'Scenario Name',
    value: MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME
  },
  [MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE]: {
    name: 'Scenario Response',
    value: MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE
  },
  [MOMENT_SCENARIO_DIMENSION.STORY_TITLES]: {
    name: 'Story Titles',
    value: MOMENT_SCENARIO_DIMENSION.STORY_TITLES
  },
}

export const metricOptions: Option<MomentScenarioMetricOption> = {
  [MOMENT_SCENARIO_METRIC.STORY_COUNT]: {
    name: 'Story Count',
    value: MOMENT_SCENARIO_METRIC.STORY_COUNT
  },
  [MOMENT_SCENARIO_METRIC.VIDEO_COUNT]: {
    name: 'Video Count',
    value: MOMENT_SCENARIO_METRIC.VIDEO_COUNT
  },
}

export const baseMomentScenarioReport: MomentScenarioReport = {
  name: 'Moment Scenario Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.MOMENT_SCENARIO,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: [
    ...templateMetricOptions,
    ...Object.keys(metricOptions).map(key => metricOptions[key])
  ],
  conversions: templateConversionOptions
}
