import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'
interface IProps {
    activePage: number
    total: number
    onChangePage: Function
    limit: number
}


export const TablePagination = ({ activePage, total, limit, onChangePage }: IProps) => {
    // Calculate the maximum page number

    const maxPage = Math.ceil(total / limit);

    const changeMinusPage = () => {
        onChangePage(activePage - 1)
    }
    const changePlusPage = () => {
        onChangePage(activePage + 1)
    }
    const changeFirstPage = () => {
        onChangePage(1)
    }
    const changeLastPage = () => {
        onChangePage(maxPage)
    }

    return (
        <div className='flex flex-row' data-testid="activation-reports-paginator">
            <div
                onClick={activePage === 1 ? undefined : changeFirstPage}
                className={`cursor-pointer ${activePage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                data-testid="activation-reports-paginator-doubleleft"
            >
                <ChevronDoubleLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                />
            </div>
            <div
                onClick={activePage === 1 ? undefined : changeMinusPage}
                className={`cursor-pointer ${activePage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                data-testid="activation-reports-paginator-left"
            >
                <ChevronLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                />

            </div>
            <div className=' flex justify-center w-5' data-testid="activation-reports-paginator-activepage">

                {activePage}
            </div>
            <div
                onClick={activePage >= maxPage ? undefined : changePlusPage}
                className={`cursor-pointer ${activePage >= maxPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                data-testid="activation-reports-paginator-right"
            >
                <ChevronRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                />

            </div>
            <div
                onClick={activePage >= maxPage ? undefined : changeLastPage}
                className={`cursor-pointer ${activePage >= maxPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                data-testid="activation-reports-paginator-doubleright"
            >
                <ChevronDoubleRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                />

            </div>
        </div>
    )
}
