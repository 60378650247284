import React from "react"

export const AIIcon = () => (<svg
  width="16"
  height="17"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.60306 1.4054C7.84901 1.26486 8.15094 1.26486 8.39689 1.4054L9.79689 2.2054C10.1805 2.42461 10.3138 2.9133 10.0946 3.29691C9.87536 3.68052 9.38668 3.8138 9.00306 3.59459L7.99998 3.0214L6.99689 3.59459C6.61327 3.8138 6.12459 3.68052 5.90538 3.29691C5.68617 2.9133 5.81945 2.42461 6.20306 2.2054L7.60306 1.4054Z"
    fill="white"
  />
  <path
    d="M4.49457 4.10309C4.71378 4.4867 4.5805 4.97539 4.19689 5.19459L4.01243 5.3L4.19689 5.4054C4.5805 5.62461 4.71378 6.1133 4.49457 6.49691C4.27536 6.88052 3.78668 7.0138 3.40306 6.79459L3.19998 6.67854V6.9C3.19998 7.34183 2.8418 7.7 2.39998 7.7C1.95815 7.7 1.59998 7.34183 1.59998 6.9V5.3C1.59998 5.10001 1.67336 4.91716 1.79467 4.77691C1.82239 4.74482 1.85276 4.71483 1.88557 4.68729C1.92601 4.6533 1.96986 4.62324 2.01655 4.5977L3.40306 3.8054C3.78668 3.5862 4.27536 3.71947 4.49457 4.10309Z"
    fill="white"
  />
  <path
    d="M11.5054 4.10309C11.7246 3.71947 12.2133 3.5862 12.5969 3.8054L13.9834 4.5977C14.0301 4.62324 14.0739 4.6533 14.1144 4.68729C14.289 4.83404 14.4 5.05406 14.4 5.3V6.9C14.4 7.34183 14.0418 7.7 13.6 7.7C13.1581 7.7 12.8 7.34183 12.8 6.9V6.67854L12.5969 6.79459C12.2133 7.0138 11.7246 6.88052 11.5054 6.49691C11.2862 6.1133 11.4194 5.62461 11.8031 5.4054L11.9875 5.3L11.8031 5.19459C11.4194 4.97539 11.2862 4.4867 11.5054 4.10309Z"
    fill="white"
  />
  <path
    d="M5.90538 7.30309C6.12459 6.91947 6.61327 6.7862 6.99689 7.0054L7.99998 7.5786L9.00306 7.0054C9.38668 6.7862 9.87536 6.91947 10.0946 7.30309C10.3138 7.6867 10.1805 8.17538 9.79689 8.39459L8.79998 8.96426V10.1C8.79998 10.5418 8.4418 10.9 7.99998 10.9C7.55815 10.9 7.19998 10.5418 7.19998 10.1V8.96426L6.20306 8.39459C5.81945 8.17538 5.68617 7.6867 5.90538 7.30309Z"
    fill="white"
  />
  <path
    d="M2.39998 9.3C2.8418 9.3 3.19998 9.65817 3.19998 10.1V11.2357L4.19689 11.8054C4.5805 12.0246 4.71378 12.5133 4.49457 12.8969C4.27536 13.2805 3.78668 13.4138 3.40306 13.1946L2.00306 12.3946C1.7538 12.2522 1.59998 11.9871 1.59998 11.7V10.1C1.59998 9.65817 1.95815 9.3 2.39998 9.3Z"
    fill="white"
  />
  <path
    d="M13.6 9.3C14.0418 9.3 14.4 9.65817 14.4 10.1V11.7C14.4 11.9871 14.2461 12.2522 13.9969 12.3946L12.5969 13.1946C12.2133 13.4138 11.7246 13.2805 11.5054 12.8969C11.2862 12.5133 11.4194 12.0246 11.8031 11.8054L12.8 11.2357V10.1C12.8 9.65817 13.1581 9.3 13.6 9.3Z"
    fill="white"
  />
  <path
    d="M5.90538 13.7031C6.12459 13.3195 6.61327 13.1862 6.99689 13.4054L7.19998 13.5215V13.3C7.19998 12.8582 7.55815 12.5 7.99998 12.5C8.4418 12.5 8.79998 12.8582 8.79998 13.3V13.5215L9.00306 13.4054C9.38668 13.1862 9.87536 13.3195 10.0946 13.7031C10.3138 14.0867 10.1805 14.5754 9.79689 14.7946L8.40904 15.5876C8.28935 15.659 8.14945 15.7 7.99998 15.7C7.8505 15.7 7.7106 15.659 7.59091 15.5876L6.20306 14.7946C5.81945 14.5754 5.68617 14.0867 5.90538 13.7031Z"
    fill="white"
  />
</svg>)