import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useBodyOverflowYAuto } from '@/components/Sightly/hooks/useBodyOverflowYAuto';
import { useBodyOverflowXHidden } from '@/components/Sightly/hooks/useBodyOverflowXHidden';

const MainDiv = styled.div`
    @media (min-width: 768px) { flex-direction: row; }
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
`

const FormDiv = styled.div`
    @media (min-width: 768px) {
        margin: 3em 3em;
        width: 40%;
    }
    @media (min-width: 1024px) {
        margin: 3em 9em;
        width: 40%;
    }
    margin: 3em 3em;
    overflow: auto;
`

const MediaDiv = styled.div`
    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4rem;
        position: sticky;
        top: 0;
        width: 60%;
        height: 100vh;
        overflow: hidden;
    }
    display: none;
    background: linear-gradient(0deg, rgba(38, 170, 225, 0.06), rgba(38, 170, 225, 0.06)), linear-gradient(179.98deg, #CFFAFE 16.27%, rgba(38, 170, 225, 0.28) 99.98%);;
`

const MediaImg = styled.img`
    height: 100%;
`

export interface IProps {
    children: ReactNode;
    image: string;
}

export const IntroLayout = ({ children, image }: IProps) => {
    useBodyOverflowXHidden()
    useBodyOverflowYAuto()

    return (
        <MainDiv>
            <FormDiv>
                {children}
            </FormDiv>
            <MediaDiv>
                <MediaImg src={image} alt='image depicting someone sighting something in the distance' />
            </MediaDiv>
        </MainDiv>
    );
};
