import React from 'react';
import useWindowDimensions from '@/useWindowDimensions';
import useEmbedUrls from '@/hooks/useEmbedUrls';
import useUser from '@/hooks/useUser';
import { useFlag } from '@unleash/proxy-client-react';
import { TokenExpirationAlertModal } from '@/views/Discover/components/TokenExpirationAlertModal';
import { getTokenExpirationDate, getTokenFromURL } from '@/classes/utils';

export const MomentIntelligence: React.FC = () => {
    const { currentAccount } = useUser();
    const { data: embedUrls } = useEmbedUrls(currentAccount?.accountId);

    const embedUrl = embedUrls?.metabaseMomentIntelligenceV2EmbedUrl

    const dims = useWindowDimensions();

    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        timer();

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [embedUrls])

    const timer = () => {
        if (embedUrls?.metabaseBrandMentalityEmbedUrl) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            const token = getTokenFromURL(embedUrls?.metabaseBrandMentalityEmbedUrl)
            const expirationTime = getTokenExpirationDate(token!);

            const timeUntilExpiration = expirationTime - Date.now();
            if(timeUntilExpiration > 0) {
                timerRef.current = setTimeout(() => {
                    setShowModal(true);
                }, timeUntilExpiration);
            }
        }
    };

    return (
        <div style={{ padding: 24 }}>
            <iframe
                id="momentsIntelligenceIframe"
                style={{
                    border: 0,
                    width: '100%',
                    height: dims.height - 56 - 48 - 24
                }}
                src={embedUrl}
                title={'Moments Intelligence'}
            >
                <p>Your browser does not support iframes.</p>
            </iframe>

            <TokenExpirationAlertModal
                onHide={() => setShowModal(false)}
                show={showModal}
            />
        </div>
    )
}
