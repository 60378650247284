import React from 'react'
import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import { BulkOpsButtons } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/BulkOpsButtons'
import { TableHeaderRow } from '@/views/TikTok/ActivationManager/ActivationsTable/TableHeaderRow'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import CampaignAdGroupWrapper from '@/views/TikTok/ActivationManager/ActivationsTable/CampaignAdGroupWrapper'
import { TrashIcon } from '@heroicons/react/24/outline'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/TikTok/ActivationManager/reducers/activationManager.reducer'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'
import { ACTIVATIONS_MANAGER_BULK_DELETE } from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { TargetedActivationListType } from '@/views/TikTok/ActivationManager/types'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import BulkAssignCampaignAdGroups from '@/views/TikTok/ActivationManager/ActivationsTable/BulkAssignCampaignsAdGroups'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'
import { useCampaignsAdGroupsHierarchy } from '@/views/TikTok/useTikTok'
import { useStoredTargetedActivations } from '@/views/TikTok/ActivationManager/useStoredTargetActivations'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'

type ActivationsTableProps = {
  setSearchedHashtagsIds: React.Dispatch<React.SetStateAction<string[]>>
  filteredActivations: SerializableMap<string, TargetedActivationListType>
}

const ActivationsTable = ({ setSearchedHashtagsIds, filteredActivations }: ActivationsTableProps) => {
  const { currentAccount } = useUser()
  const targetedActivationList = useTargetedActivationList()
  const [selectedActivations, setSelectedActivations] = React.useState<Set<string>>(new Set<string>())
  const [showBulkAssignmentModal, setShowBulkAssignmentModal] = React.useState<boolean>(false)
  const [isBulkAssignment, setIsBulkAssignment] = React.useState<boolean>(false)
  const [isBulkDelete, setIsBulkDelete] = React.useState<boolean>(false)
  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState<boolean>(false)
  const [activationsToBeDeleted, setActivationsToBeDeleted] = React.useState<string[] | null>(null)
  const campaignOptions = useCampaignsAdGroupsHierarchy(currentAccount?.accountId!)
  const { setActivationListToStorage } = useStoredTargetedActivations()

  let keyVersion = React.useRef(0)

  const handleRemoveActivation = () => {
    if (!activationsToBeDeleted) return
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATIONS,
      payload: {
        activationsToBeDeleted
      }
    })

    setSearchedHashtagsIds((prev) => prev.filter((id) => !activationsToBeDeleted.includes(id)))

    setActivationsToBeDeleted(null)

    setSelectedActivations((prev) => {
      const next = new Set(prev)
      activationsToBeDeleted.forEach((id) => {
        next.delete(id)
      })
      return next
    })

    if (isBulkDelete) {
      setIsBulkDelete(false)
      setIsSelectAllChecked(() => false)
    }
  }

  const handleCancelDeleteActivation = () => {
    setActivationsToBeDeleted(null)
  }

  const handleSelectAll = (checked: boolean) => {
    const areActivationsFiltered = filteredActivations.size < targetedActivationList.state.size

    setSelectedActivations((prev) => {
      const idsToBeSelected = Array.from(filteredActivations.keys())
      if (checked && areActivationsFiltered) {
        return new Set([...Array.from(prev), ...idsToBeSelected])
      }
      if (checked) {
        return new Set(idsToBeSelected)
      }
      return new Set()
    })
  }

  const handleBulkDeleteMoments = () => {
    setIsBulkDelete(() => true)
    setActivationsToBeDeleted(() => Array.from(selectedActivations))
  }

  React.useEffect(() => {
    keyVersion.current = keyVersion.current + 1
    setIsBulkAssignment(false)
  }, [isBulkAssignment])

  // Persist in the session storage
  React.useEffect(() => {
    setActivationListToStorage(targetedActivationList.state)
  }, [targetedActivationList.state])

  const handleSelectActivation = (checked: boolean, hashtagId: string) => {
    setSelectedActivations((prev) => {
      const nextState = new Set(prev)
      if (checked) {
        nextState.add(hashtagId)
      } else {
        nextState.delete(hashtagId)
      }
      return nextState
    })
  }

  const { products, perms, userPermissions, userProducts } = useUser()

  return (
    <>
      {selectedActivations.size > 0 && !campaignOptions.isLoading && (
        <BulkOpsButtons
          {...{
            handleBulkDeleteMoments,
            selectedActivationCount: selectedActivations.size,
            selectedActivations,
            setShowBulkAssignmentModal
          }}
        />
      )}

      <div className="relative flex-1 h-full overflow-y-auto ">
        <div className="h-full py-2 align-middle md:px-6">
          <div className="h-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table
              data-testid="activations-manager-activations-table"
              id="activations-manager-activations-table"
              className="w-full border-collapse"
            >
              <TableHeaderRow
                {...{
                  handleSelectAll,
                  isSelectAllChecked,
                  selectedActivations,
                  setIsSelectAllChecked
                }}
              />
              <tbody className="overflow-y-auto bg-white">
                {filteredActivations.size <= 0 && (
                  <tr>
                    <td
                      rowSpan={6}
                      colSpan={6}
                      className="text-center h-[50vh]"
                      data-testid="no-activations-found-text"
                      id="no-activations-found-text"
                    >
                      No Activations Found
                    </td>
                  </tr>
                )}
                {Array.from(filteredActivations.values()).map(
                  ({ campaignsToAdGroups, categoryName, hashtagId, hashtagName }) => {
                    return (
                      <tr key={`${hashtagId}`}>
                        <td
                          data-testid="tiktok-act-mgr-activations-table"
                          id="tiktok-act-mgr-activations-table"
                          className="p-3 text-sm font-medium text-gray-900 align-top"
                        >
                          <SightlyCheckbox
                            id="tiktok-act-mgr-activations-checkbox-id"
                            dataTestId="tiktok-act-mgr-activations-checkbox"
                            checked={selectedActivations?.has(hashtagId) || false}
                            handleChange={(checked: boolean) => handleSelectActivation(checked, hashtagId)}
                          />
                        </td>
                        <td
                          className="w-1/5 p-3 text-xs font-semibold text-gray-900 align-top rounded-md"
                          data-testid="tiktok-act-mgr-categoryName-col"
                          id="tiktok-act-mgr-categoryName-col"
                        >
                          <p className="p-1 bg-blue-100 max-w-min"> {categoryName}</p>
                        </td>
                        <td
                          data-testid="tiktok-act-mgr-hashtagName-col"
                          id="tiktok-act-mgr-hashtagName-col"
                          className="w-1/5 p-3 text-sm font-medium text-gray-900 align-top"
                        >
                          #{hashtagName}
                        </td>
                        <td className="p-2">
                          <CampaignAdGroupWrapper
                            {...{
                              campaignOptions: campaignOptions.data,
                              areCampaignOptionsLoading: campaignOptions.isLoading,
                              campaignsToAdGroups,
                              hashtagId,
                              keyVersion,
                              key: `${hashtagId}_cmp_wrapper_v${keyVersion.current}`
                            }}
                          />
                        </td>
                        <td
                          data-testid="tiktok-act-mgr-target-moments-table-action-delete"
                          className="flex justify-center p-3"
                        >
                          
                          {userCanPermissionProductQuota({
                              requiredPermissionValue: perms.TIKTOK_ACTIVATIONS_UPDATE,
                              requiredProductValue: products.TIKTOK_ACTIVATIONS,
                              userPermissions: userPermissions,
                              userProducts: userProducts,
                              checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                            }) && (
                            <TrashIcon
                              onClick={() => setActivationsToBeDeleted([hashtagId])}
                              className="w-6 h-6 rounded hover:cursor-pointer hover:bg-slate-100"
                            />
                          )}
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          </div>
          <ConfirmationDialog
            show={!!activationsToBeDeleted}
            onHide={handleCancelDeleteActivation}
            handleConfirm={() => handleRemoveActivation()}
            handleCancel={handleCancelDeleteActivation}
            body={ACTIVATIONS_MANAGER_BULK_DELETE(activationsToBeDeleted?.length ?? 0)}
            action="Delete"
          />
          <ConfirmationDialog
            show={isBulkDelete}
            onHide={handleCancelDeleteActivation}
            handleConfirm={() => handleRemoveActivation()}
            handleCancel={handleCancelDeleteActivation}
            body={ACTIVATIONS_MANAGER_BULK_DELETE(selectedActivations.size)}
            action="Delete"
          />
          <BulkAssignCampaignAdGroups
            {...{
              setShow: setShowBulkAssignmentModal,
              show: showBulkAssignmentModal,
              selectedActivations,
              setSelectedActivations,
              setIsBulkAssignment,
              setIsSelectAllChecked,
              campaignOptionsPrimary: campaignOptions.data
            }}
          />
        </div>
      </div>
    </>
  )
}

export default React.memo(ActivationsTable)
