import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
interface IProps {
    id: number | string
    error: string | undefined
}
export const FormError = ({ id, error }: IProps) => {
    if (!error) {
        return null
    }
    return (
        <p
            data-testid="p-formerror"
            className="mt-1 text-sm text-red-600 flex items-center gap-2"
            id={`${id}-error`}
        >
            <ExclamationCircleIcon className="w-4" />
            {error}
        </p>
    )
}
