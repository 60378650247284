import { z } from 'zod'

export const OpinionTypeSchema = z.object({
    typeId: z.number(),
    typeName: z.string().min(1, 'Must have at least one character'),
    descriptionText: z.string().nullable(),
    archived: z.boolean(),
    
})
export type OpinionTypeType = z.infer<typeof OpinionTypeSchema>

export const OpinionTypeSchemaArray = z.array(OpinionTypeSchema)

export const PatchOpinionTypeSchema = OpinionTypeSchema.omit({
    typeId: true
  });
export type PatchOpinionTypeType = z.infer<typeof PatchOpinionTypeSchema>;
