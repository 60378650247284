import React, { SyntheticEvent, useEffect, useState } from "react"
import './hashtags.css'
import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect'
import SightlyButton from '@/components/Sightly/SightlyButton'
import SearchBox from '@/components/SearchBox';
import boltIcon from '@/assets/img/boltIcon.svg'
import { InsightsPanel } from '@/views/TikTok/Hashtags/InsightsPanel'
import {
  ActivationStatusType,
  ICategoryOption,
  IDateOption,
  IHashtag,
  ISortByOption, IStatusOption,
  SelectedHashtagType
} from '@/views/TikTok/Hashtags/types'
import { useTargetStatus, useHashtag as useHashtagAudience, useHashtags, useHashtagsStatus } from '@/views/TikTok/useTikTok'
import HashtagsInfiniteScroll from '@/views/TikTok/Hashtags/components/HashtagsInfiniteScroll'
import { logError } from '@/utils'
import { CheckTreePicker, Loader } from 'rsuite'
import ActivationManager from '@/views/TikTok/ActivationManager/index'
import { ActivationManagerProvider } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'
import { AlertModal } from '@/views/TikTok/Hashtags/components/AlertModal'
import { useStoredTargetedActivations } from '@/views/TikTok/ActivationManager/useStoredTargetActivations'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { MessageTailwind } from '@/components/MessageTailwind'
import { Link } from '@tanstack/react-location'
import { PATH_ACTIVATIONS_TIKTOK } from '@/routes'
import userCanPermissionProductQuota, { PermissionCheckType } from "@/services/userCanPermissionProductQuota"
import { SightlyInput } from "@/components/Sightly/SightlyFormElements/SightlyInput"
import { set } from "lodash"

const getStoredSelectedHashtags = () => {
  const storedSelectedHashtags = sessionStorage.getItem('selectedHashtags')
  return storedSelectedHashtags && storedSelectedHashtags !== 'undefined' && JSON.parse(storedSelectedHashtags)
}

const getDefaultSelectedHashtags = (accountId: number | undefined): SerializableMap<string, SelectedHashtagType> => {
  const selectedActivationsAll = getStoredSelectedHashtags()
  if (accountId && selectedActivationsAll && accountId in selectedActivationsAll) {
    return new SerializableMap<string, SelectedHashtagType>(
      Object.entries(selectedActivationsAll[accountId]).map(([hashtagId, hashtag]: [string, any]) => [
        hashtagId,
        hashtag
      ])
    )
  }
  return new SerializableMap<string, SelectedHashtagType>()
}

export const Hashtags: React.FC = () => {
  const [hashtags, setHashtags] = useState<IHashtag[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const { currentAccount, products, perms, userPermissions, userProducts } = useUser()

  const { syncActivationListWithSelectedHashtags } = useStoredTargetedActivations()
  const [selectedHashtags, setSelectedHashtags] = useState<SerializableMap<string, SelectedHashtagType>>(
    getDefaultSelectedHashtags(currentAccount?.accountId)
  )
  const [selectedHashtag, setSelectedHashtag] = useState<IHashtag>()
  const [openTargetActivationPanel, setOpenTargetActivationPanel] = useState(false)
  const [activationStatus, setActivationStatus] = useState<ActivationStatusType>()

  // Filters
  const [categoryOptions, setCategoryOptions] = useState<ICategoryOption[]>()
  const [dateOptions, setDateOptions] = useState<IDateOption[]>()
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([])
  const [sortByOptions, setSortByOptions] = useState<ISortByOption[]>()

  const [selectedCategory, setSelectedCategory] = useState<string[]>(
    JSON.parse(sessionStorage.getItem('tiktokSelectedCategories') || '["ALL"]')
  );
  const [selectedDate, setSelectedDate] = useState<string>('7DAY')
  const [selectedStatus, setSelectedStatus] = useState<IStatusOption[]>([])
  const [selectedSortBy, setSelectedSortBy] = useState<string>('RANK_POSITION')
  const [search, setSearch] = useState<string>('')

  // Pagination
  const [previousPagesHashtags, setPreviousPagesHashtags] = useState<IHashtag[]>([])
  const [page, setPage] = useState(0)
  const itemsPerPage = 20

  // Hashtags data
  const { hashtagsQuery } = useHashtags(selectedCategory, selectedDate, selectedStatus, selectedSortBy, page, itemsPerPage, search, currentAccount?.accountId)
  const isLoading = hashtagsQuery.isLoading;
  const error = hashtagsQuery.error;
  const hashtagsData = hashtagsQuery?.data?.hashtags.result ?? [];
  const config = hashtagsQuery?.data?.config;
  const hasNext = hashtagsQuery?.data?.hashtags?.hasNext ?? false;

    // Hashtags status
  const { hashtagsStatusQuery } = useHashtagsStatus(hashtags.map((hashtag) => hashtag.hashtagId), currentAccount?.accountId)
  const hashtagsStatusData = hashtagsStatusQuery.data
  
  // Hashtags audience
  const { hashtagAudienceQuery } = useHashtagAudience(selectedHashtag?.hashtagId ?? '', selectedDate)
  const audienceIsLoading = hashtagAudienceQuery.isLoading
  const audienceError = hashtagAudienceQuery.error
  const hashtagAudienceData = hashtagAudienceQuery?.data

  // Hashtags Alert Modal
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [authenticationError, setAuthenticationError] = useState(false)
  const { authenticationStatusQuery } = useTargetStatus(currentAccount?.accountId!)

  useEffect(() => {
    if (!isLoading && hashtagsData && config) {
      setHashtags(previousPagesHashtags.concat(hashtagsData))
      setPreviousPagesHashtags([]) // reset saved narratives from new page
      config.categoryOptions.map((category) => {
        category.label = category.label.replaceAll('_', ' ').toLowerCase().replace(/^./, char => char.toUpperCase())
      })
      setCategoryOptions(config.categoryOptions)
      setDateOptions(config.dateOptions)
      setStatusOptions(config.statusOptions)
      setSortByOptions(config.sortByOptions)
    }
    if (error) {
      logError(error);
    }
  }, [hashtagsData]);

  useEffect(() => {
    setPage(0)
  }, [selectedCategory, selectedDate, selectedStatus, selectedSortBy, search])

  useEffect(() => {
    const parentDiv = document.querySelector('.sc-hRJfrW')
    if (parentDiv && parentDiv instanceof HTMLElement) {
      parentDiv.style.backgroundColor = '#F6FAFE'
    }

    return () => {
      if (parentDiv && parentDiv instanceof HTMLElement) {
        parentDiv.style.backgroundColor = ''
      }
    }
  }, [])

  useEffect(() => {
    let selectedActivationsAll = getStoredSelectedHashtags()
    // Merges previous stored value with new selectedMoments
    if (selectedActivationsAll && currentAccount?.accountId) {
      const prevSelectedActivations: SerializableMap<string, SelectedHashtagType> =
        selectedActivationsAll[currentAccount?.accountId]
      if (prevSelectedActivations) {
        if (!selectedHashtags) {
          setSelectedHashtags(() => prevSelectedActivations)
        } else {
          selectedActivationsAll[currentAccount?.accountId] = new SerializableMap(selectedHashtags)
        }
      } else {
        selectedActivationsAll[currentAccount?.accountId] = new SerializableMap(selectedHashtags)
      }
    } else
      selectedActivationsAll =
        (currentAccount?.accountId && { [currentAccount?.accountId]: new SerializableMap(selectedHashtags) }) ||
        selectedActivationsAll

    if (currentAccount?.accountId) {
      selectedActivationsAll[currentAccount?.accountId] = new SerializableMap(selectedHashtags)
    }
    syncActivationListWithSelectedHashtags()
    sessionStorage.setItem('selectedHashtags', JSON.stringify(selectedActivationsAll))
  }, [selectedHashtags])

  useEffect(() => {
    if (!isLoading) {
      const nonOfflineHashtags = hashtags.filter((h) => h.status.toLowerCase() !== 'offline').map((h) => h.hashtagId)

      const notIncludedInSelectedItems = nonOfflineHashtags.filter((id) => !selectedHashtags.has(id))

      setSelectAll(notIncludedInSelectedItems.length === 0)
    }
  }, [selectedHashtags, hashtags, isLoading])

  useEffect(() => {
    const storedSelectedCategory = JSON.parse(sessionStorage.getItem('tiktokSelectedCategories') || '[]');
    if (JSON.stringify(storedSelectedCategory) === JSON.stringify(selectedCategory)) return;
    sessionStorage.setItem('tiktokSelectedCategories', JSON.stringify(selectedCategory));
  }, [selectedCategory]);

  const handleSelectAllChange = (checked: boolean) => {
    if (checked) {
      setSelectAll(() => true)
      setSelectedHashtags((prev) => {
        const next = new SerializableMap(prev)
        hashtags
          .forEach(({ hashtagId, hashtagName, categoryName, status }) =>
            (status.toLowerCase() !== 'offline') &&
            next.set(hashtagId, { hashtagId, hashtagName, categoryName })
          )
        return next
      })
    } else {
      setSelectAll(() => false)
      setSelectedHashtags(() => new SerializableMap<string, SelectedHashtagType>())
    }
  }

  const handleCheckboxChange = (_hashtagId: string) => {
    if (selectedHashtags.has(_hashtagId)) {
      setSelectedHashtags((prev) => {
        const next = new SerializableMap(prev)
        next.delete(_hashtagId)
        return next
      })
    } else {
      setSelectedHashtags((prev) => {
        const next = new SerializableMap(prev)
        const selectedHashtag = hashtags.find(({ hashtagId }) => hashtagId === _hashtagId)
        selectedHashtag &&
          next.set(_hashtagId, {
            hashtagId: _hashtagId,
            categoryName: selectedHashtag.categoryName,
            hashtagName: selectedHashtag.hashtagName
          })
        return next
      })
    }
  }

  const handleStatusChange = (values: string[], event: SyntheticEvent<HTMLElement, Event>) => {
    const selectedOptions = statusOptions
      .filter(option => values.includes(option.value))
      .map(({ label, value }) => ({ label, value }));
    setSelectedStatus(selectedOptions);
  };

  const handleHashtagsTarget = () => {
    if (authenticationStatusQuery.data) {
      if (!authenticationStatusQuery.data.error) {
        setOpenTargetActivationPanel(true)
      } else {
        setAuthenticationError(authenticationStatusQuery.data.source == 'authentication')
        setOpenAlertModal(true)
      }
    } else {
      const isCampaignError = authenticationStatusQuery.error.config.url.includes('campaigns')
      setAuthenticationError(!isCampaignError)
      setOpenAlertModal(true)
    }
  }

  const handleSearch = (keyword: string) => {
    setSearch(keyword)
  }

  return (
    <div className="container mx-auto">
      <div className="mb-4 sightlyPanelHeader">
        <div className="flex flex-row font-bold main-title">TikTok Hashtags</div>
      </div>
      {openAlertModal && (
        <AlertModal
          open={openAlertModal}
          handleClose={() => setOpenAlertModal(false)}
          authentication={authenticationError}
        />
      )}
      <div className="w-min-40">
        {isLoading && !categoryOptions && !dateOptions && !statusOptions ? (
          <Loader
            content="Loading..."
            center
          />
        ) : (
          <>
            <div className="flex flex-wrap items-center justify-between py-4 mb-2 w-100">
              <div className="flex space-x-4">
                <div
                  data-testid="tiktok-hashtags-filters-panel-categoriesDropdown"
                  className="font-bold text-sightly-blue"
                >
                  <CheckTreePicker
                    id="categoriesDropdown"
                    style={{ width: 250 }}
                    className="pendo-date-dropdown-select"
                    preventOverflow
                    data={categoryOptions ?? []}
                    labelKey="label"
                    valueKey="value"
                    onChange={setSelectedCategory}
                    value={selectedCategory}
                    placeholder="Category"
                    cleanable={false}
                    searchable={true}
                  />
                </div>
                <div
                  data-testid="tiktok-hashtags-filters-panel-dateDropdown"
                  className="font-bold text-sightly-blue"
                >
                  <SightlySelect
                    id="dateDropdown"
                    width={150}
                    options={dateOptions}
                    buttonClass="pendo-date-dropdown-select"
                    labelKey="label"
                    valueKey="value"
                    onChange={setSelectedDate}
                    value={selectedDate}
                  />
                </div>
                <div data-testid="tiktok-hashtags-filters-panel-statusDropdown" className="font-bold text-sightly-blue">
                  <CheckTreePicker
                    id="statusDropdown"
                    style={{ width: 150 }}
                    preventOverflow
                    data={statusOptions}
                    labelKey="label"
                    valueKey="value"
                    onChange={handleStatusChange}
                    value={selectedStatus.map(option => option.value)}
                    placeholder="Hashtag Status"
                    cleanable={false}
                    searchable={false}
                  />
                </div>
              </div>
              <div className="font-bold">
                <SightlyInput
                  id="searchBox"
                  dataTestId="search-box"
                  width={'222px'}
                  placeholder="Search hashtags"
                  onChange={handleSearch}
                  value={search}
                  hasSearchIcon
                />
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between mb-6">
              <div className="flex items-center">
                <div>
                  <SightlyCheckbox
                    indeterminate={selectedHashtags.size > 0 && !selectAll}
                    handleChange={handleSelectAllChange}
                    id="selectAllTodo"
                    checked={selectAll}
                  />
                </div>
                <div className="ml-3 mr-4">
                  {selectedHashtags.size > 0 && (
                    <span>
                      {selectedHashtags.size}{' '}
                      {selectedHashtags.size > 1 ? 'hashtags' : 'hashtag'} selected
                    </span>
                  )}
                </div>
                <div data-testid="tiktok-hashtags-action-buttons-target">
                  <SightlyButton
                    id="targetButton"
                    handleClick={handleHashtagsTarget}
                    text="Target"
                    disabled={
                      selectedHashtags.size === 0 ||
                      !userCanPermissionProductQuota({
                        requiredPermissionValue: perms.TIKTOK_ACTIVATIONS_READ,
                        requiredProductValue: products.TIKTOK_ACTIVATIONS,
                        userPermissions: userPermissions,
                        userProducts: userProducts,
                        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                      })
                    }
                    type="indigo"
                    icon={
                      <img
                        data-testid={'target-image'}
                        src={boltIcon}
                        className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                      />
                    }
                  />
                </div>
              </div>
              <div
                data-testid="tiktok-hashtags-filters-panel-sortDropdown"
                className="font-bold"
              >
                <SightlySelect
                  id="sortDropdown"
                  width={220}
                  options={sortByOptions}
                  buttonClass="pendo-date-dropdown-select"
                  labelKey="label"
                  valueKey="value"
                  placeholder="Sort by:"
                  onChange={setSelectedSortBy}
                  value={selectedSortBy}
                />
              </div>
            </div>
            {activationStatus &&
              activationStatus.length &&
              activationStatus.map(({ message, type }) => (
                <div key={type} className='pb-2'>
                  <MessageTailwind
                    show={true}
                    message={
                      <div className='flex gap-1'>
                        <p>{message} </p>
                        <Link to={PATH_ACTIVATIONS_TIKTOK} className='text-sightlyPurple' >View Here</Link>
                      </div>}
                    type={type === 'SUCCESS' ? 'success' : 'error'}
                  />
                </div>
              ))}

            <HashtagsInfiniteScroll
              hashtags={hashtags}
              hasNext={hasNext}
              isLoading={isLoading}
              selectedHashtags={selectedHashtags}
              hashtagsStatus={hashtagsStatusData}
              handleCheckboxChange={handleCheckboxChange}
              setSelectedHashtag={setSelectedHashtag}
              setPreviousPagesNarratives={setPreviousPagesHashtags}
              setPage={setPage}
              page={page}
              dateRange={dateOptions?.find((date) => date.value === selectedDate)?.label ?? selectedDate}
              clearSearchFilter={() => setSearch('')}
              searchTerm={search}
            />

            <div className="animate-in fade-in">
              {selectedHashtag && hashtagAudienceData?.audienceAges && (
                <InsightsPanel
                  hashtag={selectedHashtag}
                  ageAudiences={hashtagAudienceData.audienceAges}
                  handleClose={() => {
                    setSelectedHashtag(undefined)
                  }}
                  open={true}
                  dateRange={dateOptions?.find((date) => date.value === selectedDate)?.label ?? selectedDate}
                  audienceIsLoading={audienceIsLoading}
                  audienceError={audienceError}
                />
              )}
            </div>
          </>
        )}
      </div>

      {openTargetActivationPanel && (
        <ActivationManagerProvider hashtags={Array.from(selectedHashtags.values())}>
          <ActivationManager
            open={openTargetActivationPanel}
            setOpen={setOpenTargetActivationPanel}
            setSelectedHashtags={setSelectedHashtags}
            setActivationStatus={setActivationStatus}
          />
        </ActivationManagerProvider>
      )}
    </div>
  )
}
