import React from 'react'
import { useNavigate } from '@tanstack/react-location'

import { BrandProfile } from '@/classes/brandProfile'
import { ACTIVATIONS_SIDEBAR_DESCRIPTION, ACTIVATIONS_SIDEBAR_TITLE } from '@/views/Discover/Activations/v2/constants'

type SideBarProps = {
  activationsStatusData:
    | Map<
        number,
        {
          activeCount: number
          pendingCount: number
          completeCount: number
        }
      >
    | undefined
  currentBrandProfileId: number | undefined
  setCurrentBrandProfileId: React.Dispatch<React.SetStateAction<number | undefined>>
  brandProfilesMap: Map<number, string>
}

export const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col flex-none w-64" >
      <div className="flex flex-col flex-1 min-h-0 bg-white border-r border-gray-200">
        <div
          data-testid="activations-sidebar"
          className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto divide-y"
        >
          <div className="flex flex-col items-start flex-shrink-0 px-4">
            <div className="text-lg font-bold text-gray-900 ">{ACTIVATIONS_SIDEBAR_TITLE}</div>
            <div className="text-xs text-gray-500 ">{ACTIVATIONS_SIDEBAR_DESCRIPTION}</div>
          </div>
          <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
            <div className="flex flex-col mt-4">
              {props.activationsStatusData &&
                Array.from(props.activationsStatusData.keys()).map((brandProfileId: number) => {
                  const isCurrentlySelected = brandProfileId === props.currentBrandProfileId
                  return (
                    <div
                      key={brandProfileId}
                      data-testid="activations-sidebar-brand-profile-option"
                      id="pendo-activations-sidebar-brand-profile-option"
                      className="flex border border-transparent rounded-lg cursor-pointer "
                      onClick={() => {
                        props.setCurrentBrandProfileId(brandProfileId)
                        navigate({
                          search: (old) => {
                            return {
                              ...old,
                              brandProfileId
                            }
                          }
                        })
                      }}
                    >
                      <div className={`${isCurrentlySelected ? 'bg-sightlyBlue': 'bg-white'} w-1`}></div>
                      <div className={`p-2 flex-1 ${isCurrentlySelected && 'bg-blue-100'}`}>
                        <div className="flex items-center justify-between gap-2">
                          <p data-testid="activations-sidebar-brand-profile-name" className='break-all'>
                            {props.brandProfilesMap.get(brandProfileId) ?? ''}
                          </p>
                          <p
                            data-testid="activations-sidebar-brand-profile-active-count"
                            className="px-3 py-1 text-center text-white rounded-full bg-sightlyBlue"
                          >
                            {props.activationsStatusData?.get(brandProfileId)?.activeCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
