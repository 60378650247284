import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { logError } from '../../../../../utils'
import { api } from './api'
import { queryKeys } from './queryKeys'
import toast from 'react-hot-toast'
import { IProps} from './types'

export const useMediaResponsibility = ({ brandProfileId }: IProps) => {

  const mediaResponsibilitiesLookupQuery = useQuery(
    queryKeys.mediaResponsibilitiesLookup(),
    () => api.getMediaResponsibilitiesLookup(),
    {
      onError: (err) => {
          logError(err)
      },
      enabled: false
    }
  )

  const getMediaPreferencesQuery = useQuery(
    queryKeys.mediaPreferences({brandProfileId}),
    () => api.getBrandMediaResponsibilities({brandProfileId}),
    {
      enabled: false,
      onError: (err) => {
        logError(err)
      },
    }
  )
  const queryClient = useQueryClient()
  const addBrandMediaResponsibilities = useMutation(api.addBrandMediaResponsibilities, {
    
    onSettled: () => {
        queryClient.invalidateQueries(queryKeys.mediaPreferences({brandProfileId}))
    },
    onSuccess: () => {
        toast.success('Changes saved')
    },
    onError: (err) => {
        logError(err)
    }
  })

  return {
      mediaResponsibilitiesLookupQuery,
      getMediaPreferencesQuery,
      addBrandMediaResponsibilities
  }
}
