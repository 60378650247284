import React from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import RelationshipSelectForm from './components/RelationshipSelectForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { competitorObjValidation, influencerObjValidation } from '@/schemas/brandProfiles'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import NameSearchField from './NameSearchField'
import { SectionTypeOne } from './QueryTypeOneSection'
import { userAccountAxios } from '@/axiosInstances'
import { typeOneSections } from './constants'
import { useMatch, useSearch } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'
import WebsiteInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/WebsiteInputForm";
import WikiInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/WikiInputForm";
import TwitterInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/TwitterInputForm";
import ResetFieldsButton from "@/views/BrandProfiles/BrandProfile/components/Queries/components/ResetFieldsButton";
import { products } from '@/staticData/products'
import { MyLocationGenerics } from '@/classes/utils'

enum fields {
    'name' = 'name',
    'id' = 'id',
    'twitterProfileUrl' = 'twitterProfileUrl',
    'wikiUrl' = 'wikiUrl',
    'websiteUrl' = 'websiteUrl',
}

type IProps = {
    item: SectionTypeOne
    open: boolean
    handleClose: () => void
    disabled?: boolean
    type: 'edit' | 'add'
    queryType: typeOneSections
}

const QueryTypeOneModal = ({ item, open, handleClose, disabled, type, queryType }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch()
    const {
        accountId
    } = useSearch<MyLocationGenerics>()
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = React.useState(false)

    const { register, handleSubmit, reset, control, formState, setValue, getValues, watch } = useForm<SectionTypeOne>({
        defaultValues: item,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(
            queryType === typeOneSections.influencers ? influencerObjValidation : competitorObjValidation
        )
    })

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: Partial<SectionTypeOne>) => {
        setIsSaving(true)
        delete data.id
        delete data.relationshipName

        try {
            if (type === 'edit') {
                const url = `/brand-profile/${brandProfileId}/${queryType}/${item.id}`
                // disable isAIGenerated to false since it's being modified by the user
                data.isAIGenerated = false
                await userAccountAxios.patch(url, data)
                toast.success('Saved')
            } else {
                const url = `/brand-profile/${brandProfileId}/${queryType}`
                await userAccountAxios.post(url, data)
                // to fetch the latest product quota
                await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.QUERIES))
                toast.success('Added')
                reset()
            }
            queryClient.invalidateQueries(rqKeys.brandProfileQuery(queryType, Number(brandProfileId)))
            queryClient.invalidateQueries({ queryKey: rqKeys.brandProfileBasic(Number(brandProfileId)) })
        } catch (err) {
            toast.error('Error')
            setIsSaving(false)
        }
        setIsSaving(false)
    }

    const errors = formState.errors

    React.useEffect(() => {
        if (item && type === 'edit') {
            reset(item)
        }
    }, [item])

    const typeInfo = React.useMemo(() => {
        if (queryType === typeOneSections.philanthropic) {
            return {
                humanName: 'Charity'
            }
        }
        if (queryType === typeOneSections.influencers) {
            return {
                humanName: 'Influencer'
            }
        }
    }, [queryType])

    const watchName = watch('name')

    return (
        <PortalModal
            hasClose={true}
            handleClose={_handleClose}
            open={open}
            footerButtons={[
                <SightlyButton
                    id="rename"
                    loading={isSaving}
                    disabled={!formState.isValid || !formState.isDirty}
                    text={type === 'edit' ? 'Save' : 'Add'}
                    handleClick={handleSubmit(onSubmit)}
                    type="primary"
                />
            ]}
        >
            <h4 style={{ marginBottom: 30, width: 376 }}>
                {type === 'edit' ? `Edit ${typeInfo?.humanName}` : `Add New ${typeInfo?.humanName}`}
            </h4>

            <div className="form-container">
                <NameSearchField
                    dataTestId={'input-name'}
                    name={'Name'}
                    disabled={disabled}
                    handleSetWikiUrl={(val: never) =>
                        setValue(fields.wikiUrl, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    handleSetName={(val: never) =>
                        setValue(fields.name, val, {
                            shouldValidate: true,
                            shouldDirty: true
                        })
                    }
                    nameValue={watchName}
                    error={errors?.name?.message}
                />

                <WikiInputForm
                    control={control}
                    error={errors?.wikiUrl?.message}
                    name={fields.wikiUrl}
                    register={register}
                    disabled={disabled}
                />

                <TwitterInputForm
                    control={control}
                    error={errors?.twitterProfileUrl?.message}
                    name={fields.twitterProfileUrl}
                    register={register}
                    disabled={disabled}
                />

                {queryType !== typeOneSections.influencers && (
                    <WebsiteInputForm
                        control={control}
                        error={errors?.websiteUrl?.message}
                        name={fields.websiteUrl}
                        register={register}
                        disabled={disabled}
                    />
                )}

                <RelationshipSelectForm
                    control={control}
                    disabled={disabled}
                />

                {type === 'add' && formState.isDirty && (
                    <ResetFieldsButton
                        reset={reset}
                    />
                )}
            </div>

            <style>{`
				.form-container {
					display: grid;
					gap: 24px;				
					grid-template-columns: repeat(auto-fit);			
				}		
			`}</style>
        </PortalModal>
    )
}

export default QueryTypeOneModal
