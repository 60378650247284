import React from 'react'
import { routes } from '@/routes'
import SidebarItem from './SidebarItem'
import { Cog8ToothIcon, UserIcon } from '@heroicons/react/24/outline'
import { useNavigate } from '@tanstack/react-location'
import { routeIsActive } from '@/utils_ts'

const Sidebar = () => {
    const navigate = useNavigate();

    const handleNavigation = (route: string) => {
        navigate({ to: route })
    }

    const sidebarSection1Items = [
        {
            displayText: "Roles",
            itemKey: "roles",
            route: routes.admin.roles.path,
            dataTestId: "roles-sidebar-item",
            icon: <UserIcon/>,
        },
        {
            displayText: "Permissions",
            itemKey: "permissions",
            route: routes.admin.permissions.path,
            dataTestId: "permissions-sidebar-item",
        },
    ]

    const sidebarSection2Items = [
        {
            displayText: "Scenarios",
            itemKey: "scenarios-v2",
            route: routes.admin.scenariosV2.path,
            dataTestId: "scenarios-v2-sidebar-item",
            icon: <Cog8ToothIcon/>,
        },
        {
            displayText: "Opinions",
            itemKey: "opinions",
            route: routes.admin.opinions.path,
            dataTestId: "opinions-sidebar-item",
        },
        {
            displayText: "IAB Categories",
            itemKey: "iab-categories",
            route: routes.admin.iabCategories.path,
            dataTestId: "iab-categories-sidebar-item",
        },
        {
            displayText: "Evergreen Channels",
            itemKey: "evergreen-channels",
            route: routes.admin.evergreenChannels.path,
            dataTestId: "evergreen-channels-sidebar-item",
        },
    ]

    return (
      <div
        className="min-w-[256px] border-r border-solid border-gray-200"
        data-testid="sidebar-component"
      >
        <p className="text-base font-bold text-sightlySlate pt-5 pb-4 pl-5">Admin</p>
        <div className="pb-[60px]">
            {
                sidebarSection1Items.map(item => (
                    <SidebarItem
                        key={item.itemKey}
                        displayText={item.displayText}
                        handleNavigation={() => handleNavigation(item.route)}
                        icon={item.icon}
                        dataTestId={item.dataTestId}
                        isActive={routeIsActive([item.route])}
                    />
                ))
            }
        </div>
        <div>
            {
                sidebarSection2Items.map(item => (
                    <SidebarItem
                        key={item.itemKey}
                        displayText={item.displayText}
                        handleNavigation={() => handleNavigation(item.route)}
                        icon={item.icon}
                        dataTestId={item.dataTestId}
                        isActive={routeIsActive([item.route])}
                    />
                ))
            }
        </div>
      </div>
    )
}

export default Sidebar
