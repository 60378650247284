import React from 'react'
import Button from 'rsuite/lib/Button'
import PortalModal from './PortalModal/PortalModal'

interface IProps {
	text: string
	headerText: string
	closeButtonText: string
	headerColor: string
	topHeaderText: string
	show: boolean
	setShow: Function
	onClose: Function
	headerIcon: React.ReactNode
}

const SightlyAlert: Function = ({
	headerColor,
	text,
	topHeaderText,
	headerText,
	show,
	closeButtonText,
	setShow,
	onClose,
	headerIcon
}: IProps) => {
	const handleClose = () => {
		setShow(false)
		onClose()
	}
	return (
		<PortalModal
			cannotClose
			open={show}
			handleClose={handleClose}
			footerButtons={[
				<Button
					style={{
						height: 40,
						fontWeight: 600,
						lineHeight: '22px',
						borderRadius: 3
					}}
					onClick={handleClose}>
					{closeButtonText}
				</Button>
			]}>
			<div
				style={{
					background: headerColor,
					color: 'white',
					height: 40,
					position: 'absolute',
					textAlign: 'center',
					top: 0,
					left: 0,
					right: 0,
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12,
					lineHeight: '16.94px',
					fontWeight: 600,
					paddingTop: 8,
					display: 'flex',
					justifyContent: 'center'
				}}>
				{headerIcon && (
					<div style={{ height: 40, paddingRight: 7 }}>{headerIcon}</div>
				)}

				<div style={{ height: 40, paddingTop: 2.5 }}>{topHeaderText}</div>
			</div>

			<div
				style={{
					fontSize: 14,
					marginTop: 32,
					marginBottom: 30,
					textAlign: 'center',
					paddingLeft: 144,
					paddingRight: 144,
					paddingTop: 72,
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					gap: 48
				}}>
				<h4>{headerText}</h4>
				<div
					style={{
						textAlign: 'left',
						width: 633,
						fontWeight: 400,
						lineHeight: '22px',
						color: '#00000'
					}}>
					{text}
				</div>
			</div>
		</PortalModal>
	)
}

export default SightlyAlert
