import { ZodType, z } from 'zod';

export interface ChangePasswordFormType {
    password: string
    confirmPassword: string
}

export const ChangePasswordFormSchema: ZodType<ChangePasswordFormType> = z.object({
    password: z.string()
        .min(8, { message: 'At least 8 characters' })
        .max(64, { message: 'At most 64 characters' })
        .regex(/.*\d.*/, 'At least 1 number')
        .regex(/.*[A-Z].*/, 'At least 1 uppercase character')
        .regex(/.*[a-z].*/, 'At least 1 lowercase character')
        .regex(/.*[!"#$%&'()*+-.//:;<=>?@\[\]^_`{}|~\\].*/, { message: 'At least 1 symbol' }),
    confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, { message: 'Your password does not match', path: ['confirmPassword'] })
