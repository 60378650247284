import * as React from 'react';
import useUser from "@/hooks/useUser";
import {useCallback} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {userAccountAxios} from "@/axiosInstances";
import {useSearch} from "@tanstack/react-location";
import {MyLocationGenerics} from "@/classes/utils";

// If using TypeScript, add the following snippet to your file as well.
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


export function StripeCheckout() {
    const { currentAccount } = useUser()
    const { priceId } = useSearch<MyLocationGenerics>()

    const fetchClientSecret = useCallback(async () => {
      const response = await userAccountAxios.post("/stripe/create-checkout-session", {
        accountId: currentAccount?.accountId,
        stripePriceId: priceId
      })
      return response.data.clientSecret
    }, []);

    const options = {fetchClientSecret,};

    return (
      <div id="checkout">
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
          >
              <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
      </div>
    )
}
