import React, { useState } from 'react'
import { Button, Modal } from 'rsuite';

export interface IProps {
    title: string
    errorMessages: string[]
    isErrorModalVisible: boolean
    setIsErrorModalVisible: Function
}

export const InformationModal = ( { title, errorMessages, isErrorModalVisible, setIsErrorModalVisible } : IProps) => {

    const handleCloseErrorModal = () => {
        setIsErrorModalVisible(false)
    }

    return (
        <div>
            <Modal
                data-testid='div-report-generator-error-modal'
                show={isErrorModalVisible}
                onHide={handleCloseErrorModal}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id='close-modal-error-button'
                        data-testid={'close-modal-error-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#7B61FF' }}
                        onClick={handleCloseErrorModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
