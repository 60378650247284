import { Menu, Transition } from '@headlessui/react'
import { Fragment, ReactElement } from 'react'
import { ChevronDownIcon, NoSymbolIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { ReactComponent as MonitorIcon } from '@/assets/img/monitor.svg'
import { ReactComponent as DontMonitorIcon } from '@/assets/img/dontmonitor.svg'
import { ReactComponent as TargetIcon } from '@/assets/img/target.svg'

import { accentColor } from '@/assets/jss/colorConstants'

type OptionType = {
    id: 1 | 2 | 3 | 4
    label: string
    bgColor: string
    icon: ReactElement
}

const _options: OptionType[] = [
    {
        id: 3,
        label: 'Target',
        bgColor: 'green',
        icon: (
            <TargetIcon
                style={{ height: 18, width: 18 }}
                className="mr-2"
                aria-hidden="true"
                color={accentColor}
            />
        )
    },
    {
        id: 1,
        label: 'Block',
        bgColor: 'red',
        icon: (
            <NoSymbolIcon
                style={{ height: 18, width: 18 }}
                className="mr-2"
                aria-hidden="true"
                color="#DC2626"
            />
        )
    },
    {
        id: 2,
        label: 'Monitor',
        bgColor: 'purple',
        icon: (
            <MonitorIcon
                style={{ height: 18, width: 18 }}
                className="mr-2"
                aria-hidden="true"
            />
        )
    }
]

export function SightlyActionDropdown({
    value,
    handleChange,
    disabled,
    placeholder,
    includeDontMonitor
}: {
    value: number | null
    handleChange: (val: number) => void
    disabled?: boolean
    placeholder?: string
    includeDontMonitor?: boolean
}) {
    const options = includeDontMonitor
        ? _options.concat({
              id: 4,
              label: 'Do not monitor',
              bgColor: 'lightgrey',
              icon: (
                  <DontMonitorIcon
                      style={{ height: 18, width: 18 }}
                      className="mr-2"
                      aria-hidden="true"
                  />
              )
          })
        : _options
    const selectedValue = options.filter((option) => option.id === value)[0] || {}
    const bgColor = value === 1 ? '#FBBEBE' : value === 2 ? '#EBE7FC' : value === 3 ? '#BFE1FB' : '#f1f2f7'
    const textColor = value === 1 ? '#EA430F' : value === 2 ? '#5B32EF' : value === 3 ? '#26AAE1' : ''

    return (
        <div
            data-testid="action-dropdown"
            className="text-right"
        >
            <Menu
                as="div"
                className="relative inline-block text-left"
            >
                <div>
                    <Menu.Button
                        disabled={disabled}
                        style={{ backgroundColor: bgColor, color: textColor }}
                        className="inline-flex items-center justify-center w-full px-2 py-1 text-xs bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                        <>
                            {value ? (
                                <>
                                    {selectedValue.icon}
                                    {selectedValue.label}
                                </>
                            ) : (
                                <>{placeholder || ''}</>
                            )}

                            <ChevronDownIcon
                                className="w-5 h-5 ml-2 -mr-1"
                                aria-hidden="true"
                                color={textColor}
                            />
                        </>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {options.map((option) => {
                                return (
                                    <Menu.Item key={option.id}>
                                        {({ active }) => (
                                            <button
                                                disabled={disabled}
                                                onClick={() => handleChange(option.id)}
                                                className={`${
                                                    option.id === value ? 'bg-slate-100' : ''
                                                } group flex w-full items-center rounded-md px-2 py-2 text-xs hover:bg-slate-100 hover:text-gray-900`}
                                            >
                                                <>
                                                    {option.icon}
                                                    {option.label}
                                                </>
                                            </button>
                                        )}
                                    </Menu.Item>
                                )
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
