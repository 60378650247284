import React from 'react'
import { ListedBoardsType } from '@/views/Discover/Moments/v2/TargetMoment/ToolBar'
import { CheckPicker, Icon } from 'rsuite'
import DataPickerFooter from '@/components/DataPickerFooter'

export const BoardsFilter = (props: {
  boardsOptions: ListedBoardsType
  handleFilterBoards: (filteredBoardIds: number[]) => void
}) => {
  const [filteredBoardIds, setFilteredBoards] = React.useState<number[]>()
  const handleRenderMenu = (menu: React.ReactNode) => {
    if (!props.boardsOptions || props.boardsOptions.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Icon
            icon="spinner"
            spin
          />{' '}
          Loading...
        </p>
      )
    }
    return menu
  }

  const handleChange = (value: any[]) => {
    setFilteredBoards((prev) => value)
    props.handleFilterBoards(value)
  }

  const renderFooter = () => {
    if (!props.boardsOptions.length) return
    return (
      <DataPickerFooter
        rowCount={props.boardsOptions.length}
        handleClear={() => {
          handleChange([])
        }}
        handleSelectAll={() => {
          setFilteredBoards((prev) => props.boardsOptions.map(({ boardId }) => boardId))
        }}
      />
    )
  }

  return (
    <div
      data-testid="act-mgr-target-moment-boards-filter"
    >
      <CheckPicker
        data={props.boardsOptions}
        renderMenu={(menu: React.ReactNode) => handleRenderMenu(menu)}
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Boards"
        value={filteredBoardIds}
        labelKey="boardName"
        valueKey="boardId"
        onChange={handleChange}
        onClean={() => handleChange([])}
        renderExtraFooter={renderFooter}
      />
      <style>
        {`
          .rs-picker-toggle .rs-picker-toggle-placeholder{
            color: unset;
          }
        `}
      </style>
    </div>
  )
}
