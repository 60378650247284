import React from 'react'
import { useForm } from 'react-hook-form'
import { useMatch, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import AIGenerateModal from './components/AIGenerateModal'
import { ZodType, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { AIGeneratorBoundaryValues, typeOneSections } from './constants'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import { products } from '@/staticData/products'

export type EventAIGenerationFormType = {
  targetSuggestionCount: number
  monitorSuggestionCount: number
}

export const EventAIGenerationFormSchema: ZodType<EventAIGenerationFormType> = z.object({
  targetSuggestionCount: z.coerce
    .number()
    .gte(AIGeneratorBoundaryValues.minValue)
    .lte(AIGeneratorBoundaryValues.maxValue, {
      message: `Number can't be more than ${AIGeneratorBoundaryValues.maxValue}`
    }),
  monitorSuggestionCount: z.coerce
    .number()
    .gte(AIGeneratorBoundaryValues.minValue)
    .lte(AIGeneratorBoundaryValues.maxValue, {
      message: `Number can't be more than ${AIGeneratorBoundaryValues.maxValue}`
    }),
})

export const InputStyleWrapper = (props: { children: React.ReactNode }) => (
  <div className="p-4 bg-sightlyLightBlue rounded-md border border-[#BAE6FD]">{props.children}</div>
)

const CustomLabel = (props: { mainCaption: string; subCaption: string }) => (
  <div className="flex flex-col">
    <p className="text-gray-700">{props.mainCaption}</p>
    <p className="text-sm text-sky-700">{props.subCaption}</p>
  </div>
)

export type EventAIGenerationType = {
  relationshipId: number
  brandName: string
  suggestionCount: number
  companySummary?: string | undefined
  companyMentality?: string | undefined
  existingQueryTypes: string[]
}

const EventAIGenerationForm = (props: {
  isAIGenerate: boolean
  setIsAIGenerate: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmitForm: (data: EventAIGenerationFormType) => void
}) => {
  const [totalSuggestionCount, setTotalSuggestionCount] = React.useState(0)
  const {
    params: { brandProfileId }
  } = useMatch()
  const { accountId } = useSearch<MyLocationGenerics>()
  const { productQuotaTally: queriesQuotaTally } = useProductUsage({ accountId, productName: products.QUERIES })
  const { productQuotaTally: aiCreditsQuotaTally } = useProductUsage({ accountId, productName: products.AI_CREDITS })
  const maxQueryQuota = React.useMemo(() => {
    return queriesQuotaTally ? queriesQuotaTally.productBalance : AIGeneratorBoundaryValues.maxValue
  }, [queriesQuotaTally])

  const maxAICreditsQuota = React.useMemo(() => {
    return aiCreditsQuotaTally ? aiCreditsQuotaTally.productBalance : AIGeneratorBoundaryValues.maxValue
  }, [aiCreditsQuotaTally])

  const maxQueryValue = Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota, maxAICreditsQuota)
  const EventAIGenerationFormSchema: ZodType<EventAIGenerationFormType> = z.object({
    targetSuggestionCount: z.coerce
      .number()
      .gte(AIGeneratorBoundaryValues.minValue)
      .lte(maxQueryValue, {
        message: `Suggestion Count exceeds the max limit of ${maxQueryValue}`
      }),
    monitorSuggestionCount: z.coerce
      .number()
      .gte(AIGeneratorBoundaryValues.minValue)
      .lte(maxQueryValue, {
        message: `Suggestion Count exceeds the max limit of ${maxQueryValue}`
      }),
  })

  const { register, handleSubmit, reset, control, formState, watch } = useForm<EventAIGenerationFormType>({
    defaultValues: {
      targetSuggestionCount: 0,
      monitorSuggestionCount: 0,
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(EventAIGenerationFormSchema)
  })

  React.useEffect(() => {
    const sum =
      Number(watch('targetSuggestionCount')) +
      Number(watch('monitorSuggestionCount'))
    setTotalSuggestionCount(() => sum)
  }, [formState])

  const onSubmit = async (data: EventAIGenerationFormType) => {
    props.handleSubmitForm(data)
    reset()
  }

  const disableSubmit = React.useMemo(() => {
    let canAddQuery = true
    if (queriesQuotaTally && aiCreditsQuotaTally) {
      canAddQuery =
        totalSuggestionCount <= AIGeneratorBoundaryValues.maxValue &&
        queriesQuotaTally.productBalance - totalSuggestionCount >= 0 &&
        aiCreditsQuotaTally.productBalance - totalSuggestionCount >= 0
    }
    return !canAddQuery
  }, [queriesQuotaTally,aiCreditsQuotaTally, totalSuggestionCount])

  return (
    <AIGenerateModal
      open={props.isAIGenerate}
      handleClose={() => {
        props.setIsAIGenerate(false)
        reset()
      }}
      handleSubmit={handleSubmit(onSubmit)}
      disableSubmit={disableSubmit}
    >
      <div className="flex flex-col gap-2">
        <InputStyleWrapper>
          <SightlyInputForm
            dataTestId={'queries-targetSuggestionCount-input'}
            control={control}
            inputType="number"
            minValue={AIGeneratorBoundaryValues.minValue}
            maxValue={Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota)}
            customLabel={
              <CustomLabel
                mainCaption="Events to be around"
                subCaption="Select the number of suggestions"
              />
            }
            error={formState.errors?.targetSuggestionCount?.message}
            name="targetSuggestionCount"
            register={register}
            id="targetSuggestionCount"
          />
        </InputStyleWrapper>
        <InputStyleWrapper>
          <SightlyInputForm
            dataTestId={'queries-monitorSuggestionCount-input'}
            control={control}
            inputType="number"
            minValue={AIGeneratorBoundaryValues.minValue}
            maxValue={Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota)}
            error={formState.errors?.monitorSuggestionCount?.message}
            name="monitorSuggestionCount"
            register={register}
            id="monitorSuggestionCount"
            customLabel={
              <CustomLabel
                mainCaption="Events to monitor"
                subCaption="Select the number of suggestions"
              />
            }
          />
        </InputStyleWrapper>
      </div>
      {totalSuggestionCount > maxQueryValue && (
        <p
          className="text-sm text-red-600"
          data-testid="brand-suggestion-count-max-error"
        >
          Suggestion Count exceeds the permissible limit of total {maxQueryValue} queries.
        </p>
      )}
    </AIGenerateModal>
  )
}

export default EventAIGenerationForm
