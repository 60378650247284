import { userAccountAxios } from '../../../../../axiosInstances'
import { MediaResponsibilityLookupSchema, MediaResponsibilitySchema,IProps, MediaResponsibilityPostSchema } from './types'



export const api = {
    getMediaResponsibilitiesLookup: async () => {
        const url = `/media-responsibility`
        const res = await userAccountAxios.get(url)
        return MediaResponsibilityLookupSchema.parse(res.data)
    },
    getBrandMediaResponsibilities: async ({ brandProfileId }: IProps) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/media-responsibility`
        const res = await userAccountAxios.get(url)
        return MediaResponsibilitySchema.parse(res.data)
    },
    addBrandMediaResponsibilities: async({
        brandProfileId,
        politicalBiasPreferenceIds,
        reliabilityPreferenceIds}: MediaResponsibilityPostSchema) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/media-responsibility`
        const result = await userAccountAxios.post(url, {
            politicalBiasPreferenceIds,
            reliabilityPreferenceIds
        })
        return result.data
    }   
}
