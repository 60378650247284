import React from 'react'
import toast from 'react-hot-toast'
import { SmallBadge } from '../SmallClosableBadge'

type IProps = {
    tags: string[]
    inputIsValid: (val: string) => boolean
    setSelectedTags: (val: string[]) => void
    placeholder: string
    invalidWarningText?: string
}

const SightlyTagInput = (props: IProps) => {
    const [val, setVal] = React.useState('')
    const [tags, setTags] = React.useState(props.tags)
    const removeTags = (indexToRemove: number) => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)])
        props.setSelectedTags([...tags.filter((_, index) => index !== indexToRemove)])
    }
    const addTags = (value: any) => {
        if (value === '') return
        if (!props.inputIsValid(value)) {
            toast.error(props.invalidWarningText || 'Unable to add, invalid value')
            return
        }
        setTags([...tags, value])
        props.setSelectedTags([...tags, value])
        setVal('')
    }

    return (
        <>
            <div className="rounded-md form-input font-normal w-full border-gray-300 placeholder:font-light placeholder:text-slate-400 focus:border-sightly-blue focus:ring-sightly-blue text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500">
                <ul className="flex flex-wrap gap-1 mb-0">
                    {tags.map((tag, index) => (
                        <li
                            key={index}
                            className="mr-1 max-h-5"
                        >
                            <SmallBadge
                                key={index}
                                text={tag}
                                onClose={() => removeTags(index)}
                            />
                        </li>
                    ))}
                    <input
                        className="flex-auto myInput rounded-md border-none border-transparent font-normal border-gray-300 placeholder:font-light placeholder:text-slate-400 text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                        type="text"
                        onKeyUp={(event: any) => (event.key === 'Enter' ? addTags(event.target.value) : null)}
                        onChange={(e) => setVal(e.target.value)}
                        placeholder={props.placeholder}
                        value={val}
                    />
                </ul>
            </div>
            {val && props.inputIsValid(val) && (
                <p
                    onClick={() => addTags(val)}
                    className="h-6 hover:cursor-pointer text-sightly-blue text-xs flex items-center"
                >
                    Save
                </p>
            )}
        </>
    )
}

export default SightlyTagInput
