import React from 'react'
import { TargetviewIo } from '@/classes/brandProfile'
import { InsertionOrderPersonasRow } from '@/views/Discover/Moments/v2/TargetMoment/InsertionOrderPersonasRow'
import { IoOptionsType, TargetListType } from '@/views/Discover/Moments/v2/TargetMoment/index'

/**
 * AddRowButton Component:
 * Represents a button used for adding rows.
 *
 * @param {Object} props - Component props
 * @param {Function} props.handleAddRow - Function to handle adding rows
 * @returns {JSX.Element} - JSX representation of the component
 */
const AddRowButton = (props: { handleAddRow: () => void }) => (
  // The blank divs are to place the Add button as per the design and aligned with the row selectors
  <div className="flex justify-evenly">
    <div className="w-80 inline-block ">&nbsp;</div>
    <div className="w-80 inline-block flex justify-end gap-1">
    &nbsp;
      <div
        data-testid="act-mgr-target-moments-table-io-persona-addRowButton"
        className="m-2 text-xs font-semibold text-right text-sightlyBlue"
      >
        <span
          className="cursor-pointer"
          onClick={props.handleAddRow}
        >
          + Add
        </span>
      </div>
      <div className="w-10"></div>
    </div>
  </div>
)

/**
 * InsertionOrderPersonasWrapper Component:
 * Manages a wrapper for Insertion Order Personas, handling addition and removal of rows.
 *
 * @param {Object} props - Component props
 * @param {TargetviewIo[]} props.ioOptions - List of TargetviewIo objects
 * @param {number} props.clusterId - ID of the cluster
 * @param {Map<number, TargetListType>} props.targetList - Map containing TargetListType by cluster ID
 * @param {(clusterId: number, ioId: number) => void} props.setIOsToTargetList - Function to set Insertion Orders
 * @param {(clusterId: number, ioId: number) => void} props.removeIOsFromTargetList - Function to remove Insertion Orders
 * @param {(clusterId: number, ioId: number, personas: string[]) => void} props.setPersonasToTargetList - Function to set personas for a Target List
 * @returns {JSX.Element} - JSX representation of the component
 */
export const InsertionOrderPersonasWrapper = (props: {
  ioOptions: IoOptionsType[]
  clusterId: number
  targetList: Map<number, TargetListType>
  setIOsToTargetList: (clusterId: number, ioId: number, prevIoId: number | undefined) => void
  removeIOsFromTargetList: (clusterId: number, ioId: number) => void
  setPersonasToTargetList: (clusterId: number, ioId: number, personas: string[]) => void
  isBulkAssignApplied: boolean
  setIsBulkAssignApplied: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  let elementKey = React.useRef(0)
  let keyVersion = React.useRef(0)
  const {
    clusterId,
    ioOptions,
    targetList,
    setIOsToTargetList,
    removeIOsFromTargetList,
    setPersonasToTargetList,
    isBulkAssignApplied,
    setIsBulkAssignApplied,
  } = props

  /**
   * Handles removal of a row based on its rowKey.
   * @param {string} rowKey - Key of the row to be removed
   * @returns {void}
   */
  const handleRemoveRow = (rowKey: string) => {
    // Checks if the row is the first in the list
    if (rowKey === `${clusterId}_v${keyVersion.current}_0`) return

    setInsertionOrderPersonasJsxMap((prev) => {
      const next = new Map(prev)
      next.delete(rowKey)
      return next
    })
  }

  // Memoizes the Insertion Order Personas JSX map based on the provided dependencies
  const defaultInsertionOrderPersonasJsxMap = React.useCallback(
    (rowKeyVersion?: number) => {
      // rowKeyVersion would update the rowKeys and inform React to not use the cache and rerender instead.
      rowKeyVersion = rowKeyVersion || keyVersion.current
      const jsxMap = new Map()
      //Resetting the elementKey as the 0th row can't be deleted.
      elementKey.current = 0

      let rowKey = `${clusterId}_v${rowKeyVersion}_${elementKey.current}`
      // Retrieves the current cluster's Target List from props
      const currentClusterTargetList = targetList?.get(clusterId)
      // Loops through each IO ID with its associated personas in the Target List
      currentClusterTargetList?.ioIdWithPersonas.forEach((personas, ioId) => {
        // Creates Insertion Order Personas component and adds it to the jsxMap
        jsxMap.set(
          rowKey,
          <InsertionOrderPersonasRow
            iosOptions={ioOptions}
            clusterId={clusterId}
            targetList={targetList}
            setIOsToTargetList={setIOsToTargetList}
            removeIOsFromTargetList={removeIOsFromTargetList}
            rowKey={rowKey}
            key={rowKey}
            handleRemoveRow={handleRemoveRow}
            setPersonasToTargetList={setPersonasToTargetList}
            selectedIoId={ioId}
            selectedPersonas={Array.from(personas)}
          />
        )
        // Increments the elementKey to maintain uniqueness for new elements
        elementKey.current += 1
        rowKey = `${clusterId}_v${rowKeyVersion}_${elementKey.current}`
      })

      // Adds an empty row if the jsxMap size is 0.
      if (!jsxMap.size) {
        jsxMap.set(
          rowKey,
          <InsertionOrderPersonasRow
            iosOptions={ioOptions}
            clusterId={clusterId}
            targetList={targetList}
            setIOsToTargetList={setIOsToTargetList}
            removeIOsFromTargetList={removeIOsFromTargetList}
            rowKey={rowKey}
            key={rowKey}
            handleRemoveRow={handleRemoveRow}
            setPersonasToTargetList={setPersonasToTargetList}
          />
        )
      }
      return jsxMap
    },
    [targetList]
  )

  const [insertionOrderPersonasJsxMap, setInsertionOrderPersonasJsxMap] = React.useState<Map<string, JSX.Element>>(
    defaultInsertionOrderPersonasJsxMap
  )
  //Clears and updates the assignments.
  React.useEffect(() => {
    if (isBulkAssignApplied) {
      keyVersion.current += 1
      setInsertionOrderPersonasJsxMap(() => defaultInsertionOrderPersonasJsxMap(keyVersion.current))
      setIsBulkAssignApplied(false)
    }
  }, [targetList])

  /**
   * Handles addition of a new row.
   * @returns {void}
   */
  const handleAddRow = (): void => {
    elementKey.current += 1
    setInsertionOrderPersonasJsxMap((prev) => {
      const next = new Map(prev)
      const rowKey = `${clusterId}_v${keyVersion.current}_${elementKey.current}`
      next.set(
        rowKey,
        <InsertionOrderPersonasRow
          iosOptions={ioOptions}
          clusterId={clusterId}
          targetList={targetList}
          setIOsToTargetList={setIOsToTargetList}
          removeIOsFromTargetList={removeIOsFromTargetList}
          rowKey={rowKey}
          key={rowKey}
          handleRemoveRow={handleRemoveRow}
          setPersonasToTargetList={setPersonasToTargetList}
        />
      )
      return next
    })
  }

  return (
    <>
      {/* Renders all Insertion Order Personas rows */}
      {Array.from(insertionOrderPersonasJsxMap.values())}
      {ioOptions.length > 0 && insertionOrderPersonasJsxMap.size < ioOptions.length && (
        <AddRowButton handleAddRow={handleAddRow}/>
      )}
    </>
  )
}
