import React from 'react'
import { ReactComponent as NoCategories } from '@/assets/img/noActionJustifications.svg'
import { CategoryActionJustificationType } from '../../types'
import { ActionBadge } from "@/views/Discover/Moments/v2/components/ActionBadge";
import { LoadingPage } from "@/components/LoadingPage";
import { BrandProfileLink } from "@/views/Discover/Moments/v2/components/BrandProfileLink";
interface IProps {
    brandProfileId: number | undefined
    isLoading: boolean
    categories: CategoryActionJustificationType[] | undefined
    nonGarmActions: CategoryActionJustificationType[] | undefined
}

export const CategoriesSection = ({ brandProfileId, categories, nonGarmActions, isLoading }: IProps) => {
    const availableCategories:(CategoryActionJustificationType[] | undefined) = nonGarmActions

    const target = availableCategories ? availableCategories?.filter(category => category.action === 'target') : []
    const block = availableCategories ? availableCategories?.filter(category => category.action === 'block') : []
    const monitor = availableCategories ? availableCategories?.filter(category => category.action === 'monitor') : []
    const noaction = availableCategories ? availableCategories?.filter(category => category.action === 'no action' || category.action === 'noaction') : [];

    const getCategorySection = (label:string, availableCategories:CategoryActionJustificationType[]) =>  (
        <div className="pb-4">
            <div className="pb-1 font-bold text-gray-400">{label}</div>
            <div className="flex flex-wrap">
                {availableCategories?.map((data, index) => (
                    <ActionBadge key={index} action={data.action} label={data.category}/>
                ))}
            </div>
        </div>
    )

    const noCategories =
        <div
            data-testid="div-moment-categories-no-categories"
            className="grid justify-items-center p-16 text-center"
        >
            <NoCategories data-testid="no-categories-icon"/>
            <h5>No Categories</h5>
            <div className="justify-self-center">There are no categories from your brand profile matching this moment</div>
        </div>

    const hasCategories =
        <div
            data-testid="div-moment-categories-has-categories"
            className="h-full overflow-auto"
        >
            {target?.length > 0 && getCategorySection('Targeted Categories', target)}
            {block?.length > 0 && getCategorySection('Blocked Categories', block)}
            {monitor?.length > 0 && getCategorySection('Monitored Categories', monitor)}
            {noaction?.length > 0 && getCategorySection('Undecided Categories', noaction)}
        </div>

    const categoriesComponent = categories?.length === 0 ? noCategories : hasCategories

    return (
        <div
            data-testid="moment-categories"
            className="p-4 bg-white rounded-xl"
        >
            <div className="flex justify-between">
                <h4 className="pb-2">Categories</h4>
                <BrandProfileLink dataTestId="brand-profile-categories-link" brandProfileId={brandProfileId} section="opinions"/>
            </div>
            <div>
                {isLoading
                    ? <LoadingPage message="Loading" />
                    : categoriesComponent
                }
            </div>
        </div>

    )
}


