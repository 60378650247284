import { userAccountAxios } from '@/axiosInstances'

export const getRelationships = async () => {
    try {
        const url = 'brand-profile/relationships'
        const { data } = await userAccountAxios.get(url)
        return data
    } catch (error) {
        console.error(error)
        throw error
    }
}
