export const SET_EVERGREEN_CHANNELS = 'SET_EVERGREEN_CHANNELS'
export const SET_EVERGREEN_CHANNELS_LOADING = 'SET_EVERGREEN_CHANNELS_LOADING'
export const SET_EVERGREEN_CHANNEL_COUNT = 'SET_EVERGREEN_CHANNEL_COUNT'
export const SET_EVERGREEN_CHANNELS_PAGE = 'SET_EVERGREEN_CHANNELS_PAGE'
export const SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE = 'SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE'
export const EVERGREEN_CHANNEL_ARCHIVING = 'EVERGREEN_CHANNEL_ARCHIVING'
export const EVERGREEN_CHANNEL_TO_ARCHIVE = 'EVERGREEN_CHANNEL_TO_ARCHIVE'
export const EVERGREEN_CHANNEL_SAVING = 'EVERGREEN_CHANNEL_SAVING'
export const SET_EVERGREEN_CHANNELS_IAB_CATEGORIES = 'SET_EVERGREEN_CHANNELS_IAB_CATEGORIES'
// export const ADD_EVERGREEN_CHANNEL = 'ADD_EVERGREEN_CHANNEL'
