import React from 'react';
import Divider from 'rsuite/lib/Divider';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import tikTokLogo from '@/assets/img/tiktoklogo.png';

interface IProps {
  tikTokStatus: string;
  removeTikTokIntegration: () => void;
  addTikTokIntegration: () => void;
}

export const IntegrationCard = ({ tikTokStatus, removeTikTokIntegration, addTikTokIntegration }: IProps) => {

  return (
    <div
      data-testid="integration-card"
      className="w-72 ring-1 ring-[#F2F2F2] bg-white rounded-lg shadow-lg"
    >
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-2 px-4 pt-4">
          <div
            data-testid="integration-card-title"
            className="flex items-center"
          >
            <div className="font-semibold text-base flex items-center text-lg">
              <img
                src={tikTokLogo}
                className="w-[45px] h-[45px] rounded-full mr-2"
                alt="TikTok"
              />
              <span>TikTok</span>
              {tikTokStatus === 'ACCESS_TOKEN_VALID' && (
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              )}
            </div>
          </div>
          <div
            data-testid="integration-card-description"
            className="text-xs font-normal text-sightlySlate"
          >
            Connect to TikTok For Business to access all of your business accounts in one place.
          </div>
        </div>

        <Divider className="!mb-0" />
        <div className="flex justify-end text-xs font-semibold p-4 bg-gray-100">
          {tikTokStatus === 'ACCESS_TOKEN_VALID' ? (
            <SightlyButton
              text="Remove"
              datatestid="integration-card-remove"
              handleClick={removeTikTokIntegration}
              type="cancel"
            />
          ) : (
            <SightlyButton
              text="Integrate"
              datatestid="integration-card-integrate"
              handleClick={addTikTokIntegration}
            />
          )}
        </div>
      </div>
    </div>
  );
};
