import { useQuery } from '@tanstack/react-query';
import { UsersSchema } from '@/schemas/schemas';
import { userAccountAxios } from '@/axiosInstances';
import useUser from '@/hooks/useUser';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { UserType } from '@/schemas/schemas';

const getUsers = async (currentAccountId: number | undefined) => {
    const url = `/account/${currentAccountId}/users`
    const { data } = await userAccountAxios.get<UserType[]>(url)

    const usersParsing = UsersSchema.safeParse(data)
    
    if (!usersParsing.success) {
        console.error('received diff data than expected getting users')
    }

    return data
}

export default function useUsers() {
    const { currentAccount } = useUser()
    return useQuery(rqKeys.usersKeys(currentAccount?.accountId), () => getUsers(currentAccount?.accountId), {
        enabled: !!currentAccount?.accountId
    })
}
