import { userAccountAxios } from '@/axiosInstances';
import { AylienIndustriesSchema } from '@/schemas/schemas';
import { logError } from '@/utils';

export const aylienIndustries = {
  get: async () => {
    const url = `v1/brand-profiles/industry_categories/trees`
    const { data } = await userAccountAxios.get(url)
    return data
  }
};
