import { SmallBadge } from '@/components/SmallClosableBadge'
import React from 'react'
import Tooltip from '@/components/TailwindTooltip'
import { ItemDetail } from '@/views/Discover/Boards/types'

export const BoardItemDescription = (board: {
    type: string,
    defaultType: string | null,
    selectedItems: string[],
    operator: string,
  },
    listOfAgregates?: ItemDetail[],
  ) => {
    if (board.type === 'custom') {
      return (
        <div className="flex items-center gap-2">
          <div className="flex flex-wrap gap-2 ">
            {board.selectedItems.slice(0, 1).map((item) => (
              <SmallBadge
                dataTestId={'card-description-first-badge'} 
                onClose={() => undefined}
                text={item}
                key={item}
                disabled={true}
                bgColor='bg-white'
                border='border-[#6FEABC] border-2'
              />
            ))}
          </div>

          {
            board.selectedItems.length > 1 && (
              <div className="flex flex-wrap gap-2 ">
                <p
                  data-testid={'card-description-operator'}
                  className='text-purple-500'
                >
                  {board.operator}
                </p>
                <Tooltip content={board.selectedItems.map((e) => JSON.stringify(e)).join(', ')}>
                  <div className="text-xs">
                    <SmallBadge
                      dataTestId={'card-description-others-badge'} 
                      onClose={() => undefined}
                      text={`${board.selectedItems.length - 1} others`}
                      key={"other-categories"}
                      disabled={true}
                      bgColor='bg-white'
                      border='border-[#6FEABC] border-2'
                    />
                  </div>
                </Tooltip>
              </div>
            )
          }
        </div>
      )
    } else if (board.type === 'naturalLanguage') {
      return <h3 data-testid={'card-description-text'} className='text-sm text-[#333D47] font-medium whitespace-wrap overflow-ellipsis'>
        AI generated board
      </h3>
    } else {
      return <h3 data-testid={'card-description-text'} className='text-sm text-[#333D47] font-medium whitespace-wrap overflow-ellipsis'>
        {listOfAgregates?.find(a => a.defaultType === board.defaultType!)?.description}
      </h3>
    }
  }