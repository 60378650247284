import { listBuilderAxios } from '@/axiosInstances'
import { MomentInsightsProps, MomentInsightsListSchema } from './types'
import { logError } from '@/utils'

export const getMomentInsights = async ({ clusterId, brandProfileId }: MomentInsightsProps) => {
  try {
    if (clusterId === undefined || brandProfileId === undefined) return Promise.reject('invalid input')
    const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/insights`
    const { data } = await listBuilderAxios.get(url)
    return MomentInsightsListSchema.parse(data)
  } catch (error) {
    logError(error)
  }
}
