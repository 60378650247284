import { z } from 'zod'

//scenarios:
export const ScenarioSchema = z.object({
    id: z.number(),
    archived: z.boolean(),
    scenarioName: z.string().min(1, 'Must have at least one character'),
    scenarioThemeIds: z.array(z.number()).min(1, 'At least one theme is required'),
})
export type ScenarioType = z.infer<typeof ScenarioSchema>

const GetScenarioSchema = ScenarioSchema.pick({
    id: true,
    archived: true,
    scenarioName: true
})
export const ScenariosFromGetAllSchema = z.array(GetScenarioSchema)
export type ScenarioFromGetAllType = z.infer<typeof GetScenarioSchema>

export const PostScenarioSchema = ScenarioSchema.pick({
    scenarioName: true,
    scenarioThemeIds: true,
})
export type PostScenarioType = z.infer<typeof PostScenarioSchema>

export const PatchScenarioSchema = ScenarioSchema.pick({
    archived: true,
    scenarioName: true,
    scenarioThemeIds: true,
})
export type PatchScenarioType = z.infer<typeof PatchScenarioSchema>

//themes:
export const ThemeSchema = z.object({
    id: z.number(),
    description: z.string().min(1, 'Must have at least one character'),
    theme: z.string().min(1, 'Must have at least one character'),
    archived: z.boolean()
})
export const ThemesSchema = z.array(ThemeSchema)
export type ThemeType = z.infer<typeof ThemeSchema>

export const PostThemeSchema = ThemeSchema.pick({
    description: true,
    theme: true
})
export type PostThemeType = z.infer<typeof PostThemeSchema>

export const PatchThemeSchema = ThemeSchema.pick({
    archived: true,
    description: true,
    theme: true
})
export type PatchThemeType = z.infer<typeof PatchThemeSchema>
