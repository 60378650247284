import React from 'react'
import ButtonGroup from 'rsuite/lib/ButtonGroup'
import Button from 'rsuite/lib/Button'
import { AylienItem, HandleActionClickProps } from './useBrandProfileAylien'
import { accentColor, dividerColor } from '../../../../../assets/jss/colorConstants'
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

type IProps = {
    item: AylienItem
    disabled: boolean
    handleClick: (args: HandleActionClickProps) => void
}

const Node = ({ item, disabled, handleClick }: IProps) => {
    const { perms, userPermissions } = useUser();
    const includeColor = React.useMemo(() => {
        return item.responseId === 1 ? '#4CAF50' : item.responseId === 2 ? 'lightgrey' : accentColor
    }, [item.responseId])

    const excludeColor = React.useMemo(() => {
        return item.responseId === 2 ? 'red' : item.responseId === 1 ? 'lightgrey' : accentColor
    }, [item.responseId])

    return (
        <div
            style={{
                display: 'flex',
                width: '700px',
                backgroundColor: dividerColor
            }}
        >
            <div className="maxOneLine" style={{ flex: 3, color: 'black', fontSize: 12, alignSelf: 'center' }}>
                {item.name}
            </div>
            <div>
                <ButtonGroup size="xs">
                    <Button
                        disabled={!userCanPermissionProductQuota({
                            requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                        }) || disabled}
                        key="0"
                        id="includeButton"
                        onClick={() =>
                            handleClick({
                                id: item.id,
                                responseId: item.responseId === 1 ? null : 1
                            })
                        }
                        style={{
                            backgroundColor: includeColor,
                            color: 'white',
                            fontWeight: 700
                        }}
                    >
                        Target
                    </Button>

                    <Button
                        disabled={!userCanPermissionProductQuota({
                            requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                        }) || disabled}
                        id="excludeButton"
                        key="1"
                        onClick={() =>
                            handleClick({
                                id: item.id,
                                responseId: item.responseId === 2 ? null : 2
                            })
                        }
                        style={{
                            backgroundColor: excludeColor,
                            color: 'white',
                            fontWeight: 700
                        }}
                    >
                        Block
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    )
}

export default Node
