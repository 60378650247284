import React from 'react'
import debounce from 'just-debounce-it'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { UseFormGetValues, UseFormReset, UseFormTrigger } from 'react-hook-form'
import { ZodSchema } from 'zod'
import { logError } from '@/utils'
import { BasicInfoType } from '@/hooks/brandProfile/useBrandProfileBasic'

type IProps = {
    mutateAsync: UseMutateAsyncFunction<AxiosResponse<any, any>, unknown, any, unknown>
    validationSchema: ZodSchema
    getValues: UseFormGetValues<any>
    reset: UseFormReset<any>
    trigger: UseFormTrigger<BasicInfoType>
    data: any
    debounceInterval?: number
}

export function useDebouncedAutoSave({ mutateAsync, validationSchema, getValues, reset, trigger, data, debounceInterval }: IProps) {

    const handleDebouncedChange = React.useMemo(
        () =>
            debounce((data: any) => {
                mutateAsync(data)
            }, debounceInterval || 500),
        [mutateAsync]
    )

    const onChange = async () => {
        try {
            const values = getValues()
            const validated = await validationSchema.parse(values)
            if (!validated) trigger()
            handleDebouncedChange(validated)
            return 'success'
        } catch (e) {
          logError(e)
        }
    }

    React.useEffect(() => {
        reset(data)
    }, [data])

    return {
        onChange
    }
}
