import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { reportGeneratorApi } from "../reportGenerator-api";
import { ReportConfiguration } from '../reportGenerator-types';

import TableRow from './TableRow'

interface IProps {
    tableData: Array<object>,
    queryKey: Array<any>,
    setIsDownloading: Function
}

export default function ReportTable({ tableData, queryKey, setIsDownloading }: IProps) {
    const queryClient = useQueryClient()

    const deleteConfiguration = useMutation({
        mutationFn: reportGeneratorApi.deleteReportConfiguration,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey })
        }
    });

    const downloadReport = async (configuration: ReportConfiguration) => {
        setIsDownloading(true)
        await reportGeneratorApi.downloadReport(configuration)
        setIsDownloading(false)
    }

    return (
        <div className="mx-14 my-8 shadow ring-1 ring-black ring-opacity-5 rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                        Report
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Last Modified Date
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Last Modified By
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                    >
                        Download
                    </th>
                    <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData?.map((rowData: any, index: number) => (
                        <TableRow
                            key={index}
                            rowData={rowData}
                            deleteConfiguration={deleteConfiguration}
                            downloadReport={downloadReport}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
