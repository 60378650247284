import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard'
import Tabs from '@/components/Tabs/Tabs'
import { SparklesIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'
import { SelectPicker } from 'rsuite'
import GraphsOverview from '@/views/Trends/Results/components/GraphsOverview'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import { TrendTab } from './components/Tabs/TrendTab'
import { TopicTab } from './components/Tabs/TopicTab'
import { MomentTab } from './components/Tabs/MomentTab'
import { NarrativeTab } from './components/Tabs/NarrativeTab'
import { useTopicSearch } from '../hooks/useTopics'
import { useTrendSearch } from '../hooks/useTrends'
import { useTopicTrendMoments } from '../hooks/useTopicTrendMoments'
import { useNarrativeSearch } from '../hooks/useNarratives'
import { useGenerateSummaryData } from '../hooks/useSummary'
import { MyLocationGenerics } from '@/classes/utils'

export const TrendsResult = () => {
    const navigate = useNavigate();
    const trendsEnabled = useFlag('enable_trends_feature');
    if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

    const [activeTab, setActiveTab] = useState('0')
    const [category, setCategory] = useState<number>(-1)
    const [timeRange, setTimeRange] = useState<number>(1)
    const [fromDate, setFromDate] = useState<Date>(new Date('2024-04-04'));
    const [debouncedSearch, setDebouncedSearch] = useState('');

    const { searchTerm } = useSearch<MyLocationGenerics>();
    const [search, setSearch] = useState<string>(searchTerm ?? '');
    
    const tabs = [
        { id: '0', title: 'Topics' },
        { id: '1', title: 'Trends' },
        { id: '2', title: 'Moments' },
        { id: '3', title: 'Narratives' }
    ]

    const categoryOptions = [
        { value: -1, label: 'All' },
        { value: 123, label: 'Category 1' },
        { value: 456, label: 'Category 2' },
        { value: 789, label: 'Category 3' }
    ]

    const timeRangeOptions = [
        { value: 1, label: '15 Days' },
        { value: 2, label: '30 Days' },
        { value: 4, label: '60 Days' }
    ]

    const pageSize = 10;

    const [callSummaryOnSearch, setCallSummaryOnSearch] = useState(false);

    const [topicPage, setTopicPage] = useState(1);
    const { topicsQuery, isLoadingTopics } = useTopicSearch(search);

    const [trendPage, setTrendPage] = useState(1);
    const { trendsQuery, isLoadingTrends } = useTrendSearch(search);

    const [momentPage, setMomentPage] = useState(1);
    const { momentsQuery, isLoadingMoments } = useTopicTrendMoments(momentPage, pageSize, fromDate, search);

    const [narrativePage, setNarrativePage] = useState(1);
    const { narrativesQuery, isLoadingNarratives } = useNarrativeSearch(narrativePage, pageSize, fromDate, search);

    useEffect(() => {
        if (searchTerm !== undefined && searchTerm !== search) {
          setSearch(searchTerm);
        }
      }, [searchTerm]);
    
      useEffect(() => {
        const handler = setTimeout(() => {
          setSearch(debouncedSearch);
        }, 500);
    
        return () => clearTimeout(handler);
      }, [debouncedSearch]);
    
      useEffect(() => {
        if (!isLoadingTopics && !isLoadingTrends && !isLoadingMoments && !isLoadingNarratives) {
          setCallSummaryOnSearch((prev) => !prev);
        }
      }, [isLoadingTopics, isLoadingTrends, isLoadingMoments, isLoadingNarratives]);
    
      const handleSearch = (val: string) => setDebouncedSearch(val);
    
      const handleTabChange = (tab: string) => setActiveTab(tab);

    const momentsToGenerate = momentsQuery?.data?.slice(0, 20) || [];
    const narrativesToGenerate = narrativesQuery?.data?.slice(0, 20) || [];
    const trendsToGenerate = trendsQuery?.data?.slice(0, 20) || [];
    const topicsToGenerate = topicsQuery?.data?.slice(0, 20) || [];

    const { summaryData, isLoadingSummary } = useGenerateSummaryData(
        momentsToGenerate,
        narrativesToGenerate,
        trendsToGenerate,
        topicsToGenerate,
        callSummaryOnSearch
    );

    const detaultSummary = 'Conversations around mobile gaming focus on PUBG and BGMI, highlighting gameplay techniques, device performance tests, and sensitivity settings. Users share their experiences with various devices like the iQOO Z9S Pro and Samsung Galaxy Tab, while also promoting live streams and gameplay tips.';

    return (
        <div className='px-12 py-8 h-[90%] bg-white rounded-md mx-auto flex flex-col gap-4'>
            <div className="flex gap-8">
                <p className='text-2xl'>Explore</p>
                <SightlyInput
                    placeholder="Search"
                    onChange={handleSearch}
                    id="search"
                    hasSearchIcon
                    maxLength={140}
                    value={search}
                />
            </div>
            <div className='w-full h-full flex animate-in fade-in'>
                <div className="w-2/3 flex flex-col pr-4 border-r border-gray-200">
                    <div className='flex flex-col gap-2'>
                        <SightlyGradientCard>
                            <h3 className="text-highlightPurple text-sm">
                                <SparklesIcon
                                    className="h-5 w-5 text-highlightPurple inline mr-2"
                                    aria-hidden="true"
                                />
                                Summary
                            </h3>
                            <p className="text-sm">
                                {summaryData?.summary || detaultSummary}
                            </p>
                        </SightlyGradientCard>
                    </div>
                    <div className='h-[70%] flex flex-col gap-2'>
                        <div className="w-full flex flex-col gap-4">
                            <Tabs
                                width={'100%'}
                                marginRight={40}
                                active={activeTab}
                                onChange={(active: string) => {
                                    handleTabChange(active)
                                }}
                            >
                                {tabs.map((tab) => (
                                    <div className="flex flex-row items-center" key={tab.id}>
                                        <div>{tab.title}</div>
                                    </div>
                                ))}
                            </Tabs>
                            <div className='flex gap-6'>
                                <div className='flex gap-2 items-center'>
                                    <span className="text-sm">Category</span>
                                    <SelectPicker
                                        data={categoryOptions}
                                        value={category}
                                        onChange={(value) => setCategory(value)}
                                        searchable={true}
                                        cleanable={true}
                                        appearance='subtle'
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <span className="text-sm">Date Range</span>
                                    <SelectPicker
                                        data={timeRangeOptions}
                                        value={timeRange}
                                        onChange={(value) => setTimeRange(value)}
                                        searchable={false}
                                        cleanable={false}
                                        appearance='subtle'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='h-full flex overflow-y-auto'>
                            <>
                                {activeTab === '0' && (
                                    <TopicTab
                                        noResultsMessage="There are no topics for your filters" data={topicsQuery?.data || []} isLoading={isLoadingTopics} page={topicPage} pageSize={pageSize} setPage={setTopicPage} />
                                )}

                                {activeTab === '1' && (
                                    <TrendTab
                                        noResultsMessage="There are no trends for your filters" data={trendsQuery?.data || []} isLoading={isLoadingTrends} page={trendPage} pageSize={pageSize} setPage={setTrendPage} />
                                )}

                                {activeTab === '2' && (
                                    <MomentTab
                                    noResultsMessage="There are no moments for your filters" data={momentsQuery?.data || []} isLoading={isLoadingMoments} page={momentPage} pageSize={pageSize} setPage={setMomentPage} />
                                )}

                                {activeTab === '3' && (
                                    <NarrativeTab
                                        noResultsMessage="There are no narratives for your filters" data={narrativesQuery?.data || []} isLoading={isLoadingNarratives} page={narrativePage} pageSize={pageSize} setPage={setNarrativePage} />
                                )}
                            </>
                        </div>
                    </div>
                </div>
                <div className='w-1/3 flex flex-col gap-4 pl-4'>
                    <GraphsOverview />
                </div>
            </div>
        </div>
    )
}
