import { z } from 'zod'

//scenarios:
const ScenarioSchema = z.object({
    scenarioId: z.number(),
    scenarioName: z.string(),
    scenarioResponse: z.string().nullable(),
    scenarioResponseId: z.number().nullable(),
    isAiGenerated: z.boolean().nullable(),
    confidenceScore: z.number().nullable(),
    reason: z.string().nullable()
})
export type ScenarioType = z.infer<typeof ScenarioSchema>
const ScenarioThemeSchema = z.object({
    answeredCount: z.number(),
    scenarioTheme: z.string().min(1, 'Must have at least one character'),
    scenarioThemeDescription: z.string().min(1, 'Must have at least one character'),
    scenarioThemeId: z.number(),
    scenarios: z.array(ScenarioSchema).min(1, 'Must have at least one scenario')
})
export type ScenarioThemeType = z.infer<typeof ScenarioThemeSchema>
export const ScenarioThemesSchema = z.array(ScenarioThemeSchema)

const PatchScenarioSchema = ScenarioSchema.pick({
    scenarioId: true,
    scenarioResponseId: true
})

export const PatchScenariosSchema = z.array(PatchScenarioSchema)
export type PatchScenariosType = z.infer<typeof PatchScenariosSchema>
export type PatchScenarioType = z.infer<typeof PatchScenarioSchema>
