import React from 'react'

interface iProps {
    text: string
    size: 'small' | 'big' | 'xl' | 'xs'
    type: 'blue' | 'pink' | null
}

const SightlyAvatar = ({ text, size, type }: iProps) => {
    const label = React.useMemo(() => {
        try {
            if (!text || text === ' ') return ''
            const wordArray = text.split(' ')
            const first = wordArray[0].charAt(0)
            let second = ''
            if (wordArray[1]) {
                second = wordArray[1].charAt(0)
            }
            return first + second
        } catch {
            return ''
        }
    }, [text])

    const _size = React.useMemo(() => {
        if (size === 'big') {
            return 40
        } else if (size === 'xl') {
            return 120
        } else if (size === 'small') {
            return 32
        }
        return 24
    }, [size])

    const _fontSize = React.useMemo(() => {
        if (size === 'xl') return 59
        return 16
    }, [size])

    return (
        <div
            className="unselectable text-gray-"
            style={{
                display: 'flex',
                alignItems: 'center',
                width: _size,
                color: '#FFFFFF',
                height: _size,
                borderRadius: _size,
                fontWeight: 600,
                fontSize: _fontSize,
                justifyContent: 'center',
                textTransform: 'uppercase',
                backgroundImage:
                    type === 'blue'
                        ? 'linear-gradient(to right, #26AAE1, #7B61FF)'
                        : type === 'pink'
                        ? 'linear-gradient(to bottom right, #EE7731, #E8178A)'
                        : 'linear-gradient(to bottom right, rgb(156, 163, 175), rgb(156, 163, 175))'
            }}
        >
            {label}
        </div>
    )
}

export default SightlyAvatar
