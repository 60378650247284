import React from 'react';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { ProgressBar } from "@/components/ProgressBar";
import { UsersIcon } from "@heroicons/react/20/solid";
import { products } from '@/staticData/products';
import { InviteUserExternal } from "@/components/InviteUserExternal";
import { perms } from "@/staticData/permissions";
import { ProductQuotaTally } from "@/hooks/subscription/useProductUsage/types";
import { getProductUsageProgressValues } from "./helpers/getProductUsageProgressValues";
import { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

type InviteTeamMembersProps = {
  userCanPermissionProductQuota: Function
  productQuotaTally: ProductQuotaTally
  userPermissions: any
  userProducts: any
}

export const InviteTeamMembers = ({ userCanPermissionProductQuota, productQuotaTally, userPermissions, userProducts }: InviteTeamMembersProps) => {
  const [inviteUserDialogIsOpen, setInviteUserDialogIsOpen] = React.useState(false)

  const { percentage, text } = getProductUsageProgressValues(productQuotaTally)

  const quotaIsSpent = percentage >= 100 || productQuotaTally?.productQuota === 0 || productQuotaTally?.productQuota === undefined;

  const userCannotTakeAction = !userCanPermissionProductQuota({
    requiredPermissionValue: perms.USER_CREATE,
    requiredProductValue: products.USERS,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
    quota_is_allowed: !quotaIsSpent
  })

  return (
    <div
      data-testid="invite-team"
      className="flex items-center justify-between border h-24 bg-white rounded-md"
    >
      <div className="mx-6 grid gap-2 flex-1 ">
        <div className="font-semibold text-base">Invite Team Members</div>
        <ProgressBar
          percentage={percentage}
          text={text}
        />
      </div>
      <div className="mx-4">
        <SightlyButton
          datatestid="button-invite-user"
          handleClick={() => setInviteUserDialogIsOpen(true)}
          icon={<UsersIcon className="-ml-0.5 mr-2 h-5" />}
          text={"Invite User"}
          disabled={userCannotTakeAction || quotaIsSpent}
        />
      </div>

      <InviteUserExternal
        isOpen={inviteUserDialogIsOpen}
        setIsOpen={setInviteUserDialogIsOpen}
      />
    </div>
  )
}
