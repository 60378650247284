import React from 'react';
import { NEW_BRAND_PROFILE } from './constants';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import useUser from '@/hooks/useUser';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import { NewBrandProfileFormSchema, NewBrandProfileFormType } from './types';
import GeneratingData from '@/views/BrandProfiles/BrandProfile/components/GeneratingData';
import { useIsMutating } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { products, userActions } from '@/staticData/products';
import Tooltip from '@/components/TailwindTooltip';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import SightlyButton from '@/components/Sightly/SightlyButton';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

interface CreateProfileButtonProps {
  disableTooltip: boolean
  disableCreateButton: boolean
  handleAIGenerateBrandInfo: () => void
}

function CreateProfileButton({
  disableTooltip,
  disableCreateButton,
  handleAIGenerateBrandInfo
}: CreateProfileButtonProps) {
  return <Tooltip
    disabled={disableTooltip}
    content={<ProductLimitedAccessInfo />}
  >
    <div>
      <SightlyButton
        text="Create New Profile"
        selectorClass="pendo-create-brand-profile-button"
        datatestid='new-brand-profile-create'
        handleClick={handleAIGenerateBrandInfo}
        disabled={disableCreateButton}
        />
        </div>
  </Tooltip>
}

function NewBrandProfile() {
  const { user, currentAccount, perms, products, userPermissions, userProducts, subscriptionQuota } = useUser();
  
  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.BRAND_PROFILES)) {
      const quota = subscriptionQuota.get(products.BRAND_PROFILES)
      quotaIsAllowed = quota.isAllowed
  }

  React.useEffect(() => {
    setValue('brandName', user?.company ?? '')
  }, [])

  const {
    sightlyRegister,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    getValues
  } = useSightlyForm<NewBrandProfileFormType>(NewBrandProfileFormSchema)

  const { createBrandProfile, createBrandProfileMutation } = useBrandProfiles({ submittedOnly: false })

  const isCreatingBrandProfile = useIsMutating({ mutationKey: rqKeys.brandProfileCreateMutationKey(currentAccount?.accountId) })

  const onSubmit = handleSubmit((data) => {
    // createBrandProfile(data)
    // to do: refactor form to not use both
    // handleAIGenerateBrandInfo on click and handleSubmit
    // on form submit
    return;
  })

  const handleAIGenerateBrandInfo = () => {
    createBrandProfile({
      ...getValues(),
      isAIGenerate: false
    });
  }

  let disableCreateButton;
  let disableTooltip;

  disableCreateButton = !isValid
    || !!isCreatingBrandProfile
    || !userCanPermissionProductQuota({
      requiredPermissionValue: perms.BRAND_PROFILE_CREATE,
      requiredProductValue: products.BRAND_PROFILES,
      userPermissions: userPermissions,
      userProducts: userProducts,
      checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
      quota_is_allowed: quotaIsAllowed
    });


  disableTooltip = userCanPermissionProductQuota({
    requiredPermissionValue: perms.BRAND_PROFILE_CREATE,
    requiredProductValue: products.BRAND_PROFILES,
    userPermissions: userPermissions,
    userProducts: userProducts,
    checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
    quota_is_allowed: quotaIsAllowed
  });

  return (
    <div className="h-full p-16 bg-gray-50">
      <section className="flex flex-col w-1/4 min-w-[30rem] m-auto gap-10">
        <header className="flex flex-col items-center gap-6">
          <h1 className="text-2xl font-semibold text-center text-gray-900">{NEW_BRAND_PROFILE.HEADER}</h1>
          <p className="text-sm font-normal text-gray-500">{NEW_BRAND_PROFILE.DETAIL}</p>
        </header>
        <main>
          <form
            onSubmit={onSubmit}
            className="flex flex-col w-full gap-10 py-6"
          >
            <SightlyModernFormInput
              {...sightlyRegister('brandName', 'Brand Name')}
              placeholder="Your brand name"
              additionalLabelClassName="!text-gray-500 !text-xs !font-semibold"
              disabled = {!!isCreatingBrandProfile}
            />
            <SightlyModernFormInput
              {...sightlyRegister('websiteUrl', 'Website')}
              prefix="https://"
              placeholder="Your brand website"
              additionalLabelClassName="!text-gray-500 !text-xs !font-semibold"
              disabled = {!!isCreatingBrandProfile}
            />
            <div className="flex px-16 justify-evenly">
              <CreateProfileButton
                disableTooltip={disableTooltip}
                disableCreateButton={disableCreateButton}
                handleAIGenerateBrandInfo={handleAIGenerateBrandInfo}
              />
            </div>
          </form>
          {!!isCreatingBrandProfile && <GeneratingData message='Creating Brand Profile...'/>}
          {createBrandProfileMutation.isError && <div className='text-sm text-red-500' data-testid='new-brand-profile-error-message'>{NEW_BRAND_PROFILE.ERROR_MESSAGE}</div>}
        </main>
      </section>
    </div>
  )
}

export default NewBrandProfile
