import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { FormError } from './SightlyFormError'
import { FormLabel } from './SightlyFormLabel'

interface IProps {
    id: string
    label?: string
    customLabel?:React.ReactElement<any>
    placeholder?: string
    disabled?: boolean
    name: string
    register: UseFormRegister<any>
    error?: string
    width?: string | number
    autoFocus?: boolean
    control: Control<any, any>
    prefix?: string
    textarea?: boolean
    required?: boolean
    dataTestId?: string
    inputType?: string
    minValue?: number
    maxValue?: number
    autocomplete?:boolean
    onChange?: (value: boolean) => void
    isValidUrl?: boolean
}

const SightlyInputForm = ({
    name,
    label,
    customLabel: CustomLabel,
    placeholder,
    disabled = undefined,
    id,
    control,
    error,
    width,
    autoFocus,
    prefix,
    textarea,
    required,
    dataTestId,
    inputType,
    minValue,
    maxValue,
    autocomplete,
    onChange,
    isValidUrl
}: IProps) => {
    const borderClass = prefix ? 'rounded-none rounded-r-md' : 'rounded-md'
    const primaryClassName = `${borderClass} form-input block w-full border-gray-300 focus:border-sightly-blue focus:ring-sightly-blue text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`

    return (
        <div
            data-testid={dataTestId}
            className='w-full text-left'
        >
            <Controller
                name={name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                    const { onChange: onFieldChange, ref, ...fields } = field;
                
                    // On field edit, send boolean to onChange prop indicating whether field is blank
                    const handleChange = (e: { target: { value: string } }) => {
                      onFieldChange(e);
                      if (onChange) {
                        onChange(e.target.value !== '');
                      }
                    }; 
                    return (
                    <div>
                        {label && (
                            <div className="flex items-center">
                            <FormLabel
                                id={id}
                                text={label}
                                required={required}
                            />
                            {isValidUrl !== undefined && !isValidUrl && 
                                <span style={{ color: 'rgb(255, 60, 60)' }} className="error-message ml-2">
                                    Please enter a valid URL
                                </span>}
                        </div>
                        )}
                        {CustomLabel}
                        <div className="flex mt-1 rounded-md shadow-sm">
                            {prefix && (
                                <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
                                    {prefix}
                                </span>
                            )}
                            {textarea ? (
                                <textarea
                                    {...fields}
                                    id={id}
                                    rows={3}
                                    className={primaryClassName}
                                    placeholder={disabled ? '' : placeholder}
                                    disabled={disabled}
                                    autoFocus={autoFocus}
                                    onChange={handleChange}
                                    ref={ref}
                                />
                            ) : (
                                inputType === 'number' ?
                                <input
                                    type='number'
                                    {...fields}
                                    id={id}
                                    min={minValue}
                                    max={maxValue}
                                    autoComplete={autocomplete ? 'on':'off'}
                                    className={primaryClassName}
                                    placeholder={disabled ? '' : placeholder}
                                    disabled={disabled}
                                    autoFocus={autoFocus}
                                    onChange={handleChange}
                                    ref={ref}
                                />
                                :
                                <input
                                    type='text'
                                    {...fields}
                                    id={id}
                                    className={primaryClassName}
                                    placeholder={disabled ? '' : placeholder}
                                    disabled={disabled}
                                    autoFocus={autoFocus}
                                    onChange={handleChange}
                                />
                            )}
                        </div>
                        <FormError
                            id={id}
                            error={error}
                        />
                    </div>
                )}}
            />
        </div>
    )
}

export default SightlyInputForm
