import { SET_UPLOADED_LIST } from '../../action-types/engage/lists'
import { uploadedListObjValidation } from '../../../schemas/Engage/Lists/schemas'

export function setUploadedList(uploadedList) {
	uploadedListObjValidation.validate(uploadedList).catch(function(err) {
		console.log(err.name, err.errors)
		alert(`Whoops! Your file is in the wrong format.`)
		return {
			type: SET_UPLOADED_LIST,
			uploadedList: []
		}
	})
	return {
		type: SET_UPLOADED_LIST,
		uploadedList
	}
}
