import React from 'react'
import AiIcon from '@/views/BrandProfiles/BrandProfile/components/aiIcon.svg'

export const InspireMeButton = ({
  label,
  handleAIGenerate,
  pendoId,
  disabled,
  dataTestId,
  size
}: {
  label: string
  handleAIGenerate: () => void
  disabled?: boolean
  dataTestId?: string
  size?: 'xs'|'sm'
  pendoId?:string
}) => {
  return (
    <div
      data-testid={dataTestId}
      id={pendoId}
      onClick={() => {
        !disabled && handleAIGenerate()
      }}
      className={`flex items-center justify-between  px-4 py-2 rounded-md bg-gradient-to-r from-[#0047FF] via-[#5B32EF] to-[#333D47] 
      ${size=== 'xs' ? 'h-6 text-xs':'h-8'}
      ${disabled ? 'opacity-40 cursor-not-allowed': 'cursor-pointer'}`}
    >
      <span className="pr-2 font-bold text-slate-100">{label}</span>
      <span>
        <img src={AiIcon} className='w-4 h-4' alt="" />
      </span>
    </div>
  )
}
