import { Link, useNavigate } from '@tanstack/react-location';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { api } from '@/views/Admin/ScenariosV2/api';
import { ScenarioFromGetAllType } from '@/views/Admin/ScenariosV2/types';
import Sidebar from '@/views/Admin/components/Sidebar';

export const ScenariosV2Admin = () => {
    const [archived, setArchived] = React.useState(false);
    const scenariosQuery = useQuery(['adminScenariosV2', archived], () => api.v2Scenarios.get({ archived }));
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            {scenariosQuery.isLoading ? (
                <LoadingPage message="Fetching V2 Scenarios" />
            ) : (
                <div className="p-8" style={{ width: '100%' }}>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">Scenarios v2</h1>
                                <p className="mt-2 text-sm text-gray-700"></p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                                <SightlyButton
                                    handleClick={() => navigate({ to: 'create' })}
                                    id="addScenario"
                                    text="Add Scenario"
                                />
                                <SightlyButton
                                    handleClick={() => navigate({ to: 'themes' })}
                                    id="configureThemes"
                                    text="Configure Themes"
                                    type="secondary"
                                />
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <SightlyToggle
                                id="archivedToggle"
                                enabled={archived}
                                setEnabled={setArchived}
                                enabledText="Archived"
                                disabledText="Active"
                            />
                            <div className="pt-3 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                    >
                                                        Scenario Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    >
                                                        Archived
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                                    >
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {scenariosQuery &&
                                                    scenariosQuery.data &&
                                                    scenariosQuery.data.map((scenario: ScenarioFromGetAllType) => (
                                                        <tr key={scenario.id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {scenario.scenarioName}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {scenario.archived ? 'True' : 'False'}
                                                            </td>

                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                <Link to={`/app/admin/v2scenarios/edit/${scenario.id}`}>
                                                                    Edit
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
