import { z } from 'zod'

const OpinionItemSchema = z.object({
  opinionId: z.number(),
  question: z.string(),
  opinionType: z.string(),
  brandProfileId: z.any().optional(),
  opinionResponseId: z.any().optional().nullable(),
  opinionResponseName: z.any().optional(),
  isAiGenerated: z.boolean().nullable(),
  confidenceScore: z.number().nullable(),
  reason: z.string().nullable(),
  opinionDescription: z.string().optional().nullable()
})
export type OpinionType = z.infer<typeof OpinionItemSchema>

export const OpinionsThemeSchema = z.object({
  opinionTypeId: z.number(),
  opinionTypeName: z.string().min(1),
  descriptionText: z.string().optional().nullable(),
  opinions: z.array(OpinionItemSchema)
})

export type OpinionsThemeType = z.infer<typeof OpinionsThemeSchema>
export const OpinionsObjSchema = z.array(OpinionsThemeSchema).min(1)

export type OpinionsType = z.infer<typeof OpinionsObjSchema>

const OpinionPatchSchema = z.object({
  brandProfileId: z.number(),
  opinions: z.array(
    z.object({
      opinionId: z.number(),
      opinionResponseId: z.number()
    })
  )
})

export type OpinionPatchSchemaType = z.infer<typeof OpinionPatchSchema>
