import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { AdGroupsType, TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'

export const targetedActivationListReducer = (
  targetedActivationList: SerializableMap<string, TargetedActivationListType[]>,
  action: TargetedActivationListActionType
) => {
  switch (action.type) {
    case TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN: {
      const { persona, currentActivationItemId, nextCampaignId, prevCampaignId } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      const prevActivationGroup = nextState.get(persona)
      if (prevActivationGroup) {
        const targetedActivationGroup = prevActivationGroup.find(
          ({ activationItemId }) => activationItemId === currentActivationItemId
        )
        if (targetedActivationGroup) {
          if (!targetedActivationGroup.campaignsToAdGroups.has(nextCampaignId)) {
            targetedActivationGroup.campaignsToAdGroups.delete(prevCampaignId)
            targetedActivationGroup.campaignsToAdGroups.set(nextCampaignId, {
              keywordAdGroups: new SerializableSet(),
              videoAdGroups: new SerializableSet()
            })
          }
        }
      }
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS: {
      const nextState = new SerializableMap(targetedActivationList)
      const { persona, currentActivationItemId, campaignId, adGroupType, adGroups } = action.payload
      const prevActivationGroup = nextState.get(persona)
      if (prevActivationGroup) {
        const targetedActivationGroup = prevActivationGroup.find(
          ({ activationItemId }) => activationItemId === currentActivationItemId
        )

        if (targetedActivationGroup) {
          if (!targetedActivationGroup.campaignsToAdGroups.has(campaignId)) {
            return nextState
          }
          const prevKeywordAdGroups =
            targetedActivationGroup.campaignsToAdGroups.get(campaignId)?.keywordAdGroups ??
            new SerializableSet<number>()
          const prevVideoAdGroups =
            targetedActivationGroup.campaignsToAdGroups.get(campaignId)?.videoAdGroups ?? new SerializableSet<number>()
          const adGroupsValue = {
            keywordAdGroups: adGroupType === 'keyword' ? new SerializableSet(adGroups) : prevKeywordAdGroups,
            videoAdGroups: adGroupType === 'video' ? new SerializableSet(adGroups) : prevVideoAdGroups
          }
          targetedActivationGroup.campaignsToAdGroups.set(campaignId, adGroupsValue)
        }
      }
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN: {
      const nextState = new SerializableMap(targetedActivationList)
      const { campaignId, persona, currentActivationItemId } = action.payload
      const currentActivationGroup = nextState.get(persona)
      if (currentActivationGroup) {
        const currentActivation = currentActivationGroup.find(
          ({ activationItemId }) => activationItemId === currentActivationItemId
        )
        currentActivation?.campaignsToAdGroups.delete(campaignId)
        nextState.set(persona, currentActivationGroup)
      }
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.REMOVE_PERSONA: {
      const nextState = new SerializableMap(targetedActivationList)
      const { personaToBeDeleted } = action.payload
      nextState.delete(personaToBeDeleted)
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATION: {
      const { activationToBeDeleted } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      const [persona, currentActivationItemId] = activationToBeDeleted
      const prevActivationGroup = targetedActivationList.get(persona)
      if (prevActivationGroup) {
        const nextActivationGroup = prevActivationGroup.filter(
          ({ activationItemId }) => activationItemId !== currentActivationItemId
        )
        if (!nextActivationGroup.length) nextState.delete(persona)
        else nextState.set(persona, nextActivationGroup)
      }
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.SET_DEFAULT: {
      const { targetedActivationList } = action.payload
      return targetedActivationList
    }

    case TARGETED_ACTIVATION_ACTIONS.BULK_DELETE_ACTIVATIONS: {
      const { selectedActivations } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      selectedActivations.forEach((activations, persona) => {
        const prevActivationGroup = targetedActivationList.get(persona)
        if (prevActivationGroup) {
          const nextActivationGroup = prevActivationGroup.filter(
            ({ activationItemId }) => !activations.has(activationItemId)
          )
          if (!nextActivationGroup.length) nextState.delete(persona)
          else nextState.set(persona, nextActivationGroup)
        }
      })
      return nextState
    }

    case TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS: {
      const { selectedActivations, selectedCampaignsToAdGroups } = action.payload
      const nextState = new SerializableMap(targetedActivationList)
      selectedActivations.forEach((activations, persona) => {
        const prevActivationGroup = targetedActivationList.get(persona)
        if (prevActivationGroup) {
          const selectedActivationGroup = prevActivationGroup.filter((targetedActivation) =>
            activations.has(targetedActivation.activationItemId)
          )
          const nextActivationGroup = selectedActivationGroup.map((selectedActivation) => {
            selectedActivation.campaignsToAdGroups = new SerializableMap(selectedCampaignsToAdGroups)
            return { ...selectedActivation }
          })

          nextState.set(persona, nextActivationGroup)
        }
      })
      return nextState
    }

    default: {
      return targetedActivationList
    }
  }
}

export enum TARGETED_ACTIVATION_ACTIONS {
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  ADD_AD_GROUPS = 'ADD_AD_GROUPS',
  REMOVE_PERSONA = 'REMOVE_PERSONA',
  REMOVE_ACTIVATION = 'REMOVE_ACTIVATION',
  REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN',
  SET_DEFAULT = 'SET_DEFAULT',
  BULK_DELETE_ACTIVATIONS = 'BULK_DELETE_ACTIVATIONS',
  BULK_ASSIGN_CAMPAIGN_ADGROUPS = 'BULK_ASSIGN_CAMPAIGN_ADGROUPS'
}

type NewCampaignPayload = {
  persona: string
  currentActivationItemId: number
  nextCampaignId: number
  prevCampaignId: number
}

type NewAdGroupsPayload = {
  persona: string
  currentActivationItemId: number
  campaignId: number
  adGroupType: 'video' | 'keyword'
  adGroups: number[] | undefined
}

type RemoveCampaignPayload = {
  persona: string
  currentActivationItemId: number
  campaignId: number
}

type RemovePersonaPayload = {
  personaToBeDeleted: string
}

type RemoveActivationPayload = {
  activationToBeDeleted: [string, number]
}

type DefaultActivationPayload = {
  targetedActivationList: SerializableMap<string, TargetedActivationListType[]>
}

type BulkDeleteActivationsPayload = {
  selectedActivations: Map<string, Set<number>>
}

type BulkAssignCampaignAdGroupsPayload = {
  selectedActivations: Map<string, Set<number>>
  selectedCampaignsToAdGroups: SerializableMap<number, AdGroupsType>
}

export type TargetedActivationListActionType =
  | { type: TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN; payload: NewCampaignPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS; payload: NewAdGroupsPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN; payload: RemoveCampaignPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.REMOVE_PERSONA; payload: RemovePersonaPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATION; payload: RemoveActivationPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.SET_DEFAULT; payload: DefaultActivationPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.BULK_DELETE_ACTIVATIONS; payload: BulkDeleteActivationsPayload }
  | { type: TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS; payload: BulkAssignCampaignAdGroupsPayload }
