import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'

import { IAgeAudience, IHashtag } from "@/views/TikTok/Hashtags/types"
import ProgressLine from '@/views/Discover/Boards/NarrativesTab/OverviewSidePanel/ProgressLine'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'

type IProps = {
    open: boolean
    handleClose: () => void
    hashtag: IHashtag
    ageAudiences: IAgeAudience[]
    dateRange: string
    audienceIsLoading: boolean
    audienceError: any
}

export const InsightsPanel = ({ open, handleClose, hashtag, ageAudiences, dateRange, audienceIsLoading, audienceError }: IProps) => {
    useEffect(() => {

    }, [open])

    const formatNumber = (value: number) => {
        if (value < 1000) return value.toString()

        const suffixes = ['K', 'M', 'B', 'T']
        const suffixIndex = Math.floor(Math.log10(value) / 3)
        const shortValue = (value / Math.pow(1000, suffixIndex)).toFixed(1)

        return shortValue.replace(/\.0$/, '') + suffixes[suffixIndex - 1]
    }

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => handleClose()}
            >
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden rounded-md">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="w-screen max-w-xl pointer-events-auto">
                                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                                        <div className="flex flex-col flex-1 min-h-0 pb-2">
                                            <div className="px-4 pt-6  font-black text-xl bg-sky-50">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="pb-5">
                                                        <span data-testid="tiktok-hashtags-insight-hashtag" className='text-lg font-bold'>#{hashtag.hashtagName}</span>
                                                        <span data-testid="tiktok-hashtags-insight-category" className='text-sm block font-normal'>{hashtag.categoryName}</span>
                                                    </Dialog.Title>

                                                    <div className="flex items-center ml-3 h-7">
                                                        <button
                                                            data-testid="tiktok-hashtags-insight-close-button"
                                                            type="button"
                                                            className="text-black rounded-md"
                                                            onClick={() => handleClose()}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon
                                                                className="w-6 h-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='!m-0 p-5 !h-full !w-full overflow-y-auto'>

                                                <span data-testid="tiktok-hashtags-insight-title" className='text-xl font-bold text-black'>Hashtag Insights</span>
                                                <span data-testid="tiktok-hashtags-insight-subtitle" className='block text-sm mt-12 mb-4 font-semibold'>Post & Views</span>

                                                <div className='flex gap-4'>
                                                    <div className='p-4 border rounded-lg shadow-md bg-white hover:shadow-lg flex flex-col w-1/2'>
                                                        <span data-testid="tiktok-hashtags-insight-posts" className='text-sm font-semibold'>Posts</span>
                                                        <div className='flex gap-4'>
                                                            <div className='flex flex-col'>
                                                                <span data-testid="tiktok-hashtags-insight-posts-count" className='text-3xl font-extrabold text-black'>{formatNumber(hashtag.posts)}</span>
                                                                <span data-testid="tiktok-hashtags-insight-posts-date" >{dateRange}</span>
                                                            </div>
                                                            <span className='font-extrabold text-4xl text-black'>/</span>
                                                            <div className='flex flex-col'>
                                                                <span data-testid="tiktok-hashtags-insight-posts-total" className='text-3xl font-extrabold text-black'>{formatNumber(hashtag.postsGlobalLifetime)}</span>
                                                                <span data-testid="tiktok-hashtags-insight-posts-overall" >Overall</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='p-4 border rounded-lg shadow-md bg-white hover:shadow-lg flex flex-col w-1/2'>
                                                        <span data-testid="tiktok-hashtags-insight-views" className='text-sm font-semibold'>Views</span>
                                                        <div className='flex gap-4'>
                                                            <div className='flex flex-col'>
                                                                <span data-testid="tiktok-hashtags-insight-views-count" className='text-3xl font-extrabold text-black'>{formatNumber(hashtag.views)}</span>
                                                                <span data-testid="tiktok-hashtags-insight-views-date" >{dateRange}</span>
                                                            </div>
                                                            <span className='font-extrabold text-4xl text-black'>/</span>
                                                            <div className='flex flex-col'>
                                                                <span data-testid="tiktok-hashtags-insight-views-total" className='text-3xl font-extrabold text-black'>{formatNumber(hashtag.viewsGlobalLifetime)}</span>
                                                                <span data-testid="tiktok-hashtags-insight-views-overall" >Overall</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-10">
                                                    <span data-testid="tiktok-hashtags-insight-age-title" className="text-sm font-semibold">Age range</span>
                                                    <div>
                                                        {audienceIsLoading ? (
                                                            <LoadingPage message={'Fetching age range values'} />
                                                        ) : audienceError ? (
                                                            <InfoPage message="There was an unknown error fetching age range values. Please refresh the page to try again. If the problem persists, please contact support." />
                                                        ) : ageAudiences && ageAudiences.length > 0 ? (
                                                            ageAudiences.map((ageAudience, index) => (
                                                                <div className="mt-2" key={ageAudience.age}>
                                                                    <ProgressLine data-testid={`tiktok-hashtags-insight-age-score-${index}`} score={ageAudience.percentage} />
                                                                    <span data-testid={`tiktok-hashtags-insight-age-value-${index}`} className="block text-xs text-black">
                                                                        {ageAudience.age}
                                                                    </span>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className='flex flex-row gap-2 bg-blue-100 p-4 mt-4'>
                                                                <InformationCircleIcon className="flex h-10 mr-1 text-cyan-600" aria-hidden="true" />
                                                                <div>
                                                                    <span className='flex text-lg font-semibold'>Audience Insights</span>
                                                                    <span>If the hashtag is not available for targeting or the expected audience size is less than 1.000, audience insights will be empty.</span>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
