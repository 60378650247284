import React from 'react';
import { PATH_MOMENTS_V2 } from '@/routes';
import { useQueryClient } from '@tanstack/react-query';
import { userAccountAxios } from '@/axiosInstances';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { rqKeys } from '@/ReactQueryKeyFactory';
import debounce from 'just-debounce-it';
import {
  BoardSchemaType,
  HandleSelectProps,
  IProps,
  Item,
  ItemDetail,
  ItemTypes,
  SearchResult
} from '@/views/Discover/Boards/types';
import { useSemanticSearch, useBoardsAPI } from '@/views/Discover/Boards/hooks';
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic';
import { products } from '@/staticData/products';
import { GENERAL_BOARD_DEFAULT_TYPES, SUGGESTED_BOARD_NAMES } from '@/views/BrandProfiles/constants';
import _ from 'lodash';

const getSelectedItems = (items: Item[]) => {
  let selectedItems = [];
  for (const item of items) {
    for (const _el of item.items) {
      if (_el.selected === true) {
        selectedItems.push({ ..._el, groupName: item.name });
      }
    }
  }
  return selectedItems;
}

const getSelectedQueryIds = (item: Item) => {
  if (!item) return [];
  let selectedItems = [];
  for (const _item of item.items) {
    if (_item.selected === true) {
      selectedItems.push(_item.id);
    }
  }
  return selectedItems;
}

const getTemplateBoardNames = (defaultType: string) => {
  switch (defaultType) {
    case GENERAL_BOARD_DEFAULT_TYPES.COMPETITORS:
      return SUGGESTED_BOARD_NAMES.COMPETITORS;
    case GENERAL_BOARD_DEFAULT_TYPES.INFLUENCERS:
      return SUGGESTED_BOARD_NAMES.INFLUENCERS;
    case GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS:
      return SUGGESTED_BOARD_NAMES.TRENDING_MOMENTS;
    case GENERAL_BOARD_DEFAULT_TYPES.INDUSTRY:
      return SUGGESTED_BOARD_NAMES.INDUSTRY;
    case GENERAL_BOARD_DEFAULT_TYPES.SAFEGUARD:
      return SUGGESTED_BOARD_NAMES.SAFEGUARD;
    case GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE:
      return SUGGESTED_BOARD_NAMES.RELEVANCE;
    default:
      return 'New Board'
  }
}

const useAylienBoardCreator = ({ boardType }: IProps) => {
  const navigate = useNavigate();
  const { brandProfileId, accountId } = useSearch<MyLocationGenerics>();
  const {
    params: { boardId }
} = useMatch();
  const queryClient = useQueryClient();
  const { queryResult: basicInfoResult } = useBrandProfileBasic(Number(brandProfileId));
  const [board, setBoard] = React.useState<BoardSchemaType>();
  const [queries, setQueries] = React.useState<Item[]>([]);
  const [selectedQueries, setSelectedQueries] = React.useState<ItemDetail[]>([]);
  const [areQueriesLoading, setAreQueriesLoading] = React.useState<boolean>(true);
  const [aylienNews, setAylienNews] = React.useState<Item[]>([]);
  const [areAylienNewsLoading, setAreAylienNewsLoading] = React.useState<boolean>(true);
  const [searchedAylienNews, setSearchedAylienNews] = React.useState<Item[]>([]);
  const [selectedAylienNews, setSelectedAylienNews] = React.useState<ItemDetail[]>([]);
  const [aylienIndustries, setAylienIndustries] = React.useState<Item[]>([]);
  const [areAylienIndustriesLoading, setAreAylienIndustriesLoading] = React.useState<boolean>(true);
  const [selectedAylienIndustries, setSelectedAylienIndustries] = React.useState<ItemDetail[]>([]);
  const [searchedAylienIndustries, setSearchedAylienIndustries] = React.useState<Item[]>([]);
  const [aggregates, setAggregates] = React.useState<Item[]>([]);
  const [name, setName] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isCreating, setIsCreating] = React.useState(false);
  const [isOr, setIsOr] = React.useState(true);
  const {
    fetchBrandProfileAggregates,
    fetchBrandProfileAylienIndustries,
    fetchBrandProfileAylienNews,
    fetchBrandProfileQueries,
    fetchBoardById
  } = useBoardsAPI({
    brandProfileId,
    boardId : Number(boardId),
    boardType,
    board,
    setBoard,
    setQueries,
    setAylienNews,
    setSearchedAylienNews,
    setAylienIndustries,
    setSearchedAylienIndustries,
    setAggregates,
    setAreQueriesLoading,
    setAreAylienIndustriesLoading,
    setAreAylienNewsLoading
  });

  React.useEffect(() => {
    if (!brandProfileId) return
    if (boardId) {
      fetchBoardById().then((board) => {
        fetchBrandProfileQueries(board);
        fetchBrandProfileAylienNews(board);
        fetchBrandProfileAylienIndustries(board);
        fetchBrandProfileAggregates();
      })
      return
    }
    fetchBrandProfileQueries();
    fetchBrandProfileAylienNews();
    fetchBrandProfileAylienIndustries();
    fetchBrandProfileAggregates();
  }, [brandProfileId]);

  React.useEffect(() => {
    board && setEditBoard(board);
  }, [board]);

  const setEditBoard = (board: BoardSchemaType) => {
    setName(board?.boardName);
    setIsOr(board.operator === 'or' ? true : false);
  }

  // Resets the selected moments from the MomentsPage
  const resetSelectedMoments = (brandProfileId:number | undefined) => {
    const storedSelectedMoments = sessionStorage.getItem('selectedMoments');
    const selectedMomentsList =
      storedSelectedMoments
      && storedSelectedMoments !== 'undefined'
      && !_.isNil(storedSelectedMoments)
      && JSON.parse(storedSelectedMoments);
    brandProfileId && selectedMomentsList && delete selectedMomentsList[brandProfileId];
    selectedMomentsList && sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsList));
  }

  const saveModifiedBoard = async () => {
    setIsCreating(true);
    const url = `/brand-profile/${brandProfileId}/board/${boardId}`;
    const _board = {
      name,
      operator: isOr ? 'or' : 'and',
      aylienNewsIds: getSelectedItems(aylienNews).map(({ id }) => id),
      aylienIndustryIds: getSelectedItems(aylienIndustries).map(({ id }) => id),
      queries: {
        competitors: getSelectedQueryIds(queries.filter((q) => q.name === 'competitors')[0]),
        influencers: getSelectedQueryIds(queries.filter((q) => q.name === 'influencers')[0]),
        events: getSelectedQueryIds(queries.filter((q) => q.name === 'events')[0]),
        philanthropic: getSelectedQueryIds(queries.filter((q) => q.name === 'philanthropic')[0]),
        socialAndEnvironmental: getSelectedQueryIds(queries.filter((q) => q.name === 'socialAndEnvironmental')[0])
      }
    };

    await userAccountAxios.put(url, _board);
    await queryClient.invalidateQueries(rqKeys.brandProfileBoards(brandProfileId));
    setIsCreating(false);

    window.history.back()
    resetSelectedMoments(brandProfileId);
  }

  // createBoard can have a template-related code cleanup in the future
  const createBoard = async () => {
    setIsCreating(true);
    const url = `/brand-profile/${brandProfileId}/board`;

    let boardName = name;
    if (getSelectedItems(aggregates).length > 0 && name.length === 0) {
      const defaultType = aggregates[0].items.filter((i: ItemDetail) => i.selected)[0].defaultType;
      boardName = getTemplateBoardNames(defaultType)
    }

    if (getSelectedItems(aylienNews).length > 0 && name.length === 0) {
      boardName = getSelectedItems(aylienNews)[0].name;
    }
    if (getSelectedItems(aylienIndustries).length > 0 && name.length === 0) {
      boardName = getSelectedItems(aylienIndustries)[0].name;
    }

    const _board: Partial<any> = {
      boardType,
      name: boardName,
      starred: false,
      aylienNewsIds: getSelectedItems(aylienNews).map(({ id }) => id),
      aylienIndustryIds: getSelectedItems(aylienIndustries).map(({ id }) => id),
      queries: {
        competitors: getSelectedQueryIds(queries.filter((q) => q.name === 'competitors')[0]),
        influencers: getSelectedQueryIds(queries.filter((q) => q.name === 'influencers')[0]),
        events: getSelectedQueryIds(queries.filter((q) => q.name === 'events')[0]),
        philanthropic: getSelectedQueryIds(queries.filter((q) => q.name === 'philanthropic')[0]),
        socialAndEnvironmental: getSelectedQueryIds(queries.filter((q) => q.name === 'socialAndEnvironmental')[0])
      }
    }
    if (boardType === 'general') {
      delete _board.queries;
    }

    if (boardType === 'custom') {
      _board.operator = isOr ? 'or' : 'and';
    }

    if (getSelectedItems(aggregates).length > 0) {
      _board.defaultType = aggregates[0].items.filter((i: ItemDetail) => i.selected)[0].defaultType;
    }

    const newBoard = await userAccountAxios.post(url, _board);
    await queryClient.invalidateQueries(rqKeys.brandProfileBoards(brandProfileId));
    // to refresh product quota
    queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.BOARDS));
    setIsCreating(false);
    navigate({
      to: PATH_MOMENTS_V2,
      search(prev?) {
        return {
          ...prev,
          boardsView: 'My Boards',
          boardIds: [newBoard.data['id']],
          brandProfileId: brandProfileId,
          timeRange: 1,
          searchTerm: '',
          sortBy:  _board?.defaultType === GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE ? 'relevance' : 'ranking',
        }
      }
    });
  }

   // This is a streamline version of createBoard
  const createBoardFromTemplate = async () => {
    setIsCreating(true);
    const url = `/brand-profile/${brandProfileId}/board`;

    let boardName = '';
    const defaultType = aggregates[0].items.filter((i: ItemDetail) => i.selected)[0].defaultType;

    if (defaultType === GENERAL_BOARD_DEFAULT_TYPES.INDUSTRY) {
      const industryUrl = `/v1/brand-profiles/industry_categories/${basicInfoResult.data?.aylienIndustryId}`;
      const { data: boardIndustry } = await userAccountAxios.get<Item>(industryUrl);
      boardName = boardIndustry
        ? boardIndustry?.name
        : getTemplateBoardNames(defaultType)
    } else {
      boardName = getTemplateBoardNames(defaultType);
    }

    const _board: Partial<any> = {
      boardType,
      name: boardName,
      starred: false,
      aylienNewsIds: [],
      aylienIndustryIds: [],
      defaultType,
    }

    const newBoard = await userAccountAxios.post(url, _board);
    await queryClient.invalidateQueries(rqKeys.brandProfileBoards(brandProfileId));
    // to refresh product quota
    queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.BOARDS));
    setIsCreating(false);
    navigate({
      to: PATH_MOMENTS_V2,
      search(prev?) {
        return {
          ...prev,
          boardsView: 'My Boards',
          boardIds: [newBoard.data['id']],
          brandProfileId: brandProfileId,
          timeRange: 1,
          searchTerm: '',
          sortBy: _board?.defaultType === GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE ? 'relevance' : 'ranking',
        }
      }
    });
  }

  // This is a streamline version of createBoard


  const handleSelectItem = ({ itemType, name, id }: HandleSelectProps) => {
    if (itemType === ItemTypes.aylienIndustries) {
      setAylienIndustries((prev) => {
        return prev.map((item) => {
          if (item.name === name) {
            for (const el of item.items) {
              if (el.id === id) {
                el.selected = !el.selected;
              }
            }
          }
          return item;
        })
      })
      return;
    }

    if (itemType === ItemTypes.aylienNews) {
      setAylienNews((prev) => {
        return prev.map((item) => {
          if (item.name === name) {
            for (const el of item.items) {
              if (el.id === id) {
                el.selected = !el.selected;
              }
            }
          }
          return item;
        })
      })
      return;
    }

    if (itemType === ItemTypes.queries) {
      setQueries((prev) => {
        return prev.map((item) => {
          if (item.name === name) {
            for (const el of item.items) {
              if (el.id === id) {
                el.selected = !el.selected;
              }
            }
          }
          return item;
        })
      })
    }

    if (itemType === ItemTypes.aggregate) {
      setAggregates((prev) => {
        return prev.map((item) => {
          if (item.name === name) {
            for (const el of item.items) {
              if (el.id === id) {
                el.selected = !el.selected;
              }
            }
          }
          return item;
        })
      })
    }
  }

  const selectedCount = React.useMemo(() => {
    let selectedItems = 0
    for (const query of queries) {
      for (const item of query.items) {
        if (item.selected === true) {
          selectedItems++;
        }
      }
    }
    for (const news of aylienNews) {
      for (const item of news.items) {
        if (item.selected === true) {
          selectedItems++;
        }
      }
    }
    for (const ind of aylienIndustries) {
      for (const item of ind.items) {
        if (item.selected === true) {
          selectedItems++
        }
      }
    }
    for (const a of aggregates) {
      for (const item of a.items) {
        if (item.selected === true) {
          selectedItems++;
        }
      }
    }

    return selectedItems
  }, [queries, aylienIndustries, aylienNews, aggregates, boardType])

  const searchedQueries = React.useMemo(() => {
    if (!searchTerm) {
      return queries;
    }

    return queries.map((q) => {
      return {
        name: q.name,
        items: q.items.filter((i) => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
      }
    });
  }, [searchTerm, queries]);

  const { searchAylienIndustriesQuery, searchAylienNewsQuery } = useSemanticSearch(
    brandProfileId,
    searchTerm,
    boardType
  );

  const uniqueCategories = (categories: ItemDetail[], semanticSearchResult: SearchResult[]) => {
    const filteredCategories = new Map()
    categories.forEach((category) => {
      if (
        !filteredCategories.has(category.name) &&
        (category.name.toLowerCase().includes(searchTerm.toLowerCase()) || category.id in semanticSearchResult)
      ) {
        filteredCategories.set(category.name, category);
      }
    })
    return Array.from(filteredCategories.values());
  };

  const searchAylienNews = React.useCallback(
    debounce(() => {
      if (!searchTerm) {
        setSearchedAylienNews(aylienNews);
        return;
      }
      if (boardType === 'general') {
        setSearchedAylienNews(
          aylienNews.map((q) => {
            return {
              name: q.name,
              items: q.items.filter(
                (i) =>
                  i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  i?.description?.toLowerCase()?.includes(searchTerm.toLowerCase())
              )
            };
          })
        )
        return;
      }

      searchAylienNewsQuery.refetch().then(({ data: semanticSearchResult }) => {
        semanticSearchResult &&
          setSearchedAylienNews(
            aylienNews.map((q) => ({
              name: q.name,
              items: uniqueCategories(q.items, semanticSearchResult)
            }))
          );
      })
    }, 1000, true),
    [searchTerm]
  );

  React.useEffect(() => {
    areAylienNewsLoading && aylienNews.length && setAreAylienNewsLoading(false);
    setSelectedAylienNews(getSelectedItems(aylienNews));
  }, [aylienNews])

  React.useEffect(() => {
    setSelectedAylienIndustries(getSelectedItems(aylienIndustries));
  }, [aylienIndustries])

  React.useEffect(() => {
    setSelectedQueries(getSelectedItems(queries));
  }, [queries])

  const searchAylienIndustries = React.useCallback(
    debounce(() => {
      if (!searchTerm) {
        setSearchedAylienIndustries(aylienIndustries);
        return;
      }
      if (boardType === 'general') {
        setSearchedAylienIndustries(
          aylienIndustries.map((q) => {
            return {
              name: q.name,
              items: q.items.filter(
                (i) =>
                  i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  i?.description?.toLowerCase()?.includes(searchTerm.toLowerCase())
              )
            }
          })
        );
        return;
      }

      searchAylienIndustriesQuery.refetch().then(({ data: semanticSearchResult }) => {
        semanticSearchResult &&
          setSearchedAylienIndustries(
            aylienIndustries.map((q) => ({
              name: q.name,
              items: uniqueCategories(q.items, semanticSearchResult)
            }))
          );
      })
    }, 1000, true),
    [searchTerm]
  );

  React.useEffect(() => {
    searchAylienNews();
    searchAylienIndustries();
  }, [searchTerm]);

  const searchedAggregates = React.useMemo(() => {
    if (!searchTerm) {
      return aggregates
    }
    return aggregates.map((q) => {
      return {
        name: q.name,
        items: q.items.filter((i) => i.name.toLowerCase().includes(searchTerm.toLowerCase()))
      };
    });
  }, [searchTerm, aggregates]);

  const queriesCount = React.useMemo(() => {
    let _count = 0;
    for (const query of queries) {
      for (const item of query.items) {
        _count++;
      }
    }
    return _count;
  }, [queries]);

  const industriesTargetedCount = React.useMemo(() => {
    let _count = 0;
    for (const group of aylienIndustries) {
      for (const item of group.items) {
        if (item.targeted) {
          _count++;
        }
      }
    }
    return _count;
  }, [aylienIndustries]);

  const newsTargetedCount = React.useMemo(() => {
    let _count = 0;
    for (const group of aylienNews) {
      for (const item of group.items) {
        if (item.targeted) {
          _count++;
        }
      }
    }
    return _count;
  }, [aylienNews]);

  const aggregateTargetedCount = React.useMemo(() => {
    let _count = 0;
    for (const group of aggregates) {
      for (const item of group.items) {
        if (item.targeted) {
          _count++;
        }
      }
    }
    return _count
  }, [aggregates]);

  return {
    queries: searchedQueries,
    selectedQueries,
    areQueriesLoading,
    aylienIndustries: searchedAylienIndustries,
    selectedAylienIndustries,
    areAylienIndustriesLoading,
    aylienNews: searchedAylienNews,
    selectedAylienNews,
    areAylienNewsLoading,
    aggregates: searchedAggregates,
    name,
    setName,
    createBoard,
    createBoardFromTemplate,
    isCreating,
    saveModifiedBoard,
    handleSelectItem,
    setSearchTerm,
    searchTerm,
    selectedCount,
    queriesCount,
    industriesTargetedCount,
    newsTargetedCount,
    aggregateTargetedCount,
    isOr,
    setIsOr,
    boardId,
    board,
  }
}

export default useAylienBoardCreator;
