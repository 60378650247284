import { useQuery } from "@tanstack/react-query";
import { api } from "@/api/api";
import { IMoment, INarrative, ITrend, ITopic } from "../types";
import { logError } from "@/utils";

export function useGenerateSummaryData(moments: IMoment[], narratives: INarrative[], trends: ITrend[], topics: ITopic[], callSummaryOnSearch: boolean) {
  const summaryQuery = useQuery(
    ['generateSummary',callSummaryOnSearch],
    async () => {
      const data = {
        moments: moments.length > 20 ? moments.slice(0, 20) : moments,
        narratives: narratives.length > 20 ? narratives.slice(0, 20) : narratives,
        trends: trends.length > 20 ? trends.slice(0, 20) : trends,
        topics: topics.length > 20 ? topics.slice(0, 20) : topics,
      };

      const response = await api.summary.get(data);
      return response.data;
    },
    {
      retry: 1,
      onError: (err) => {
        logError(err, { info: 'Error generating summary' })
      }
    }
  );

  return {
    summaryData: summaryQuery.data,
    isLoadingSummary: summaryQuery.isLoading,
  }
}

