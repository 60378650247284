import React, { ReactNode } from 'react';

interface IProps {
  title?: string;
  children: ReactNode;
}

const SightlyGradientCard = ({ title, children }: IProps) => {
  return (
    <div className="relative rounded-md overflow-hidden p-0.5 bg-gradient-to-r from-[#48C294] via-[#8F7EFF] to-[#4F46E5]">
      <div className="p-4 rounded-md bg-white/70 h-full w-full">
        {title && (
          <h3 className="text-highlightPurple text-sm">
            {title}
          </h3>
        )}
        {children}
      </div>
    </div>
  );
};

export default SightlyGradientCard;
