import React from "react";
import { ActivationStatus } from "@/views/Discover/Moments/v2/types";
import { SightlyTag } from "@/components/Sightly/SightlyTag";

interface IProps {
    activationStatus: ActivationStatus
}

const toTitleCase = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const ActivationStatusBadge = ({ activationStatus }: IProps) => {
    // Converts "placements" type name to "videos"
    const { action, status, types } = activationStatus
    let text: string | undefined
    let tagType: string | undefined

    switch(status) {
        case 'pending':
        case 'queued':
        case 'active':
        case 'processing':
            // e.g. Block videos processing
            text = `${types.map(type => toTitleCase(type === 'placements' ? 'videos' : type)).join('/ ')} ${action} processing`
            tagType = 'warning'
            break
        case 'timeout':
        case 'failed':
            // e.g. Block videos error
            text = `${types.map(type => toTitleCase(type === 'placements' ? 'videos' : type)).join('/ ')} ${action} error`
            tagType = 'error'
            break
        case 'complete':
            // e.g. Videos blocked
            text = `${types.map(type => toTitleCase(type === 'placements' ? 'videos' : type)).join('/ ')} ${action}ed`
            tagType = 'success'
            break
    }

    if (!text || !tagType) return null;

    return (
      <SightlyTag
        dataTestId='moment-card-action-tags'
        text={text}
        type={tagType}
      />
    )
}
