import { useEffect } from 'react'

export const useBodyOverflowXHidden = () => {
    useEffect(() => {
        document.body.style.overflowX = 'hidden'

        return () => {
            document.body.style.overflowY = 'auto'
        }
    }, [])
}
