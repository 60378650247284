import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum CREATIVE_DIMENSION {
    AD_ID = 'adId',
    CLIENT_CREATIVE_ID = 'clientCreativeId',
    CLIENT_CREATIVE_NAME = 'clientCreativeName',
    VIDEO_DURATION = 'videoDuration',
    VIDEO_ID = 'videoId',
    VIDEO_TITLE = 'videoTitle',
    DEVICE = 'device',
}

interface CreativeDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | CREATIVE_DIMENSION
}

export interface CreativeReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | CREATIVE_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | CreativeDimensionOption)[]
}

export const dimensionOptions: Option<CreativeDimensionOption> = {
  [CREATIVE_DIMENSION.AD_ID]: {
    name: 'Ad Id',
    value: CREATIVE_DIMENSION.AD_ID
  },
  [CREATIVE_DIMENSION.CLIENT_CREATIVE_ID]: {
    name: 'Client Creative Id',
    value: CREATIVE_DIMENSION.CLIENT_CREATIVE_ID
  },
  [CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME]: {
    name: 'Client Creative Name',
    value: CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME
  },
  [CREATIVE_DIMENSION.DEVICE]: {
    name: 'Device',
    value: CREATIVE_DIMENSION.DEVICE
  },
  [CREATIVE_DIMENSION.VIDEO_ID]: {
    name: 'Video Id',
    value: CREATIVE_DIMENSION.VIDEO_ID
  },
  [CREATIVE_DIMENSION.VIDEO_TITLE]: {
    name: 'Video Title',
    value: CREATIVE_DIMENSION.VIDEO_TITLE
  },
  [CREATIVE_DIMENSION.VIDEO_DURATION]: {
    name: 'Video Duration',
    value: CREATIVE_DIMENSION.VIDEO_DURATION
  },
}

export const baseCreativeReport: CreativeReport = {
  name: 'Creative Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.CREATIVE,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const creativeReportTemplate: CreativeReport = {
  ...baseCreativeReport,
  name: 'Creative Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    CREATIVE_DIMENSION.VIDEO_TITLE,
    CREATIVE_DIMENSION.VIDEO_DURATION,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
