import React from 'react'
import { Control } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { getRelationships } from "../queries-api";
import SightlySelectForm from "@/components/Sightly/SightlyFormElements/SightlySelectForm";

interface IProps {
    control: Control<any, any>
    disabled?: boolean
}

const RelationshipSelectForm = ({ control, disabled }: IProps) => {
    const {
        data: relationshipOptions
    } = useQuery(['queryRelationships'], getRelationships)

    return (
        <SightlySelectForm
            dataTestId={"select-relationships"}
            label="Type"
            id="relationship-type"
            labelKey="relationship"
            valueKey="id"
            name="relationshipId"
            options={relationshipOptions}
            control={control}
            disabled={disabled}
        />
    )
}

export default RelationshipSelectForm
