import { useQuery } from '@tanstack/react-query'
import { api } from './api'

interface IProps {
    includeDeleted: boolean
}

export const useThemes = ({ includeDeleted }: IProps) => {
    const QUERY_KEY = ['allScenarioThemes', includeDeleted]
    const themesQuery = useQuery(QUERY_KEY, () => api.themes.get({ includeDeleted }))
    return {
        ...themesQuery
    }
}
