import React from 'react'
import Input from 'rsuite/lib/Input'
import IconButton from 'rsuite/lib/IconButton'
import TagGroup from 'rsuite/lib/TagGroup'
import Tag from 'rsuite/lib/Tag'
import Icon from 'rsuite/lib/Icon'
import InputGroup from 'rsuite/lib/InputGroup'

const CreatableTagPicker = ({
	items,
	disabled,
	backgroundColor,
	textColor,
	saveChanges,
	id
}) => {
	const [typing, setTyping] = React.useState(false)
	const [inputValue, setInputValue] = React.useState('')
	const [tags, setTags] = React.useState([])

	React.useEffect(() => {
		setTags(items)
	}, [items])

	const handleButtonClick = () => {
		setTyping(true)
	}
	const handleInputChange = (inputValue) => {
		setInputValue(inputValue)
	}
	const handleInputConfirm = () => {
		const nextTags = inputValue ? [...tags, inputValue] : tags

		setTags(nextTags)
		setTyping(false)
		setInputValue('')
		saveChanges(nextTags)
	}
	const handleTagRemove = (tag) => {
		const nextTags = tags.filter((item) => item !== tag)
		setTags(nextTags)
		saveChanges(nextTags)
	}

	const renderInput = () => {
		if (typing) {
			return (
				<div style={{ display: 'inline-block' }}>
					<InputGroup
						size='xs'
						style={{
							width: 100,
							margin: '10px'
						}}
					>
						<Input
							className='tag-input'
							autoFocus
							size='xs'
							style={{
								width: 70
							}}
							value={inputValue}
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
							disabled={disabled}
						/>
						<InputGroup.Button
							appearance='link'
							size='xs'
							onClick={handleInputConfirm}
							disabled={disabled}
						>
							<Icon icon='save' />
						</InputGroup.Button>
					</InputGroup>
				</div>
			)
		}
		return (
			<IconButton
				style={{
					display: 'inline-block',
					margin: '10px'
				}}
				onClick={handleButtonClick}
				icon={<Icon icon='plus' />}
				appearance='ghost'
				size='xs'
				disabled={disabled}
				id='clickToAddButton'
			>
				Click to add
			</IconButton>
		)
	}

	return (
		<TagGroup style={{ margin: 0 }} id={id + 'tagGroup'}>
			{tags.map((item, index) => (
				<Tag
					style={{
						fontWeight: 900,
						backgroundColor: backgroundColor,
						color: textColor
					}}
					key={index}
					closable={!disabled}
					onClose={() => {
						handleTagRemove(item)
					}}
				>
					{item}
				</Tag>
			))}
			{renderInput()}
		</TagGroup>
	)
}

export default CreatableTagPicker
