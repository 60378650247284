import { z } from 'zod'

const passwordStrength = require('check-password-strength')

const emailSchema = z.string().email()
export function isValidEmail(email) {
    const res = emailSchema.safeParse(email)
    return res.success
}

export function isEmailError(text) {
    return text.length > 0 && !isValidEmail(text)
}

export function isEmailSuccess(text) {
    return isValidEmail(text)
}

export function isValidPassword(text) {
    if (text.length > 1) {
        let val = passwordStrength(text)
        let strength = val.value
        return strength !== 'Weak'
    } else {
        return true
    }
}

export function invalidPasswordObject(text) {
    let requirements = [
        { id: 1, text: '6 characters minimum', satisfied: false },
        { id: 2, text: 'One special character', satisfied: false },
        { id: 3, text: 'One lowercase character', satisfied: false },
        { id: 4, text: 'One uppercase character', satisfied: false },
        { id: 5, text: 'One number', satisfied: false }
    ]

    if (text.length < 1) {
        return requirements
    }
    let val = passwordStrength(text)

    let contains = val.contains

    let hasLower = false
    let hasUpper = false
    let hasNumber = false
    let hasSpecial = false

    for (const prop of contains) {
        if (prop.message === 'lowercase') {
            hasLower = true
        }
        if (prop.message === 'uppercase') {
            hasUpper = true
        }
        if (
            prop.message === 'symbol' ||
            text.includes('-') ||
            text.includes(')') ||
            text.includes('(') ||
            text.includes('_') ||
            text.includes('/') ||
            text.includes('\\')
        ) {
            hasSpecial = true
        }
        if (prop.message === 'number') {
            hasNumber = true
        }
    }

    let length = val.length

    let hasMinimum = false
    if (length >= 6) {
        hasMinimum = true
    }

    requirements[0].satisfied = hasMinimum
    requirements[1].satisfied = hasSpecial
    requirements[2].satisfied = hasLower
    requirements[3].satisfied = hasUpper
    requirements[4].satisfied = hasNumber

    return requirements
}

export function isIframe() {
    return window.self !== window.top;
}
