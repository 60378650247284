import { listBuilderAxios } from "@/axiosInstances"
import { ITrend, trendSchema, IPeople, peopleSchema, IBrand, brandsSchema, INarrative, narrativeSchema, IMomentTimeline, momentTimelineSchema, ITrendMetric, trendMetricSchema, articleSchema, IArticle } from "@/views/Trends/types"


export const trends = {
    get: async (search?: string) => {
      let url = `/trend-analysis/trends?prompt=${search ? search : ''}`
      return listBuilderAxios.get<ITrend[]>(url)
    },

    getById: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}`
      const result = await listBuilderAxios.get<ITrend>(url)
      return trendSchema.parse(result.data)
    },

    getPeople: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/people`
      const result = await listBuilderAxios.get<IPeople[]>(url)
      return result.data.map(person => peopleSchema.parse(person))
    },

    getBrands: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/brands`
      const result = await listBuilderAxios.get<IBrand[]>(url)
      return result.data.map(brand => brandsSchema.parse(brand)) },

    getNarratives: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/narratives`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getMoments: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/moments`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getArticles: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/moment-articles`
      const result = await listBuilderAxios.get<IArticle[]>(url)
      return result.data.map(brand => articleSchema.parse(brand))
    },

    getMomentTimeline: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/moments-timeline`
      const result = await listBuilderAxios.get<IMomentTimeline>(url)
      return momentTimelineSchema.parse(result.data)
    },

    getSocialPosts: async (trendId: number) => {
      const url = `/trends/${trendId}/metrics?type=social-posts`
      const result = await listBuilderAxios.get<ITrendMetric[]>(url)
      return result.data.map(trendMetric => trendMetricSchema.parse(trendMetric))
    },

    getArticlesMetrics: async (trendId: number) => {
      const url = `/trends/${trendId}/metrics?type=articles`
      const result = await listBuilderAxios.get<ITrendMetric[]>(url)
      return result.data.map(trendMetric => trendMetricSchema.parse(trendMetric))
    },

    getSentimentScore: async (trendId: number) => {
      const url = `/trend-analysis/trends/${trendId}/sentiment-score`
      const result = await listBuilderAxios.get<Number>(url)
      return result.data;
    }
}