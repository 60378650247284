import { userAccountAxios } from '../../../../../axiosInstances'
import { ScenarioThemesSchema, PatchScenariosType } from './scenariosV2-types'

export const scenariosApi = {
    getV2Scenarios: async ({ brandProfileId, sortBy }: { brandProfileId: number; sortBy: 'least' | 'most' }) => {
        const url = `/brand-profile/${brandProfileId}/scenarios/v2?completionSort=${
            sortBy === 'least' ? 'asc' : 'desc'
        }`
        const { data } = await userAccountAxios.get(url)
        const parsedData = ScenarioThemesSchema.parse(data)
        return parsedData
    },
    respondToScenarios: async ({
        scenarios,
        brandProfileId
    }: {
        scenarios: PatchScenariosType
        brandProfileId: number
    }) => {
        const url = `/brand-profile/${brandProfileId}/scenarios/v2`
        const { data } = await userAccountAxios.patch(url, scenarios)
        return data
    },
    aiGenerateBrandProfileScenarios : async (data: {
        scenarioIds: number[],
        companyName: string,
        companyInfoSummary: string,
        companyMentalitySummary: string,
        brandProfileId: number
    }) => {
        const {brandProfileId, ...payload} = data
        const url = `/brand-profile/${brandProfileId}/scenarios/v2/ai-generator`
        await userAccountAxios.patch(url, payload)
    }
}
