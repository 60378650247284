import React from 'react'
import './hashtags.css'
import { Divider } from 'rsuite'
import { IHashtag, IHashtagsStatus } from '@/views/TikTok/Hashtags/types'
import { BoltIcon as BoltIconOutline } from '@heroicons/react/24/outline'
import { BoltIcon as BoltIconSolid } from '@heroicons/react/24/solid'
import Tooltip from "@/components/TailwindTooltip"

interface IProps {
    tiktokHashtag: IHashtag
    status: IHashtagsStatus | undefined
    isChecked: boolean
    handleCheckboxChange: (hashtagId: string) => void
    index: number
    onOpenInsights: (hashtag: IHashtag) => void
    dateRange: string
}

export const TikTokHashtagCard: React.FC<IProps> = ({ tiktokHashtag, status, isChecked, handleCheckboxChange, index, onOpenInsights, dateRange }) => {

    const formatNumber = (value: number) => {
        if (value < 1000) return value.toString()

        const suffixes = ['K', 'M', 'B', 'T']
        const suffixIndex = Math.floor(Math.log10(value) / 3)
        const shortValue = (value / Math.pow(1000, suffixIndex)).toFixed(1)

        return shortValue.replace(/\.0$/, '') + suffixes[suffixIndex - 1]
    }

    const getBadge = () => {
        const rankChange = tiktokHashtag.rankChange.toLowerCase()
        if (rankChange == 'new') {
            return <span className="px-3 py-1 text-xs font-bold text-blue-500 bg-blue-100 rounded-full">● New</span>
        } else {
            const numRankChange = Number(tiktokHashtag.rankChange)
            if (numRankChange > 0) {
                return <span className="px-3 py-1 text-xs font-bold text-green-500 bg-green-100 rounded-full">↑ {numRankChange}</span>
            } else if (numRankChange < 0) {
                return <span className="px-3 py-1 text-xs font-bold text-red-500 bg-red-100 rounded-full">↓ {Math.abs(numRankChange)}</span>
            } else {
                return null
            }
        }
    }

    const getStatus = () => {
        const hashtagStatus = status?.status.toLowerCase();
        if (hashtagStatus == 'active') {
            return <span className="px-3 py-1 text-xs font-semibold text-green-500 bg-green-100 rounded-full">Hashtag activated</span>
        } else if (hashtagStatus == 'pending') {
            return <span className="px-3 py-1 text-xs font-semibold text-yellow-500 bg-yellow-100 rounded-full">Activation pending</span>
        } else {
            return null
        }
    }

    return (
        <div data-testid="tiktok-hashtags-card" className={`flex flex-col justify-between p-4 border mr-[30px] rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 w-72 aspect-square ${isChecked ? 'border-[#7B61FF] border-2' : ''}`}>
            {tiktokHashtag.status.toLowerCase() == 'online' && (
                <input
                    type="checkbox"
                    data-testid="tiktok-hashtags-card-checkbox"
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(tiktokHashtag.hashtagId)}
                    className="w-4 h-4 border-gray-300 rounded form-checkbox text-sightly-blue focus:ring-sightly-blue disabled:opacity-40"
                />
            )}
            <div data-testid="tiktok-hashtags-card-badges" className='flex items-baseline justify-between my-3'>
                <div data-testid="tiktok-hashtags-card-status-badge">
                    {getStatus()}
                </div>
                <div data-testid="tiktok-hashtags-card-activation-badge">
                    {getBadge()}
                </div>
            </div>

            <div data-testid="tiktok-hashtags-card-title" className="flex m-2 text-xl font-bold text-gray-800 justify-center">
                <Tooltip data-testid="tiktok-hashtags-card-tooltip-name" content={`#${tiktokHashtag.hashtagName}`}>
                    <span data-testid="tiktok-hashtags-card-name" className='truncate'>#{tiktokHashtag.hashtagName}</span>
                </Tooltip>
                {tiktokHashtag.status.toLowerCase() === 'online' && (
                    <Tooltip data-testid="tiktok-hashtags-card-tooltip-target" content={<div>Available for targeting</div>}>
                        <BoltIconSolid data-testid="tiktok-hashtags-card-target" className='h-6 ml-2 text-violet-600' />
                    </Tooltip>
                )}
                {tiktokHashtag.status.toLowerCase() === 'offline' && (
                    <Tooltip data-testid="tiktok-hashtags-card-tooltip-notarget" content={<div>Unavailable for targeting</div>}>
                        <BoltIconOutline data-testid="tiktok-hashtags-card-notarget" className='h-6 ml-2 text-neutral-400' />
                    </Tooltip>
                )}
            </div>

            <Divider style={{ margin: '12px 0px' }} />

            <div data-testid="tiktok-hashtags-card-data" className="flex justify-between pt-2 pb-2 mb-2">
                <div data-testid="tiktok-hashtags-card-posts" className='text-center ml-3'>
                    <p data-testid="tiktok-hashtags-card-posts-title" className="text-xs">Posts</p>
                    <p data-testid="tiktok-hashtags-card-posts-count" className="text-lg font-extrabold text-black">{formatNumber(tiktokHashtag.posts)}</p>
                    <p data-testid="tiktok-hashtags-card-posts-date" className="text-xs">{dateRange}</p>
                </div>
                <div data-testid="tiktok-hashtags-card-views" className="text-center mr-3">
                    <p data-testid="tiktok-hashtags-card-views-title" className="text-xs">Views</p>
                    <p data-testid="tiktok-hashtags-card-views-count" className="text-lg font-extrabold text-black">{formatNumber(tiktokHashtag.views)}</p>
                    <p data-testid="tiktok-hashtags-card-views-date" className="text-xs">{dateRange}</p>
                </div>
            </div>

            <Divider style={{ margin: '12px 0px' }} />

            <a href="#" data-testid="tiktok-hashtags-card-insight" className="text-sm font-bold text-sightly-blue" onClick={(e) => { e.preventDefault(); onOpenInsights(tiktokHashtag) }}>Insights →</a>
        </div>
    )
}