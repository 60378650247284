import React from 'react'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const HeaderGroup = ({
  selectedActivationIds,
  activationsList,
  allMomentsOnPageAreSelected,
  handleSelectAll,
  itemsSelectable = true,
  expandedInsertionOrder,
  handleExpandAll,
  ioIdsSize,
}: {
  selectedActivationIds: number[];
  activationsList: Array<ActivationItemType>;
  allMomentsOnPageAreSelected: boolean;
  handleSelectAll: (checked: boolean) => void;
  itemsSelectable?: boolean;
  expandedInsertionOrder?: Set<number> | null;
  handleExpandAll?: () => void;
  ioIdsSize?: number;
}) => {
  const getSelectedActivationsState = () => {
    return {
      selectAll: activationsList?.length === selectedActivationIds?.length,
      indeterminate:
        selectedActivationIds &&
        selectedActivationIds.length > 0 &&
        !(activationsList?.length === selectedActivationIds?.length),
      empty: selectedActivationIds.length === 0
    }
  }

  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="relative w-16"
        >
      <div className="flex gap-1 pl-2">
      <div
        className="cursor-pointer"
        data-testid="todo-activation-io-group-row-expand-btn"
        id="todo-activation-io-group-row-expand-btn"
        onClick={() => handleExpandAll && handleExpandAll()}
          >
            { expandedInsertionOrder && ioIdsSize && expandedInsertionOrder?.size === ioIdsSize ? (
              <ChevronDownIcon
                height={25}
                width={25}
              />
            ) : (
              <ChevronRightIcon
                height={25}
                width={25}
              />
            )}
      </div>
          { itemsSelectable && ( <SightlyCheckbox
            dataTestId="activations-todo-select-all-checkbox"
            indeterminate={getSelectedActivationsState().indeterminate}
            handleChange={(checked: boolean) => handleSelectAll(checked)}
            id="selectAllTodo"
            checked={getSelectedActivationsState().selectAll}
          /> )}
        </div>
        </th>
      <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Insertion Order
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600 w-28"
        >
          Date Added
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Personas
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600  w-28"
        >
          Assignees
        </th>

        <th
          scope="col"
          className="relative w-12 py-3 pl-3 pr-4 sm:pr-6"
        >
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
  )
}

export default HeaderGroup
