import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { OpinionTable } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/SlideOver/OpinionTable';
import { OpinionsThemeType } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/opinions-types';
import { ReactComponent as GarmFlagIcon } from '@/assets/img/GarmFlagIcon.svg';

interface IProps {
  open: boolean
  onClose: () => void
  opinionTheme: OpinionsThemeType | null
  sortBy: 'least' | 'most'
}

export const SlideOver = ({ open, onClose, opinionTheme, sortBy }: IProps) => {

  return (
    <Transition.Root
      show={open}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onClose}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-4xl pointer-events-auto">
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title
                            data-testid="opinion-dialog-title"
                            className="text-base font-bold text-sightlySlate"
                          >
                            {opinionTheme?.opinionTypeName === 'Sensitive Topics' &&
                              <GarmFlagIcon className="inline mr-2" />
                            }
                            {opinionTheme?.opinionTypeName}
                          </Dialog.Title>
                          <div
                            data-testid="opinion-dialog-description"
                            className="text-sm font-normal text-sightlySlate"
                          >
                            {opinionTheme?.descriptionText}
                          </div>
                        </div>

                        <div className="flex items-center ml-3 h-7">
                          <button
                            data-testid="opinion-dialog-close-button"
                            type="button"
                            className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon
                              className="w-6 h-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 mt-6">
                      <OpinionTable
                        opinionTheme={opinionTheme}
                        sortBy={sortBy}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
