import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import React from 'react'

function MessageToAssignees(props:{
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<string>>
}) {
  const {message,setMessage} = props
  return (
    <div className="px-4 pt-6 sm:px-6 text-grey-900" data-testid="act-mgr-target-moments-table-messages" id='pendo-act-mgr-assignees-messages'>
              <div className="mb-2 font-medium">Message</div>
              <SightlyInput
                id="message"
                textarea={true}
                onChange={(val: string) => setMessage(val)}
                value={message}
              />
            </div>
  )
}

export default MessageToAssignees