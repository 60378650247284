import React, { useState } from 'react'
interface CheckboxProps {
    id: string
    handleChange: (checked: boolean) => void
    label: string
}

const CheckBoxButton: React.FC<CheckboxProps> = ({ id, handleChange, label }) => {
    const [checked, setChecked] = useState(false)

    const handleInputChange = () => {
        const newChecked = !checked
        setChecked(newChecked)
        handleChange(newChecked)
    }

    return (
        <div className="relative flex  items-center space-x-2">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={handleInputChange}
                className="form-checkbox h-4 w-4 rounded border-gray-300 text-sightly-blue focus:ring-sightly-blue disabled:opacity-40"
            />
            <label
                htmlFor={id}
                style={{ wordBreak: 'break-all' }}
                className="text-sm font-medium text-gray-700 line-clamp-1"
            >
                {label}
            </label>
        </div>
    )
}

export default CheckBoxButton
