import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import config from '@/config'

export function SortBy({
    selected,
    onChange
}: {
    selected: 'most' | 'least'
    onChange: (val: 'most' | 'least') => void
}) {
    return (
        <div data-testid="sort-by-button">
            <Menu
                as="div"
                className="relative inline-block text-left"
            >
                <div>
                    {config.featureFlag.aiProfiles ? 
                    <Menu.Button className="inline-flex justify-between w-full px-4 py-2 text-sm font-semibold text-gray-700 outline-none hover:bg-opacity-30 ">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="5" width="16" height="3" fill="#395066"/>
                        <rect x="9" y="10" width="11" height="3" fill="#395066"/>
                        <rect x="13" y="15" width="7" height="3" fill="#395066"/>
                    </svg>

                        Sort By
                    </Menu.Button>
                    : <Menu.Button className="inline-flex justify-between w-full gap-4 px-4 py-2 text-sm font-medium text-white rounded-md bg-sightly-blue hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={'white'}
                            className="w-5 h-5 ml-2 -mr-1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                            />
                        </svg>
                        Sort By
                    </Menu.Button>}
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => onChange('least')}
                                        className={`${
                                            active ? 'bg-slate-100' : 'text-gray-900'
                                        } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                    >
                                        Least Completed
                                        {selected === 'least' && (
                                            <CheckIcon className="w-5 h-5 mr-2 text-sightly-blue" />
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => onChange('most')}
                                        className={`${
                                            active ? 'bg-slate-100' : 'text-gray-900'
                                        } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                    >
                                        Most Completed
                                        {selected === 'most' && (
                                            <CheckIcon className="w-5 h-5 mr-2 text-sightly-blue" />
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
