export const ScenarioResponseEnum = {
    noresponse: {
      id: 0,
      label: 'No Response'
    },
    target: {
        id: 3,
        label: 'Target'
    },
    block: {
        id: 1,
        label: 'Block'
    },
    monitor: {
        id: 2,
        label: 'Monitor'
    },
    dontmonitor: {
        id: 4,
        label: `Don't Monitor`
    }
}

export const getScenarioResponse = (scenarioResponseId: number) => {
    switch (scenarioResponseId) {
        case 0:
            return 'No Response'
        case 1:
            return 'Block'
        case 2:
            return 'Monitor'
        case 3:
            return 'Target'
        case 4:
            return `Don't Monitor`
        default:
            return 'Other'
    }
}
