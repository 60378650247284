import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import svgLogo from '@/assets/img/sightly-logo.svg'
import defaultLoginImage from '@/assets/img/introSideImage.png'
import tiktokLoginImage from '@/assets/img/tiktoklogin.png'
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { routes } from '@/routes';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { api } from '@/api/api';
import {isIframe} from '@/validations';

const SightlyLogo = styled.img`
    height: 2em;
`

const SignUpHeader = styled.h1`
    padding-top: 1em;
    text-align: center;
`

const StyledArrowLongLeftIcon = styled(ArrowLongLeftIcon)`
    height: 1.5em;
    padding-right: .5em;
`

const TextDiv = styled.div`
    padding-top: .25em;
    text-align: center;
`

const ClickToResendDiv= styled.div`
    padding-top: 2em;
    text-align: center;
`

const ClickToResendButton = styled.button`
    padding-left: .5em;
`

const BackToLoginSightlyModernButton = styled(SightlyModernButton)`
    margin-top: 2em;
    width: 100%;
`

export const CheckYourEmail = () => {
    const navigate = useNavigate()
    const {
        params: { email }
    } = useMatch()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const openLoginPage = () => {
        navigate({ to: routes.login.path })
    }
    const isInTikTokIframe = isIframe();

    const mutation = useMutation(api.user.resetPassword, {
        onSuccess: () => {
            toast.success('Reset password email sent. Check Your email.')
        },
        onError: (err: AxiosError<any>) => {
            if (err.response && 'Error' in err.response.data && err.response.data.Error === 'No user found with that email address.') {
                toast.error(err.response.data.Error)
                return
            }

            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit: MouseEventHandler = (event) => {
        event.preventDefault()
        mutation.mutate({ email: email })
    };

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            <SightlyLogo src={svgLogo} alt='logo' />
            <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Check your email</SignUpHeader>
            <TextDiv>
                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    We've sent a password reset link to {email}
                </p>
            </TextDiv>
            <ClickToResendDiv>
                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    Didn’t receive the email?<ClickToResendButton className='text-sightlyBlue' onClick={onSubmit}>Click to resend</ClickToResendButton>
                </p>
            </ClickToResendDiv>
            <BackToLoginSightlyModernButton
                id='loginButton'
                type='secondaryblack'
                preventDefaultOnClick
                icon={<StyledArrowLongLeftIcon />}
                handleClick={openLoginPage}
                text='Back to Login'
            />

            <WarningModalTailwind
                open={showGenericErrorDialog}
                setOpen={setShowGenericErrorDialog}
                title='Something went wrong'
                subtitle='Please try again, or contact support at support@sightly.com'
            />
        </IntroLayout>
    );
};
