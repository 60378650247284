import { useEffect } from 'react'

export const useBodyOverflowYAuto = () => {
    useEffect(() => {
        document.body.style.overflowY = 'auto'

        return () => {
            document.body.style.overflowY = 'hidden'
        }
    }, [])
}
