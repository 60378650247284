import React from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'

interface IProps {
    id: number | string
    error: string | undefined
}

export const SightlyModernFormError: React.FC<IProps> = (props: IProps) => {
    if (!props.error) {
        return null
    }

    return (
        <div id={`${props.id}-error`} data-testid='modern-error' className='flex flex-row mt-2'>
            <XMarkIcon className="w-7 text-red-600 text-sm" />
            <p className="text-sm text-red-600 flex items-center gap-2">{props.error}</p>
        </div>
    )
}
