import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { userAccountAxios } from '@/axiosInstances';
import { RolesAndPermissionsSchema } from '@/schemas/schemas';
import { rqKeys } from '@/ReactQueryKeyFactory';

const softDeleteRole = async (roleId: number) => {
    const url = `/v1/users/roles/${roleId}`

     return await userAccountAxios
        .delete(url)
        .then(response => {
            if (response.status === 304) {
                toast.success('Role archived')
            }
        })
        .catch(error => {
            if (error.response.status === 409) {
                toast.error('Users assigned to role')
            }
            console.error(error)
        })
}

const createRole = async (role: any) => {
    const url = `/v1/users/roles`

    return await userAccountAxios
        .post(url, role)
        .then(response => {
            if (response.status === 200) {
                toast.success('Role created')
            }
        })
        .catch(error => {
            if (error.response.status === 409) {
                toast.error('Role already exists')
            }
            console.error(error)
        })
}

const getRoles = async () => {
    const url = `/v1/users/roles?pageSize=100&orderBy=userType&sort=desc`
    const result = await userAccountAxios.get(url)

    const rolesAndPermissionsParsing = RolesAndPermissionsSchema.safeParse(result.data.results)

    if (!rolesAndPermissionsParsing.success) {
        console.log(rolesAndPermissionsParsing.error)
        console.error('Could not validate Roles data')
    }

    return result.data.results
}

const updateRole = async (role: any) => {
    const url = `/v1/users/roles/${role.id}`

    return await userAccountAxios
      .patch(url, role)
      .then(response => {
          if (response.status === 200) {
              toast.success('Role updated')
          }
      })
      .catch(error => {
          toast.error('Error updating role')
          console.error(error)
      })
}

export default function useRoles() {
    const QUERY_KEY = rqKeys.rolesKey()
    const queryClient = useQueryClient()

    const onSettled = () => queryClient.invalidateQueries(QUERY_KEY)

    return {
        ...useQuery(QUERY_KEY, getRoles),
        archiveRole: useMutation(softDeleteRole, { onSettled }).mutate,
        createRole: useMutation(createRole, { onSettled }).mutate,
        updateRole: useMutation(updateRole, { onSettled }).mutate,
    }
}
