import React from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import Icon from 'rsuite/lib/Icon'
import { IPlatform } from '@/views/TikTok/Campaigns/Campaigns'

type IProps = {
    platforms: IPlatform[]
    open: boolean
    handleClose: () => void
    handleSelectPlatform: (platform: IPlatform) => void
}

const ConnectedAccountsModal = ({ platforms, open, handleClose, handleSelectPlatform }: IProps) => {
    return (
        <PortalModal
            hasClose={true}
            handleClose={handleClose}
            open={open}
        >
            <div className='min-w-[30rem]'>
                <div className='mb-4 mr-8'>
                    <p data-testid='tiktok-connected-accounts-modal-label' className='font-bold'>
                        Connect your ad accounts and import campaigns
                    </p>
                    <p className='text-xs text-gray-500'>Import all of your campaigns from your ad accounts.</p>
                </div>

                <div data-testid='tiktok-connected-accounts-modal-platforms-container' className="flex flex-col gap-2">
                    {platforms.map((platform, index) => {
                        return (
                            <div key={index} data-testid={`tiktok-connected-accounts-modal-platform-${index}`} className="p-4 rounded-md border-[0.5px] border-gray-200 drop-shadow-lg flex flex-col divide-y gap-4">
                                <div className='flex flex-col gap-2'>
                                    <div className="font-semibold text-base flex items-center text-lg">
                                        <img
                                            src={platform.logo}
                                            className="aspect-square h-8 rounded-full mr-2"
                                            alt={platform.name}
                                        />
                                        <span data-testid={`tiktok-connected-accounts-modal-platform-${index}-name`}>{platform.name}</span>
                                    </div>
                                    <div className='flex items-center'>
                                        <Icon
                                            className='rounded-full border-2 border-green-500'
                                            icon='check-circle'
                                            style={{
                                                background: 'green',
                                                color: 'white',
                                                marginRight: 4
                                            }}
                                        />
                                        <span data-testid={`tiktok-connected-accounts-modal-platform-${index}-connected-accounts-count`}>{platform.connectedAccountsCount} accounts connected</span></div>
                                </div>
                                <div className='w-full flex justify-center pt-4'>
                                    <SightlyButton
                                        selectorClass="w-full"
                                        datatestid={`tiktok-connected-accounts-modal-platform-${index}-import`}
                                        handleClick={() => {handleSelectPlatform(platform)}}
                                        text={"Import from your accounts"}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </PortalModal>
    )
}

export default ConnectedAccountsModal
