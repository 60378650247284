import React,   { useState } from 'react'
import thumbsDownOutlineIcon from '@/assets/img/thumbs-down-regular.svg'
import thumbsDownSolidIcon  from '@/assets/img/thumbs-down-solid.svg'
import ThumbsUpSolidIcon from '@/assets/img/thumbs-up-solid.svg'
import ThumbsUpOutlineIcon  from '@/assets/img/thumbs-up-regular.svg'


type IProps = {
    positiveFeedBack: boolean | null,
    setPositiveFeedBack: Function, 
    negativeFeedBack: boolean | null,
    setNegativeFeedBack: Function,
    updateUserFeedback: Function
    disable?: boolean
}

export const ThumsActions = ( { positiveFeedBack, setPositiveFeedBack, negativeFeedBack, setNegativeFeedBack, updateUserFeedback, disable = true }: IProps ) => {
    
    const updateFeedback = (feedbackType: string) => {
        if (feedbackType == 'positive') {
            if (positiveFeedBack) {
                setPositiveFeedBack(null)
            } else {
                if(negativeFeedBack){
                    setNegativeFeedBack(null)
                    setPositiveFeedBack(true)
                } else {
                    setPositiveFeedBack(true)
                }
            }
        } else {
            if (negativeFeedBack) {
                setNegativeFeedBack(null)
            } else {
                if(positiveFeedBack){
                    setPositiveFeedBack(null)
                    setNegativeFeedBack(true)
                } else {
                    setNegativeFeedBack(true)
                }

            }
        }
        updateUserFeedback();
    }

    return (

        <div className='flex-row flex'>
            <button
                onClick={() => updateFeedback('positive')}
                disabled={disable}
                data-testid="positive-feedback-button"
            >{positiveFeedBack
                ? <img data-testid="positive-feedback-button-check" data-positive-thumb={positiveFeedBack} src={ThumbsUpSolidIcon} className='w-6 h-5 mr-4' /> : <img data-testid="positive-feedback-button-uncheck"  data-positive-thumb={positiveFeedBack}  src={ThumbsUpOutlineIcon} className='w-6 h-5 mr-4' />}

            </button>
            <button
                onClick={() => updateFeedback('negative')}
                disabled={disable}
                data-testid="negative-feedback-button"
            >{negativeFeedBack
                ? <img data-testid="negative-feedback-button-check" data-negative-thumb={negativeFeedBack} src={thumbsDownSolidIcon} className='w-6 h-5 mr-4' /> : <img data-testid="negative-feedback-button-uncheck" data-negative-thumb={negativeFeedBack} src={thumbsDownOutlineIcon} className='w-6 h-5 mr-4' />}

            </button>
        </div>
    )
}
