import React from 'react'
import Tabs from '@/components/Tabs/Tabs'
import Input from 'rsuite/lib/Input'
import useBoardCreator from './useBoardCreator'
import Footer from './Footer'
import { PATH_MOMENTS_V2, PATH_MOMENTS_V2_SETTINGS_REDESIGN, routes } from '@/routes'
import PillsGroup from './PillsGroup'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { location } from '@/RouteContainer'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import { Item, ItemTypes } from './types'
import CategoriesSelectionPane from './components/CategoriesSelectionPane'
import LoadingData from './components/LoadingData'
import { MyLocationGenerics } from '@/classes/utils'
import debounce from 'just-debounce-it';

const _tabs = ['Queries', 'News Verticals', 'Industry Verticals']

const isNoResultFound = (list: Item[]) => list.every(({ items }) => !items.length)

const NoResultFound = () => (
  <div className="flex items-center justify-center h-1/2">
    <p className="text-base text-center">No Results Found</p>
  </div>
)

const Custom = () => {
  const navigate = useNavigate()
  const { accountId } = useSearch<MyLocationGenerics>()
  const [currentAccountId, setCurrentAccountId] = React.useState(accountId)
  const [active, setActive] = React.useState<string>('0')
  const [didBlurName, setDidBlurName] = React.useState(false)
  const { currentBrandProfile } = useBrandProfiles({
    submittedOnly: true
  })

  const MAX_ALLOWED_CUSTOM_ITEMS = 10
  const [boardCaptions, setBoardCaptions] = React.useState({
    title: 'Custom Board',
    subTitle: 'Craft your board',
    description: `Create a personalized news board by selecting up to ${MAX_ALLOWED_CUSTOM_ITEMS} items. By default, your board will include news related to any of the selected topics.`,
    buttonLabel: 'Save Board'
  })
  const brandProfileId = currentBrandProfile?.brandProfileId

  const boardCreator = useBoardCreator({
    brandProfileId,
    boardType: 'custom'
  })


  React.useEffect(() => {
    if (boardCreator.boardId) {
      if (!boardCreator.board?.boardType) return
      if (boardCreator.board?.boardType === 'custom') {
        setBoardCaptions({
          title: 'Edit Board',
          subTitle: 'Craft your board',
          description: 'Edit your personalized news board by selecting or deselecting additional items.',
          buttonLabel: 'Save Board'
        })
        return
      }
      // Rerouting to dashboard if the boardId is not "custom" i.e. uneditable
      navigate({
        to: PATH_MOMENTS_V2_SETTINGS_REDESIGN,
        search(prev: any) {
          const newPrev = prev;
          newPrev.boardsView = 'Manage Boards';
          return {
            ...newPrev
          }
        }
      })
    }
  }, [boardCreator.board?.boardType])

  React.useEffect(() => {
    if (currentBrandProfile) {
      navigate({
        search: (search) => ({
          ...search,
          brandProfileId: currentBrandProfile?.brandProfileId
        })
      })
    }
  }, [currentBrandProfile, location.current.search])

  React.useEffect(() => {
    if (accountId != currentAccountId) {
      navigate({
        to: PATH_MOMENTS_V2,
        search: (old) => {
          delete old?.boardId
          return { ...old }
        }
      })
    }
  }, [accountId])
  // onUnmount
  React.useEffect(() => {
    return () => {
      navigate({
        search: (old) => {
          delete old?.boardId
          return { ...old }
        }
      })
    }
  }, [])

  const handleSearch = debounce((val: string) => {
    boardCreator.setSearchTerm(val)
  }, 500)

  if (boardCreator.boardId && !boardCreator.board) {
    return <LoadingData message="Loading..." />
  }

  return (
    <div
      data-testid="page-custom-board"
      className="gridContainer"
    >
      <main
        data-testid="custom-board-main"
        className="mx-6 divide-x main"
      >
        <section
          data-testid="custom-board-section-form"
          className="w-full max-h-screen p-5 overflow-y-auto"
        >
          <div
            data-testid="custom-board-title"
            className="title"
          >
            {boardCaptions.title}
          </div>
          <div
            data-testid="custom-board-name"
            className="name"
          >
            <SightlyInput
              autoFocus
              id="name"
              onChange={(val: string) => boardCreator.setName(val)}
              value={boardCreator.name}
              placeholder="Enter a name for this board here"
              defaultValue={''}
              onBlur={() => setDidBlurName(true)}
              error={didBlurName && boardCreator.name === '' ? 'You must enter a board name.' : ''}
            />
          </div>
          <div
            data-testid="custom-board-subtitle"
            className="subtitle"
          >
            {boardCaptions.subTitle}
          </div>
          <div
            data-testid="custom-board-description"
            className="description"
          >
            {boardCaptions.description}
          </div>
          <div className="flex items-center gap-4 p-1 rounded bg-blue-50">
            <div
              data-testid="custom-board-toggle-isOr"
              className="flex items-center gap-8"
            >
              <SightlyToggle
                id="operator"
                switchClass="pendo-operator-switch"
                setEnabled={() => boardCreator.setIsOr(!boardCreator.isOr)}
                enabled={!boardCreator.isOr}
              />
            </div>
            <div className="flex flex-col">
              <strong>And Logic</strong>
              <p>
                Turn on this toggle to refine your board’s content. If selected, your board will only display moments
                where these topics intersect (i.e. Sports AND Politics).
              </p>
            </div>
          </div>

          <Tabs
            active={active}
            onChange={(active: string) => {
              setActive(active)
            }}
          >
            {_tabs.map((_tab, index) => {
              return (
                <div
                  data-testid={'custom-board-tab-' + _tab}
                  key={index}
                >
                  {_tab}
                </div>
              )
            })}
          </Tabs>

          <InputGroup
            data-testid="custom-board-search"
            inside
            disabled={
              boardCreator.areQueriesLoading &&
              boardCreator.areAylienNewsLoading &&
              boardCreator.areAylienIndustriesLoading
            }
            className="max-w-xl mt-4 mb-6"
          >
            <InputGroup.Button>
              <Icon
                icon="search"
                className="text-slate-500"
              />
            </InputGroup.Button>
            <Input
              className="rounded"
              id="search"
              onChange={handleSearch}
              placeholder="Search"
            />
          </InputGroup>

          {active === '0' &&
            (boardCreator.areQueriesLoading ? (
              <LoadingData message="Loading..." />
            ) : isNoResultFound(boardCreator.queries) ? (
              <NoResultFound />
            ) : (
              <PillsGroup
                boardType="custom"
                MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                data={boardCreator.queries}
                targetedCount={boardCreator.queriesCount}
                handleSelectItem={boardCreator.handleSelectItem}
                selectedCount={boardCreator.selectedCount}
                itemType={ItemTypes.queries}
                searchTerm={boardCreator.searchTerm}
                WarningMessage={
                  <div className="mb-6">
                    Please{' '}
                    <a href={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/brands`}>
                      return to your Brand Profile Queries section
                    </a>{' '}
                    and add some Queries in order to receive topic recommendations.{' '}
                  </div>
                }
              />
            ))}

          {active === '1' &&
            (boardCreator.areAylienNewsLoading ? (
              <LoadingData message="Loading..." />
            ) : isNoResultFound(boardCreator.aylienNews) ? (
              <NoResultFound />
            ) : (
              <PillsGroup
                boardType="custom"
                MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                data={boardCreator.aylienNews}
                targetedCount={boardCreator.newsTargetedCount}
                handleSelectItem={boardCreator.handleSelectItem}
                selectedCount={boardCreator.selectedCount}
                itemType={ItemTypes.aylienNews}
                searchTerm={boardCreator.searchTerm}
                WarningMessage={
                  <div className="mb-6">
                    Please{' '}
                    <a href={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/opinions`}>
                      return to your Brand Profile Opinions section
                    </a>{' '}
                    and designate some opinions which your Brand agrees with in order to receive topic recommendations.{' '}
                  </div>
                }
              />
            ))}

          {active === '2' &&
            (boardCreator.areAylienIndustriesLoading ? (
              <LoadingData message="Loading..." />
            ) : isNoResultFound(boardCreator.aylienIndustries) ? (
              <NoResultFound />
            ) : (
              <PillsGroup
                boardType="custom"
                MAX_ALLOWED_CUSTOM_ITEMS={MAX_ALLOWED_CUSTOM_ITEMS}
                data={boardCreator.aylienIndustries}
                targetedCount={boardCreator.industriesTargetedCount}
                handleSelectItem={boardCreator.handleSelectItem}
                selectedCount={boardCreator.selectedCount}
                itemType={ItemTypes.aylienIndustries}
                searchTerm={boardCreator.searchTerm}
                WarningMessage={
                  <div>
                    Please{' '}
                    <a href={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/opinions`}>
                      return to your Brand Profile Opinions section
                    </a>{' '}
                    and designate some opinions which your Brand agrees with in order to receive topic recommendations.{' '}
                  </div>
                }
              />
            ))}
        </section>
        <CategoriesSelectionPane
          handleSelectItem={boardCreator.handleSelectItem}
          selectedAylienIndustries={boardCreator.selectedAylienIndustries}
          selectedAylienNews={boardCreator.selectedAylienNews}
          selectedQueries={boardCreator.selectedQueries}
          isLoading={
            !!boardCreator.boardId &&
            (boardCreator.areQueriesLoading ||
              boardCreator.areAylienNewsLoading ||
              boardCreator.areAylienIndustriesLoading)
          }
        />
      </main>
      <Footer
        isCreating={boardCreator.isCreating}
        handleCreate={() => (boardCreator.boardId ? boardCreator.saveModifiedBoard() : boardCreator.createBoard())}
        disabled={
          boardCreator.selectedCount < 1 ||
          boardCreator.name === '' ||
          boardCreator.areQueriesLoading ||
          boardCreator.areAylienNewsLoading ||
          boardCreator.areAylienIndustriesLoading
        }
        buttonLabel={boardCaptions.buttonLabel}
      />

      <style>{`
              .gridContainer {
                display: grid;
                height: 100%;	
                grid-template-columns: 1fr;	
                grid-template-rows:  1fr 64px;
                grid-template-areas:
                  "main"
                  "footer"		
              }		

              .main {
                grid-area: main;
                overflow-y: auto;
                justify-content: center;
                display: flex; 
              
              }  
              
              .title {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
                color: #333D47;
                margin-bottom: 18px;
              }
              .name {
                width: 559px;
              }
              .subtitle {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin-top: 26px;
                margin-bottom: 16px;
              }
              .description {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: black;
                max-width: 790px;
                margin-bottom: 16px;
              }
  
  `}</style>
    </div>
  )
}

export default Custom
