import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum KEYWORD_DIMENSION {
  DEVICE = 'device',
  KEYWORD = 'keyword',
}

interface KeywordDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | KEYWORD_DIMENSION
}

export interface KeywordReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | KEYWORD_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | KeywordDimensionOption)[]
}

export const dimensionOptions: Option<KeywordDimensionOption> = {
  [KEYWORD_DIMENSION.DEVICE]: {
    name: 'Device',
    value: KEYWORD_DIMENSION.DEVICE
  },
  [KEYWORD_DIMENSION.KEYWORD]: {
    name: 'Keyword',
    value: KEYWORD_DIMENSION.KEYWORD
  },
}

export const baseKeywordReport: KeywordReport = {
  name: 'Keyword Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.KEYWORD,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const personaKeywordReportTemplate: KeywordReport = {
  ...baseKeywordReport,
  name: 'Persona Keyword Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.PERSONA,
    KEYWORD_DIMENSION.KEYWORD,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
