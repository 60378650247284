import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { userAccountAxios } from '@/axiosInstances';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { logError } from '@/utils';

const EmbedUrlsSchema = z.object({
    metabaseBrandMentalityEmbedUrl: z.string(),
    metabaseBrandProfileInsightsEmbedUrl: z.string(),
    metabaseMomentIntelligenceV2EmbedUrl: z.string(),
    metabaseSocialDataEmbedUrl: z.string()
})

export const fetchEmbedUrlsService = async (accountId: number | undefined) => {
    if (accountId === undefined) return
    const url = `/account/${accountId}/get-embed-urls`
    const result = await userAccountAxios.get(url)
    return EmbedUrlsSchema.parse(result.data)
}

export default function useEmbedUrls(accountId: number | undefined) {
    return useQuery(rqKeys.embedUrlsKey(accountId), () => fetchEmbedUrlsService(accountId), {
        enabled: !!accountId,
        onError: (err) => {
            logError(err)
        },
        staleTime: 500,
        // Do not re-query on page refocus
        // https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
    })
}
