import React from 'react'

export const SmallBadge = ({ text, onClose, disabled, dataTestId, bgColor = "bg-slate-200", border = "" }: { text: string; onClose: () => void; disabled?: boolean, dataTestId?:string, bgColor?:string, border?:string }) => {
    if (disabled) {
        return (
            <span data-testid={dataTestId ?? 'small-badge'} className={`inline-flex items-center rounded-full ${bgColor} ${border} py-0.5  px-2 text-xs font-medium`}>
                {text}
            </span>
        )
    }
    return (
        <span data-testid={dataTestId ?? 'small-badge'} className={`inline-flex items-center rounded-full  ${bgColor} ${border} py-0.5 pl-2 pr-0.5 text-xs font-medium text-indigo-700`}>
            {text}

            <button
                onClick={onClose}
                type="button"
                className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
            >
                <span className="sr-only">Remove small option</span>
                <svg
                    className="w-2 h-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                >
                    <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                    />
                </svg>
            </button>
        </span>
    )
}
