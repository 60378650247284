import { z } from 'zod'
import { listBuilderAxios, userAccountAxios } from '../../../axiosInstances'
import {
    ActivationAdGroupsResultSchema,
    ActivationItemsSchema,
    GetActivationsPropsType,
    PatchActivationsItemType
} from './activations-types'
var fileDownload = require('js-file-download')

const StatusCountsSchema = z.object({
    activeCount: z.number(),
    pendingCount: z.number(),
    completeCount: z.number()
})

interface DownloadActivationProps {
    brandProfileId: number
    clusterIds: number[]
    activationState: string
}

export const activationsApi = {
    getStatusCounts: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-status-count`
        const res = await listBuilderAxios.get(url)
        return StatusCountsSchema.parse(res.data)
    },
    getActivations: async ({ brandProfileId, page, size, status }: GetActivationsPropsType) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
        const res = await listBuilderAxios.post(url, {
            page,
            size,
            status
        })
        return ActivationItemsSchema.parse(res.data)
    },
    getTodoActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
        const res = await listBuilderAxios.post(url, {
            page: 1,
            size: 1000000,
            status: 'active'
        })
        return ActivationItemsSchema.parse(res.data)
    },
    getInProcessActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
        const res = await listBuilderAxios.post(url, {
            page: 1,
            size: 1000000,
            status: 'pending'
        })
        return ActivationItemsSchema.parse(res.data)
    },
    getImplementedActivations: async ({ brandProfileId }: { brandProfileId: number | undefined }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moments`
        const res = await listBuilderAxios.post(url, {
            page: 1,
            size: 1000000,
            status: 'complete'
        })
        return ActivationItemsSchema.parse(res.data)
    },
    getAdgroupsOptions: async ({ ioIds, brandProfileId }: { ioIds: Array<string>; brandProfileId: number | undefined }) => {
        if (ioIds.length <=0 || !brandProfileId) return undefined
        const ioIdsParam = ioIds.map(curr => `ioIds[]=${curr}`).join('&')
        const url = `/account/hierarchy?${ioIdsParam}`
        const res = await userAccountAxios.get(url)
        return ActivationAdGroupsResultSchema.parse(res.data)
    },
    patchActivations: async ({
        brandProfileId,
        activations
    }: {
        brandProfileId: number | undefined
        activations: PatchActivationsItemType[]
    }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list`
        const res = await listBuilderAxios.patch(url, activations)
        return res
    },
    changeStatusToPending: async ({
        brandProfileId,
        activations
    }: {
        brandProfileId: number | undefined
        activations: number[]
    }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/update-status-pending`
        const res = await listBuilderAxios.patch(url, { targetListIds: activations })
        return res
    },
    removeItemFromTodo: async ({
        brandProfileId,
        activationListItemId
    }: {
        brandProfileId: number | undefined
        activationListItemId: number
    }) => {
        if (!brandProfileId) return undefined
        const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/${activationListItemId}`
        const res = await listBuilderAxios.delete(url)
        return res.data
    },
    downloadActivations: async ({ brandProfileId, clusterIds, activationState }: DownloadActivationProps) => {
        const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-download`
        let data = { clusterIds, activationState }
        const result = await listBuilderAxios({
            method: 'POST',
            url,
            responseType: 'blob',
            data
        })
        if (!result) {
            return Promise.reject('Unknown download error')
        }
        fileDownload(result.data, `CurrentMomentsActivations.xlsx`)
    },
}
