import React from 'react'
import _ from 'lodash'
import BulkAssignModal from '@/views/Discover/Moments/v2/TargetMoment/BulkAssignModal'
import {
  ActivationAdGroupsResultType,
  ActivationCampaignAdGroupSchemaType,
  ActivationCampaignsSchemaType
} from '@/views/Discover/Activations/v2/activations-types'
import { BULK_ASSIGN_TITLE } from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import CheckTreeDataPicker from '@/components/CheckTreeDataPicker'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'
import { useInsertionOrderHierarchy } from '@/views/Discover/Activations/v2/hooks/activations-hooks'
import { AdGroupsType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/Discover/Activations/v2/ActivationsManager/reducers/activationManager.reducer'
import {
  SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS,
  selectedCampaignsToAdGroupsReducer
} from '@/views/Discover/Activations/v2/ActivationsManager/reducers/selectedCampaignsToAdGroups.reducer'
import { Loader } from 'rsuite'
import { useFlag } from '@unleash/proxy-client-react'

type BulkAssignCampaignAdGroupsProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  ioIds: string[]
  selectedActivations: Map<string, Set<number>>
  resetSelectedActivations: ()=>void
  setIsBulkAssignment: React.Dispatch<React.SetStateAction<boolean>>
}

const BulkAssignCampaignAdGroups = ({
  show,
  setShow,
  ioIds,
  selectedActivations,
  resetSelectedActivations,
  setIsBulkAssignment
}: BulkAssignCampaignAdGroupsProps) => {
  const [campaignSearchKeyword, setCampaignSearchKeyword] = React.useState<string>()
  const [adGroupSearchKeyword, setAdGroupSearchKeyword] = React.useState<string>()
  const keyRef = React.useRef(0)
  const targetedActivationList = useTargetedActivationList()
  const [selectedCampaignsToAdGroups, dispatchSelectedCampaignsToAdGroups] = React.useReducer(
    selectedCampaignsToAdGroupsReducer,
    new SerializableMap<number, AdGroupsType>()
  )

  const isNewActivationManagerEnabled = useFlag(`enable_activation_campaign_expand`)

  const adGroupCampaignsQuery = useInsertionOrderHierarchy({ ioIds })

  const [campaignOptionsPrimary, adGroupOptionsPrimary] = React.useMemo(
    () => getCampaignAdGroupOptions(adGroupCampaignsQuery.data),
    [adGroupCampaignsQuery.data]
  )
  const disabledCampaignOptions = React.useMemo(() => {
    const disabledValues = new Set()
    campaignOptionsPrimary?.forEach((mcc) => {
      disabledValues.add(mcc.id)
    })
    return Array.from(disabledValues)
  }, [campaignOptionsPrimary])

  const adGroupOptions = React.useMemo(() => {
    const defaultOptions: Array<ActivationCampaignAdGroupSchemaType> = []
    Array.from(selectedCampaignsToAdGroups.keys()).forEach((campaignId) => {
      const campaignAdGroupOption = adGroupOptionsPrimary?.get(campaignId)
      campaignAdGroupOption && defaultOptions.push(campaignAdGroupOption)
    })
    if (!adGroupSearchKeyword) return defaultOptions ?? []
    const filteredAdGroupOptions = _.cloneDeep(defaultOptions)
      .map((option) => {
        const { children } = option
        option.children = children?.filter(({ name }) =>
          name.toLowerCase().includes(adGroupSearchKeyword.toLowerCase())
        )
        return option
      })
      ?.filter((option) => option.children && option.children?.length > 0)
    return filteredAdGroupOptions ?? []
  }, [selectedCampaignsToAdGroups.keys(), adGroupSearchKeyword])

  const handleCampaignPickerChange = (activeNode: any, value: any) => {
    dispatchSelectedCampaignsToAdGroups({
      type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_CAMPAIGN,
      payload: {
        activeNode,
        value
      }
    })
  }

  const handleCampaignSelectAll = (selectAll: boolean) => {
    dispatchSelectedCampaignsToAdGroups({
      type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_CAMPAIGNS,
      payload: {
        selectAll,
        campaignOptions
      }
    })
  }

  const handleAdGroupPickerChange = (activeNode: any, value: any, type?: string) => {
    if (!type) return
    dispatchSelectedCampaignsToAdGroups({
      type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ADGROUP,
      payload: {
        activeNode,
        value,
        type
      }
    })
  }

  const handleAdGroupSelectAll = (selectAll: boolean, type?: string) => {
    if (!type) return
    dispatchSelectedCampaignsToAdGroups({
      type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_ADGROUPS,
      payload: {
        adGroupOptions,
        selectAll,
        type
      }
    })
  }

  const handleApply = () => {
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS,
      payload: {
        selectedActivations,
        selectedCampaignsToAdGroups
      }
    })
    setIsBulkAssignment(() => true)
    reset()
  }

  const reset = () => {
    setShow(false)
    setCampaignSearchKeyword(() => undefined)
    setAdGroupSearchKeyword(() => undefined)
    resetSelectedActivations()
    dispatchSelectedCampaignsToAdGroups({
      type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.RESET
    })
  }

  const campaignOptions = React.useMemo(() => {
    if (!campaignSearchKeyword) return campaignOptionsPrimary ?? []

    const nextOptions = _.cloneDeep(campaignOptionsPrimary)
    const campaignOptions = nextOptions
      ? nextOptions.map((mcc) => {
          mcc.children = mcc.children.map((cid) => {
            cid.children = cid.children.filter(({ name }) =>
              name.toLowerCase().includes(campaignSearchKeyword.toLowerCase())
            )
            return cid
          })
          return mcc
        })
      : []
    return campaignOptions.filter((mcc) => mcc.children.filter((cid) => cid.children.length).length)
  }, [campaignSearchKeyword, campaignOptionsPrimary])

  const campaignsCount = React.useMemo(() => {
    const rowCounter = campaignOptions.reduce((rowSum, option) => {
      option.children.forEach((cid) => {
        rowSum += cid.children.length
      })
      return rowSum
    }, 0)
    return rowCounter
  }, [campaignOptions])

  const tabs = [
    { id: 'keyword', label: 'Keyword Ad Groups' },
    { id: 'video', label: 'Video Ad Groups' }
  ]

  const adGroupPickerValues = React.useMemo(() => {
    const keyword = Array.from(selectedCampaignsToAdGroups.values()).flatMap(({ keywordAdGroups }) =>
      Array.from(keywordAdGroups)
    )
    const video = Array.from(selectedCampaignsToAdGroups.values()).flatMap(({ videoAdGroups }) =>
      Array.from(videoAdGroups)
    )
    return { keyword, video }
  }, [selectedCampaignsToAdGroups])

  return (
    <BulkAssignModal
      dataTestId="act-mgr-target-moment-assign-adgroup-modal"
      size="lg"
      handleApply={handleApply}
      handleCancel={reset}
      show={show}
      header={<Header />}
    >
      {adGroupCampaignsQuery.isLoading ? <div className='flex items-center justify-center h-32 m-auto'> <Loader speed='normal'/> </div> :
      <div className="flex gap-1 -mx-4 bg-gray-50">
        <CheckTreeDataPicker
          title="Campaigns"
          key="CampaignsCheckTreeDataPicker"
          options={campaignOptions || []}
          labelKey="name"
          valueKey="id"
          value={Array.from(selectedCampaignsToAdGroups.keys())}
          handleSearch={(searchKey: string) => {
            setCampaignSearchKeyword(() => searchKey)
          }}
          handleSelectAll={handleCampaignSelectAll}
          uncheckableItemValues={disabledCampaignOptions}
          placeholder="No Result Found"
          rowCountValue={campaignsCount}
          handleSelectNode={handleCampaignPickerChange}
          suffixForCount={campaignOptions.length <= 1 ? 'Campaign' : 'Campaigns'}
          addCollapsetoHeader={isNewActivationManagerEnabled}
        />
        <CheckTreeDataPicker
          tabs={tabs}
          title="Ad Groups"
          key={`AdGroupCheckTreeDataPicker_v${keyRef.current}`}
          componentKey={`AdGroupCheckTreeDataPicker_v${keyRef.current}`}
          options={Array.from(adGroupOptions?.values() ?? [])}
          labelKey="name"
          valueKey="id"
          value={adGroupPickerValues}
          childrenKey="children"
          handleSelectAll={handleAdGroupSelectAll}
          handleSelectNode={handleAdGroupPickerChange}
          handleSearch={(searchKey: string) => {
            setAdGroupSearchKeyword(() => searchKey)
          }}
          placeholder={adGroupSearchKeyword ? 'No Results Found' : 'Please select campaigns for ad groups to appear'}
          suffixForCount={adGroupOptionsPrimary && adGroupOptionsPrimary.size <= 1 ? 'Ad Group' : 'Ad Groups'}
          addCollapsetoHeader={isNewActivationManagerEnabled}
        />
      </div>
      }
      <style>{`.rs-checkbox-disabled > .rs-checkbox-checker > label{
            color: unset;
          }`}</style>
    </BulkAssignModal>
  )
}

export default BulkAssignCampaignAdGroups

const Header = () => (
  <header
    data-testid="act-mgr-target-moment-modal-header"
    id="act-mgr-target-moment-modal-header"
    className="text-lg border-b-2 border-gray-300"
  >
    <p className="px-5 pb-5 font-medium text-gray-800">{BULK_ASSIGN_TITLE}</p>
  </header>
)

const getCampaignAdGroupOptions = (
  adGroupCampaignsQueryData: ActivationAdGroupsResultType | undefined
): [ActivationCampaignsSchemaType | undefined, Map<number, ActivationCampaignAdGroupSchemaType> | undefined] => {
  let campaignOptionsList: ActivationCampaignsSchemaType = [],
    adGroupOptionsList: Map<number, ActivationCampaignAdGroupSchemaType> = new Map()

  if (!adGroupCampaignsQueryData) return [undefined, undefined]

  campaignOptionsList = _.cloneDeep(adGroupCampaignsQueryData).map((mcc) => {
    const { id, name, searchName } = mcc
    const children = mcc.children.map((cid) => {
      const { id, name, searchName } = cid
      const children = cid.children.map((campaign) => {
        adGroupOptionsList.set(campaign.id, campaign)
        const { id, isCampaign, name, searchName } = campaign
        return { id, isCampaign, name, searchName }
      })
      return { id, name, searchName, children }
    })
    return { id, name, searchName, children }
  })
  return [campaignOptionsList, adGroupOptionsList]
}
