import React, { useEffect, useState } from 'react'
import MomentCard from '@/views/Discover/Moments/v2/MomentCard'
import { MomentSlideOver } from '@/views/Discover/Moments/v2/components/MomentSlideOver/MomentSlideOver'
import { useMoments } from '@/views/Discover/Moments/v2/useMoments'
import useBoards, { Board } from '@/views/Discover/Boards/useBoards'
import Sidebar from '@/views/Discover/Boards/Sidebar'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import AylienBoardPortalModal from '@/views/Discover/Boards/components/AylienBoardPortalModal';
import ActionMessages from '@/views/Discover/Boards/components/ActionMessages';
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useSearch } from '@tanstack/react-location'
import NoBrandProfiles from '@/components/NoBrandProfiles'
import ConfirmationModals from '@/views/Discover/Boards/components/ConfirmationModals';
import { MyLocationGenerics } from '@/classes/utils'
import { location } from '@/RouteContainer'
import { formatDateyyyyMMdd, logError } from '@/utils'
import useUser from '@/hooks/useUser'
import { LoadingPage } from '@/components/LoadingPage'
import { PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes'
import { ClusterActivationType, ClusterType, MomentJustificationOrigin, MomentSortBy } from '@/views/Discover/Moments/v2/types'
import { api, TargetListProps, TargetListPropsV2 } from '@/views/Discover/Moments/v2/api'
import { InfoPage } from '@/components/InfoPage'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import useBrandProfileInsertionOrders from '@/hooks/brandProfile/useBrandProfileInsertionOrders'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ActionsMenu } from '@/views/Discover/Moments/v2/ActionsMenu'
import { ArrowDownTrayIcon, NoSymbolIcon } from '@heroicons/react/20/solid'
import { SortBy } from '@/views/Discover/Moments/v2/SortBy'
import { BlockSlideOver } from '@/views/Discover/Moments/v2/BlockSlideOver'
import { BoltIcon } from '@heroicons/react/24/outline'
import { TargetSlideOver } from '../Moments/v2/TargetSlideOver'
import { isAxiosError } from '@/utils_ts'
import Tooltip from '@/components/TailwindTooltip'
import { useQueryClient } from '@tanstack/react-query'
import { activationsRqKeys } from '@/views/Discover/Activations/activationsRqKeys'
import { rqKeys as MomentViewRqKeys } from "@/views/Discover/Moments/v2/rqKeys";
import config from '@/config'
import { useFlag } from '@unleash/proxy-client-react'
import BoardTabs from '@/views/Discover/Boards/BoardTabs'
import Narratives from '@/views/Discover/Boards/NarrativesTab/Narratives'

import { useUnleashContext } from '@unleash/proxy-client-react';
import { TargetMoment } from '@/views/Discover/Moments/v2/TargetMoment/index'
import debounce from "just-debounce-it";
import { CheckTreePicker, CheckTreePickerProps } from 'rsuite';
import { SightlyInput } from "@/components/Sightly/SightlyFormElements/SightlyInput";
import { GENERAL_BOARD_DEFAULT_TYPES } from '@/views/BrandProfiles/constants'

import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg';
import clockSvg from '@/assets/img/AI/justification/clockIcon.svg';
import { JustificationSlidingOver } from '@/views/Discover/Boards/components/JustificationSlidingOver'
import { activationStatusOptions } from '@/views/Discover/Boards/types';
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection'
import { ranges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types'
import { parse, isAfter, isBefore, subDays, isValid, format } from 'date-fns';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'
import useSessionStorage from '@/hooks/useSessionStorage'
import { SelectAllMomentsPanel } from '@/components/SelectAllMomentsPanel';

const MAX_NUMBER_OF_MOMENTS_TO_TARGET = 50;

// Deprecate
const timeRangeOptions = [
    { id: 1, label: 'Last 24 hrs' },
    { id: 2, label: 'Last 7 days' },
    { id: 3, label: 'Last 30 days' },
    { id: 4, label: 'Last 60 days' },
    { id: 5, label: 'Custom Range' }
]

const actionOptions = [
    { id: 'Target', label: 'Target' },
    { id: 'Monitor', label: 'Monitor' },
    { id: 'Block', label: 'Block' },
    { id: 'No Action', label: 'No Action' }
]

const getLast24Hrs = (): [Date, Date] => {
    const now = new Date()
    const yesterday = new Date(now)
    yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
    return [yesterday, now]
}

const AylienBoard = () => {

    const updateContext = useUnleashContext();
    updateContext({ userId: localStorage.getItem('email')?.toString() });

    const { subscriptionQuota, perms, products, userPermissions, userProducts } = useUser()
    let quotaIsAllowed = false;
    if (subscriptionQuota.has(products.BOARDS)) {
        const quota = subscriptionQuota.get(products.BOARDS)
        quotaIsAllowed = quota.isAllowed
    }
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const boardsObj = useBoards()

    const [actions, setActions] = React.useState(['Target', 'Monitor', 'Block', 'No Action'])
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

    const [downloadingTrends, setDownloadingTrends] = React.useState(false)
    const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

    const [downloadingNarratives, setDownloadingNarratives] = React.useState(false)

    const [isViewingTargetModal, setIsViewingTargetModal] = React.useState(false)
    const [isViewingBlockModal, setIsViewingBlockModal] = React.useState(false)
    const [isViewingMoment, setIsViewingMoment] = React.useState(false)

    const [tryingToBlockWithTarget, setTryingToBlockWithTarget] = React.useState(false)
    const [tryingToBlockWithMonitor, setTryingToBlockWithMonitor] = React.useState(false)
    const [tryingToTargetWithBlock, setTryingToTargetWithBlock] = React.useState(false)
    const [tryingToTargetWithMonitor, setTryingToTargetWithMonitor] = React.useState(false)

    const [isTargeting, setIsTargeting] = React.useState(false)
    const [showTargetSuccess, setShowTargetSuccess] = React.useState(false)
    const [targetError, setTargetError] = React.useState<string>('')

    const [blockingPlacements, setBlockingPlacements] = React.useState<boolean>(false)
    const [showBlockPlacementsSuccess, setShowBlockPlacementsSuccess] = React.useState<boolean>(false)
    const [blockPlacementsError, setBlockPlacementsError] = React.useState<string>('')

    const [blockingKeywords, setBlockingKeywords] = React.useState<boolean>(false)
    const [blockKeywordsError, setBlockKeywordsError] = React.useState<string>('')
    const [showBlockKeywordsSuccess, setShowBlockKeywordsSuccess] = React.useState<boolean>(false)
    const { boardsQuotaTally } = useBoards();

    const enableActivationFilters = useFlag(`enable_activation_status_filter`)
    const isNewActivationManagerEnabled = useFlag(`enable_new_activation_mgr_${config.environment}`)

    const [activationStatus, setActivationStatus] = React.useState<string[]>(enableActivationFilters ? ['not_activated'] : [])

    const getLast24Hrs = (): [Date, Date] => {
        const now = new Date()
        const yesterday = new Date(now)
        yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
        return [yesterday, now]
    }

    const [boardsDateRange, setBoardsDateRange] = useSessionStorage<[Date, Date]>('boardsDateRange', getLast24Hrs());

    const startingRange = boardsDateRange.map((dateVal)=> new Date(dateVal))

    const DATE_FMT_YYYY_MM_DD = 'yyyy-MM-dd';
    const [startingDateString, setStartingDateString] = React.useState<string>(format(startingRange[0], DATE_FMT_YYYY_MM_DD));
    const [endingDateString, setEndingDateString] = React.useState<string>(format(startingRange[1], DATE_FMT_YYYY_MM_DD));

    const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0]);
    const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1]);

    React.useEffect(() => {
        const newStartDate = startingDateObj || (parse as any)(startingDateString, DATE_FMT_YYYY_MM_DD, new Date());
        const newEndDate = endingDateObj || (parse as any)(endingDateString, DATE_FMT_YYYY_MM_DD, new Date());

        if (isValid(newStartDate) && isValid(newEndDate)) {
            setBoardsDateRange([newStartDate, newEndDate]);
        }
    }, [startingDateString, endingDateString]);

    const [isDateOutOfBounds, setIsDateOutOfBounds] = React.useState<boolean>(false);

    const [noIOsLinkedToBrandProfile, setNoIOsLinkedToBrandProfile] = React.useState(false)

    const [selectedIndustryCategoryIds, setSelectedIndustryCategoryIds] = React.useState<number[]>([])
    const [selectedNewsCategoryIds, setSelectedNewsCategoryIds] = React.useState<number[]>([])

    const {
        data: brandProfiles,
        currentBrandProfile,
        isLoading: brandProfilesIsLoading
    } = useBrandProfiles({
        submittedOnly: true
    })
    const {
        brandProfileId,
        boardIds,
        startDate = startingDateString,
        endDate = endingDateString,
        sortBy = 'ranking',
        searchTerm = '',
        selectedMomentId
    } = useSearch<MyLocationGenerics>()

    const adjustOutOfBoundsDates = (startDate: string | undefined, endDate: string | undefined): { dateCheckStartDate: string, dateCheckEndDate: string } => {
        const now = new Date();
        const sixtyDaysAgo = subDays(now, 61);
        const yesterday = subDays(now, 1);

        const parsedStartDate = startDate ? parse(startDate) : new Date();
        const parsedEndDate = endDate ? parse(endDate) : new Date();

        let adjustedStartDate = parsedStartDate;
        let adjustedEndDate = parsedEndDate;

        if (isBefore(parsedStartDate, sixtyDaysAgo) || isAfter(parsedStartDate, now)) {
            adjustedStartDate = yesterday;
        }

        if (isAfter(parsedEndDate, now) || isBefore(parsedEndDate, sixtyDaysAgo)) {
            adjustedEndDate = now;
        }

        return {
            dateCheckStartDate: formatDateyyyyMMdd(adjustedStartDate),
            dateCheckEndDate: formatDateyyyyMMdd(adjustedEndDate)
        };
    };


    const { dateCheckStartDate: dateCheckedStartDate, dateCheckEndDate: dateCheckedEndDate } = adjustOutOfBoundsDates(startDate as string, endDate as string);

    useEffect(() => {
        const formattedStartDate = startDate ? (parse as any)(startDate, DATE_FMT_YYYY_MM_DD, new Date()) : new Date();
        const formattedEndDate = endDate ? (parse as any)(endDate, DATE_FMT_YYYY_MM_DD, new Date()) : new Date();
        (dateCheckedStartDate !== formatDateyyyyMMdd(formattedStartDate) || dateCheckedEndDate !== formatDateyyyyMMdd(formattedEndDate)) ? setIsDateOutOfBounds(true) : setIsDateOutOfBounds(false)
    }, [startDate, endDate]);

    useEffect(() => {
        // When the URL parameters change, assign the <Date> state variable that controls calendar display
        // Separate string state vars are defined as string for API
        const [startYear, startMonth, startDay] = startDate.split('-').map(Number);
        const [endYear, endMonth, endDay] = endDate.split('-').map(Number);

        setStartingDateObj(new Date(startYear, startMonth - 1, startDay));
        setEndingDateObj(new Date(endYear, endMonth - 1, endDay));
    }, [startDate, endDate]);

    const iosQuery = useBrandProfileInsertionOrders(brandProfileId)

    const handleSearch = debounce((text: string) => {
        navigate({
            search: (prev) => {
                return {
                    ...prev,
                    searchTerm: text
                }
            }
        })
    }, 500)

    const handleDateRangeSelect = (selectedRange: any) => {
        setAllMomentsAreSelected(false);

        const startDate = formatDateyyyyMMdd(selectedRange[0]);
        const endDate = formatDateyyyyMMdd(selectedRange[1]);

        setStartingDateString(startDate);
        setEndingDateString(endDate);

        setStartingDateObj(selectedRange[0]);
        setEndingDateObj(selectedRange[1]);


        // Navigate when range is updated
        navigate({
            search: (old) => {
                return {
                    ...old,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        })
        invalidateMomentsQuery();
        invalidateAylienTaxonomiesQuery();
    };

    const {
        moments,
        fetchNextPage,
        isFetchingNextPage,
        isMomentsError,
        handleSelectMoment,
        momentsIsLoading,
        setSortBy,
        handleSelectAllOnPage,
        selectAllMoments,
        allMomentsOnPageAreSelected,
        allMomentsAreSelected,
        setAllMomentsAreSelected,
        allMomentsIsFetching,
        allMoments,
        selectedMomentIds,
        postTrendRecActionFeedback,
        postTrendKeywordsFeedback,
        aylienNewsOptions,
        aylienIndustriesOptions,
        setAylienNews,
        setAylienIndustries,
        aylienIndustries,
        aylienNews,
        totalResultCount,
        hasNextPage,
        requestedPageSize,
        selectedMoments,
        resetSelectedMoments,
        handleSelectedMomentsTarget,
        getDefaultSelectedMoments,
        setSelectedMoments,
        invalidateMomentsQuery,
        invalidateAylienTaxonomiesQuery,
        renderAlert,
    } = useMoments({
        showTargetSuccess,
        brandProfileId,
        boardIds: boardIds || [],
        startDate,
        endDate,
        searchTerm: searchTerm.toString(),
        sortBy,
        actions,
        activationStatus
    })

    const selectedMoment = React.useMemo(() => {
        if (!moments) return undefined
        if (!selectedMomentId) return undefined
        const momentSelected = queryClient.getQueryData(MomentViewRqKeys.momentInViewSlideOver(brandProfileId, selectedMomentId))
        return moments?.filter((moment: ClusterType) => moment.clusterId === selectedMomentId)[0] || momentSelected
    }, [selectedMomentId, moments])

    const [boardsAreSorted, setBoardsAreSorted] = React.useState(false);
    const [sortedBoards, setSortedBoards] = React.useState<Board[] | undefined>(undefined);
    const [setshowSlidingModal, setShowSlidingModal] = useState(false);
    const [setFromSlidingModal, setFromShowSlidingModal] = useState('');

    const [allMomentsSelected, setAllMomentsSelected] = React.useState(false);

    const handleSelectAll = () => {
        selectAllMoments();
    }

    useEffect(() => {
        setAllMomentsAreSelected(false);
    }, [])

    useEffect(() => {
        setAllMomentsSelected(selectedMomentIds.length === allMoments.length && allMoments.length !== 0);
    }, [selectedMoments])

    const setDefaultSortByWithOnlyRelevance = () => {
        const selectedBoards = boardsObj.boards?.filter((board) => boardIds?.includes(board.id))
        const areAllBoardsRelevanceType = selectedBoards?.every((board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE)
        setSortBy(areAllBoardsRelevanceType ? 'relevance' : 'ranking')
    }

    React.useEffect(setDefaultSortByWithOnlyRelevance, [boardsObj.boards, boardIds])

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: (search) => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
        if (boardsObj.boards && boardsObj.boardsFetched && !boardsObj.boardsIsLoading && !boardsObj.boardsIsFetching) {
            // Sort boards in specific order, return sorted boards object, set loading state to prevent FOUC
            const sortedBoards = [...boardsObj.boards]
                .sort((a, b) => {
                    const typeOrder: Record<string, number> = { trendingMoments: 0, general: 1, custom: 2, naturalLanguage: 3 };
                    const { TRENDING_MOMENTS } = GENERAL_BOARD_DEFAULT_TYPES
                    const typeA = a.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : a.type;
                    const typeB = b.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : b.type;
                    if (typeA === typeB) {
                        return a.name.localeCompare(b.name);
                    }
                    return typeOrder[typeA] - typeOrder[typeB];
                });

            setSortedBoards(sortedBoards);
            setBoardsAreSorted(true); // Set loading state to true after sorting
            setNoIOsLinkedToBrandProfile(false)
            navigate({
                search: (search) => {
                    const boardIdsForUrl =
                        !Array.isArray(search?.boardIds)
                            ? sortedBoards.map(board => board.id)
                            : Array.isArray(search?.boardIds)
                                ? search?.boardIds.filter((id: number) => sortedBoards.map(board => board.id).includes(id))
                                : []
                    return {
                        ...search,
                        startDate: search?.startDate ?? formatDateyyyyMMdd(startingRange[0]),
                        endDate: search?.endDate ?? formatDateyyyyMMdd(startingRange[1]),
                        searchTerm: search?.searchTerm ?? '',
                        boardIds: boardIdsForUrl,
                        sortBy: search?.sortBy ?? 'ranking'
                    }
                }
            })
        }
    }, [currentBrandProfile, location.current.search, boardsObj.boards, boardsObj.boardsIsLoading, boardsObj.boardsFetched, boardsObj.boardsIsFetching])

    const handleDownloadTrendsByFilter = async () => {
        if (brandProfileId === undefined || boardIds === undefined || actions === undefined) return
        setDownloadingTrends(true)
        if (totalResultCount > 1000) {
            setDownloadingTrends(false)
            setShowTooManyTrendsError(true)
            return
        }
        const args = {
            brandProfileId,
            boardIds,
            startDate: startingDateString,
            endDate: endingDateString,
            actions,
            sortBy,
            searchTerm,
            aylienNews,
            aylienIndustries
        }
        try {
            await api.moments.downloadByFilter(args)
        } catch (err) {
            toast.error('There was an error downloading your moments.')
        } finally {
            setDownloadingTrends(false)
        }
    }

    const handleDownloadTrends = async () => {
        if (brandProfileId === undefined) return
        setDownloadingTrends(true)
        if (selectedMomentIds.length > 1000) {
            setDownloadingTrends(false)
            setShowTooManyTrendsError(true)
            return
        }
        const args = {
            brandProfileId,
            clusterIds: selectedMomentIds
        }
        try {
            await api.moments.download(args)
        } catch (err) {
            toast.error('There was an error downloading your moments.')
            setDownloadingTrends(false)
        }

        setDownloadingTrends(false)
    }

    const hasMonitor = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'monitor' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const hasTarget = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'target' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const hasBlock = React.useMemo(() => {
        if (!moments) return false
        for (const trend of moments) {
            if (trend.action === 'block' && selectedMomentIds.includes(trend.clusterId)) {
                return true
            }
        }
        return false
    }, [moments, selectedMomentIds])

    const handleTarget = async (args: TargetListProps) => {
        setShowTargetSuccess(false)
        setTargetError('')
        setIsTargeting(true)

        try {
            const res = await api.moments.createTargetList(args)

            if (res.status === 200) {
                setShowTargetSuccess(true)
                await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
                resetSelectedMoments(brandProfileId)
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response?.data === 'No Cids found') {
                    setTargetError('Error: No Cids found')
                    setShowTargetSuccess(false)
                    return
                }
            }

            setTargetError('An error occurred targeting these moments.')
            logError(err, { info: 'error targeting on aylien boards' })
        } finally {
            setIsTargeting(false)
        }
    }

    const handleTargetV2 = async (payload: TargetListPropsV2) => {
        setShowTargetSuccess(false)
        setTargetError('')
        setIsTargeting(true)

        try {
            const res = await api.moments.createTargetListV2(payload)
            if (res.status === 200) {
                setShowTargetSuccess(true)
                await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
                resetSelectedMoments(brandProfileId)
            }
        } catch (err) {
            if (isAxiosError(err)) {
                if (err.response?.data === 'No Cids found') {
                    setTargetError('Error: No Cids found')
                    setShowTargetSuccess(false)
                    return
                }
            }

            setTargetError('An error occurred targeting these moments.')
            logError(err, { info: 'error targeting on aylien boards' })
        } finally {
            setIsTargeting(false)
        }
    }

    const handleBlock = async ({
        keywordClusters,
        placementClusters,
        ioIds
    }: {
        keywordClusters: ClusterActivationType[]
        placementClusters: ClusterActivationType[]
        ioIds: number[]
    }) => {
        if (brandProfileId === null || brandProfileId === undefined) {
            return
        }
        setBlockPlacementsError('')
        setBlockKeywordsError('')
        setShowBlockPlacementsSuccess(false)
        setShowBlockKeywordsSuccess(false)

        if (keywordClusters.length > 0) {
            setBlockingKeywords(true)
            try {
                const keywordResult = await api.moments.createKeywordsBlockList({
                    brandProfileId,
                    clusters: keywordClusters,
                    ioIds
                })
                if (keywordResult?.status === 200 && keywordResult.data === 'No Cids found') {
                    setBlockKeywordsError('Error: No Cids found')
                    setShowBlockKeywordsSuccess(false)
                    return
                }

                if (keywordResult?.status === 200) {
                    setBlockingKeywords(false)
                    setShowBlockKeywordsSuccess(true)
                    setBlockKeywordsError('')
                }
            } catch (err) {
                logError(err, { info: 'error blocking keywords moments list' })
                setBlockKeywordsError('An unknown error occurred blocking keywords.')
                setShowBlockKeywordsSuccess(false)
            } finally {
                resetSelectedMoments(brandProfileId)
                setBlockingKeywords(false)
            }
        }

        if (placementClusters.length > 0) {
            setBlockingPlacements(true)

            try {
                const placementResult = await api.moments.createPlacementsBlockList({
                    brandProfileId,
                    clusters: placementClusters,
                    ioIds
                })

                if (placementResult?.status === 200 && placementResult.data === 'No Cids found') {
                    setBlockPlacementsError('Error: No Cids found')
                    setShowBlockPlacementsSuccess(false)
                    return
                }

                if (placementResult?.status === 200) {
                    setShowBlockPlacementsSuccess(true)
                    setBlockPlacementsError('')
                }
            } catch (err) {
                logError(err, { info: 'error blocking keywords moments list' })
                setBlockPlacementsError('An unknown error occurred blocking videos.')
                setShowBlockPlacementsSuccess(false)
            } finally {
                resetSelectedMoments(brandProfileId)
                setBlockingPlacements(false)
            }
        }
    }

    const resetBrandProfileIdMoments = (brandProfileId: number) => {
        setSelectedTabIndex(0)
        setSelectedMoments(getDefaultSelectedMoments(brandProfileId))
        setShowBlockPlacementsSuccess(false)
        setShowBlockKeywordsSuccess(false)
        setBlockPlacementsError('')
        setBlockKeywordsError('')
        setTargetError('')
        setShowTargetSuccess(false)
    }

    if (brandProfilesIsLoading) {
        return null
    }

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    }

    if (boardsObj.boardsIsLoading) {
        return <LoadingPage message="Fetching boards" />
    }

    if (
        (
            !boardsObj.boards
            // only navigate if you're on the brand profile that has 0 boards
            // If the account is maxed out on their board quota,
            // and has multiple brand profiles, they should
            // still be able to see the boards of the brand profile that has them
            || (boardsObj.boards.length === 0 && boardsQuotaTally?.isMaxedOut)
        )
        && userCanPermissionProductQuota({
            requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
            requiredProductValue: products.BOARDS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
        })
    ) {
        return <Navigate to={PATH_MOMENTS_V2_SETTINGS_REDESIGN} />
    }

    const getSelectedBoardNames = () => {
        if (!boardIds || !sortedBoards) return "";

        switch (boardIds.length) {
            case 0:
                return "No board selected";
                break;
            case 1:
                return sortedBoards.filter(b => boardIds && boardIds.includes(b.id)).map(b => b.name).join();
                break;

            default:
                return 'Boards';
                break;
        }
    }

    // CheckTreePicker doesn't correctly focus on clicked items. These functions fix the issue
    const focusOnClickedItem = (event: React.SyntheticEvent | undefined): void => {
        const target = event?.target as HTMLElement | null;
        const itemLabel = target?.closest('.rs-check-tree-node')?.querySelector<HTMLElement>('.rs-check-tree-node-label');

        if (itemLabel) {
            itemLabel.focus();
        }
    };

    const handleActionsDropdownChange: CheckTreePickerProps['onChange'] = (actions, event) => {
        setAllMomentsAreSelected(false);
        actions.length > 0 && setActions(actions);
        focusOnClickedItem(event);
    };

    const handleStatusDropdownChange: CheckTreePickerProps['onChange'] = (newValue, event) => {
        setAllMomentsAreSelected(false);
        setActivationStatus(newValue);
        focusOnClickedItem(event);
    };

    const findTaxonomyCode = (items: any[], id: number): string => {
        for (const item of items) {
            if (item.id === id) {
                return item.taxonomyCode;
            }
            if (item.children) {
                const result = findTaxonomyCode(item.children, id);
                if (result) {
                    return result;
                }
            }
        }

        return '';
    };

    const getTaxonomyCodes = (items: any[], ids: number[]): string[] => {
        return ids.map(id => findTaxonomyCode(items, id)).filter(code => code !== '')
    }

    const handleNewsDropdownChange: CheckTreePickerProps['onChange'] = (values, event) => {
        setAllMomentsAreSelected(false);
        setSelectedNewsCategoryIds(values);
        setAylienNews(getTaxonomyCodes(aylienNewsOptions, values));
        focusOnClickedItem(event);
    };

    const handleIndustriesDropdownChange: CheckTreePickerProps['onChange'] = (values, event) => {
        setAllMomentsAreSelected(false);
        setSelectedIndustryCategoryIds(values);
        setAylienIndustries(getTaxonomyCodes(aylienIndustriesOptions, values));
        focusOnClickedItem(event);
    };

    const isTargetDisabled = (): boolean | undefined => {
        return (
            selectedMomentIds.length > MAX_NUMBER_OF_MOMENTS_TO_TARGET ||
            selectedMomentIds.length <= 0  &&
            (
                (boardIds && boardIds?.length > 0) ||
                totalResultCount === 0  ||
                momentsIsLoading
            )
        )
    }

    const getTargetTooltipContent = (): React.ReactNode => {
        return selectedMomentIds.length > MAX_NUMBER_OF_MOMENTS_TO_TARGET ?
            `For the moment target is disabled when more than ${MAX_NUMBER_OF_MOMENTS_TO_TARGET} are selected` :
            "To target moments, please select a moment first"
    }


    return (
        <div className="gridContainer animate-in fade-in">
            <JustificationSlidingOver
                open={setshowSlidingModal}
                handleClose={() => setShowSlidingModal(false)}
                header="Mentality AI"
                selectedMoments={selectedMoments}
                brandProfileId={brandProfileId}
                openFrom={setFromSlidingModal}
            />
            {boardsAreSorted && (
                <Sidebar
                    handleBrandProfileChange={(brandProfileId: number) => {
                        navigate({
                            search: (old) => {
                                return {
                                    ...old,
                                    brandProfileId
                                }
                            }
                        })
                        resetBrandProfileIdMoments(brandProfileId)
                    }}
                    resetBrandProfileIdMoments={resetBrandProfileIdMoments}
                    brandProfileId={brandProfileId}
                    handleSelectBoard={boardsObj.handleSelectBoard}
                    handleSelectAllBoards={boardsObj.handleSelectAllBoards}
                    clearSelectedBoards={boardsObj.clearSelectedBoards}
                    boards={sortedBoards || []}
                    showBackToDashboards={(boardsObj?.boards && boardsObj.boards.length > 0) || false}
                    selectedBoards={boardIds || []}
                    handleDeleteBoard={() => { resetSelectedMoments(brandProfileId) }}
                    hideNewBoardButton={!userCanPermissionProductQuota({
                        requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                        requiredProductValue: products.BOARDS,
                        userPermissions: userPermissions,
                        userProducts: userProducts,
                        checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
                        quota_is_allowed: quotaIsAllowed
                    })}
                />
            )}

            {selectedTabIndex === 0 ? (
                <>
                    <div className="header-aylien">
                        <div className="w-full">
                            <BoardTabs
                                fixedHeadline={getSelectedBoardNames()}
                                selectedTabIndex={selectedTabIndex}
                                setSelectedTabIndex={setSelectedTabIndex}
                            />
                            {/*<Filters*/}
                            <div data-testid="anticipation-boards-filters-panel" className='flex items-center justify-between py-4 bg-gray-100 w-100'>
                                <div className="flex gap-4 px-6">
                                    <div data-testid="anticipation-boards-filters-panel-actionDropdown">
                                        <CheckTreePicker
                                            id="actionDropdown"
                                            style={{ width: 162 }}
                                            preventOverflow
                                            data={actionOptions}
                                            labelKey="label"
                                            valueKey="id"
                                            onChange={handleActionsDropdownChange}
                                            value={actions}
                                            cleanable={false}
                                        />
                                    </div>
                                    {enableActivationFilters && <div data-testid="anticipation-boards-filters-panel-activationStatusDropdown">
                                        <CheckTreePicker
                                            id="activationStatusDropdown"
                                            style={{ width: 162 }}
                                            preventOverflow
                                            data={activationStatusOptions}
                                            labelKey="label"
                                            valueKey="id"
                                            placeholder="Activation Status"
                                            onChange={handleStatusDropdownChange}
                                            cleanable={false}
                                            value={activationStatus}
                                        />
                                    </div>}
                                    <div data-testid="anticipation-boards-filters-panel-dateDropdown">
                                        <DateRangeSection
                                            onSelectDateRange={handleDateRangeSelect}
                                            ranges={ranges}
                                            onCalendarStateChange={() => { }}
                                            rangeValue={[startingDateObj, endingDateObj]}
                                        />
                                    </div>
                                    <div data-testid="anticipation-boards-filters-panel-news-filter">
                                        <CheckTreePicker
                                            id="pendo-aylien-news-filter"
                                            style={{ width: 162 }}
                                            menuStyle={{ maxWidth: '90vw' }}
                                            preventOverflow
                                            data={aylienNewsOptions}
                                            labelKey="name"
                                            valueKey="id"
                                            cascade={false}
                                            placeholder="News"
                                            onChange={handleNewsDropdownChange}
                                            value={selectedNewsCategoryIds}
                                        />
                                    </div>
                                    <div data-testid="anticipation-boards-filters-panel-industries-filter">
                                        <CheckTreePicker
                                            id="pendo-aylien-industry-filter"
                                            style={{ width: 162 }}
                                            menuStyle={{ maxWidth: '90vw' }}
                                            preventOverflow
                                            data={aylienIndustriesOptions}
                                            labelKey="name"
                                            valueKey="id"
                                            cascade={false}
                                            placeholder="Industries"
                                            onChange={handleIndustriesDropdownChange}
                                            value={selectedIndustryCategoryIds}
                                        />
                                    </div>
                                </div>


                                <div
                                    data-testid="anticipation-boards-search-box"
                                    className="px-6"
                                >
                                    <SightlyInput
                                        id="searchMoments"
                                        defaultValue={searchTerm}
                                        placeholder="Search moments"
                                        onChange={(val) => handleSearch(val)}
                                        hasSearchIcon
                                        width={240}
                                    />
                                </div>
                            </div>
                            {/*<Actions*/}
                            <div
                                data-testid="anticipation-boards-action-buttons"
                                className="flex items-center justify-start w-full gap-4 px-6 my-4"
                            >
                                <div
                                    data-testid="anticipation-boards-action-buttons-selectAllCheckbox"
                                    className="mr-2"
                                >
                                    <SightlyCheckbox
                                        data-testid="select-all-moments-checkbox"
                                        id="selectAllCheckbox"
                                        indeterminate={selectedMomentIds.length > 0 && !allMomentsOnPageAreSelected}
                                        checked={allMomentsOnPageAreSelected}
                                        handleChange={(checked: boolean) => {
                                            handleSelectAllOnPage(checked)
                                        }}
                                        label={
                                            selectedMomentIds.length > 0 ? (
                                                <div className="text-xs text-sightly-slate">
                                                    {selectedMomentIds.length} item(s) selected
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        }
                                    />
                                </div>

                                {userCanPermissionProductQuota({
                                    requiredPermissionValue: perms.TRENDS_DOWNLOAD,
                                    requiredProductValue: products.BOARDS,
                                    userPermissions: userPermissions,
                                    userProducts: userProducts,
                                    checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                                }) && (
                                        <div data-testid="anticipation-boards-action-buttons-download">
                                            <ActionsMenu
                                                buttonId="download"
                                                buttonText="Download"
                                                buttonIcon={
                                                    <ArrowDownTrayIcon
                                                        className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                                        aria-hidden="true"
                                                    />
                                                }
                                                options={[
                                                    {
                                                        disabled:
                                                            selectedMomentIds.length < 1 || totalResultCount === 0 || momentsIsLoading,
                                                        name: 'Download selected moments',
                                                        description: 'Download all of the selected moments and their meta data',
                                                        onClick: handleDownloadTrends,
                                                        icon: (
                                                            <ArrowDownTrayIcon
                                                                className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                        )
                                                    },
                                                    {
                                                        disabled: totalResultCount < 1 || momentsIsLoading,
                                                        name: 'Download all moments',
                                                        description:
                                                            'Download all of the moments and their meta data, according to your filters',
                                                        onClick: handleDownloadTrendsByFilter,
                                                        icon: (
                                                            <ArrowDownTrayIcon
                                                                className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                                aria-hidden="true"
                                                            />
                                                        )
                                                    }
                                                ]}
                                                selectorClass="pendo-download-button-selector"
                                            />
                                        </div>
                                    )}
                                {
                                    userCanPermissionProductQuota({
                                        requiredPermissionValue: perms.TRENDS_TARGET,
                                        requiredProductValue: products.BOARDS,
                                        userPermissions: userPermissions,
                                        userProducts: userProducts,
                                        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                                    }) && (
                                        <>
                                            <Tooltip
                                                content="To block moments, please select a moment first"
                                                disabled={selectedMomentIds.length > 0}
                                            >
                                                <div data-testid="anticipation-boards-action-buttons-block">
                                                    <SightlyButton
                                                        id="blockButton"
                                                        disabled={
                                                            (selectedMomentIds.length <= 0) &&
                                                            ((boardIds && boardIds?.length > 0) || totalResultCount === 0 ||
                                                                momentsIsLoading)
                                                        }
                                                        handleClick={() => {
                                                            if (!!(iosQuery.data && iosQuery.data.length < 1)) {
                                                                setNoIOsLinkedToBrandProfile(true);
                                                            } else {
                                                                hasTarget
                                                                    ? setTryingToBlockWithTarget(true)
                                                                    : hasMonitor
                                                                        ? setTryingToBlockWithMonitor(true)
                                                                        : setIsViewingBlockModal(true);
                                                            }
                                                        }}
                                                        text="Block"
                                                        type="secondaryblack"
                                                        icon={
                                                            <NoSymbolIcon
                                                                className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                                                aria-hidden="true"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Tooltip>

                                            <Tooltip
                                                content={getTargetTooltipContent()}
                                                disabled={!isTargetDisabled()}
                                            >
                                                <div data-testid="anticipation-boards-action-buttons-target">
                                                    <SightlyButton
                                                        id="targetButton"
                                                        disabled={
                                                            isTargetDisabled()
                                                        }
                                                        handleClick={() => {
                                                            if (!!(iosQuery.data && iosQuery.data.length < 1)) {
                                                                setNoIOsLinkedToBrandProfile(true);
                                                            } else {
                                                                hasMonitor
                                                                    ? setTryingToTargetWithMonitor(true)
                                                                    : hasBlock
                                                                    ? setTryingToTargetWithBlock(true)
                                                                    : setIsViewingTargetModal(true);
                                                            }
                                                        }}
                                                        text="Target"
                                                        type="secondaryblack"
                                                        icon={
                                                            <BoltIcon
                                                                className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                                                aria-hidden="true"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Tooltip>
                                        </>
                                    )

                                }

                                <Tooltip
                                    content={allMomentsAreSelected ? "To analyze moments, please select only on screen moments" : "To analyze moments, please select a moment first"}
                                    disabled={!allMomentsAreSelected && selectedMomentIds.length > 0}
                                >
                                    <div data-testid="anticipation-boards-action-buttons-ai-analyze">
                                        <SightlyButton
                                            id="aiAnalyzeButton"
                                            handleClick={() => {
                                                setFromShowSlidingModal(MomentJustificationOrigin.Analyze)
                                                setShowSlidingModal(true)
                                            }
                                        }
                                        text="Analyze"
                                        disabled={selectedMomentIds.length != 1}
                                        type="gradient-ai"
                                        icon={
                                            <img data-testid={'ai-analyze-image'} src={sparklesAISvg} className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"/>
                                        }
                                    />
                                    </div>
                                </Tooltip>

                                <div className='w-[1px] h-9 bg-[#E5E7EB]' />

                                <div data-testid="anticipation-boards-action-buttons-ai-history">
                                    <SightlyButton
                                        id="aiHistoryButton"
                                        handleClick={() => {
                                            setFromShowSlidingModal(MomentJustificationOrigin.History)
                                            setShowSlidingModal(true)
                                        }
                                        }
                                        text=""
                                        type="gradient-ai"
                                        icon={
                                            <img data-testid={'ai-history-image'} src={clockSvg} className="w-5 h-5 text-grey-900" />
                                        }
                                    />
                                </div>

                                <div className="flex ml-auto">
                                    {!momentsIsLoading && moments && moments.length > 0 && (
                                        <div
                                            data-testid="showing-moments-count"
                                            className="flex flex-col justify-center mr-5 text-xs text-sightly-slate"
                                        >
                                            {`Showing ${moments.length} of ${totalResultCount}`}
                                        </div>
                                    )}
                                    <div>
                                        <SortBy
                                            selected={sortBy}
                                            onChange={(val: MomentSortBy) => setSortBy(val)}
                                            buttonClass='pendo-moments-sort-by-button'
                                        />
                                    </div>
                                </div>
                            </div>

                            <ActionMessages
                                downloadingTrends={downloadingTrends}
                                noIOsLinkedToBrandProfile={noIOsLinkedToBrandProfile}
                                setNoIOsLinkedToBrandProfile={setNoIOsLinkedToBrandProfile}
                                iosQuery={iosQuery}
                                brandProfileId={brandProfileId}
                                showTargetSuccess={showTargetSuccess}
                                setShowTargetSuccess={setShowTargetSuccess}
                                isTargeting={isTargeting}
                                targetError={targetError}
                                setTargetError={setTargetError}
                                showBlockKeywordsSuccess={showBlockKeywordsSuccess}
                                setShowBlockKeywordsSuccess={setShowBlockKeywordsSuccess}
                                showBlockPlacementsSuccess={showBlockPlacementsSuccess}
                                setShowBlockPlacementsSuccess={setShowBlockPlacementsSuccess}
                                blockingKeywords={blockingKeywords}
                                blockingPlacements={blockingPlacements}
                                blockPlacementsError={blockPlacementsError}
                                setBlockPlacementsError={setBlockPlacementsError}
                                blockKeywordsError={blockKeywordsError}
                                setBlockKeywordsError={setBlockKeywordsError}
                            />
                        </div>
                    </div>

                    {/* Moments*/}
                    <div className="px-4 bg-gray-100 main">
                        {isMomentsError ? (
                            <InfoPage message="There was an unknown error fetching Moments. Please refresh the page to try again. If the problem persists, please contact support." />
                        ) : isDateOutOfBounds ? (
                            <InfoPage message={'Please select a date range within past the 60 days'} />
                        ) : !boardsObj.boards || boardsObj.boards.length === 0 ? (
                            <InfoPage message="We currently don’t have any boards created and processed for this Brand Profile." />
                        ) : boardIds?.length === 0 && userCanPermissionProductQuota({
                            requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                            requiredProductValue: products.BOARDS,
                            userPermissions: userPermissions,
                            userProducts: userProducts,
                            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
                        }) ? (
                            <InfoPage message="Please select a board" />
                        ) : momentsIsLoading ? (
                            <LoadingPage message={'Fetching moments'} />
                        ) : !momentsIsLoading && moments && moments.length < 1 ? (
                            <InfoPage message="There are no moments for your filters" />
                        ) : (
                            <div className="w-full">
                              {allMomentsOnPageAreSelected &&
                                !allMomentsSelected &&
                                selectedMomentIds.length !== totalResultCount &&
                                totalResultCount > requestedPageSize &&
                                (
                                  <SelectAllMomentsPanel
                                    allMomentsIsFetching={allMomentsIsFetching}
                                    selectedMomentsAmount={selectedMomentIds.length}
                                    allMomentsAmount={totalResultCount}
                                    handleSelectAll={handleSelectAll} />
                                )
                              }
                              {moments?.map((moment: ClusterType) => {
                                    return (
                                        <MomentCard
                                            key={moment.clusterId}
                                            trendingMoments={boardsObj.boards?.find((board: Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS)}
                                            momentsIsLoading={momentsIsLoading}
                                            checked={selectedMomentIds.includes(moment.clusterId)}
                                            handleSelectMoment={handleSelectMoment}
                                            brandProfileId={brandProfileId}
                                            moment={moment}
                                            handleTrendNameClick={(moment: ClusterType) => {
                                                navigate({
                                                    search: (search) => ({
                                                        ...search,
                                                        selectedMomentId: moment.clusterId
                                                    })
                                                })
                                                setIsViewingMoment(true)
                                            }}
                                        />
                                    )
                                })}

                                {hasNextPage && (
                                    <div className="flex justify-center w-full py-4">
                                        <SightlyButton
                                            datatestid="load-more-moments-button"
                                            block
                                            id="loadmorebutton"
                                            text="Load More"
                                            handleClick={fetchNextPage}
                                            loading={isFetchingNextPage}
                                            disabled={isFetchingNextPage}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="header-aylien">
                        <div className="w-full">
                            <BoardTabs
                                fixedHeadline={getSelectedBoardNames()}
                                selectedTabIndex={selectedTabIndex}
                                setSelectedTabIndex={setSelectedTabIndex}
                            />
                        </div>
                    </div>

                    <div className="px-4 main">
                        <Narratives />
                    </div>
                </>
            )}
            {renderAlert()}

            <AylienBoardPortalModal showTooManyTrendsError={showTooManyTrendsError} setShowTooManyTrendsError={setShowTooManyTrendsError} />

            <BlockSlideOver
                open={isViewingBlockModal}
                setOpen={setIsViewingBlockModal}
                selectedMoments={selectedMoments}
                handleBlock={handleBlock}
                handleSelectMoment={handleSelectMoment}
            />

            {isNewActivationManagerEnabled ?
                (isViewingTargetModal && <TargetMoment
                    open={isViewingTargetModal}
                    trendingMoments={boardsObj.boards?.find((board: Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS)}
                    setOpen={setIsViewingTargetModal}
                    selectedMoments={selectedMoments}
                    handleTarget={handleTargetV2}
                    handleSelectMoment={handleSelectMoment}
                />) :
                <TargetSlideOver
                    open={isViewingTargetModal}
                    setOpen={setIsViewingTargetModal}
                    selectedMoments={selectedMoments}
                    handleTarget={handleTarget}
                    handleSelectMoment={handleSelectMoment}
                />}

            {brandProfileId && selectedMoment && (
                <MomentSlideOver
                    show={isViewingMoment}
                    close={setIsViewingMoment}
                    moment={selectedMoment}
                    brandProfileId={brandProfileId}
                    postTrendRecActionFeedback={postTrendRecActionFeedback}
                    postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                    invalidateMomentsQuery={invalidateMomentsQuery}
                />
            )}

            <ConfirmationModals tryingToBlockWithTarget={tryingToBlockWithTarget}
                setTryingToBlockWithTarget={setTryingToBlockWithTarget}
                setIsViewingBlockModal={setIsViewingBlockModal}
                tryingToBlockWithMonitor={tryingToBlockWithMonitor}
                setTryingToBlockWithMonitor={setTryingToBlockWithMonitor}
                tryingToTargetWithBlock={tryingToTargetWithBlock}
                setTryingToTargetWithBlock={setTryingToTargetWithBlock}
                setIsViewingTargetModal={setIsViewingTargetModal}
                tryingToTargetWithMonitor={tryingToTargetWithMonitor}
                setTryingToTargetWithMonitor={setTryingToTargetWithMonitor}
            />

            <style>{`
            .gridContainer {
              display: grid;
              height: 100%;	
              grid-template-columns: 345px 1fr;	
              grid-template-rows:  0fr 0px 1fr 1fr;
              grid-template-areas:
                "sidebar header-aylien"
                "sidebar action-bar"
                "sidebar main"
                "sidebar main"	
                "sidebar footer-aylien"	
            }
           
            .header-aylien {
                grid-area: header-aylien;		
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-bottom: 2px solid #f8f8f8;
            }
            .main {
                grid-area: main;
                overflow-y: auto;
                justify-content: center;
                display: flex;
                height: 100%;          
            }  
                       
          `}</style>
        </div>
    )
}

export default AylienBoard;
