import React from 'react'
import { Icon, Input, InputGroup } from 'rsuite'
import debounce from 'just-debounce-it'

import { sightlyBlue } from '@/assets/jss/colorConstants'

/**
 * Search box component.
 * @param {Object} props - Component props.
 * @param {(searchKey: string) => void} props.handleSearch - Function called when the search keyword changes.
 * @param {string} [props.placeholder] - Placeholder text for the search box.
 * @param {boolean} [props.disabled] - Whether the search box is disabled.
 * @returns {JSX.Element} The rendered component.
 */
const SearchBox = (props: {
  className?: string
  handleSearch: (searchKey: string) => void
  placeholder?: string
  disabled?: boolean
  dataTestId?: string
  height?: string
}) => {
  const keywordRef = React.useRef<any>()
  const handleSearch = React.useCallback(
    debounce((searchKeyword: string) => {
      keywordRef.current.value = searchKeyword
      props.handleSearch(searchKeyword ?? '')
    }, 500),
    []
  )
  return (
    <div data-testid={props.dataTestId ?? 'search-box'} id='pendo-search-box' className={props.className ?? ''}>
      <InputGroup
        inside
        style={{ height: props.height ?? '38px', padding: '0 2px' }}
      >
        <InputGroup.Button>
          <Icon icon="search" />
        </InputGroup.Button>
        <Input
          data-testid="search-box-input"
          disabled={props.disabled}
          placeholder={props.placeholder ?? 'Search'}
          onChange={(searchKeyword: string) => {
            handleSearch(searchKeyword)
          }}
          inputRef={keywordRef}
        />
        <InputGroup.Button
          data-testid="search-box-clear-button"
          onClick={() => {
            if (keywordRef.current) keywordRef.current.value = ''
            handleSearch('')
          }}
        >
          <Icon icon="close" />
        </InputGroup.Button>
      </InputGroup>
      <style>
        {`
          .rs-input:focus, .rs-input:hover {
            border-color: ${sightlyBlue};
            outline: 0;
          }
            .rs-input-group.rs-input-group-inside .rs-input{
            min-width: 7rem;
            }
        `}
      </style>
    </div>
  )
}

export default SearchBox
