import React from 'react';
import Tabs from '@/components/Tabs/Tabs';
import useEmbedUrls from '@/hooks/useEmbedUrls';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import NoBrandProfiles from '@/components/NoBrandProfiles';
import { useQueryClient } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import MetabaseIFrame from '@/views/Discover/MetabaseIFrame';
import { useSearch } from '@tanstack/react-location';
import { TokenExpirationAlertModal } from '@/views/Discover/components/TokenExpirationAlertModal';
import { getTokenExpirationDate, getTokenFromURL } from '@/classes/utils';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const addIframeListener = (iframeId) => {
    // Update the document title using the browser API
    try {
        window.addEventListener('message', function (event) {
            if (
                document.getElementById(iframeId) &&
                event.source === document.getElementById(iframeId).contentWindow &&
                event.origin === 'https://metabase.sightly.com/'
            ) {
                if (event.data.type === 'workbook:loaded') {
                    const metabase_iframe = document.getElementById(iframeId)
                    metabase_iframe.contentWindow.postMessage(
                        {
                            type: 'workbook:variables:update',
                            variables: { 'Profile-Name': '', 'INSERTION-ORDER-NAME': '' }
                        },
                        'https://metabase.sightly.com/'
                    )
                }
            }
        })
    } catch (err) { }
}

const IFRAME_ID = {
    brand_mentality: 'brandMentalityIframe',
    insights: 'bpInsightsIframe',
}
const ACTIVE_TAB =
{
    brand_mentality: 'Brand Mentality',
    brand_profile_insights: 'Brand Profile Insights',
}

const GetEmbedUrls = (embedUrls, activeTab) => {
    if (activeTab === ACTIVE_TAB.brand_mentality)
        return embedUrls?.metabaseBrandMentalityEmbedUrl
    else if (activeTab === ACTIVE_TAB.brand_profile_insights)
        return embedUrls?.metabaseBrandProfileInsightsEmbedUrl
    else
        return ""
}

const Reporting = () => {
    const queryClient = useQueryClient()
    const { reportingView } = useSearch();
    const { currentAccount, perms, userPermissions } = useUser()
    const { data: brandProfiles, isLoading, isFetched } = useBrandProfiles({ submittedOnly: true })
    const [activeTab, setActiveTab] = React.useState('')
    const [views, setViews] = React.useState([])

    const [showModal, setShowModal] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        if (!currentAccount.accountId) return
        queryClient.refetchQueries(rqKeys.embedUrlsKey(currentAccount.accountId))
    }, [activeTab, currentAccount])

    React.useEffect(() => {
        const _views = []
        if (userCanPermissionProductQuota({
            requiredPermissionValue: perms.REPORTING_BRAND_MENTALITY_USAGE_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })) {
            _views.push('Brand Mentality')
        }
        if (userCanPermissionProductQuota({
            requiredPermissionValue: perms.REPORTING_BRAND_MENTALITY_INSIGHTS_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })) {
            _views.push('Brand Profile Insights')
        }

        setViews(_views)

        if (reportingView && _views.includes(reportingView)) {
            setActiveTab(reportingView);
        } else {
            setActiveTab(_views[0]);
        }
    }, [reportingView])

    React.useEffect(() => {
        if (document.getElementById(IFRAME_ID.insights)) {
            addIframeListener(IFRAME_ID.insights)
        }
        if (document.getElementById(IFRAME_ID.brand_mentality)) {
            addIframeListener(IFRAME_ID.brand_mentality)
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [])

    const { data: embedUrls } = useEmbedUrls(currentAccount?.accountId)

    React.useEffect(() => {
        timer();
    }, [embedUrls])

    const timer = () => {
        if (embedUrls?.metabaseBrandMentalityEmbedUrl) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            const token = getTokenFromURL(embedUrls?.metabaseBrandMentalityEmbedUrl)
            
            const expirationTime = getTokenExpirationDate(token);
            
            const timeUntilExpiration = expirationTime - Date.now();
            if(timeUntilExpiration > 0) {   
                timerRef.current = setTimeout(() => {
                    setShowModal(true);
                }, timeUntilExpiration);
            }
        }
    };

    if (brandProfiles && brandProfiles.length === 0 && !isLoading && isFetched) {
        return <NoBrandProfiles />
    }

    return (
        <div style={{ padding: 24 }}>
            <Tabs
                width={'100%'}
                marginRight={40}
                active={activeTab}
                onChange={(active) => {
                    setActiveTab(active)
                }}
            >
                {views.map((view) => {
                    return <div key={view}>{view}</div>
                })}
            </Tabs>

            <TokenExpirationAlertModal 
                onHide={() => setShowModal(false)}
                show={showModal}
            />

            {activeTab === ACTIVE_TAB.brand_mentality && (
                <div style={{ paddingTop: 12 }}>
                    {userCanPermissionProductQuota({
                        requiredPermissionValue: perms.REPORTING_BRAND_MENTALITY_USAGE_READ,
                        userPermissions: userPermissions,
                        checkType: PermissionCheckType.PERMISSION_CHECK
                    }) && (
                        <MetabaseIFrame
                            id={IFRAME_ID.brand_mentality}
                            title={ACTIVE_TAB.brand_mentality}
                            sourceUrl={GetEmbedUrls(embedUrls, ACTIVE_TAB.brand_mentality)}
                        />
                    )}
                </div>
            )}

            {activeTab === ACTIVE_TAB.brand_profile_insights && (
                <div style={{ paddingTop: 12 }}>
                    {userCanPermissionProductQuota({
                        requiredPermissionValue: perms.REPORTING_BRAND_MENTALITY_INSIGHTS_READ,
                        userPermissions: userPermissions,
                        checkType: PermissionCheckType.PERMISSION_CHECK
                    }) && (
                        <MetabaseIFrame
                            id={IFRAME_ID.insights}
                            title={ACTIVE_TAB.brand_profile_insights}
                            sourceUrl={GetEmbedUrls(embedUrls, ACTIVE_TAB.brand_profile_insights)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default Reporting
