import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import { ranges } from '@/views/ReportGenerator/reportGenerator-types';
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import { ValueType } from "rsuite/lib/DateRangePicker";

interface IProps {
    handleOnChange: Function
    formValues: ActivationReportConfiguration
}


export default function DateRange({ handleOnChange, formValues } : IProps) {
    return (
      <div data-testid='div-report-generator-select-date-range'>
        <h5 className="mt-3 mb-2 text-base">Date Range</h5>
        <div className="relative">
          <DateRangePicker
            ranges={ranges}
            value={[formValues.startDate, formValues.endDate]}
            onChange={(dates) => handleOnChange(dates)}
            placeholder="Select Date(s)"
            placement="autoVerticalStart"
            cleanable={false}
          />
        </div>
      </div>
    )
}
