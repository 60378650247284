import React from "react";
import {SelectPicker} from "rsuite";
import {ActivationReportConfiguration} from "@/views/ActivationReports/types/activation-report.types";

interface IProps {
    handleOnChange: Function
  formValues: ActivationReportConfiguration
}

export const grouping = [
  { label: 'Insertion Order', value: 'insertionOrder' },
  { label: 'Report', value: 'report' },
  { label: 'Ad Format', value: 'adFormat' },
]
export default function GroupBy({  handleOnChange, formValues } : IProps) {
    return (
      <div data-testid='div-report-generator-select-group-by'>
        <h5 className="mt-3 mb-2 text-base">Group By</h5>
        <div className="relative">
          <SelectPicker
            data={grouping}
            value={formValues.grouping}
            onChange={(value) => handleOnChange('grouping', value)}
            searchable={false}
            placement="autoVerticalStart"
            cleanable={false}
            data-testid='group-by-picker'
          />
        </div>
      </div>
    )
}
