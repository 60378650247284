import React, { useState } from 'react'
import { CheckTree, Icon, IconButton } from 'rsuite';

import DataPickerHeader from '@/components/DataPickerFooter'
import Tooltip from '@/components/TailwindTooltip'
import SearchBox from '@/components/SearchBox'


/**
 * Checkable Tree Data Picker component.
 * @param {Object} props - Component props.
 * @param {string} props.title - Title displayed above the picker.
 * @param {string} [props.componentKey] - Unique key for the component.
 * @param {string} props.labelKey - Key in the options array that contains the label to display.
 * @param {string} props.valueKey - Key in the options array that contains the value of each option.
 * @param {Array<any>} props.options - Array of options to display in the tree.
 * @param {any} props.value - Currently selected value or values.
 * @param {boolean} [props.virtualized] - Flag to enable virtualization for large lists.
 * @param {string} [props.childrenKey] - Key in the options array that contains children options.
 * @param {boolean} [props.hideSearchBox] - Flag to hide the search box.
 * @param {string} [props.placeholder] - Placeholder text for the search box.
 * @param {number} [props.checkTreeHeight] - Height of Checktree in px.
 * @param {(value: any) => void} [props.handleChange] - Function called when the selected value changes.
 * @param {(selectAll: boolean) => void} [props.handleSelectAll] - Function called when the "Select All" button is clicked.
 * @param {(activeNode: any, value: any) => void} [props.handleSelectNode] - Function called when a node is selected.
 * @param {(searchKey: string) => void} [props.handleSearch] - Function called when the search keyword changes.
 * @param {string} [props.suffixForCount] - Suffix to display after the count of selected items.
 * @returns {JSX.Element} The rendered component.
 */

type BaseCheckTreeDataPickerProps = {
  title: string
  componentKey?: string
  labelKey: string
  valueKey: string
  options: Array<any>
  value: any
  disabledItemValues?: any[]
  uncheckableItemValues?: any[]
  virtualized?: boolean
  childrenKey?: string
  hideSearchBox?: boolean
  placeholder?: string
  checkTreeHeight?: number
  suffixForCount?: string
  rowCountValue?: number
  handleChange?: (value: any) => void
  handleSelectAll?: (selectAll: boolean) => void
  handleSelectNode?: (activeNode: any, value: any) => void
  handleSearch?: (searchKey: string) => void
  addCollapsetoHeader?: boolean
}

type CheckTreeDataPickerPropsWithTabs = BaseCheckTreeDataPickerProps & {
  tabs: {
    id: string
    label: string
  }[]
  handleChange?: (value: any, tab: string) => void
  handleSelectNode?: (activeNode: any, value: any, tab?: string) => void
  handleSelectAll: (selectAll: boolean, tab: string) => void
}

type CheckTreeDataPickerProps = (BaseCheckTreeDataPickerProps & { tabs?: never }) | CheckTreeDataPickerPropsWithTabs

type TreeNode = {
  id: string;
  children?: TreeNode[];
};

const getAllNodeIds = (nodes: TreeNode[]): string[] => {
  let allIds: string[] = [];

  nodes.forEach((node) => {
    allIds.push(node.id); // Add the current node ID
    if (node.children) {
      allIds = allIds.concat(getAllNodeIds(node.children)); // Recursively add child IDs
    }
  });

  return allIds;
};

const CheckTreeDataPicker = (props: CheckTreeDataPickerProps) => {
  const [searchKeyword, setSearchKeyword] = React.useState<string>()
  const checkTreePickerKey = React.useRef(0)
  const [selectedTab, setSelectedTab] = React.useState(props.tabs && props.tabs[0])

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [expandedIcon, setExpandedIcon] = useState<boolean>(true);

  const rowCount = React.useMemo(() => {
    if (!props.childrenKey) {
      return props.options.length
    }
    const rowCounter = props.options.reduce((rowSum, option) => {
      if (!props.childrenKey) return rowSum
      if (props.childrenKey in option) {
        rowSum += option[props.childrenKey].length
      }
      return rowSum
    }, 0)
    return rowCounter
  }, [props.options])

  const handleChange = (value: any) => {
    if (props.handleChange) {
      if (selectedTab) props.handleChange(value, selectedTab.id)
      else props.handleChange(value)
    }
  }

  const handleSelectNode = (activeNode: any, value: any) => {
    if (props.handleSelectNode) {
      if (selectedTab) props.handleSelectNode(activeNode, value, selectedTab.id)
      else props.handleSelectNode(activeNode, value)
    }
  }

  // Handle expand/collapse event
  const handleExpand = (expandItemValues: string[], activeNode: any) => {

    const topLevelParentIds = props.options.map((node) => node.id); // Return the ID of each top-level node

    const anyParenttCollapse =  topLevelParentIds.some((parentId) => expandItemValues.includes(parentId));

    if(anyParenttCollapse){
      setExpandedIcon(true)
    } else {
      setExpandedIcon(false)
    }

    setExpandedItems([...expandItemValues]);
  };

  //Handle collapse/expand from top level button in header
  const _handleExpandCollapseFromOutsideButton = () => {
    if (expandedIcon) {
      collapseAll()
    } else {
      expandAll()
    }
    setExpandedIcon(!expandedIcon)
  }

  // Collapse all nodes
  const collapseAll = () => {
    setExpandedItems(props.options);
  };

  // Expand all nodes
  const expandAll = () => {
    const allKeys: string[] = getAllNodeIds(props.options);
    setExpandedItems(allKeys);
  };

  return (
    <div className="flex flex-col w-1/2 gap-2 px-4 bg-white">
      <p
        data-testid="check-tree-data-picker-title"
        id="pendo-check-tree-data-picker-title"
        className="py-2 text-lg text-gray-800"
      >
        {props.addCollapsetoHeader && <IconButton
          className='mr-2'
          disabled={props.options.length <= 0}
          appearance="subtle"
          icon={expandedIcon ? <Icon icon="arrow-down" className='' /> : <Icon icon="arrow-right" />}
          size='xs'
          onClick={_handleExpandCollapseFromOutsideButton}
        />}
        {props.title}
      </p>
      {!props.hideSearchBox && props.handleSearch && (
        <SearchBox
          dataTestId="check-tree-data-picker-search-box"
          handleSearch={(keyword) => {
            if (keyword) checkTreePickerKey.current += 1
            setSearchKeyword((prev) => {
              return keyword
            })
            props.handleSearch && props.handleSearch(keyword)
          }}
          placeholder="Search by name"
          disabled={!searchKeyword && props.options.length <= 0}
        />
      )}
      {props.options.length ? (
        <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto">
          <div className="-mx-4">
            <DataPickerHeader
              handleSelectAll={() => {
                if (props.handleSelectAll) {
                  if (selectedTab) props.handleSelectAll(true, selectedTab.id)
                  else props.handleSelectAll(true)
                  return
                }
                const value = props.options.map((option) => option[props.valueKey])
                handleChange(value)
              }}
              handleClear={() => {
                if (props.handleSelectAll) {
                  if (selectedTab) props.handleSelectAll(false, selectedTab.id)
                  else props.handleSelectAll(false)
                  return
                }
                handleChange([])
              }}
              rowCount={props.rowCountValue ?? rowCount}
              suffixForCount={props.suffixForCount}
            />
          </div>
          {props.tabs && props.tabs.length && selectedTab && (
            <div className="flex gap-4">
              {props.tabs.map((tab) => (
                <p
                  data-testid="check-tree-data-picker-tab"
                  id="pendo-check-tree-data-picker-tab"
                  key={`${tab.id}-ad-group`}
                  onClick={() => setSelectedTab(() => tab)}
                  className={`cursor-pointer hover:bg-gray-50 p-2 m-0 ${selectedTab.id === tab.id ? 'border-b-2 border-b-sightlyBlue' : ''
                    }`}
                >
                  {tab.label}
                </p>
              ))}
            </div>
          )}

          <CheckTree
            data={props.options}
            labelKey={props.labelKey}
            valueKey={props.valueKey}
            value={selectedTab ? props.value[selectedTab.id] : props.value}
            appearance="subtle"
            key={`${props.title}_v${checkTreePickerKey.current}`}
            height={props.checkTreeHeight ?? 330}
            childrenKey={props.childrenKey}
            onChange={handleChange}
            onSelect={handleSelectNode}
            disabledItemValues={props.disabledItemValues ?? []}
            uncheckableItemValues={props.uncheckableItemValues ?? []}
            defaultExpandAll
            onExpand={handleExpand}
            expandItemValues={expandedItems}
            renderTreeNode={(nodeData: any) => {
              return (
                <Tooltip
                  disabled={!props.virtualized}
                  content={
                    <div
                      data-testid="act-mgr-target-moments-table-io-persona-io-selector-tooltip"
                      id="pendo-act-mgr-target-moments-table-io-persona-io-selector-tooltip"
                      className="text-left"
                    >
                      {nodeData[props.labelKey]}
                    </div>
                  }
                >
                  <p>{nodeData[props.labelKey]}</p>
                </Tooltip>
              )
            }}
            virtualized={props.virtualized ?? false}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p
            className="p-4"
            data-testid="check-tree-data-picker-placeholder"
            id="pendo-check-tree-data-picker-placeholder"
          >
            {props.placeholder}
          </p>
        </div>
      )}
    </div>
  )
}

export default CheckTreeDataPicker
