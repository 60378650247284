import React from 'react'
import Tooltip from '@/components/TailwindTooltip'
interface BadgeProps {
  text: string
  onClose: () => void
  disabled?: boolean
  size: 'small' | 'medium' | 'large'
  color: 'gray' | 'blue'
  truncateTextSize?: number
  closable?: boolean
  dataTestId?: string
}

const sizeConfig = {
  small: 'text-xs py-0.5 pl-2 pr-0.5',
  medium: 'text-sm py-0.5 px-2',
  large: 'text-base py-1 px-2'
}

const colorTheme = {
  gray: 'text-indigo-700 bg-slate-200',
  blue: 'text-sightlyBlue ring-1 ring-sightlyBlue'
}

export const Badge = ({ text, onClose, disabled, size, color, closable, dataTestId, truncateTextSize }: BadgeProps) => {
  let truncatedName = text
  let disableTooltip = true
  if(truncateTextSize) {
    truncatedName = text.length <= truncateTextSize ? text : text.slice(0, truncateTextSize) + '...'
    disableTooltip = text.length < truncateTextSize
  }
  if (disabled) {
    return (
      <span
        data-testid={dataTestId}
        className={`
            inline-flex items-center rounded-full font-medium max-w-fit select-none
            ${sizeConfig[size]}
            ${colorTheme[color]}
            `}
      >
        {truncatedName}
      </span>
    )
  }
  return (
    <Tooltip  
        disabled={disableTooltip}
        content={<div className="text-left">{text}</div>}
      >
    <span
      data-testid={dataTestId}
      className={`
          inline-flex items-center rounded-full max-w-fit select-none
          ${sizeConfig[size]}
          ${colorTheme[color]}
          `}
    >
      {truncatedName}

      {closable !== false && (
        <button
          onClick={onClose}
          type="button"
          className={`ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center
          rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500
          focus:text-white focus:outline-none`}
        >
          <span className="sr-only">Remove small option</span>
          <svg
            className="w-2 h-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
    </Tooltip>
  )
}
