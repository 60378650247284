import React, { ReactNode } from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import { MomentInsightsModalProps, MomentInsightsType } from './types'
import BoardsPanel from './BoardsPanel'
import InsightsPanel from './InsightsPanel'
import ModalFooter from './ModalFooter'
import { CLOSE_BUTTON_SVG_FILTER, INSIGHTS_ERROR, NO_INSIGHTS } from './constants'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import LoadingData from '@/views/Discover/Boards/components/LoadingData'

interface IMomentInsightsModalStyleWrapper {
  children: React.ReactElement
  open: boolean
  footerButtons: ReactNode[]
  handleClose: () => void
  hasClose: boolean
  closeButtonSVGFilter: string
}

const MomentInsightsModalStyleWrapper = ({
  children,
  open,
  footerButtons,
  handleClose,
  hasClose,
  closeButtonSVGFilter
}: IMomentInsightsModalStyleWrapper) => {
  return (
    <PortalModal {...{ open, footerButtons, handleClose, hasClose, closeButtonSVGFilter }}>
      <div
        data-testid="moment-insights-modal"
        className="w-[70vw]"
      >
        <header
          data-testid="moment-insights-modal-header"
          className="bg-sightlySlate py-1 px-8 -m-6 rounded-t-[10px] h-16 flex items-center"
        >
          <span className="text-lg font-semibold text-gray-200">Why this moment ?</span>
        </header>
        {children}
      </div>
    </PortalModal>
  )
}

export default function MomentInsightsModal({
  show,
  handleClose,
  momentInsights: momentInsightsList,
  trendingMoments,
  momentInsightsError
}: MomentInsightsModalProps) {
  const [_momentInsights, setMomentInsights] = React.useState<MomentInsightsType[] | undefined>()
  const [activeIndex, setActiveIndex] = React.useState<number>(-1)
  const [activeBoardId, setActiveBoardId] = React.useState<string>('')
  const { boardIds: selectedBoardIds } = useSearch<MyLocationGenerics>()

  React.useEffect(() => {
    setMomentInsights((prev) => momentInsightsList?.momentInsights)
  }, [momentInsightsList])

  const boardIds = React.useMemo(() => _momentInsights?.map(({ boardId }) => boardId) || [], [_momentInsights])

  React.useEffect(() => {
    if (!_momentInsights || (!_momentInsights.length && !momentInsightsList?.isTrendingMoment)) {
      setActiveIndex(-1)
      return
    }
    if (trendingMoments) {
      if (!_momentInsights.length && momentInsightsList?.isTrendingMoment) {
        // adding top moments
        setMomentInsights(
          (prev) =>
            prev &&
            prev.concat({
              boardId: trendingMoments.id.toString(),
              boardName: trendingMoments.name,
              clusterId: 0,
              insights: []
            })
        )
        setActiveIndex(0)
        return
      }
      const topMomentInsight = _momentInsights?.find((insight) => insight.boardId === trendingMoments.id.toString())
      if (!topMomentInsight && momentInsightsList?.isTrendingMoment) {
        // adding top moments
        setMomentInsights(
          (prev) =>
            prev &&
            prev.concat({
              boardId: trendingMoments.id.toString(),
              boardName: trendingMoments.name,
              clusterId: _momentInsights[0].clusterId,
              insights: []
            })
        )
        setActiveIndex(_momentInsights.length - 1)
      }
    }
    const active = _momentInsights.findIndex(
      ({ boardId }) => selectedBoardIds && selectedBoardIds.includes(parseInt(boardId))
    )
    setActiveIndex(active)
  }, [_momentInsights])

  React.useEffect(() => {
    activeBoardId && setActiveIndex(boardIds.indexOf(activeBoardId))
  }, [activeBoardId])

  React.useEffect(() => {
    _momentInsights && activeIndex >= 0 && setActiveBoardId(_momentInsights[activeIndex]?.boardId)
  }, [_momentInsights, activeIndex])

  const handleFooterNav = (type: 'prev' | 'next') => {
    setActiveIndex(boardIds.indexOf(activeBoardId))
    if (type === 'prev') {
      if (activeIndex === 0) return
      setActiveIndex((prevIndex) => prevIndex - 1)
      setActiveBoardId((prevId) => boardIds[activeIndex - 1])
      return
    }
    if (activeIndex === boardIds.length - 1) return
    setActiveIndex((prevIndex) => prevIndex + 1)
    setActiveBoardId((prevId) => boardIds[activeIndex + 1])
    return
  }

  if (momentInsightsError) {
    return (
      <MomentInsightsModalStyleWrapper
        open={show}
        handleClose={handleClose}
        hasClose={true}
        footerButtons={[]}
        closeButtonSVGFilter={CLOSE_BUTTON_SVG_FILTER}
      >
        <main
          className="min-h-[60vh] flex mt-6"
          data-testid="moment-insights-modal-main"
        >
          <p className="p-2 m-auto text-2xl text-gray-800">{INSIGHTS_ERROR}</p>
        </main>
      </MomentInsightsModalStyleWrapper>
    )
  }

  if (_momentInsights && !_momentInsights.length && activeIndex < 0) {
    return (
      <MomentInsightsModalStyleWrapper
        open={show}
        handleClose={handleClose}
        hasClose={true}
        footerButtons={[]}
        closeButtonSVGFilter={CLOSE_BUTTON_SVG_FILTER}
      >
        <main
          className="min-h-[60vh] flex mt-6"
          data-testid="moment-insights-modal-main"
        >
          <p className="p-2 m-auto text-2xl text-gray-800">{NO_INSIGHTS}</p>
        </main>
      </MomentInsightsModalStyleWrapper>
    )
  }

  return (
    <MomentInsightsModalStyleWrapper
      open={show}
      handleClose={handleClose}
      hasClose={true}
      footerButtons={
        !_momentInsights?.length
          ? []
          : [
              <ModalFooter
                maxLength={_momentInsights.length - 1}
                activeIndex={activeIndex}
                handleFooterNav={handleFooterNav}
              />
            ]
      }
      closeButtonSVGFilter={CLOSE_BUTTON_SVG_FILTER}
    >
      <main
        data-testid="moment-insights-modal-main"
        className="min-h-[60vh] flex mt-6 -mr-11"
      >
        {!_momentInsights ? (
          <div className="m-auto">
            <LoadingData message="Loading..." />
          </div>
        ) : (
          _momentInsights.length && (
            <>
              <BoardsPanel
                activeBoardId={activeBoardId}
                setActiveBoardId={setActiveBoardId}
                boards={_momentInsights.map(({ boardName, boardId }) => ({ boardName, boardId }))}
              />
              <InsightsPanel
                activeBoardId={activeBoardId}
                momentInsights={_momentInsights}
              />
            </>
          )
        )}
      </main>
    </MomentInsightsModalStyleWrapper>
  )
}
