declare global {
    interface Window { zE: any; } // Ignore window.zE method in TypeScript linter
}

import { useEffect } from 'react';


function hideZendeskButton() {
    window.zE(function() {
        window.zE.hide()
    })
}

export function openZendeskChat() {
    window.zE(function() {
        /**
         * If Zendesk button is hidden, need to show it before the chat
         * window can be opened
         */
        window.zE(function() {
            window.zE.show()
        })
        window.zE('webWidget', 'open');
    })
}

export function setZendeskPosition(navbarExpanded: boolean) {
    const positionConfig = {
        position: { horizontal: 'left', vertical: 'bottom'},
        offset: { horizontal: '190px', vertical: '50px' },
        zIndex: 500,
    };

    if (!navbarExpanded) {
        positionConfig.offset.horizontal = '100px'
    }

    window.zE('webWidget', 'updateSettings', {
        webWidget: positionConfig
    });
}


export default function ZendeskWidget() {
    useEffect(() => {
        const zdScript = document.createElement('script');
        zdScript.type = 'text/javascript';
        zdScript.id = 'ze-snippet';
        zdScript.async = false;
        zdScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=f40d71b2-a7bc-4f67-bc9c-88e15db7a25f';
        zdScript.onload = function() {
            hideZendeskButton();
            setZendeskPosition(true)

            /**
             * Event listener to hide Zendesk button
             * when chat widget close action is triggered
             */
            window.zE('webWidget:on', 'close', function() {
                hideZendeskButton();
            });
        };

        document.getElementsByTagName('head')[0].appendChild(zdScript);
    }, []); // Script runs once on mount

    return null
}
