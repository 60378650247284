import { combineReducers } from 'redux'
import {
    evergreenChannels,
    evergreenChannelsLoading,
    evergreenChannelCount,
    currentEvergreenChannelsPage,
    evergreenChannelIabCategories,
    evergreenChannelsHasNextPage,
    evergreenChannelArchiving,
    evergreenChannelSaving
} from './evergreenChannels'

export default combineReducers({
    evergreenChannels,
    evergreenChannelsLoading,
    evergreenChannelCount,
    currentEvergreenChannelsPage,
    evergreenChannelIabCategories,
    evergreenChannelsHasNextPage,
    evergreenChannelArchiving,
    evergreenChannelSaving,
})
