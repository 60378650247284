import React from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton';
import alertModalIcon from '@/assets/img/tiktok/hashtagAlertModalImage.svg';
import { useNavigate } from '@tanstack/react-location';
import {  Modal } from 'rsuite';
import { PATH_ACTIVATIONS_CAMPAIGNS, PATH_INTEGRATIONS } from '@/routes';

type IProps = {
    open: boolean
    handleClose: () => void
    authentication: boolean
}

export const AlertModal = ({ open, handleClose, authentication }: IProps) => {
    const navigate = useNavigate()

    const handleAuthenticationErrorNavigation = () => {
        navigate({
            to: PATH_INTEGRATIONS,
            search: (prev: any) => {
              return {
                ...prev
              }
            }
          })
    }
    const handleCampaignErrorNavigation = () => {
        navigate({
            to: PATH_ACTIVATIONS_CAMPAIGNS,
            search: (prev: any) => {
              return {
                ...prev
              }
            }
          })
    }

    return (
        <Modal
            data-testid='new-board-from-template-modal'
            className='items-center justify-center p-0 no-padding-modal'
            show={open}
            onHide={handleClose}
            style={{
                display: "flex",
            }}
        >
            <div className='w-[344px] p-4 h-[284px]'>
                <div className='w-full flex flex-col justify-center items-center'>
                    <img data-testid={'alert-modal-icon'} src={alertModalIcon} alt={'alert-modal-icon'} className="w-12 h-12" />
                    <div className='mt-3'>
                        {authentication
                            ? <p data-testid='modal-title-auth-req' className='font-bold text-lg'>
                                Authentication Required
                            </p>
                            : <p data-testid='modal-title-import-campaigns' className='font-bold text-lg'>
                                Import Campaigns Required
                            </p>
                        }
                    </div>
                    <div className='w-[312px] mt-2'>
                        {authentication
                            ? <p data-testid='modal-text-auth-req' className='font-normal text-sm'>
                                To target hashtags, please complete steps to link your ads account, & import campaigns
                            </p>
                            : <p data-testid='modal-text-import-campaigns' className='font-normal text-sm'>
                                To target hashtags, please import your campaigns.
                            </p>
                        }
                    </div>

                </div>
                <div className='w-full flex justify-center mt-5'>
                    <SightlyButton
                        datatestid={'tiktok-import-campaigns-modal-import-button'}
                        id="tiktok-import-campaigns-modal-import-button"
                        type='purple'
                        handleClick={authentication ? handleAuthenticationErrorNavigation : handleCampaignErrorNavigation}
                        text={authentication ? 'Set Up Now' : 'Import Now'}
                        block={true}
                    />
                </div>
                <div className='w-full flex justify-center mt-4'>
                    <SightlyButton
                        datatestid={'tiktok-import-campaigns-modal-import-button'}
                        id="tiktok-import-campaigns-modal-import-button"
                        type='secondary'
                        handleClick={handleClose}
                        text="Cancel"
                        block={true}

                    />
                </div>
            </div>
        </Modal>
    )
}
