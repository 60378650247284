export const OVERVIEW_SIDE_PANEL_HEADER_TITLE = 'Overview'

export const OVERVIEW_SIDE_PANEL_TAB_IDS = {
  TAB_NARRATIVE: 'tab_narrative',
  TAB_PLATFORMS: 'tab_platforms',
  TAB_THEMES: 'tab_themes',
}

export const OVERVIEW_SIDE_PANEL_TAB_OPTIONS = [
  {
    name: 'Narratives',
    id: OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_NARRATIVE
  },
  {
    name: 'Platforms',
    id: OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_PLATFORMS
  },
  {
    name: 'Themes',
    id: OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_THEMES
  },
]

export const OVERVIEW_SIDE_PANEL_DEFAULT_TAB_ID = 'tab_narrative'

export const SOCIAL_NARRATIVE_PANEL_HEADER_TITLE = 'Overview';

export const SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS = [
  {
    name: 'Overview',
    id: 'social_tab_overview'
  },
  {
    name: 'Posts',
    id: 'social_tab_post'
  },
  {
    name: 'Platforms',
    id: 'social_tab_platforms'
  },
  {
    name: 'Themes',
    id: 'social_tab_themes'
  },
];

export const SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID = 'social_tab_overview';

export const THUMBS_UP_LIMIT = 10;
export const THUMBS_DOWN_LIMIT = 10;