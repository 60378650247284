import React from 'react'
import loadingIcon from '@/assets/img/sightly_loading_small.gif'

const LoaderIcon = () => (
  <div className="h-10">
        <img
          className="h-full"
          src={loadingIcon}
          alt="loading..."
        />
      </div>
)
export default function GeneratingData(props: { message: string; backdrop?: boolean }) {
  if (props.backdrop) {
    return (
      <div className="absolute inset-0 z-50 flex justify-center w-full h-full bg-slate-50 bg-opacity-60">
          <div className="fixed flex items-center justify-center top-1/2">
            <span className="text-lg font-medium">{props.message}</span>
            <LoaderIcon/>
        </div>
      </div>
    )
  }
  return (
    <div className="flex items-center justify-center h-1/2">
      <span>{props.message}</span>
      <LoaderIcon/>
    </div>
  )
}
