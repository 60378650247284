import { ZodType, z } from 'zod';

export interface MyProfileFormType {
    firstName: string
    lastName: string
    email: string
}

export const MyProfileFormTypeSchema: ZodType<MyProfileFormType> = z.object({
    firstName: z.string().min(1, { message: 'Please enter first name' }).max(100, { message: 'Please use a shorter name' }),
    lastName: z.string().min(1, { message: 'Please enter last name' }).max(100, { message: 'Please use a shorter name' }),
    email: z.string().email({ message: 'Enter valid email' }),
})
