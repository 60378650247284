import React from 'react'

export interface SidebarItemProps {
    displayText: string
    handleNavigation: () => void
    icon?: React.ReactElement
    dataTestId?: string
    isActive?: boolean
}

const SidebarItem = ({
    displayText,
    handleNavigation,
    icon,
    dataTestId,
    isActive = false,
}: SidebarItemProps) => {

    let activeClass = '';
    if (isActive) {
        activeClass = 'bg-sidebarActiveBlue';
    }
    
    return (
        <div>
            <button 
                className={`flex w-full px-5 py-2 ${activeClass}`}
                data-testid={dataTestId}
                onClick={handleNavigation}
            >
                <div className="w-6 h-6 mr-3 text-gray-400">
                    {icon}
                </div>
                <span className="text-base font-medium leading-6 text-gray-600">
                    {displayText}
                </span>
            </button>
        </div>
    )
}

export default SidebarItem