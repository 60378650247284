import React from 'react'
import { ListedCategoryType } from '@/views/TikTok/ActivationManager/types'
import { CheckPicker, Icon } from 'rsuite'
import DataPickerFooter from '@/components/DataPickerFooter'

export const CategoryFilter = (props: {
  categoryOptions: ListedCategoryType
  handleFilterCategory: (filteredCategoryIds: string[]) => void
}) => {
  const [filteredCategoryIds, setFilteredCategoryIds] = React.useState<string[]>()
  const handleRenderMenu = (menu: React.ReactNode) => {
    if (!props.categoryOptions || props.categoryOptions.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Icon
            icon="spinner"
            spin
          />{' '}
          Loading...
        </p>
      )
    }
    return menu
  }

  const handleChange = (value: any[]) => {
    setFilteredCategoryIds((prev) => value)
    props.handleFilterCategory(value)
  }

  const renderFooter = () => {
    if (!props.categoryOptions.length) return
    return (
      <DataPickerFooter
        rowCount={props.categoryOptions.length}
        handleClear={() => {
          handleChange([])
        }}
        handleSelectAll={() => {
          handleChange(props.categoryOptions.map(({ categoryId }) => categoryId))
        }}
      />
    )
  }

  return (
    <div
      data-testid="act-mgr-target-moment-category-filter"
    >
      <CheckPicker
        data={props.categoryOptions}
        renderMenu={(menu: React.ReactNode) => handleRenderMenu(menu)}
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Category"
        value={filteredCategoryIds}
        labelKey="categoryName"
        valueKey="categoryId"
        onChange={handleChange}
        onClean={() => handleChange([])}
        renderExtraFooter={renderFooter}
      />
      <style>
        {`
          .rs-picker-toggle .rs-picker-toggle-placeholder{
            color: unset;
          }
        `}
      </style>
    </div>
  )
}
