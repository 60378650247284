import React, { SyntheticEvent, useState } from "react";
import { Button, Modal } from 'rsuite'
import aiGenerate from '@/assets/img/aiIcon.svg'
import '@/components/Modals/AIGenerate/GenerateAIModalCustom.css'


/**
 * Modal component for bulk assignment.
 * @param {Object} props - Component props.
 * @param {boolean} props.show - Flag to control the visibility of the modal.
 * @param {() => void} props.handleApply - Function called when the apply button is clicked.
 * @param {() => void} props.handleCancel - Function called when the cancel button is clicked.
 * @param {React.ReactNode} props.header - React node representing the header of the modal.
 * @param {React.ReactNode} props.children - React node representing the content of the modal.
 * @param {string} [props.dataTestId] - Test ID for the modal component.
 * @param {'sm' | 'md' | 'lg' | 'xs'} props.size - Size of the modal ('sm' for small, 'md' for medium, 'lg' for large, 'xs' for extra small).
 * @returns {JSX.Element} The rendered component.
 */
const GenerateAIModal = (props: {
  show: boolean
  handleCancel: () => void
  handleClick: ( textAreaContent: string) => void
  header: React.ReactNode
  dataTestId?: string
}) => {
  const { show, handleCancel, handleClick, header, dataTestId } = props
  const [textAreaContent, setTextAreaContent] = useState('');
  const handleTextAreaChange = (e: any) => {
    setTextAreaContent(e.target.value);  // Update the local text area state
  };
  const handleButtonClick = (e:SyntheticEvent<Element, Event>) => {
    if (textAreaContent.trim() === '')return
    handleClick(textAreaContent.trim());  // Send the text area data to the parent on button click
  };

  return (
    <div data-testid={dataTestId}>
      <Modal
        dialogClassName={'ai_generate_modal_root'}
        show={show}
        onHide={handleCancel}
        onExit={handleCancel}
        backdrop={'static'}
        className="!bg-gradient-to-r from-green-400 to-purple-400 !p-4 !w-1/2 !h-64	 !rounded-lg !absolute !top-1/2 !left-1/2 !transform -translate-x-1/2 -translate-y-1/2"
      >
        <Modal.Header className="!pb-4 !text-center !text-white !text-3xl">{header}</Modal.Header>

        <Modal.Body className=" !w-full !h-32 !content-center !shadow-lg  !bg-white  !rounded-lg !p-4 ">
          <div className="!align-top !relative !w-full !h-full">
              <textarea
                id="generate_ai_board_prompt_text"
                data-testid='generate-ai-board-modal-textarea'
                rows={3}
                cols={40}
                placeholder=""
                className="border-none focus:ring-0 h-full w-full !text-gray-500"
                onChange={handleTextAreaChange}
              />
            <Button
              onClick={handleButtonClick}
              id="generate-ai-board-button"
              data-testid="generate-ai-board-modal-button"
              className="!flex !items-center !justify-center !absolute !bottom-0 !right-0 !p-4 !h-10 !w-28 !bg-gradient-to-r !from-green-400 !to-purple-400 !text-white !font-bold !rounded"
            >
              <img src={aiGenerate} alt="AI Generate" className="mr-2" />
              <div className="text-md">Generate</div>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default GenerateAIModal
