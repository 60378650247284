import { z } from 'zod'

export const getAllTime = (): [Date, Date] => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const startOf2022 = new Date(2022, 0, 1);

    return [startOf2022, yesterday]
}

export const getYesterday = () => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
}

export const getThisWeek = (): [Date, Date] => {
    const now = new Date()
    const startOfWeek = new Date(now)
    startOfWeek.setDate(now.getDate() - now.getDay())

    return [startOfWeek, now]
}

export const getLastWeek = (): [Date, Date] => {
    const now = new Date()
    const startOfLastWeek = new Date(now)
    startOfLastWeek.setDate(now.getDate() - now.getDay() - 7)
    const endOfLastWeek = new Date(startOfLastWeek)
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6)

    return [startOfLastWeek, endOfLastWeek]
}
export const getLastMonth = (): [Date, Date] => {
    const now = new Date()
    const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)

    return [startOfLastMonth, endOfLastMonth]
}
export const getThisMonth = (): [Date, Date] => {
    const now = new Date()
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)

    return [startOfMonth, now]
}
export const getLast30Days = (): [Date, Date] => {
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30)
    return [thirtyDaysAgo, now];
}
export type RangeType = {
    label: string
    value: () => [Date, Date]
}
export const ranges: RangeType[] = [
    {
        label: 'Today',
        value: () => [new Date(), new Date()]
    },
    {
        label: 'Yesterday',
        value: () => [getYesterday(), getYesterday()]
    },
    {
        label: 'This Week',
        value: () => getThisWeek()
    },
    {
        label: 'Last Week',
        value: () => getLastWeek()
    },
    {
        label: 'This Month',
        value: () => getThisMonth()
    },
    {
        label: 'Last 30 Days',
        value: () => getLast30Days()
    },
    {
        label: 'Last Month',
        value: () => getLastMonth()
    },
    {
        label: 'All Time',
        value: () => getAllTime()
    }
]

export const reports = [
    {
        label: 'Ad Group',
        value: 'adGroup',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Ad Group x Keyword',
        value: 'adGroupKeyword',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Ad Group x Moment x Keyword',
        value: 'adGroupMomentKeyword',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Age',
        value: 'age',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Audience',
        value: 'audience',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Campaign',
        value: 'campaign',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'CCID',
        value: 'ccid',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Client Placement',
        value: 'clientPlacement',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Creative',
        value: 'creative',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Creative x Device',
        value: 'creativeDevice',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Device',
        value: 'device',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'DCM Creative',
        value: 'dcmCreative',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'DMA Region',
        value: 'dmaRegion',
        showMessage: true,
        messageText: 'Region/GEO data may not sum up to overall campaign performance numbers due to Google Ads technical limitations.'
    },
    {
        label: 'Gender',
        value: 'gender',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Google Placement',
        value: 'placement',
        showMessage: true,
        messageText: 'Placement data may not sum up to overall campaign performance numbers due to Google Ads technical limitations.'
    },
    {
        label: 'Industry Category',
        value: 'industryCategory',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Keyword',
        value: 'keyword',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Moment',
        value: 'moment',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Moment x Keyword',
        value: 'momentKeyword',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Moment x Persona',
        value: 'momentPersona',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'News Category',
        value: 'newsCategory',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Parental status',
        value: 'parentalStatus',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona',
        value: 'persona',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Audience',
        value: 'personaAudience',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Creative',
        value: 'personaCreative',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Creative x Device',
        value: 'personaCreativeDevice',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Device',
        value: 'personaDevice',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Keyword',
        value: 'personaKeyword',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Persona x Topic',
        value: 'personaTopic',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Scenario',
        value: 'scenario',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Sightly Placement',
        value: 'sightlyPlacement',
        showMessage: false,
        messageText: ''
    },
    {
        label: 'Topic',
        value: 'topic',
        showMessage: false,
        messageText: ''
    },
]
export const granularity = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
    { label: 'Summary', value: 'summary' }
]
export const performanceMetrics = [
    { label: 'Impressions', value: 'impressions' },
    { label: 'Views', value: 'views' },
    { label: 'View Rate', value: 'viewRate' },
    { label: 'VCR', value: 'vcr' },
    { label: 'Clicks', value: 'clicks' },
    { label: 'CPM', value: 'cpm' },
    { label: 'CPV', value: 'cpv' },
    { label: 'CPC', value: 'cpc' },
    { label: 'CTR', value: 'ctr' },
    { label: 'Gross Cost', value: 'cost' },
    { label: 'Margin', value: 'margin' },
    { label: 'Video Played to 25%', value: 'q25' },
    { label: 'Video Played to 50%', value: 'q50' },
    { label: 'Video Played to 75%', value: 'q75' },
    { label: 'Video Played to 100%', value: 'q100' }
]

export const conversionMetrics = [
    { label: 'All Conversions', value: 'allConversions' },
    { label: 'Total Conversions', value: 'totalConversions' },
    { label: 'Total Conversion Rate', value: 'totalConversionRate' },
    { label: 'Total Conversion CPA', value: 'totalConversionCPA' }
]

export const ReportConfigurationSchema = z.object({
    reportName: z.string(),
    insertionOrderIds: z.array(z.number()),
    startDate: z.date().nullable(),
    endDate: z.date().nullable(),
    granularity: z.enum(['summary', 'day', 'week', 'month', 'year', '']),
    splitAdFormat: z.boolean(),
    reports: z.array(z.enum(['age', 'gender', 'persona', 'parentalStatus', 'device', 'creative', 'keyword',
        'audience', 'adGroup', 'campaign', 'ccid', 'topic', ''])),
    conversionMetrics: z.array(
        z.enum(['allConversions', 'totalConversions', 'totalConversionRate', 'totalConversionCPA'])
    ),
    performanceMetrics: z.array(
        z.enum(['impressions', 'views', 'viewRate', 'vcr', 'clicks', 'cpm', 'cpv', 'cpc', 'ctr', 'cost', 'margin', 'q25', 'q50', 'q75', 'q100'])
    )
})

export type ReportConfiguration = z.infer<typeof ReportConfigurationSchema>
