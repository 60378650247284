import React from 'react';
import BrandSection from '@/views/BrandProfiles/BrandProfile/components/Queries/Brand/BrandSection';
import QueryTypeOneSection from '@/views/BrandProfiles/BrandProfile/components/Queries/QueryTypeOneSection';
import { typeOneSections, CHILD_STEPS } from '@/views/BrandProfiles/BrandProfile/components/Queries/constants';
import { useNavigate } from '@tanstack/react-location';
import { location } from '@/RouteContainer';
import EventSection from '@/views/BrandProfiles/BrandProfile/components/Queries/EventSection';
import SocialSection from '@/views/BrandProfiles/BrandProfile/components/Queries/SocialSection';
import { ProductCountTracker } from '@/views/BrandProfiles/BrandProfile/components/ProductCountTracker';
import { products } from '@/staticData/products';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

export const Queries = (props: { path: string }) => {
  const navigate = useNavigate()

  const handleAccordionClick = (selectedPath: string) => {
    // Get the current path from the location object
    const pathsList = window.location.pathname.split('/')
    const lastPath = pathsList.pop()
    let newUrl: string
    // checks if the selectedPath accordion is open or not
    if (lastPath === selectedPath) {
      newUrl = pathsList.join('/')
    } else {
      // Adding selectedPath to the current path
      const lastIndex = location.current.pathname.lastIndexOf('/queries')
      newUrl =
        location.current.pathname.substring(
          0,
          lastIndex > -1 ? lastIndex : location.current.pathname.lastIndexOf('/')
        ) + `/queries/${selectedPath}`
    }
    // Use the navigate function to update the URL
    navigate({
      to: newUrl,
      search: (prev) => {
        return {
          ...prev
        }
      }
    })
  }

  const { activeStep,
    handleContinueClick,
    handlePreviousClick } = useSteps();

  return (
    <>
      <ProductCountTracker
        productName={products.QUERIES}
        title={'Queries'}
        additionalStyle="p-4 z-10 sticky top-0 items-center"
        dataTestId='product-count-tracker-queries'
      />
      <div className="flex flex-col h-full gap-3 p-2 bg-gray-100">
        <BrandSection
          isActive={props.path === CHILD_STEPS.brands}
          handleAccordionClick={() => {
            handleAccordionClick(CHILD_STEPS.brands)
          }}
        />
        <QueryTypeOneSection
          key={typeOneSections.influencers}
          queryType={typeOneSections.influencers}
          isActive={props.path === typeOneSections.influencers}
          handleAccordionClick={() => {
            handleAccordionClick(typeOneSections.influencers)
          }}
        />

        <EventSection
          isActive={props.path === CHILD_STEPS.events}
          handleAccordionClick={() => {
            handleAccordionClick(CHILD_STEPS.events)
          }}
        />
        <SocialSection
          isActive={props.path === CHILD_STEPS.social}
          handleAccordionClick={() => {
            handleAccordionClick(CHILD_STEPS.social)
          }}
        />
        <QueryTypeOneSection
          key={typeOneSections.philanthropic}
          queryType={typeOneSections.philanthropic}
          isActive={props.path === typeOneSections.philanthropic}
          handleAccordionClick={() => {
            handleAccordionClick(typeOneSections.philanthropic)
          }}
        />
      </div>
      <BrandProfileFooter activeStep={activeStep}
        disabled={false}
        handlePrevious={handlePreviousClick}
        handleContinue={handleContinueClick}
      />
      <style>
        {`
        .rs-panel-heading::before {
          padding: 2px 5px;
          border-radius: 4px;
          background-color: #E1E5E8;
        }
      `}
      </style>
    </>
  )
}
