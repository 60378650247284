import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useSearch } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query';

import svgLogo from '@/assets/img/sightly-logo.svg'
import defaultLoginImage from '@/assets/img/introSideImage.png'
import tiktokLoginImage from '@/assets/img/tiktoklogin.png'
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput'
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm'
import { MyLocationGenerics } from '@/classes/utils';
import { PATH_BRAND_PROFILES, PATH_INTEGRATIONS, PATH_TIKTOK_HASHTAGS, PATH_TO_ONBOARDING_HUB } from '@/routes';
import { api } from '@/api/api';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { LoginFormSchema, LoginFormType } from './types';
import useUser from "@/hooks/useUser";
import { isIframe } from '@/validations';
import { useTikTok } from '../TikTok/useTikTok';
import ACApp from '@tiktok-service/app-center-connect';

const Form = styled.form`
    div, button {
        width: 100%;
    }

    padding-top: 1em;
    gap: 1em;
`

const SightlyLogo = styled.img`
    height: 2em;
`

const SignUpHeader = styled.div`
    padding-top: 1em;
    text-align: center;
`

const SignupDiv = styled.h1`
    padding-top: .25em;
    text-align: center;
`

const StyledLink = styled(Link)`
    padding-left: .5em;
`

const MARKETING_API_AUTH_TYPE = 'MAPI'

const genericErrorMessage = 'Please try again, or contact support at support@sightly.com';

export const Login: React.FC = () => {
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState<{ open: boolean, message: string, status: number }>({ open: false, message: '', status: 0 })
    const { redirect } = useSearch<MyLocationGenerics>()
    const { data } = useUser()
    const [tikTokAuthCode, setTikTokAuthCode] = React.useState('')

    const isInTikTokIframe = isIframe()

    const { sightlyRegister, handleSubmit, formState: { isValid } } = useSightlyForm<LoginFormType>(LoginFormSchema)
    const mutation = useMutation(api.user.login, {
        onSuccess: (data) => {
            if (data.isError) {
                setShowGenericErrorDialog({ open: true, message: data.error, status: data.status })
                return
            }
        },
        onError: () => {
            setShowGenericErrorDialog({ open: true, message: genericErrorMessage, status: 0 })
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    /**
     * Route only after user authentication was successful
     * and we have the user object retrieved
     */
    if (localStorage.getItem('token') && data) {
        if (isInTikTokIframe) {
            if (data.accounts[0]?.brandProfiles?.length) {
                window.location.href = redirect || PATH_TIKTOK_HASHTAGS
            } else {
                window.location.href = redirect || PATH_BRAND_PROFILES
            }
        } else {
            const params = new URLSearchParams(window.location.search);
            if (params.has('auth_code')) {
                window.location.href = redirect || PATH_INTEGRATIONS
            } else {
                window.location.href = redirect || PATH_TO_ONBOARDING_HUB
            }
        }
    }

    /**
     * Redirect user to TikTok auth view if there is no stored token or auth code is either
     * non present.
     */
    useEffect(() => {
        if (isInTikTokIframe && !localStorage.getItem('token') && !localStorage.getItem('tiktok_access_token')) {
            const params = new URLSearchParams(window.location.search)
            const authCode = params.get('auth_code')
            if (!authCode) { // No auth code
                const app = new ACApp(process.env.TIK_TOK_APP_ID || '')
                app.redirectToAuth(MARKETING_API_AUTH_TYPE)
            } else { // Auth code needs to be validated
                setTikTokAuthCode(authCode)
            }
        }
    }, [])

    const { tikTokAccessToken } = useTikTok(tikTokAuthCode)

    /**
     * Redirect user to TikTok auth view if auth token could not be retrieved.
     */
    useEffect(() => {
        const response = tikTokAccessToken.data
        if (response && !localStorage.getItem('tiktok_access_token')) {
            if ([0, 200].includes(response.code) && response.accessToken) {
                localStorage.setItem('tiktok_access_token', response.accessToken)
                localStorage.setItem('is_tiktok_user', 'true')
            } else {
                const app = new ACApp(process.env.TIK_TOK_APP_ID || '')
                app.redirectToAuth(MARKETING_API_AUTH_TYPE)
            }

        }
    }, [tikTokAccessToken])

    return (
        <IntroLayout image={loginImage}>
            <SightlyLogo src={svgLogo} alt='logo' />
            <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Login to your account</SignUpHeader>
            {isIframe() && <SignupDiv>
                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    Or
                    <StyledLink
                        className='text-sm leading-5 font-medium text-sightlyBlue'
                        to='/signup'
                    >
                        start your 14-day free trial
                    </StyledLink>
                </p>
            </SignupDiv>
            }
            <Form
                onSubmit={onSubmit}
                className='flex flex-col justify-center items-center'
            >
                <SightlyModernFormInput {...sightlyRegister('email', 'Email address')} placeholder='you@example.com' />
                <SightlyModernFormInput {...sightlyRegister('password', 'Password')} type='password' />

                <div className="flex justify-end">
                    <Link
                        className="text-sm leading-5 font-medium text-sightlyBlue"
                        to="/resetPassword"
                    >
                        Forgot your password?
                    </Link>
                </div>

                <SightlyModernButton
                    id='submitButton'
                    type='inverted'
                    handleClick={() => undefined}
                    text='Login'
                    disabled={mutation.isLoading || !isValid}
                />

                <WarningModalTailwind
                    open={showGenericErrorDialog.open}
                    setOpen={() => setShowGenericErrorDialog({ open: false, message: '', status: 0 })}
                    title={showGenericErrorDialog.status == 401 ? 'Incorrect log-in credentials' : 'Something went wrong'}
                    subtitle={showGenericErrorDialog.message}
                />
            </Form>
        </IntroLayout>
    );
};
