import React from 'react'
import { perms } from '@/staticData/permissions';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

interface IProps {
    activeTab: string
    setActiveTab: (activeTab: string) => void
}
interface NavigationListItem {
    name: string
}

export const SideNavigation: React.FC<IProps> = ({ activeTab, setActiveTab }: IProps) => {
    const { userPermissions } = useUser();

    let listItems:NavigationListItem[] = [
      { name: 'Overview' },
      { name: 'Brand Mentality'},
      { name: 'Articles'},
      { name: 'Videos'}
    ];

    if (userCanPermissionProductQuota({
      requiredPermissionValue: perms.AYLIEN_MOMENT_KEYWORDS_READ,
      userPermissions: userPermissions,
      checkType: PermissionCheckType.PERMISSION_CHECK
    })) {
      listItems.push({ name: 'Keywords'});
    }

    return (
      <nav data-testid="moment-navbar" className="py-4 h-screen">
        <ul role="list" className="-mx-2 space-y-6">
          {listItems.map((option) => (
            <li key={option.name}>
              <div
                data-testid={`moment-navbar-tab-${option.name}`}
                onClick={() => setActiveTab(option.name)}
                className={`${activeTab === option.name ? 'font-bold' : 'font-medium'} hover:font-bold cursor-pointer py-2 pl-10 text-lg leading-6 text-gray-700`}
              >
                {option.name}
              </div>
            </li>
          ))}
        </ul>
      </nav>
    )
}
