import { z } from "zod"

export interface IProps {
  brandProfileId?: number | undefined
}
export const ResponsibilitySchema = z.object({
  id: z.number(),
  name: z.string()
})
export const MediaResponsibilityLookupSchema = z.object({
  politicalBiasPreferenceOptions: z.array(ResponsibilitySchema),
  reliabilityPreferenceOptions: z.array(ResponsibilitySchema),
})

export const MediaResponsibilitySchema = z.object({
  politicalBiasPreferences: z.array(ResponsibilitySchema),
  reliabilityPreferences: z.array(ResponsibilitySchema),
})

export interface MediaResponsibilityPostSchema{
  brandProfileId:number,
  politicalBiasPreferenceIds:number[],
  reliabilityPreferenceIds:number[],
}