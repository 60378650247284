import React from 'react'
import { brandSuccessColor } from '../../../../../assets/jss/colorConstants'
import { ScenarioThemeType } from './scenariosV2-types'
import config from '@/config'
import Progress from 'rsuite/lib/Progress'
import Divider from 'rsuite/lib/Divider'

interface IProps {
    handleContinueClick: () => void
    scenarioTheme: ScenarioThemeType
}

export const ScenarioThemeCard = ({ handleContinueClick, scenarioTheme }: IProps) => {
    const percentComplete = scenarioTheme.answeredCount / scenarioTheme.scenarios.length
    if(config.featureFlag.aiProfiles){
        return (
            <div
            data-testid="scenrario-card"
            id={`pendo-${scenarioTheme.scenarioTheme.split(' ').join('-')}`}
            className="duration-100 hover:scale-105 hover:cursor-pointer w-72 p-4 ring-1 ring-[#F2F2F2] bg-white rounded-lg"
            onClick={handleContinueClick}
        >   <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-2">
            <div
                data-testid="scenrio-card-title"
                className="flex items-center "
            >
                <div className="font-semibold text-base text-[#0D062D]">{scenarioTheme.scenarioTheme}</div>
            </div>

            <div
                data-testid="scenrio-card-description"
                className="text-sm font-normal text-sightlySlate"
            >
                {scenarioTheme.scenarioThemeDescription}
            </div>
            </div>
            <div
                data-testid="scenrio-card-percentage-complete"
                className="w-full mt-4 text-xs font-semibold text-end"
            >
                {percentComplete > 0 ? `${(percentComplete * 100).toFixed(0)} %` : `Not Started`}
            </div>
            <div data-testid="opinion-card-progress-complete">
          <Progress.Line
            percent={percentComplete * 100}
            showInfo={false}
            strokeWidth={8}
            trailColor={'rgb(243 244 246)'}
            style={{ padding: '0px' }}
            strokeColor={percentComplete === 1 ? brandSuccessColor : '#0047ff'}
          /></div>

          <Divider style={{ margin: '12px 0px' }} />
          <div className="flex justify-between text-xs font-semibold">
                <div
                    data-testid="scenrio-card-scenarios-count"
                    className="text-[#8f8f8f]"
                >
                    {scenarioTheme.scenarios.length} Scenarios
                </div>
                <div
                    data-testid="scenrio-card-button"
                    className="cursor-pointer text-sightlyBlue"
                >
                    {percentComplete === 1 ? (
                        <div>View All &rarr;</div>
                    ) : percentComplete > 0 ? (
                        <div>Continue &rarr;</div>
                    ) : (
                        <div>Get Started &rarr;</div>
                    )}
                </div>
            </div>
        </div>
            
            
        </div>
        )
    }
    return (
        <div
            data-testid="scenrario-card"
            id={`pendo-${scenarioTheme.scenarioTheme.split(' ').join('-')}`}
            style={{ width: 318, padding: 16, borderRadius: 8, border: '1px solid #F2F2F2' }}
            className="duration-100 hover:scale-105 hover:cursor-pointer"
            onClick={handleContinueClick}
        >
            <div
                data-testid="scenrio-card-title"
                style={{ display: 'flex', alignItems: 'center', gap: 16 }}
            >
                <div style={{ fontWeight: 600, fontSize: 16, color: '#0D062D' }}>{scenarioTheme.scenarioTheme}</div>
            </div>

            <div
                data-testid="scenrio-card-description"
                className="h-12 line-clamp-2"
                style={{ fontWeight: 400, fontSize: 16, color: '#333D47', marginTop: 13.5 }}
            >
                {scenarioTheme.scenarioThemeDescription}
            </div>
            <div
                data-testid="scenrio-card-percentage-complete"
                style={{
                    fontWeight: 600,
                    fontSize: 12,
                    color: '#000000',
                    marginBottom: 4,
                    width: '100%',
                    textAlign: 'end',
                    marginTop: 16
                }}
            >
                {percentComplete > 0 ? `${(percentComplete * 100).toFixed(0)} %` : `Not Started`}
            </div>
            <div
                data-testid="scenrio-card-progress-complete"
                style={{ width: 286, height: 8, borderRadius: 75, backgroundColor: '#F1F2F7' }}
            >
                <div
                    style={{
                        width: percentComplete * 286,
                        backgroundColor: percentComplete === 1 ? brandSuccessColor : '#0047ff',
                        height: 8,
                        borderRadius: 75
                    }}
                />
            </div>
            <div style={{ width: 286, border: '1px solid #EEEEEE', marginTop: 16 }}></div>
            <div
                style={{
                    display: 'flex',
                    marginTop: 16,
                    fontSize: 12,
                    fontWeight: 600,
                    justifyContent: 'space-between'
                }}
            >
                <div
                    data-testid="scenrio-card-scenarios-count"
                    style={{ color: '#8f8f8f' }}
                >
                    {scenarioTheme.scenarios.length} Scenarios
                </div>
                <div
                    data-testid="scenrio-card-button"
                    style={{ color: '#00AEEF', cursor: 'pointer' }}
                >
                    {percentComplete === 1 ? (
                        <div>View All &rarr;</div>
                    ) : percentComplete > 0 ? (
                        <div>Continue &rarr;</div>
                    ) : (
                        <div>Get Started &rarr;</div>
                    )}
                </div>
            </div>
        </div>
    )
}
