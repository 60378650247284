export const STRIPE_PRICES = {
  TEST_BASE_PER_MONTH: 'price_1Pi1ShFFKmXVA2btpbZHTfHt',
  TEST_PREMIUM_PER_MONTH: 'price_1Pi1T5FFKmXVA2btEoWQCNQo',
  LIVE_BASE_PER_MONTH: 'price_1Pg63YFFKmXVA2btUwPU6NHr',
  LIVE_PREMIUM_PER_MONTH: 'price_1Pg65MFFKmXVA2btNKsBs1kU',
}

export type StripePrice =
  typeof STRIPE_PRICES['TEST_BASE_PER_MONTH'] |
  typeof STRIPE_PRICES['TEST_PREMIUM_PER_MONTH'] |
  typeof STRIPE_PRICES['LIVE_BASE_PER_MONTH'] |
  typeof STRIPE_PRICES['LIVE_PREMIUM_PER_MONTH']


export type StripeSubscription = 'base' | 'premium'
