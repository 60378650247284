import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import React from 'react'

interface IProps {
  growth: number
}

const Growth = ({ growth }: IProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {growth < 0 ? (
          <ArrowDownIcon className="h-4 stroke-2 text-red-400 font-bold inline"></ArrowDownIcon>
        ) : growth > 0 ? (
          <ArrowUpIcon className="h-4 stroke-2 text-green-400 font-bold inline"></ArrowUpIcon>
        ) : (<></>)
        }
        <span className={`font-semibold text-md ${growth < 0 ? "text-red-400" : growth > 0 ? "text-green-400" : ""}`} data-testid="conversation-overview-growth">
          {`${growth}%`}
        </span>
      </div>
      <div className="flex gap-2 items-center">
        <span className='text-xs text-gray-400'>Growth</span>
      </div>
    </div>
  )
}

export default Growth
