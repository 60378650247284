import React from 'react';
import Whisper from 'rsuite/lib/Whisper';
import Tooltip from 'rsuite/lib/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface OpinionDescriptionHoverableProps {
  description?: string | null
}

export const OpinionDescriptionHoverable = ({ description }: OpinionDescriptionHoverableProps) => {
  return <>
    {description && 
      <Whisper
        delayShow={0}
        delayHide={0}
        trigger='hover'
        placement='auto'
        speaker={
          <Tooltip>
            <p
              data-testid={`opinion-description-hoverable-tooltip-${description.slice(0, 20)}`}
              className='mt-2 mb-2'
            >
              {description}
            </p>
          </Tooltip>
        }
      >
        <InformationCircleIcon className="h-6"
          data-testid={`opinion-description-hoverable-icon-${description.slice(0, 20)}`}
        />
      </Whisper>
    }
  </>
}