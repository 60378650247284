import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { logError } from '@/utils';
import { isAxiosError } from '@/utils_ts';
import { useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { api } from '@/api/api';

export default function useAccount() {
  // Build query key for user queries
  // This query key is required to preserve the functionality
  // of marking user queries as stale when accounts are updated
  const search = useSearch<MyLocationGenerics>();
  const accountIdFromParam = Number(search.accountId);
  const queryClient = useQueryClient();
  const userId = Number(localStorage.getItem('userId'));
  const QUERY_KEY = rqKeys.userKey(userId, accountIdFromParam);

  const createAccountMutation = useMutation(api.account.create, {
    onSettled: () => {
      // mark user queries as stale given an account has been created
      // and accounts are embedded in our user get response
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onSuccess: () => {
      toast.success('Account created')
    }
  });

  const deleteAccountMutation = useMutation(api.account.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY).then(() => {
        toast.success('Account deleted')
      })
    },
    onError: (err) => {
      if (
        isAxiosError(err) &&
        String(err?.response?.data).includes('Unable to Delete Account that Users are linked to')
      ) {
        toast.error(String(err?.response?.data))
        logError(err)
      } else {
        toast.error('an unknown error ocurred')
        logError(err, { info: 'error deleting account' })
      }
    }
  });

  const updateAccountMutation = useMutation(api.account.update, {
    onSettled: () => {
      // mark user queries as stale given an account has been updated
      // and accounts are embedded in our user get response
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onSuccess: () => {
      toast.success('Changes saved')
    },
    onError: (err) => {
      if (isAxiosError(err)) {
        if (err.status === '401') {
          //ok
        } else {
          logError(err)
        }
      } else {
        logError(err)
      }
      toast.error('Unable to update account, please contact support for help.')
    }
  });


  return {
    createAccount: createAccountMutation.mutate,
    deleteAccount: deleteAccountMutation.mutate,
    updateAccount: updateAccountMutation.mutate
  }
}
