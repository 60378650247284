import React from 'react'
import { Modal } from 'rsuite'
import AiIcon from '@/views/BrandProfiles/BrandProfile/components/aiIcon.svg'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { AI_WARNING_LABELS } from '../../constants'

export const AIWarningModal = (props: { open: boolean; handleGenerate: () => void; handleCancel: () => void }) => {
  return (
    <div data-testid="ai-warning-modal">
      <Modal
        backdrop={'static'}
        show={props.open}
        onHide={props.handleCancel}
        size="xs"
        className="top-1/4"
      >
        <Modal.Body>
          <div className="flex flex-col gap-6">
            <div className="flex justify-around" data-testid="ai-warning-modal-icon">
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-gradient-to-b from-[#0047FF] via-[#5B32EF] to-[#333D47]">
                <img
                  src={AiIcon}
                  className="w-7 h-7"
                />
              </div>
            </div>
            <p className="text-lg font-semibold text-center text-gray-900" data-testid="ai-warning-modal-title">{AI_WARNING_LABELS.TITLE}</p>
            <div>
              <p className="flex gap-2" data-testid="ai-warning-modal-first-desc">
                <span>🚀 </span>
                <span> {AI_WARNING_LABELS.FIRST_DESCRIPTION}</span>
              </p>
            </div>

            <div>
              <p className="flex gap-2" data-testid="ai-warning-modal-second-desc">
                <span>🚨</span>
                <span> {AI_WARNING_LABELS.SECOND_DESCRIPTION}</span>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-col gap-2 align-baseline">
            <SightlyButton
              handleClick={props.handleGenerate}
              text="Generate"
              type="purple"
              datatestid='ai-warning-modal-generate-btn'
            />
            <SightlyButton
              handleClick={props.handleCancel}
              text="Cancel"
              type="secondary"
              datatestid='ai-warning-modal-cancel-btn'
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
