import React from 'react'

import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import {
  ACTIVATIONS_TABLE_CATEGORY_COL_CAPTION,
  ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION,
  ACTIVATIONS_TABLE_HASHTAG_COL_CAPTION,
  ACTIVATIONS_TABLE_AD_GROUP_COL_CAPTION
} from '@/views/TikTok/ActivationManager/constants'

import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'

const columnHeaderStyle = 'py-3.5  px-2 text-left text-xs font-semibold text-gray-500'

export const TableHeaderRow = (props: {
  selectedActivations: Set<string>
  isSelectAllChecked: boolean
  handleSelectAll: (checked: boolean) => void
  setIsSelectAllChecked: (value: React.SetStateAction<boolean>) => void
}) => {
  const { handleSelectAll, isSelectAllChecked, selectedActivations, setIsSelectAllChecked } = props
  const targetedActivationList = useTargetedActivationList()
  const isSelectAllIndeterminate =
    (selectedActivations.size ?? 0) > 0 && (selectedActivations.size ?? 0) < targetedActivationList.state.size
  const areAllActivationsSelected = selectedActivations.size === targetedActivationList.state.size

  return (
    <thead className="bg-gray-50">
      <tr key="tiktok-act-mgr-heading">
        <th
          id="activations-checkbox"
          className="w-12 p-3"
        >
          <SightlyCheckbox
            dataTestId="select-all-activations-checkbox"
            id="select-all-activations-checkbox"
            indeterminate={isSelectAllIndeterminate}
            checked={isSelectAllChecked || areAllActivationsSelected}
            handleChange={(checked: boolean) => {
              setIsSelectAllChecked(checked)
              handleSelectAll(checked)
            }}
          />
        </th>
        <th
          className={`${columnHeaderStyle} w-1/5`}
          id="activations-category"
        >
          {ACTIVATIONS_TABLE_CATEGORY_COL_CAPTION}
        </th>
        <th
          className={`${columnHeaderStyle} w-1/5`}
          id="activations-hashtag"
        >
          {ACTIVATIONS_TABLE_HASHTAG_COL_CAPTION}
        </th>
        <th
          className={`${columnHeaderStyle} bg-gray-50`}
          id="activations-campaign"
        >
          <div className="grid grid-cols-2 gap-4 ">
            <div>{ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION} </div>
            <div>{ACTIVATIONS_TABLE_AD_GROUP_COL_CAPTION} </div>
          </div>
        </th>
        <th
          className={`${columnHeaderStyle} bg-gray-50 w-[32px]`}
          id="activations-actions"
        >
          &nbsp;
        </th>
      </tr>
    </thead>
  )
}
