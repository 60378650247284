import React, { MouseEventHandler, ReactElement } from 'react'
import { Loader } from 'rsuite'

export const SelectAllMomentsPanel = ({
    allMomentsIsFetching,
    selectedMomentsAmount,
    allMomentsAmount,
    handleSelectAll
}: {
    allMomentsIsFetching: boolean
    selectedMomentsAmount: number
    allMomentsAmount: number
    handleSelectAll: MouseEventHandler<HTMLButtonElement>
  }) => {    
  if (allMomentsIsFetching) {
    return (
      <div className='w-full h-20 pt-4 pb-3 bg-gray-100 flex items-center justify-center'>
        <div className='w-full h-full bg-gray-50 rounded-xl flex items-center justify-center'>
          <Loader
            className="z-10"
            speed="slow"
            data-testid={"select_all_loader"}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className='w-full h-20 pt-4 pb-3 bg-gray-100 flex items-center justify-center'>
        <div className='w-full h-full bg-gray-50 rounded-xl flex items-center justify-center'>
          <p className='font-bold mx-1'>{selectedMomentsAmount}</p>
          moments are selected.
          <button 
            className='text-blue-600 ml-1 flex'
            onClick={handleSelectAll}
            data-testid={"select_all_link"}
          >
            Select all
            <p className='font-bold mx-1'>{allMomentsAmount}</p>
            moments available in this board
          </button>
        </div>
      </div>
    )
  }
}
