import React from 'react';

type PageProps = {
  headerName: string
  children: React.ReactElement
}

export const PageWithHeaderLayout = ({ headerName, children }: PageProps) => {
  return (
    //TODO: Move style to tailwind config
    <div style={{background: '#F6FAFE'}} className={`px-10 pt-12 h-full overflow-auto`}>
      <div className="text-2xl font-semibold mb-10">
        {headerName}
      </div>
      {children}
    </div>
  )
}
