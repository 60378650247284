import React from 'react'
import Table from 'rsuite/lib/Table'
import { TableColumnJs, formatDateyyyyMMdd } from '@/utils'
import { Button } from 'rsuite'
import TablePagination from 'rsuite/lib/Table/TablePagination'
import CampaignStatus from './CampaignStatus'

interface IProps {
    campaigns: any
    page: number
    total: number
    pageSize: number
    setCampaignToBeDeleted: (campaignId: number) => void
    onChangePageSize: (size: number) => void
    onChangePage: (page: number) => void
}

const ImportedCampaignsTable = ({ campaigns, page, total, pageSize, setCampaignToBeDeleted, onChangePageSize, onChangePage }: IProps) => {
    return (
        <>
            <Table
                loading={false}
                autoHeight
                data={campaigns}
                bordered
                className='shadow-md rounded-md my-4 px-2'
                data-testid="activations-campaigns-results-table"
            >
                <TableColumnJs flexGrow={6}>
                    <Table.HeaderCell className="uppercase">Campaign name</Table.HeaderCell>
                    <Table.Cell className="font-bold">
                        {(rowData: { id: string, campaignName: string }) => {
                            return <p data-testid={`activations-campaigns-results-table-name-column-${rowData.id}`}>{rowData.campaignName}</p>
                        }}
                    </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={2}>
                    <Table.HeaderCell className="uppercase">Advertisers</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData: { id: string, advertiserName: string }) => {
                            return <p data-testid={`activations-campaigns-results-table-advertisers-column-${rowData.id}`}>{rowData.advertiserName}</p>
                        }}
                    </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell className="uppercase">Create time</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData: { id: string, createTime: string }) => {
                            const formattedDate = formatDateyyyyMMdd(new Date(rowData.createTime))
                            return <p data-testid={`activations-campaigns-results-table-create-time-column-${rowData.id}`}>{formattedDate}</p>
                        }}
                    </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell className="uppercase">Status</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData: { id: string, operationStatus: string }) => {
                            return (
                                <CampaignStatus
                                    active={rowData.operationStatus.toUpperCase() === 'ENABLE'}
                                    dataTestId={`activations-campaigns-results-table-status-column-${rowData.id}`}
                                />
                            )
                        }}
                    </Table.Cell>
                </TableColumnJs>

                <TableColumnJs flexGrow={1}>
                    <Table.HeaderCell className="uppercase"></Table.HeaderCell>
                    <Table.Cell>
                        {(rowData: { id: number }) => {
                            return (
                                <Button
                                    data-testid="activations-campaigns-results-table-delete-column"
                                    appearance="link"
                                    onClick={() => setCampaignToBeDeleted(rowData.id)}
                                >
                                    Delete
                                </Button>
                            )
                        }}
                    </Table.Cell>
                </TableColumnJs>
            </Table>
            <TablePagination
                lengthMenu={[
                    {
                        value: 10,
                        label: 10
                    },
                    {
                        value: 20,
                        label: 20
                    }
                ]}
                onChangeLength={onChangePageSize}
                last={page == (total / pageSize)}
                next={page < (total / pageSize)}
                renderTotal={() => {
                    return <div>Page {page}</div>
                }}
                activePage={page}
                displayLength={pageSize}
                onChangePage={onChangePage}
            />
        </>
    )
}

export default ImportedCampaignsTable