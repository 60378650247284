import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FinalActivationType } from '@/views/TikTok/ActivationManager/types'
import { api } from '@/api/api'
import toast from 'react-hot-toast'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SelectedHashtagType } from '@/views/TikTok/Hashtags/types'
import { logError } from '@/utils'
import { SubmitActivationsType } from '@/views/TikTok/Activations/types'

export const useSubmitTargetActivations = (props: {
  accountId: number | undefined
  setOpen: (val: boolean) => void
  setSelectedHashtags: React.Dispatch<React.SetStateAction<SerializableMap<string, SelectedHashtagType>>>
  updateActivationStatus: (data: SubmitActivationsType) => void
}) => {
  const { accountId, setOpen, setSelectedHashtags, updateActivationStatus } = props
  if (!accountId) return
  const queryClient = useQueryClient()

  return useMutation(
    ({ activations }: { activations: FinalActivationType[] | undefined }) =>
      api.tikTok.submitActivations(accountId!, activations),
    {
      onSettled: () => {},
      onSuccess: (data) => {
        toast.success('Targeted TikTok Activations are submitted.')
        updateActivationStatus(data)
        setOpen(false)
        setSelectedHashtags(() => new SerializableMap<string, SelectedHashtagType>())
      },
      onError: (err) => {
        logError(err, { info: 'Failure submitting tikTok activations ' })
      }
    }
  )
}
