import React from "react"
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button } from '@/views/Discover/Moments/v2/TargetMoment/ToolBar'

export const BulkOpsButtons = (props: {
  selectedActivationCount: number
  selectedActivations: Map<string, Set<number>> | Set<string>
  setShowBulkAssignmentModal: (value: React.SetStateAction<boolean>) => void
  handleBulkDeleteMoments: () => void
}) => (
  <div className="flex justify-between mx-6 rounded-lg bg-gray-50">
    <div className="flex justify-start gap-2">
      <p
        data-testid="act-mgr-selected-target-count"
        id="act-mgr-selected-target-count"
        className="p-4 pr-0 m-auto"
      >
        <strong>{props.selectedActivationCount}</strong> selected
      </p>
      <div className="px-2 py-4">
        <Button
          dataTestId="act-mgr-edit-assignments-btn"
          icon={<PencilSquareIcon className="w-6 h-w-6" />}
          label="Edit Assignments"
          disabled={props.selectedActivations.size <= 0}
          handleClick={() => {
            props.setShowBulkAssignmentModal(true)
          }}
        />
      </div>
    </div>
    <div className="px-2 py-4">
      <Button
        dataTestId="act-mgr-bulk-delete-btn"
        icon={<TrashIcon className="w-6 h-w-6" />}
        handleClick={() => props.handleBulkDeleteMoments()}
        disabled={props.selectedActivations.size <= 0}
        label="Delete Moments"
      />
    </div>
  </div>
)