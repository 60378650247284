import React from 'react';
import { IMoment, INarrative, ITopic, ITrend } from '@/views/Trends/types';
import ResultCard from '@/views/Trends/Results/components/ResultCard';
import SightlyButton from '@/components/Sightly/SightlyButton';
import ResultMomentCard from './ResultMomentCard';
import ResultSocialNarrativeCard from './ResultNarrativeCard';

interface IProps {
  results: (ITopic | ITrend | INarrative | IMoment)[];
  type: 'topic' | 'trend' | 'moment' | 'narrative';
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalItems: number;
  handlePageChange: (newPage: number) => void;
}

const ResultCardList = ({ results, type, handlePageChange, isLoading, page, pageSize, totalItems }: IProps) => {
  const renderResults = () => {
    switch (type) {
      case 'topic':
      case 'trend':
        return results.map((result) => (
          <ResultCard key={result.id} result={result as ITopic | ITrend} />
        ));

      case 'moment':
        return results.map((result) => (
          <ResultMomentCard key={result.id} moment={result as IMoment} />
        ));

      case 'narrative':
        return results.map((result) => (
          <ResultSocialNarrativeCard key={result.id} narrative={result as INarrative} />
        ));

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {renderResults()}
      {results.length > 0 && (
        <div className="flex justify-center h-10">
          <SightlyButton
            datatestid="load-more-trend-result-button"
            block
            id="loadmorebutton"
            text="Load More"
            handleClick={() => handlePageChange(page + 1)}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default ResultCardList;
