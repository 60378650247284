import { InfoPage } from "@/components/InfoPage";
import React, { useEffect, useState } from "react";
import ResultCardList from "@/views/Trends/Results/components/ResultCardList";
import { INarrative } from "@/views/Trends/types";

interface NarrativeTabProps {
    noResultsMessage: string;
    data: INarrative[];
    isLoading: boolean;
    page: number;
    pageSize: number;
    setPage: Function
}

export const NarrativeTab: React.FC<NarrativeTabProps> = ({
    noResultsMessage,
    data,
    isLoading,
    page,
    pageSize,
    setPage,
}) => {

    const [results, setResults] = useState<INarrative[]>([]); 

    useEffect(() => {
        if (data.length > 0) {
            setResults((prevResults: INarrative[]) => [...prevResults, ...data]);
        }
    }, [data]);

    const handleLoadMore = () => {
        setPage((prevPage: number) => prevPage + 1);
    };

    if (results.length > 0) {
        return (
            <ResultCardList
                results={results}
                type="narrative"
                isLoading={isLoading}
                page={page}
                totalItems={results.length || 0 }
                pageSize={pageSize}
                handlePageChange={handleLoadMore}
            />
        );
    } else {
        return (
            <div className='w-full justify-center items-center'>
                <InfoPage message={noResultsMessage} />
            </div>
        );
    }
};