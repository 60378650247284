import React from 'react'
import './Tabs.css'

class Tabs extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div
				style={{
					backgroundColor: 'white',
					height: 56,
					backgroundColor: this.props.backgroundColor
						? this.props.backgroundColor
						: undefined
				}}
				className='Tabs'
			>
				{React.Children.map(this.props.children, (child, i) => {
					let className = `Tabs__Tab`
					if (!child) {
						return <div />
					}
					if (child.key === this.props.active) {
						className = `${className} Tabs__Tab--active`
					}
					return (
						<div
							className={className}
							onClick={() => {
								this.props.onChange(child.key)
							}}
						>
							{child}
						</div>
					)
				})}
			</div>
		)
	}
}

export default Tabs
