import React from "react";
import { DateRangePicker } from "rsuite";

export default function DateRangeSection({ formValues, ranges, handleDateRangeChange }) {
    const ref = React.useRef()

    return (
      <div data-testid='div-report-generator-select-date-range'>
        <h5>6. Select your Date Range</h5>
        <label
          htmlFor=""
          className="pl-4 text-gray-500"
        >
          {' '}
          Choose the dates you want to run this report for
        </label>
        <div className="pl-4 relative" ref={ref}>
          <DateRangePicker
            container={() => ref.current}
            ranges={ranges}
            defaultValue={[formValues.startDate, formValues.endDate]}
            onChange={(dates) => handleDateRangeChange(dates)}
            placeholder="Select Date(s)"
            placement="autoVerticalStart"
          />
        </div>
      </div>
    )
}
