import React from 'react'
import { FaPlus } from 'react-icons/fa'
import SightlyButton from '@/components/Sightly/SightlyButton'

interface IProps {
    showImportCampaignModal: () => void
    disabled: boolean
}

const NoImportedCampaigns = ({ showImportCampaignModal, disabled }: IProps) => {
    return (

        <div data-testid="activations-campaigns-zero-results" className="flex h-full items-center justify-center">
            <div className="w-[40%] flex flex-col gap-2 items-center">
                <div className="rounded-full border-2 border-gray-200 border-dashed">
                    <FaPlus className="text-gray-500 m-4" />
                </div>
                <p className="text-xl font-bold">Import Campaigns</p>
                <p data-testid="activations-campaigns-zero-results-message" className="text-xs text-center text-gray-500">
                    Import campaigns from your ad accounts to your Brand Mentality activation manager, and
                    enables you to activate moments on these accounts.
                </p>
                <SightlyButton
                    datatestid="activations-campaigns-zero-results-show-connected-accounts-modal-empty"
                    id="show-connected-accounts-modal-empty"
                    handleClick={showImportCampaignModal}
                    text=""
                    disabled={disabled}
                    icon={<div className="flex gap-2 items-center">Import campaigns <FaPlus /></div>}
                />
            </div>
        </div>
    )
}

export default NoImportedCampaigns