import SlideOver from '@/components/SlideOver'
import VerticalTabs from '@/components/VerticalTabs'
import React from 'react'

type TabType = { name: string; id: string }

type SidePanelProps = {
  /** Title to be displayed in the header of the SidePanel */
  headerTitle: string
  /** Boolean to control whether the SidePanel is open or closed */
  open: boolean
  /** Function to be called when the SidePanel is requested to hide */
  onHide: () => void
  /** Array of tab options to be displayed in the VerticalTabs component */
  tabOptions: Array<TabType>
  /** ID of the currently active tab */
  activeTabId: string
  /** Function to set the active tab ID */
  setActiveTabId: React.Dispatch<React.SetStateAction<string>>
  /** React node to be rendered as the content of the SidePanel */
  children: React.ReactNode
  /** Optional data test ID for testing purposes */
  dataTestId?: string
}

const SidePanel = ({
  dataTestId,
  headerTitle,
  onHide,
  open,
  activeTabId,
  tabOptions,
  setActiveTabId,
  children
}: SidePanelProps) => {
  return (
    <SlideOver
      dataTestId={dataTestId ?? 'conversation-drawer'}
      headerBackgroundColor="bg-white"
      headerForegroundColor='text-gray-900'
      headerTitle={headerTitle}
      show={open}
      onHide={onHide}
      maxOffset="256px - 7em"
      minOffset="7em"
      showToggleFullScreenButton={false}
    >
      <div className="flex h-full gap-3 bg-gray-200">
        <VerticalTabs
          active={activeTabId}
          onChange={(activeTabId: string) => {
            setActiveTabId(activeTabId)
          }}
        >
          {tabOptions.map(({ name, id }) => (
            <div
              data-testid="moment-insights-modal-boardName"
              className="pr-6 text-sm break-words"
              key={id}
            >
              {name}
            </div>
          ))}
        </VerticalTabs>

        <div className="w-full p-2 m-4 overflow-auto bg-white rounded-md h-screen-minus-8em">{children}</div>
      </div>
    </SlideOver>
  )
}

export default SidePanel
