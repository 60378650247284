import React, { useState } from 'react';
import { ReportFiltersItems } from '@/views/ActivationReports/components/ReportFiltersItems'
import SearchBox from '@/components/SearchBox';
import { ReportTemplate, templateGenericDimensionOptions, templateMetricOptions, templateConversionOptions } from '@/views/ActivationReports/types/activation-report.types';

interface IProps {
    tableData: any[]
    handleTableData: Function
    reportTemplate: ReportTemplate
}

export const ReportFilters = ({ tableData, handleTableData, reportTemplate }: IProps) => {

    const [dimensions, setDimensions] = useState(reportTemplate.dimensions.sort((a,b) => a.name.localeCompare(b.name)))
    const [metrics, setMetrics] = useState(reportTemplate.metrics.sort((a,b) => a.name.localeCompare(b.name)))
    const [conversions, setConversions] = useState(reportTemplate.conversions.sort((a,b) => a.name.localeCompare(b.name)))

    const handleSearch = (keyword: string) => {
        const lowerKeyword = keyword.toLowerCase();

        const filteredDimensions = reportTemplate.dimensions.filter((dimension) =>
            dimension.name.toLowerCase().includes(lowerKeyword)
        );

        const filteredMetrics = reportTemplate.metrics.filter((metric) =>
            metric.name.toLowerCase().includes(lowerKeyword)
        );

        const filteredConversions = reportTemplate.conversions.filter((conversion) =>
            conversion.name.toLowerCase().includes(lowerKeyword)
        );

        setDimensions(filteredDimensions);
        setMetrics(filteredMetrics);
        setConversions(filteredConversions);
    }

    return (
        <div className='mx-8 mt-2'>
            <SearchBox
                dataTestId="check-tree-data-picker-search-box"
                handleSearch={handleSearch}
                placeholder="Search by name"
                className='mb-3'
            />
            <ReportFiltersItems filters={dimensions} filterName={'Dimensions'} tableData={tableData} handleDataTable={handleTableData} />
            <div className='mb-6' />
            <ReportFiltersItems filters={metrics} filterName={'Metrics'} tableData={tableData} handleDataTable={handleTableData} />
            <div className='mb-6' />
            <ReportFiltersItems filters={conversions} filterName={'Conversions'} tableData={tableData} handleDataTable={handleTableData} />
            <div className='mb-6' />
        </div>
    )
}
