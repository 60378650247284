import React from 'react'
import { Progress } from 'rsuite'
import {accentColor} from "@/assets/jss/colorConstants";

interface ProgressBarProps {
  percentage: number
  showProgressPercentage?: boolean
  text?: string
}

export const ProgressBar = ({ percentage, text, showProgressPercentage }: ProgressBarProps) => {
  return (
    <div data-testid="progress-bar">
      <Progress.Line
        percent={percentage}
        showInfo={showProgressPercentage || false}
        strokeColor={accentColor}
        style={{padding: 0}}
      />
      {text &&
        <p data-testid="progress-bar-text" className="text-gray-500">{text}</p>
      }
    </div>
  )
}
