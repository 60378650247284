import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import React from 'react'
import { aiGenerateModal } from './constants'
import AiIcon from '@/views/BrandProfiles/BrandProfile/components/aiIcon.svg'

const AIGenerateModal = (props: {
  children: React.ReactNode
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
  disableSubmit: boolean
  isLoading?: boolean
}) => {
  return (
    <PortalModal
      hasClose
      handleClose={props.handleClose}
      open={props.open}
      centerFooterButtons
      blockFooterButtons
      footerButtons={[
        <SightlyButton
          id="aiGenerate"
          selectorClass="pendo-inspireme-queries-generate"
          block
          loading={props.isLoading}
          disabled={props.disableSubmit}
          text={'Generate'}
          handleClick={props.handleSubmit}
          type="indigo"
          datatestid='brand-ai-generate-button'
        />
      ]}
    >
      <div className="w-[25vw] flex flex-col gap-2">
        <header className="flex flex-col items-center gap-3">
          <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gradient-to-b from-[#0047FF] via-[#5B32EF] to-[#333D47]">
            <img
              src={AiIcon}
              className="w-5 h-5"
            />
          </div>
          <p className="text-xs text-gray-500">{aiGenerateModal.HEADER_CAPTION}</p>
        </header>
        {props.children}
      </div>
    </PortalModal>
  )
}

export default AIGenerateModal
