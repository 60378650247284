import { userAccountAxios } from '@/axiosInstances';
import { NewBoardNlpPromptFormSchema, NewBoardNlpPromptFormType } from '@/views/Discover/Boards/types';
import { BoardV1 } from '@/views/Discover/Boards/useBoardsV1';

export enum BoardType {
  General = 'general',
  Custom = 'custom',
  NaturalLanguage = 'naturalLanguage'
}

interface BoardCreatorProps {
  boardType: BoardType;
  boardName: string;
  prompt: NewBoardNlpPromptFormType;
  brandProfileId: number;
}

interface BoardGetProps {
  boardId: number;
}

interface BoardPatchProps {
  board: BoardV1
}

export const boardsV1 = {
  create: async ({ boardName, boardType, prompt, brandProfileId }: BoardCreatorProps) => {
    const url = '/v1/boards';
  
    const _board: Partial<any> = {
      boardType,
      name: boardName,
      starred: false,
      brandProfileId,
      prompt,
      defaultType: null,
      competitors: [],
      operator: 'or'
    }

    const result = await userAccountAxios.post(url, _board);
    return result.data;
  },
  getAll: async () => {
    const url = '/v1/boards';

    const result = await userAccountAxios.get(url);
    return result.data;
  },
  get: async ({ boardId }: BoardGetProps) => {
    const url = `/v1/boards/${boardId}`;

    const result = await userAccountAxios.get(url);
    return result.data;
  },
  update: async ({ board }: BoardPatchProps) => {
    const url = `/v1/boards/${board.id}`;

    const result = await userAccountAxios.patch(url, board);
    return result.data;
  }
};
