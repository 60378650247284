import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ScenarioTable } from './ScenarioTable'
import { ScenarioThemeType } from '../scenariosV2-types'

interface IProps {
    open: boolean
    onClose: () => void
    scenarioTheme: ScenarioThemeType | null
    sortBy: 'least' | 'most'
}

export const SlideOver = ({ open, onClose, scenarioTheme, sortBy }: IProps) => {
    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={onClose}
            >
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <div>
                                                    <Dialog.Title
                                                        data-testid="scenario-dialog-title"
                                                        style={{ fontWeight: 700, fontSize: 16, color: '#333D47' }}
                                                    >
                                                        {scenarioTheme?.scenarioTheme}
                                                    </Dialog.Title>
                                                    <div
                                                        data-testid="scenario-dialog-description"
                                                        style={{ fontWeight: 400, fontSize: 14, color: '#333d47' }}
                                                    >
                                                        {scenarioTheme?.scenarioThemeDescription}
                                                    </div>
                                                </div>

                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        data-testid="scenario-dialog-close-button"
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={onClose}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1">
                                            <ScenarioTable
                                                scenarioThemeId={scenarioTheme?.scenarioThemeId}
                                                sortBy={sortBy}
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
