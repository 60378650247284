import React from 'react'
import useWindowDimensions from '../../useWindowDimensions'

interface IProps {
    title: any
    sourceUrl: any
    id: string
}

export default function MetabaseIFrame({ title, sourceUrl, id }: IProps) {
    return (
      <iframe
        data-testid={"metabase-iframe"}
        id={id}
        style={{
            border: 0,
            width: '100%',
            height: useWindowDimensions().height - 56 - 48 - 24 - 24 - 24 - 12
        }}
        src={sourceUrl}
        title={title}
      >
          <p>Your browser does not support iframes.</p>
      </iframe>
    )
}
