import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import SearchBox from '@/components/SearchBox';
import useUser from '@/hooks/useUser';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { Assignees } from '@/views/Discover/Activations/v2/ToDo/Assignees';
import { RocketLaunchIcon, TrashIcon } from '@heroicons/react/24/outline';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { CheckPicker, DateRangePicker, Icon } from 'rsuite';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { ranges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import { InsertionOrder } from './InsertionOrder';
import { useFlag } from '@unleash/proxy-client-react';
const { combine, before, afterToday } = DateRangePicker;
import config from '@/config'

type ToolBarProps = {
  handleSearchMoments: (searchKey: string) => void
  assigneesOptions: {
    email: string
    fullName: string
  }[]
  assignees: string[]
  setAssignees: React.Dispatch<React.SetStateAction<string[]>>
  insertionOrderOptions?: {
    id: number
    name: string
  }[]
  insertionOrderId?: string[]
  setInsertionOrderId?: React.Dispatch<React.SetStateAction<string[]>>
  actionsEnabled?: boolean
  downloadEnabled?: boolean
  brandProfileId: number | undefined
  setIsEditOpen: (value: React.SetStateAction<boolean>) => void
  customStartDate: Date | null;
  customEndDate: Date | null;
  handleDateRangeChange: (value: ValueType) => void;
  handleTimeRangeChange: (value: number) => void;
  handleBulkDelete: () => void
  handleDownload: () => void
  areBulkOpsDisabled: boolean
}

const ToolBar = ({
  handleSearchMoments,
  assigneesOptions,
  assignees,
  setAssignees,
  insertionOrderOptions,
  insertionOrderId,
  setInsertionOrderId,
  actionsEnabled = true,
  downloadEnabled = true,
  brandProfileId,
  setIsEditOpen,
  customStartDate,
  customEndDate,
  handleDateRangeChange,
  handleBulkDelete,
  handleDownload,
  areBulkOpsDisabled,
}: ToolBarProps) => {
  const { perms, userPermissions } = useUser()
  const now = new Date();
  const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60));
  const enableIoFilter = useFlag(`enable_new_activation_mgr_${config.environment}`);
  return (
    <div className="flex justify-between">
      <div className="flex justify-around gap-2">
        <SearchBox
          dataTestId="activations-search-moments"
          handleSearch={handleSearchMoments}
          key={brandProfileId}
          placeholder="Search Moments"
        />
        <Assignees
          options={assigneesOptions}
          assignees={assignees}
          setAssignees={setAssignees}
        />
        { enableIoFilter && (
           <InsertionOrder
           options={insertionOrderOptions ?? []}
           insertionOder={insertionOrderId ?? []}
           setInsertionOder={setInsertionOrderId ?? (() => null)}
         />
        )}
        <div data-testid="date-range-picker">
        <DateRangePicker
          placeholder="Select Date Range"
          onChange={handleDateRangeChange}
          cleanable={false}
          ranges={ranges}
          disabledDate= {combine(before(sixtyDaysAgo), afterToday())}
          value={[customStartDate ?? undefined, customEndDate ?? undefined]}
        />
        </div>
      </div>

  
      <div className="flex justify-end space-x-5">
        { actionsEnabled && (  <SightlyButton
          datatestid="activations-implement-btn"
          id="activations-implement-btn"
          handleClick={() => setIsEditOpen(true)}
          text="Implement"
          disabled={areBulkOpsDisabled}
          icon={
            <RocketLaunchIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
              aria-hidden="true"
            />
          }
        />
      )}
        { actionsEnabled && (   <SightlyButton
          datatestid="activations-bulk-delete-btn"
          handleClick={handleBulkDelete}
          id="bulkDeleteActivations"
          text="Remove"
          disabled={areBulkOpsDisabled}
          icon={
            <TrashIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
              aria-hidden="true"
            />
          }
        />
      )}

      {userCanPermissionProductQuota({
          requiredPermissionValue: perms.TRENDS_DOWNLOAD,
          userPermissions: userPermissions,
          checkType: PermissionCheckType.PERMISSION_CHECK
        }) && downloadEnabled && (
          <SightlyButton
            datatestid="activations-download-btn"
            handleClick={handleDownload}
            id="downloadActivation"
            text="Download"
            disabled={areBulkOpsDisabled}
            icon={
              <ArrowDownTrayIcon
                className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                aria-hidden="true"
              />
            }
          />
        )}
        </div>
    </div>
  )
}

export default ToolBar
