import React from 'react'
import { Control, UseFormRegister } from 'react-hook-form'
import SightlyInputForm from "@/components/Sightly/SightlyFormElements/SightlyInputForm";

interface IProps {
    control: Control<any>
    disabled?: boolean
    name: string
    error?: string
    register: UseFormRegister<any>
}

const TwitterInput = ({ control, disabled, name, error, register }: IProps) => {
    return (
        <SightlyInputForm
            dataTestId={'input-website-url'}
            control={control}
            error={error}
            name={name}
            register={register}
            id="website"
            label="Website"
            disabled={disabled}
        />
    )
}

export default TwitterInput
