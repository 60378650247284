import React, {useState} from 'react';
import { PlayCircleIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { YoutubeVideoPlayer } from "@/components/YoutubeVideoPlayer"
import { Panel } from "@/views/OnboardingHub/GettingStarted/components/Panel";
import { UserCompletionStep } from '@/schemas/schemas';
import { completeGreen } from '@/assets/jss/colorConstants';

export type VideoResource = {
  icon: React.ReactNode;
  title: string;
  description: string;
  complete: boolean;
  videoId: string
}

const items: VideoResource[] = [
  {
    icon: <PlayCircleIcon className="h-8" />,
    title: 'LEARN ABOUT THE PLATFORM',
    description: 'Watch product videos to understand the Brand Mentality Platform',
    complete: false,
    videoId: 'PkTno03IMIc'
  },
  {
    icon: <PlayCircleIcon className="h-8" />,
    title: 'EXPLORE THE BRAND PROFILE',
    description: 'Everything starts with the way Brands view the world. Submit a Brand Profile to unlock the power of Brand Mentality',
    complete: false,
    videoId: 'htzyW5eI1FU'
  },
  {
    icon: <PlayCircleIcon className="h-8" />,
    title: 'TRACK & DISCOVER MOMENTS',
    description: 'Create anticipation boards to follow topics & queries that matter most',
    complete: false,
    videoId: 'xzQjd5l6mgU'
  },
];

export type ExternalResource = {
  icon: React.ReactNode;
  title: string;
  description: string;
  complete: boolean;
  videoId: string
}

const externalItems: ExternalResource[] = [
  {
    icon: <ArrowTopRightOnSquareIcon className="h-8"/>,
    title: 'LEARN HOW TO ACT IN REAL TIME',
    description: 'Explore our case studies on how Brand Mentality has helped other brands',
    complete: false,
    videoId: 'https://www.sightly.com/case-studies/'
  }
];

interface GenerateUpdatedResourcesProps {
  resources: VideoResource[] | ExternalResource[]
  completedResources: string[]
  setter: Function
}

const generateUpdatedResources = ({resources, completedResources, setter}:GenerateUpdatedResourcesProps) => {
  const updatedResources = resources.map((resource) => {
    resource.complete = completedResources.includes(resource.videoId)
    return resource;
  });
  setter(updatedResources);
}


type VideoResourcesProps =  {
  completedSteps: UserCompletionStep[] | undefined
  postUserCompletionStep: Function
  refetchUserCompletionSteps: Function
}

export const VideoResources = ({ completedSteps = [], postUserCompletionStep, refetchUserCompletionSteps }: VideoResourcesProps) => {
  const [openModal, setOpenModal] = useState(true);
  const [selectedItem, setSelectedItem] = useState<VideoResource | null>(null);
  const [videoResources, setVideoResources] = useState<VideoResource[]>(items);
  const [externalResources, setExternalResources] = useState<VideoResource[]>(externalItems);

  const hasCompletedSteps: boolean = !!(completedSteps && completedSteps?.length > 0);

  const markCompletedSteps = () => {
    const completedVideos:string[] = completedSteps?.map((step) => step.videoId) || [] as string[];
    generateUpdatedResources({
      resources: videoResources,
      completedResources: completedVideos,
      setter: setVideoResources
    });
    generateUpdatedResources({
      resources: externalResources,
      completedResources: completedVideos,
      setter: setExternalResources

    });
  }

  const saveCompletedStatus = (videoId:string) => {
    const completedIds = completedSteps.map((step) => step.videoId);
    if (!completedIds.includes(videoId)) {
      postUserCompletionStep(videoId)
        .catch(() => {})
        .then(() => {
          markCompletedSteps();
          return refetchUserCompletionSteps();
        });
    }
  };

  const handleSelect = (item: VideoResource) => {
    if (item) {
      setSelectedItem(item);
      setOpenModal(true);
      saveCompletedStatus(item.videoId);
    }
  }

  React.useEffect(() => {
    if (hasCompletedSteps) {
      markCompletedSteps();
    }
  }, [completedSteps])

  const iconCompleteStyle = { color: completeGreen, borderColor: '#fff' }
  const getIconStyle = (complete:boolean) => {
    return complete
      ? iconCompleteStyle
      : {};
  }

  return (
    <Panel
      headerText="Kick Start Your Learning"
      subHeaderText="Complete in first few days to learn how the platform works."
      dataTestId="video-resources"
    >
      <>
        {videoResources.map((item: VideoResource, index: number) => (
          <div
            key={index}
            data-testid="video-resource"
            data-test-is-seen={item.complete}
            className="flex items-center mb-6 group hover:cursor-pointer"
            onClick={() => handleSelect(item)}
          >
            <div
              className={`flex items-center justify-center group-hover:text-sightlyBlue`}
              style={getIconStyle(item.complete)}
            >
              {item.icon}
            </div>
            <div className="ml-4">
              <div className="text-gray-500 font-semibold group-hover:text-sightlyBlue">{item.title}</div>
              <div className="text-gray-500 group-hover:text-sightlyBlue">{item.description}</div>
            </div>
          </div>
        ))}

        {externalResources.map((item: VideoResource, index: number) => (
          <div
          key={`external-${index}`}
            data-testid="case-study"
            data-test-is-seen={item.complete}
            className="flex items-center group hover:cursor-pointer"
            onClick={() => {
              saveCompletedStatus(item.videoId);
              window.open(item.videoId, '_blank', 'noopener,noreferrer');
            }}
          >
            <div
              className={`flex items-center justify-center group-hover:text-sightlyBlue`}
              style={getIconStyle(item.complete)}
            >
              {item.icon}
            </div>
            <div className="ml-4">
              <div className="text-gray-500 font-semibold group-hover:text-sightlyBlue">{item.title}</div>
              <div className="text-gray-500 group-hover:text-sightlyBlue">{item.description}</div>
            </div>
          </div>
        ))}

        {selectedItem && (
          <YoutubeVideoPlayer
            dataTestId={`youtube-video-player-${selectedItem.videoId}`}
            autoplay
            isOpen={openModal}
            setIsOpen={setOpenModal}
            content={{
              title: selectedItem.title,
              subTitle: 'Approximately 2 Minutes',
              videoId: selectedItem.videoId
            }}
           />
        )
        }
      </>
    </Panel>
  )
}
