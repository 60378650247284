import React from "react"

import { SerializableMap } from "@/utils/classes/SerializableMap"
import { SerializableSet } from "@/utils/classes/SerializableSet"
import { TargetedActivationListType } from "@/views/TikTok/ActivationManager/types"
import { SelectedHashtagType } from "@/views/TikTok/Hashtags/types"
import { TargetedActivationListActionType, targetedActivationListReducer } from "@/views/TikTok/ActivationManager/reducers/activationManager.reducer"
import { useStoredTargetedActivations } from "@/views/TikTok/ActivationManager/useStoredTargetActivations"

type TargetedActivationListContextType = {
  state: SerializableMap<string, TargetedActivationListType>
  dispatch: React.Dispatch<TargetedActivationListActionType>
}

type ActivationManagerProviderProps = {
  children: React.ReactNode
  hashtags: SelectedHashtagType[]
}

const TargetedActivationListContext = React.createContext<TargetedActivationListContextType | null>(null)

export function ActivationManagerProvider({ children, hashtags }: ActivationManagerProviderProps) {
  const { storedActivationList } = useStoredTargetedActivations()
  const [targetedActivationList, dispatchTargetedActivationList] = React.useReducer(
    targetedActivationListReducer,
    getDefaultTargetedActivationList(hashtags, storedActivationList)
  )

  return (
    <TargetedActivationListContext.Provider
      value={{ state: targetedActivationList, dispatch: dispatchTargetedActivationList }}
    >
      {children}
    </TargetedActivationListContext.Provider>
  )
}

export function useTargetedActivationList() {
  const context = React.useContext(TargetedActivationListContext)
  if (context === null) {
    throw new Error('useTargetedActivationList must be used within a ActivationManagerProvider')
  }
  return context
}

const getDefaultTargetedActivationList = (hashtags:SelectedHashtagType[],
  storedActivationList: SerializableMap<string, TargetedActivationListType> | undefined
) => {
  const defaultTargetedActivationList: SerializableMap<string, TargetedActivationListType> = new SerializableMap(
    hashtags.map((hashtag) => (
      [
        hashtag.hashtagId, 
        {
          hashtagId : hashtag.hashtagId,
          hashtagName: hashtag.hashtagName,
          categoryName: hashtag.categoryName,
          campaignsToAdGroups: new SerializableMap<number, SerializableSet<number>>([[0, new SerializableSet([0])]])
        }
      ]
    ))
  )
  storedActivationList?.forEach((activation, hashtagId)=>{
    defaultTargetedActivationList.set(hashtagId, activation)
  })

  return defaultTargetedActivationList
}