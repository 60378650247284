import React from 'react'
import { ReactComponent as Save } from '../../assets/img/saveIcon.svg'
import { useKeyPress } from '../../hooks/useKeyPress'
import Input from 'rsuite/lib/Input'
import Tag from 'rsuite/lib/Tag'
import TagGroup from 'rsuite/lib/TagGroup'
import { brandPinkColor } from '../../assets/jss/colorConstants'

const SightlyCreatableTagPicker = ({ disabled, defaultItems, handleChange, id }) => {
    const [compItems, setCompItems] = React.useState([])
    const enterPressed = useKeyPress('Enter')

    React.useEffect(() => {
        handleSave()
    }, [enterPressed])

    const [defaultIsSet, setDefaultIsSet] = React.useState(false)

    React.useEffect(() => {
        if (defaultIsSet) {
            return
        }
        setCompItems(defaultItems)
        setDefaultIsSet(true)
    }, [defaultItems])

    const [val, setVal] = React.useState('')

    const handleSave = () => {
        if (!val || val === '') {
            return
        }
        const _val = JSON.parse(JSON.stringify(val))

        let _items = JSON.parse(JSON.stringify(compItems))
        _items.push(_val)
        setCompItems(_items)
        handleChange(_items)
        setVal('')
    }

    const handleRemove = text => {
        const _items = [...compItems.filter(el => text !== el)]
        setCompItems(_items)
        handleChange(_items)
    }

    return (
        <div style={{ position: 'relative', width: 300 }}>
            <div style={{ position: 'relative', width: 300 }}>
                <Input
                    id={id}
                    disabled={disabled}
                    style={{ marginBottom: 8 }}
                    value={val}
                    onChange={t => setVal(t)}
                    placeholder="Enter keywords to block"
                />
                <div style={{ position: 'absolute', right: 9, top: 10 }}>
                    <Save style={{ cursor: 'pointer' }} onClick={handleSave} />
                </div>
            </div>
            <div style={{ marginBottom: 8, marginTop: 8 }}>
                <TagGroup>
                    {compItems.map((item, index) => {
                        return (
                            <Tag
                                className="block-tag"
                                key={index}
                                closable
                                onClose={() => {
                                    handleRemove(item)
                                }}
                            >
                                {item}
                            </Tag>
                        )
                    })}
                </TagGroup>
            </div>
            <style>
                {`
					.block-tag {
						background-color: ${brandPinkColor} !important;
					}
				`}
            </style>
        </div>
    )
}

export default SightlyCreatableTagPicker
