export const neutralColor = '#fff' // process.env.REACT_APP_NEUTRAL
export const neutralLightColor = '#2f2f2f'
export const neutralExtraLightColor = '#565656'
export const accentColor = '#00aeef'
export const sightlyBlue = '#00aeef'
export const brandPinkColor = '#EC3876'
export const brandSuccessColor = '#1BD317'
export const purpleSecondaryColor = '#5B32EF'
export const neutralExtraExtraLightColor = '#7e7e7e'
export const labelColor = 'black' // '#384955'
export const bgColor = '#F0F3F5'
export const dividerColor = '#f2f2f5'
export const sightlyBlack = '#353D46'
export const darkTextColor = '#333D47'
export const darkBlueColor = '#295572'
export const darkBlueSecondaryColor = '#075985'
export const grayBackgroundLayoutColor = '#E1E5E8'
export const alertOrange = '#FF6F07'
export const completeGreen = '#4D8B31';