import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { rqKeys } from '@/views/Discover/Moments/v2/TargetMoment/hooks/rqKeys'
import { userAccountAxios } from '@/axiosInstances'
import { z } from 'zod'
import { logError } from '@/utils'

export const AssigneeSchema = z.object({
  userId: z.number(),
  fullName: z.string(),
  email: z.string(),
  userType: z.string()
})

export const AssigneesSchema = z.array(AssigneeSchema)

export type AssigneesSchemaType = z.infer<typeof AssigneesSchema>
export type AssigneeSchemaType = z.infer<typeof AssigneeSchema>

const getAssignees = async (accountId: number|undefined) => {
  try {
    if (!accountId) return Promise.reject('invalid input')
    const url = `/account/${accountId}/assignees`
    const { data } = await userAccountAxios.get(url)
    return AssigneesSchema.parse(data)
  } catch (error) {
    logError(error)
  }
}

export default function useAssignees( accountId: number | undefined ) {
  const assigneesQuery = useQuery(
    rqKeys.assigneesKey(accountId),
    () => getAssignees(accountId),
    {
      onError: (err) => {
        logError(err, { info: 'Assignees' })
      }
    }
  )
  return {
    assigneesQuery
  }
}
