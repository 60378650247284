import React, { useState } from 'react';
import SocialPostResultCard from '@/views/Trends/TrendDetails/components/SocialPostResultCard';
import { INarrative } from '@/views/Discover/Boards/types';
import { metaData } from '@/views/Trends/types';
import SearchBox from '@/components/SearchBox';
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import { ranges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import dayjs from 'dayjs';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface SocialPostListProps {
  narratives: INarrative[]
  meta: metaData
}

const SocialPostResultsList = ({ narratives, meta }: SocialPostListProps) => {
  const [filteredNarratives, setFilteredNarratives] = useState(narratives);
  const [showMoreStates, setShowMoreStates] = useState<{ [key: string]: boolean }>({});

  const handleSearch = (keyword: string) => {
    const lowerKeyword = keyword.toLowerCase();

    const newFilteredNarratives = filteredNarratives.filter((article) => article.name.toLowerCase().includes(lowerKeyword));

    setFilteredNarratives(newFilteredNarratives);
  }

  const sourceTypes = filteredNarratives
  .map((narrative) => narrative.sourceTypeList)
  .flatMap((sourceType) => (Array.isArray(sourceType) ? sourceType : [sourceType]))
  .filter((sourceType, index, arr) => arr.indexOf(sourceType) === index);

  const getLast60Days = (): [Date, Date] => {
    const now = new Date();
    const sixtyDaysAgo = new Date(now);
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    return [sixtyDaysAgo, now];
  }

  const startingRange = getLast60Days();

  const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0]);
  const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1]);

  const handleChangeDateRange = (selectedRange: any) => {
    setStartingDateObj(selectedRange[0]);
    setEndingDateObj(selectedRange[1]);

    // Filter when range is updated
    // set filtered narratives by how many are within the date range
    const newFilteredArticles = filteredNarratives.filter((narrative) => {
      const timestamp = dayjs(narrative.maxPublishDate);
      const startTime = dayjs(selectedRange[0]);
      const endTime = dayjs(selectedRange[1]);
      return timestamp.isAfter(startTime) && timestamp.isBefore(endTime);
    });

    setFilteredNarratives(newFilteredArticles);
  };

  const toggleShowMore = (sourceType: string) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [sourceType]: !prev[sourceType],
    }));
  };

  return <div>
    <div className="w-full flex justify-between">
      <div>
        <SearchBox
          dataTestId="social-post-result-list-search-box"
          handleSearch={handleSearch}
          placeholder="Search Social Posts"
          className='mb-3'
        />
      </div>
      <div>
        <DateRangeSection
          onSelectDateRange={handleChangeDateRange}
          ranges={ranges}
          rangeValue={[startingDateObj, endingDateObj]}
        />
      </div>
    </div>
    <div className="w-full flex justify-end py-2">
      Showing 1-{filteredNarratives.length} of {meta.total}
    </div>
    {sourceTypes.map((sourceType, index) => {
        const filteredNarrativesForSourceType = filteredNarratives.filter((narrative) =>
          narrative.sourceTypeList.includes(sourceType)
        );
        const showMoreButton = filteredNarrativesForSourceType.length > 4;
        const showMore = showMoreStates[sourceType] || false;

        return (
          <div key={`source-type-map-key-${index}`} className="flex flex-col mt-4">
            <h3 className="mb-2">{sourceType}</h3>
            <div className="flex flex-wrap">
              {filteredNarrativesForSourceType
                .slice(0, showMore ? filteredNarrativesForSourceType.length : 4)
                .map((narrative) => (
                  <SocialPostResultCard key={`narrative-result-${narrative.id}`} narrative={narrative} />
                ))}
              {showMoreButton && (
                <div className="flex items-center">
                  <button
                    className="rounded-full bg-[#7B61FF] h-[40px] w-[40px] flex items-center justify-center"
                    onClick={() => toggleShowMore(sourceType)}
                    data-testid="activation-reports-form-scroll-right"
                  >
                    {showMore ? (
                      <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" color="#FFFFFF" />
                    ) : (
                      <ChevronRightIcon className="w-5 h-5" aria-hidden="true" color="#FFFFFF" />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
};

export default SocialPostResultsList;