import React from 'react'
import { BoardsPanelProps } from './types'
import VerticalTabs from '@/components/VerticalTabs'
import { ReactComponent as BoardsIcon } from '@/assets/img/union.svg'
import { useNavigate } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import { PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes'

const BoardsSettingsLink = () => {
  const navigate = useNavigate<MyLocationGenerics>()

  return (
    <div className="w-full" data-testid="moment-insights-boardsIcon">
      <div
        onClick={() =>
          navigate({
            to: PATH_MOMENTS_V2_SETTINGS_REDESIGN,
            search: (old) => {
              return {
                boardsView: 'Manage Boards',
                brandProfileId: old?.brandProfileId,
                accountId: old?.accountId
              }
            }
          })
        }
        className="flex items-center justify-start w-full gap-2 p-3 cursor-pointer"
      >
        <BoardsIcon />
        <div className="text-xs font-semibold text-gray-700">Boards</div>
      </div>
    </div>
  )
}

export default function BoardsPanel({ activeBoardId, boards, setActiveBoardId }: BoardsPanelProps) {
  return (
    <div
      data-testid="moment-insights-modal-boards-panel"
      className="flex flex-col w-1/3 gap-2 pl-2 -ml-6 border-b divide-y bg-slate-100"
    >
      <BoardsSettingsLink />
      <VerticalTabs
        active={activeBoardId}
        onChange={(activeBoardId: string) => {
          setActiveBoardId(activeBoardId)
        }}
      >
        {boards.map(({ boardName, boardId }) => (
          <div
            data-testid="moment-insights-modal-boardName"
            className="pr-6 text-sm break-words"
            key={boardId}
          >
            {boardName}
          </div>
        ))}
      </VerticalTabs>
    </div>
  )
}
