import { userAccountAxios } from '@/axiosInstances'
import { competitorsObjValidation } from "@/schemas/brandProfiles";
import { Brand } from './brand-types'

export const getBrands = async (brandProfileId: number) => {
    try {
        const url = `/brand-profile/${brandProfileId}/competitors`
        const result = await userAccountAxios.get(url)

        if (result.status === 200 && result.data.length > 0) {
            competitorsObjValidation
                .validate(result.data)
                .catch(function(err) {
                    console.error(err)
                })
        }
        return result.data
    } catch (error) {
        console.error(error)
        throw error
    }
}
export const updateBrand = async (brandProfileId: number, competitorId: number, data: Brand) => {
    try {
        const url = `/brand-profile/${brandProfileId}/competitors/${competitorId}`
        await userAccountAxios.patch(url, data)
    } catch (error) {
        console.error(error)
        throw error
    }
}
export const saveBrand = async (brandProfileId: number, data: Brand) => {
    try {
        const url = `/brand-profile/${brandProfileId}/competitors`
        await userAccountAxios.post(url, data)
    } catch (error) {
        console.error(error)
        throw error
    }
}
export const deleteBrand = async (brandProfileId: number, competitorId: number) => {
    try {
        const url = `/brand-profile/${brandProfileId}/competitors/${competitorId}`
        await userAccountAxios.delete(url)
    } catch (error) {
        console.error(error)
        throw error
    }
}
