import React from 'react'
import { Link } from '@tanstack/react-location'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { api } from '@/views/Admin/Scenarios/api'
import { PostThemeSchema, PostThemeType } from '@/views/Admin/Scenarios/types'
import SightlyButton from '@/components/Sightly/SightlyButton'
import toast from 'react-hot-toast'
import { isAxiosError } from '@/utils_ts'
import { logError } from '@/utils'

export const CreateTheme = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, isValid }
  } = useForm<PostThemeType>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      description: ''
    },
    resolver: zodResolver(PostThemeSchema)
  })

  const onSubmit = (theme: PostThemeType) => {
    submitMutation.mutate(theme)
  }

  const submitMutation = useMutation(api.themes.post, {
    onSuccess: () => {
      toast.success('Changes saved')
    },
    onError: (err) => {
      if (isAxiosError<any>(err)) {
        // Access to config, request, and response
        const isDuplicateNameError = err?.response?.data.includes('already exists')
        if (isDuplicateNameError) {
          toast.error('That name is already used, please try another name')
        } else {
          toast.error('Unknown error ocurred')
          logError(err, { info: 'error posting scenario' })
        }
      } else {
        toast.error('Unknown error ocurred')
        logError(err, { info: 'error posting scenario' })
      }
    }
  })

  return (
    <div className="w-full flex justify-center items-center mt-12">
      <div className="w-3/6">
        <Link to={'../'}>Back to Themes</Link>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Create Theme
            </h2>
          </div>
        </div>
        <form
          className="w-full space-y-4 mt-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SightlyInputForm
            control={control}
            id="name"
            name="name"
            register={register}
            label="Name"
            required
            autoFocus
            error={errors?.name?.message}
          />

          <SightlyInputForm
            control={control}
            id="description"
            name="description"
            register={register}
            label="Description"
            required
            autoFocus
            error={errors?.description?.message}
            textarea
          />

          <SightlyButton
            id="submitButton"
            handleClick={() => undefined}
            text="Submit"
            disabled={!(isValid && isDirty)}
          />
        </form>
      </div>
    </div>
  )
}
