import { useQuery } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { api } from '@/api/api';

const getBpTvIos = async (brandProfileId: number | undefined) => {
    if (!brandProfileId) return []
    const ios = await api.brandProfile.ios.get(brandProfileId)
    return ios || []
}

export default function useBrandProfileInsertionOrders(brandProfileId: number | undefined) {
    const queryResult = useQuery(rqKeys.brandProfileInsertionOrders(brandProfileId), () => getBpTvIos(brandProfileId), {
        enabled: !!brandProfileId
    })

    return queryResult
}
