export const perms = {
    ASSIGNED_ACCOUNT_UPDATE: 'ASSIGNED_ACCOUNT_UPDATE',
    ACCOUNT_CREATE: 'ACCOUNT_CREATE',
    ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
    ACCOUNT_DELETE: 'ACCOUNT_DELETE',
    USER_CREATE: 'USER_CREATE',
    USER_UPDATE: 'USER_UPDATE',
    USER_DELETE: 'USER_DELETE',
    BRAND_PROFILE_CREATE: 'BRAND_PROFILE_CREATE',
    BRAND_PROFILE_UPDATE: 'BRAND_PROFILE_UPDATE',
    BRAND_PROFILE_DELETE: 'BRAND_PROFILE_DELETE',
    BRAND_PROFILE_DOWNLOAD: 'BRAND_PROFILE_DOWNLOAD',
    ACCOUNT_READ: 'ACCOUNT_READ',
    USER_READ: 'USER_READ',
    BRAND_PROFILE_READ: 'BRAND_PROFILE_READ',
    BRAND_PROFILES_PAGE_READ: 'BRAND_PROFILES_PAGE_READ',
    BRAND_PROFILE_TOPICS_READ: 'BRAND_PROFILE_TOPICS_READ',
    BRAND_PROFILE_GOOGLE_ADS_READ: 'BRAND_PROFILE_GOOGLE_ADS_READ',
    BRAND_PROFILE_CATEGORIES_READ: 'BRAND_PROFILE_CATEGORIES_READ',
    BRAND_PROFILE_KEYWORDS_READ: 'BRAND_PROFILE_KEYWORDS_READ',
    BRAND_PROFILE_IOS_READ: 'BRAND_PROFILE_IOS_READ',
    BRAND_PROFILE_IOS_UPDATE: 'BRAND_PROFILE_IOS_UPDATE',
    BRAND_PROFILE_AYLIEN_NEWS_READ: 'BRAND_PROFILE_AYLIEN_NEWS_READ',
    BRAND_PROFILE_AYLIEN_INDUSTRY_READ: 'BRAND_PROFILE_AYLIEN_INDUSTRY_READ',
    BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY: 'BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY',
    BRAND_PROFILE_ADVANCED_SETTINGS_READ: 'BRAND_PROFILE_ADVANCED_SETTINGS_READ',
    BRAND_PROFILE_SCENARIOS_V2_READ: 'BRAND_PROFILE_SCENARIOS_V2_READ',
    DISCOVER_READ: 'DISCOVER_READ',
    ENGAGE_READ: 'ENGAGE_READ',
    ADMIN_READ: 'ADMIN_READ',
    TRENDS_READ: 'TRENDS_READ', // the original trends board
    TRENDS_DOWNLOAD: 'TRENDS_DOWNLOAD',
    SMARTLIST_EDIT: 'SMARTLIST_EDIT',
    SMARTLIST_ARCHIVE: 'SMARTLIST_ARCHIVE',
    SMARTLIST_CREATE: 'SMARTLIST_CREATE',
    SMARTLIST_CREATE_UPLOAD: 'SMARTLIST_CREATE_UPLOAD',
    SMARTLIST_READ: 'SMARTLIST_READ',
    SMARTLIST_DOWNLOAD: 'SMARTLIST_DOWNLOAD',
    ACCOUNT_SETTINGS_READ: 'ACCOUNT_SETTINGS_READ',
    TREND_LABELS_POPUP_READ: 'TREND_LABELS_POPUP_READ',
    MEASURE_READ: 'MEASURE_READ',
    REPORTING_READ: 'REPORTING_READ',
    REPORTING_BRAND_MENTALITY_USAGE_READ: 'REPORTING_BRAND_MENTALITY_USAGE_READ',
    REPORTING_BRAND_MENTALITY_INSIGHTS_READ: 'REPORTING_BRAND_MENTALITY_INSIGHTS_READ',
    GARM_FEEDBACK_READ: 'GARM_FEEDBACK_READ',
    TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ: 'TRENDS_SHARE_IMPLEMENTATIONS_BUTTON_READ',
    DEMO_TRENDS_EDIT: 'DEMO_TRENDS_EDIT',
    MOMENTS_INTELLIGENCE_READ: 'MOMENTS_INTELLIGENCE_READ', //The tab to the right of the reporting tab on outcomes horizontal navbar,
    AYLIEN_MOMENT_KEYWORDS_READ: 'AYLIEN_MOMENT_KEYWORDS_READ',
    AYLIEN_BOARD_CREATE: 'AYLIEN_BOARD_CREATE',
    AYLIEN_TRENDS_READ: 'AYLIEN_TRENDS_READ', // the aylien boards page/s
    ACTIVATIONS_READ: 'ACTIVATIONS_READ',
    TRENDS_TARGET: 'TRENDS_TARGET',
    REPORTING_GENERATOR_CREATE: 'REPORTING_GENERATOR_CREATE', //tab for reporting generator tool
    INTERNAL_SUPPORT_CHAT_PERMISSION: 'INTERNAL_SUPPORT_CHAT_PERMISSION', // Jira widget
    EXTERNAL_SUPPORT_CHAT_PERMISSION: 'EXTERNAL_SUPPORT_CHAT_PERMISSION', // Zendesk widget
    AI_CREDITS: 'AI_CREDITS',
    ONBOARDING_HUB_READ: 'ONBOARDING_HUB_READ',
    TIKTOK_HASHTAGS_DASHBOARD_READ: 'TIKTOK_HASHTAGS_DASHBOARD_READ',
    TIKTOK_ACTIVATIONS_READ: 'TIKTOK_ACTIVATIONS_READ',
    TIKTOK_ACTIVATIONS_UPDATE: 'TIKTOK_ACTIVATIONS_UPDATE',
    TIKTOK_CAMPAIGNS_READ: 'TIKTOK_CAMPAIGNS_READ',
    TIKTOK_CAMPAIGNS_UPDATE: 'TIKTOK_CAMPAIGNS_UPDATE',
    TIKTOK_INTEGRATIONS_READ: 'TIKTOK_INTEGRATIONS_READ'
}
