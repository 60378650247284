import React from 'react'
import { brandSuccessColor } from '@/assets/jss/colorConstants'
import { OpinionsThemeType } from './opinions-types'
import { Divider, Progress } from 'rsuite'
import { ReactComponent as GarmFlagIcon } from '@/assets/img/GarmFlagIcon.svg';

interface IProps {
  handleContinueClick: () => void
  opinionTheme: OpinionsThemeType
}

export const OpinionThemeCard = ({ handleContinueClick, opinionTheme }: IProps) => {
  const answeredCount = opinionTheme.opinions.reduce(
    (ansCount, opinion) => (opinion.opinionResponseId ? ansCount + 1 : ansCount),
    0
  );

  const percentComplete = answeredCount / opinionTheme.opinions.length;

  return (
    <div
      data-testid="opinion-card"
      id={`pendo-${opinionTheme.opinionTypeName.split(' ').join('-')}`}
      className="duration-100 hover:scale-105 hover:cursor-pointer w-72 p-4 ring-1 ring-[#F2F2F2] bg-white rounded-lg"
      onClick={handleContinueClick}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-2">
          <div
            data-testid="opinion-card-title"
            className="flex items-center "
          >
            <div className="font-semibold text-base text-[#0D062D]">
              {opinionTheme.opinionTypeName === 'Sensitive Topics' &&
                <GarmFlagIcon className="inline mr-2" />
              }
              {opinionTheme.opinionTypeName}
            </div>
          </div>
          <div
            data-testid="opinion-card-description"
            className="text-sm font-normal text-sightlySlate"
          >
            {opinionTheme.descriptionText}
          </div>
        </div>

        <div className="flex flex-col">
          <div
            data-testid="opinion-card-percentage-complete"
            className="w-full mt-4 text-xs font-semibold text-end"
          >
            {percentComplete > 0 ? `${(percentComplete * 100).toFixed(0)} %` : `Not Started`}
          </div>
          <div data-testid="opinion-card-progress-complete">
          <Progress.Line
            percent={percentComplete * 100}
            showInfo={false}
            strokeWidth={8}
            trailColor={'rgb(243 244 246)'}
            style={{ padding: '0px' }}
            strokeColor={percentComplete === 1 ? brandSuccessColor : '#0047ff'}
          /></div>

          <Divider style={{ margin: '12px 0px' }} />
          <div className="flex justify-between text-xs font-semibold">
            <div
              data-testid="opinion-card-opinions-count"
              className="text-[#8f8f8f]"
            >
              {opinionTheme.opinions.length} Opinions
            </div>
            <div
              data-testid="opinion-card-button"
              className="cursor-pointer text-sightlyBlue"
            >
              {percentComplete === 1 ? (
                <div>View All &rarr;</div>
              ) : percentComplete > 0 ? (
                <div>Continue &rarr;</div>
              ) : (
                <div>Get Started &rarr;</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
