import React from 'react'
import { accentColor } from "@/assets/jss/colorConstants";

interface IProps {
    reset: Function
}

const ResetFieldsButton = ({ reset }: IProps) => {
    return (
        <div
            data-testid="div-reset-fields"
            onClick={() => reset()}
            className="sightlyLink"
            style={{
                position: 'absolute',
                bottom: 24,
                left: 24,
                color: accentColor
            }}
        >
            Clear
        </div>
    )
}

export default ResetFieldsButton
