import React from 'react';

type SectionProps = {
  headerName: string
  dataTestId?: string
  children?: React.ReactElement
}

export const Section = ({ headerName, children, dataTestId }: SectionProps) => {
  return (
    <div data-testid={dataTestId} className="flex-col mb-8">
      <div className="text-xl font-semibold mb-4">
        {headerName}
      </div>
      {children}
    </div>
  )
}
