import React from 'react'
import PlatformIcon from '@/views/Discover/Boards/components/conversations/PlatformIcon'
import {ConversationPanelSectionLayout} from "@/views/Discover/Boards/NarrativesTab/PanelSectionLayout.";
import {Loader} from "rsuite";

type ThemesOverviewType = {
  theme: string
  platforms: string[]
  postCount: number
}[]

const ThemesOverview = ( {themesOverview}:{themesOverview: ThemesOverviewType} ) => {

  if (!themesOverview.length) {
    return <Loader center/>
  }

  return (
    <ConversationPanelSectionLayout headerName="Themes" headerCount={themesOverview.length}>
      <table className="w-full">
        <thead className="h-12 p-2 text-sm font-semibold bg-gray-200">
        <tr>
          <th className="pl-4 text-left rounded-l-md">Themes</th>
          <th className="pl-2 text-left">Platforms</th>
          <th className="pl-2 text-left rounded-r-md">Number of Posts</th>
        </tr>
        </thead>
        <tbody>
        {themesOverview.map(({theme, platforms, postCount}) => (
          <tr className="h-12 border-b border-gray-200">
            <td className="pl-4">{theme}</td>
            <td className="flex gap-4 p-2">{
              platforms.map((platform) =>
                <PlatformIcon
                  platformName={platform}
                  width="2rem"/>
              )}
            </td>
            <td className="p-2">{postCount}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </ConversationPanelSectionLayout>
  )
}

export default ThemesOverview
