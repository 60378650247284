import React, {useState} from 'react'
import {getLastWeek, ranges} from "@/views/ReportGenerator/reportGenerator-types";
import {useQuery} from "@tanstack/react-query";
import {getInsertionOrdersByAccount} from "@/views/ReportGenerator/reportGenerator-api";
import {useSearch} from "@tanstack/react-location";
import {MyLocationGenerics} from "@/classes/utils";
import {DateRangePicker, SelectPicker} from "rsuite";
import {ValueType} from "rsuite/lib/DateRangePicker";
import {userAccountAxios} from "@/axiosInstances";
import LoadingModal from "@/views/ActivationReports/components/LoadingModal";
import {downloadFile} from "@/utils_ts";
import {logError, TableColumnJs} from "@/utils";

import {ArrowDownTrayIcon} from "@heroicons/react/20/solid";
import Tabs from "@/components/Tabs/Tabs";
import Table from "rsuite/lib/Table";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {InformationModal} from "@/views/ActivationReports/components/InformationModal";
const { HeaderCell, Cell } = Table;


export interface SummaryReport {
  id: number,
  startDate: Date,
  endDate: Date,
}

const tableHeaderStyle = {
  fontWeight: 500,
  fontSize: '16px',
  color: '#000000',
  backgroundColor: '#FAFAFA'
}

export const TargetViewSummaryReportView = () => {
  const { accountId } = useSearch<MyLocationGenerics>()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false)

  const reportName = 'Performance Summary'
  const tableData = [
    {name: 'Insertion Order', value: 'accountId'},
    {name: 'Age', value: 'accountId'},
    {name: 'Gender', value: 'accountId'},
    {name: 'Device', value: 'accountId'},
    {name: 'Ads', value: 'accountId'},
    {name: 'Impressions', value: 'accountId'},
    {name: 'Views', value: 'accountId'},
    {name: 'View Rate', value: 'accountId'},
    {name: 'Clicks', value: 'accountId'},
    {name: 'CTR', value: 'accountId'},
    {name: 'CPV', value: 'accountId'},
    {name: 'Cost', value: 'accountId'},
    {name: '25%', value: 'accountId'},
    {name: '50%', value: 'accountId'},
    {name: '75%', value: 'accountId'},
    {name: '100%', value: 'accountId'},
  ]

  const [
    formValues,
    setFormValues
  ] = useState<SummaryReport>({
    id: 0,
    startDate: getLastWeek()[0],
    endDate: getLastWeek()[1],
  })

  const noInsertionOrderSelected: boolean = formValues.id === 0

  const {
    data: accountInsertionOrders
  } = useQuery(['getInsertionOrdersByAccount', accountId], getInsertionOrdersByAccount)

  const handleOnChange = (field: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handleDateRangeChange = (dates: ValueType) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      startDate: dates[0]!,
      endDate: dates[1]!
    }))
  }
  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  function parseFilename(contentDisposition: any): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }

    return 'Unknown'
  }
  const downloadReport = async () => {
    try {
      if (formValues.id === 0) {
        setIsErrorModalVisible(true)
        return
      }
      setIsDownloading(true)

      const response = await userAccountAxios.get(
        `/v1/reporting/summary-reports/generate?id=${formValues.id}&startDate=${formatDate(formValues.startDate)}&endDate=${formatDate(formValues.endDate)}`,
        {
          responseType: 'blob'
        })

      const fileName: string = parseFilename(response.headers['content-disposition'])

      downloadFile(response.data, fileName)

      setIsDownloading(false)
    } catch (err) {
      logError(err)
      setIsDownloading(false)
    }
  }


  return (
    <div className='h-full flex flex-col' data-testid="summary-report-form">
        <div className='flex justify-end mt-6 mr-32' data-testid="summary-report-form-action-buttons">
          <div className='flex flex-col items-center cursor-pointer'
               onClick={downloadReport}
               data-testid="summary-report-form-download">
            <ArrowDownTrayIcon
              className="flex-shrink-0 w-6 h-6 text-indigo-600 mb-2"
              aria-hidden="true"
            />
            <div>Download</div>
          </div>
        </div>


      <div className='ml-6 flex flex-row custom-tab-active-color' data-testid="summary-report-form-tabs">
        <Tabs
          width={'100%'}
          marginRight={40}
          active={reportName}
          onChange={() => {}}
        >
          <div className="flex flex-row items-center" key={reportName} data-testid={`tab-performance-summary`}>
            <div className='flex flex-row text-sm whitespace-nowrap ml-2'>
              {reportName}
            </div>
            <ChevronDownIcon
              className="w-4 mx-4"
              aria-hidden="true"
              data-testid={`summary-report-form-more-whisper-performance-summary`}
            />
          </div>
        </Tabs>
      </div>

      <div className='min-h-[104px] w-full bg-[#EFE3FF80] flex flex-row justify-start'
           data-testid="summary-report-form-filters">
        <div className='w-1/4 mx-8' data-testid="summary-report-form-filters-insertion">
          <h5 className='mt-3 mb-2 text-base'>Insertion Order</h5>
          <SelectPicker
            labelKey={'insertionOrderName'}
            valueKey={'insertionOrderId'}
            data={accountInsertionOrders}
            value={formValues.id}
            onChange={(value) => handleOnChange('id', value)}
            searchable={true}
            placement="autoVerticalStart"
            cleanable={false}
          />
        </div>
        <div className='w-1/4 mx-8' data-testid="summary-report-form-filters-date">
          <h5 className='mt-3 mb-2 text-base'>Date Range</h5>
          <DateRangePicker
            ranges={ranges}
            value={[formValues.startDate, formValues.endDate]}
            onChange={(dates) => handleDateRangeChange(dates)}
            placeholder="Select Date(s)"
            placement="autoVerticalStart"
            cleanable={false}
          />
        </div>
        <div className='w-1/4 mx-8' data-testid="summary-report-form-filters-granularity">
          <h5 className="mt-3 mb-2 text-base">Granularity</h5>
          <SelectPicker
            data={[{ label: 'Summary', value: 'summary' }]}
            disabled
            value={'summary'}
            placement="autoVerticalStart"
            cleanable={false}
            data-testid='granularity-picker'
          />
        </div>
      </div>
      <div
        className="data-active-activation-report-performance-summary mt-1 flex w-full flex-1 min-h-0"
        key={reportName}
        data-testid="summary-report-form-component"
      >
        <div className='w-full' data-testid="summary-report-form-container">
          <div className='w-full flex flex-row h-full' data-testid="summary-report-form-container-components">
            <div className='w-2/3 overflow-auto' data-testid="summary-report-form-table">
              <div className='mx-11 py-4' data-testid="summary-report-table">
                <div className='pt-3'>
                  <Table
                    data={tableData}
                    autoHeight={true}
                  >
                    <TableColumnJs width={64}>
                      <HeaderCell style={tableHeaderStyle}></HeaderCell>
                      <Cell></Cell>
                    </TableColumnJs>
                    <TableColumnJs width={107} flexGrow={1}>
                      <HeaderCell style={tableHeaderStyle}>Column Order</HeaderCell>
                      <Cell data-testid="summary-report-table-column-order">
                        {(rowData: any, rowIndex: number) => {
                          return (
                            rowIndex + 1
                          );
                        }}
                      </Cell>
                    </TableColumnJs>
                    <TableColumnJs width={324}>
                      <HeaderCell style={tableHeaderStyle}>Column Name</HeaderCell>
                      <Cell dataKey="name" data-testid="summary-report-table-column-name"/>
                    </TableColumnJs>
                    <TableColumnJs width={90} flexGrow={1}>
                      <HeaderCell style={tableHeaderStyle} className='cursor-pointer'></HeaderCell>
                      <Cell></Cell>
                    </TableColumnJs>
                  </Table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal isDownloading={isDownloading} title='Downloading'/>
      <InformationModal
        title={'Error'}
        errorMessages={['At least one Insertion Order must be selected']}
        isErrorModalVisible={isErrorModalVisible}
        setIsErrorModalVisible={setIsErrorModalVisible}
      />
    </div>
  )
}
