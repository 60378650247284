export const garmOptions = [
	{
		id: 1,
		name: 'Adult & Explicit Sexual Content',
		children: [
			{
				id: 13,
				name: 'Illegal sale, distribution, and consumption of child pornography'
			},
			{
				id: 14,
				name:
					'Explicit or gratuitous depiction of sexual acts, and/or display of genitals, real or animated'
			}
		]
	},
	{
		id: 2,
		name: 'Arms & Ammunition',
		children: [
			{
				id: 15,
				name:
					'Promotion and advocacy of Sales of illegal arms, rifles, and handguns'
			},
			{
				id: 16,
				name:
					'Instructive content on how to obtain, make, distribute, or use illegal arms'
			},
			{
				id: 17,
				name: 'Glamorization of illegal arms for the purpose of harm to others'
			},
			{
				id: 18,
				name: 'Use of illegal arms in unregulated environments'
			}
		]
	},
	{
		id: 3,
		name:
			'Crime & Harmful acts to individuals and Society, Human Right Violations',
		children: [
			{
				id: 19,
				name:
					'Graphic promotion, advocacy, and depiction of willful harm and actual unlawful criminal activity – Explicit violations/demeaning offenses of Human Rights (e.g. human trafficking, slavery, self-harm, animal cruelty etc.)'
			},
			{
				id: 20,
				name: 'Harassment or bullying of individuals and groups'
			}
		]
	},
	{
		id: 4,
		name: 'Death, Injury or Military Conflict',
		children: [
			{
				id: 21,
				name: 'Promotion, incitement or advocacy of violence, death or injury'
			},
			{
				id: 22,
				name: 'Murder or Willful bodily harm to others'
			},
			{
				id: 23,
				name: 'Graphic depictions of willful harm to others'
			},
			{
				id: 24,
				name:
					'Incendiary content provoking, enticing, or evoking military aggression'
			},
			{
				id: 25,
				name:
					'Live action footage/photos of military actions & genocide or other war crimes'
			}
		]
	},
	{
		id: 5,
		name: 'Online piracy',
		children: [
			{
				id: 26,
				name: 'Pirating, Copyright infringement, & Counterfeiting'
			}
		]
	},
	{
		id: 6,
		name: 'Hate speech & acts of aggression',
		children: [
			{
				id: 27,
				name:
					'Behavior or content that incites hatred, promotes violence, vilifies, or dehumanizes groups or individuals based on race, ethnicity, gender, sexual orientation, gender identity, age, ability, nationality, religion, caste, victims and survivors of violent acts and their kin, immigration status, or serious disease sufferers'
			}
		]
	},
	{
		id: 7,
		name:
			'Obscenity and Profanity, including language, gestures, and explicitly gory, graphic or repulsive content intended to shock and disgust',
		children: [
			{
				id: 29,
				name:
					'Excessive use of profane language or gestures and other repulsive actions that shock, offend, or insult'
			}
		]
	},
	{
		id: 8,
		name: 'Illegal Drugs/Tobacco/e-cigarettes/Vaping/Alcohol',
		children: [
			{
				id: 30,
				name:
					'Promotion or sale of illegal drug use – including abuse of prescription drugs. Federal jurisdiction applies, but allowable where legal local jurisdiction can be effectively managed'
			},
			{
				id: 31,
				name:
					'Promotion and advocacy of Tobacco and e-cigarette (Vaping) & Alcohol use to minors'
			}
		]
	},
	{
		id: 9,
		name: 'Spam or Harmful Content',
		children: [
			{
				id: 32,
				name: 'Malware/Phishing'
			}
		]
	},
	{
		id: 10,
		name: 'Terrorism',
		children: [
			{
				id: 33,
				name:
					'Promotion and advocacy of graphic terrorist activity involving defamation, physical and/or emotional harm of individuals, communities, and society'
			}
		]
	},
	{
		id: 11,
		name: 'Debated Sensitive Social Issue',
		children: [
			{
				id: 34,
				name:
					'Insensitive, irresponsible and harmful treatment of debated social issues and related acts that demean a particular group or incite greater conflict'
			}
		]
	},
	{
		id: 12,
		name: 'None of the above',
		children: [
			{
				id: 35,
				name: 'None of the above'
			}
		]
	}
]
