import React from 'react'
import { Drawer } from 'rsuite'
import Tooltip from '@/components/TailwindTooltip'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'

const DEFAULT_MIN_OFFSET = '7em'
const DEFAULT_MAX_OFFSET = '35em'

export enum slideOverPlacements {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

/**
 * Props for the SlideOver component.
 * @typedef {Object} SlideOverProps
 * @property {boolean} show - Indicates whether the SlideOver is visible.
 * @property {Function} onHide - Function to handle the hiding of the SlideOver.
 * @property {string} headerTitle - The title for the header of the SlideOver.
 * @property {string} headerBackgroundColor - The background color for the header of the SlideOver.
 * @property {React.ReactNode} children - The content of the SlideOver.
 * @property {React.ReactNode} [footer] - The footer content of the SlideOver.
 * @property {string} [headerDescription] - The description for the header of the SlideOver.
 * @property {boolean} [fullScreen] - Indicates whether the SlideOver is in full-screen mode.
 * @property {boolean} [showToggleFullScreenButton] - Indicates whether to show the toggle full-screen button.
 * @property {string} [minOffset] - The minimum offset from the opposite edge of the slideover origin edge for the SlideOver.
 * @property {string} [maxOffset] - The maximum offset from the opposite edge of the slideover origin edge for the SlideOver.
 * @property {string} [dataTestId] - The data-testid attribute for testing purposes.
 * @property {slideOverPlacements} [placement] - The placement of the SlideOver panel.
 */
type SlideOverProps = {
  show: boolean
  onHide: () => void
  headerTitle: string
  headerBackgroundColor: string
  headerForegroundColor?: string
  children: React.ReactNode
  footer?: React.ReactNode
  headerDescription?: string
  fullScreen?: boolean
  showToggleFullScreenButton?: boolean
  minOffset?: string
  maxOffset?: string
  dataTestId?: string
  placement?: slideOverPlacements
}

/**
 * A slide-over panel component.
 * @param {SlideOverProps} props - The props for the SlideOver component.
 * @returns {React.ReactElement} The rendered SlideOver component.
 */
const SlideOver = ({
  show,
  onHide,
  headerTitle,
  headerBackgroundColor,
  headerForegroundColor,
  headerDescription,
  children,
  footer,
  dataTestId,
  fullScreen,
  showToggleFullScreenButton,
  placement,
  maxOffset,
  minOffset
}: SlideOverProps) => {
  const [open, setOpen] = React.useState(show || false)
  const [isFullScreen, setIsFullScreen] = React.useState(fullScreen || false)

  React.useEffect(() => {
    setIsFullScreen(() => fullScreen || false)
  }, [fullScreen])

  React.useEffect(() => {
    setOpen(() => show || false)
  }, [show])

  return (
    <div data-testid={dataTestId || 'sightly-slideover'} id={dataTestId || 'sightly-slideover'}>
      <Drawer
        full
        placement={placement || slideOverPlacements.RIGHT}
        show={open}
        onHide={onHide}
        onExit={onHide}
      >
        <div className="flex flex-col w-full h-full bg-white divide-y divide-gray-200 shadow-xl rounded-tl-3xl">
          <div className="flex flex-col flex-1 min-h-0">
            <Header
              setOpen={setOpen}
              backgroundColor={headerBackgroundColor}
              foregroundColor={headerForegroundColor}
              headerTitle={headerTitle}
              headerDescription={headerDescription}
              showToggleFullScreenButton={showToggleFullScreenButton ?? true}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              placement={placement || slideOverPlacements.RIGHT}
            />
            {children}
          </div>
          {footer}
        </div>
      </Drawer>

      <style>
        {`
          .rs-drawer-full.rs-drawer-left, .rs-drawer-full.rs-drawer-right {
            width: ${
              isFullScreen
                ? `calc(100vw - ${minOffset ?? DEFAULT_MIN_OFFSET})`
                : `calc(100vw - ${maxOffset ?? DEFAULT_MAX_OFFSET})`
            };
          }
          .rs-drawer-full.rs-drawer-top, .rs-drawer-full.rs-drawer-bottom {
            height: ${
              isFullScreen
                ? `calc(100vh - ${minOffset ?? DEFAULT_MIN_OFFSET})`
                : `calc(100vh - ${maxOffset ?? DEFAULT_MAX_OFFSET})`
            };
          }
            .rs-drawer-content {
              border-top-left-radius: 24px;
            }
        `}
      </style>
    </div>
  )
}

const Header = (props: {
  setOpen: (val: boolean) => void
  isFullScreen: boolean
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  headerTitle: string
  backgroundColor: string
  foregroundColor?: string
  headerDescription?: string
  showToggleFullScreenButton: boolean
  placement: slideOverPlacements
}) => {
  return (
    <header
      data-testid="act-mgr-target-moment-drawer-header"
      className={`px-4 py-6 ${props.foregroundColor || 'text-white'} sm:px-6 ${props.backgroundColor} rounded-tl-3xl`}
    >
      <div className="flex items-start justify-between">
        <h2 className="text-lg font-bold">{props.headerTitle}</h2>
        <div className="flex items-center gap-6 ml-3 h-7">
          {props.showToggleFullScreenButton && (
            <ToggleFullScreenButton
              isFullScreen={props.isFullScreen}
              setIsFullScreen={props.setIsFullScreen}
              placement={props.placement}
            />
          )}
          <button
            type="button"
            className={`${props.foregroundColor || 'text-white'} rounded-md ${props.backgroundColor} 
               focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            onClick={() => props.setOpen(false)}
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon
              className="w-6 h-6"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
      {props.headerDescription && <p>{props.headerDescription}</p>}
    </header>
  )
}

const ToggleFullScreenButton = (props: {
  isFullScreen: boolean
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  placement: slideOverPlacements
}) => (
  <Tooltip
    content={
      <div
        data-testid="act-mgr-target-moments-table-cluster-name-tooltip"
        className="text-left"
      >
        Toggle Full Screen
      </div>
    }
  >
    <div
      className={`w-7 h-7 cursor-pointer text-white text-center font-semibold text-base `}
      onClick={() => props.setIsFullScreen((prev) => !prev)}
    >
      {props.placement === slideOverPlacements.RIGHT ? (
        props.isFullScreen ? (
          <ArrowUpTrayIcon className="rotate-90" />
        ) : (
          <ArrowUpTrayIcon className="-rotate-90" />
        )
      ) : (
        <></>
      )}
      {props.placement === slideOverPlacements.LEFT ? (
        props.isFullScreen ? (
          <ArrowUpTrayIcon className="-rotate-90" />
        ) : (
          <ArrowUpTrayIcon className="rotate-90" />
        )
      ) : (
        <></>
      )}
      {props.placement === slideOverPlacements.TOP ? (
        props.isFullScreen ? (
          <ArrowUpTrayIcon />
        ) : (
          <ArrowUpTrayIcon className="rotate-180" />
        )
      ) : (
        <></>
      )}
      {props.placement === slideOverPlacements.BOTTOM ? (
        props.isFullScreen ? (
          <ArrowUpTrayIcon className="rotate-180" />
        ) : (
          <ArrowUpTrayIcon />
        )
      ) : (
        <></>
      )}
    </div>
  </Tooltip>
)

export default SlideOver
