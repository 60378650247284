import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import 'rsuite/dist/styles/rsuite-default.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '@/App.css';
import '@/components/Sightly/sightlyTagInput.css';
import configureStore from '@/redux/store/index.js';
import RouteContainer from '@/RouteContainer';
import { FlagProvider } from '@unleash/proxy-client-react';
import { sharedContexts } from './sharedContexts/sharedContexts';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const store = configureStore()
const config = {
    url: `${process.env.REACT_APP_UNLEASH_URL}`,
    clientKey:  `${process.env.REACT_APP_UNLEASH_CLIENT_KEY}`,
    refreshInterval: 60, // How often (in seconds) the client should poll the proxy for updates
    appName:  `${process.env.REACT_APP_STAGE}`
};

const App = () => {    
    const [disableBrandProfileStepsClick, setDisableBrandProfileStepsClick] = useState(false);
    return (
        <FlagProvider config={config}>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Toaster
                    position={'top-right'}
                    toastOptions={{ duration: 3000 }}
                />
                <sharedContexts.Provider value={{ 
                    disableBrandProfileStepsClick, setDisableBrandProfileStepsClick }}>
                    <RouteContainer />
                </sharedContexts.Provider>
            </Provider>
        </QueryClientProvider>
        </FlagProvider>
    )
}

export default App
