import React from 'react'
import ytLogo from '../../../../../assets/img/ytLogo.png'
import FeedbackButton from '../../../../../components/FeedbackButton'
import { MomentVideoType } from './../types'
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../../../classes/utils'
import { logError, openInNewTab } from '../../../../../utils'
import { rqKeys } from './../rqKeys'
import { api, VideoFeedbackProps } from './../api'
import toast from 'react-hot-toast'

interface iProps {
    video: MomentVideoType
    clusterId: number
}

const MomentVideo = ({ video, clusterId }: iProps) => {
    const queryClient = useQueryClient()
    const isMutating = useIsMutating()
    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const feedbackMutation = useMutation(
        async (args: { type: string; video: MomentVideoType; feedback: string | null }) => {
            if (!brandProfileId) return
            let _body: VideoFeedbackProps = {
                brandProfileId,
                videoId: args.video.videoId,
                clusterId
            }

            const feedbackBool = args.feedback === 'disagree'
            if (args.type === 'videoRelatedToTrend') {
                _body.videoRelatedToTrend = feedbackBool
            }
            if (args.type === 'languageIsEnglish') {
                _body.languageIsEnglish = feedbackBool
            }
            if (args.type === 'dateRelevancyIsInvalid') {
                _body.dateRelevancyIsInvalid = feedbackBool
            }
            await api.moments.feedback.videoFeedback(_body)
        },
        {
            onSuccess: () => {
                toast.success('Changes saved')
            },
            onSettled: () => {
                queryClient.invalidateQueries(rqKeys.momentVideosKey(brandProfileId, clusterId))
            },
            onError: (err) => {
                logError(err, { info: 'err in  video feedback mutation' })
            }
        }
    )

    return (
        <div
            data-testid="moment-video"
            style={{
                width: 540,
                height: 129,
                borderBottom: '1px solid rgb(212, 217, 217, 0.5)',
                marginBottom: 7,
                position: 'relative',
                textAlign: 'left'
            }}
        >
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        marginRight: 28,
                        width: 100,
                        height: 75,
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <div
                        data-testid="moment-modal-videos-youtube-image"
                        onClick={() => openInNewTab(`https://www.youtube.com/embed/${video.videoId}?autoplay=1`)}
                        style={{ position: 'relative', cursor: 'pointer' }}
                    >
                        <img
                            style={{ width: 75 }}
                            src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
                        />
                        <div style={{ position: 'absolute', top: 14, left: 16.5 }}>
                            <img
                                style={{ width: 42 }}
                                src={ytLogo}
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        width: 400,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <div
                        data-testid="moment-modal-videos-title"
                        onClick={() => openInNewTab(`https://www.youtube.com/watch?v=${video.videoId}`)}
                        className="sightlyLink line-clamp-2"
                        style={{
                            flex: 1,
                            fontWeight: 600,
                            fontSize: 16,
                            lineHeight: '24px',
                            color: '#333D47'
                        }}
                    >
                        {video?.videoTitle}
                    </div>

                    <div
                        data-testid="moment-video-channel-title"
                        onClick={() => openInNewTab(`https://www.youtube.com/channel/${video.channelId}`)}
                        className="sightlyLink"
                        style={{
                            flex: 1,
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: '#6e8399',
                            width: 400,

                            marginTop: 4
                        }}
                    >
                        {video.channelTitle}
                    </div>

                    <div
                        style={{
                            flex: 1,
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: '#5F7284',
                            width: 400,
                            marginTop: 4
                        }}
                    ></div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        bottom: 8,
                        left: 0,
                        display: 'flex'
                    }}
                >
                    <div style={{ marginRight: 16 }}>
                        <FeedbackButton
                            data-testid="moment-not-related-feedback-button"
                            text="Video not related to moment"
                            disagree={video.feedback.videoRelatedToTrend}
                            handleClick={(feedback: string | null) => {
                                feedbackMutation.mutate({ type: 'videoRelatedToTrend', feedback, video })
                            }}
                            disabled={isMutating > 0}
                        />
                    </div>

                    <div style={{ marginRight: 16 }}>
                        <FeedbackButton
                            data-testid="moment-not-english-feedback-button"
                            text="Language not English"
                            disagree={video.feedback.languageIsEnglish}
                            handleClick={(feedback: string | null) => {
                                feedbackMutation.mutate({ type: 'languageIsEnglish', feedback, video })
                            }}
                            disabled={isMutating > 0}
                        />
                    </div>
                    <FeedbackButton
                        data-testid="moment-date-relevancy-feedback-button"
                        text="Date relevancy is invalid"
                        disagree={video.feedback.dateRelevancyIsInvalid}
                        handleClick={(feedback: string | null) => {
                            feedbackMutation.mutate({ type: 'dateRelevancyIsInvalid', feedback, video })
                        }}
                        disabled={isMutating > 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default MomentVideo
