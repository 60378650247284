import React from "react";
import { Link } from "@tanstack/react-location";
import { accentColor } from "@/assets/jss/colorConstants";
import { routes } from "@/routes";

interface IProps {
    brandProfileId: number | undefined
    section: string
    dataTestId: string
}

export const BrandProfileLink = ({ brandProfileId, section,dataTestId }: IProps) => {
    return (
        <Link
            data-testid={dataTestId}
            className="text-base font-medium self-center"
            style={{ color: accentColor }}
            to={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/${section}`}
        >
            Edit in Brand Profile
        </Link>
    )
}
