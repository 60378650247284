import React from "react";
import { SelectPicker } from "rsuite";

export default function GranularitySection({ formValues, granularity, handleOnChange }) {
    const ref = React.useRef()

    return (
      <div data-testid='div-report-generator-select-granularity'>
        <h5 className="pt-2">7. Select your Granularity</h5>
        <label
          htmlFor=""
          className="pl-4 text-gray-500"
        >
          {' '}
          This determines how the data will be rolled up
        </label>
        <div className="pl-4 relative" ref={ref}>
          <SelectPicker
            container={() => ref.current}
            data={granularity}
            defaultValue={formValues.granularity}
            onChange={(value) => handleOnChange('granularity', value)}
            searchable={false}
            placement="autoVerticalStart"
          />
        </div>
      </div>
    )
}
