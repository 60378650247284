import React from 'react'
import { routes } from './../../../../../routes'

const ListBuilderPlaceholder = () => {
	return (
		<div>
			This listbuilder URL structure has changed, please{' '}
			<a href={routes.app.engage.lists.lists.path}>
				Go back to the SmartLists page
			</a>{' '}
			and navigate to your list using the UI
		</div>
	)
}

export default ListBuilderPlaceholder
