import { Fragment, ReactNode, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { OPINION_ACTIONS } from '@/staticData/data'

type Item = {
  id: number
  text: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const Dropdown = (props: {
  handleChange: (val: number) => void
  defaultResponseId: number
  disabled: boolean
}) => {
  const OPINION_ACTIONS_VALUES = Object.values(OPINION_ACTIONS)
  const defaultValue = OPINION_ACTIONS_VALUES.find((val) => val.id === props.defaultResponseId)
  const [selected, setSelected] = useState<Item | undefined>(defaultValue)
  React.useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])
  return (
    <Listbox
      value={selected}
      onChange={(val: Item) => {
        setSelected(val)
        props.handleChange(val.id)
      }}
      disabled={props.disabled}
    >
      {({ open }) => (
        <>
          <div className="relative w-40 mt-1">
            <Listbox.Button className="relative w-full py-2 pr-10 text-xs font-bold text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sightly-slate">
              <span className="flex items-center leading-4">
                {selected?.text ? (
                  <div className="flex pl-2">
                    <span className="block ml-3 truncate">{selected.text}</span>
                  </div>
                ) : (
                  <span className="block pl-2 truncate">Select Action</span>
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-xs bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {OPINION_ACTIONS_VALUES.map((action) => (
                  <Listbox.Option
                    key={action.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-sightly-blue-light' : 'text-gray-900',
                        'relative cursor-default select-none py-2 text-xs'
                      )
                    }
                    value={action}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span className={'ml-3 block truncate font-bold text-sightly-slate text-xs'}>
                            {action.text}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              'text-sightly-blue absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon
                              className="w-4 h-4"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
