import React from 'react'

import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import {
  ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION,
  ACTIVATIONS_TABLE_KEYWORDS_COL_CAPTION,
  ACTIVATIONS_TABLE_PERSONA_COL_CAPTION,
  ACTIVATIONS_TABLE_VIDEOS_COL_CAPTION
} from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'

const columnHeaderStyle = 'py-3.5  px-2 text-left text-xs font-semibold text-gray-500'

export const fixedColumnWidth = 180

export const TableHeaderRow = (props: {
  selectedActivations: Map<string, Set<number>>
  isSelectAllChecked: boolean
  handleSelectAll: (checked: boolean) => void
  setIsSelectAllChecked: (value: React.SetStateAction<boolean>) => void
  expandedPersona: Set<string> | null
}) => {
  const { expandedPersona, handleSelectAll, isSelectAllChecked, selectedActivations, setIsSelectAllChecked } = props
  const targetedActivationList = useTargetedActivationList()
  const isSelectAllIndeterminate =
    (selectedActivations.size ?? 0) > 0 && (selectedActivations.size ?? 0) < targetedActivationList.state.size
  const areAllActivationsSelected = selectedActivations.size === targetedActivationList.state.size

  return (
    <thead className="bg-gray-50">
      <tr key="act-mgr-heading">
        <th
          id="activations-checkbox"
          className="w-12 p-3"
        >
          <SightlyCheckbox
            dataTestId="select-all-activations-checkbox"
            id="select-all-activations-checkbox"
            indeterminate={isSelectAllIndeterminate}
            checked={isSelectAllChecked || areAllActivationsSelected}
            handleChange={(checked: boolean) => {
              setIsSelectAllChecked(checked)
              handleSelectAll(checked)
            }}
          />
        </th>
        <th
          className={`w-full ${columnHeaderStyle}`}
          id="activations-persona"
        >
          {ACTIVATIONS_TABLE_PERSONA_COL_CAPTION}
        </th>
        {expandedPersona && expandedPersona.size > 0 && (
          <>
            <th
              className={`${columnHeaderStyle} w-[196px]`}
              id="activations-campaign"
            >
              {ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION}
            </th>
            <th
              className={`${columnHeaderStyle} w-[196px]`}
              id="activations-keywords"
            >
              {ACTIVATIONS_TABLE_KEYWORDS_COL_CAPTION}
            </th>
            <th
              className={`${columnHeaderStyle} w-[222px]`}
              id="activations-videos"
            >
              {ACTIVATIONS_TABLE_VIDEOS_COL_CAPTION}
            </th>
          </>
        )}
        <th
          className={`${columnHeaderStyle} bg-gray-50 w-[32px]`}
          id="activations-actions"
        >
          &nbsp;
        </th>
      </tr>
    </thead>
  )
}
