import React, { useCallback, useEffect, useState } from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import { useMediaResponsibility } from '@/views/BrandProfiles/BrandProfile/components/MediaResponsibility/hooks';
import MediaPreference from '@/views/BrandProfiles/BrandProfile/components/MediaResponsibility/MediaPreference';
import { useMatch, useSearch } from '@tanstack/react-location';
import constants from '@/views/BrandProfiles/BrandProfile/components/MediaResponsibility/constants';
import debounce from 'just-debounce-it';
import adFontesLogo from '../../../../../assets/img/adFontesLogo.png';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

const MediaResponsibility = () => {
  const { viewOnly } = useSearch()  
  const {
        params: { brandProfileId }
    } = useMatch()
    const { 
      mediaResponsibilitiesLookupQuery,
      getMediaPreferencesQuery,
      addBrandMediaResponsibilities } = useMediaResponsibility({brandProfileId:parseInt(brandProfileId)})
    
    const [reliabilityPref, setReliabilityPref] = useState<Array<number>>(constants.DEFAULT_RELIABILITY_PREFERENCE)
    const [politicalBiasPref, setPoliticalBiasPref] = useState<Array<number>>(constants.DEFAULT_POLITICAL_BIAS_PREFERENCE)
    
    const handlePoliticalBiasPref = (value:number[])=>{
      let prefList = []
      const min = Math.min(value[0],value[1])
      const max = Math.max(value[0],value[1])
      for(let i=min; i <= max; i++){
        prefList.push(i)
      }
      setPoliticalBiasPref(prefList)
      mutatePreferences(prefList,reliabilityPref)
    }

    const mutatePreferences = useCallback(debounce((politicalBiasPref:number[], reliabilityPref:number[]) => {
      addBrandMediaResponsibilities.mutate({
        brandProfileId: parseInt(brandProfileId),
        politicalBiasPreferenceIds: politicalBiasPref,
        reliabilityPreferenceIds: reliabilityPref
      }) 
    }, 500), []);
    
    const handleReliabilityPref = (value:number)=>{
      setReliabilityPref([value])
      mutatePreferences(politicalBiasPref,[value])
    }

    // onMount
    useEffect(()=>{
      mediaResponsibilitiesLookupQuery.refetch()
      getMediaPreferencesQuery.refetch()
    },[])

    useEffect(()=>{
      if(getMediaPreferencesQuery.data){
        if(getMediaPreferencesQuery
          .data?.politicalBiasPreferences.length){
            setPoliticalBiasPref(getMediaPreferencesQuery
            .data?.politicalBiasPreferences 
            .map((preference => preference.id)))
          }
        if(getMediaPreferencesQuery
          .data?.reliabilityPreferences.length){
            setReliabilityPref(getMediaPreferencesQuery
              .data?.reliabilityPreferences
              .map((preference => preference.id)))
          }
         if(!getMediaPreferencesQuery.data?.reliabilityPreferences.length || 
          !getMediaPreferencesQuery.data?.politicalBiasPreferences.length){
            mutatePreferences(politicalBiasPref,reliabilityPref)
        }
      }
    },[getMediaPreferencesQuery.data])

    // onUnmount
    useEffect(() => {
      return () => {
        getMediaPreferencesQuery.refetch()
      };
    }, []);

    const { activeStep,
      handleContinueClick,
      handlePreviousClick } = useSteps();

    if (mediaResponsibilitiesLookupQuery.isLoading || getMediaPreferencesQuery.isLoading) {
        return <LoadingPage message="Fetching data" />
    }
    return (
        <main className="m-6">
            <h4 className="mb-6">{constants.PAGE_TITLE}
              <div style={{ display: "inline-block", fontSize: 12, fontWeight: "bold", marginRight: 10, marginLeft: 10, verticalAlign: "middle" }}>
                {constants.AD_FONTES_TEXT}
              </div>
              <div style={{ display: "inline-block", transform: "translateY(10px)" }}>
                <img style={{ width: 120, backgroundColor: "white" }} src={adFontesLogo} />
              </div>
            </h4>
            <div className="text-md text-gray-500 ">
              {constants.PAGE_DESCRIPTION}
            </div>
            <div className='flex flex-col gap-5'>
            {mediaResponsibilitiesLookupQuery.data &&
            <>
              <MediaPreference
                disabled = {!!viewOnly}
                sectionCaption={constants.RELIABILITY_SECTION_CAPTION}
                key={constants.RELIABILITY_SECTION_CAPTION}
                description={constants.RELIABILITY_DESCRIPTION}
                preferences={mediaResponsibilitiesLookupQuery.data?.reliabilityPreferenceOptions}
                preference = {reliabilityPref}
                handlePreference ={handleReliabilityPref}/>

              <MediaPreference
                disabled = {!!viewOnly}
                sectionCaption={constants.POLITICAL_BIAS_SECTION_CAPTION}
                key={constants.POLITICAL_BIAS_SECTION_CAPTION}
                description={constants.POLITICAL_BIAS_DESCRIPTION}
                preferences={mediaResponsibilitiesLookupQuery.data?.politicalBiasPreferenceOptions} 
                preference = {politicalBiasPref}
                handlePreference ={handlePoliticalBiasPref}/>
            </>
            }
            </div>
            <BrandProfileFooter activeStep={activeStep}
              disabled={false}
              handlePrevious={handlePreviousClick}
              handleContinue={handleContinueClick}
            />
        </main>
    )
}

export default MediaResponsibility
