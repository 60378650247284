import { Dispatch, Fragment, SetStateAction } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { ActivationItemType } from './activations-types'
import { ReactComponent as KeywordsIcon } from '../../../assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '../../../assets/img/blackYoutube.svg'
import { SmallBadge } from '../../../components/SmallClosableBadge'
import SightlyButton from '../../../components/Sightly/SightlyButton'
import { CheckTreePicker } from 'rsuite'
import SightlyToggle from '../../../components/Sightly/SightlyFormElements/SightlyToggle'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { activationsApi } from './activations-api'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '../../../classes/utils'
import Tooltip from '../../../components/TailwindTooltip'
import toast from 'react-hot-toast'
import { logError } from '../../../utils'
import { activationsRqKeys } from './activationsRqKeys'

interface IProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setSelectedItemIds: Dispatch<SetStateAction<number[]>>
    items: ActivationItemType[]
}

export function EditSlideOver({ open, setOpen, items, setSelectedItemIds }: IProps) {
    const queryClient = useQueryClient()
    const { brandProfileId } = useSearch<MyLocationGenerics>()

    const [activationsAdgroups, setActivationsAdGroups] = React.useState<{ id: number; activationItemId: number }[]>([])
    const [removedKeywordsActivationItemIds, setRemovedKeywordsActivationItemIds] = React.useState<number[]>([])
    const [removedVideosActivationItemIds, setRemovedVideosActivationItemIds] = React.useState<number[]>([])

    const distinctActivationsAdGroups = activationsAdgroups
        .map((i) => i.activationItemId)
        .filter((v, i) => !activationsAdgroups.map((i) => i.activationItemId).includes(v, i + 1))
    const allItemsHaveAdGroups = distinctActivationsAdGroups.length === items.length
    const itemsThatHaveKeywordsAndVideosFalse = removedKeywordsActivationItemIds.filter((element) =>
        removedVideosActivationItemIds.includes(element)
    )

    const handleSubmitMutation = useMutation(
        async () => {
            const activations = items.map((item) => {
                const adGroupIds = activationsAdgroups
                    .filter((i) => i.activationItemId === item.activationItemId)
                    .map((i) => i.id)
                const isKeywords = !removedKeywordsActivationItemIds.includes(item.activationItemId)
                const isPlacements = !removedVideosActivationItemIds.includes(item.activationItemId)
                return {
                    targetListId: item.activationItemId,
                    adGroupIds,
                    isKeywords,
                    isPlacements
                }
            })
            await activationsApi.patchActivations({ brandProfileId, activations })
            return activationsApi.changeStatusToPending({
                brandProfileId,
                activations: activations.map((a) => a.targetListId)
            })
        },
        {
            onMutate: () => {},
            onSettled: () => {
                queryClient.invalidateQueries(activationsRqKeys.activationStatusCount(brandProfileId))
                queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
            },
            onSuccess: () => {
                toast.success('Changes saved')

                setOpen(false)
                setSelectedItemIds([])
            },
            onError: (err) => {
                logError(err, { info: 'error submitting todo items' })
            }
        }
    )

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={setOpen}
            >
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-screen-2xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title
                                                    onClick={() => setOpen(false)}
                                                    className="cursor-pointer text-lg font-medium text-gray-900 flex items-center gap-4"
                                                >
                                                    <ChevronLeftIcon className="w-10" />
                                                    Back to Activations
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6 bg-slate-100">
                                            <div className="px-4 sm:px-6 lg:px-8">
                                                <div className="sm:flex sm:items-center mt-8">
                                                    <div className="sm:flex-auto">
                                                        <h1 className="text-xl font-semibold text-gray-900">
                                                            Submit Target List
                                                        </h1>
                                                        <p className="mt-2 text-sm text-gray-700">
                                                            Get started by filling in the information below to create
                                                            your new target list.
                                                        </p>
                                                    </div>
                                                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                        <Tooltip
                                                            disabled={
                                                                allItemsHaveAdGroups &&
                                                                itemsThatHaveKeywordsAndVideosFalse.length === 0
                                                            }
                                                            content={
                                                                !allItemsHaveAdGroups
                                                                    ? 'Please add at least one Ad Group to each item in this list in order to continue'
                                                                    : itemsThatHaveKeywordsAndVideosFalse.length > 0
                                                                    ? 'Please enable either Keywords or Videos for each of the items in the list'
                                                                    : ''
                                                            }
                                                        >
                                                            <div>
                                                                <SightlyButton
                                                                    id="submitTargetList"
                                                                    text="Submit"
                                                                    loading={handleSubmitMutation.isLoading}
                                                                    handleClick={handleSubmitMutation.mutate}
                                                                    disabled={
                                                                        !allItemsHaveAdGroups ||
                                                                        itemsThatHaveKeywordsAndVideosFalse.length >
                                                                            0 ||
                                                                        handleSubmitMutation.isLoading
                                                                    }
                                                                />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className="mt-8 flex flex-col">
                                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                            <div className="mb-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                                <table className="min-w-full divide-y divide-gray-300">
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                                            >
                                                                                Moment
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                            >
                                                                                Persona
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                            >
                                                                                Ad Groups
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                            >
                                                                                Keywords
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-gray-900"
                                                                            >
                                                                                Videos
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                                        {items.map((activationItem) => (
                                                                            <TableRow
                                                                                key={activationItem.activationItemId}
                                                                                activationItem={activationItem}
                                                                                brandProfileId={brandProfileId}
                                                                                removedKeywordsActivationItemIds={
                                                                                    removedKeywordsActivationItemIds
                                                                                }
                                                                                removedVideosActivationItemIds={
                                                                                    removedVideosActivationItemIds
                                                                                }
                                                                                setRemovedKeywordsActivationItemIds={
                                                                                    setRemovedKeywordsActivationItemIds
                                                                                }
                                                                                setRemovedVideosActivationItemIds={
                                                                                    setRemovedVideosActivationItemIds
                                                                                }
                                                                                adGroups={activationsAdgroups
                                                                                    .filter(
                                                                                        (i) =>
                                                                                            i.activationItemId ===
                                                                                            activationItem.activationItemId
                                                                                    )
                                                                                    .map((i) => i.id)}
                                                                                setAdGroups={(adGroupIds: number[]) => {
                                                                                    setActivationsAdGroups((prev) => {
                                                                                        const arr = prev.filter(
                                                                                            (i) =>
                                                                                                i.activationItemId !==
                                                                                                activationItem.activationItemId
                                                                                        )
                                                                                        const newAdGroups =
                                                                                            adGroupIds.map((a) => {
                                                                                                return {
                                                                                                    id: a,
                                                                                                    activationItemId:
                                                                                                        activationItem.activationItemId
                                                                                                }
                                                                                            })

                                                                                        return arr.concat(newAdGroups)
                                                                                    })
                                                                                }}
                                                                            />
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

type TableRowProps = {
    activationItem: ActivationItemType
    brandProfileId: number | undefined
    setAdGroups: (adGroupIds: number[]) => void
    adGroups: number[]
    removedKeywordsActivationItemIds: number[]
    removedVideosActivationItemIds: number[]
    setRemovedKeywordsActivationItemIds: Function
    setRemovedVideosActivationItemIds: Function
}
const TableRow = ({
    activationItem,
    brandProfileId,
    setAdGroups,
    adGroups,
    removedKeywordsActivationItemIds,
    removedVideosActivationItemIds,
    setRemovedKeywordsActivationItemIds,
    setRemovedVideosActivationItemIds
}: TableRowProps) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const adGroupQuery = useQuery(
        ['adGroups', activationItem.clusterId],
        () => activationsApi.getAdgroupsOptions({ ioIds: activationItem.ioIds, brandProfileId }),
        {
            enabled:false
        }
    )
    React.useEffect(()=>{
        adGroupQuery.refetch()
    },[])
    

    const nonAdGroupIds = React.useMemo(() => {
        let ids = []
        if (!adGroupQuery.data) return []
        for (const mcc of adGroupQuery.data) {
            ids.push(mcc.id)
            for (const cid of mcc.children) {
                ids.push(cid.id)
                for (const campaign of cid.children) {
                    ids.push(campaign.id)
                }
            }
        }
        return ids
    }, [adGroupQuery.data])
    return (
        <tr key={activationItem.activationItemId}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 truncate max-w-lg">
                <div className="truncate">{activationItem.clusterName}</div>
                <div className="statsContainer mt-2">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <KeywordsIcon />
                        <div className="text-xs text-gray-500 pl-1">
                            {activationItem.keywordCount === 1
                                ? '1 keyword'
                                : activationItem.keywordCount + ' keywords'}
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <BlackYoutube />
                        <div className="text-xs text-gray-500 pl-1">
                            {activationItem.videoCount === 1 ? '1 video' : activationItem.videoCount + ' videos'}
                        </div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="flex gap-2 items-center">
                    {activationItem.personas.slice(0, 2).map((persona) => {
                        return (
                            <SmallBadge
                                key={persona}
                                text={persona}
                                onClose={() => undefined}
                                disabled={true}
                            />
                        )
                    })}
                    {activationItem.personas.length > 2 && (
                        <div className="text-xs">+ {activationItem.personas.length - 2} others</div>
                    )}
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <CheckTreePicker
                    uncheckableItemValues={nonAdGroupIds}
                    placeholder="Select AdGroups"
                    data={adGroupQuery.data || []}
                    labelKey="name"
                    cascade={false}
                    valueKey="id"
                    onChange={(v) => {
                        setAdGroups(v)
                    }}
                    renderMenu={(menuTree) => {
                        if (adGroupQuery.isLoading ) {
                            return (
                                <div className='flex justify-center py-3'>
                                <div  className='spinning-loader mx-5'></div>
                                    <p className='text-center'>Loading...
                                </p>
                                </div>

                            );
                        }
                        if(adGroupQuery.isError) {
                            return <div className='text-sm px-2 pb-1 text-red-500'>Associated Insertion order/s is invalid</div>
                        }
                        return menuTree;
                    }}
                    value={adGroups}
                    block
                    placement={'autoVerticalEnd'}
                    disabled={false}
                    style={{ width: 250 }}
                    onOpen={() => setIsOpen(true)}
                />
            </td>
            
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <SightlyToggle
                    id="keywords"
                    enabled={!removedKeywordsActivationItemIds.includes(activationItem.activationItemId)}
                    setEnabled={(val: boolean) =>
                        setRemovedKeywordsActivationItemIds((prev: any) => {
                            return val
                                ? prev.filter((v: number) => v !== activationItem.activationItemId)
                                : prev.concat(activationItem.activationItemId)
                        })
                    }
                />
            </td>
            <td className="whitespace-nowrap py-4 pl-5 pr-4 text-right text-sm font-medium sm:pr-6">
                <SightlyToggle
                    id="videos"
                    enabled={!removedVideosActivationItemIds.includes(activationItem.activationItemId)}
                    setEnabled={(val: boolean) =>
                        setRemovedVideosActivationItemIds((prev: any) => {
                            return val
                                ? prev.filter((v: number) => v !== activationItem.activationItemId)
                                : prev.concat(activationItem.activationItemId)
                        })
                    }
                />
            </td>
        </tr>
    )
}
