// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    padding: 60px;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sightlyPanelHeader {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.badge {
    padding: 0.25em 0.5em;
    border-radius: 12px;
    font-size: 0.875em;
}

.hashtags{
    width: 100%;
}

.main-title{
    font-size: 26px;
}

.rs-picker-default .rs-picker-toggle{
    z-index: 0;
}

`, "",{"version":3,"sources":["webpack://./src/views/TikTok/Hashtags/hashtags.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".container {\n    padding: 60px;\n    background-color: white;\n    border-radius: 10px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.sightlyPanelHeader {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-bottom: 20px;\n}\n\n.badge {\n    padding: 0.25em 0.5em;\n    border-radius: 12px;\n    font-size: 0.875em;\n}\n\n.hashtags{\n    width: 100%;\n}\n\n.main-title{\n    font-size: 26px;\n}\n\n.rs-picker-default .rs-picker-toggle{\n    z-index: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
