import React from 'react';
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import { SightlyTag } from '@/components/Sightly/SightlyTag';
import { ReactComponent as LightBulbIcon } from '@/assets/img/LightBulbIcon.svg';
import { Whisper, Popover, Dropdown, IconButton, Icon, Divider } from 'rsuite/lib';
import MomentInsightsModal from './MomentInsightsModal';
import { useSearch } from '@tanstack/react-location';
import { useMomentInsights } from './MomentInsightsModal/useMomentInsights';
import { MomentInsightsListType } from './MomentInsightsModal/types';
import { ClusterType } from '@/views/Discover/Moments/v2/types';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import { Board } from '@/views/Discover/Boards/useBoards';
import { MyLocationGenerics } from '@/classes/utils';
import { ReactComponent as GarmFlagIcon } from '@/assets/img/GarmFlagIcon.svg';
import { ActivationStatusBadge } from '@/views/Discover/Moments/v2/components/ActivationStatusBadge'
import { ActionBadgeWithIcon } from '@/views/Discover/Moments/v2/components/ActionBadgeWithIcon'
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import Tooltip from '@/components/TailwindTooltip';
import dayjs from 'dayjs';
import { RelevanceClassificationBadge } from '@/views/Discover/Moments/v2/components/RelevanceClassificationBadge';



interface IProps {
  brandProfileId: number
  trendingMoments: Board
  handleTrendNameClick: Function
  moment: ClusterType
  momentsIsLoading: boolean
  checked: boolean
  handleSelectMoment: Function
}


let tableBody: HTMLElement
let trigger: any = null

const MomentEllipsesMenu = ({
  handleSelectMenu,
  handleOpenInsights
}: {
  handleSelectMenu: Function
  handleOpenInsights: Function
}) => {
  return (
    <div data-testid="anticipation-boards-moment-card-ellipse-menu">
      <Whisper
        placement="topEnd"
        trigger="click"
        ref={(ref: React.RefObject<any>) => {
          trigger = ref
        }}
        container={() => {
          return tableBody
        }}
        speaker={
          <Popover full>
            <Dropdown.Menu
              onSelect={handleSelectMenu}
              className="min-w-max"
            >
              <Dropdown.Item onClick={handleOpenInsights}>Why this moment ?</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        }
      >
        <IconButton
          size="lg"
          appearance="subtle"
          circle
          className="rotate-90"
          icon={
            <Icon
              icon="more"
              className="text-gray-700"
            />
          }
        />
      </Whisper>
    </div>
  )
}

const MomentCard: Function = React.memo(
  ({
    brandProfileId,
    trendingMoments,
    moment,
    handleTrendNameClick,
    momentsIsLoading,
    checked,
    handleSelectMoment
  }: IProps) => {
    const [showInsightModal, setShowInsightModal] = React.useState(false)
    const [momentInsights, setMomentInsights] = React.useState<MomentInsightsListType>()
    const [momentInsightsError, setMomentInsightsError] = React.useState<any>()
    const { insightsQuery } = useMomentInsights({ brandProfileId, clusterId: moment.clusterId })

    // onUnmount
    React.useEffect(() => {
      return () => {
        setShowInsightModal(false)
      }
    }, [])

    const getKeywordCount = () => {
      if (moment.action === 'target') {
        return moment.targetKeywordsCount;
      } else if (moment.action === 'block') {
        return moment.blockKeywordsCount;
      } else {
        return moment.targetKeywordsCount + moment.blockKeywordsCount;
      }
    }



    return (
      <div
        data-testid="anticipation-boards-moment-card"
        className="relative flex items-center my-4 bg-white shadow gap-y-2 animate-in fade-in rounded-xl"
        key={moment.clusterId}
      >

        {moment.garmFlagged && (
          <div className="absolute top-[-10px] left-[-10px]">
            <GarmFlagIcon data-testid="moment-card-garm-flagged-icon" className="inline mr-2" />
          </div>
        )}
        <div
          data-testid="anticipation-boards-moment-card-checkbox"
          className="absolute border-2 rounded-md cursor-pointer top-2 left-2 border-sightlyBlue"
        >
          <SightlyCheckbox
            id="selectedIs"
            disabled={momentsIsLoading}
            checked={checked}
            handleChange={(bool: boolean) => {
              handleSelectMoment(moment)
            }}
          />
        </div>
        <div
          className="flex items-center flex-grow cursor-pointer hover:bg-gray-50"
          onClick={() => handleTrendNameClick(moment)}
        >
          <img
            data-testid="anticipation-boards-moment-card-image"
            src={moment.imageUrl}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = NoImageFoundPath
            }}
            alt={`image:${moment.clusterName}`}
            className="object-cover w-32 h-32 rounded-l-xl"
          />

          <div className="flex items-center grow justify-evenly">
            <div className="flex flex-col justify-center flex-1 w-2/3 pl-4 ">
              <div className="flex items-center gap-2 mb-2">
                <RelevanceClassificationBadge relevanceClassification={moment.relevanceClassification}/>
                {/* Recommended Action*/}
                <div
                  data-testid="anticipation-boards-moment-card-recommended-action"
                  className="flex items-center pl-1 text-xs text-gray-500"
                >
                  <LightBulbIcon />
                  <ActionBadgeWithIcon type={moment.action} />
                </div>
              </div>

              {/* Moment Title*/}
              <div
                id={`trendTitle-${moment.clusterId}`}
                className="flex-wrap min-w-0 overflow-hidden text-base font-semibold text-gray-900 break-words"
              >
                <span data-testid="anticipation-boards-moment-card-headline">
                  {moment.clusterName.substring(0, 90)}{moment.clusterName.length > 89 ? '...' : ''}
                </span>
                {moment.minPublishedAt && (
                  <div data-testid="moment-card-first-published-date" className="mt-1 text-xs text-gray-400">
                    Published date: {dayjs(moment.minPublishedAt.toString()).format('MMMM D, YYYY')}
                  </div>
                )}
              </div>

              {/* Activations*/}
              <div className="flex gap-2">
                {/* Blocking */}
                {moment.activationStatuses && moment.activationStatuses.map((activationStatus, index) => {
                  return <ActivationStatusBadge
                    key={`${moment.clusterId}_${activationStatus.action}_${activationStatus.status}_${index}`}
                    activationStatus={activationStatus}
                  />
                })}
              </div>
            </div>

            <div className="ml-6">
              <Tooltip
                theme={'light'}
                content={
                  <div
                    className="p-4 text-left"
                  >
                    <p>
                      Daily article count for the last 30 days after the moment has been published, indicating the current velocity of the moment.
                    </p>
                    <div className="flex justify-between w-full mt-4 row">
                      <span>
                        First published
                      </span>
                      <span data-testid="moment-card-first-published-date-tooltip" className="font-bold">
                        {dayjs(moment.minPublishedAt.toString()).format('MMMM D, YYYY')}
                      </span>
                    </div>
                    <hr />
                    {moment.articleCountPerDay && (<div className="flex justify-between w-full my-2 row">
                      <span>
                        Last 24 hr. article count
                      </span>
                      <span className="font-bold">
                        {moment.articleCountPerDay[
                          moment.articleCountPerDay.length - 1
                        ]?.story_count}
                      </span>
                    </div>)}
                  </div>
                }
              >
                <div data-testid="moment-card-article-count-chart-div">
                  <LineChart
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    width={90} height={40} data={moment.articleCountPerDay}
                  >
                    <XAxis dataKey="name" tick={false} width={1} height={0} />
                    <YAxis tick={false} width={0} domain={['dataMin', 'dataMax']} />
                    <Line type="monotone" dataKey="story_count" stroke="#7748f9" dot={false} />
                  </LineChart>
                </div>
              </Tooltip>
            </div>

            <div className="flex w-1/3 select-none justify-evenly">
              <div>
                <p
                  data-testid="anticipation-boards-moment-card-articles"
                  className="pl-1 text-sm font-bold text-highlightPurple"
                >
                  {moment.articleCount}
                </p>
                <p>Articles</p>
              </div>
              <div>
                <p
                  data-testid="anticipation-boards-moment-card-keywords"
                  className="pl-1 text-sm font-bold text-highlightPurple"
                >
                  {getKeywordCount()}
                </p>
                <p>Keywords</p>
              </div>
              <div>
                <p
                  data-testid="anticipation-boards-moment-card-videos"
                  className="pl-1 text-sm font-bold text-highlightPurple"
                >
                  {moment.videoCount}
                </p>
                <p>Videos</p>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full">
          <MomentEllipsesMenu
            handleSelectMenu={(eventKey: any, event: any) => {
              trigger.hide()
              insightsQuery.refetch().then((query) => {
                if (query.error) {
                  setMomentInsightsError(query.error)
                } else {
                  setMomentInsights(query.data)
                }
              })
            }}
            handleOpenInsights={() => setShowInsightModal(true)}
          />
          {showInsightModal && (
            <MomentInsightsModal
              show={showInsightModal}
              handleClose={() => setShowInsightModal(false)}
              momentInsights={momentInsights}
              trendingMoments={trendingMoments}
              momentInsightsError={momentInsightsError}
            />
          )}
        </div>
      </div>
    )
  }
)

export default MomentCard;
