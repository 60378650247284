import { useQuery } from '@tanstack/react-query';
import { logError } from '@/utils';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { api } from '@/api/api';

const postUserCompletionStep = async (videoId:string) => {
  const userId = localStorage.getItem('userId'); // Use authenticated user's id
  return await api.userCompletionSteps.post(Number(userId), videoId);
};

export default function useUserCompletionSteps() {
  const userId = localStorage.getItem('userId'); // Use authenticated user's id

  const userCompletionSteps = useQuery(rqKeys.userCompletionSteps(Number(userId)),
    () => api.userCompletionSteps.get(Number(userId)),
    {
      refetchInterval: 20000,
      onError: (err: any) => {
        logError(err);
      }
    });

  return { ...userCompletionSteps, postUserCompletionStep }
}