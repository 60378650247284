import React from 'react';
import useUser from '@/hooks/useUser';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { location } from '@/RouteContainer';
import { sharedContexts } from '@/sharedContexts/sharedContexts';
import { MyLocationGenerics } from '@/classes/utils';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import config from '@/config';
import { products } from '@/staticData/products';
import { useFlag } from '@unleash/proxy-client-react';

export default function useSteps() {
  const { disableBrandProfileStepsClick } = React.useContext(sharedContexts);
  const { viewOnly } = useSearch<MyLocationGenerics>()
  const { perms, userPermissions, userProducts } = useUser();
  const navigate = useNavigate()
  const steps = [
    {
      name: 'brandInformation',
      humanName: 'Brand Information',
      userCanView: true,
      parentName: 'brandInformation'
    },
    {
      name: 'queries',
      humanName: 'Queries',
      userCanView: userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_READ,
        requiredProductValue: products.BRAND_PROFILES,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      }),
      parentName: 'queries',
      ...(!config.featureFlag.aiProfiles && {children: [
        {
          name: 'brands',
          humanName: 'Brands',
          userCanView: true,
          parentName: 'queries'
        },
        {
          name: 'influencers',
          humanName: 'Influencers',
          userCanView: true,
          parentName: 'queries'
        },
        {
          name: 'events',
          humanName: 'Events',
          userCanView: true,
          parentName: 'queries'
        },
        {
          name: 'social',
          humanName: 'Social Causes',
          userCanView: true,
          parentName: 'queries'
        },
        {
          name: 'philanthropic',
          humanName: 'Philanthropic Orgs',
          userCanView: true,
          parentName: 'queries'
        }
      ]})
    },
    {
      name: 'brandPositioning',
      humanName: 'Brand Positioning',

      
      userCanView: userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_READ,
        requiredProductValue: products.BRAND_PROFILES,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      }),
      parentName: 'brandPositioning',
      children: [
        {
          name: 'opinions',
          humanName: 'Opinions',
          userCanView: true,
          parentName: 'brandPositioning'
        },
        {
          name: 'scenarios',
          humanName: 'Scenarios',
          userCanView: true,
          parentName: 'brandPositioning'
        }
      ]
    },
    {
      name: 'mediaResponsibility',
      parentName: 'mediaResponsibility',
      humanName: 'Media Responsibility',
      userCanView: userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
      }), //<-- this controls if user sees the STEP / circle  on the ui.
    },
    {
      name: 'keywordsGroup',
      parentName: 'keywordsGroups',
      humanName: 'Advanced Settings',
      userCanView: userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_ADVANCED_SETTINGS_READ,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
      }),
      children: [
        {
          name: 'iabCategories',
          humanName: 'Categories',
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_CATEGORIES_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          }),
          permissionNeeded: perms.BRAND_PROFILE_CATEGORIES_READ,
          parentName: 'keywordsGroup'
        },
        {
          name: 'topics',
          humanName: 'Topics',
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_TOPICS_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          }),
          permissionNeeded: perms.BRAND_PROFILE_TOPICS_READ,
          parentName: 'keywordsGroup'
        },
        {
          name: 'news',
          humanName: 'News',
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_AYLIEN_NEWS_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          }),
          permissionNeeded: perms.BRAND_PROFILE_AYLIEN_NEWS_READ,
          parentName: 'keywordsGroup'
        },
        {
          name: 'industries',
          humanName: 'Industries',
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          }),
          permissionNeeded: perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ,
          parentName: 'keywordsGroup'
        },
        {
          name: 'keywords',
          humanName: 'Keywords',
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_KEYWORDS_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          }),
          permissionNeeded: perms.BRAND_PROFILE_KEYWORDS_READ,
          parentName: 'keywordsGroup'
        },
        {
          name: 'googleAds',
          parentName: 'keywordsGroup',
          humanName: 'Google Ads Content',
          permissionNeeded: perms.BRAND_PROFILE_IOS_UPDATE,
          userCanView: userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_IOS_UPDATE,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
          })
        }
      ]
    },
    {
      name: 'targetview',
      parentName: 'targetview',
      humanName: 'TargetView IOs',
      permissionNeeded: perms.BRAND_PROFILE_IOS_READ,
      userCanView: userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_IOS_READ,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
      })
    },
    {
      name: 'submit',
      parentName: 'submit',
      humanName: 'Review and Submit',
      userCanView: true
    }
  ];

  const _steps = React.useMemo(() => {
    const mainSteps = steps.filter((s) => s.userCanView)
    for (const s of mainSteps) {
      if (s.children) {
        s.children = s.children.filter((c) => c.userCanView)
      }
    }
    const final = mainSteps.filter((s) => !s.children || s.children.length > 0)

    return final
  }, [steps])

  const getCurrentStep = () => {
    let currentStep:string|undefined;
    const currentPath = window.location.pathname.split('/');
    if(window.location.pathname.includes('/queries/')){
      currentPath.pop()
      currentStep = currentPath.pop();
    }else{
      currentStep = currentPath.pop()

    }
    return currentStep
  }

  const activeStep = React.useMemo(() => {
    return (config.featureFlag.aiProfiles ? getCurrentStep(): window.location.pathname.split('/').pop()) || ''
  }, [location.current.pathname])

  const handleStepsClick = (step: string) => {
    if (viewOnly || !disableBrandProfileStepsClick) { // Context api state set by BasicInfo to disable steps click if required fields not filled
      let newSection;
      _steps.forEach((element, index) => {
        if (element.name === step) {
          if (element.children) {
            newSection = element.children[0].name;
          }
        }
      });

      const lastIndex = location.current.pathname.lastIndexOf('/queries');
      
      let newUrl = (config.featureFlag.aiProfiles ?
        location.current.pathname.substring(0, (lastIndex > -1 ? lastIndex : location.current.pathname.lastIndexOf('/'))):
        location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/'))) +
        `/${newSection ? newSection : step}`;

      navigate({
        to: newUrl,
        search: (prev) => {
          return {
            ...prev
          }
        }
      });
    }
  };

  const handleContinueClick = () => {
    // find parentName
    const currentStep = config.featureFlag.aiProfiles ? getCurrentStep() : window.location.pathname.split('/').pop()
    let parentName: string
    for (const p of _steps) {
      if (p.name === currentStep) {
        parentName = p.parentName
      }
      if (p.children) {
        for (const child of p.children) {
          if (child.name === currentStep) {
            parentName = child.parentName
          }
        }
      }
    }

    let newIndex: number | null = null
    let newSection
    _steps.forEach((element, index) => {
      if (element.name === parentName) {
        if (element.children) {
          let indexOfCurrentChild
          element.children.forEach((child, childIndex) => {
            if (currentStep === child.name) {
              indexOfCurrentChild = childIndex
            }
          })
          if (indexOfCurrentChild !== undefined && indexOfCurrentChild < element.children.length - 1) {
            newSection = element.children[indexOfCurrentChild + 1].name
            return
          }
        }
        newIndex = index + 1
      }
    })
    const nextStep = newIndex &&  _steps[parseInt(newIndex)]?.name
    //find first child based off nextStep
    if (nextStep) {
      handleStepsClick(nextStep)
    } else {
      let newUrl =
        location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/')) +
        `/${newSection ? newSection : nextStep}`
      navigate({
        to: newUrl,
        search: (prev) => {
          return {
            ...prev
          }
        }
      })
    }
  }
  
  const getCurrentProgress = React.useMemo(() => {
    // find parentName
    let currentParentIndex = 0
    let currentChildIndex = 0
    let childLength = 0
    _steps.forEach((p, parentIndex) => {
      if (p.name === activeStep) {
        currentParentIndex = parentIndex
      }
      if (p.children) {
        p.children.forEach((child, index) => {
          if (child.name === activeStep) {
            currentParentIndex = parentIndex
            currentChildIndex = index
            childLength = p.children ? p.children.length : 0
          }
        })
      }
    })
    const currentParentProgress = (currentParentIndex / (_steps.length - 1)) * 100
    const currentChildProgress = childLength ? (currentChildIndex / childLength / (_steps.length - 1)) * 100 : 0
    return currentParentProgress + currentChildProgress
  }, [activeStep])

  const handlePreviousClick = () => {
    const currentStep = config.featureFlag.aiProfiles ? getCurrentStep() : window.location.pathname.split('/').pop()
    // find parentName
    let parentName: string = ''
    for (const p of _steps) {
      if (p.name === currentStep) {
        parentName = p.parentName
      }
      if (p.children) {
        for (const child of p.children) {
          if (child.name === currentStep) {
            parentName = child.parentName
          }
        }
      }
    }
    let newIndex:number|null = null
    let newSection:string = ''
    _steps.forEach((element, index) => {
      if (element.name === parentName) {
        if (element.children) {
          let indexOfCurrentChild
          element.children.forEach((child, childIndex) => {
            if (currentStep === child.name) {
              indexOfCurrentChild = childIndex
            }
          })
          
          if (indexOfCurrentChild) {
            newSection = element.children[indexOfCurrentChild - 1].name
            return
          }
        }
        newIndex = index - 1
      }
    })

    const nextStep = newIndex && _steps[parseInt(newIndex)]?.name
    if (nextStep) {
      handleStepsClick(nextStep)
    } else {
      const lastIndex = location.current.pathname.lastIndexOf('/queries')
      let newUrl =(config.featureFlag.aiProfiles ?
        location.current.pathname.substring(0, (lastIndex > -1 ? lastIndex : location.current.pathname.lastIndexOf('/'))):
        location.current.pathname.substring(0, location.current.pathname.lastIndexOf('/'))) +
        `/${newSection ? newSection : (nextStep || 'brandInformation')}`

      navigate({
        to: newUrl,
        search: (prev) => {
          return {
            ...prev
          }
        }
      })
    }
  }

  return {
    steps,
    _steps,
    getCurrentStep,
    activeStep,
    handleStepsClick,
    handleContinueClick,
    getCurrentProgress,
    handlePreviousClick
  }
}