import React, {useState} from 'react';
import { Icon } from 'rsuite';
import { VerticalTimeline, TimelineStep } from "@/components/VerticalTimeline"
import {PATH_BRAND_PROFILES, PATH_MOMENTS_V2} from "@/routes";
import { Panel } from "@/views/OnboardingHub/GettingStarted/components/Panel";
import {BrandProfile} from "@/classes/brandProfile";
import { completeGreen } from '@/assets/jss/colorConstants';

type TeamSuccessProps = {
  submittedBrandProfiles: BrandProfile[] | undefined
}

export const getTimelineSteps = (): TimelineStep[] => {
  return [
    {
      title: 'CREATE ACCOUNT',
      description: 'Create an account for your team',
      complete: true,
      disabled: true,
      initialIcon: <Icon data-testid="timeline-item-icon" icon="check" size="2x" />,
      completeIcon: <Icon data-testid="timeline-item-icon" style={{ background: completeGreen, color: '#fff', borderColor: completeGreen }} icon="check" size="2x" />,
      routePath: null
    },
    {
      title: 'SUBMIT A BRAND PROFILE',
      description: 'Everything starts with the way Brands view the world. Submit a Brand Profile to unlock the power of Brand Mentality',
      complete: false,
      disabled: false,
      initialIcon: <Icon data-testid="timeline-item-icon" icon="check" size="2x" style={{ color: '#fff'}}/>,
      completeIcon: <Icon data-testid="timeline-item-icon" style={{ background: completeGreen, color: '#fff', borderColor: '#fff' }} icon="check" size="2x" />,
      routePath: PATH_BRAND_PROFILES,
    },
    {
      title: 'BUILD ANTICIPATION BOARDS',
      description: 'Create anticipation boards to follow topics & queries that matter most',
      complete: false,
      disabled: true,
      initialIcon: <Icon data-testid="timeline-item-icon" icon="lock" size="2x" />,
      completeIcon: <Icon data-testid="timeline-item-icon" icon="unlock" size="2x" style={{ color: completeGreen, borderColor: completeGreen }}/>,
      routePath: PATH_MOMENTS_V2
    },
  ]
}

export const TeamSuccess = ({ submittedBrandProfiles }: TeamSuccessProps) => {
  const [timelineSteps, setTimelineSteps] = useState<TimelineStep[]>(getTimelineSteps());

  const hasSubmittedBrandProfile: boolean = !!(submittedBrandProfiles && submittedBrandProfiles?.length > 0)

  React.useEffect(() => {
    if (hasSubmittedBrandProfile) {
      const updatedSteps = timelineSteps.slice();
      updatedSteps[1].complete = true;

      // Unlocks anticipation board step when Submit Brand Profile step is complete
      updatedSteps[2].complete = true;
      updatedSteps[2].disabled = false;

      setTimelineSteps(updatedSteps);
    }
  }, [submittedBrandProfiles, submittedBrandProfiles?.length])

  return (
    <Panel
      headerText="Set up your team for success"
      subHeaderText="Complete to enable your teams use of the platform."
      dataTestId="team-success"
      className="border-b"
    >
      <VerticalTimeline
        steps={timelineSteps}
      />
    </Panel>
  )
}
