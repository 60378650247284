import React from 'react'
import { ReactComponent as MonitorIcon } from '@/assets/img/monitor.svg'
import { ReactComponent as BlockIcon } from '@/assets/img/block.svg'
import { ReactComponent as TargetIcon } from '@/assets/img/target.svg'
import { ReactComponent as DontMonitorIcon } from '@/assets/img/dontmonitor.svg'
import { ChevronDownIcon, NoSymbolIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

interface IProps {
    type: 'target' | 'monitor' | 'block' | 'dontmonitor'
    handleClick: () => void
    dataTestId?: String
}

export const ActionButton = ({ type, handleClick, dataTestId }: IProps) => {
    const icon =
        type === 'monitor' ? (
            <MonitorIcon />
        ) : type === 'target' ? (
            <TargetIcon />
        ) : type === 'block' ? (
            <NoSymbolIcon
                style={{ height: 16, width: 16 }}
                aria-hidden="true"
                color="#DC2626"
            />
        ) : (
            <DontMonitorIcon />
        )

    const text =
        type === 'monitor' ? 'Monitor' : type === 'target' ? 'Target' : type === 'block' ? 'Block' : `Do not monitor`

    return (
        <button
            data-testid={dataTestId}
            className="bg-white hover:bg-slate-100 "
            style={{
                color: '#333D47',
                alignItems: 'center',
                display: 'flex',
                gap: 14,
                borderRadius: 6,
                fontSize: 12,
                fontWeight: 700,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 4,
                paddingBottom: 4,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
            }}
            onClick={handleClick}
        >
            {icon}
            {text}
        </button>
    )
}
