import React from 'react';
import chevronDown from '@/assets/img/DropSelector.png';
import './../index.css';
import SightlyAvatar from '@/components/SightlyAvatar';
import ClickAwayListener from 'react-click-away-listener';
import Tree from 'rsuite/lib/Tree';
import debounce from 'just-debounce-it';
import { routes } from '@/routes';
import { permissionView } from '@/utils';
import { useNavigate } from '@tanstack/react-location';
import { location } from '@/RouteContainer';
import Icon from 'rsuite/lib/Icon';
import Input from 'rsuite/lib/Input';
import InputGroup from 'rsuite/lib/InputGroup';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

export const HorizontalNavAccountItem: React.FC<{ accountName?: string }> = ({ accountName }) => {
    const [open, setOpen] = React.useState(false)
    const [searchTerm, setSearchTerm] = React.useState('')
    const { accounts, currentAccount, perms } = useUser()

    const hasMoreThanSingleAccount =
        accounts !== undefined &&
        accounts.length >= 1 &&
        (accounts.length > 1 ||
            (accounts[0].children !== undefined && accounts[0].children.length >= 1))

      const navigate = useNavigate()
    const handleSearch = debounce((text: string) => {
        setSearchTerm(text)
    }, 400)

    const handleAccountSelect = (accountId: number) => {
        setOpen(false)
        setSearchTerm('')
        navigate({
            to: location.current.pathname.includes(`/app/brandProfiles/brandProfile`)
                ? routes.app.brandProfiles.path
                : location.current.pathname,
            search: (old = {}) => {
                const newData = old;
                newData.accountId = accountId;
                newData.brandProfileId = undefined
                return newData;
            }
        })
    }

    const { userPermissions } = useUser();
    const canReadAccount = userCanPermissionProductQuota({
        requiredPermissionValue: perms.ACCOUNT_READ,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    })

    return (
        <div
            style={{
                borderRadius: 2,
                position: 'relative',
                paddingRight: 10,
                color: 'black',
                height: 56,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                marginRight: 90
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: hasMoreThanSingleAccount
                        ? canReadAccount ? 'pointer' : 'not-allowed'
                        : 'default'
                }}
                onClick={() => {
                    if (!canReadAccount || !hasMoreThanSingleAccount) return
                    setOpen(true)
                }}
            >
                <div
                    style={{
                        width: 60,
                        textAlign: 'center'
                    }}
                >
                    <SightlyAvatar
                        text={accountName ?? ''}
                        type="blue"
                        size="small"
                    />
                </div>
                <div
                    style={{
                        flex: 1,
                        textAlign: 'left',
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: '24px',
                        minWidth: 122
                    }}
                    className="line-clamp-1"
                >
                    {accountName}
                </div>

                {hasMoreThanSingleAccount &&
                    <div
                        style={{
                            paddingLeft: 12,
                            textAlign: 'left'
                        }}
                    >
                        <img
                            style={{
                                backgroundColor: '#F5F5F5',
                                borderRadius: 6
                            }}
                            width={24}
                            src={chevronDown}
                        />
                    </div>
                }
            </div>

            {open && (
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false)
                        setSearchTerm('')
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 64,
                            left: 14,
                            backgroundColor: 'white',
                            zIndex: 99999,
                            //height: 500,
                            overflowY: 'auto',
                            borderRadius: 6,
                            border: '1px solid rgb(224, 224, 224)',
                            minWidth: 400,
                            padding: 8
                        }}
                    >
                        {permissionView && <div style={{ color: 'red' }}>{perms.ACCOUNT_READ}</div>}
                        <InputGroup>
                            <Input
                                placeholder="Search Accounts"
                                onChange={(val) => handleSearch(val)}
                            />

                            <InputGroup.Button
                                disabled
                                style={{ backgroundColor: 'transparent' }}
                            >
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>

                        <div style={{ height: 8 }} />
                        <Tree
                            showIndentLine
                            searchKeyword={searchTerm}
                            autoHeight
                            defaultValue={currentAccount?.accountId}
                            defaultExpandItemValues={
                                currentAccount?.parentAccountId ? [1, currentAccount.parentAccountId] : [1]
                            }
                            defaultExpandAll={false}
                            labelKey="accountName"
                            valueKey="accountId"
                            data={accounts}
                            onSelect={(account) => handleAccountSelect(account.accountId)}
                        />
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}
