import Dropdown from "rsuite/lib/Dropdown";
import Icon from "rsuite/lib/Icon";
import IconButton from "rsuite/lib/IconButton";
import Popover from "rsuite/lib/Popover";
import React from "react";
import Whisper from "rsuite/lib/Whisper";

import { getCalendarDate } from "../../../utils";
import { accentColor } from "../../../assets/jss/colorConstants";
import { ReactComponent as DownloadImage } from '../../../assets/img/documentDownloadIcon.svg'

import { ReportConfiguration } from '../reportGenerator-types'

interface IProps {
    rowData: {
        id: number,
        updatedAt: Date,
        updatedBy: string,
        configuration: ReportConfiguration
    },
    downloadReport: Function,
    deleteConfiguration: {
        mutate: Function
    }
}

export default function TableRow({ rowData, downloadReport, deleteConfiguration }: IProps) {
    // @ts-ignore useRef type and rsuite component types are incompatible
    const ref = React.useRef<HTMLDivElement>(undefined)

    return (
        <tr key={rowData.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <div className="text-sm font-medium truncate max-w-lg text-gray-900">
                    {rowData.configuration.reportName}
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {getCalendarDate(rowData.updatedAt)}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {rowData.updatedBy}
            </td>
            <td className="grid place-content-center py-6">
                <DownloadImage
                    data-testid='icon-report-generator-download-button'
                    className="cursor-pointer"
                    onClick={() => downloadReport(rowData.configuration)}
                />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <div
                    data-testid='div-report-generator-actions-dropdown'
                    className="more"
                >
                    <Whisper
                        container={() => ref.current}
                        placement="topEnd"
                        trigger="click"
                        speaker={
                            <Popover full>
                                <Dropdown.Menu >
                                    <Dropdown.Item
                                        data-testid='div-report-generator-delete-button'
                                        onClick={() => deleteConfiguration.mutate(rowData.id)}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Popover>
                        }
                    >
                        <IconButton
                            size="lg"
                            appearance="default"
                            icon={
                                <Icon
                                    icon="more"
                                    color={accentColor}
                                />
                            }
                        />
                    </Whisper>
                </div>
            </td>
        </tr>
    )
}
