import { patchVersionDataService } from '@/services/lists';

export class Video {
	constructor(
		public actionId: number | null,
		readonly categoryName: string,
		readonly channelName: string,
		readonly published: string,
		readonly description: string,
		readonly id: string,
		readonly name: string,
		readonly thumbnail: string,
		readonly views: number,
		readonly likes: number,
		readonly comments: number,
		readonly versionId: number,
		readonly topTargetCategories: string[],
		readonly topMonitorCategories: string[],
		readonly topBlockCategories: string[],
		public garmFeedbackIds: number[]
	) {}

	public changeAction(_actionId: number): Video {
		let args = {
			versionId: this.versionId,
			data: { actionId: _actionId, id: this.id }
		}

		patchVersionDataService(args)

		return new Video(
			_actionId,
			this.categoryName,
			this.channelName,
			this.published,
			this.description,
			this.id,
			this.name,
			this.thumbnail,
			this.views,
			this.likes,
			this.comments,
			this.versionId,
			this.topTargetCategories,
			this.topMonitorCategories,
			this.topBlockCategories,
			this.garmFeedbackIds
		)
	}
}
