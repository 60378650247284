import { useQuery } from '@tanstack/react-query'
import { logError } from '@/utils'
import { activationsApi } from '@/views/Discover/Activations/v2/hooks/activations-api'
import { activationsRqKeys } from './activationsRqKeys'

export const useBrandProfilesActivationsStatus = (props: { brandProfileIds: number[] }) => {
  const { brandProfileIds } = props
  const statusQuery = useQuery(
    activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds),
    () => activationsApi.getBrandProfilesActivationStatus({ brandProfileIds }),
    {
      onError: (err) => {
        logError(err)
      },
      enabled: !!brandProfileIds,
      refetchInterval: 30000
    }
  )
  return statusQuery
}
