import toast from 'react-hot-toast'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { userAccountAxios } from '../../../../../axiosInstances'
import { opinionsObjValidation } from '../../../../../schemas/brandProfiles'
import { rqKeys } from '../../../../../ReactQueryKeyFactory'

const getBrandProfileOpinions = async (brandProfileId: number) => {
    let url = `/brand-profile/${brandProfileId}/opinions`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            opinionsObjValidation.validate(result.data.slice(0, 3)).catch(function(err) {
                console.log(err.name, err.errors)
                console.error(
                    ' we received different data from the api than expected while fetching brand profile opinions, see console log for more details'
                )
            })
        }
        return result.data
    } catch (error) {
        console.error(error)
    }
}

export const patchBrandProfileOpinions = async (data: any) => {
    let brandProfileId = data.brandProfileId
    let opinions = data.opinions
    const url = `/brand-profile/${brandProfileId}/opinions`
    await userAccountAxios.patch(url, opinions)
}

const useOpinions = (brandProfileId: number) => {
    const queryClient = useQueryClient()
    const QUERY_KEY = rqKeys.brandProfileOpinions(brandProfileId)
    const { data: opinions, isLoading } = useQuery(QUERY_KEY, () => getBrandProfileOpinions(brandProfileId), {
        enabled: !!brandProfileId
    })

    const mutateOpinions = useMutation(
        (args: any) => {
            return patchBrandProfileOpinions(args)
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(QUERY_KEY)
            },
            onSuccess(data, variables, context) {
                toast.success('Changes saved')
            }
        }
    )

    return {
        opinions,
        opinionsIsLoading: isLoading,
        handleOpinionChange: mutateOpinions.mutate
    }
}

export default useOpinions
