import React, { useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import Chip, { ChipSize } from '@/views/Discover/Boards/components/conversations/Chip'
import { IBrand, IPeople } from '../types'

interface IDetailOverviewProps {
  overviewText: string
  overviewBullets: string[]
  totalMoments: number
  totalNarratives: number
  totalArticles: number
  totalSocialPosts: number
  sentimentScore: number
  sentimentType: string
  topBrands: IBrand[]
  topPeople: IPeople[]
}

const DetailOverview: React.FC<IDetailOverviewProps> = ({
  overviewText,
  overviewBullets,
  totalMoments,
  totalNarratives,
  totalArticles,
  totalSocialPosts,
  sentimentScore,
  sentimentType,
  topBrands,
  topPeople
}) => {
  const [brandsToShow, setBrandsToShow] = useState(4)
  const [peopleToShow, setPeopleToShow] = useState(4)

  const hasMoreBrands = brandsToShow < topBrands.length
  const hasMorePeople = peopleToShow < topPeople.length

  const handleViewMore = () => {
    setBrandsToShow(topBrands.length)
  }

  const handleViewLess = () => {
    setBrandsToShow(4)
  }

  const handleViewMorePeople = () => {
    setPeopleToShow(topPeople.length)
  }

  const handleViewLessPeople = () => {
    setPeopleToShow(4)
  }

  return (
    <div>
      <h5 className="text-lg">Overview</h5>
      <div className="mt-3">{overviewText}</div>
      <div className="  pl-2">
        <ul className="list-disc list-inside space-y-2">
          {overviewBullets.map((bullet, index) => (
            <li
              key={index}
              className="text-sm text-gray-500"
            >
              {bullet}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row gap-10 mt-10">
        <div className="flex flex-col">
          <div className="text-lg font-bold">Total Moments</div>
          <span className="text-xl font-semibold leading-[35px] text-purple-600">{totalMoments}</span>
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">Total Narratives</div>
          <span className="text-xl font-semibold leading-[35px] text-purple-600">{totalNarratives}</span>
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">Total Articles</div>
          <span className="text-xl font-semibold leading-[35px] text-purple-600">{totalArticles}</span>
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">Total Social Posts</div>
          <span className="text-xl font-semibold leading-[35px] text-purple-600">{totalSocialPosts}</span>
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">Sentiment Score</div>
          <div className="flex flex-row gap-2">
            <span className="text-xl font-semibold leading-[35px]">{sentimentScore}%</span>
            <span className="text-xl font-semibold leading-[35px] text-lime-500">{sentimentType}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-row mb-4 gap-16 mt-5">
        <div className="flex flex-col gap-2 w-80">
          <div className="text-lg font-bold">Top Brands</div>
          <div
            data-testid="trends-top-brands"
            className="flex flex-row flex-wrap gap-2"
          >
            {topBrands.slice(0, brandsToShow).map((brand: IBrand, index: number) => (
              <Chip
                key={index}
                dataTestId={`anticipation-boards-social-narrative-card-themes-${brand}`}
                text={brand.name}
                size={ChipSize.MEDIUM}
              />
            ))}

            {hasMoreBrands && (
              <Chip
                key="view-more"
                dataTestId="anticipation-boards-social-narrative-card-view-more"
                text={`View ${topBrands.length - brandsToShow} more`}
                size={ChipSize.MEDIUM}
                onClick={handleViewMore}
                pointer="cursor-pointer"
              />
            )}

            {!hasMoreBrands && (
              <div
                key="view-less"
                className="text-blue-500 underline cursor-pointer flex items-center justify-center"
                onClick={handleViewLess}
              >
                View less
                <ChevronUpIcon className="w-5 h-5 ml-1 text-blue-500" />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 w-80">
          <div className="text-lg font-bold">Top People</div>
          <div
            data-testid="trends-top-brands"
            className="flex flex-row flex-wrap gap-2"
          >
            {topPeople.slice(0, peopleToShow).map((people, index) => (
              <Chip
                key={index}
                dataTestId={`anticipation-boards-social-narrative-card-themes-${people.id}`}
                text={people.name}
                size={ChipSize.MEDIUM}
                textColor="text-blue-400"
                bgColor="bg-blue-100"
                borderColor="border-blue-400"
              />
            ))}

            {hasMorePeople && (
              <Chip
                key="view-more"
                dataTestId="anticipation-boards-social-narrative-card-view-more"
                text={`View ${topPeople.length - peopleToShow} more`}
                size={ChipSize.MEDIUM}
                onClick={handleViewMorePeople}
                textColor="text-blue-400"
                bgColor="bg-blue-100"
                borderColor="border-blue-400"
                pointer="cursor-pointer"
              />
            )}

            {!hasMorePeople && (
              <div
                key="view-less"
                className="text-blue-500 underline cursor-pointer flex items-center justify-center"
                onClick={handleViewLessPeople}
              >
                View less
                <ChevronUpIcon className="w-5 h-5 ml-1 text-blue-500" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailOverview
