import React from 'react'
import { MyLocationGenerics } from '@/classes/utils'
import { useSearch } from '@tanstack/react-location'

type TargetMomentsInfo = {
  assignees: string[] | undefined
  message: string | undefined
}

// Custom React Hook for managing Target Moments information
function useTargetMomentsInfo() {
  const { brandProfileId } = useSearch<MyLocationGenerics>()

  const getTargetMomentsInfo = () => {
    const storedAssignee = sessionStorage.getItem('targetMomentsInfo')
    return storedAssignee && storedAssignee !== 'undefined' && JSON.parse(storedAssignee)
  }

  const getDefaultTargetMomentsInfo = (): TargetMomentsInfo => {
    const targetMomentsInfo = getTargetMomentsInfo()
    return (brandProfileId && targetMomentsInfo && targetMomentsInfo[brandProfileId]) || {}
  }

  // Resets the TargetMomentsSlideOver Info
  const resetTargetMomentsInfo = () => {
    const targetMomentsInfoList = getTargetMomentsInfo()
    if(!targetMomentsInfoList) return
    brandProfileId && delete targetMomentsInfoList[brandProfileId]
    sessionStorage.setItem('targetMomentsInfo', JSON.stringify(targetMomentsInfoList))
}

  const setTargetMomentsInfo = (targetMomentsInfo: TargetMomentsInfo) => {
    let targetMomentsInfoList = getTargetMomentsInfo()
    if (targetMomentsInfoList && brandProfileId) targetMomentsInfoList[brandProfileId] = targetMomentsInfo
    else targetMomentsInfoList = brandProfileId && { [brandProfileId]: targetMomentsInfo }
    sessionStorage.setItem('targetMomentsInfo', JSON.stringify(targetMomentsInfoList))
  }

  return {
    setTargetMomentsInfo,
    resetTargetMomentsInfo,
    defaultTargetMomentsInfo: getDefaultTargetMomentsInfo()
  }
}

export default useTargetMomentsInfo
