import { useQuery } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { logError } from '@/utils';
import { fetchTargetviewInsertionOrders } from '@/hooks/targetview/targetview';

const useTargetViewInsertionOrders = () => {
    return useQuery(rqKeys.targetViewInsertionOrders(), fetchTargetviewInsertionOrders, {
        onError: (err) => {
            logError(err, { info: 'error getting tv ios' })
        }
    })
}

export default useTargetViewInsertionOrders
