import { ArrowDownIcon, ArrowUpIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { ResponsiveContainer, AreaChart, XAxis, Area, Tooltip as RechartsTooltip } from 'recharts';
import Whisper from 'rsuite/lib/Whisper';
import Tooltip from 'rsuite/lib/Tooltip';
import ChartTooltip from './ChartTooltip';
import { IAreaChartData } from '../../types';

interface AreaChartComponentProps {
  title: string;
  clusterId: string
  totalPosts: number;
  index: number;
  growth: number;
  fillColor: string;
  strokeColor: string;
  data: IAreaChartData[];
  toggleSocialNarrativePanel: Function
}

const BoardOverviewCardComponent: React.FC<AreaChartComponentProps> = ({
  title,
  clusterId,
  totalPosts,
  growth,
  index,
  fillColor,
  strokeColor,
  data,
  toggleSocialNarrativePanel
}) => (
  <div className="flex flex-col justify-between px-4 pt-4 bg-white border-[0.5px] border-gray-200 rounded-lg min-w-fit xl:w-full drop-shadow-lg h-full cursor-pointer"
    data-testid="board-overview-card"
    onClick={() => toggleSocialNarrativePanel(clusterId)}
  >
    <div className="flex justify-between" data-testid="conversation-overview-header">
      <div className="flex items-center justify-center">
        <div
          className="font-bold text-gray-700 rounded-full flex items-center justify-center h-6 w-6 mr-1"
          style={{ backgroundColor: strokeColor }}
        >
          <p data-testid="conversation-overview-index" className="text-white p-5">{index}</p>
        </div>
        <div>
          <p className="text-sm line-clamp-3 ml-2" data-testid="conversation-overview-title">{title}</p>
        </div>
      </div>
      <div className="flex flex-col ml-5">
        <div className="text-sm font-bold flex justify-between">
          <label>
            <div className="flex items-center">
              Growth
              <Whisper
                delayShow={0}
                delayHide={0}
                trigger='hover'
                placement='auto'
                speaker={
                  <Tooltip className="inline">
                    % growth represents growth over the selected time period
                  </Tooltip>
                }
              >
                <InformationCircleIcon className="h-5 ml-2"
                  data-testid="ai-board-prompt-tooltip"
                />
              </Whisper>
            </div>
          </label>
        </div>
        <div className="flex flex-row">
          {growth < 0 ? (
            <ArrowDownIcon className="h-6 stroke-3 text-red-400 font-bold mr-1 inline"></ArrowDownIcon>
          ) : growth > 0 ? (
            <ArrowUpIcon className="h-6 stroke-3 text-green-400 font-bold mr-1 inline"></ArrowUpIcon>
          ) : (<></>)
          }
          <span className={`font-bold text-xl ${growth < 0 ? "text-red-400" : growth > 0 ? "text-green-400" : ""}`} data-testid="conversation-overview-growth">
            {`${growth}%`}
          </span>
        </div>
      </div>
    </div>
    <div className="mt-4">
      <div className="text-sm font-bold">Total posts</div>
      <div className="text-base font-bold text-[#651AEB]" data-testid="conversation-overview-total-posts">{totalPosts}</div>
      <div className='aspect-[1/0.25]'>
        <ResponsiveContainer>
          <AreaChart
            data={data}
          >
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              padding={{ right: 15 }}
              style={{
                fontSize: '8px'
              }}
            />
            <Area
              type="linear"
              dataKey="posts"
              stroke={strokeColor}
              fill={fillColor}
            />
            <RechartsTooltip content={<ChartTooltip />} allowEscapeViewBox={{ y: true }} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);

export default BoardOverviewCardComponent;