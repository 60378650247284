import React, { memo } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

export type TooltipProps = {
    children: React.ReactElement
    content: React.ReactNode | string
    disabled?: boolean
    theme?: string
}

const Tooltip: React.FC<TooltipProps> = memo((props) => {
    return (
        <Tippy
            theme={props.theme}
            disabled={props.disabled}
            content={props.content}
            delay={200}
        >
            {props.children}
        </Tippy>
    )
})

export default Tooltip
