import React from 'react'
import Panel from 'rsuite/lib/Panel'
import RangeSlider from 'rsuite/lib/RangeSlider'
import Slider from 'rsuite/lib/Slider'

import { accentColor } from '../../../../../assets/jss/colorConstants'
import constants from './constants'

export interface IPreference{
  id: number
  name: string
}

interface IMediaPreferenceProps{
  sectionCaption: string
  description: string
  scaleCaption?: string|undefined
  preferences: Array<IPreference>
  preference: Array<number>
  handlePreference: (value:any) => void
  disabled: boolean
}

export default function MediaPreference({
  sectionCaption,
  description,
  preferences,
  preference,
  handlePreference,
  disabled,
}:IMediaPreferenceProps) {
  const renderSliderMark = (mark:number) => {
    if(preference.includes(mark)){
      return (<span className='text-sm font-bold'>
                {preferences.find(({id}) => id === mark)?.name}
              </span>)
      }
    return (<span className='text-xs font-medium'>
              {preferences.find(({id}) => id === mark)?.name}
          </span>)
  }

  return (
    <div className='flex flex-col justify-around gap-2 bg-gray-50 m-2 rounded-lg'>
      <Panel
          collapsible
          defaultExpanded = {true}
          header={
              <div className='text-xl font-bold'>
                  {sectionCaption}
              </div>
          }
      > 
      <>
        <div className='text-sm text-gray-600'>{description}</div> 
        <div className='flex flex-col p-3 text-lg font-semibold'>
          {sectionCaption === constants.RELIABILITY_SECTION_CAPTION && <Slider 
              disabled = {disabled}
              progress
              graduated
              defaultValue = {preference[0]}
              min={1}
              step={1}
              tooltip={false}
              max={preferences.length}
              renderMark={renderSliderMark}
              style={{
                width: '90%',
                margin: '2rem'
                
              }}
              value={preference[0]}
              onChange={handlePreference}
            />
          }

          {sectionCaption === constants.POLITICAL_BIAS_SECTION_CAPTION && <RangeSlider
              disabled = {disabled}
              min={1}
              step={1}
              max={preferences.length}
              defaultValue={[preference[0],preference[preference.length - 1]]}
              value={[preference[0],preference[preference.length - 1]]}
              graduated
              renderMark={renderSliderMark}
              tooltip={false}
              style={{
                width: '90%',
                margin: '3rem'
              }}
              onChange={handlePreference}
            />
          }
        </div>
      
      </>
      </Panel>
      {/* CSS to override rsuite slider css behavior */}
      <style>{`
        .rs-slider-progress-bar{
          background-color : ${accentColor};
        }
        .rs-slider-bar{
          background-color : #7a7a7a !important;
          height: 8px;
        }
        .rs-slider-progress-bar{
          height: 8px;
        }
        .rs-slider-graduator > ul > li:last-child::after, .rs-slider-graduator > ul > li::before{
          height: 10px;
          width: 10px;
          border: 1px solid gray ;
        }
        .rs-slider-active:first-child ~span{
          font-weight: 900 !important;
          font-size: 1rem !important;
        }
        .rs-slider-handle::before{
          height: 16px;
          width: 16px;
          border: 4px solid ${accentColor};
        }
      `}</style>
    </div>
    
  )
}
