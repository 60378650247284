import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { AdGroupsType, TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager'
import { MyLocationGenerics } from '@/classes/utils'
import { useSearch } from '@tanstack/react-location'

export const useStoredTargetedActivations = () => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()

  const getStoredSelectedActivations = () => {
    const storedSelectedActivations = sessionStorage.getItem('selectedToDoActivations')
    return (
      storedSelectedActivations && storedSelectedActivations !== 'undefined' && JSON.parse(storedSelectedActivations)
    )
  }

  const getStoredTargetedActivationsList = () => {
    const storedSelectedActivations = sessionStorage.getItem('targetedActivationsList')
    return (
      storedSelectedActivations && storedSelectedActivations !== 'undefined' && JSON.parse(storedSelectedActivations)
    )
  }

  /**
   * Loads the activation list from session storage based on the brand profile ID.
   *
   * @returns {Map<number, TargetedActivationListType>} - Map of cluster IDs to TargetedActivationListType.
   */
  const loadActivationListFromStorage = (): SerializableMap<string, TargetedActivationListType[]> | undefined => {
    // Retrieve the locally stored value from session storage.
    const targetedActivationsListFromStorage = getStoredTargetedActivationsList()
    const storedTodoActivations = getStoredSelectedActivations()
    if (targetedActivationsListFromStorage && brandProfileId) {
      const targetedActivationsList: Record<string, TargetedActivationListType[]> =
        targetedActivationsListFromStorage[brandProfileId]
      if (targetedActivationsList) {
        const selectedToDoActivations: number[] = storedTodoActivations[brandProfileId]
        const newTargetedActivationList = updateStoredActivationListWithTodoList(
          targetedActivationsList,
          selectedToDoActivations
        )
        return parseStoredActivationList(newTargetedActivationList)
      }
    }
    return
  }

  const parseStoredActivationList = (newTargetedActivationList: Record<string, TargetedActivationListType[]>) => {
    //Parse the JSON into the desired Type : SerializableMap<string, TargetedActivationListType[]>.
    const parsedList = new SerializableMap<string, TargetedActivationListType[]>()
    //prepare the default list
    for (const [persona, activationsList] of Object.entries(newTargetedActivationList)) {
      const targetedActivation: TargetedActivationListType[] = activationsList.map((item: any) => ({
        activationItemId: item.activationItemId,
        clusterName: item.clusterName,
        campaignsToAdGroups: new SerializableMap<number, AdGroupsType>(
          Object.entries(item.campaignsToAdGroups).map(([campaignId, adGroups]: [string, any]) => [
            parseInt(campaignId, 10),
            {
              keywordAdGroups: new SerializableSet(adGroups.keywordAdGroups),
              videoAdGroups: new SerializableSet(adGroups.videoAdGroups)
            }
          ])
        )
      }))
      parsedList.set(persona, targetedActivation)
    }
    return parsedList
  }

  const updateStoredActivationListWithTodoList = (
    targetedActivationsList: Record<string, TargetedActivationListType[]>,
    selectedToDoActivations: number[]
  ) => {
    const newTargetedActivationList: Record<string, TargetedActivationListType[]> = {}
    //sync the activation list with selected Todo list
    Object.entries(targetedActivationsList).forEach(([persona, activations]) => {
      const targetActivation = activations.filter(({ activationItemId }) =>
        selectedToDoActivations.includes(activationItemId)
      )
      if (targetActivation.length) newTargetedActivationList[persona] = targetActivation
    })

    return newTargetedActivationList
  }
  /**
   * Sets the activation list to session storage based on the brand profile ID.
   *
   * @param {Map<number, TargetedActivationListType>} newTargetedActivationList - Map of cluster IDs to ActivationListType.
   * @returns {void}
   */
  const setActivationListToStorage = (
    newTargetedActivationList: SerializableMap<string, TargetedActivationListType[]>
  ): void => {
    const targetedActivationsListFromStorage = getStoredTargetedActivationsList() ?? {}
    if (!brandProfileId) {
      return
    }
    targetedActivationsListFromStorage[brandProfileId] = newTargetedActivationList
    sessionStorage.setItem('targetedActivationsList', JSON.stringify(targetedActivationsListFromStorage))
  }

  const syncActivationListWithTodo = () => {
    const targetedActivationList = loadActivationListFromStorage()
    targetedActivationList && setActivationListToStorage(targetedActivationList)
  }

  return {
    storedActivationList: loadActivationListFromStorage(),
    setActivationListToStorage,
    syncActivationListWithTodo
  }
}
