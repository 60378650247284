import React from 'react';
import { perms } from '@/staticData/permissions';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import config from '@/config';

var dayjs = require('dayjs');
const date_format = 'MMMM DD, YYYY h:mm A';
const WIDGET_SELECTOR = '#jsd-widget';
const CLOSE_BUTTON_SELECTOR = '.close-icon';

function jiraHelpdesk(callback) {
    var jhdScript = document.createElement('script')
    jhdScript.type = 'text/javascript'
    jhdScript.setAttribute('data-jsd-embedded', null)
    jhdScript.setAttribute('data-key', '7a227ce3-6b6d-4b3b-99de-68f098552b4a')
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
    if (jhdScript.readyState) {
        // old IE support
        jhdScript.onreadystatechange = function() {
            if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
                jhdScript.onreadystatechange = null
                callback()
            }
        }
    } else {
        //modern browsers
        jhdScript.onload = function() {
            callback()
        }
    }
    document.getElementsByTagName('head')[0].appendChild(jhdScript)
}

const toggleDisplay = (elementSelector) => {
  var element = document.querySelector(elementSelector);
  var currentDisplay = window.getComputedStyle(element).getPropertyValue('display');

  if (currentDisplay === 'none') {
    element.style.display = 'block';
  } else {
    element.style.display = 'none';
  }
}

const ensureWidgetIsHidden = () => {
  var element = document.querySelector(WIDGET_SELECTOR);
  var currentDisplay = window.getComputedStyle(element).getPropertyValue('display');

  if (currentDisplay === 'block') {
    element.style.display = 'none';
  }
}

const setupOnClose = () => {
  var iframe = document.querySelector(WIDGET_SELECTOR);

  iframe.contentDocument.body.addEventListener('click', ensureWidgetIsHidden);
}

export const openJiraHelpdeskWidget = () => {
  const iframe = document.querySelector(WIDGET_SELECTOR);
  const iframeContent = iframe.contentDocument;
  const button = iframeContent.querySelector('#help-button');
  const closeButton = iframeContent.querySelector(CLOSE_BUTTON_SELECTOR);

  const isOpen = closeButton && !button;

  if (button && !isOpen) {
    button.click();
    toggleDisplay(WIDGET_SELECTOR);
  }
  else if (closeButton && isOpen) {
    closeButton.click();
    // event handler placed on iframe from setupOnClose handles hiding the widget (?) button
  }
}

const initializePendo = (user, account) => {
    window.pendo.initialize({
        visitor: {
            id: `${config.environment}_${user.userId}`,
            roleId: user.roleId,
            roleName: user.roleName,
            email: user.email,
            company: user.company,
            createdDate: user.createdDate,
            firstName: user.firstName,
            lastName: user.lastName,
            userName: user.userName
        },
        account: {
            id: account?.accountId,
            accountLevelId: account?.accountLevelId,
            accountLevelName: account?.accountLevelName,
            accountMargin: account?.accountMargin,
            accountName: account?.accountName,
            accountTypeId: account?.accountTypeId,
            accountTypeName: account?.accountTypeName,
            parentAccountId: account?.parentAccountId,
            parentAccountName: account?.parentAccountName,
            packageId: account?.packageId,
            package: account?.package?.name,
            packageStartDate: account?.packageStartDate ? dayjs(account.packageStartDate).format(date_format) : '-',
            packageEndDate: account?.packageEndDate ? dayjs(account.packageEndDate).format(date_format) : '-',
            trialStartDate:account?.trialStartDate ? dayjs(account.trialStartDate).format(date_format) : '-',
            trialEndDate: account?.trialEndDate ? dayjs(account.trialEndDate).format(date_format) : '-',
        }
    })
}

const useThirdPartyInitializer = (user, currentAccount) => {
  const { userPermissions } = useUser();

  const [initializedPendoAccounts, setInitializedPendoAccounts] = React.useState([]);
  React.useEffect(() => {
    if (!user || initializedPendoAccounts.includes(currentAccount?.accountId) || !currentAccount?.accountId) {
      return
    }
    const userId = localStorage.getItem('userId')
    console.log(
      `initializing Pendo with userId: ${userId}, roleId: ${user.roleId}, accountId: ${currentAccount.accountId}`
    )

    initializePendo(user, currentAccount)
    setInitializedPendoAccounts(prev => {
      prev.push(currentAccount?.accountId)
      return prev
    })
  }, [user, currentAccount?.accountId])

  const [initiatedJira, setInitiatedJira] = React.useState(false)

  React.useEffect(() => {
    if (!userCanPermissionProductQuota({
      requiredPermissionValue: perms.INTERNAL_SUPPORT_CHAT_PERMISSION,
      userPermissions: userPermissions,
      checkType: PermissionCheckType.PERMISSION_CHECK
    })) {
      console.log('User does not have permission to use internal support chat.');
      return;
    }
    jiraHelpdesk(function() {
      var DOMContentLoaded_event = document.createEvent('Event');
      DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);

      // hide JIRA widget on load for the new navigation experience
      setupOnClose();
      ensureWidgetIsHidden();
    });
    setInitiatedJira(true);
  }, [userCanPermissionProductQuota({
      requiredPermissionValue: perms.INTERNAL_SUPPORT_CHAT_PERMISSION,
      userPermissions: userPermissions,
      checkType: PermissionCheckType.PERMISSION_CHECK
    })]);
}

export default useThirdPartyInitializer
