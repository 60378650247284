import {
	SET_EVERGREEN_CHANNELS,
	SET_EVERGREEN_CHANNEL_COUNT,
	SET_EVERGREEN_CHANNELS_LOADING,
	SET_EVERGREEN_CHANNELS_PAGE,
	SET_EVERGREEN_CHANNELS_IAB_CATEGORIES,
	SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE,
	EVERGREEN_CHANNEL_ARCHIVING,
	EVERGREEN_CHANNEL_TO_ARCHIVE,
	EVERGREEN_CHANNEL_SAVING
} from '../../action-types/admin/evergreenChannels'
import * as Yup from 'yup'

import toast from 'react-hot-toast'
import { listBuilderAxios } from '../../../axiosInstances'


export const evergreenChannelsObj = Yup.array()
	.of(
		Yup.object().shape({
			channelId: Yup.string().required(),
			title: Yup.string().defined(),
			description: Yup.string().nullable(),
			ytLink: Yup.string().defined(),
			totalSubscribers: Yup.number().defined(),
			totalViews: Yup.number().defined(),
			archived: Yup.bool().defined(),
			lastUploadDate: Yup.string().defined(),
			iabCategoryIds: Yup.array().defined()
		})
	)

export function setEvergreenChannels(evergreenChannels) {
	return {
		type: SET_EVERGREEN_CHANNELS,
		evergreenChannels
	}
}

export function setEvergreenChannelCount(evergreenChannelCount) {
	return {
		type: SET_EVERGREEN_CHANNEL_COUNT,
		evergreenChannelCount
	}
}

export function setEvergreenChannelsLoading(evergreenChannelsLoading) {
	return {
		type: SET_EVERGREEN_CHANNELS_LOADING,
		evergreenChannelsLoading
	}
}

export function setEvergreenChannelsPage(evergreenChannelsPage) {
	return {
		type: SET_EVERGREEN_CHANNELS_PAGE,
		evergreenChannelsPage
	}
}

export function setEvergreenChannelsIabCategories(
	evergreenChannelsIabCategories
) {
	return {
		type: SET_EVERGREEN_CHANNELS_IAB_CATEGORIES,
		evergreenChannelsIabCategories
	}
}

export function setEvergreenChannelsHasNextPage(evergreenChannelsHasNextPage) {
	return {
		type: SET_EVERGREEN_CHANNELS_HAS_NEXT_PAGE,
		evergreenChannelsHasNextPage
	}
}

export function setEvergreenChannelArchiving(channelId) {
	return {
		type: EVERGREEN_CHANNEL_ARCHIVING,
		evergreenChannelArchiving: channelId
	}
}

export function setEvergreenChannelToArchived(channelId) {
	return {
		type: EVERGREEN_CHANNEL_TO_ARCHIVE,
		channelId
	}
}

export function setEvergreenChannelSaving(bool) {
	return {
		type: EVERGREEN_CHANNEL_SAVING,
		evergreenChannelSaving: bool
	}
}

export function fetchEvergreenChannels(params) {
	let url =  `/evergreen-channels/get-all`
	return async (dispatch) => {
		dispatch(setEvergreenChannelsLoading(true))
		try {
			const result = await listBuilderAxios.post(url, params)
			if (result.status === 200) {
				let evergreenChannels = result.data.channels
				let evergreenChannelCount = result.data.totalCount

				evergreenChannelsObj.validate(evergreenChannels).catch(function (err) {
					console.log(err.name, err.errors)
					console.error(
						'We received different API data than expected while fetching admin evergreen channels, see the console log for more details.'
					)
				})

				dispatch(setEvergreenChannels(evergreenChannels))
				dispatch(setEvergreenChannelCount(evergreenChannelCount))
				dispatch(setEvergreenChannelsLoading(false))
				dispatch(setEvergreenChannelsPage(params.page))
				dispatch(setEvergreenChannelsIabCategories(params.iabCategories))
				dispatch(
					setEvergreenChannelsHasNextPage(
						params.limit * params.page < evergreenChannelCount
					)
				)
			}
		} catch (error) {
			console.error(error)
			dispatch(setEvergreenChannelsLoading(false))
		}
	}
}

export function archiveEvergreenChannel(channelId) {
	let url = `/evergreen-channels/${channelId}`
	return (dispatch) => {
		dispatch(setEvergreenChannelArchiving(channelId))
		listBuilderAxios
			.delete(url)
			.then((response) => {
				dispatch(setEvergreenChannelToArchived(channelId))
				dispatch(setEvergreenChannelArchiving(''))
				toast.success('Evergreen Channel archived!')
			})
			.catch((error) => {
				console.error(error)
			})
	}
}

export const createEvergreenChannel = (channel) => {
	let url = `/evergreen-channels`
	return (dispatch, getState) => {
		dispatch(setEvergreenChannelSaving(true))
		listBuilderAxios
			.post(url, channel)
			.then((response) => {
				// dispatch(addEvergreenChannel(response.data[0]))
				dispatch(setEvergreenChannelSaving(false))
				toast.success('Evergreen Channel created!')
			})
			.catch((error) => {
				//error
			})
	}
}
