import React from 'react'
import Table from 'rsuite/lib/Table'
import { TableColumnJs } from '@/utils'
import { IBrandSensitiveContentCategory } from '@/views/BrandProfiles/types'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import Tooltip from '@/components/TailwindTooltip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

interface IProps {
    brandSensitiveContent: IBrandSensitiveContentCategory[]
    handleCheckboxChange: Function
}

const ExcludedSensitiveContentTable = ({ brandSensitiveContent, handleCheckboxChange }: IProps) => {

    return (

        <Table
            loading={false}
            autoHeight
            data={brandSensitiveContent}
            bordered
            className='shadow-md rounded-md'
        >
            <TableColumnJs flexGrow={6}>
                <Table.HeaderCell>
                    Sensitive Content Types
                </Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { name: string, description: string }) => {
                        return (
                            <div className='flex gap-2' data-testid='senstive-content-name'>
                                {rowData.name}
                                <Tooltip content={rowData.description}>
                                    <InformationCircleIcon className="h-5 w-5 text-slate-400" />
                                </Tooltip>
                            </div>
                        )
                    }}
                </Table.Cell>
            </TableColumnJs>
            <TableColumnJs flexGrow={1}>
                <Table.HeaderCell>
                    <div className='w-full flex justify-center'>
                        Exclude
                    </div>
                </Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { id: number, isExcluded: boolean }) => {
                        return <div className='w-full flex justify-center' data-testid='senstive-content-checkbox'>
                            <SightlyCheckbox
                                id={`excludChece-${rowData.id}-sensitive-content-category`}
                                handleChange={(checked: boolean) => {
                                    rowData.isExcluded = checked
                                    handleCheckboxChange(checked, rowData.id)
                                }}
                                checked={rowData.isExcluded}
                                type='sightlyPurple' />
                        </div>
                    }}
                </Table.Cell>
            </TableColumnJs>
        </Table>
    )
}

export default ExcludedSensitiveContentTable;