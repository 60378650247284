import React from 'react'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import SightlySelectForm from '@/components/Sightly/SightlyFormElements/SightlySelectForm'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { socialObjValidation } from '@/schemas/brandProfiles'
import toast from 'react-hot-toast'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { Social } from './SocialSection'
import { userAccountAxios } from '@/axiosInstances'
import { useMatch, useSearch } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'

import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import {getRelationships} from "@/views/BrandProfiles/BrandProfile/components/Queries/queries-api";
import RelationshipSelectForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/RelationshipSelectForm";
import WikiInputForm from "@/views/BrandProfiles/BrandProfile/components/Queries/components/WikiInputForm";
import ResetFieldsButton from "@/views/BrandProfiles/BrandProfile/components/Queries/components/ResetFieldsButton";
import { products } from '@/staticData/products'
import { MyLocationGenerics } from '@/classes/utils'

enum fields {
    'name' = 'name',
    'id' = 'id',
    'wikiUrl' = 'wikiUrl',
    'description' = 'description',
    'causeIsNotListed' = 'causeIsNotListed',
    'aylienId' = 'aylienId'
}

type IProps = {
    item: Social
    open: boolean
    handleClose: () => void
    disabled?: boolean
    type: 'edit' | 'add'
}

type AylienDropdownItem = {
    id: number
    aylienTaxonomyCode: string
    aylienTaxonomyName: string
    aylienTaxonomyDescription: string
}

const getDropdownItems = async (): Promise<AylienDropdownItem[]> => {
    const url = '/brand-profile/SNEDropdown'
    const res = await userAccountAxios.get(url)
    return res.data
}

const SocialModal = ({ item, open, handleClose, disabled, type }: IProps) => {
    const {
        params: { brandProfileId }
    } = useMatch()
    const {
        accountId
    } = useSearch<MyLocationGenerics>()
    const queryClient = useQueryClient()
    const [isSaving, setIsSaving] = React.useState(false)

    const dropdownItems = useQuery(rqKeys.socialAylien(), getDropdownItems)

    const {
        data: relationshipOptions
    } = useQuery(['queryRelationships'], getRelationships)

    const { register, handleSubmit, reset, control, formState, setValue, watch, getValues } = useForm<Social>({
        defaultValues: item,
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(socialObjValidation)
    })

    const _handleClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: Partial<Social>) => {
        const items = await queryClient.getQueryData<Social[]>(['social', brandProfileId])
        if (data.name === null) {
            console.error('data.name cannot be null')
            return
        }
        const threshold = type === 'edit' ? 1 : 0
        const nameIsDuplicate =
            items &&
            items.filter((item) => data.name && item?.name?.toLowerCase() === data.name.toLowerCase()).length >
                threshold
        if (nameIsDuplicate) {
            toast.error('This name is already used. Please pick a different name and try again.')
            return
        }
        setIsSaving(true)
        delete data.id
        delete data.relationshipName
        const _args: any = {
            ...data
        }

        if (!data.causeIsNotListed) {
            const aylienItem = dropdownItems.data && dropdownItems.data.filter((i) => i.id === data.aylienId)[0]
            if (!aylienItem) {
                console.error('aylienItem cannot be undefined')
                return
            }
            if (aylienItem?.aylienTaxonomyCode.includes('ay.')) {
                _args.aylienNewsId = aylienItem.id
                _args.aylienIndustriesId = null
            } else {
                _args.aylienIndustriesId = aylienItem.id
                _args.aylienNewsId = null
            }
            _args.name = aylienItem.aylienTaxonomyName
        } else {
            _args.aylienIndustriesId = null
            _args.aylienNewsId = null
        }
        delete _args.aylienId
        if (!_args.aylienIndustriesId) {
            _args.aylienIndustriesId = null
        }
        if (!_args.aylienNewsId) {
            _args.aylienNewsId = null
        }

        try {
            if (type === 'edit') {
                const url = `/brand-profile/${brandProfileId}/social-and-environmental/${item.id}`
                // disable isAIGenerated to false since it's being modified by the user
                _args.isAIGenerated = false
                await userAccountAxios.patch(url, _args)
                toast.success('Saved')
            } else {
                const url = `/brand-profile/${brandProfileId}/social-and-environmental`
                await userAccountAxios.post(url, _args)
                // to fetch the latest product quota
                await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.QUERIES))
                toast.success('Added')
                reset()
            }
            queryClient.invalidateQueries(rqKeys.brandProfileSocial(Number(brandProfileId)))
            queryClient.invalidateQueries(rqKeys.brandProfileSocial(Number(brandProfileId)))
        } catch (err) {
            toast.error('Error')
            setIsSaving(false)
        }
        setIsSaving(false)
    }

    const errors = formState.errors

    React.useEffect(() => {
        if (item && type === 'edit') {
            reset(item)
        }
    }, [item])

    const watchCauseIsNotListed = watch(fields.causeIsNotListed)

    const watchName = watch(fields.name)

    React.useEffect(() => {
        if (!watchName) {
            return
        }
        const item = dropdownItems?.data?.filter((i) => i.aylienTaxonomyName === watchName)[0]
        if (!item) {
            return
        }
        setValue(fields.description, item.aylienTaxonomyDescription as never, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [watchName])

    const watchAylienId = watch(fields.aylienId)

    React.useEffect(() => {
        if (!watchAylienId) {
            return
        }
        if (watchCauseIsNotListed) {
            return
        }
        const item = dropdownItems?.data?.filter((i) => i.id === watchAylienId)[0]
        if (!item) {
            return
        }
        setValue(fields.name, item.aylienTaxonomyName as never, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [watchAylienId])

    React.useEffect(() => {
        if (watchCauseIsNotListed) {
            setValue(fields.aylienId, null as never, {
                shouldValidate: true,
                shouldDirty: true
            })
        } else {
        }
    }, [watchCauseIsNotListed])

    React.useEffect(() => {
        var year = new Date().getFullYear()
        if (!document || document === null) {
            return
        }
        document.getElementById('date')?.setAttribute('min', year + '-01-01')
        document.getElementById('date')?.setAttribute('max', year + '-12-31')
    }, [])

    return (
        <PortalModal
            hasClose={true}
            handleClose={_handleClose}
            open={open}
            footerButtons={[
                <SightlyButton
                    id="rename"
                    loading={isSaving}
                    disabled={!formState.isValid || !formState.isDirty}
                    text={type === 'edit' ? 'Save' : 'Add'}
                    handleClick={handleSubmit(onSubmit)}
                    type="primary"
                />
            ]}
        >
            <h4 style={{ marginBottom: 30, width: 376 }}>
                {type === 'edit' ? `Edit Social Issue` : `Add New Social Issue`}
            </h4>

            <div style={{ width: 450, justifyContent: 'center', marginBottom: 24 }}>
                Please select any social or environmental causes that are relevant to your brand from the dropdown
                provided. If you don’t see your cause in the list, please provide some additional information in the
                optional fields below.
            </div>

            <div className="form-container">
                <div>
                    {watchCauseIsNotListed ? (
                        <SightlyInputForm
                            dataTestId={'input-name'}
                            control={control}
                            error={errors?.name?.message}
                            name={fields.name}
                            register={register}
                            id="nameField"
                            label="Name"
                            disabled={disabled}
                        />
                    ) : (
                        <SightlySelectForm
                            dataTestId={'select-name'}
                            label="Name"
                            id="name"
                            labelKey="aylienTaxonomyName"
                            valueKey="id"
                            name={fields.aylienId}
                            options={dropdownItems.data}
                            control={control}
                            disabled={disabled}
                            placeholder="Select Name"
                        />
                    )}
                    <div className="h-4" />
                    <SightlyCheckbox
                        dataTestId={'checkbox-no-cause'}
                        id="notlisted"
                        label="My cause is not listed"
                        checked={watchCauseIsNotListed}
                        handleChange={(checked: boolean) => {
                            setValue(fields.name, '' as never, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                            setValue(fields.causeIsNotListed, checked as never, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                    />
                </div>

                <SightlyInputForm
                    dataTestId={'input-cause-description'}
                    control={control}
                    error={errors?.description?.message}
                    name={fields.description}
                    register={register}
                    id="description"
                    label="Description"
                    disabled={disabled}
                    textarea
                />

                <WikiInputForm
                    control={control}
                    error={errors?.wikiUrl?.message}
                    name={fields.wikiUrl}
                    register={register}
                    disabled={disabled}
                />

                <RelationshipSelectForm
                    control={control}
                    disabled={disabled}
                />

                {type === 'add' && formState.isDirty && (
                    <ResetFieldsButton
                        reset={reset}
                    />
                )}
            </div>

            <style>{`
				.form-container {
					display: grid;
					gap: 24px;				
					grid-template-columns: repeat(auto-fit);			
				}		
			`}</style>
        </PortalModal>
    )
}

export default SocialModal
