import React, { useState } from 'react'
import TopicTrendChartByRange from '../components/TopicTrendChartByRange'
import { ITrendMetric, ITopicMetric } from '../types'

interface IDetailTimeChartProps {
  lineChartData: (ITrendMetric | ITopicMetric)[],
  handleSourceChange: Function,
  sourceOptions: string[]
}

const DetailTimeChart: React.FC<IDetailTimeChartProps> = ({ lineChartData, handleSourceChange, sourceOptions }) => {
  const timeRangeOptions = [
    { id: 1, label: 'Last 24 hrs' },
    { id: 2, label: 'Last 7 days' },
    { id: 3, label: 'Last 30 days' },
    { id: 4, label: 'Last 60 days' },
    { id: 5, label: 'Custom Range' }
  ]
  const [selectedTimeRange, setSelectedTimeRange] = useState<number | undefined>(2)

  const [filteredMetrics, setFilteredMetrics] = useState(lineChartData)

  const handleTimeRangeChange = (selectedRange: number) => {
    setSelectedTimeRange(selectedRange)

    const now = new Date()
    const startingRange = new Date(now)

    switch (selectedRange) {
      case 1:
        startingRange.setDate(startingRange.getDate() - 1)
        break
      case 2:
        startingRange.setDate(startingRange.getDate() - 7)
        break
      case 3:
        startingRange.setDate(startingRange.getDate() - 30)
        break
      case 4:
        startingRange.setDate(startingRange.getDate() - 60)
        break
      default:
        break
    }

    const newFilteredMetrics = lineChartData.filter((metric) => {
      const timestamp = new Date(metric.createdAt)
      return timestamp >= startingRange
    })

    setFilteredMetrics(newFilteredMetrics)
  }

  return (
    <div>
      <TopicTrendChartByRange
        selectedTimeRange={selectedTimeRange}
        sourceOptions={sourceOptions}
        timeRangeOptions={timeRangeOptions}
        handleTimeRangeChange={handleTimeRangeChange}
        handleSourceChange={handleSourceChange}
        lineChartData={filteredMetrics}
      />
    </div>
  )
}

export default DetailTimeChart
