import { useQuery } from '@tanstack/react-query'
import { rqKeys } from '@/views/Discover/Moments/v2/TargetMoment/hooks/rqKeys'
import { listBuilderAxios } from '@/axiosInstances'
import { z } from 'zod'
import { logError } from '@/utils'
import { formatDate } from '@/utils_ts'

export const IoSchema = z.object({
    insertionOrderId: z.number(),
    insertionOrderName: z.string(),
})

export const IosSchema = z.array(IoSchema)

export type IosSchemaType = z.infer<typeof IosSchema>
export type IoSchemaType = z.infer<typeof IoSchema>

const getIos = async (
    brandProfileId: number | undefined,
    status?: string,
    startDateString?: string,
    endDateString?: string
  ) => {
    try {
      if (!brandProfileId) return Promise.reject('invalid input');
      const url = `/brand-profile/${brandProfileId}/moment-clusters/activation-moment-ios`;
      const data = await listBuilderAxios.get(url, {
        params: {
          status: status,
          startDate: startDateString,
          endDate: endDateString,
        }
      })

      return IosSchema.parse(data.data);
    } catch (error) {
      logError(error);
      throw error;
    }
  };
  
  
  export default function useIos(
    brandProfileId: number | undefined,
    status?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    const startDateString = startDate ? formatDate(startDate) : undefined
    const endDateString = endDate ? formatDate(endDate) : undefined
    
    const iosQuery = useQuery(
      rqKeys.iosKey(brandProfileId),
      () => getIos(brandProfileId, status, startDateString, endDateString),
      {
        onError: (err) => {
          logError(err, { info: 'insertionOrder' });
        }
      }
    );
  
    return {
      iosQuery
    };
  }
  
