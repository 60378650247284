import React from 'react';
import { brandSuccessColor } from '@/assets/jss/colorConstants';

interface BoardsCreatedGaugeProps {
    tally?: { currentProductCount: number, productQuota: number, isMaxedOut: boolean }
    backgroundColor?: string;
}

export const BoardsCreatedGauge = ({ tally, backgroundColor }: BoardsCreatedGaugeProps) => {
  return (
    <div
      data-testid="dashboard-created-gauge"
      className='font-semibold text-[#696969] text-left'
    >
      {tally?.currentProductCount} / {tally?.productQuota} Boards Created
    </div>
  );
}
