import React from "react";
import { TagPicker } from "rsuite";

export default function PerformanceMetricsSection({ formValues, performanceMetrics, handleOnChange }) {
    const ref = React.useRef()

    return (
      <div data-testid='div-report-generator-select-performance-metrics'>
        <h5>5. Select your Non Conversion Metrics</h5>
        <label className="pl-4 text-gray-500">
          {' '}
          Choose the data fields you would like to include in the report
        </label>
        <div className="pl-4 relative" ref={ref}>
          <TagPicker
            container={() => ref.current}
            data={performanceMetrics}
            defaultValue={formValues.performanceMetrics}
            onChange={(value) => handleOnChange('performanceMetrics', value)}
            placeholder="Search"
          />
        </div>
      </div>
    )
}
