import React from 'react'

import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { AdGroupsType, TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import {
  TargetedActivationListActionType,
  targetedActivationListReducer
} from '@/views/Discover/Activations/v2/ActivationsManager/reducers/activationManager.reducer'
import { useStoredTargetedActivations } from '@/views/Discover/Activations/v2/ActivationsManager/useStoredTargetedActivations'

type TargetedActivationListContextType = {
  state: SerializableMap<string, TargetedActivationListType[]>
  dispatch: React.Dispatch<TargetedActivationListActionType>
}

type ActivationManagerProviderProps = {
  children: React.ReactNode
  activationsGroupedByPersonas: Map<string, Array<ActivationItemType>>
}

const TargetedActivationListContext = React.createContext<TargetedActivationListContextType | null>(null)

export function ActivationManagerProvider({ children, activationsGroupedByPersonas }: ActivationManagerProviderProps) {
  const { storedActivationList } = useStoredTargetedActivations()

  const [targetedActivationList, dispatchTargetedActivationList] = React.useReducer(
    targetedActivationListReducer,
    getDefaultTargetedActivationList(activationsGroupedByPersonas, storedActivationList)
  )

  return (
    <TargetedActivationListContext.Provider
      value={{ state: targetedActivationList, dispatch: dispatchTargetedActivationList }}
    >
      {children}
    </TargetedActivationListContext.Provider>
  )
}

export function useTargetedActivationList() {
  const context = React.useContext(TargetedActivationListContext)
  if (context === null) {
    throw new Error('useTargetedActivationList must be used within a ActivationManagerProvider')
  }
  return context
}

const getDefaultTargetedActivationList = (
  activationsGroupedByPersonas: Map<string, Array<ActivationItemType>>,
  storedActivationList: SerializableMap<string, TargetedActivationListType[]> | undefined
) => {
  const defaultTargetedActivationList: SerializableMap<
    string,
    Array<TargetedActivationListType>
  > = new SerializableMap()

  const storedActivationIdToActivations = new Map<string, Map<number, TargetedActivationListType>>()
  storedActivationList?.forEach((activations, personas) => {
    storedActivationIdToActivations.set(
      personas,
      new Map(activations.map((activation) => [activation.activationItemId, activation]))
    )
  })

  const activationItemIdsTargeted = Array.from(activationsGroupedByPersonas.values()).flatMap((activations) =>
    activations.map(({ activationItemId }) => activationItemId)
  )
  const uniqueActivationItemIds = new Set(activationItemIdsTargeted)

  const activationItemIdsStored = Array.from(storedActivationIdToActivations.values()).flatMap((activations) =>
    Array.from(activations.keys())
  )
  const uniqueActivationItemIdsStored = new Set(activationItemIdsStored)

  const newActivationItemIds = Array.from(uniqueActivationItemIds).filter(
    (activationItemId) => uniqueActivationItemIdsStored.size && !uniqueActivationItemIdsStored.has(activationItemId)
  )

  activationsGroupedByPersonas.forEach((activations, persona) => {
    // Fetch existing storedActivations and load them
    const storedActivations = storedActivationIdToActivations.get(persona)
    const filteredActivations = getActivationsFromStorage(
      activations,
      storedActivations,
      storedActivationIdToActivations,
      persona,
      uniqueActivationItemIdsStored,
    )

    // Check if there are new activations added to the targetList and add default data
    const newActivations = activations.filter(
      ({ activationItemId }) =>
        newActivationItemIds.includes(activationItemId) &&
        !filteredActivations.some((filtered) => filtered.activationItemId === activationItemId)
    )
    const newTargetedActivations = newActivations.map(({ activationItemId, clusterName }) => {
      const activationElement: TargetedActivationListType = {
        activationItemId,
        clusterName,
        campaignsToAdGroups: getDefaultCampaignsToAdGroups()
      }
      return activationElement
    })

    // combine with the existing data
    let nextActivationList: TargetedActivationListType[]

    if (filteredActivations?.length) {
      nextActivationList = [...filteredActivations, ...newTargetedActivations]
    } else {
      nextActivationList = [...newTargetedActivations]
    }
    nextActivationList.length && defaultTargetedActivationList.set(persona, nextActivationList)
  })
  return defaultTargetedActivationList
}

const getActivationsFromStorage = (
  activations: Array<ActivationItemType>,
  storedActivations: Map<number, TargetedActivationListType> | undefined,
  storedActivationIdToActivations: Map<string, Map<number, TargetedActivationListType>>,
  persona: string,
  uniqueActivationItemIdsStored: Set<number>,
) => {
  const filteredActivations: TargetedActivationListType[] = []
  activations.forEach(({ activationItemId, clusterName }) => {
    if (storedActivations) {
      const activation = storedActivationIdToActivations.get(persona)?.get(activationItemId)
      activation && filteredActivations.push(activation)
    } else {
      if (uniqueActivationItemIdsStored.has(activationItemId)) return

      const activationElement: TargetedActivationListType = {
        activationItemId,
        clusterName,
        campaignsToAdGroups: getDefaultCampaignsToAdGroups()
      }
      filteredActivations.push(activationElement)
    }
  })

  return filteredActivations
}

const getDefaultCampaignsToAdGroups = () => {
  const defaultCampaignsToAdGroups = new SerializableMap<number, AdGroupsType>()
  defaultCampaignsToAdGroups.set(0, {
    keywordAdGroups: new SerializableSet(),
    videoAdGroups: new SerializableSet()
  })
  return defaultCampaignsToAdGroups
}
