import React from 'react'
import _ from 'lodash'
import { CheckPicker, Icon, TreePicker } from 'rsuite'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  ActivationAdGroupsSchemaType,
  ActivationCampaignsSchemaType
} from '@/views/Discover/Activations/v2/activations-types'
import { CAMPAIGN_VALUE_EXISTS } from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { fixedColumnWidth } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/TableHeaderRow'
import Tooltip from '@/components/TailwindTooltip'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/Discover/Activations/v2/ActivationsManager/reducers/activationManager.reducer'
import DataPickerFooter from '@/components/DataPickerFooter'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'

export const SelectorGroup = (props: {
  persona: string
  activationItemId: number
  elementKey: string
  isRemovable?: boolean
  handleRemoveRow?: (persona: string, currentActivationItemId: number, campaignId: number) => void
  campaignOptions: ActivationCampaignsSchemaType | undefined
  adGroupOptions: Map<number, ActivationAdGroupsSchemaType> | undefined
  campaignValue: number
  keywordAdGroupValue: number[] | undefined
  videoAdGroupValue: number[] | undefined
  handleCampaignChange: (
    elementKey: string,
    persona: string,
    currentActivationItemId: number,
    nextCampaignId: number,
    prevCampaignId: number,
    setDoesValueExists: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
}) => {
  const {
    campaignOptions,
    campaignValue,
    keywordAdGroupValue,
    videoAdGroupValue,
    adGroupOptions,
    activationItemId,
    persona,
    elementKey,
    handleCampaignChange
  } = props

  const [doesValueExists, setDoesValueExists] = React.useState(false)
  const targetedActivationList = useTargetedActivationList()

  const disabledCampaignOptions = React.useMemo(() => {
    const disabledValues = new Set()
    campaignOptions?.forEach((mcc) => {
      disabledValues.add(mcc.id)
      mcc.children.forEach((cid) => {
        disabledValues.add(cid.id)
      })
    })
    return Array.from(disabledValues)
  }, [campaignOptions])

  const getLabelValue = React.useCallback((campaignId: number) => {
    let label = ''
    campaignOptions?.forEach((mcc) => {
      mcc.children.forEach((cid) => {
        const targetCampaign = cid.children.find((campaign) => campaign.id === campaignId)
        if (targetCampaign) {
          label = targetCampaign.name
          return
        }
      })
    })
    return label
  }, [])

  const renderFooter = (type: 'keyword' | 'video') => {
    if (!adGroupOptions?.size) return
    return (
      <DataPickerFooter
        rowCount={adGroupOptions.get(campaignValue)?.length ?? 0}
        handleClear={() => {
          targetedActivationList.dispatch({
            type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS,
            payload: {
              persona,
              currentActivationItemId: activationItemId,
              campaignId: campaignValue,
              adGroupType: type,
              adGroups: []
            }
          })
        }}
        handleSelectAll={() => {
          if (!campaignValue || !adGroupOptions) return
          const values = adGroupOptions.get(campaignValue)?.map(({ id }) => id)
          targetedActivationList.dispatch({
            type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS,
            payload: {
              persona,
              currentActivationItemId: activationItemId,
              campaignId: campaignValue,
              adGroupType: type,
              adGroups: values
            }
          })
        }}
      />
    )
  }

  const renderMenu = (menu: React.ReactNode) => {
    if (!campaignOptions) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Icon
            icon="spinner"
            spin
          />{' '}
          Loading...
        </p>
      )
    }
    if (campaignOptions.length === 0) {
      return <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>No Results Found</p>
    }
    return menu
  }

  const handleAddAdGroup = (values: number[] | undefined, types: 'keyword' | 'video') => {
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.ADD_AD_GROUPS,
      payload: {
        persona,
        currentActivationItemId: activationItemId,
        campaignId: campaignValue,
        adGroupType: types,
        adGroups: values
      }
    })
  }

  return (
    <>
      <td
        data-testid="act-mgr-activations-campaign-picker"
        id="act-mgr-activations-campaign-picker"
        headers="activations-campaign"
      >
        <Tooltip
          disabled={!getLabelValue(campaignValue)}
          content={
            campaignValue && (
              <div
                data-testid="act-mgr-target-moments-table-io-persona-io-selector-tooltip"
                id="act-mgr-target-moments-table-io-persona-io-selector-tooltip"
                className="text-left"
              >
                {getLabelValue(campaignValue)}
              </div>
            )
          }
        >
          <div className="flex flex-col gap-2">
            {doesValueExists && <p className="text-xs text-red-500">{CAMPAIGN_VALUE_EXISTS}</p>}
            <TreePicker
              data={props.campaignOptions || []}
              style={{ width: `${fixedColumnWidth}px` }}
              key={`${props.persona}_${props.activationItemId}_campaign`}
              placement="autoVertical"
              id="campaignInput"
              labelKey="name"
              valueKey="id"
              childrenKey="children"
              value={campaignValue}
              height={450}
              disabledItemValues={disabledCampaignOptions}
              renderMenu={renderMenu}
              defaultExpandAll
              cleanable={false}
              placeholder="Campaigns"
              onChange={(value: number) => {
                handleCampaignChange(elementKey, persona, activationItemId, value, campaignValue, setDoesValueExists)
              }}
            />
          <style>
            {`
              .rs-tree {
                overflow-y:auto;
              }
            `}
          </style>
          </div>
        </Tooltip>
      </td>
      <td
        data-testid="act-mgr-activations-keyword-ad-group-picker"
        id="act-mgr-activations-keyword-ad-group-picker"
        headers="activations-keywords"
      >
        {doesValueExists && <p className="h-6">&nbsp;</p>}
        <CheckPicker
          data={(campaignValue && adGroupOptions && adGroupOptions.get(campaignValue)) || []}
          disabled={!adGroupOptions || !adGroupOptions.has(campaignValue)}
          style={{ width: `${fixedColumnWidth}px` }}
          menuStyle={{width: `${fixedColumnWidth*1.5}px`}}
          value={keywordAdGroupValue ?? []}
          key={`${props.persona}_${props.activationItemId}_keyword`}
          placement="autoVerticalStart"
          id="keywordAdGroupInput"
          labelKey="name"
          valueKey="id"
          placeholder="Keywords"
          virtualized={false}
          onChange={(values: number[] | undefined) => handleAddAdGroup(values, 'keyword')}
          cleanable
          renderExtraFooter={() => renderFooter('keyword')}
        />
      </td>
      <td
        data-testid="act-mgr-activations-video-ad-group-picker"
        id="act-mgr-activations-video-ad-group-picker"
        headers="activations-videos"
      >
        {doesValueExists && <p className="h-6">&nbsp;</p>}
        <div className="flex gap-1">
          <CheckPicker
            data={(campaignValue && adGroupOptions && adGroupOptions.get(campaignValue)) || []}
            disabled={!adGroupOptions || !adGroupOptions.has(campaignValue)}
            style={{ width: `${fixedColumnWidth}px` }}
            menuStyle={{width: `${fixedColumnWidth*1.5}px`}}
            value={videoAdGroupValue ?? []}
            key={`${props.persona}_${props.activationItemId}_videos`}
            placement="autoVerticalStart"
            id="videoAdGroupInput"
            labelKey="name"
            valueKey="id"
            placeholder="Videos"
            virtualized={false}
            onChange={(values: number[] | undefined) => handleAddAdGroup(values, 'video')}
            cleanable
            renderExtraFooter={() => renderFooter('video')}
          />
          {props.isRemovable ? (
            <button
              data-testid="act-mgr-campaign-to-ad-group-row-remove-btn"
              id="act-mgr-campaign-to-ad-group-row-remove-btn"
              type="button"
              className="rounded-md focus:outline-none "
              onClick={() => {
                props.handleRemoveRow && props.handleRemoveRow(props.persona, props.activationItemId, campaignValue)
              }}
            >
              <span className="sr-only">Close panel</span>
              <XMarkIcon
                className="w-8 h-8"
                aria-hidden="true"
              />
            </button>
          ) : (
            <div className="w-8 h-8">&nbsp;</div>
          )}
        </div>
        <style>
          {`
          .rs-tree-node-disabled{
            color: unset; 
          }
        `}
        </style>
      </td>
    </>
  )
}

export const AddSelectorGroupRow = (props: { children: React.ReactNode }) => {
  return (
    <>
      <tbody data-testid="campaign-adgroup-row" id="campaign-adgroup-row">
        <tr className="campaignAdGroupRow">
          <td colSpan={2}></td>
          {props.children}
          <td></td>
        </tr>
      </tbody>
    </>
  )
}
