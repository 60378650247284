import { ZodType, z } from 'zod';

export interface LoginFormType {
    email: string
    password: string
}

export const LoginFormSchema: ZodType<LoginFormType> = z.object({
    email: z.string().email({ message: 'Enter valid email' }),
    password: z.string().min(1, { message: 'Enter valid password' })
        .max(64, { message: 'At most 64 characters' }),
})
