import React from 'react';
import SightlyButton from "@/components/Sightly/SightlyButton";
import { routes } from "@/routes";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "@tanstack/react-location";

export const UpdateSettings = () => {
  const navigate = useNavigate()

  return (
    <div
      data-testid="update-settings"
      className="flex items-center justify-between border h-24 bg-white rounded-md"
    >
      <div className="mx-6 grid gap-2 flex-1 ">
        <div className="font-semibold text-base">Update Your Settings</div>
      </div>
      <div className="mx-4">
        <SightlyButton
          datatestid="button-settings"
          handleClick={() => navigate({to: routes.app.settings.account.path})}
          icon={<Cog6ToothIcon className="-ml-0.5 mr-2 h-5"/>}
          text={"Settings"}
        />
      </div>
    </div>
  )
}
