import React from 'react';
import BrandProfileSteps from '@/views/BrandProfiles/BrandProfile/BrandProfileSteps';
import { Outlet, useMatch } from '@tanstack/react-location';
import useBrandProfileBasic from '@/hooks/brandProfile/useBrandProfileBasic';
import LoadingData from '@/views/Discover/Boards/components/LoadingData';
import { Progress } from 'rsuite';
import { accentColor } from '@/assets/jss/colorConstants';
import { BRAND_PROFILE } from '@/views/BrandProfiles/constants';
import { ProductCountTracker } from '@/views/BrandProfiles/BrandProfile/components/ProductCountTracker';
import { products } from '@/staticData/products';
import useSteps from '@/hooks/brandProfile/useSteps';

// Returns LastSaved text in terms of Months or days or (hours and mins) ago
const lastSaved = (intervalTime: { days: number; hours: number; mins: number }) => {
  const prefix = 'Saved'
  let lastSaved = ''
  if (intervalTime.days > 0) { 
    if (intervalTime.days >= 30) //show only month if number of days is more than a month
      lastSaved += ` ${Math.floor(intervalTime.days / 30)} ${
        Math.floor(intervalTime.days / 30) === 1 ? 'month' : 'months'
      }`
      //show only ðays if number of hours is more than a day
    else lastSaved += ` ${intervalTime.days} ${intervalTime.days === 1 ? 'day' : 'days'}`
  } else {
    if (intervalTime.hours > 0) lastSaved += ` ${intervalTime.hours} ${intervalTime.hours === 1 ? 'hour' : 'hours'}`
    if (intervalTime.mins > 0) lastSaved += ` ${intervalTime.mins} ${intervalTime.mins === 1 ? 'min' : 'mins'}`
  }
  return lastSaved ? `${prefix} ${lastSaved} ago` : `${prefix} just now`
}

// Converts interval in milliseconds to days, hours and mins
const getDaysHoursMinutes = (interval: number) => {
  interval = Number(interval)
  const mins = Math.floor(interval / 60)
  const hours = Math.floor(mins / 60)
  const days = Math.floor(hours / 24)
  return { days, hours: hours % 24, mins: mins % 60 }
}

function BrandProfile() {
  const {
    params: { brandProfileId }
  } = useMatch()
  const { queryResult: brandProfileQuery } = useBrandProfileBasic(Number(brandProfileId))

  const { _steps,
    activeStep,
    handleStepsClick,
    getCurrentProgress
  } = useSteps();

  const _lastSaved = React.useMemo(() => {
    return brandProfileQuery.data && lastSaved(getDaysHoursMinutes(Number(brandProfileQuery.data.lastSaved)))
  }, [brandProfileQuery.data])

  if (!brandProfileQuery.data) {
    return <LoadingData message="Loading Brand Profile" />
  }
  return (
    <div className="gridContainer">
      {/* Heading Section */}
      <div className="flex flex-col justify-start gap-2 p-6 text-xl font-semibold text-gray-700 header">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="10" height="12" stroke="#333D47" strokeWidth="2"/>
                        <line x1="4" y1="5" x2="8" y2="5" stroke="#333D47" strokeWidth="2"/>
                        <line x1="4" y1="9" x2="8" y2="9" stroke="#333D47" strokeWidth="2"/>
            </svg>
            <p data-testid='BrandProfile-brandProfileName-info-label'>{brandProfileQuery.data.brandProfileName}</p>
          </div>
          <p data-testid="BrandProfile-lastSaved-info-label" className="text-xs text-gray-400">{_lastSaved}</p>
        </div>
        <div>
          <div className="px-3 py-1 text-xs text-gray-700 bg-gray-100 rounded-xl max-w-fit" data-testid='BrandProfile-brandProfileStatus-info-label'>
            {brandProfileQuery?.data?.submitted ? BRAND_PROFILE.STATUS.SUBMITTED:BRAND_PROFILE.STATUS.DRAFT}
          </div>
        </div>
        <Progress.Line
          percent={getCurrentProgress}
          showInfo={false}
          strokeColor={accentColor}
        />
      </div>
      
      <div className="flex flex-col justify-between sidebar ">
        <BrandProfileSteps
          steps={_steps}
          activateStep={handleStepsClick}
          activeStep={activeStep}
        />
        <ProductCountTracker 
          productName={products.AI_CREDITS} 
          title='AI Credits'
          direction='col' 
          additionalStyle='p-4'
          dataTestId='product-count-tracker-ai-credits'
          />
      </div>
      <div className="main">
        <Outlet />
      </div>

      <style>{`
  .gridContainer {
		display: grid;
   	height: 100%;	
    grid-template-columns: 20em 1fr;	
    grid-template-rows:  10em 1fr;
    grid-template-areas:
        "header header"  
        "sidebar main"
	}	
	.sidebar {
    grid-area: sidebar;
    border-right: 2px solid #f8f8f8;
	}
  .header{
    grid-area: header;
  }
  .main {
    grid-area: main;
    overflow-y: auto;
    height: calc(100% - 64px)
  }
`}</style>
    </div>
  )
}

export default BrandProfile
