import React from 'react';
import '@/components/Navbars/index.css';
import { HorizontalNavAccountItem } from '@/components/Navbars/Horizontal/HorizontalNavAccountItem';
import useUser from '@/hooks/useUser';
import styled from 'styled-components';

const ContainerDiv = styled.div`
    background-color: white;
    border-bottom-width: 1px;
    border-bottom-color: rgb(225, 229, 232);
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const HorizontalNavBar = () => {
    const { currentAccount } = useUser();

    return (
        <ContainerDiv>
            <HorizontalNavAccountItem accountName={currentAccount?.accountName} />
        </ContainerDiv>
    )
}
