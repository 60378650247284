import debounce from 'just-debounce-it'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { userAccountAxios } from '@/axiosInstances'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import { rqKeys } from '@/ReactQueryKeyFactory'
import toast from 'react-hot-toast'
import { api } from '@/api/api'
import { products } from '@/staticData/products'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import  useUser  from '@/hooks/useUser'

export type Board = {
    id: number
    name: string
    brandProfileId?: number | null
    defaultType: string | null
    createdByName: string
    createdDate: string
    updatedDate: string
    updatedByName: string
    starred: boolean
    type: 'custom' | 'general' | 'naturalLanguage'
    selectedItems: string[]
    operator: 'and' | 'or'
}

export type ChangeStarredProps = {
    id: number
    starred: boolean
}

export default function useBoards() {
    const navigate = useNavigate<MyLocationGenerics>()
    const { user } = useUser()
    const { brandProfileId, boardIds, accountId } = useSearch<MyLocationGenerics>()
    const queryKey = rqKeys.brandProfileBoards(brandProfileId)
    const queryClient = useQueryClient()


    const {productQuotaTally : boardsQuotaTally} = useProductUsage({accountId, productName: products.BOARDS})

    const queryObj = useQuery(queryKey, () => api.brandProfile.boards.get(brandProfileId), {
        enabled: !!brandProfileId
    })

    type ChangeNameProps = {
        id: number
        name: string
    }

    const changeName = useMutation(
        ({ id, name }: ChangeNameProps) => {
            const stateBoards = queryClient.getQueryData<Board[]>(queryKey)
            const url = `/brand-profile/${brandProfileId}/board/${id}`
            return userAccountAxios.patch(url, {
                starred: stateBoards?.filter((b) => b.id === id)[0].starred,
                name
            })
        },
        {
            onMutate: async ({ id, name }: ChangeNameProps) => {
                queryClient.setQueryData(queryKey, (old: Board[] | undefined) => {
                    if (!old) return []
                    return old.map((t: Board) => {
                        if (t.id === id) {
                            const updatedDate = new Date().toISOString();
                            const updatedByName = user?.firstName +' '+user?.lastName;
                            return { ...t, name, updatedDate, updatedByName }
                        }
                        return t
                    })
                })
            }
        }
    )

    const changeStarred = useMutation(
        ({ id, starred }: ChangeStarredProps) => {
            const stateBoards = queryClient.getQueryData<Board[]>(queryKey)
            let url = `/brand-profile/${brandProfileId}/board/${id}`
            return userAccountAxios.patch(url, {
                starred,
                name: stateBoards?.filter((b) => b.id === id)[0].name
            })
        },
        {
            onMutate: async ({ id, starred }: ChangeStarredProps) => {
                queryClient.setQueryData(queryKey, (old: Board[] | undefined) => {
                    if (!old) return []
                    return old.map((t: Board) => {
                        if (t.id === id) {
                            return { ...t, starred }
                        }
                        return t
                    })
                })
            },
            onSuccess: () => {
                toast.success('Changes saved')
            }
        }
    )

    const deleteBoard = useMutation(
        (id: number) => {
            const url = `/brand-profile/${brandProfileId}/board/${id}`
            return userAccountAxios.delete(url)
        },
        {
            onMutate: async (id: number) => {
                queryClient.setQueryData(queryKey, (old: Board[] | undefined) => {
                    if (!old) return []
                    return old.filter((board: Board) => board.id !== id)
                })
            },
            onSuccess: async () => {
                // refresh the latest product quota
                await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(accountId, products.BOARDS))
            }
        }
    )

    const handleSelectBoard = (id: number) => {
        const _boardIds = Array.isArray(boardIds) ? (boardIds as number[]) : []

        let finalBoardIds: number[] = []
        if (_boardIds.includes(id)) {
            finalBoardIds = _boardIds.filter((_id) => _id !== id)
        } else {
            finalBoardIds = [..._boardIds, id] // _boardIds.push(String(id))
        }
        navigate({
            search: (old) => {
                // clear search bar on board select change
                delete old?.searchTerm;
                return {
                    ...old,
                    boardIds: finalBoardIds
                }
            }
        })
    }

    const handleSelectAllBoards = () => {
        navigate({
            search: (old) => {
                // clear search bar on board select change
                delete old?.searchTerm;
                return {
                    ...old,
                    boardIds: queryObj.data?.map((b) => b.id)
                }
            }
        })
    }

    const handleNameChange = debounce(({ id, name }: ChangeNameProps) => {
        changeName.mutate({ id, name })
    }, 300)

    return {
        boards: queryObj.data,
        boardsFetched: queryObj.isFetched,
        boardsIsLoading: queryObj.isLoading,
        boardsIsFetching: queryObj.isFetching,
        handleRename: handleNameChange,
        boardsQuotaTally,
        handleEditStarred: changeStarred.mutate,
        handleDelete: deleteBoard.mutate,
        handleSelectBoard,
        handleSelectAllBoards,
        clearSelectedBoards: () => {
            navigate({
                search: (old) => {
                    return { ...old, boardIds: [] }
                }
            })
        }
    }
}
