import React from 'react'

function ProductLimitedAccessInfo(props: { productNames?: Array<string | undefined>; inSufficientPermission?: boolean }) {
  return (
    <>
      {props.inSufficientPermission && <p>Insufficient Permissions to access this resource. </p>}
      {props.productNames && props.productNames.length  && <p>This account has reached it’s {props.productNames.filter(productName => !!productName).join(',')} limit. </p>}
      {!props.inSufficientPermission && !props.productNames && <p>This account has reached it’s limit. </p>}
      For assistance please contact support.
    </>
  )
}

export default ProductLimitedAccessInfo
