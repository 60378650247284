import React from 'react';
import { Divider, Loader, Modal } from 'rsuite';
import { HiOutlineX } from "react-icons/hi";

import { BoardFromTemplateItem } from './BoardFromTemplateItem';
import useBoardCreator from '@/views/Discover/Boards/useBoardCreator';
import { useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';

import trendingMomentsSvg from '@/assets/img/boardTemplate/trending_moments_board_template.svg';


type IProps = {
    open: boolean,
    handleClose: () => void
}

export const BoardFromTemplateModal = ({ open, handleClose }: IProps) => {

    if (!open) {
        return null
    }
    const { brandProfileId } = useSearch<MyLocationGenerics>();

    const boardCreator = useBoardCreator({
        brandProfileId,
        boardType: 'general'
    })

    const listOfAgregates = boardCreator.aggregates.find(a => a.name === "Suggested Boards")?.items;

    return (
        <Modal
            data-testid='new-board-from-template-modal'
            className='items-center justify-center p-0 no-padding-modal'
            show={open}
            onHide={handleClose}
            style={{
                display: "flex",
            }}
        >
            <div className="flex justify-between pt-5 row px-7 align-center">
                <h1 data-testid={"modal-title"} className='text-xl font-black'>New Board from Templates</h1>
                <HiOutlineX data-testid={"close-modal-icon"} onClick={handleClose} className='mt-1 text-xl font-black cursor-pointer' />
            </div>

            <Divider className='bg-[#D9D9D9] border'></Divider>

            <Modal.Body className='grid grid-rows-[164px] grid-cols-[repeat(3,_minmax(196px,_1fr))] grid-flow-row gap-x-6 gap-y-10 px-7'>
                {
                    boardCreator.isCreating
                        ? <div className="absolute content-center w-full h-full pb-12 text-center">
                            <Loader size="md" />
                        </div>
                        : listOfAgregates?.map((template) =>
                            <BoardFromTemplateItem
                                key={template.id}
                                id={template.id}
                                img={template.img ? template.img : trendingMomentsSvg}
                                name={template.name}
                                boardCreator={boardCreator}
                                hoverText={template.hoverText ? template.hoverText : template.name}
                                handleClose={handleClose}
                                disableBlurButton={template.alwaysDisabled}
                            />
                        )
                }
            </Modal.Body>
        </Modal>

    )
}
