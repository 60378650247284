import React from 'react'
import {
    InformationCircleIcon,
    XCircleIcon,
    CheckCircleIcon,
    ClockIcon
} from '@heroicons/react/24/outline'

interface IProps {
    text: string,
    type: string,
    dataTestId?:string,
}

export const SightlyTag = ({ text, type, dataTestId }: IProps) => {
    let color
    let icon

    switch (type) {
        case 'error':
            color = 'red'
            icon = <XCircleIcon className="h-4 mr-1 text-red-700" />
            break
        case 'warning':
            color = 'yellow'
            icon = <ClockIcon className="h-4 mr-1 text-yellow-700" />
            break
        case 'info':
            color = 'blue'
            icon = <InformationCircleIcon className="h-4 mr-1 text-blue-700" />
            break
        case 'success':
            color = 'green'
            icon = <CheckCircleIcon className="h-4 mr-1 text-green-700" />
            break
    }

    return (
      <span
        data-testid={dataTestId}
        className={`inline-flex items-center rounded-full bg-${color}-100 px-2 py-0.5 text-xs text-${color}-700`}>
          {icon}
          {text}
      </span>
    )

}
