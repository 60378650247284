import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'

export const groupByInsertionOrder = (activationsList: Array<ActivationItemType>) => {
    //Fetch the ioIds and keep them sorted
    const insertionOrderIds = activationsList.map(({insertionOrderId})=> insertionOrderId).sort()
    //use the sorted ioId key list to create a consistent Map: this is to avoid unwanted shuffling if activationsList changes.
    const activationsGroupedByIO = new Map<number, Array<ActivationItemType>>(insertionOrderIds.map((ioId)=>[ioId,[]]))
  
    for (const activation of activationsList) {
      const activationGroup = activationsGroupedByIO.get(activation.insertionOrderId)
      activationGroup?.push(activation)
    }
    return activationsGroupedByIO
  }
