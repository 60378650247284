import { api } from "@/api/api"
import { formatDateyyyyMMdd, logError } from "@/utils"
import { useQuery } from "@tanstack/react-query"

export function useNarrativeSearch(page: number, pageSize: number, fromDate: Date, 
    search?: string) {

  const narrativesQuery = useQuery(
    ['narrativesQuery', page, pageSize, search],
    async () => {
      return await api.narratives.get(page, pageSize, 
        formatDateyyyyMMdd(fromDate), search)
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      onError: (err) => {
        logError(err, { info: 'Error fetching trends data' });
      }
    }
  )

  return {
    narrativesQuery: narrativesQuery.data,
    isLoadingNarratives: narrativesQuery.isLoading,
  }
}

export function useNarrativesById(narrativeId: number) {
  const narrativesQuery = useQuery(
    ['narrativesQuery', narrativeId],
    async () => {
      return await api.narratives.getById(narrativeId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting topic' })
      }
    }
  )

  return {
    narrativesQuery
  }
}