import React, { Fragment, ReactElement, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import SightlyButton from '@/components/Sightly/SightlyButton'

interface IProps {
    open: boolean
    title: string
    subtitle: string | ReactElement
    handleConfirm: () => void
    handleCancel: () => void
    dataTestId?: string
}
export function ConfirmationModalTailwind({ open, title, subtitle, handleConfirm, handleCancel, dataTestId }: IProps) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={() => undefined}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel data-testid={dataTestId || 'confirmation-modal'} className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-yellow-100 rounded-full">
                                        <ExclamationTriangleIcon
                                            className="w-6 h-6 text-yellow-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">{subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <SightlyButton
                                        text="Cancel"
                                        selectorClass="pendo-cancel-block-button"
                                        datatestid='confirmationmodal-cancel'
                                        handleClick={handleCancel}
                                        type="secondaryblack"
                                    />
                                    <SightlyButton
                                        text="Continue"
                                        selectorClass="pendo-continue-to-block-button"
                                        datatestid='confirmationmodal-continue'
                                        handleClick={handleConfirm}
                                        type="primary"
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
