/*eslint-disable no-restricted-globals*/
import React from 'react';
import Button from 'rsuite/lib/Button';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { createOpinion } from '@/redux/actions/admin/opinions';
import CheckTreePicker from 'rsuite/lib/CheckTreePicker';
import { joinClasses } from '@/utils.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'rsuite/lib/Input';
import useBrandProfileOpinionTypes from '@/hooks/brandProfile/useBrandProfileOpinionTypes';

const mapStateToProps = (state) => {
	return {
		opinionSaving: state.admin.opinionSaving
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		createOpinion: (opinion) => dispatch(createOpinion(opinion))
	}
}

const schemaValidation = Yup.object().shape({
	question: Yup.string()
		.required('Required')
		.min(2, 'Must be greater than 1 character')
		.max(50, 'Must be less than 50 characters'),
	description: Yup.string()
		.min(2, 'Must be greater than 1 character')
		.max(255, 'Must be less than 255 characters'),
	opinionTypeIds: Yup.array()
		.of(Yup.number())
		.required()
		.min(1, 'must have one')
})

function Opinion(props) {
	const opinionTypesObj = useBrandProfileOpinionTypes({ excludeArchived: true });
	const { handleSubmit, formState, setValue } = useForm({
		defaultValues: {
			opinionTypeIds: [],
			question: ''
		},
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schemaValidation)
	})

	const onSubmit = async (data) => {
		const opinion = {
			question: data.question,
			opinionTypeIds: data.opinionTypeIds
		}
		props.createOpinion(opinion)
	}

	return (
		<div style={{ width: 500, margin: 'auto' }}>
			<div
				className={joinClasses('input-label', 'isRequired')}
				style={{ marginTop: 24 }}>
				Opinion
			</div>
			<Input
				componentClass={'textarea'}
				rows={3}
				id='question'
				disabled={false}
				onChange={(v) => {
					setValue('question', v, {
						shouldValidate: true,
						shouldDirty: true
					})
				}}
			/>
			<div style={{ color: 'red' }}>
				{formState?.errors?.question && formState?.errors?.question.message}
			</div>

			<div
				className={joinClasses('input-label', 'isRequired')}
				style={{ marginTop: 24 }}>
				Opinion Types
			</div>
			<CheckTreePicker
				preventOverflow
				data={opinionTypesObj.opinionTypes}
				labelKey={'typeName'}
				valueKey={'typeId'}
				onChange={(v) => {
					setValue('opinionTypeIds', v, {
						shouldValidate: true,
						shouldDirty: true
					})
				}}
				block
			/>
			<div style={{ color: 'red' }}>
				{formState?.errors?.opinionTypeIds &&
					formState?.errors?.opinionTypeIds.message}
			</div>

			<div style={{ height: 20 }} />

			<Button
				loading={props.opinionSaving}
				disabled={
					!formState.isDirty || !formState.isValid || props.opinionSaving
				}
				onClick={handleSubmit(onSubmit)}>
				Save
			</Button>
		</div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Opinion)
