import React from 'react';

interface IProps {
    selectedTabIndex: number
    setSelectedTabIndex: Function
    fixedHeadline?: String
}

export default function BoardTabs({ selectedTabIndex, setSelectedTabIndex, fixedHeadline }: IProps) {
  const handleClick = (index:number) => setSelectedTabIndex(index);
  const getSelectedTabStyle = (tabIndex:number) =>  selectedTabIndex === tabIndex ? 'font-semibold border-sightlyPurple' : 'border-transparent';

  return (
    <>
      {fixedHeadline &&
      <div className="flex flex-row h-16 px-6 grow justify-between">
        <div className="flex flex-col">
          <div className="grow shrink-0">
            <h3 className="flex-row grow w-full mt-3">{fixedHeadline}</h3>
          </div>
        </div>
      </div>}
      <div data-testid="anticipation-board-header-tabs" className="flex h-16 px-6 gap-8 pointer">
        <button
            id="pendo-moments-tab"
            key={'moments'}
            className={`flex flex-col justify-center cursor-pointer border-b-4 ${getSelectedTabStyle(0)}`}
            onClick={() => handleClick(0)}
        >
            Moments
        </button>
        <button
          id="pendo-conversations-tab"
          key={'conversations'}
          className={`flex flex-col justify-center cursor-pointer border-b-4 ${getSelectedTabStyle(1)}`}
          onClick={() => handleClick(1)}
        >
          Narratives
        </button>
      </div>
    </>
  )
}
