export const ACTIVATIONS_MANAGER_TITLE = 'Submit Target List'
export const ACTIVATIONS_MANAGER_DESCRIPTION = 'Get started by implementing the target list.'
export const ACTIVATIONS_TABLE_PERSONA_COL_CAPTION = 'Persona'
export const ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION = 'Campaign'
export const ACTIVATIONS_TABLE_KEYWORDS_COL_CAPTION = 'Keywords To Apply To'
export const ACTIVATIONS_TABLE_VIDEOS_COL_CAPTION = 'Videos To Apply To'
export const ACTIVATIONS_MANAGER_DELETE_ACTIVATIONS = 'Are you sure you want to remove this activation?'
export const ACTIVATIONS_MANAGER_DELETE_PERSONAS = 'Are you sure you want to remove this persona?'
export const ACTIVATIONS_MANAGER_BULK_DELETE = (count:number) => `Are you sure you want to remove ${count} ${count <= 1 ? "moment": "moments"}?`
export const TEN_MINUTES = 10 * 60 * 1000
export const CAMPAIGN_VALUE_EXISTS = 'Campaign already exists.'
export const BULK_ASSIGN_TITLE = 'Assign Campaigns and Ad Groups'
export const DISABLED_CREATE_BUTTON = {
  isActivationLoading: 'Activations are being submitted.',
  invalidActivationList: 'One of the campaigns are not assigned either keyword or video ad groups.'
}
export const SUBMITTING_ACTIVATIONS = 'Submitting Activations...'
