import { ConfirmationModalTailwind } from '@/components/ConfirmationModalTailwind';
import React from 'react';

interface ConfirmationModalProps {
  tryingToBlockWithTarget: boolean
  setTryingToBlockWithTarget: Function
  setIsViewingBlockModal: Function
  tryingToBlockWithMonitor: boolean
  setTryingToBlockWithMonitor: Function
  tryingToTargetWithBlock: boolean
  setTryingToTargetWithBlock: Function
  setIsViewingTargetModal: Function
  tryingToTargetWithMonitor: boolean
  setTryingToTargetWithMonitor: Function
}

export default function ConfirmationModals(props:ConfirmationModalProps) {
  const {
    tryingToBlockWithTarget,
    setTryingToBlockWithTarget,
    setIsViewingBlockModal,
    tryingToBlockWithMonitor,
    setTryingToBlockWithMonitor,
    tryingToTargetWithBlock,
    setTryingToTargetWithBlock,
    setIsViewingTargetModal,
    tryingToTargetWithMonitor,
    setTryingToTargetWithMonitor
  } = props;
  return <>
    <ConfirmationModalTailwind
      open={tryingToBlockWithTarget}
      title="Warning"
      subtitle={
        'We recommend that you target one of the moments you are trying to block. Are you still sure you want to block it?'
      }
      handleConfirm={() => {
        setTryingToBlockWithTarget(false)
        setIsViewingBlockModal(true)
      }}
      handleCancel={() => setTryingToBlockWithTarget(false)}
    />
    <ConfirmationModalTailwind
      open={tryingToBlockWithMonitor}
      title="Warning"
      subtitle={
        'We recommend that you monitor one of the moments you are trying to block. Are you still sure you want to block it?'
      }
      handleConfirm={() => {
        setTryingToBlockWithMonitor(false)
        setIsViewingBlockModal(true)
      }}
      handleCancel={() => setTryingToBlockWithMonitor(false)}
    />

    <ConfirmationModalTailwind
      open={tryingToTargetWithBlock}
      title="Warning"
      subtitle={
        'We recommend that you block one of the moments you are trying to target. Are you still sure you want to target it?'
      }
      handleConfirm={() => {
        setTryingToTargetWithBlock(false)
        setIsViewingTargetModal(true)
      }}
      handleCancel={() => setTryingToTargetWithBlock(false)}
    />
    <ConfirmationModalTailwind
      open={tryingToTargetWithMonitor}
      title="Warning"
      subtitle={
       'We recommend that you monitor one of the moments you are trying to target. Are you still sure you want to target it?'
      }
      handleConfirm={() => {
        setTryingToTargetWithMonitor(false)
        setIsViewingTargetModal(true)
      }}
      handleCancel={() => setTryingToTargetWithMonitor(false)}
    />
  </>;
}