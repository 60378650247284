import React from 'react';
import { Link, useMatch } from '@tanstack/react-location';
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyButton from '@/components/Sightly/SightlyButton';
import toast from 'react-hot-toast';
import { isAxiosError } from '@/utils_ts';
import { logError } from '@/utils';
import { MyLocationGenerics } from '@/classes/utils';
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';
import { userAccountAxios } from '@/axiosInstances';
import { OpinionTypeSchema, OpinionTypeSchemaArray, PatchOpinionTypeSchema, PatchOpinionTypeType } from './opinionTypesTypes';

export const EditOpinionType = () => {
    const {
        params: { opinionTypeId }
    } = useMatch<MyLocationGenerics>()

    const opinionTypeQuery = useQuery(
        ['opinionType', opinionTypeId],
        () => getById({ opinionTypeId: Number(opinionTypeId) }),
        {
          enabled: !!opinionTypeId,
          onError: (err) => {
            logError(err)
          }
        }
      )

      const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        getValues,
        formState: { errors, isDirty, isValid }
      } = useForm<PatchOpinionTypeType>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
          archived: false,
          typeName: '',
          descriptionText: '',
        },
        resolver: zodResolver(PatchOpinionTypeSchema)
      });

      React.useEffect(() => {
        if (opinionTypeQuery.data) {
            reset(opinionTypeQuery.data);
        }
    }, [opinionTypeQuery.data, reset]);                  

    const onSubmit = (opinionType: PatchOpinionTypeType) => {
        submitMutation.mutate(opinionType)
    }

    const submitMutation = useMutation(
        (opinionType: PatchOpinionTypeType) => patch(opinionType, Number(opinionTypeId)),
        {
            onSettled: () => {
                reset(getValues())
            },
            onSuccess: () => {
                toast.success('Changes saved')
            },
            onError: (err) => {
                if (isAxiosError<any>(err)) {
                    // Access to config, request, and response
                    const isDuplicateNameError = err?.response?.data.includes('already exists')
                    const isTypeAssociatedToOpinion = err?.response?.data.includes('type linked to opinions')
                    if (isDuplicateNameError) {
                        toast.error('That name is already used, please try another name')
                    }
                    else if (isTypeAssociatedToOpinion) {
                        toast.error('Cannot archive Opinion Type linked to Opinion')
                        setValue('archived', !getValues().archived);
                    }
                    else {
                        toast.error('Unknown error ocurred')
                        logError(err, { info: 'error posting opinion type' })
                    }
                } else {
                    toast.error('Unknown error ocurred')
                    logError(err, { info: 'error posting opinion type' })
                }
            }
        }
    )

    const getById = async ({ opinionTypeId }: { opinionTypeId: number }) => {
        const url = `opinions/types/${opinionTypeId}`
        const res = await userAccountAxios.get(url)
        const parsedResult = OpinionTypeSchema.parse(res.data)
        const opinionType = parsedResult

        // Convert null to empty string for form field initialization
        if (opinionType.descriptionText === null) {
          opinionType.descriptionText = ''
        }

        return opinionType
      }

      const patch = async (opinionType: PatchOpinionTypeType, opinionTypeId: number) => {
        const url = `opinions/types/${opinionTypeId}`
        const res = await userAccountAxios.patch(url, opinionType)
      }

    if (opinionTypeQuery.isLoading) {
        return <LoadingPage message="Fetching data" />
    }

    return (
        <div className="w-full flex justify-center items-center mt-12">
            <div className="w-3/6">
                <Link to={'/app/admin/opinions/types'}>Back to Opinion Types</Link>
                <div className="mt-2 md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Edit Opinion Type
                        </h2>
                    </div>
                </div>
                <form
                    className="space-y-4 mt-8"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <SightlyToggle
                        id="archived"
                        setEnabled={(v) => {
                            setValue('archived', v, {
                                shouldValidate: true,
                                shouldDirty: true
                            })
                        }}
                        enabled={getValues().archived || false}
                        enabledText="Archived"
                        disabledText="Active"
                        isDisabled={false}
                    />
                    <SightlyInputForm
                        control={control}
                        id="typeName"
                        name="typeName"
                        register={register}
                        label="Name"
                        required
                        autoFocus
                        error={errors?.typeName?.message}
                        disabled={getValues().archived || false}
                    />  

                    <SightlyInputForm
                        control={control}
                        id="descriptionText"
                        name="descriptionText"
                        register={register}
                        label="Description"                        
                        autoFocus
                        error={errors?.descriptionText?.message}
                        disabled={getValues().archived || false}
                    />                        
                    
                    <SightlyButton
                        data-testid="submit-button"
                        id="submitButton"
                        handleClick={() => undefined}
                        text="Submit"
                        disabled={!isDirty || !isValid}
                    />
                </form>
            </div>
        </div>
    )
}