import React from "react";
import { Radio, RadioGroup } from "rsuite";

export default function AdFormatSection({ splitAdFormat, handleOnChange }) {
    return (
      <div data-testid='div-report-generator-select-ad-format'>
        <h5 className="pt-2">3. Select your Ad Format Breakout</h5>
        <label className="pl-4 text-gray-500">
          {' '}
          This determines how your reports will be generated
        </label>
        <div className="pl-2">
          <RadioGroup
            name="adFormatList"
            value={splitAdFormat}
            onChange={(value) => handleOnChange('splitAdFormat', value)}
          >
            <Radio value={true}>Split View</Radio>
            <Radio value={false}>Combined</Radio>
          </RadioGroup>
        </div>
      </div>
    )
}
