import React from 'react'
import twitterLogo from '@/assets/img/twitterLogo.png'
import { ReactComponent as LinkIcon } from '@/assets/img/hyperlink.svg'
import { MomentArticleType } from '@/views/Discover/Moments/v2/types'
import NoImageFoundPath from '@/assets/img/NoImageFound.svg'

interface iProps {
    article: MomentArticleType
}

const MomentArticle = ({ article }: iProps) => {
    const getHostnameFromRegex = (url: string) => {
        const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
        return matches && matches[1]
    }

    const isTwitter = article.url.includes('twitter')

    const _imageUrl = React.useMemo(() => {
        if (isTwitter) return twitterLogo
        if (article.images && article.images[0]) return article.images[0]
        if (!article.images || !article.images[0]) return ''
        return ''
    }, [article])

    return (
        <div
            data-testid="moment-modal-articles-component" 
            onClick={() => window.open(article.url, '_blank')?.focus()}
            style={{
                cursor: 'pointer',
                height: 77,
                marginTop: 8,
                borderBottom: '1px solid rgb(212, 217, 217, 0.5)',
                display: 'flex',
                width: 700
            }}
        >
            <div data-testid="moment-modal-articles-image" style={{ width: 116 }}>
                {_imageUrl.length > 0 ? (
                    <img
                        style={{ width: 100, height: 72, borderRadius: 3 }}
                        src={_imageUrl}
                    />
                ) : (
                    
                       <img 
                            src={NoImageFoundPath}
                            className="pb-0 h-[95%] w-[88%] object-cover"                    
                        />
                    
                )}
            </div>
            <div data-testid="moment-modal-articles-title"  style={{ flex: 1 }}>
                <div
                    style={{
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#333D47',
                        textAlign: 'left'
                    }}
                >
                    {article.title === 'Access Denied' ? 'Title Unavailable' : article.title}
                </div>

                <div data-testid="moment-modal-articles-url"  style={{ display: 'flex', paddingBottom: 5 }}>
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: '#333D47',
                            textAlign: 'left'
                        }}
                    >
                        {isTwitter ? 'twitter.com' : article.url ? getHostnameFromRegex(article.url) : ''} -
                    </div>
                    <div data-testid="moment-modal-articles-link-icon"  style={{ marginTop: 2, marginLeft: 3.5 }}>
                        <LinkIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MomentArticle
