import React from 'react'
import Button from 'rsuite/lib/Button'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { createEvergreenChannel } from '@/redux/actions/admin/evergreenChannels'
import { iabCategoriesFilter } from '@/staticData/iabCategories'
import CheckTreePicker from 'rsuite/lib/CheckTreePicker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'

const mapStateToProps = (state) => {
    return {
        evergreenChannelSaving: state.admin.evergreenChannelSaving
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvergreenChannel: (evergreenChannel) => dispatch(createEvergreenChannel(evergreenChannel))
    }
}

const schemaValidation = Yup.object().shape({
    channelId: Yup.string().required('Required').length(24),
    iabCategoryIds: Yup.array().of(Yup.string())
})

function EvergreenChannel(props) {
    const { handleSubmit, formState, setValue, register, control } = useForm({
        defaultValues: {
            channelId: '',
            iabCategoryIds: []
        },
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(schemaValidation)
    })

    return (
        <div style={{ width: 500, margin: 'auto', padding: 48, display: 'flex', flexDirection: 'column', gap: 24 }}>
            <SightlyInputForm
                error={formState.errors?.channelId?.message}
                name="channelId"
                register={register}
                id="channelId"
                label="Channel ID"
                control={control}
                required={true}
            />

            <CheckTreePicker
                preventOverflow
                defaultExpandAll={false}
                data={iabCategoriesFilter}
                labelKey={'name'}
                valueKey={'id'}
                onChange={(v) => {
                    setValue('iabCategoryIds', v, {
                        shouldValidate: true,
                        shouldDirty: true
                    })
                }}
                cascade={true}
                block
            />

            <Button
                loading={props.evergreenChannelSaving}
                disabled={!formState.isValid || !formState.isDirty || props.evergreenChannelSaving}
                onClick={handleSubmit(props.createEvergreenChannel)}
            >
                Save
            </Button>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EvergreenChannel)
