import { listBuilderAxios } from "@/axiosInstances"
import { ITopic, IPeople, peopleSchema, IBrand, brandsSchema, INarrative, narrativeSchema, IMomentTimeline, momentTimelineSchema, ITopicMetric, topicMetricSchema, IArticle, articleSchema } from "@/views/Trends/types"


export const topics = {
    get: async (search?: string) => {
      let url = `/trend-analysis/topics?prompt=${search ? search : ''}`
      const res = listBuilderAxios.get<ITopic[]>(url);
      return res
    },

    getById: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}`
      return listBuilderAxios.get<ITopic>(url)
    },

    getPeople: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/people`
      const result = await listBuilderAxios.get<IPeople[]>(url)
      return result.data.map(person => peopleSchema.parse(person))
    },

    getBrands: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/brands`
      const result = await listBuilderAxios.get<IBrand[]>(url)
      return result.data.map(brand => brandsSchema.parse(brand))
    },

    getNarratives: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/narratives`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getMoments: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/moments`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getArticles: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/moment-articles`
      const result = await listBuilderAxios.get<IArticle[]>(url)
      return result.data.map(brand => articleSchema.parse(brand))
    },

    getMomentTimeline: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/moments-timeline`
      const result = await listBuilderAxios.get<IMomentTimeline>(url)
      return momentTimelineSchema.parse(result.data)
    },

    getSocialPosts: async (topicId: number) => {
      const url = `/topics/${topicId}/metrics?type=social-posts`
      const result = await listBuilderAxios.get<ITopicMetric[]>(url)
      return result.data.map(topicMetric => topicMetricSchema.parse(topicMetric))
    },

    getArticleMetrics: async (topicId: number) => {
      const url = `/topics/${topicId}/metrics?type=articles`
      const result = await listBuilderAxios.get<ITopicMetric[]>(url)
      return result.data.map(topicMetric => topicMetricSchema.parse(topicMetric))
    },

    getSentimentScore: async (topicId: number) => {
      const url = `/trend-analysis/topics/${topicId}/sentiment-score`
      const result = await listBuilderAxios.get<Number>(url)
      return result.data;
    }
}
