/**
 * userCan service, an abstraction layer handling permissions + account product checks.
 * The userCan function checks if a user has the required permission and returns a boolean value.
 * Optionally, products can be enforced against the supplied user's account's products.
 */

import { productsWithQuota } from "@/staticData/products"
import { isIframe } from "@/validations";
import { products } from "@/staticData/products";

export enum PermissionCheckType {
    PERMISSION_CHECK = "PERMISSION_CHECK",
    PRODUCT_CHECK = "PRODUCT_CHECK",
    PRODUCT_PERMISSION_CHECK = "PRODUCT_PERMISSION_CHECK",
    PRODUCT_QUOTA_PERMISSION_CHECK = "PRODUCT_QUOTA_PERMISSION_CHECK"
}

interface userCanPermissionProductQuotaArgs {
    requiredPermissionValue?: string;
    userPermissions?: string[];
    requiredProductValue?: string;
    userProducts?: string[];
    quota_is_allowed?: boolean;
    checkType: PermissionCheckType;
}

export default function userCanPermissionProductQuota(permsObj: userCanPermissionProductQuotaArgs): boolean {
    const {
        requiredPermissionValue = '',
        userPermissions = [],
        requiredProductValue = '',
        userProducts = [],
        quota_is_allowed = false,
        checkType
    } = permsObj;

    const required_permission_check = (requiredPermissionValue: string, userPermissions: string[] = []): boolean => {
        return userPermissions?.includes(requiredPermissionValue);
    }

    const required_product_check = (requiredProductValue  = '',userProducts: string[] = []): boolean => {
        return userProducts?.includes(requiredProductValue);
    }


    const required_product_quota = (requiredProductValue: string = '', quota_is_allowed: boolean): boolean => {
        if(!quota_is_allowed) return false;
        if(requiredProductValue in productsWithQuota) return true;
        else return false;
    }

    const checkTikTok = isIframe();
    const tiktokProducts = [
        products.TIKTOK_HASHTAGS_DASHBOARD, 
        products.TIKTOK_ACTIVATIONS, 
        products.BRAND_PROFILE_INFO_ONLY,
        products.BRAND_PROFILES,
        products.TIKTOK_INTEGRATIONS,
        products.USERS
    ];

    if(checkTikTok && requiredProductValue.length && !tiktokProducts.includes(requiredProductValue)) return false
    if(checkType === PermissionCheckType.PERMISSION_CHECK) return required_permission_check(requiredPermissionValue, userPermissions)
    else if(checkType === PermissionCheckType.PRODUCT_CHECK) return required_product_check(requiredProductValue, userProducts)
    else if(checkType === PermissionCheckType.PRODUCT_PERMISSION_CHECK) return required_permission_check(requiredPermissionValue, userPermissions) && required_product_check(requiredProductValue, userProducts)
    else if(checkType === PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK) return required_permission_check(requiredPermissionValue, userPermissions) && required_product_check(requiredProductValue, userProducts)  && required_product_quota(requiredProductValue,quota_is_allowed)
    else return false;
}
