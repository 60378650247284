import { userAccountAxios } from '@/axiosInstances'
import {
    PatchThemeType,
    PostScenarioType,
    PostThemeType,
    ScenarioSchema,
    ScenariosFromGetAllSchema,
    ThemeSchema,
    ThemesSchema,
} from './types'

export const api = {
    v2Scenarios: {
        get: async ({ archived }: { archived: boolean }) => {
            const url = `/scenarios/v2?archived=${archived}`
            const res = await userAccountAxios.get(url)
            return ScenariosFromGetAllSchema.parse(res.data)
        },
        getById: async ({ scenarioId }: { scenarioId: number }) => {
            const url = `/scenarios/v2/${scenarioId}`
            const res = await userAccountAxios.get(url)
            return ScenarioSchema.parse(res.data)
        },
        post: async (scenario: PostScenarioType) => {
            const url = '/scenarios/v2'
            return await userAccountAxios.post(url, scenario)
        },
        patch: async (scenario: PostScenarioType, scenarioId: number) => {
            const url = `/scenarios/v2/${scenarioId}`
            return await userAccountAxios.patch(url, scenario)
        }
    },
    themes: {
        get: async ({ archived }: { archived: boolean }) => {
            const url = `/scenarios/v2/themes?archived=${archived.toString()}`
            const res = await userAccountAxios.get(url)
            return ThemesSchema.parse(res.data)
        },
        getById: async ({ themeId }: { themeId: number }) => {
            const url = `/scenarios/v2/themes/${themeId}`
            const res = await userAccountAxios.get(url)
            return ThemeSchema.parse(res.data)
        },
        post: async (theme: PostThemeType) => {
            const url = '/scenarios/v2/themes'
            return await userAccountAxios.post(url, theme)
        },
        patch: async ({ theme, themeId }: { theme: PatchThemeType; themeId: number }) => {
            const url = `/scenarios/v2/themes/${themeId}`
            return await userAccountAxios.patch(url, theme)
        }
    },
}
