import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { logError } from '../../../utils'
import { activationsApi } from './activations-api'
import { activationsRqKeys } from './activationsRqKeys'
import { ActivationItemType } from './activations-types'
import toast from 'react-hot-toast'

interface IProps {
    brandProfileId: number | undefined
}

export const useTodoActivations = ({ brandProfileId }: IProps) => {
    const QUERY_KEY = activationsRqKeys.todoActivations({ brandProfileId })
    const queryClient = useQueryClient()
    const activationsQuery = useQuery(QUERY_KEY, () => activationsApi.getTodoActivations({ brandProfileId }), {
        onError: (err) => {
            logError(err)
        },
        enabled: !!brandProfileId
    })

    const removeItemFromTodo = useMutation(activationsApi.removeItemFromTodo, {
        onSettled: () => {
            queryClient.invalidateQueries(activationsRqKeys.activationStatusCount(brandProfileId))
            queryClient.invalidateQueries(QUERY_KEY)
        },
        onSuccess: () => {
            toast.success('Changes saved')
        },
        onMutate: (args) => {
            queryClient.setQueryData(QUERY_KEY, (old: any) => {
                return old.filter((i: ActivationItemType) => i.activationItemId !== args.activationListItemId)
            })
            queryClient.setQueryData(activationsRqKeys.activationStatusCount(brandProfileId), (old: any) => {
                return {
                    ...old,
                    activeCount: old.activeCount - 1
                }
            })
        },
        onError: (err) => {
            logError(err)
        }
    })

    return {
        activationsQuery,
        removeItemFromTodo
    }
}


export const useInProcessActivations = ({ brandProfileId }:IProps) =>{
    const QUERY_KEY = activationsRqKeys.inProcessActivations({brandProfileId})
    const queryClient = useQueryClient()
    return useQuery(QUERY_KEY, () => activationsApi.getInProcessActivations({ brandProfileId }), {
        onError: (err) => {
            logError(err)
        },
        enabled: !!brandProfileId
    })

}

export const useImplementedActivations = ({ brandProfileId }:IProps) =>{
    const QUERY_KEY = activationsRqKeys.inProcessActivations({brandProfileId})
    const queryClient = useQueryClient()
    return useQuery(QUERY_KEY, () => activationsApi.getImplementedActivations({ brandProfileId }), {
        onError: (err) => {
            logError(err)
        },
        enabled: !!brandProfileId
    })

}