import React from 'react';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { connect } from 'react-redux';
import { LayoutState } from '@/redux/reducers/layout';

interface BrandProfileFooterProps {
  disabled: boolean
  handlePrevious: () => void
  handleContinue: () => void
  activeStep: string
  isMinimized: boolean
}


const mapStateToProps = (state: LayoutState) => {
  return {
    isMinimized: state.layoutReducer.isMinimized
  }
}

function BrandProfileFooter({ disabled, handlePrevious, handleContinue, activeStep, isMinimized }: BrandProfileFooterProps) {
  const width = isMinimized ? "w-[calc(100%-280px-98px)]" : "w-[calc(100%-280px-187px)]"

  return <div className={`transition-all footer h-16 fixed top-90 bottom-0 ${width} right-0`}>
    <div className='flex flex-row justify-between w-full'>
      <div>{activeStep !== 'brandInformation' && (
        <SightlyButton
          id="prevButton"
          text="Previous"
          handleClick={handlePrevious}
          type="secondary"
        />
      )}
      </div>

      {activeStep !== 'submit' && (
        <SightlyButton
          id="nextButton"
          text="Continue"
          disabled={disabled}
          handleClick={handleContinue}
        />
      )}
    </div>

    <style>{`
      .footer {
        background-color: #FFF;
        box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(6px);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
        gap: 18px;
        z-index: 2;
      }
    `}</style>
  </div>
}

export default connect(mapStateToProps)(BrandProfileFooter)
