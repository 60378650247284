import React from 'react'
import { connect } from 'react-redux'
import Loader from 'rsuite/lib/Loader'
import Uploader from 'rsuite/lib/Uploader'
import Panel from '../../../components/CustomPanel'
import Button from 'rsuite/lib/Button'
import Whisper from 'rsuite/lib/Whisper'
import debounce from 'just-debounce-it'
import Tooltip from 'rsuite/lib/Tooltip'
import { targetTypes } from './constants'
import { setUploadedList } from '../../../redux/actions/engage/lists'
import { uploadedListObjValidation } from '../../../schemas/Engage/Lists/schemas'
import toast from 'react-hot-toast'
import { postUploadedListService } from '../../../services/lists'
import Input from 'rsuite/lib/Input'
import SelectPicker from 'rsuite/lib/SelectPicker'
import useBrandProfiles from '../../../hooks/brandProfile/useBrandProfiles'
import SightlyButton from '../../../components/Sightly/SightlyButton'
import config from '../../../config'
import { useMatch, useNavigate } from '@tanstack/react-location'
import useUser from '../../../hooks/useUser'
import UploadSuccessAlert from './components/UploadSuccessAlert'
import UploadErrorAlert from './components/UploadErrorAlert'

const parseExcelUrl = config.api.listBuilderUrl + '/parse-excel'

const mapStateToProps = (state) => {
    return {
        uploadedList: state.engage.uploadedList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUploadedList: (list) => dispatch(setUploadedList(list))
    }
}

const objectives = [
    { objectiveId: 1, objectiveName: 'Reach & Awareness' },
    { objectiveId: 2, objectiveName: 'Branding' },
    { objectiveId: 3, objectiveName: 'Conversions' }
]

function UploadList(props) {
    const navigate = useNavigate()
    const { currentAccount } = useUser()
    const currentAccountId = currentAccount?.accountId
    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false)
    const [showWarningPopup, setShowWarningPopup] = React.useState(false)
    const [postingList, setPostingList] = React.useState(false)
    const [uploadStats, setUploadStats] = React.useState({})
    const [uploadedCount, setUploadedCount] = React.useState(0)
    const [formObject, setFormObject] = React.useState({
        objectiveId: objectives[0].objectiveId,
        targetTypeId: targetTypes[3].targetTypeId,
        brandProfileId: null,
        name: '',
        uploadedList: []
    })

    const { data: brandProfiles, isLoading: brandProfilesIsLoading } = useBrandProfiles({
        submittedOnly: true
    })

    const filteredBrandProfiles = React.useMemo(() => {
        if (!brandProfiles) {
            return []
        }
        return brandProfiles.filter((bp) => bp.submitted && !bp.archived)
    }, [brandProfiles])

    const isClean = React.useMemo(() => {
        if (formObject.name.length < 1) return false
        if (formObject.uploadedList.length < 1) return false
        if (filteredBrandProfiles?.length < 1) return false
        return true
    }, [formObject])

    React.useEffect(() => {
        return () => {
            setShowSuccessPopup(false)
            setShowWarningPopup(false)
        }
    }, [])

    React.useEffect(() => {
        if (filteredBrandProfiles.length > 0) {
            handleChangeExecute('brandProfileId', filteredBrandProfiles[0].brandProfileId)
        }
    }, [filteredBrandProfiles])

    const handleUploadSuccess = (data) => {
        props.setUploadedList(data)
    }

    const handleRemoveFile = () => {
        decrementUploadedCount()
        handleChange('uploadedList', [])
    }

    const incrementUploadedCount = () => {
        setUploadedCount(uploadedCount + 1)
    }

    const decrementUploadedCount = () => {
        setUploadedCount(uploadedCount - 1)
    }

    const handleDownloadTemplateClick = () => {
        window.location.href =
            'https://storage.googleapis.com/sightlyoutcomeintelligence_templates/SmartList_Upload_Template.xlsx'
    }

    const handleChange = debounce((property, value) => {
        handleChangeExecute(property, value)
    }, 500)

    const handleChangeExecute = (property, value) => {
        switch (property) {
            case 'name':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        name: value
                    }
                    return newState
                })
                break

            case 'objectiveId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        objectiveId: value
                    }
                    return newState
                })
                break

            case 'targetTypeId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        targetTypeId: value
                    }
                    return newState
                })
                break

            case 'brandProfileId':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        brandProfileId: value
                    }
                    return newState
                })
                break

            case 'uploadedList':
                setFormObject((prevState) => {
                    let newState = {
                        ...prevState,
                        uploadedList: value
                    }
                    return newState
                })
                break

            default:
                break
        }
    }

    const handleSubmit = async () => {
        setPostingList(true)

        const listData = {
            list: {
                smartListName: formObject.name,
                objectiveId: formObject.objectiveId,
                smartListData: formObject.uploadedList,
                targetTypeId: formObject.targetTypeId
            },
            brandProfileId: formObject.brandProfileId,
            accountId: currentAccountId
        }

        const { data: uploadStats } = await postUploadedListService(listData)

        setPostingList(false)
        await setUploadStats(uploadStats)

        uploadStats?.invalidIds?.length > 0 ?
          setShowWarningPopup(true) :
          setShowSuccessPopup(true)
    }

    if (brandProfilesIsLoading) {
        return (
            <Loader
                vertical
                center
            />
        )
    }

    return (
        <div style={{ padding: 24 }}>
            <UploadSuccessAlert
              uploadStats={uploadStats}
              showSuccessPopup={showSuccessPopup}
              setShowSuccessPopup={setShowSuccessPopup}
              navigate={navigate}
            />

            <UploadErrorAlert
              uploadStats={uploadStats}
              showWarningPopup={showWarningPopup}
              setShowWarningPopup={setShowWarningPopup}
              setShowSuccessPopup={setShowSuccessPopup}
            />

            <Panel
                header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Upload a list</div>

                        <Whisper
                            delayShow={700}
                            delayHide={0}
                            trigger="hover"
                            placement="bottom"
                            speaker={<Tooltip>The list can be either channels or videos</Tooltip>}
                        >
                            <Button
                                appearance="link"
                                style={{ margin: 0, padding: 0 }}
                                onClick={handleDownloadTemplateClick}
                            >
                                Click to download template
                            </Button>
                        </Whisper>
                    </div>
                }
                bordered
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        className="isRequired"
                        style={{ marginTop: 24 }}
                    >
                        SmartList Name
                    </div>
                    <Input onChange={(val) => handleChange('name', val)} />
                    <div style={{ marginTop: 24 }}> Objective</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'objectiveName'}
                        valueKey={'objectiveId'}
                        placeholder={'Select'}
                        data={objectives}
                        defaultValue={formObject.objectiveId}
                        onChange={(val) => {
                            handleChange('objectiveId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}>Target Type</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'targetTypeName'}
                        valueKey={'targetTypeId'}
                        placeholder={'Select'}
                        data={targetTypes}
                        defaultValue={formObject.targetTypeId}
                        onChange={(val) => {
                            handleChange('targetTypeId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}>Brand Profile</div>
                    <SelectPicker
                        preventOverflow
                        labelKey={'brandProfileName'}
                        valueKey={'brandProfileId'}
                        placeholder={'Select'}
                        value={formObject.brandProfileId}
                        data={filteredBrandProfiles}
                        onChange={(val) => {
                            handleChangeExecute('brandProfileId', val)
                        }}
                        cleanable={false}
                        block
                        searchable={false}
                    />
                    <div style={{ marginTop: 24 }}> </div>
                    <div>
                        <p className="isRequired">Pick a file</p>
                        <Uploader
                            disabled={uploadedCount > 0}
                            listType="text"
                            accept=".xlsx, .xls, .csv"
                            action={parseExcelUrl}
                            headers={{ Authorization: localStorage.getItem('token') }}
                            multiple={false}
                            draggable
                            onError={(err) => console.log('err consolelog:', err)}
                            onSuccess={(val) => {
                                uploadedListObjValidation
                                    .validate(val)
                                    .then((data) => {
                                        handleChange('uploadedList', val)
                                        handleUploadSuccess(val)
                                    })
                                    .catch((err) => {
                                        toast.error(err.errors.join(', ') + '. Please fix your file and try again', {
                                            duration: 5000
                                        })
                                    })

                                incrementUploadedCount()
                            }}
                            onRemove={() => handleRemoveFile()}
                        >
                            <div
                                style={{
                                    height: 150,
                                    width: '100%',
                                    display: 'flex',
                                    color: 'grey',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Click or Drag files to this area to upload
                            </div>
                        </Uploader>
                    </div>
                </div>
                <div style={{ marginTop: 24 }} />
                <SightlyButton
                    loading={postingList}
                    disabled={!isClean}
                    handleClick={handleSubmit}
                    text="Submit"
                />
            </Panel>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadList)
