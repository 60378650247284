import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from '@tanstack/react-location'
import { Loader } from 'rsuite'
import { Link } from '@tanstack/react-location';
import { PATH_BRAND_PROFILES, } from '@/routes';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import { InProcess } from '@/views/Discover/Activations/v2/InProcess'
import { Implemented } from '@/views/Discover/Activations/v2/Implemented'
import { activationsApi } from '@/views/Discover/Activations/v2/hooks/activations-api'
import { useBrandProfilesActivationsStatus } from '@/views/Discover/Activations/v2/hooks/useBrandProfilesActivationsStatus'
import {
  ACTIVATION_MANAGER_DESCRIPTION,
  ACTIVATION_MANAGER_TITLE,
  NO_ACTIVATIONS_FOUND
} from '@/views/Discover/Activations/v2/constants'
import { InfoPage } from '@/components/InfoPage'
import { Todo } from '@/views/Discover/Activations/v2/ToDo/index'
import { SideBar } from '@/views/Discover/Activations/v2/SideBar'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const handleDownloadActivations = async ({
  brandProfileId,
  setDownloadingTrends,
  setShowTooManyTrendsError,
  selectedMomentIds,
  activationState
}: any) => {
  if (brandProfileId === undefined) return
  setDownloadingTrends(true)
  if (selectedMomentIds.length > 1000) {
    setDownloadingTrends(false)
    setShowTooManyTrendsError(true)
    return
  }
  const args = {
    brandProfileId,
    clusterIds: selectedMomentIds,
    activationState
  }
  try {
    await activationsApi.downloadActivations(args)
  } catch (err) {
    toast.error('There was an error downloading your moments.')
    setDownloadingTrends(false)
  }

  setDownloadingTrends(false)
}

const Activations: React.FC = () => {
  const bpQuery = useBrandProfiles({
    submittedOnly: true
  })
  const brandProfilesMap = React.useMemo(() => {
    return new Map(bpQuery.data?.map((brandProfile) => [brandProfile.brandProfileId, brandProfile.brandProfileName]))
  }, [bpQuery])

  const brandProfileIds = React.useMemo(
    () => bpQuery.data?.map(({ brandProfileId }) => brandProfileId) || [],
    [bpQuery.data]
  )

  const undefinedBrandProfileIds = React.useMemo(
    () => {
      if (brandProfileIds.length < 1) return true;
      return brandProfileIds.length > 0 && brandProfileIds.every(element => element === undefined)
    },
    [bpQuery.data, brandProfileIds]
  );

  const brandProfileActivationsStatus = useBrandProfilesActivationsStatus({ brandProfileIds })
  const [currentBrandProfileId, setCurrentBrandProfileId] = React.useState<number>()
  const [currentTab, setCurrentTab] = React.useState('todo')
  const navigate = useNavigate()

  React.useEffect(() => {
    if(brandProfileActivationsStatus.data && brandProfileActivationsStatus.data.size > 0)
    if (!currentBrandProfileId || !brandProfileActivationsStatus.data.has(currentBrandProfileId) ) {
      //getting the first brandProfileId from the latest brandProfileActivationsStatus
      const brandProfileId = Array.from(
        brandProfileActivationsStatus.data?.keys() ?? [bpQuery.currentBrandProfile?.brandProfileId ?? 0]
      )[0]
      setCurrentBrandProfileId(brandProfileId)
      navigate({
        search: (old) => {
          return {
            ...old,
            brandProfileId
          }
        }
      })
    }
  }, [brandProfileActivationsStatus.data])

  if (bpQuery.isLoading || brandProfileActivationsStatus.isLoading) {
    return (
      <Loader
        center
        content="Loading Activations..."
        vertical
        className="z-10"
        speed="slow"
      />
    )
  }

  if (brandProfileActivationsStatus?.data && brandProfileActivationsStatus.data?.size < 1) {
    return (
      <InfoPage
        message={
          <div>
            <span>{NO_ACTIVATIONS_FOUND}</span>
          </div>
        }
      />
    )
  }

  const currentActivationsStatus = brandProfileActivationsStatus?.data?.get(
    bpQuery.currentBrandProfile?.brandProfileId ?? 0
  )

  const tabs = [
    {
      id: 'todo',
      name: 'To-Do',
      count: currentActivationsStatus?.activeCount ?? 0
    },
    {
      id: 'inprocess',
      name: 'In Process',
      count: currentActivationsStatus?.pendingCount ?? 0
    },
    {
      id: 'implemented',
      name: 'Implemented',
      count: currentActivationsStatus?.completeCount ?? 0
    }
  ]

  return (
    <div className="flex flex-row min-h-screen animate-in fade-in bg-slate-100 max-h-max">
      <SideBar
        activationsStatusData={brandProfileActivationsStatus.data}
        brandProfilesMap={brandProfilesMap}
        currentBrandProfileId={currentBrandProfileId}
        setCurrentBrandProfileId={setCurrentBrandProfileId}
      />
      <div className="flex-grow px-10 py-6">
        <h1 className="text-lg font-semibold text-gray-900">{ACTIVATION_MANAGER_TITLE}</h1>
        <div className="mt-2 text-xs font-medium text-gray-500">{ACTIVATION_MANAGER_DESCRIPTION}</div>

        <Tabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
        />

        <div>
          {undefinedBrandProfileIds && (
            <div className="text-center h-[50vh]">
              No submitted brand profiles. Please <Link to={PATH_BRAND_PROFILES}>submit a brand profile</Link> first.
            </div>
          )}
          {!undefinedBrandProfileIds && currentTab === 'todo' ? (
            <Todo
              {...{
                defaultBrandProfileId: currentBrandProfileId,
                key: currentBrandProfileId,
                handleDownloadActivations,
              }}
            />
          ) : !undefinedBrandProfileIds &&  currentTab === 'inprocess' ? (
            <InProcess />
          ) : !undefinedBrandProfileIds &&  currentTab === 'implemented' ? (
            <Implemented
              {...{
                handleDownloadActivations,
                key: currentBrandProfileId,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Activations

type TabsProps = {
  currentTab: string
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  tabs: {
    id: string
    name: string
    count: number
  }[]
}
const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  return (
    <nav
      className="flex -mb-px space-x-8"
      aria-label="Tabs"
      data-testid = "activations-main-tabs"
      id = "pendo-activations-main-tabs"
    >
      {props.tabs.map((tab) => (
        <div
          data-testid = 'activations-main-tab'
          id = {`pendo-activations-main-tab-${tab.id}`}
          key={tab.name}
          className={classNames(
            props.currentTab === tab.id
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
            'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
          )}
          aria-current={props.currentTab === tab.id ? 'page' : undefined}
          onClick={() => {
            props.setCurrentTab(tab.id)
          }}
        >
          {tab.name}
          {tab.count ? (
            <span
              className={classNames(
                props.currentTab === tab.id ? 'bg-sightly-blue-light text-blue-600' : 'bg-slate-300 text-gray-900',
                ' ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
              )}
            >
              {tab.count}
            </span>
          ) : null}
        </div>
      ))}
    </nav>
  )
}
