import React from 'react'
import Message from 'rsuite/lib/Message'
import ConfirmSubmit from './ConfirmSubmit'
import SightlyButton from '../../../../../components/Sightly/SightlyButton'
import { Link, useMatch } from '@tanstack/react-location'
import { LoadingPage } from '../../../../../components/LoadingPage'
import useBrandProfileBasic from '../../../../../hooks/brandProfile/useBrandProfileBasic'
import useBrandProfile from '../../../../../hooks/brandProfile/useBrandProfile'
import { MyLocationGenerics } from '../../../../../classes/utils'
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { PATH_TIKTOK_HASHTAGS } from '@/routes';
import { isIframe } from '@/validations';

const Review = () => {
    const {
        params: { brandProfileId }
    } = useMatch<MyLocationGenerics>()

    const { queryResult: bpBasic, submit, submitWithoutBoard, submitMutation, submitWithoutBoardMutation } = useBrandProfileBasic(Number(brandProfileId))
    const { status, statusIsLoading } = useBrandProfile(Number(brandProfileId))
    const { userPermissions, userProducts, perms, products } = useUser()

    const canSeeHashtagDashboard = userCanPermissionProductQuota({
        requiredPermissionValue: perms.TIKTOK_HASHTAGS_DASHBOARD_READ,
        userPermissions: userPermissions,
        requiredProductValue: products.TIKTOK_HASHTAGS_DASHBOARD,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
    })

    const canSeeBrandProfileInfoOnly = userCanPermissionProductQuota({
        requiredPermissionValue: perms.BRAND_PROFILE_READ,
        userPermissions: userPermissions,
        requiredProductValue: products.BRAND_PROFILE_INFO_ONLY,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
    })

    const perfectlyComplete =
        status?.brandProfileNameComplete &&
        status.websiteComplete &&
        status.opinionIncompleteCount === 0 &&
        status.queriesComplete === true &&
        (userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_SCENARIOS_V2_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })
            ? status.scenarioV2IncompleteCount === 0
            : status.scenarioIncompleteCount === 0)

    if (bpBasic?.isLoading || statusIsLoading) {
        return <LoadingPage message="Fetching status" />
    }

    if (bpBasic?.data?.submitted) {
        return (
            <>
                <Message
                    showIcon
                    type="success"
                    title="Success!"
                    description="This brand profile is submitted."
                />
                {canSeeHashtagDashboard && isIframe() && (
                    <div className='w-full flex justify-center'>
                        <div className='mt-4 w-52'>
                            <SightlyButton
                                block
                                text="Go to TikTok Hashtags"
                                handleClick={() => window.location.href = PATH_TIKTOK_HASHTAGS}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }

    if (!status?.brandProfileNameComplete || !status?.websiteComplete) {
        return (
            <Message
                showIcon
                type="warning"
                title="We need more info!"
                description={
                    <p style={{ fontSize: 16 }}>
                        Please complete all of the required fields in the{' '}
                        <Link
                            to={`${window.location.pathname.substring(
                                0,
                                window.location.pathname.lastIndexOf('/')
                            )}/brandInformation`}
                        >
                            Brand Information section
                        </Link>{' '}
                        before submitting.
                    </p>
                }
            />
        )
    }

    return (
        <div
            style={{
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 24
            }}
        >
            {perfectlyComplete ? (
                <Message
                    showIcon
                    type="success"
                    title="Ready to submit!"
                    description={'Your brand profile is ready to submit'}
                />
            ) : (
                !canSeeBrandProfileInfoOnly && !isIframe() && (
                    <Message
                        showIcon
                        type="warning"
                        title="We need more info!"
                        description={<ConfirmSubmit brandProfile={status} />}
                    />
                )
            )}
            <div style={{ width: 200 }}>
                <SightlyButton
                    block
                    disabled= {submitMutation.isLoading || submitWithoutBoardMutation.isLoading}
                    id={'submitBrandProfileButton'}
                    text="Submit"
                    handleClick={isIframe() ? submitWithoutBoard : submit}
                />
            </div>
        </div>
    )
}

export default Review
