import React from 'react';
import { useForm } from 'react-hook-form';
import { useMatch, useSearch } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm';
import AIGenerateModal from '@/views/BrandProfiles/BrandProfile/components/Queries/components/AIGenerateModal';
import { ZodType, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AIGeneratorBoundaryValues } from '@/views/BrandProfiles/BrandProfile/components/Queries/constants';
import { useProductUsage } from '@/hooks/subscription/useProductUsage';
import { products } from '@/staticData/products';

type BrandAIGenerationFormType = {
  competitorsSuggestionCount: number
  aspirationalSuggestionCount: number
}

export const BrandAIGenerationFormSchema: ZodType<BrandAIGenerationFormType> = z.object({
  competitorsSuggestionCount: z.coerce
    .number()
    .gte(AIGeneratorBoundaryValues.minValue)
    .lte(AIGeneratorBoundaryValues.maxValue, {
      message: `Number can't be more than ${AIGeneratorBoundaryValues.maxValue}`
    }),
  aspirationalSuggestionCount: z.coerce
    .number()
    .gte(AIGeneratorBoundaryValues.minValue)
    .lte(AIGeneratorBoundaryValues.maxValue, {
      message: `Number can't be more than ${AIGeneratorBoundaryValues.maxValue}`
    })
})

export const InputStyleWrapper = (props: { children: React.ReactNode }) => (
  <div className="p-4 bg-sightlyLightBlue rounded-md border border-[#BAE6FD]">{props.children}</div>
)

const CustomLabel = (props: { mainCaption: string; subCaption: string }) => (
  <div className="flex flex-col">
    <p className="text-gray-700">{props.mainCaption}</p>
    <p className="text-sm text-sky-700">{props.subCaption}</p>
  </div>
)

export type BrandAIGenerationType = {
  type: 'Competitor' | 'Aspirational Brand'
  brandName: string
  suggestionCount: number
  companySummary?: string | undefined
  companyMentality?: string | undefined
  existingBrands: string[]
}

const BrandAIGenerationForm = (props: {
  isAIGenerate: boolean
  setIsAIGenerate: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmitForm: (data: BrandAIGenerationFormType) => void
}) => {
  const [totalSuggestionCount, setTotalSuggestionCount] = React.useState(0)
  const {
    params: { brandProfileId }
  } = useMatch()
  const { accountId } = useSearch<MyLocationGenerics>()
  const { productQuotaTally: queriesQuotaTally } = useProductUsage({ accountId, productName: products.QUERIES })
  const { productQuotaTally: aiCreditsQuotaTally } = useProductUsage({ accountId, productName: products.AI_CREDITS })

  const maxQueryQuota = React.useMemo(() => {
    return queriesQuotaTally ? queriesQuotaTally.productBalance : AIGeneratorBoundaryValues.maxValue
  }, [queriesQuotaTally])
  
  const maxAICreditsQuota = React.useMemo(() => {
    return aiCreditsQuotaTally ? aiCreditsQuotaTally.productBalance : AIGeneratorBoundaryValues.maxValue
  }, [aiCreditsQuotaTally])

  const maxQueryValue = Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota, maxAICreditsQuota)
  const BrandAIGenerationFormSchema: ZodType<BrandAIGenerationFormType> = z.object({
    competitorsSuggestionCount: z.coerce
      .number()
      .gte(AIGeneratorBoundaryValues.minValue)
      .lte(maxQueryValue, {
        message: `Suggestion Count exceeds the max limit of ${maxQueryValue}`
      }),
    aspirationalSuggestionCount: z.coerce
      .number()
      .gte(AIGeneratorBoundaryValues.minValue)
      .lte(maxQueryValue, {
        message: `Suggestion Count exceeds the max limit of ${maxQueryValue}`
      })
  })

  const { register, handleSubmit, reset, control, formState, watch } = useForm<BrandAIGenerationFormType>({
    defaultValues: {
      aspirationalSuggestionCount: 0,
      competitorsSuggestionCount: 0
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(BrandAIGenerationFormSchema)
  })

  React.useEffect(() => {
    const sum = Number(watch('competitorsSuggestionCount')) + Number(watch('aspirationalSuggestionCount'))
    setTotalSuggestionCount(() => sum)
  }, [formState])

  const onSubmit = async (data: BrandAIGenerationFormType) => {
    props.handleSubmitForm(data)
    reset()
  }

  const disableSubmit = React.useMemo(() => {
    let canAddQuery = true
    if (queriesQuotaTally && aiCreditsQuotaTally) {
      canAddQuery =
        totalSuggestionCount <= AIGeneratorBoundaryValues.maxValue &&
        queriesQuotaTally.productBalance - totalSuggestionCount >= 0 &&
        aiCreditsQuotaTally.productBalance - totalSuggestionCount >= 0
    }
    return !canAddQuery
  }, [queriesQuotaTally,aiCreditsQuotaTally, totalSuggestionCount])

  return (
    <AIGenerateModal
      open={props.isAIGenerate}
      handleClose={() => {
        props.setIsAIGenerate(false)
        reset()
      }}
      handleSubmit={handleSubmit(onSubmit)}
      disableSubmit={disableSubmit}
    >
      <div className="flex flex-col gap-2">
        <InputStyleWrapper>
          <SightlyInputForm
            dataTestId={'brand-competitorsSuggestionCount-input'}
            control={control}
            inputType="number"
            minValue={AIGeneratorBoundaryValues.minValue}
            maxValue={Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota)}
            customLabel={
              <CustomLabel
                mainCaption="Competitors"
                subCaption="Select the number of suggestions"
              />
            }
            error={formState.errors?.competitorsSuggestionCount?.message}
            name="competitorsSuggestionCount"
            register={register}
            id="competitorsSuggestionCount"
          />
        </InputStyleWrapper>
        <InputStyleWrapper>
          <SightlyInputForm
            dataTestId={'brand-aspirationalSuggestionCount-input'}
            control={control}
            inputType="number"
            minValue={AIGeneratorBoundaryValues.minValue}
            maxValue={Math.min(AIGeneratorBoundaryValues.maxValue, maxQueryQuota)}
            error={formState.errors?.aspirationalSuggestionCount?.message}
            name="aspirationalSuggestionCount"
            register={register}
            id="aspirationalSuggestionCount"
            customLabel={
              <CustomLabel
                mainCaption="Aspirational Brands"
                subCaption="Select the number of suggestions"
              />
            }
          />
        </InputStyleWrapper>
      </div>
      {totalSuggestionCount > maxQueryValue && (
        <p
          className="text-sm text-red-600"
          data-testid="brand-suggestion-count-max-error"
        >
          Suggestion Count exceeds the permissible limit of total {maxQueryValue} queries.
        </p>
      )}
    </AIGenerateModal>
  )
}

export default BrandAIGenerationForm
