import { useQueries } from '@tanstack/react-query'
import { userAccountAxios } from '@/axiosInstances'
import { products, productsWithQuota } from '@/staticData/products'
import { logError } from '@/utils'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { z } from 'zod'

export const SubscriptionQuotaSchema = z.object({
  currentProductCount: z.number(),
  productName: z.string(),
  productQuota: z.number(),
  productBalance: z.number(),
  isAllowed: z.boolean()
})

export type SubscriptionQuota = z.infer<typeof SubscriptionQuotaSchema>

const checkSubscriptionQuota = async (accountId?: number, productName?: string) => {
  try {
    if (!accountId || !(productName && productName in products)) {
      throw Error(`Invalid arguments: 
      ${!(productName && productName in products) && `${productName} is not available in UI`}`)
    }
    const url = `/account/${accountId}/subscription-package/${productName}`
    const result = await userAccountAxios.get<SubscriptionQuota | undefined>(url)
    return result
  } catch (error: any) {
    logError(error)
  }
}

export default function useSubscription(props: { accountId?: number; userProducts: string[] }) {
  const { accountId, userProducts } = props
  const queries = userProducts
    .filter((product: string) => product in productsWithQuota)
    .map((product: string) => ({
      queryKey: rqKeys.subscriptionQuotaQueryKey(accountId, product),
      queryFn: () => checkSubscriptionQuota(accountId, product),
      enabled: !!accountId,
      staleTime: 10000 //10 secs refetch
    }))
  const subscriptionQuotaQueryResults = useQueries({ queries })

  return {
    subscriptionQuotaQueryResults
  }
}
