import React from 'react'
import Panel from 'rsuite/lib/Panel'
import { InspireMeButton } from './InspireMeButton'
import useUser from '@/hooks/useUser'
import { productNamesForUI } from '@/staticData/products'
import GeneratingData from '../../GeneratingData'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import Tooltip from '@/components/TailwindTooltip'
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'

const CreateButton = (props: { disabled: boolean; handleClick: () => void }) => {
  return (
    <div
      data-testid="createNewbutton"
      onClick={() => {
        !props.disabled && props.handleClick()
      }}
      className={`w-8 h-8 pb-1 text-center bg-sightlyBlue text-white rounded-full 
    ${props.disabled ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'}`}
    >
      <span className="text-2xl">+</span>
    </div>
  )
}

const ButtonPanel = (props: {
  disabled: boolean
  isAIGenerating: boolean
  handleCreateBtnClick: () => void
  handleAIGenerate: () => void
}) => {
  const { currentAccount, subscriptionQuota, perms, products, userPermissions, userProducts } = useUser()

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.AI_CREDITS)) {
      const quota = subscriptionQuota.get(products.AI_CREDITS)
      quotaIsAllowed = quota.isAllowed
  }

  const { productQuotaTally: AICreditsTally } = useProductUsage({
    accountId: currentAccount?.accountId,
    productName: products.AI_CREDITS
  })
  return (
    <div className="flex justify-end gap-3">
      {props.isAIGenerating ? (
        <GeneratingData message="Generating..." />
      ) : (
        <Tooltip
          disabled={userCanPermissionProductQuota({
            requiredPermissionValue: perms.AI_CREDITS,
            requiredProductValue: products.AI_CREDITS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
            quota_is_allowed: quotaIsAllowed
          })}
          content={
            <ProductLimitedAccessInfo
              inSufficientPermission={!userCanPermissionProductQuota({
                requiredPermissionValue: perms.AI_CREDITS,
                requiredProductValue: products.AI_CREDITS,
                userPermissions: userPermissions,
                userProducts: userProducts,
                checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
              })}
              productNames={[AICreditsTally && AICreditsTally.isMaxedOut ? productNamesForUI.AI_CREDITS : undefined]}
            />
          }
        >
          <div>
            <InspireMeButton
              dataTestId="newBrandProfile-ai-generate-button"
              label="Inspire Me"
              disabled={props.disabled || !userCanPermissionProductQuota({
                requiredPermissionValue: perms.AI_CREDITS,
                requiredProductValue: products.AI_CREDITS,
                userPermissions: userPermissions,
                userProducts: userProducts,
                checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
                quota_is_allowed: quotaIsAllowed
              })} //Checks for AI Credits access
              handleAIGenerate={props.handleAIGenerate}
              pendoId='pendo-inspireme-queries'
            />
          </div>
        </Tooltip>
      )}
      <CreateButton
        disabled={props.disabled || props.isAIGenerating}
        handleClick={props.handleCreateBtnClick}
      />
    </div>
  )
}

function AccordionSection(props: {
  isActive: boolean
  sectionCaption: string
  description: string
  canCreate: boolean
  handleAIGenerate: () => void
  children: React.ReactNode
  handleAddClick: () => void
  handleAccordionClick?: () => void
  isAIGenerating: boolean
}) {
  const { subscriptionQuota, perms, products, userPermissions, userProducts } = useUser()

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.QUERIES)) {
      const quota = subscriptionQuota.get(products.QUERIES)
      quotaIsAllowed = quota.isAllowed
  }

  return (
    <Panel
      collapsible
      defaultExpanded={props.isActive}
      header={<div className="text-xl font-bold">{props.sectionCaption}</div>}
      onSelect={props.handleAccordionClick}
      style={{ backgroundColor: 'white' }}
    >
      <div className="flex flex-col gap-2">
        <div className="text-sm text-gray-600">{props.description}</div>
        <ButtonPanel
          disabled={
            !props.canCreate || !userCanPermissionProductQuota({
              requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
              requiredProductValue: products.QUERIES,
              userPermissions: userPermissions,
              userProducts: userProducts,
              checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
              quota_is_allowed: quotaIsAllowed
            })
          }
          handleCreateBtnClick={props.handleAddClick}
          handleAIGenerate={props.handleAIGenerate}
          isAIGenerating={props.isAIGenerating}
        />
        {props.children}
      </div>
    </Panel>
  )
}

export default AccordionSection
