import React from 'react'
import { MomentKeywordType } from '@/views/Discover/Moments/v2/types'

const MomentKeyword = ({ keyword, index }: { keyword: MomentKeywordType; index: number }) => {
  return (
    <div
      className={`flex h-11 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-200'} 
      font-normal text-sm pt-2 text-black pl-7 text-left`}
    >
      <div
        data-testid="moment-modal-keywords-rank"
        className="w-16"
      >
        {keyword.rank || 'N/A'}
      </div>

      <div data-testid="moment-modal-keywords-text">{keyword.keyword}</div>
    </div>
  )
}

export default MomentKeyword
