import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { ValueType } from 'rsuite/lib/DateRangePicker'
import { Button, Modal } from 'rsuite'
import Form from 'rsuite/lib/Form'
import FormGroup from 'rsuite/lib/FormGroup'

import SightlyButton from '@/components/Sightly/SightlyButton'
import { Tab } from '@/components/Navbars/Vertical/Tab'

import AdFormatSection from "@/views/ReportGenerator/components/formSections/AdFormatSection";
import ConversionMetricsSection from "@/views/ReportGenerator/components/formSections/ConversionMetricsSection";
import DateRangeSection from "@/views/ReportGenerator/components/formSections/DateRangeSection";
import DownloadingModal from "@/views/ReportGenerator/components/DownloadingModal";
import GranularitySection from "@/views/ReportGenerator/components/formSections/GranularitySection";
import InsertionOrderSection from "@/views/ReportGenerator/components/formSections/InsertionOrderSection";
import PerformanceMetricsSection from "@/views/ReportGenerator/components/formSections/PerformanceMetricsSection";
import ReportNameSection from "@/views/ReportGenerator/components/formSections/ReportNameSection";
import ReportTypesSection from "@/views/ReportGenerator/components/formSections/ReportTypesSection";

import { MyLocationGenerics } from '@/classes/utils'
import { routes } from '@/routes'

import {
    conversionMetrics,
    performanceMetrics,
    reports,
    ranges,
    granularity,
    ReportConfiguration, getLastWeek
} from '@/views/ReportGenerator/reportGenerator-types';
import { getInsertionOrdersByAccount, reportGeneratorApi } from '@/views/ReportGenerator/reportGenerator-api'

export interface multiSelectValues {
    label: string | null
    value: number | null
}

interface IInsertionOrder {
    insertionOrderName: string
    insertionOrderId: number
}

const ReportGeneratorForm: React.FC = () => {
    const navigate = useNavigate<MyLocationGenerics>()
    const { accountId } = useSearch<MyLocationGenerics>()
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false)
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [isDownloading, setIsDownloading] = useState(false)
    const [insertionOrders, setInsertionOrders] = useState<multiSelectValues[]>([])
    const [formValues, setFormValues] = useState<ReportConfiguration>({
        reportName: '',
        insertionOrderIds: [],
        reports: [],
        splitAdFormat: false,
        performanceMetrics: ['impressions', 'views', 'viewRate', 'clicks', 'cpm', 'cpv', 'ctr', 'cost', 'q25', 'q50', 'q75', 'q100'],
        conversionMetrics: [],
        granularity: 'summary',
        startDate: getLastWeek()[0],
        endDate: getLastWeek()[1],
    })

    const {
        data: accountInsertionOrders
    } = useQuery(['getInsertionOrdersByAccount', accountId], getInsertionOrdersByAccount)

    React.useEffect(() => {
        const loadInsertionOrders = async () => {
            try {
                const reformattedInsertionOrders = accountInsertionOrders?.map((insertionOrder: IInsertionOrder) => ({
                    label: insertionOrder.insertionOrderName,
                    value: insertionOrder.insertionOrderId
                }))
                if (reformattedInsertionOrders) {
                    setInsertionOrders(reformattedInsertionOrders)
                }
            } catch (error) {
                console.error(`Error trying to load Insertion Orders by account for account: ${accountId}`, error)
            }
        }
        loadInsertionOrders()
    }, [accountInsertionOrders])

    // ********************* Form submission functions *********************
    const handleOnChange = (field: string, value: any) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }))
    }

    const handleDateRangeChange = (dates: ValueType) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            startDate: dates[0] || null,
            endDate: dates[1] || null
        }))
    }

    const handleCheckboxChange = (value: string, checked: boolean) => {
        if (checked) {
            // If the checkbox is checked, add the value to the reports array
            // @ts-ignore
            setFormValues((prevValues) => ({
                ...prevValues,
                reports: [...prevValues.reports, value]

            }))
        } else {
            // If the checkbox is unchecked, remove the value from the reports array
            setFormValues((prevValues) => ({
                ...prevValues,
                reports: prevValues.reports.filter((item) => item !== value)
            }))
        }
    }
    const handleCloseErrorModal = () => {
        setIsErrorModalVisible(false)
    }

    const showErrorModal = (messages: string[]) => {
        setErrorMessages(messages)
        setIsErrorModalVisible(true)
    }

    const handleSubmit = async () => {
        let errorMessages: string[] = []

        Object.keys(formValues).forEach((key) => {
            if (formValues[key as keyof ReportConfiguration] === '') {
                errorMessages.push(`${key} cannot be an empty.`)
            }
        })


        const hasMetricsOrConversions =
            formValues.performanceMetrics.length > 0 || formValues.conversionMetrics.length > 0


        if (!hasMetricsOrConversions) {
            errorMessages.push('At least one value must be selected for conversion or non conversion metrics.')
        }

        if (formValues.reports.length === 0) {
            errorMessages.push('At least one report type must be selected.')
        }

        if (formValues.insertionOrderIds.length === 0) {
            errorMessages.push('At least one insertion Order must be selected.')
        }

        if (formValues.startDate === null || formValues.endDate === null) {
            errorMessages.push('A date range must be selected.')
        }

        if (errorMessages.length > 0) {
            showErrorModal(errorMessages)
        }
        else {
            setIsDownloading(true)
            try {
                await Promise.all([
                    reportGeneratorApi.saveReportConfiguration(accountId, formValues),
                    reportGeneratorApi.downloadReport(formValues)
                ])
                navigate({
                    to: routes.reporting.path,
                    search: (search) => ({ accountId: search?.accountId })
                })
            } catch (error) {
                console.error('Error downloading the report:', error)
                showErrorModal(['An error occurred while downloading the report.'])
            } finally {
                setIsDownloading(false)
            }
        }
    }

    return (
        <div data-testid='div-report-generator' className="gap-12 space-y-4">

            <div className="p-6 pb-0 sightlyPanelHeader flex">
                <Tab
                    isActive={false}
                    datatestid='div-report-generator-back'
                    handleClick={() => {
                        navigate({
                            to: routes.reporting.path,
                            search: (search) => ({ accountId: search?.accountId })
                        })
                    }}
                    tabImage={
                        <div className="flex flex-row">
                            <ChevronLeftIcon
                                className={'w-7 h-7 text-gray-500 hover:text-gray-800 transition-colors'}
                            />
                            Back to Reports
                        </div>
                    }
                />
            </div>

            <div className="rounded-lg bg-white shadow-md p-8 m-4">
                <div className="flex text-gray-700 font-medium justify-center">
                    {' '}
                    <h5 className="pb-4">Report Builder</h5>
                </div>

                <Form fluid>

                    <FormGroup className="border-t-2 pt-4">
                        <ReportNameSection handleOnChange={handleOnChange} />
                    </FormGroup>

                    <FormGroup className="flex-col-grow border-t-2 pt-4">
                        <div className="flex-col space-y-4 w-5/12">
                            <InsertionOrderSection insertionOrders={insertionOrders} handleOnChange={handleOnChange} />
                            <ReportTypesSection reports={reports} selectedReports={formValues.reports} handleCheckboxChange={handleCheckboxChange} />
                            <AdFormatSection splitAdFormat={formValues.splitAdFormat} handleOnChange={handleOnChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="flex-col-grow border-t-2 pt-4">
                        <div className="space-y-4 w-5/12" >
                            <ConversionMetricsSection conversionMetrics={conversionMetrics} handleOnChange={handleOnChange} />
                            <PerformanceMetricsSection formValues={formValues} performanceMetrics={performanceMetrics} handleOnChange={handleOnChange} />
                        </div>
                    </FormGroup>

                    <FormGroup className="flex-col-grow border-t-2 pt-4">
                        <div className="space-y-4 w-3/12">
                            <DateRangeSection formValues={formValues} ranges={ranges} handleDateRangeChange={handleDateRangeChange} />
                            <GranularitySection formValues={formValues} granularity={granularity} handleOnChange={handleOnChange} />
                        </div>
                    </FormGroup>

                    <div className="flex justify-start space-x-4 border-t-2 pt-4 pb-2">
                        <SightlyButton
                            datatestid='button-report-generator-create'
                            id="Create and Run"
                            text="Create and Run"
                            handleClick={() => handleSubmit()}
                        />
                        <SightlyButton
                            datatestid='button-report-generator-cancel'
                            type="secondaryblack"
                            id="cancel"
                            text="Cancel"
                            handleClick={() => {
                                navigate({
                                    to: routes.reporting.path,
                                    search: (search) => ({ accountId: search?.accountId })
                                })
                            }}
                        />
                    </div>

                    <DownloadingModal isDownloading={isDownloading} />

                    <Modal
                        data-testid='div-report-generator-error-modal'
                        show={isErrorModalVisible}
                        onHide={handleCloseErrorModal}
                    >
                        <Modal.Header>
                            <Modal.Title>Error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {errorMessages.map((message, index) => (
                                <p key={index}>{message}</p>
                            ))}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                onClick={handleCloseErrorModal}
                                appearance="primary"
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </Form>
            </div>
        </div>
    )
}

export default ReportGeneratorForm
