import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useMatch, useNavigate } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query';
import { ArrowLongLeftIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

import svgLogo from '@/assets/img/sightly-logo.svg'
import defaultLoginImage from '@/assets/img/introSideImage.png'
import tiktokLoginImage from '@/assets/img/tiktoklogin.png'
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput'
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm'
import { routes } from '@/routes';
import { api } from '@/api/api';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { ChangePasswordFormSchema, ChangePasswordFormType } from './types';
import {isIframe} from '@/validations';

const Form = styled.form`
    div, button {
        width: 100%;
    }

    padding-top: 1em;
    gap: 1em;
`

const NameFieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
`

const SightlyLogo = styled.img`
    height: 2em;
`

const SignUpHeader = styled.h1`
    padding-top: 1em;
    text-align: center;
`

const LoginDiv = styled.div`
    text-align: center;
`

const StyledLink = styled(Link)`
    padding-left: .5em;
`

const TextDiv = styled.div`
    padding-top: .25em;
    text-align: center;
`

const StyledArrowLongLeftIcon = styled(ArrowLongLeftIcon)`
    height: 1.5em;
    padding-right: .5em;
`

const StyledSightlyModernButton = styled(SightlyModernButton)`
    margin-top: 2em;
    width: 100%;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
    height: 3em;
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
    text-align: center;
`
const IconDiv = styled.div`
    display: flex;
    justify-content: center;
`

export const ChangePassword: React.FC = () => {
    const {
        params: { userId, token }
    } = useMatch()
    const navigate = useNavigate()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const [showSuccessfulDetais, setShowSuccessfulDetails] = React.useState(false)
    const openLoginPage = () => navigate({ to: routes.login.path })
    const isInTikTokIframe = isIframe();

    const { sightlyRegister, handleSubmit, formState: { isValid }, trigger, watch, getFieldState } = useSightlyForm<ChangePasswordFormType>(ChangePasswordFormSchema)
    // This is needed due to controller RHF fields not having the deps property: https://github.com/react-hook-form/react-hook-form/issues/9685#issuecomment-1369813795
    useEffect(() => {
        if (getFieldState('confirmPassword').isTouched) {
            trigger(['confirmPassword'])
        }
    }, [trigger, watch('password')]);

    const changePasswordOverride = (changePasswordDetails: ChangePasswordFormType) => api.user.changePassword(userId, token, changePasswordDetails)
    const mutation = useMutation(changePasswordOverride, {
        onSuccess: (data) => {
            if (data.isError) {
                setShowGenericErrorDialog(true)
                return
            }

            setShowSuccessfulDetails(true)
        },
        onError: (_) => {
            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    const successDetails = <>
        <IconDiv><StyledCheckCircleIcon /></IconDiv>
        <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Password reset</SignUpHeader>
        <TextDiv>
            <p className='text-sm leading-5 font-normal text-gray-600 '>
                Your password has been successfully reset.
            </p>
        </TextDiv>
        <TextDiv>
            <p className='text-sm leading-5 font-normal text-gray-600 '>
                Click below to login.
            </p>
        </TextDiv>

        <StyledSightlyModernButton
            id='continueButton'
            type='inverted'
            preventDefaultOnClick
            handleClick={openLoginPage}
            text='Continue'
        />

        <StyledSightlyModernButton
            id='loginButton'
            type='secondaryblack'
            preventDefaultOnClick
            icon={<StyledArrowLongLeftIcon />}
            handleClick={openLoginPage}
            text='Back to Login'
        />

        <WarningModalTailwind
            open={showGenericErrorDialog}
            setOpen={setShowGenericErrorDialog}
            title='Something went wrong'
            subtitle='Please try again, or contact support at support@sightly.com'
        />
    </>

    const formDetails = <>
        <SightlyLogo src={svgLogo} alt='logo' />
        <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Set new password</SignUpHeader>
        <TextDiv>
            <p className='text-sm leading-5 font-normal text-gray-600 '>
                Your new password must be different to previously used passwords
            </p>
        </TextDiv>
        <Form
            onSubmit={onSubmit}
            className='flex flex-col justify-center items-center'
        >
            <SightlyModernFormInput {...sightlyRegister('password', 'Password')} showAllErrors type='password' />
            <SightlyModernFormInput {...sightlyRegister('confirmPassword', 'Confirm password')} type='password' />

            <SightlyModernButton
                id='submitButton'
                type='inverted'
                handleClick={() => undefined}
                text='Reset Password'
                disabled={mutation.isLoading || !isValid}
            />

            <SightlyModernButton
                id='loginButton'
                type='secondaryblack'
                preventDefaultOnClick
                icon={<StyledArrowLongLeftIcon />}
                handleClick={openLoginPage}
                text='Back to Login'
            />

            <WarningModalTailwind
                open={showGenericErrorDialog}
                setOpen={setShowGenericErrorDialog}
                title='Something went wrong'
                subtitle='Please try again, or contact support at support@sightly.com'
            />
        </Form>
    </>

const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            {
                showSuccessfulDetais
                    ? successDetails
                    : formDetails
            }
        </IntroLayout>
    );
};
