import React from 'react'
import { Progress } from 'rsuite'

const ProgressLine = ({ score }: { score: number }) => (
  <>
    <Progress.Line
      percent={Number((score * 100).toFixed(2))}
      className="w-full"
    />
    <style>
      {`
        .rs-progress-line-bg {
        background: linear-gradient(to right, #2BA476, #8F7EFF, #4F46E5);
        }
        .rs-progress-line-inner, .rs-progress-line-bg {
        height: 6px;
        }
        .rs-progress-line-inner{
        background: transparent;
        }
        .rs-progress, .rs-progress-icon-success, .rs-progress-icon-fail, .rs-progress-info-status{
        font-size: 14px;
        }
      `}
    </style>
  </>
)

export default ProgressLine
