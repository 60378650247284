import ChartTooltip from "@/views/Discover/Boards/components/conversations/ChartTooltip";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { ResponsiveContainer, AreaChart, XAxis, Area, Tooltip as RechartTooltip} from 'recharts'
import { SelectPicker } from "rsuite";
import { ITrendMetric, ITopicMetric } from "@/views/Trends/types";

interface IProps {
    selectedTimeRange: any
    sourceOptions: string[],
    timeRangeOptions: any,   
    handleTimeRangeChange: Function,
    handleSourceChange: Function,
    lineChartData: (ITrendMetric | ITopicMetric)[]
}

const TopicTrendChartByRange: React.FunctionComponent<IProps> = React.memo(
    ({ selectedTimeRange, sourceOptions, timeRangeOptions, handleTimeRangeChange, handleSourceChange, lineChartData }) => {
    return (
        <div className='flex flex-col h-52 pr-5 gap-4'>
              <div className='flex flex-row justify-between'>
                <SelectPicker
                  data={sourceOptions.map(option => ({ label: option, value: option }))}
                  placeholder="Source"
                  style={{ width: 160 }}
                  cleanable={false}
                  searchable={false}
                  onChange={(val) => handleSourceChange(val)}
                />
                <SelectPicker
                  defaultValue={selectedTimeRange}
                  data-testid="time-range-select"
                  preventOverflow
                  labelKey="label"
                  valueKey="id"
                  placeholder="Date Range"
                  data={timeRangeOptions}
                  onSelect={(val) => handleTimeRangeChange(val)}
                  width={600}
                  cleanable={false}
                  searchable={false}
                />
              </div>

              <ResponsiveContainer>
                <AreaChart
                  data={lineChartData}
                >
                  <XAxis
                    dataKey="createdAt"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: '10px'
                    }}
                  />
                  <Area
                    type="linear"
                    dataKey="totalCount"
                    stroke={'#651AEB'}
                    fill={'transparent'}
                  />
                  <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ zIndex: 1000 }} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
    )
})

export default TopicTrendChartByRange