import React from 'react'
import { ReactComponent as ThumbsDown } from '../assets/img/thumbs-down-solid.svg'
import { ReactComponent as ThumbsDownOutline } from '../assets/img/thumbs-down-regular.svg'

interface IProps {
    disagree: boolean | undefined
    text: string
    handleClick: Function
    disabled?: boolean
}

const FeedbackButton = ({ disagree, text, handleClick, disabled }: IProps) => {
    const [componentDisagree, setComponentDisagree] = React.useState<boolean | null>(null)

    React.useEffect(() => {
        if (!disagree) return
        setComponentDisagree(disagree)
    }, [disagree])

    const handleButtonClick = () => {
        if (disabled) return
        handleClick(componentDisagree ? null : 'disagree')
        setComponentDisagree((prev) => !prev)
    }

    return (
        <div
            style={{ display: 'flex', cursor: disabled ? 'not-allowed' : 'pointer' }}
            onClick={handleButtonClick}
        >
            <div
                style={{
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#333D47',
                    marginRight: 10
                }}
            >
                {text}
            </div>

            {componentDisagree ? (
                <ThumbsDown style={{ height: 16, marginTop: 3 }} />
            ) : (
                <ThumbsDownOutline style={{ height: 16, marginTop: 3 }} />
            )}
        </div>
    )
}

export default FeedbackButton
