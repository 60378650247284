import React from 'react'
import { ReactComponent as ThumbsUp } from '../assets/img/thumbs-up-solid.svg'
import { ReactComponent as ThumbsDown } from '../assets/img/thumbs-down-solid.svg'
import { ReactComponent as ThumbsUpOutline } from '../assets/img/thumbs-up-regular.svg'
import { ReactComponent as ThumbsDownOutline } from '../assets/img/thumbs-down-regular.svg'

interface IProps {
    buttonState: any
    handleClick: any
    text: string
    textColor: string | null
    handleDownClick: Function | undefined | null
    disabled?: boolean
}

const FeedbackButton = ({ buttonState, handleClick, text, textColor, handleDownClick, disabled }: IProps) => {
    const [componentState, setComponentState] = React.useState<string | null>(null)

    React.useEffect(() => {
        setComponentState(buttonState)
    }, [buttonState])

    const handleButtonClick = (type: string | null) => {
        if (disabled) return
        if (componentState === type) {
            type = null
        }

        if (type === 'dislike' && handleDownClick) {
            handleDownClick()
        }
        setComponentState(type)
        handleClick(type)
    }

    const buttonStyle = { height: 16, width: 16, cursor: disabled ? 'not-allowed' : 'pointer' }

    return (
        <div style={{ display: 'flex' }}>
            <div
                data-testid="reaction-caption"
                style={{
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: textColor ? textColor : '#333D47'
                }}
            >
                {text}
            </div>
            <div data-testid="reaction-like" style={{ marginLeft: 9.99, marginTop: 2 }}>
                {componentState === 'like' ? (
                    <ThumbsUp
                        style={buttonStyle}
                        onClick={() => handleButtonClick('like')}
                    />
                ) : (
                    <ThumbsUpOutline
                        style={buttonStyle}
                        onClick={() => handleButtonClick('like')}
                    />
                )}
            </div>

            <div  data-testid="reaction-dislike" style={{ marginLeft: 9.99, marginTop: 2 }}>
                {componentState === 'dislike' ? (
                    <ThumbsDown
                        style={buttonStyle}
                        onClick={() => handleButtonClick('dislike')}
                    />
                ) : (
                    <ThumbsDownOutline
                        style={buttonStyle}
                        onClick={() => handleButtonClick('dislike')}
                    />
                )}
            </div>
        </div>
    )
}

export default FeedbackButton
