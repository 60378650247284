import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from '@tanstack/react-location';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import svgLogo from '@/assets/img/sightly-logo.svg'
import defaultLoginImage from '@/assets/img/introSideImage.png'
import tiktokLoginImage from '@/assets/img/tiktoklogin.png'
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { routes } from '@/routes';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { ResetPasswordFormType, ResetPasswordSchema } from './types';
import { api } from '@/api/api';
import { isIframe } from '@/validations';

const Form = styled.form`
    div, button {
        width: 100%;
    }

    padding-top: 1em;
    gap: 1em;
`

const SightlyLogo = styled.img`
    height: 2em;
`

const SignUpHeader = styled.h1`
    padding-top: 1em;
    text-align: center;
`

const StyledArrowLongLeftIcon = styled(ArrowLongLeftIcon)`
    height: 1.5em;
    padding-right: .5em;
`

const TextDiv = styled.div`
    padding-top: .25em;
    text-align: center;
`

export const ResetPassword: React.FC = () => {
    const navigate = useNavigate()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const openLoginPage = () => navigate({ to: routes.login.path })
    const isInTikTokIframe = isIframe();

    const { sightlyRegister, handleSubmit, formState: { isValid } } = useSightlyForm<ResetPasswordFormType>(ResetPasswordSchema)

    const mutation = useMutation(api.user.resetPassword, {
        onSuccess: (data) => {
            if (data.isError){
                setShowGenericErrorDialog(true)
                return
            }

            toast.success('Reset password email sent. Check Your email.')
            navigate({ to: `/checkYourEmail/${data.result}`})
        },
        onError: (err: AxiosError<any>) => {
            if (err.response && 'Error' in err.response.data &&  err.response.data.Error === 'No user found with that email address.') {
                toast.error(err.response.data.Error)
                return
            }

            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            <SightlyLogo src={svgLogo} alt='logo' />
            <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Forgot password?</SignUpHeader>
            <TextDiv>
                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    No worries, we’ll send your reset instructions
                </p>
            </TextDiv>
            <Form
                onSubmit={onSubmit}
                className='flex flex-col justify-center items-center'
            >
                <SightlyModernFormInput {...sightlyRegister('email', 'Email address')} placeholder='you@example.com' />

                <SightlyModernButton
                    id='submitButton'
                    type='inverted'
                    handleClick={() => undefined}
                    text='Reset Password'
                    disabled={mutation.isLoading || !isValid}
                />

                <SightlyModernButton
                    id='loginButton'
                    type='secondaryblack'
                    preventDefaultOnClick
                    icon={<StyledArrowLongLeftIcon />}
                    handleClick={openLoginPage}
                    text='Back to Login'
                />

                <WarningModalTailwind
                    open={showGenericErrorDialog}
                    setOpen={setShowGenericErrorDialog}
                    title='Something went wrong'
                    subtitle='Please try again, or contact support at support@sightly.com'
                />
            </Form>
        </IntroLayout>
    );
};
