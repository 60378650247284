import React from 'react'
import SightlyAvatar from '@/components/SightlyAvatar'
import SlideOver from '@/components/SlideOver'
import { IMomentTimeline } from '../types'



interface IDetailTimelineProps {
  timelineData: IMomentTimeline
}

const DetailTimeline: React.FC<IDetailTimelineProps> = ({ timelineData }) => {
  const [showSlideOver, setShowSlideOver] = React.useState(false)

  const TimelineContent = ({ timelineData }: IDetailTimelineProps) => (
    <div className="timeline">
      {timelineData.map((item, index) => (
        <div
          key={index}
          className="timeline-item mb-4"
        >
          <div className="flex items-center">
            <div className="inline-block rounded-full ring-2 ring-white">
              <SightlyAvatar
                text={item.title}
                size="big"
                type={null}
              />
            </div>

            <div className="ml-4">
              <h3 className="font-inter text-[17.64px] font-semibold leading-[26.46px] text-left text-[rgba(87,87,87,1)]">
                {item.title}
              </h3>
              <div className="flex flex-row">
                <p className="text-sm text-gray-500">{item.date}</p>

                {item.type === 'New Moment' && (
                  <span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">New Moment</span>
                )}
                {item.type === 'Articles' && item.count && (
                  <span className="bg-green-100 text-green-600 text-xs px-2 py-1 rounded-full">
                    {item.count} Articles
                  </span>
                )}
                {item.type === 'Social Posts' && item.count && (
                  <span className="bg-teal-100 text-teal-600 text-xs px-2 py-1 rounded-full">
                    {item.count} Social Posts
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle="Trend Timeline"
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="60%"
      >
        <div className="pl-10">
          <TimelineContent timelineData={timelineData} />
        </div>
      </SlideOver>

      <h5 className="text-gray">Trend Timeline</h5>
      <div className="border-t border-black-300 mb-5 mt-3"></div>
      <TimelineContent timelineData={timelineData} />

      <div className="mt-4">
        <a
          href="#"
          className="text-blue-500 font-semibold"
          onClick={(e) => {
            e.preventDefault()
            setShowSlideOver(true)
          }}
        >
          View full Timeline
        </a>
      </div>
    </div>
  )
}

export default DetailTimeline
