import React from 'react';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout';
import { GettingStarted } from '@/views/OnboardingHub/GettingStarted';
import { AccountManagement } from '@/views/OnboardingHub/AccountManagement';
import { Resources } from '@/views/OnboardingHub/Resources';
import useUser from '@/hooks/useUser';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import { useProductUsage } from '@/hooks/subscription/useProductUsage';
import useUserCompletionSteps from '@/views/OnboardingHub/hooks/useUserCompletionSteps';
import { products } from '@/staticData/products';
import userCanPermissionProductQuota from '@/services/userCanPermissionProductQuota';

export const OnboardingHub = () => {
	const { currentAccount, userProducts, userPermissions } = useUser()

	const { productQuotaTally } = useProductUsage({
	  accountId: currentAccount?.accountId,
	  productName: products.USERS
	})

	const { data: submittedBrandProfiles, refetch: refetchProfiles} = useBrandProfiles({ submittedOnly: true});
  const { data: completedSteps, refetch, postUserCompletionStep } = useUserCompletionSteps();

	React.useEffect(() => {
		refetchProfiles();
	}, [currentAccount]);

	return (
		<PageWithHeaderLayout
			headerName="Onboarding Hub"
		>
			<div className="grid grid-cols-2 gap-10">
				<div className="col-span-1">
					<GettingStarted
						headerName="Getting Started"
						submittedBrandProfiles={submittedBrandProfiles}
						completedSteps={completedSteps}
						postUserCompletionStep={postUserCompletionStep}
						refetchUserCompletionSteps={refetch}
					/>
				</div>
				<div className="col-span-1">
					<AccountManagement
						headerName="Account Management"
						userCanPermissionProductQuota={userCanPermissionProductQuota}
						productQuotaTally={productQuotaTally}
						currentAccount={currentAccount}
						userProducts={userProducts}
						userPermissions={userPermissions}
					/>
					<Resources headerName="Resources"/>
				</div>
			</div>
		</PageWithHeaderLayout>
	)
}
