import React from 'react'

import { TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import { PersonaGroup } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/PersonaGroup'
import { CampaignAdGroupRow } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/CampaignAdGroupRow'
import { TableHeaderRow } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/TableHeaderRow'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/Discover/Activations/v2/ActivationsManager/reducers/activationManager.reducer'
import {
  ACTIVATIONS_MANAGER_BULK_DELETE,
  ACTIVATIONS_MANAGER_DELETE_ACTIVATIONS,
  ACTIVATIONS_MANAGER_DELETE_PERSONAS
} from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { useStoredTargetedActivations } from '@/views/Discover/Activations/v2/ActivationsManager/useStoredTargetedActivations'
import {
  SELECTED_ACTIVATIONS_ACTIONS,
  selectedActivationReducer
} from '@/views/Discover/Activations/v2/ActivationsManager/reducers/selectedActivations.reducer'
import BulkAssignCampaignAdGroups from '@/views/Discover/Activations/v2/ActivationsManager/BulkAssignCampaignAdGroups'
import { BulkOpsButtons } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/BulkOpsButtons'

type ActivationsTableProps = {
  activationsToIoIds: Map<number, number>
  personaFilters: string[]
  searchedMomentsIds: number[]
  setOpen: (val: boolean) => void
  open: boolean
}

const ActivationsTable = ({
  activationsToIoIds,
  personaFilters,
  searchedMomentsIds,
  setOpen,
  open
}: ActivationsTableProps) => {
  const targetedActivationList = useTargetedActivationList()
  const { setActivationListToStorage } = useStoredTargetedActivations()
  const defaultExpandedPersona =
    targetedActivationList.state.size > 0 ? new Set([Array.from(targetedActivationList.state.keys())[0]]) : null

  const [expandedPersona, setExpandedPersona] = React.useState<Set<string> | null>(defaultExpandedPersona)
  const [personaToBeDeleted, setPersonaToBeDeleted] = React.useState<null | string>(null)
  const [activationToBeDeleted, setActivationToBeDeleted] = React.useState<null | [string, number]>(null)
  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false)
  const [selectedActivations, dispatchSelectedActivations] = React.useReducer(
    selectedActivationReducer,
    new Map<string, Set<number>>()
  )
  const [showBulkAssignmentModal, setShowBulkAssignmentModal] = React.useState<boolean>(false)
  const [isBulkDelete, setIsBulkDelete] = React.useState<boolean>(false)
  let keyVersion = React.useRef(0)
  const [isBulkAssignment, setIsBulkAssignment] = React.useState<boolean>(false)

  //cleans up expanded Persona if the master data targetedActivationList doesn't have a persona.
  React.useEffect(() => {
    if (!expandedPersona) return
    expandedPersona.forEach((persona) => {
      if (!targetedActivationList.state.has(persona)) {
        setExpandedPersona((prev) => {
          const next = new Set(prev)
          next.delete(persona)
          return next
        })
      }
    })
  }, [targetedActivationList.state])

  const togglePersona = (persona: string) =>
    setExpandedPersona((prev) => {
      const next = new Set(prev)
      next?.has(persona) ? next.delete(persona) : next.add(persona)
      return next
    })

  const isPersonaGroupSelectAllIndeterminate = (persona: string, activations: Array<TargetedActivationListType>) =>
    (selectedActivations.get(persona)?.size ?? 0) > 0 &&
    (selectedActivations.get(persona)?.size ?? 0) < activations.length

  const ioIdsForSelectedActivations = React.useMemo(() => {
    const selectedActivationIds: Set<number> = new Set(
      Array.from(selectedActivations.values()).flatMap((ids) => Array.from(ids))
    )
    let insertionOrderIds: string[] = []
    selectedActivationIds.forEach((activationId) => {
      const insertionOrderId = activationsToIoIds.get(activationId) 
      insertionOrderId && insertionOrderIds.push(insertionOrderId.toString())
    })
    return Array.from(new Set(insertionOrderIds))
  }, [selectedActivations])

  const handleSelectActivation = (checked: boolean, persona: string, activation: TargetedActivationListType) => {
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ACTIVATION,
      payload: {
        activation,
        checked,
        persona
      }
    })
  }

  const hasSearchedActivations = (activations: TargetedActivationListType[]) =>
    !searchedMomentsIds.length ||
    activations.some(({ activationItemId }) => searchedMomentsIds.includes(activationItemId))

  const handleSelectAll = (checked: boolean) => {
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL,
      payload: {
        checked,
        personaFilters,
        searchedMomentsIds,
        targetedActivationList: targetedActivationList.state
      }
    })
  }

  const handleSelectAllPersonaGroup = (
    checked: boolean,
    persona: string,
    activations: Array<TargetedActivationListType>
  ) => {
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.SELECT_ALL_PERSONA_GROUP,
      payload: {
        activations,
        checked,
        persona,
        searchedMomentsIds
      }
    })
  }

  const handleRemovePersona = () => {
    if (!personaToBeDeleted) return
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.REMOVE_PERSONA,
      payload: {
        personaToBeDeleted
      }
    })

    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.REMOVE_PERSONA,
      payload: {
        persona: personaToBeDeleted
      }
    })
    setPersonaToBeDeleted(() => null)
  }

  const handleCancelDeletePersona = () => {
    setPersonaToBeDeleted(() => null)
  }

  const handleRemoveActivation = () => {
    if (!activationToBeDeleted) return
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.REMOVE_ACTIVATION,
      payload: {
        activationToBeDeleted
      }
    })
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.REMOVE_ACTIVATION,
      payload: {
        activation: activationToBeDeleted
      }
    })
    setActivationToBeDeleted(() => null)
  }

  const handleCancelDeleteActivation = () => {
    setActivationToBeDeleted(() => null)
  }

  const bulkDeleteMoments = () => {
    if (!selectedActivations.size) return
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.BULK_DELETE_ACTIVATIONS,
      payload: {
        selectedActivations
      }
    })
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.RESET
    })
    setIsSelectAllChecked(false)
    setIsBulkDelete(() => false)
  }

  const handleBulkDeleteMoments = () => {
    setIsBulkDelete(() => true)
  }

  const handleCancelBulkDeletePersona = () => {
    setIsBulkDelete(() => false)
  }

  const resetSelectedActivations = () => {
    dispatchSelectedActivations({
      type: SELECTED_ACTIVATIONS_ACTIONS.RESET
    })
    setIsSelectAllChecked(false)
  }
  
  const selectedActivationCount = React.useMemo(() => {
    let count = 0
    selectedActivations.forEach((activation, persona) => {
      count += activation.size
    })
    return count
  }, [selectedActivations])

  // Persist in the session storage
  React.useEffect(() => {
    setActivationListToStorage(targetedActivationList.state)
    if (open && !targetedActivationList.state.size) setOpen(false)
  }, [targetedActivationList.state])

  React.useEffect(()=>{
    keyVersion.current = keyVersion.current + 1
    setIsBulkAssignment(false)
  },[isBulkAssignment])

  return (
    <>
      {selectedActivations.size > 0 && (
        <BulkOpsButtons
          {...{
            handleBulkDeleteMoments,
            selectedActivationCount,
            selectedActivations,
            setShowBulkAssignmentModal
          }}
        />
      )}

      <div className="relative flex-1 h-full overflow-y-auto ">
        <div className="h-full py-2 align-middle md:px-6">
          <div className="h-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table
              data-testid="activations-manager-activations-table"
              id="activations-manager-activations-table"
              className="w-full border-collapse table-fixed"
            >
              <TableHeaderRow
                {...{
                  expandedPersona,
                  handleSelectAll,
                  isSelectAllChecked,
                  selectedActivations,
                  setIsSelectAllChecked
                }}
              />

              <tbody className="overflow-y-auto bg-white">
                {targetedActivationList.state.size <= 0 && (
                  <tr>
                    <td
                      rowSpan={6}
                      colSpan={6}
                      className="text-center h-[50vh]"
                      data-testid="no-activations-found-text"
                      id="no-activations-found-text"
                    >
                      No Activations Found
                    </td>
                  </tr>
                )}
                {Array.from(targetedActivationList.state.entries()).map(([persona, activations]) => {
                  if (
                    (!personaFilters.length || personaFilters.includes(persona)) &&
                    hasSearchedActivations(activations)
                  ) {
                    return (
                      <tr key={`${persona}_v${keyVersion.current}_wrapper`}>
                        <td colSpan={6}>
                          <table
                            data-testid="act-mgr-activations-table-persona-group-table"
                            id="act-mgr-activations-table-persona-group-table"
                            className="w-full table-fixed"
                          >
                            <TableColGroup />
                            <tbody data-testid="persona-group" id="persona-group">
                              <PersonaGroup
                                {...{
                                  key: persona,
                                  activations,
                                  expandedPersona,
                                  handleSelectAllPersonaGroup,
                                  isPersonaGroupSelectAllIndeterminate,
                                  persona,
                                  selectedActivations,
                                  togglePersona,
                                  setPersonaToBeDeleted
                                }}
                              />

                              {expandedPersona?.has(persona) &&
                                activations.map((activation) => {
                                  if (
                                    !searchedMomentsIds.length ||
                                    searchedMomentsIds.includes(activation.activationItemId)
                                  ) {
                                    return (
                                      <React.Fragment key={`${persona}_${activation.activationItemId}_wrapper`}>
                                        <CampaignAdGroupRow
                                          {...{
                                            key: `${persona}_${activation.activationItemId}`,
                                            activation,
                                            handleSelectActivation,
                                            persona,
                                            insertionOrderId: activationsToIoIds.get(activation.activationItemId) ?? NaN,
                                            selectedActivations,
                                            setActivationToBeDeleted,
                                            keyVersion
                                          }}
                                        />
                                      </React.Fragment>
                                    )
                                  }
                                  return <></>
                                })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )
                  }
                  return <></>
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ConfirmationDialog
          show={activationToBeDeleted !== null}
          onHide={handleCancelDeleteActivation}
          handleConfirm={() => handleRemoveActivation()}
          handleCancel={handleCancelDeleteActivation}
          body={ACTIVATIONS_MANAGER_DELETE_ACTIVATIONS}
          action="Delete"
        />
        <ConfirmationDialog
          show={personaToBeDeleted !== null}
          onHide={handleCancelDeletePersona}
          handleConfirm={() => handleRemovePersona()}
          handleCancel={handleCancelDeletePersona}
          body={ACTIVATIONS_MANAGER_DELETE_PERSONAS}
          action="Delete"
        />
        <ConfirmationDialog
          show={isBulkDelete}
          onHide={handleCancelBulkDeletePersona}
          handleConfirm={() => bulkDeleteMoments()}
          handleCancel={handleCancelBulkDeletePersona}
          body={ACTIVATIONS_MANAGER_BULK_DELETE(selectedActivationCount)}
          action="Delete"
        />
        <BulkAssignCampaignAdGroups
          {...{
            ioIds:ioIdsForSelectedActivations,
            setShow:setShowBulkAssignmentModal,
            show:showBulkAssignmentModal,
            selectedActivations,
            resetSelectedActivations,
            setIsBulkAssignment,
          }}
        />
      </div>
    </>
  )
}

export const TableColGroup = () => (
  <colgroup>
    <col span={2} />
    <col className="w-[196px] px-2" />
    <col className="w-[196px] px-2" />
    <col className="w-[222px] px-2" />
    <col className="w-[32px]" />
  </colgroup>
)

export default ActivationsTable
