import React from 'react';
import SidePanel from '@/views/Discover/Boards/components/conversations/SidePanel';
import { SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID, SOCIAL_NARRATIVE_PANEL_HEADER_TITLE, SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS } from '@/views/Discover/Boards/NarrativesTab/constants';
import SocialOverviewTabContents from '@/views/Discover/Boards/NarrativesTab/SocialNarrativePanel/SocialOverviewTabContents';
import SocialPostsTabContents from '@/views/Discover/Boards/NarrativesTab/SocialNarrativePanel/SocialPostsTabContents';
import PlatformsOverview from '@/views/Discover/Boards/NarrativesTab/OverviewSidePanel/PlatformsOverview';
import ThemesOverview from '@/views/Discover/Boards/NarrativesTab/OverviewSidePanel/ThemesOverview';
import useSocialClusterById from '@/views/Discover/Boards/NarrativesTab/SocialNarrativePanel/useSocialClusterById';

interface SocialNarrativePanelProps {
  openSocialNarrativePanel: boolean
  setOpenSocialNarrativePanel: React.Dispatch<React.SetStateAction<boolean>>
  selectedSocialClusterId: string
}

const SocialNarrativePanel = ({
  openSocialNarrativePanel,
  setOpenSocialNarrativePanel,
  selectedSocialClusterId
}: SocialNarrativePanelProps) => {
  const [activeTabId, setActiveTabId] = React.useState<string>(SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID);

  const {
    socialClusterData,
  } = useSocialClusterById(selectedSocialClusterId);

  return (
    <SidePanel
      open={openSocialNarrativePanel}
      activeTabId={activeTabId}
      setActiveTabId={setActiveTabId}
      headerTitle={SOCIAL_NARRATIVE_PANEL_HEADER_TITLE}
      tabOptions={SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS}
      onHide={()=>setOpenSocialNarrativePanel(()=>false)}
      dataTestId='conversations-social-narrative-side-panel'
    >
      {activeTabId === SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[0].id && (<SocialOverviewTabContents overview={socialClusterData?.overview} />)}
      {activeTabId === SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[1].id && (<SocialPostsTabContents posts={socialClusterData?.posts}/>)}
      {activeTabId === SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[2].id && (<PlatformsOverview platformsOverview={socialClusterData?.platforms}/>)}
      {activeTabId === SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[3].id && (<ThemesOverview themesOverview={socialClusterData?.themes} />)}
    </SidePanel>
  )
}

export default SocialNarrativePanel;
