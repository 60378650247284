import React from 'react'
import { CheckPicker, Icon } from 'rsuite'

type IoProps = {
  options: {id:number, name:string}[]
  insertionOder: string[]
  setInsertionOder: React.Dispatch<React.SetStateAction<string[]>>
}

export const InsertionOrder: React.FC<IoProps> = (props:IoProps) => {
  const {options, insertionOder, setInsertionOder} = props
  return (
    <div data-testid='activations-io-filter' id='pendo-activations-io-filter'>
      <CheckPicker
        data={options}
        className='w-40 h-[38px]'
        renderMenu={(menu: React.ReactNode) => {
          if (!options) {
            return (
              <p className='p-1 text-[#999] text-center'>
                <Icon
                  icon="spinner"
                  spin
                />{' '}
                Loading...
              </p>
            )
          }
          if (options.length === 0) {
            return <p className='p-1 text-[#999] text-center'>No Results Found</p>
          }
          return menu
        }}
        searchable={!!options}
        labelKey="name"
        valueKey="id"
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Insertion Order"
        block
        value={insertionOder}
        onChange={(setInsertionOderList: string[]) => {
            setInsertionOder((prev: string[]) => setInsertionOderList)
        }}
        onClean={() => {
            setInsertionOder((prev: string[]) => [])
        }}
      />
      <style>
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn {
            height:100%;
          }
        `}
      </style>
    </div>
  )
}
