import React from 'react'

interface VerticalTabsProps {
  children: React.ReactElement[]
  active: string
  onChange: (key: string) => void
}

const verticalTabStyle = {
  normal: `hover:text-sightlySlate text-[#696969] text-sm font-medium cursor-pointer flex flex-col`,
  active: `text-sightlySlate text-sm font-bold cursor-pointer flex flex-col shadow-[-3px_0_0_#7748F9_inset]`
}

export default function VerticalTabs({ children, active, onChange }: VerticalTabsProps) {
  return (
    <div className="flex flex-col select-none h-14 pt-4">
      {children.map((child: React.ReactElement) => {
        if (!child) return <div />
        return (
          <div
            className="py-4 pl-4"
            key={child.key}
            onClick={() => {
              child.key && onChange(child.key.toString())
            }}
          >
            <div className={`${child.key === active ? verticalTabStyle.active : verticalTabStyle.normal}`}>{child}</div>
          </div>
        )
      })}
    </div>
  )
}
