import React from "react";
import {Loader, Modal} from "rsuite";

interface IProps {
    isDownloading: boolean
}

export default function DownloadingModal({ isDownloading }: IProps) {
    return (
        <Modal
            data-testid='div-report-generator-downloading-modal'
            show={isDownloading}
            onHide={() => {}}
            size="xs"
        >
            <Modal.Body className="flex justify-center ">
                <Loader
                    content="Downloading file, please wait..."
                    vertical
                    size="md"
                />
            </Modal.Body>
        </Modal>
    )
}
