import React from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton'

type IProps = {
  handleCreate: () => void
  isCreating: boolean
  disabled: boolean
  buttonLabel: string
}
const Footer = ({ handleCreate, isCreating, disabled, buttonLabel }: IProps) => {

  return (
    <div
      data-testid="board-footer"
      className="footer"
    >
      <SightlyButton
        datatestid="board-footer-button-cancel"
        type="secondary"
        id="cancel"
        text="Cancel"
        handleClick={() => window.history.back()}
      />

      <SightlyButton
        datatestid="board-footer-button-save"
        disabled={disabled}
        loading={isCreating}
        type="primary"
        id="create"
        text={buttonLabel}
        handleClick={handleCreate}
      />
      <style>{`
  
  .footer {
    grid-area: footer;
    background-color: #FFF;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    gap: 18px;
  }
  
`}</style>
    </div>
  )
}

export default Footer
