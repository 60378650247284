import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg'
import { MyLocationGenerics } from '@/classes/utils'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React from 'react'
import DetailDisplaySections from './components/DetailDisplaySections'
import DetailOverview from './components/DetailOverview'
import DetailTimeChart from './components/DetailTimeChart'
import DetailTimeline from './components/DetailTimeline'
import {
  useTopicById,
  useGetTopicBrands,
  useGetTopicPeople,
  useGetTopicSocialPosts,
  useGetTopicArticles,
  useGetTopicArticleMetrics,
  useGetTopicMomentTimeline
} from './hooks/useTopics'

export const TopicDetails = () => {
  const showRelevance = false;

  const navigate = useNavigate()
  const trendsEnabled = useFlag('enable_trends_feature')
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' })

  const { params } = useMatch<MyLocationGenerics>()
  const topicId = Number(params.topicId)

  const { topicQuery } = useTopicById(topicId)

  const { brandsQuery } = useGetTopicBrands(topicId)
  const topBrands = brandsQuery?.data || []

  const { peopleQuery } = useGetTopicPeople(topicId)
  const topPeople = peopleQuery?.data || []

  const { socialPostsQuery } = useGetTopicSocialPosts(topicId)
  let lineChartData = socialPostsQuery?.data || []

  const { momentTimelineQuery } = useGetTopicMomentTimeline(topicId)
  const momentTimeline = momentTimelineQuery?.data || []

  const { articlesQuery } = useGetTopicArticles(topicId)
  const articles = articlesQuery?.data || []

  const lineChartSourceOptions = ['Social Posts', 'Articles']
  const [lineChartSource, setLineChartSource] = React.useState(lineChartSourceOptions[0])
  const handleSourceChange = (source: string) => {
    if (source === lineChartSourceOptions[0]) {
      lineChartData = socialPostsQuery?.data || []
    } else if (source === lineChartSourceOptions[1]) {
      const { articleMetricsQuery } = useGetTopicArticleMetrics(topicId)
      lineChartData = articleMetricsQuery?.data || []
    }
    setLineChartSource(source)
  }

  return (
    <div className="flex flex-col p-6">
      <div
        className="flex flex-row justify-between items-center pb-4"
        data-testid="topic-details-header"
      >
        <div className="flex flex-row gap-2">
          <h4>{topicQuery?.data?.name}</h4>
        </div>
        <div className="flex flex-row gap-4 items-center">
          <div className="flex flex-row gap-2">
            <div className="h-10">
              <SightlyButton
                datatestid="topic-detail-download-btn"
                handleClick={() => {}}
                id="downloadActivation"
                text="Download"
                type="transparent"
                disabled={false}
                col={true}
                icon={
                  <ArrowDownTrayIcon
                    className="h-4 w-4 text-purple-600"
                    aria-hidden="true"
                  />
                }
              />
            </div>

            <div className="border-l border-gray-300 mx-6"></div>

            <div className="h-10 flex justify-center">
              <SightlyButton
                id="aiAnalyzeButton"
                handleClick={() => {}}
                text="Analyze"
                type="gradient-ai"
                icon={
                  <img
                    data-testid={'ai-analyze-image'}
                    src={sparklesAISvg}
                    className=" h-4 w-4 text-grey-900"
                  />
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-center w-10 h-10 rounded-full border-[1.1px] border-[rgba(212,217,217,1)] bg-gray-200">
            <button
              data-testid="moment-close-button"
              type="button"
              className="text-black rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => {}}
            >
              <span className="sr-only">Close panel</span>
              <XMarkIcon
                className="w-6 h-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-4 ">
        <div className="flex flex-col w-3/5 gap-4">
          <DetailOverview
            overviewText={topicQuery?.data?.overview || ''}
            overviewBullets={topicQuery?.data?.bulletsText || []}
            totalMoments={topicQuery?.data?.metrics?.moment.count || 0}
            totalNarratives={topicQuery?.data?.metrics?.trend.count || 0}
            totalArticles={topicQuery?.data?.metrics?.moment.articleCount || 0}
            totalSocialPosts={topicQuery?.data?.metrics?.narrative.postCount || 0}
            sentimentScore={85}
            sentimentType="Positive"
            topBrands={topBrands}
            topPeople={topPeople}
          />
        </div>

        <div className="border-l border-gray-300 mx-6"></div>

        <div className="flex flex-col w-2/5 gap-4 pb-5">
          <DetailTimeline timelineData={momentTimeline} />

          <div className="border-t border-black-300"></div>

          <DetailTimeChart
            lineChartData={lineChartData}
            sourceOptions={lineChartSourceOptions}
            handleSourceChange={handleSourceChange}
          />
        </div>
      </div>

      <div className="border-t border-black-300"></div>

      <DetailDisplaySections articles={articles} />
    </div>
  )
}
