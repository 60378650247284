import React from 'react'
import Button from 'rsuite/lib/Button'
import Radio from 'rsuite/lib/Radio'
import RadioGroup from 'rsuite/lib/RadioGroup'
import PortalModal from '../../components/PortalModal/PortalModal'

function DownloadOptionsModal(props) {
	const [componentScenario, setComponentScenario] = React.useState('question')
	const [componentOpinion, setComponentOpinion] = React.useState('question')
	return (
		<PortalModal
		hasClose
			open={props.showModal}
			handleClose={props.handleCloseClick}
			footerButtons={[
				<Button
					loading={props.isDownloadingBrandProfile}
					onClick={props.handleDownloadClick}>
					Download
				</Button>
			]}>
			<div>
				<h4>Brand Profile download options</h4>
				<div style={{padding: 24}}>
				<RadioGroup
					style={{ marginBottom: 30 }}
					value={componentScenario}
					onChange={(value) => {
						setComponentScenario(value)
						setTimeout(() => {
							props.setScenarioDownloadSort(value)
						}, 200)
					}}>
					<p>How would you like the scenario section organized?</p>
					<Radio value='question'>By Question Created Date</Radio>
					<Radio value='response'>By response</Radio>
				</RadioGroup>

				<RadioGroup
					value={componentOpinion}
					onChange={(value) => {
						setComponentOpinion(value)
						setTimeout(() => {
							props.setOpinionDownloadSort(value)
						}, 200)
					}}>
					<p>How would you like the opinion section organized?</p>
					<Radio value='question'>By Question Created Date</Radio>
					<Radio value='response'>By response</Radio>
				</RadioGroup>
				</div>
			</div>
		</PortalModal>
	)
}

export default DownloadOptionsModal
