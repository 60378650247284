import React from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton'
import Tooltip from '@/components/TailwindTooltip'

export const Footer = (props: {
  setOpen: (val: boolean) => void
  disabledCreateButton: boolean
  disabledCreateButtonReasons: Set<string> | undefined
  handleConfirmClick: () => void
}) => (
  <div
    data-testid="activations-manager-footer"
    className="flex justify-end flex-shrink-0 gap-3 px-4 py-4 bg-gray-100 shadow-md"
  >
    <SightlyButton
      datatestid="activations-manager-cancel-btn"
      type="secondary"
      text="Cancel"
      id="activations-manager-cancel-btn"
      handleClick={() => props.setOpen(false)}
    />
    <Tooltip
      disabled={!props.disabledCreateButton}
      content={
        <div
          data-testid="activations-manager-footer-disabled-btn-tooltip"
          className="text-left"
        >
          <>
            {Array.from(props.disabledCreateButtonReasons?.values() || []).map((text) => (
              <p
                className="p-1"
                key={text}
              >
                {text}
              </p>
            ))}
          </>
        </div>
      }
    >
      <div>
        <SightlyButton
          disabled={props.disabledCreateButton}
          datatestid="activations-manager-submit-btn"
          text="Create"
          id="activations-manager-submit-btn"
          handleClick={props.handleConfirmClick}
        />
      </div>
    </Tooltip>
  </div>
)
