import React from 'react';
import { Tabs } from 'flowbite-react';
import SankeyGraphComponent from '@/views/Trends/components/SankeyGraphComponent';
import ArticleResultsList from '@/views/Trends/TrendDetails/components/ArticleResultsList';
import SocialPostResultsList from '@/views/Trends/TrendDetails/components/SocialPostResultsList';
import {ArticleResultsResponse, IArticle, NarrativeResultsResponse } from '@/views/Trends/types';
import { INarrative } from '@/views/Discover/Boards/types';
const DetailDisplaySections = ({ articles }: { articles: IArticle[] }) => {

  const sampleArticleResponse: ArticleResultsResponse = {
    articles: articles,
    meta: {
      total: articles.length,
      page: 1
    }
  }

  const sampleNarratives: INarrative[] = [
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '1',
      name: 'Foo',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '2',
      name: 'Baz',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['YouTube'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['YouTube'],
        themeList: []
      }
    },
    {
      id: '3',
      name: 'Fizz',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['Facebook'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['Facebook'],
        themeList: []
      }
    },   {
      id: '3',
      name: 'Fizz',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['Facebook'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['Facebook'],
        themeList: []
      }
    },   {
      id: '3',
      name: 'Fizz',
      topicId: 1,
      topicName: 'Bar',
      summary: 'Lorem ipsum sit dolor',
      postCount: 3,
      socialPostIdList: ['1', '2', '3'],
      sourceTypeList: ['Facebook'],
      themeList: ['Business', 'Aviation'],
      minPublishDate: '2024-10-08T20:50:42.711Z',
      maxPublishDate: '2024-10-09T16:57:27.871Z',
      createdDate: '2024-10-08T20:50:42.711Z',
      dateRange: '2024-10-08T20:50:42.711Z',
      sourceTypeCountJson: [],
      postCountPerDayJson: [],
      topPostJSON: {
        id: '1',
        post: 'Foo',
        publishDate: '2024-10-08T20:50:42.711Z',
        sourceTypeList: ['Facebook'],
        themeList: []
      }
    }
  ];

  const sampleNarrativeResponse: NarrativeResultsResponse = {
    narratives: sampleNarratives,
    meta: {
      total: sampleNarratives.length,
      page: 1
    }
  }

  const data = {
    nodes: [
        { name: "Online Gaming Communities", type: 'Topic' },
        { name: "Rise of Collaborative Gaming and Community Engagement", type: 'Trend'},
        { name: "", type: 'Trend' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
        { name: "", type: 'Moment' },
    ],
    links: [
        { source: 0, target: 1, value: 120 },
        { source: 0, target: 2, value: 160 },
        { source: 1, target: 3, value: 50 },
        { source: 1, target: 4, value: 70 },
        { source: 2, target: 5, value: 50 },
        { source: 2, target: 6, value: 15 },
        { source: 2, target: 7, value: 15 },
        { source: 2, target: 8, value: 60 },
        { source: 2, target: 9, value: 20 },
    ]
};

  return (
    <div className='w-full h-full'>
      <Tabs aria-label="Default tabs"
        style="underline"
        theme={{
          tablist: {
            tabitem: {
              base: 'flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0  focus:outline-none rounded-t-lg border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 border-bottom-color:sightlyPurple',
            },
          },
        }}
        >
        <Tabs.Item active title={"Trend Breakdown"} data-testid="detail-page-trend-breakdown-tab">
          <div>
            <SankeyGraphComponent data={data}/>
          </div>
        </Tabs.Item>
        <Tabs.Item active title={"Articles"} data-testid="detail-page-articles-tab">
          <div>
            <ArticleResultsList articles={sampleArticleResponse.articles} meta={sampleArticleResponse.meta}/>
          </div>
        </Tabs.Item>
        <Tabs.Item active title={"Social Posts"} data-testid="detail-page-social-posts-tab">
          <div>
            <SocialPostResultsList narratives={sampleNarrativeResponse.narratives} meta={sampleNarrativeResponse.meta}/>
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  )
}

export default DetailDisplaySections;
