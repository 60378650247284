import { ZodType, z } from 'zod';

export interface SignupFormType {
    firstName: string
    lastName: string
    companyName: string
    email: string
    password: string
    confirmPassword: string
}

export const SignupFormSchema: ZodType<SignupFormType> = z.object({
    firstName: z.string().min(1, { message: 'Please enter first name' }).max(100, { message: 'Please use a shorter name' }),
    lastName: z.string().min(1, { message: 'Please enter last name' }).max(100, { message: 'Please use a shorter name' }),
    companyName: z.string().min(1, { message: 'Please enter company name' }).max(100, { message: 'Please use a shorter name' }),
    email: z.string().email({ message: 'Enter valid email' }),
    password: z.string()
        .min(8, { message: 'At least 8 characters' })
        .max(64, { message: 'At most 64 characters' })
        .regex(/.*\d.*/, 'At least 1 number')
        .regex(/.*[A-Z].*/, 'At least 1 uppercase character')
        .regex(/.*[a-z].*/, 'At least 1 lowercase character')
        .regex(/.*[!"#$%&'()*+-.//:;<=>?@\[\]^_`{}|~\\].*/, { message: 'At least 1 symbol' }),
    confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, { message: 'Your password does not match', path: ['confirmPassword'] })
