export const CAPTIONS = {
  queries: 'People, Organizations, or Events',
  category: 'News Categories',
  industry: 'Industries'
}
export const TOP_TRENDING_MOMENTS = 'Top Trending Moment'
export const INSIGHTS_ERROR = 'An error occurred! Please try again later.'
export const NO_INSIGHTS = 'No Insights Available.'
export const CLOSE_BUTTON_SVG_FILTER =
  'invert(93%) sepia(8%) saturate(77%) hue-rotate(182deg) brightness(97%) contrast(96%)' //value of tailwind gray-200 in svg filter terms
export const HEADER_DESCRIPTION =
  'Based on the tags you chose for this board, we are showing how relevant each  is within the articles of this moment.'
export const TOP_MOMENTS_HEADER_DESCRIPTION = 'This moment is currently trending and has been talked about in numerous articles.'
