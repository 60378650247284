import { userAccountAxios } from '@/axiosInstances';


type ReturnType = boolean

export const urlExists = async (urlToCheck: string): Promise<ReturnType> => {
    const url = '/brand-profile/url-exists'
    const response = await userAccountAxios
        .post(url, {
            urlToCheck
        })
        .catch(() => {
            return {
                data: false
            }
        })

    return response.data === true
}
