import React from 'react'

interface IProps {
    text: string
    id: string
    required?: boolean
    additionalClass?: string
}

export const FormLabel = ({ text, required, id, additionalClass }: IProps) => {
    return (
        <div className="flex items-center">
            <label
                data-testid="label-formlabel"
                htmlFor={id}
                className={`${required && 'required'} ${additionalClass ?? ''} block text-sm font-medium text-gray-700 mb-1`}
            >
                {text}
            </label>
        </div>
    )
}
