import { ZodType, z } from 'zod';

export interface InviteFormType {
    firstName: string
    lastName: string
    company: string
    email: string
    roleId: number
    accounts: number[]
}

export const InviteFormSchema: ZodType<InviteFormType> = z.object({
    firstName: z.string().min(1, { message: 'Please enter first name' }).max(100, { message: 'Please use a shorter name' }),
    lastName: z.string().min(1, { message: 'Please enter last name' }).max(100, { message: 'Please use a shorter name' }),
    company: z.string().min(1, { message: 'Please enter company name' }).max(100, { message: 'Please use a shorter name' }),
    email: z.string().email({ message: 'Enter valid email' }),
    roleId: z.number().min(1),
    accounts: z.array(z.number()).min(1)
})
