import React from 'react'
import _ from 'lodash'

import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import BulkAssignModal from '@/views/Discover/Moments/v2/TargetMoment/BulkAssignModal'
import CheckTreeDataPicker from '@/components/CheckTreeDataPicker'
import { BULK_ASSIGN_TITLE } from '@/views/TikTok/ActivationManager/constants'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { CampaignOptionsType } from '@/views/TikTok/ActivationManager/types'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/TikTok/ActivationManager/reducers/activationManager.reducer'

type BulkAssignCampaignAdGroupsProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  selectedActivations: Set<string>
  setSelectedActivations: React.Dispatch<React.SetStateAction<Set<string>>>
  setIsBulkAssignment: React.Dispatch<React.SetStateAction<boolean>>
  campaignOptionsPrimary: CampaignOptionsType | undefined
  setIsSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const BulkAssignCampaignAdGroups = ({
  show,
  setShow,
  selectedActivations,
  setSelectedActivations,
  setIsBulkAssignment,
  campaignOptionsPrimary,
  setIsSelectAllChecked
}: BulkAssignCampaignAdGroupsProps) => {
  const [campaignSearchKeyword, setCampaignSearchKeyword] = React.useState<string>()
  const [adGroupSearchKeyword, setAdGroupSearchKeyword] = React.useState<string>()
  const keyRef = React.useRef(0)
  const targetedActivationList = useTargetedActivationList()
  const [selectedCampaignsToAdGroups, setSelectedCampaignsToAdGroups] = React.useState<
    SerializableMap<number, SerializableSet<number>>
  >(new SerializableMap())

  const campaignOptions = React.useMemo(() => {
    if (!campaignSearchKeyword) return campaignOptionsPrimary ?? []

    const nextOptions = _.cloneDeep(campaignOptionsPrimary)
    const campaignOptions = nextOptions
      ? nextOptions.filter(({ campaignName }) =>
          campaignName.toLowerCase().includes(campaignSearchKeyword.toLowerCase())
        )
      : []
    return campaignOptions
  }, [campaignSearchKeyword, campaignOptionsPrimary])

  const handleApply = () => {
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.BULK_ASSIGN_CAMPAIGN_ADGROUPS,
      payload: {
        selectedActivations,
        selectedCampaignsToAdGroups
      }
    })
    setIsBulkAssignment(() => true)
    reset()
  }

  const reset = () => {
    setShow(false)
    setCampaignSearchKeyword(() => undefined)
    setAdGroupSearchKeyword(() => undefined)
    setSelectedActivations(() => new Set<string>())
    setIsSelectAllChecked(() => false)
    setSelectedCampaignsToAdGroups(() => new SerializableMap<number, SerializableSet<number>>())
  }

  const handleCampaignSelectAll = (selectAll: boolean) => {
    setSelectedCampaignsToAdGroups((prev) => {
      const next = new SerializableMap(prev)
      campaignOptions.forEach(({ campaignId }) => {
        if (selectAll) {
          next.set(campaignId, new SerializableSet<number>())
        } else next.delete(campaignId)
      })
      return next
    })
  }

  const handleCampaignPickerChange = (activeNode: any) => {
    setSelectedCampaignsToAdGroups((prev) => {
      const next = new SerializableMap(prev)

      if (activeNode.check) next.set(activeNode.campaignId, new SerializableSet<number>())
      else next.delete(activeNode.campaignId)

      return next
    })
  }

  const adGroupOptions = React.useMemo(() => {
    const defaultOptions = campaignOptions.filter(({ campaignId }) => selectedCampaignsToAdGroups.has(campaignId))

    if (!adGroupSearchKeyword) return formatAdGroupOptions(defaultOptions) ?? []

    const filteredAdGroupOptions = _.cloneDeep(defaultOptions)
      .map((option) => {
        option.adGroups = option.adGroups.filter(({ adGroupName }) =>
          adGroupName.toLowerCase().includes(adGroupSearchKeyword.toLowerCase())
        )
        return option
      })
      .filter((option) => !!option.adGroups.length)

    return formatAdGroupOptions(filteredAdGroupOptions) ?? []
  }, [selectedCampaignsToAdGroups.keys(), adGroupSearchKeyword])

  const adGroupPickerValues = React.useMemo(
    () => Array.from(selectedCampaignsToAdGroups.values()).flatMap((value) => Array.from(value)),
    [selectedCampaignsToAdGroups]
  )

  const handleAdGroupSelectAll = (selectAll: boolean) => {
    setSelectedCampaignsToAdGroups((prev) => {
      const next = new SerializableMap(prev)
      adGroupOptions.forEach((campaign) => {
        const adGroupIds = campaign.children.map(({ id }) => id)
        if (selectAll) {
          next.set(campaign.id, new SerializableSet(adGroupIds))
        } else {
          const currentCampaign = next.get(campaign.id)
          adGroupIds.forEach((id) => currentCampaign?.delete(id))
        }
      })
      return next
    })
  }

  const handleAdGroupPickerChange = (activeNode: any) => {
    setSelectedCampaignsToAdGroups((prev) => {
      const next = new SerializableMap(prev)
      if (activeNode.type === 'campaign') {
        if (activeNode.check) {
          next.set(activeNode.id, new SerializableSet(activeNode.children.map((adGroup: any) => adGroup?.id)))
        } else {
          next.set(activeNode.id, new SerializableSet())
        }
      }
      if (activeNode.type === 'adGroup') {
        const currentCampaign = next.get(activeNode.parentNode.id)
        if (activeNode.check) {
          currentCampaign && currentCampaign.add(activeNode.id)
        } else {
          currentCampaign && currentCampaign.delete(activeNode.id)
        }
      }
      return next
    })
  }
  return (
    <BulkAssignModal
      dataTestId="act-mgr-target-moment-assign-adgroup-modal"
      size="lg"
      handleApply={handleApply}
      handleCancel={reset}
      show={show}
      header={<Header />}
    >
      <div className="flex gap-1 -mx-4 bg-gray-50">
        <CheckTreeDataPicker
          title="Campaigns"
          key="CampaignsCheckTreeDataPicker"
          options={campaignOptions || []}
          labelKey="campaignName"
          valueKey="campaignId"
          value={Array.from(selectedCampaignsToAdGroups.keys())}
          handleSearch={(searchKey: string) => {
            setCampaignSearchKeyword(() => searchKey)
          }}
          handleSelectAll={handleCampaignSelectAll}
          placeholder="No Result Found"
          rowCountValue={campaignOptions.length}
          handleSelectNode={handleCampaignPickerChange}
          suffixForCount={campaignOptions.length <= 1 ? 'Campaign' : 'Campaigns'}
        />
        <CheckTreeDataPicker
          title="Ad Groups"
          key={`AdGroupCheckTreeDataPicker_v${keyRef.current}`}
          componentKey={`AdGroupCheckTreeDataPicker_v${keyRef.current}`}
          options={adGroupOptions}
          labelKey="name"
          valueKey="id"
          value={adGroupPickerValues}
          childrenKey="children"
          handleSelectAll={handleAdGroupSelectAll}
          handleSelectNode={handleAdGroupPickerChange}
          handleSearch={(searchKey: string) => {
            setAdGroupSearchKeyword(() => searchKey)
          }}
          placeholder={adGroupSearchKeyword ? 'No Results Found' : 'Please select campaigns for ad groups to appear'}
          suffixForCount={adGroupOptions && adGroupOptions.length <= 1 ? 'Ad Group' : 'Ad Groups'}
        />
      </div>
      <style>{`.rs-checkbox-disabled > .rs-checkbox-checker > label{
            color: unset;
          }`}</style>
    </BulkAssignModal>
  )
}

export default BulkAssignCampaignAdGroups

const Header = () => (
  <header
    data-testid="act-mgr-target-moment-modal-header"
    id="act-mgr-target-moment-modal-header"
    className="text-lg border-b-2 border-gray-300"
  >
    <p className="px-5 pb-5 font-medium text-gray-800">{BULK_ASSIGN_TITLE}</p>
  </header>
)

const formatAdGroupOptions = (options: CampaignOptionsType) => {
  return options.map(({ adGroups, campaignId, campaignName }) => ({
    name: campaignName,
    id: campaignId,
    type: 'campaign',
    children: adGroups.map(({ adGroupId, adGroupName }) => ({
      name: adGroupName,
      id: adGroupId,
      type: 'adGroup'
    }))
  }))
}
