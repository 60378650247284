import React from 'react'
import _ from 'lodash'
import { Loader } from 'rsuite'
import { useSearch } from '@tanstack/react-location'

import SightlyButton from '@/components/Sightly/SightlyButton'
import { SelectedClusterType } from '@/views/Discover/Moments/v2/types'
import useBrandProfileInsertionOrders from '@/hooks/brandProfile/useBrandProfileInsertionOrders'
import { TargetListPropsV2 } from '@/views/Discover/Moments/v2/api'
import { useBulkMomentInsights } from '@/views/Discover/Moments/v2/MomentInsightsModal/useMomentInsights'
import { Board } from '@/views/Discover/Boards/useBoards'
import { MyLocationGenerics } from '@/classes/utils'
import { logError } from '@/utils'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'
import { MomentTable } from '@/views/Discover/Moments/v2/TargetMoment/MomentTable'
import Assignees from '@/views/Discover/Moments/v2/TargetMoment/Assignees'
import { useTargetMoments } from '@/views/Discover/Moments/v2/TargetMoment/hooks/useTargetMoments'
import useTargetMomentsInfo from '@/views/Discover/Moments/v2/TargetMoment/hooks/useTargetMomentsInfo'
import MessageToAssignees from '@/views/Discover/Moments/v2/TargetMoment/MessageToAssignees'
import ToolBar, { ListedBoardsType } from '@/views/Discover/Moments/v2/TargetMoment/ToolBar'
import SlideOver, { slideOverPlacements } from '@/components/SlideOver'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import Tooltip from '@/components/TailwindTooltip'
import { assigneesWarningText, emptyIoPersonasWarningText, personasWarningText, targetMomentsWarningText } from '@/views/Discover/Moments/v2/TargetMoment/constants'
import { useFlag } from '@unleash/proxy-client-react'

interface IProps {
  open: boolean
  setOpen: (val: boolean) => void
  selectedMoments: SelectedClusterType[]
  handleTarget: (payload: TargetListPropsV2) => Promise<void>
  handleSelectMoment: (moment: SelectedClusterType) => void
  trendingMoments?: Board
}

export type TargetListType = {
  id: number
  clusterName: string
  ioIdWithPersonas: Map<number, Set<string>>
}

export function TargetMoment({
  open,
  setOpen,
  selectedMoments: listedMoments,
  trendingMoments,
  handleTarget,
  handleSelectMoment: handleListedMoment
}: IProps) {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const iosQuery = useBrandProfileInsertionOrders(brandProfileId)
  const [selectedTargetMoments, setSelectedTargetMoments] = React.useState<Set<number>>(new Set())
  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState<boolean>(false)
  const [momentToBeDeleted, setMomentToBeDeleted] = React.useState<null | number>(null)
  const [deleteBulkMoments, setDeleteBulkMoments] = React.useState<boolean>(false)
  const { bulkMomentInsightsData, bulkMomentInsightsQueries } = useBulkMomentInsights({
    clusterIds: listedMoments.map(({ clusterId }) => clusterId),
    brandProfileId
  })
  const [isBulkAssignApplied, setIsBulkAssignApplied] = React.useState<boolean>(false)

  const { loadTargetListFromStorage, setTargetListToStorage } = useTargetMoments({ brandProfileId })
  const [targetList, setTargetList] = React.useState<Map<number, TargetListType>>(() => loadTargetListFromStorage())

  const { defaultTargetMomentsInfo, setTargetMomentsInfo } = useTargetMomentsInfo()
  const [assignees, setAssignees] = React.useState<string[]>(() => defaultTargetMomentsInfo?.assignees || [])
  const [message, setMessage] = React.useState(() => defaultTargetMomentsInfo.message || '')
  const [filteredBoardIds, setFilteredBoardIds] = React.useState<number[]>()
  const [searchedMomentsIds, setSearchedMomentsIds] = React.useState<number[]>()
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [isTargetingMoment, setIsTargetingMoment] = React.useState(false)
  const [disabledCreateButtonReasons, setDisabledCreateButtonReason] = React.useState<Set<string>>()

  React.useEffect(() => {
    setTargetMomentsInfo({ assignees, message })
  }, [message, assignees])

  /**
   * listedMomentBoards Memoized Function:
   * Generates a mapping of moment IDs to an array of associated boards based on bulkMomentInsightsData.
   *
   * @returns {Map<number, Array<{ boardId: number; boardName: string }>> | undefined}
   *   - A mapping of moment IDs to an array of associated boards if bulkMomentInsightsData is available; otherwise, undefined.
   */
  const listedMomentBoards = React.useMemo(() => {
    if (!bulkMomentInsightsData) return undefined
    // Initialize a map to store moment IDs and associated boards
    const selectedMomentBoardsMap = new Map<number, Array<{ boardId: number; boardName: string }>>()
    bulkMomentInsightsData.forEach((value, currentClusterId) => {
      // set corresponding boards to clusters
      const boards = []
      if (value?.isTrendingMoment && trendingMoments) {
        boards.push({ boardId: trendingMoments.id, boardName: trendingMoments.name })
      }
      if (value?.momentInsights.length) {
        value.momentInsights.forEach(({ boardId, boardName }) => boards.push({ boardId, boardName }))
      }
      if(bulkMomentInsightsQueries.every(query => !query.isLoading) && !boards.length) {
        boards.push({ boardId: 0, boardName: 'N/A' })
      }
      selectedMomentBoardsMap.set(currentClusterId, boards)
    })
    return selectedMomentBoardsMap
  }, [bulkMomentInsightsData])

  const filteredClusterToBoards = React.useMemo<
    | Map<
        number,
        {
          boardId: number
          boardName: string
        }[]
      >
    | undefined
  >(() => {
    if (!filteredBoardIds || !filteredBoardIds.length) return listedMomentBoards
    const next = new Map()
    listedMomentBoards?.forEach((boards, clusterId) => {
      const filteredBoardsList = boards.filter((board) => filteredBoardIds.includes(board.boardId))
      if (filteredBoardsList.length) {
        next.set(clusterId, filteredBoardsList)
      } else {
        selectedTargetMoments.delete(clusterId)
      }
    })
    return next
  }, [listedMomentBoards, filteredBoardIds])

  const boardsFilterOptions = React.useMemo(() => {
    const boardsFilterOptionsSet = new Set()
    const filteredList: ListedBoardsType = []
    const boardOptionsList = Array.from(listedMomentBoards?.values() || []).flat()
    // Create unique list of boards.
    boardOptionsList.forEach((board) => {
      if (!boardsFilterOptionsSet.has(board.boardId)) {
        boardsFilterOptionsSet.add(board.boardId)
        filteredList.push(board)
      }
    })
    if (filteredList.length) return filteredList
    return boardOptionsList
  }, [filteredClusterToBoards])

  const filteredClusters = React.useMemo(() => {
    if (!filteredClusterToBoards || !filteredClusterToBoards.size) return listedMoments
    return listedMoments.filter(({ clusterId }) => {
      if (searchedMomentsIds) {
        return filteredClusterToBoards.has(clusterId) && searchedMomentsIds.includes(clusterId)
      }
      return filteredClusterToBoards.has(clusterId)
    })
  }, [filteredClusterToBoards, searchedMomentsIds])

  const handleSelectAll = (checked: boolean) => {
    if (checked && filteredClusters) {
      setSelectedTargetMoments(() => new Set(filteredClusters.map(({ clusterId }) => clusterId)))
    } else setSelectedTargetMoments(() => new Set())
  }
  
  const handleFilterBoards = (filteredBoardIds: number[]) => {
    setFilteredBoardIds(filteredBoardIds)
  }

  const handleSearchMoments = (searchKey: string) => {
    const searchResult = listedMoments.filter(({ clusterName }) =>
      clusterName.toLowerCase().includes(searchKey.toLowerCase())
    )
    setSearchedMomentsIds((prev) => searchResult.map(({ clusterId }) => clusterId))
  }

  const handleApplyBulkPersonas = (ioToPersonas: Map<number, Set<string>>) => {
    setTargetList((prev) => {
      const next = new Map(prev)
      selectedTargetMoments.forEach((clusterId) => {
        const targetMoment = listedMoments.find((moment) => moment.clusterId === clusterId)
        if (!targetMoment) return prev
        const value: TargetListType = {
          id: targetMoment.clusterId,
          clusterName: targetMoment.clusterName,
          ioIdWithPersonas: ioToPersonas
        }
        next.set(clusterId, value)
      })
      return next
    })
    setIsBulkAssignApplied(() => true)
    return
  }

  const handleBulkDeleteMoments = () => {
    // Reset the selectedMoments in AylienBoards page.
    Array.from(listedMoments).forEach((moment) => {
      selectedTargetMoments.has(moment.clusterId) && handleListedMoment(moment)
    })
    // Delete clusters from targetlist
    setTargetList((prev) => {
      const next = new Map(prev)
      selectedTargetMoments.forEach((clusterId) => {
        next.delete(clusterId)
      })
      return next
    })
    // Reset the selectedMoments.
    setSelectedTargetMoments(() => new Set())
    // Reset confirmation dialog
    setDeleteBulkMoments(false)
    // Reset isSelectAllChecked
    setIsSelectAllChecked(false)
  }

  const handleCancelBulkDeleteMoment = () => setDeleteBulkMoments(false)

  /**
   * setIOsToTargetList Function:
   * Updates the Target List with the selected Insertion Order and initializes the associated personas.
   *
   * @param {number} clusterId - ID of the cluster to update in the Target List
   * @param {number} ioId - ID of the selected Insertion Order
   * @param {number | undefined} prevIoId - ID of the previously selected Insertion Order (if any)
   * @returns {void}
   */
  const setIOsToTargetList = (clusterId: number, ioId: number, prevIoId?: number | undefined): void => {
    setTargetList((prev) => {
      const next = new Map(prev)
      const value = next.get(clusterId)
      // If the value exists and a new IO is selected
      if (value && ioId) {
        // If there was a previously selected IO, remove it from the IOs with personas
        prevIoId && value.ioIdWithPersonas.delete(prevIoId)
        // Initialize the selected IO with an empty set of personas
        value.ioIdWithPersonas.set(ioId, new Set())
        // Update the Target List with the modified value
        next.set(clusterId, value)
      } else {
        // If the value does not exist, create a new entry in the Target List
        const targetMoment = listedMoments.find((moment) => moment.clusterId === clusterId)
        targetMoment &&
          next.set(clusterId, {
            id: targetMoment.clusterId,
            clusterName: targetMoment.clusterName,
            ioIdWithPersonas: new Map<number, Set<string>>().set(ioId, new Set())
          })
      }
      return next
    })
  }

  const removeIOsFromTargetList = (clusterId: number, ioId: number) => {
    setTargetList((prev) => {
      const next = new Map(prev)
      const value = next.get(clusterId)
      if (value) {
        value.ioIdWithPersonas.delete(ioId)
        next.set(clusterId, value)
      }
      return next
    })
  }

  const setPersonasToTargetList = (clusterId: number, ioId: number, personas: string[]) => {
    setTargetList((prev) => {
      const next = new Map(prev)
      const value = next.get(clusterId)
      if (value) {
        value.ioIdWithPersonas.set(ioId, new Set(personas))
        next.set(clusterId, value)
      }
      return next
    })
  }

  React.useEffect(() => {
    targetList && setTargetListToStorage(targetList)
  }, [targetList])

  /**
   * Handles the creation of the Target Moments for Activation Manager
   */
  const handleCreateTargetMoments = async () => {
    if (!brandProfileId) return
    setIsTargetingMoment(true)

    const payload: TargetListPropsV2 = {
      assignees: assignees,
      brandProfileId,
      message,
      targetList: _.cloneDeep(new SerializableMap<number, TargetListType>(targetList))
    }
    await handleTarget(payload)
    setMessage('')
    setAssignees([])
    setIsTargetingMoment(false)
    setOpen(false)
  }

  const handleDeleteTargetedMoment = () => {
    try {
      const moment = Array.from(listedMoments).filter((moment) => moment.clusterId === momentToBeDeleted)[0]

      handleListedMoment(moment)
      //remove moment from selectedMoments
      setSelectedTargetMoments((prev) => {
        const next = new Set(prev)
        next.delete(moment.clusterId)
        return next
      })
      //remove moment from targetList
      setTargetList((prev) => {
        const next = new Map(prev)
        momentToBeDeleted && next.delete(momentToBeDeleted)
        return next
      })
      setMomentToBeDeleted(null)
    } catch (err) {
      logError(err)
    }
  }

  const ioOptions = React.useMemo(() => {
    const options = iosQuery.data || []
    return options.map((io) => {
      const personas = io.personas.map((persona, index) => ({
        id: `${io.ioId}_${persona}_${index}`,
        name: persona,
        type: 'persona'
      }))
      return {
        id: io.ioId,
        name: io.ioName,
        type: 'insertionOrder',
        personas: personas.length ? personas.sort((a , b) => a.name > b.name ? 1 : -1 ) : [{
          id: `${io.ioId}_NoPersona_${0}`,
          name: 'No Persona',
          type: 'persona'
        }]
      }
    }).sort((a , b) => a.name > b.name ? 1 : -1 )
  },[iosQuery.data])

  //Hides the slideover if all the target moments are deleted
  React.useEffect(() => {
    if (listedMoments.length <= 0) setOpen(false)
  }, [listedMoments])

  const handleCancelDeleteMoment = () => setMomentToBeDeleted(null)

  const disabledCreateTargetMoment = React.useMemo(() => {
    if (assignees.length < 1) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add(assigneesWarningText)
        return next
      })
      return true
    } else {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.delete(assigneesWarningText)
        return next
      })
    }
    if (targetList.size < 1) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add(targetMomentsWarningText)
        return next
      })
      return true
    } else {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.delete(targetMomentsWarningText)
        return next
      })
    }
    const targetMoments = Array.from(targetList.values())
    
    const isAtLeastOnePersonasUnallocated = targetMoments.some((moment) => {
      const personas = Array.from(moment.ioIdWithPersonas.values())
      if (personas.length < 1) {
        setDisabledCreateButtonReason((prev) => {
          const next = new Set(prev)
          next.add(emptyIoPersonasWarningText)
          return next
        })
        return true
      }

      if (personas.some((persona) => persona.size < 1)) { 
        setDisabledCreateButtonReason((prev) => {
          const next = new Set(prev)
          next.add(personasWarningText)
          return next
        })
        return true
      }
      return false
    })
    if (!isAtLeastOnePersonasUnallocated) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.delete(personasWarningText)
        next.delete(emptyIoPersonasWarningText)
        return next
      })
    }
    return isAtLeastOnePersonasUnallocated
  }, [targetList, assignees])

  return (
    <>
      <SlideOver
        dataTestId="act-mgr-target-moment-drawer"
        headerBackgroundColor="bg-sightlyBlue"
        headerTitle="New Target List"
        headerDescription="Get started by filling in the information below to create your new target list."
        show={open}
        onHide={() => setOpen(false)}
        fullScreen={isFullScreen}
        placement={slideOverPlacements.RIGHT}
        maxOffset="345px - 7em"
        minOffset="7em"
        footer={
          <Footer
            disabledCreateButton={disabledCreateTargetMoment}
            disabledCreateButtonReason={disabledCreateButtonReasons}
            handleConfirmClick={handleCreateTargetMoments}
            setOpen={setOpen}
          />
        }
      >
        <Assignees
          assignees={assignees}
          setAssignees={setAssignees}
        />

        <MessageToAssignees
          message={message}
          setMessage={setMessage}
        />

        <label className="px-4 pt-6 mb-2 font-medium sm:px-6 text-grey-900">Moments ({filteredClusters.length})</label>

        <ToolBar
          boardsOptions={boardsFilterOptions}
          handleFilterBoards={handleFilterBoards}
          handleSearchMoments={handleSearchMoments}
          selectedMomentsCount={selectedTargetMoments.size}
          ioOptions={ioOptions}
          handleApplyBulkPersonas={handleApplyBulkPersonas}
          setDeleteBulkMoments={setDeleteBulkMoments}
        />

        <MomentTable
          {...{
            selectedTargetMoments,
            setSelectedTargetMoments,
            isSelectAllChecked,
            setIsSelectAllChecked,
            handleSelectAll,
            listedMoments: filteredClusters,
            ioOptions,
            listedMomentBoards: filteredClusterToBoards,
            targetList,
            setIOsToTargetList,
            removeIOsFromTargetList,
            setPersonasToTargetList,
            setMomentToBeDeleted,
            isBulkAssignApplied,
            setIsBulkAssignApplied,
            isFullScreen
          }}
        />
        {isTargetingMoment && (
          <Loader
            backdrop
            content="Creating Activations..."
            vertical
            className="z-10"
            speed="slow"
          />
        )}
      </SlideOver>
      <ConfirmationDialog
        show={!!momentToBeDeleted}
        onHide={handleCancelDeleteMoment}
        handleConfirm={handleDeleteTargetedMoment}
        handleCancel={handleCancelDeleteMoment}
        body={'Are you sure you want to remove this moment?'}
        action="Delete"
      />
      <ConfirmationDialog
        show={!!deleteBulkMoments}
        onHide={handleCancelBulkDeleteMoment}
        handleConfirm={handleBulkDeleteMoments}
        handleCancel={handleCancelBulkDeleteMoment}
        body={`Are you sure you want to remove ${selectedTargetMoments.size} moments?`}
        action="Delete"
      />
    </>
  )
}

const Footer = (props: {
  setOpen: (val: boolean) => void
  disabledCreateButton: boolean
  disabledCreateButtonReason: Set<string> | undefined
  handleConfirmClick: () => void
}) => (
  <div className="flex justify-end flex-shrink-0 gap-3 px-4 py-4 bg-gray-100 shadow-md">
    <SightlyButton
      type="secondary"
      text="Cancel"
      id="cancelTargetButton"
      handleClick={() => props.setOpen(false)}
    />
    <Tooltip
      disabled={!props.disabledCreateButton}
      content={
        <div
          data-testid="act-mgr-target-moments-table-cluster-name-tooltip"
          className="text-left"
        >
          <>
            {Array.from(props.disabledCreateButtonReason?.values() || []).map((text) => (
              <p
                className="p-1"
                key={text}
              >
                {text}
              </p>
            ))}
          </>
        </div>
      }
    >
      <div>
        <SightlyButton
          disabled={props.disabledCreateButton}
          text="Create"
          id="confirmTargetButton"
          handleClick={props.handleConfirmClick}
        />
      </div>
    </Tooltip>
  </div>
)

export type IoOptionsType= {
  id: number;
  name: string;
  type: string;
  personas: {
      id: string;
      name: string;
      type: string;
  }[];
}