import { routes } from '@/routes';
import useUser from '@/hooks/useUser';
import { products } from '@/staticData/products';
import { ProductCountTracker } from '@/views/BrandProfiles/BrandProfile/components/ProductCountTracker';
import { UserGroupIcon, UserIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from '@tanstack/react-location';
import React from 'react';
import styled from 'styled-components';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';


const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex: auto;
    height: 100%;
`

const SettingsSidePanelDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.25rem 1rem;
    min-width: 14rem;
`

const ContentDiv = styled.div`
    flex-grow: 1;
    min-height: 100%;
    border-left-color: rgb(225, 229, 232);
    border-left-width: 1px;
`

const SettingsHeader = styled.p`
    color: var(--gray-darker, #333D47);

    font-size: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
`

const SettingsGroupDiv = styled.div`
    display: flex;
    flex-direction: column;
`

const SettingsGroupHeaderDiv = styled.div`
    > svg {
        width: 1.5rem;
        margin-right: .5rem;
    }

    > p {
        color: var(--gray-600, #4B5563);
        font-size: 1rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
    }

    padding: .5rem;
    display: flex;
    flex-direction: row;
`

const SettingGroupButton = styled.button<{ active: boolean }>`
    display: flex;
    padding: .5rem .5rem .5rem 2.5rem;
    border-radius: 0.5rem;
    text-align: left;
    font-size: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${(props) => props.active ? '#38BDF8' : '#4B5563'};
    background: ${(props) => props.active ? 'var(--light-blue-50, #F0F9FF)' : '#fff'};
`

interface IProps {
    children: React.ReactElement
}

export const SettingsLayout = ({ children }:IProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { currentAccount, perms, userPermissions } = useUser()

    return (
        <StyledDiv>
            <div className='flex flex-col justify-between'>
            <SettingsSidePanelDiv>
                <SettingsHeader>Settings</SettingsHeader>
                <SettingsGroupDiv>
                    <SettingsGroupHeaderDiv>
                        <UserIcon />
                        <p>Personal Settings</p>
                    </SettingsGroupHeaderDiv>
                    <SettingGroupButton
                        data-testid='sidenav-profile'
                        active={location.current.pathname.includes(routes.app.settings.profile.path)}
                        onClick={() => navigate({ to: routes.app.settings.profile.path })}
                    >
                        My Profile
                    </SettingGroupButton>
                </SettingsGroupDiv>
                {userCanPermissionProductQuota({
                    requiredPermissionValue: perms.ACCOUNT_SETTINGS_READ,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                }) && <SettingsGroupDiv>
                    <SettingsGroupHeaderDiv>
                        <UserGroupIcon />
                        <p>Account Settings</p>
                    </SettingsGroupHeaderDiv>
                    <SettingGroupButton
                        data-testid='sidenav-account'
                        active={location.current.pathname.includes(routes.app.settings.account.path)}
                        onClick={() => navigate({ to: routes.app.settings.account.path })}
                    >
                        Account Details
                    </SettingGroupButton>
                    <SettingGroupButton
                        data-testid='sidenav-users'
                        active={location.current.pathname.includes(routes.app.settings.users.path)}
                        onClick={() => navigate({ to: routes.app.settings.users.path })}
                    >
                        Users
                    </SettingGroupButton>
                    {currentAccount?.stripeSubscriptionId &&
                      <div>
                        <SettingGroupButton
                          data-testid='sidenav-payments'
                          active={false}
                          onClick={() => window.open(process.env.REACT_APP_STRIPE_BILLING_PORTAL_URL, "_blank")}
                        >
                          Payments & Billing
                          <ArrowTopRightOnSquareIcon className="ml-2 h-5" />
                        </SettingGroupButton>

                      </div>
                    }
                </SettingsGroupDiv>}
            </SettingsSidePanelDiv>
            <ProductCountTracker
                productName={products.USERS}
                title='Users'
                direction='col'
                additionalStyle='p-4'
                dataTestId='product-count-tracker-users'
            />
            </div>
            <ContentDiv>{children}</ContentDiv>
        </StyledDiv>
    )
}
