import { useMatch } from '@tanstack/react-location';
import React from 'react';
import { MyLocationGenerics } from '@/classes/utils';
import { LoadingPage } from '@/components/LoadingPage';
import { OpinionsThemeType } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/opinions-types';
import { OpinionThemeCard } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/OpinionThemeCard';
import { SlideOver } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/SlideOver/SlideOver';
import { SortBy } from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/SortBy';
import useOpinions from '@/views/BrandProfiles/BrandProfile/components/Opinions/V2/useOpinionsV2';
import { OPINIONS_LABELS } from '@/views/BrandProfiles/constants';
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';

export const Opinions = () => {

  const {
    params: { brandProfileId }
  } = useMatch<MyLocationGenerics>()

  const [sortBy, setSortBy] = React.useState<'least' | 'most'>('least')

  const { opinions, opinionsIsLoading } = useOpinions(Number(brandProfileId), sortBy)

  const [selectedOpinionThemeId, setSelectedOpinionThemeId] = React.useState<number | null>(null)
  const selectedOpinionType = React.useMemo(() => {
    if (!selectedOpinionThemeId || !opinions) return null
    const selectedOpinionTheme = opinions.find(
      (opinionType: OpinionsThemeType) => opinionType.opinionTypeId === selectedOpinionThemeId
    )
    return selectedOpinionTheme || null
  }, [selectedOpinionThemeId, opinions])

  const {
    activeStep,
    handleContinueClick,
    handlePreviousClick
  } = useSteps();

  if (opinionsIsLoading) {
    return <LoadingPage message="Fetching Opinions" />
  }
  return (
    <div className="flex justify-center w-full bg-gray-100 animate-in fade-in ">
      <SlideOver
        open={!!selectedOpinionThemeId}
        onClose={() => setSelectedOpinionThemeId(null)}
        opinionTheme={selectedOpinionType}
        sortBy={sortBy}
      />
      <div className="m-2">
        <div className="w-full text-xl font-bold text-sightlySlate">
          {OPINIONS_LABELS.MAIN_TITLE}
        </div>

        <div className="font-semibold text-sm text-[#898989] mt-3">
          {OPINIONS_LABELS.MAIN_DESCRIPTION}
        </div>
        <div className='flex items-center justify-end w-full pr-3 mt-2 mb-5'>
          <div className="flex items-center gap-3 p-1 rounded hover:cursor-pointer hover:bg-slate-100">
            <SortBy
              selected={sortBy}
              onChange={(val: 'least' | 'most') => setSortBy(val)}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          {opinions?.map((opinionType) => {
              return (
                <OpinionThemeCard
                  key={opinionType.opinionTypeId}
                  handleContinueClick={() => setSelectedOpinionThemeId(opinionType.opinionTypeId)}
                  opinionTheme={opinionType}
                />
              )
            })}
        </div>
      </div>
      <BrandProfileFooter activeStep={activeStep}
        disabled={false}
        handlePrevious={handlePreviousClick}
        handleContinue={handleContinueClick}
      />
    </div>
  )
}
