import React, { useState, useEffect } from 'react';
import ArticleResultCard from '@/views/Trends/TrendDetails/components/ArticleResultCard';
import { IArticle, metaData } from '@/views/Trends/types';
import SightlyButton from '@/components/Sightly/SightlyButton';
import SearchBox from '@/components/SearchBox';
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import { ranges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import dayjs from 'dayjs';

interface ArticleListProps {
  articles: IArticle[];
  meta: metaData;
}

const ArticleResultsList = ({ articles }: ArticleListProps) => {
  const [filteredArticles, setFilteredArticles] = useState<IArticle[]>(articles);
  const [displayedArticles, setDisplayedArticles] = useState<IArticle[]>(filteredArticles.slice(0, 2));
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [dateRange, setDateRange] = useState<[Date, Date]>(getLast60Days());

  const perPage = 2;

  const filterByDate = (articles: IArticle[], [start, end]: [Date, Date]) => {
    return articles.filter(article => {
      const timestamp = dayjs(article.timestamp);
      return timestamp.isAfter(dayjs(start)) && timestamp.isBefore(dayjs(end).endOf('day'));
    });
  };

  const filterBySearch = (articles: IArticle[], keyword: string) => {
    const lowerKeyword = keyword.toLowerCase();
    return articles.filter(article => article.title.toLowerCase().includes(lowerKeyword));
  };

  useEffect(() => {
    let result = articles;
    result = filterByDate(result, dateRange);
    result = filterBySearch(result, searchKeyword);

    setFilteredArticles(result);
    setDisplayedArticles(result.slice(0, perPage));
    setPage(1);
  }, [dateRange, searchKeyword, articles]);

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleChangeDateRange = (selectedRange: [Date, Date]) => {
    setDateRange(selectedRange);
  };

  const handlePageChange = (newPage: number) => {
    setDisplayedArticles(filteredArticles.slice(0, newPage * perPage));
    setPage(newPage);
  };

  return (
    <div>
      <div className="w-full flex justify-between">
        <SearchBox
          dataTestId="article-result-list-search-box"
          handleSearch={handleSearch}
          placeholder="Search Articles"
          className="mb-3"
        />
        <DateRangeSection
          onSelectDateRange={handleChangeDateRange}
          ranges={ranges}
          rangeValue={dateRange}
        />
      </div>
      <div className="w-full flex justify-end py-2">
        Showing {displayedArticles.length} of {filteredArticles.length}
      </div>
      {displayedArticles.map(article => (
        <ArticleResultCard key={article.id} article={article} />
      ))}
      <SightlyButton
        id="load-more-article-results-button"
        selectorClass="w-full"
        handleClick={() => handlePageChange(page + 1)}
        text="Load More"
        disabled={page * perPage >= filteredArticles.length}
      />
    </div>
  );
};

export const getLast60Days = (): [Date, Date] => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  const sixtyDaysAgo = new Date(now);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  sixtyDaysAgo.setHours(0, 0, 0, 0);
  return [sixtyDaysAgo, now];
};

export default ArticleResultsList;
