import NoImageFoundPath from '@/assets/img/NoImageFound.svg'
import ChartTooltip from '@/views/Discover/Boards/components/conversations/ChartTooltip'
import { RelevanceClassificationBadge } from '@/views/Discover/Moments/v2/components/RelevanceClassificationBadge'
import Growth from '@/views/Trends/Results/components/Growth'
import { ITopic, ITrend } from '@/views/Trends/types'
import React from 'react'
import { Area, AreaChart, Tooltip as RechartTooltip, ResponsiveContainer, XAxis } from 'recharts'

interface IProps {
  result:  ITopic | ITrend
}

const ResultCard = ({ result }: IProps) => {
  const totals = [
    { name: 'Total Moments', value: result.metrics.moment.count },
    { name: 'Total Social Posts', value: result.metrics.narrative.count }
  ]

  if (result?.metrics?.trend?.count !== undefined) {
    totals.push({ name: 'Total Trends', value: result.metrics.trend.count });
  }

  return (
    <div key={result.name} className="relative grid grid-cols-11 gap-2 rounded-md border border-gray-200 bg-white hover:z-50 animate-in fade-in">
      {/* Image section */}
      <div className='col-span-2 content-center h-64'>
        <img
          src={result.imageUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NoImageFoundPath;
          }}
          alt={`image:${result.name}`}
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      <div className='col-span-9 px-2 xl:px-0 py-4 grid grid-cols-1 gap-4 divide-y xl:grid-cols-5 xl:divide-y-0 xl:divide-x divide-slate-300 '>
        {/* Middle section */}
        <div className='xl:col-span-3 flex flex-col gap-7 pl-5'>
          <div>
            <span className="text-xs xl:text-sm font-bold line-clamp-1">{result.name}</span>
              <p>{result.overview}</p>
          </div>
          <div className='flex flex-row justify-between'>
            {totals.map(({ name, value }) => (
              <div className='flex flex-col gap-3'>
                <span className='text-xs'>{name}</span>
                <span className='font-bold text-lg text-sightlyPurple'>{value}</span>
              </div>
            ))}
          </div>
        </div>
        {/* Graph section */}
        <div className='xl:col-span-2 px-4 flex flex-col justify-between'>
          <RelevanceClassificationBadge relevanceClassification={result.relevance} background />
          <Growth growth={result.growth ?? 0} />
          <ResponsiveContainer width="100%" height="45%">
            <AreaChart
              margin={{ bottom: -10 }}
              data={result.metrics?.moment.articleCountPerDay}
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                style={{
                  fontSize: '8px'
                }}
              />
              <Area
                type="linear"
                dataKey="count"
                stroke={'#651AEB'}
                fill={'#ECE3FC'}
              />
              <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: false, y: true }} wrapperStyle={{ zIndex: 1000 }} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default ResultCard
