import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useSearch } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import svgLogo from '@/assets/img/sightly-logo.svg'
import defaultLoginImage from '@/assets/img/introSideImage.png'
import tiktokLoginImage from '@/assets/img/tiktoklogin.png'
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput'
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm'
import { SignupFormSchema, SignupFormType } from '@/views/Signup/types'
import { MyLocationGenerics } from '@/classes/utils';
import { PATH_TO_ONBOARDING_HUB, PATH_BRAND_PROFILES, routes } from '@/routes';
import config from '@/config'
import { api } from '@/api/api';
import { ApiErrorResponse } from '@/api/apiTypes';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { ConfirmationModalTailwind } from '@/components/ConfirmationModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { isIframe } from '@/validations';

const Form = styled.form`
    div, button {
        width: 100%;
    }

    padding-top: 1em;
    gap: 1em;
`

const NameFieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
`

const SightlyLogo = styled.img`
    height: 2em;
`

const SignUpHeader = styled.h1`
    padding-top: 1em;
    text-align: center;
`

const LoginDiv = styled.div`
    text-align: center;
`

const StyledLink = styled(Link)`
    padding-left: .5em;
`

export const Signup: React.FC = () => {
    const navigate = useNavigate()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const [showEmailInUseDialog, setShowEmailInUseDialog] = React.useState(false)
    const closeEmailInUseDialog = () => setShowEmailInUseDialog(false)
    const openLoginPage = () => navigate({ to: routes.login.path })
    const { redirect } = useSearch<MyLocationGenerics>()
    const isInTikTokIframe = isIframe();

    const { sightlyRegister, handleSubmit, formState: { isValid }, trigger, watch, getFieldState } = useSightlyForm<SignupFormType>(SignupFormSchema)
    // This is needed due to controller RHF fields not having the deps property: https://github.com/react-hook-form/react-hook-form/issues/9685#issuecomment-1369813795
    useEffect(() => {
        if (getFieldState('confirmPassword').isTouched) {
            trigger(['confirmPassword'])
        }
    }, [trigger, watch('password')]);

    const mutation = useMutation(api.user.signup, {
        onSuccess: (_) => {
            if (localStorage.getItem('token')) {
                window.location.href = redirect || PATH_TO_ONBOARDING_HUB
            }
        },
        onError: (err: AxiosError<ApiErrorResponse>) => {
            if (err.response?.status === 400 && err.response.data.message === 'Email is already registered to a user') {
                setShowEmailInUseDialog(true)
                return
            }

            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    // Handle redirection if logged in already
    // Allow navigating to signup page for beta testing purposes
    // TO DO: clean up flag on epic completion
    if (localStorage.getItem('token')) {
        if (isInTikTokIframe) {
            window.location.href = redirect || PATH_BRAND_PROFILES
        } else {
            window.location.href = redirect || PATH_TO_ONBOARDING_HUB
        }
    }

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            <SightlyLogo src={svgLogo} alt='logo' />
            <SignUpHeader className='text-3xl leading-9 font-extrabold text-gray-900'>Create your account</SignUpHeader>
            <Form
                onSubmit={onSubmit}
                className='flex flex-col justify-center items-center'
            >
                <NameFieldsDiv>
                    <SightlyModernFormInput {...sightlyRegister('firstName', 'First name')} />
                    <SightlyModernFormInput {...sightlyRegister('lastName', 'Last name')} />
                </NameFieldsDiv>
                <SightlyModernFormInput {...sightlyRegister('companyName', 'Company name')} placeholder='Your company name' />
                <SightlyModernFormInput {...sightlyRegister('email', 'Work email address')} placeholder='you@example.com' />
                <SightlyModernFormInput {...sightlyRegister('password', 'Password')} showAllErrors type='password' />
                <SightlyModernFormInput {...sightlyRegister('confirmPassword', 'Confirm password')} type='password' />

                <SightlyModernButton
                    id='submitButton'
                    type='inverted'
                    handleClick={() => undefined}
                    text='Create Account'
                    disabled={mutation.isLoading || !isValid}
                />

                <ConfirmationModalTailwind
                    open={showEmailInUseDialog}
                    handleConfirm={openLoginPage}
                    handleCancel={closeEmailInUseDialog}
                    title='Email already in use'
                    subtitle='Do you want to login instead?'
                />
                <WarningModalTailwind
                    open={showGenericErrorDialog}
                    setOpen={setShowGenericErrorDialog}
                    title='Something went wrong'
                    subtitle='Please try again, or contact support at support@sightly.com'
                />

                <LoginDiv>
                    <p className='text-sm leading-5 font-normal text-gray-600 '>
                        Already have an acount?
                        <StyledLink
                            className='text-sm leading-5 font-medium text-sightlyBlue'
                            to='/login'
                        >
                            Login
                        </StyledLink>
                    </p>
                </LoginDiv>
            </Form>
        </IntroLayout>
    );
};
