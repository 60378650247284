import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { userAccountAxios } from '@/axiosInstances';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { logError } from '@/utils';
import { isAxiosError } from '@/utils_ts';
import { useFlag } from '@unleash/proxy-client-react';
import config from '@/config';

interface IProps {
  excludeArchived?: boolean
}

type ThemeDataType = {
  id: number
  description: string
  typeName: string
  archived: boolean
}

const fetchAdminOpinionTypes = async ({ excludeArchived }: IProps) => {
  const url = excludeArchived ? `/opinions/types?archived=false` : `/opinions/types`
  const result = await userAccountAxios.get(url)
  return result.data
}

const createOpinionType = async (type: any) => {
  const url = `/opinions/types`
  await userAccountAxios.post(url, type)
}

const useBrandProfileOpinionTypes = ({ excludeArchived }: IProps) => {
  const queryClient = useQueryClient()
  const {
    data: opinionTypes,
    isLoading: opinionTypesIsLoading
  } = useQuery(
    rqKeys.opinionTypesKey(excludeArchived),
    () => fetchAdminOpinionTypes({ excludeArchived })
  )

  const createOpinionTypeMutation = useMutation(createOpinionType, {
    onMutate: async () => {
      queryClient.setQueryData(rqKeys.opinionTypesKey(excludeArchived), (old: any) => {
        if (!old) return []
        return old
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(rqKeys.opinionTypesKey(excludeArchived))
    }
  })

  return {
    opinionTypes,
    createOpinionType: createOpinionTypeMutation.mutate,
    opinionTypesIsLoading
  }
}

export default useBrandProfileOpinionTypes
