import React from 'react'
import Tooltip from '@/components/TailwindTooltip'
import { BASIC_INFO } from '@/views/BrandProfiles/constants'
import { Badge } from '@/components/Badge'
import { AIIcon } from './AIIcon'

type PillProps = {
  value: string
  handleSelect: (key: string) => void
  selected?: boolean
  disabled?: boolean
}

const Pill = ({ value, handleSelect, selected, disabled }: PillProps) => {
  const MAX_PILL_NAME_SIZE = 20
  const truncatedName = (text: string) =>
    text.length <= MAX_PILL_NAME_SIZE ? text : text.slice(0, MAX_PILL_NAME_SIZE) + '...'
  return (
    <div
      data-testid="brand-info-key-terms-pill"
      onClick={() => (disabled ? undefined : handleSelect(value))}
      className={`h-8 rounded-xl inline-block text-xs font-semibold py-2 px-3
        ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        ${selected ? 'text-white' : disabled ? 'text-gray-400' : 'text-blue-800'}
        ${selected ? 'bg-sightlyBlue' : 'bg-blue-100'}`}
    >
      <Tooltip
        disabled={value.length < MAX_PILL_NAME_SIZE}
        content={<div className="text-left">{value}</div>}
      >
        <div className="z-50 flex items-center justify-center h-full">
          {truncatedName(value)}
          <div
            className={`font-semibold text-sm ml-2
            ${selected ? 'text-white' : disabled ? 'text-black' : 'text-sightlyBlue'}`}
          >
            +
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export const KeyTerms = (props: {
  keyTerms?: string
  aiSuggestedKeyTerms?: string
  disabled: boolean | undefined
  handleKeyTermsChange: (keyTermsValue: string) => void
}) => {
  const [selectedKeyTerms, setSelectedKeyTerms] = React.useState<Set<string>>(new Set())
  const [aiSuggestedKeyTermsSet, setAiSuggestedKeyTermsSet] = React.useState<Set<string>>(new Set())
  const [userText, setUserText] = React.useState('')

  const handleSelectKeyTerm = (keyTerm: string) => {
    if (selectedKeyTerms?.has(keyTerm)) {
      setSelectedKeyTerms((prev) => {
        const newKeySet = new Set(prev)
        newKeySet.delete(keyTerm)
        return newKeySet
      })
    } else {
      setSelectedKeyTerms((prev) => new Set(prev).add(keyTerm))
    }
  }

  const handleSaveKeyTerm = () => {
    userText?.trim() &&
      setSelectedKeyTerms((prev) => {
        let current = new Set(prev)
        userText.split(',').forEach((userTerm) => userTerm.trim() && current.add(userTerm.trim()))
        return current
      })
    setUserText('')
  }

  React.useEffect(() => {
    if (props.keyTerms && props.keyTerms.trim().length) {
      setSelectedKeyTerms(new Set(props.keyTerms.split(',')))
    }
    if (props.aiSuggestedKeyTerms && props.aiSuggestedKeyTerms.trim().length) {
      setAiSuggestedKeyTermsSet(new Set(props.aiSuggestedKeyTerms.split(',')))
    }
  }, [props.keyTerms?.length, props.aiSuggestedKeyTerms?.length])

  React.useEffect(() => {
    selectedKeyTerms && props.handleKeyTermsChange(Array.from(selectedKeyTerms).join(','))
  }, [selectedKeyTerms?.size])

  return (
    <div
      data-testid="brand-info-keyTerms"
      className="flex flex-col w-full gap-4"
    >
      <div className="flex flex-col gap-4">
        <p className="text-sm text-gray-700">{BASIC_INFO.KEY_TERMS_CAPTION}</p>
        <div
          data-testid="brand-info-keyTerms-selected"
          className="flex flex-wrap gap-2"
        >
          {Array.from(selectedKeyTerms).map((keyTerm) => (
            <Badge
              dataTestId="brand-info-selected-key-term"
              color="gray"
              size="medium"
              key={keyTerm}
              disabled={props.disabled}
              text={keyTerm}
              truncateTextSize={20}
              onClose={() => {
                setSelectedKeyTerms((prev) => {
                  const newKeySet = new Set(prev)
                  newKeySet.delete(keyTerm)
                  return newKeySet
                })
              }}
            />
          ))}
        </div>
        {selectedKeyTerms.size > 0 && (
          <div
            data-testid="brand-info-keyTerms-clearAll-button"
            className={`p-2  rounded-md bg-blue-50 max-w-max
            ${props.disabled ? 'text-blue-300 cursor-not-allowed' : 'text-blue-700 cursor-pointer'}`}
            onClick={() => {
              !props.disabled && setSelectedKeyTerms((prev) => new Set())
            }}
          >
            {BASIC_INFO.CLEAR_KEYWORDS_LABEL}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-sm text-gray-700">{BASIC_INFO.KEY_TERMS_INPUT_CAPTION}</p>
        <input
          data-testid="brand-info-keyTerms-keywords-input"
          type="text"
          className="block w-full text-sm border-gray-300 rounded-md form-input focus:border-sightly-blue focus:ring-sightly-blue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
          value={userText}
          disabled={props.disabled}
          onChange={(e) => {
            setUserText(e.target.value)
          }}
          onBlur={handleSaveKeyTerm}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleSaveKeyTerm()
          }}
        />
      </div>
      {aiSuggestedKeyTermsSet.size > 0 && (
        <div className="flex flex-col w-full gap-4 p-4 border border-blue-300 rounded-md bg-blue-50">
          <div className="flex justify-between">
            <div className="flex items-center gap-3 ">
              <div className="p-1 rounded-md bg-gradient-to-br from-[#0047FF] via-[#5B32EF] to-[#333D47]">
                <AIIcon />
              </div>
              <span className="text-blue-800">{BASIC_INFO.KEY_TERMS_AI_CAPTION}</span>
            </div>
            <div
              data-testid="brand-info-keyTerms-addAll-button"
              className={`flex items-center px-2 bg-white rounded-md shadow-sm justify-evenly ring-1 ring-gray-300
              ${props.disabled ? 'text-gray-300 cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={() => {
                if (props.disabled) return
                setSelectedKeyTerms((prev) => new Set([...Array.from(prev), ...Array.from(aiSuggestedKeyTermsSet)]))
              }}
            >
              <span> {BASIC_INFO.ADD_ALL_LABEL}</span>
              <span className="my-auto text-2xl">+</span>
            </div>
          </div>
          <div
            data-testid="brand-info-keyTerms-suggestedList"
            className="flex flex-wrap justify-start gap-2"
          >
            {Array.from(aiSuggestedKeyTermsSet).map((keyTerm: string) => (
              <Pill
                value={keyTerm}
                handleSelect={handleSelectKeyTerm}
                key={keyTerm}
                disabled={props.disabled}
                selected={selectedKeyTerms.has(keyTerm)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
