import toast from 'react-hot-toast'
import { useQuery, useQueryClient, useMutation, useIsMutating } from '@tanstack/react-query'
import { userAccountAxios } from '@/axiosInstances'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { OpinionPatchSchemaType, OpinionType, OpinionsObjSchema } from './opinions-types'
import { logError } from '@/utils'
import { BrandProfile } from '@/classes/brandProfile'
import { products } from '@/staticData/products'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'

const getBrandProfileOpinions = async (brandProfileId: number,sortBy: 'least' | 'most') => {
    let url = `/brand-profile/${brandProfileId}/opinions?completionSort=${sortBy === 'least' ? 'asc' : 'desc'}`

    try {
        const result = await userAccountAxios.get(url)
        if (result.status === 200) {
            return OpinionsObjSchema.parse(result.data)
        }
        return []
    } catch (error) {
        logError(error)
    }
}

export const patchBrandProfileOpinions = async ({brandProfileId,opinions}: OpinionPatchSchemaType) => {
    const url = `/brand-profile/${brandProfileId}/opinions`
    await userAccountAxios.patch(url, opinions)
}

export const aiGenerateBrandProfileOpinions = async (data: {
    opinionIds: number[],
    companyName: string,
    companyInfoSummary: string,
    companyMentalitySummary: string,
    brandProfileId: number
}) => {
    const {brandProfileId, ...payload} = data
    const url = `/brand-profile/${brandProfileId}/opinions/ai-generator`
    await userAccountAxios.patch(url, payload)
}

const generateOpinions = async (args:{opinionIds: number[], brandProfileId:number, brandProfileInfo?:Partial<BrandProfile>}  ) => {
    const payload = {
        opinionIds: args.opinionIds,
        companyName: args.brandProfileInfo?.brandProfileName || '',
        companyInfoSummary: args.brandProfileInfo?.companySummary || '',
        companyMentalitySummary: args.brandProfileInfo?.companyMentality || '',
    }
    const url = `/brand-profile/${args.brandProfileId}/opinions/ai-generator`
    return await userAccountAxios.patch(url, payload)
}

const useOpinions = (brandProfileId: number,sortBy: 'least' | 'most',accountId?: number , setSelectedOpinions?:React.Dispatch<React.SetStateAction<Map<number, OpinionType>>>) => {
    const queryClient = useQueryClient()
    const QUERY_KEY = rqKeys.brandProfileOpinions(brandProfileId, sortBy)
    const { data: opinions, isLoading } = useQuery(QUERY_KEY, () => getBrandProfileOpinions(brandProfileId, sortBy), {
        enabled: !!brandProfileId
    })

    const mutateOpinions = useMutation(
        (args: OpinionPatchSchemaType) => {
            return patchBrandProfileOpinions(args)
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(QUERY_KEY)
                queryClient.invalidateQueries(rqKeys.subscriptionQuotaQueryKey(accountId, products.AI_CREDITS))
            },
            onSuccess(data, variables, context) {
                toast.success(`Opinions Response(s) Saved`)
                setSelectedOpinions && setSelectedOpinions(new Map())

            }
        }
    )

    const aiGenerateOpinionsMutation = useMutation(generateOpinions,
        {
            mutationKey: rqKeys.brandProfileOpinionsAIMutation(accountId),
            onSettled: () => {
                queryClient.invalidateQueries(QUERY_KEY)
                queryClient.invalidateQueries(mainRqKeys.subscriptionQuotaQueryKey(accountId, products.AI_CREDITS))
            },
            onSuccess(data, variables, context) {
                toast.success(`Opinions Response(s) Generated`)
                setSelectedOpinions && setSelectedOpinions(new Map())
            }
        }
    )
    
    const isAIGeneratingBrandOpinion = useIsMutating({
        mutationKey: rqKeys.brandProfileOpinionsAIMutation(accountId),
      })

    return {
        opinions,
        opinionsIsLoading: isLoading,
        handleOpinionChangeMutation: mutateOpinions,
        aiGenerateOpinionsMutation,
        isAIGeneratingBrandOpinion
    }
}

export default useOpinions
