import { useState } from 'react'
import AlertTailwind from '../components/AlertTailwind'
import React from 'react'
export const useAlert = () => {
    const [showAlert, setShowAlert] = useState<boolean>(false)

    React.useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false)
            }, 3000)
        }
    }, [showAlert])
    return {
        showAlert: () => setShowAlert(true),
        renderAlert: () => {
            return (
                <AlertTailwind
                    setShow={setShowAlert}
                    show={showAlert}
                    message={''}
                />
            )

            // <MyAlert
            //           visible={error !== undefined}
            //           body={error} onDismiss={() => setError(undefined)} />
        }
    }
}
