import React from 'react'
import useAssignees from '@/views/Discover/Moments/v2/TargetMoment/hooks/useAssignees'
import { MyLocationGenerics } from '@/classes/utils'
import { useSearch } from '@tanstack/react-location'
import { CheckPicker, Icon } from 'rsuite'
import useUser from '@/hooks/useUser'

function Assignees(props: { assignees: string[]; setAssignees: React.Dispatch<React.SetStateAction<string[]>> }) {
  const { accountId } = useSearch<MyLocationGenerics>()
  const { assigneesQuery } = useAssignees(accountId)
  const { assignees, setAssignees } = props
  const { user } = useUser()

  return (
    <div
      className="px-4 pt-6 sm:px-6 text-grey-900"
      data-testid="act-mgr-target-moments-table-assignees-check-picker"
    >
      <div className="mb-2 font-medium" id='pendo-act-mgr-assignees-filter'>Assignees</div>
      <CheckPicker
        data={assigneesQuery.data || []}
        className={`${(assignees.length < 1) && 'rounded-md ring-1 ring-red-600'}`}
        renderMenu={(menu: React.ReactNode) => {
          if (!assigneesQuery.data) {
            return (
              <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                <Icon
                  icon="spinner"
                  spin
                />{' '}
                Loading...
              </p>
            )
          }
          if(assigneesQuery.data.length === 0){
            return (
              <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                No Results Found
              </p>
            )
          }
          return menu
        }}
        searchable={!!assigneesQuery.data}
        labelKey="fullName"
        valueKey="email"
        // grouping the users only for Internal users
        groupBy={user?.userType === 'Internal' ? 'userType' : undefined}
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Assignees"
        block
        value={assignees}
        onChange={(assigneesList: string[]) => {
          setAssignees((prev: string[]) => assigneesList)
        }}
        onClean={() => {
          setAssignees((prev: string[]) => [])
        }}
      />
    </div>
  )
}

export default Assignees
