import { listBuilderAxios } from "@/axiosInstances"
import { INarrative, narrativeSchema } from "@/views/Trends/types"

export const narratives = {
    get: async (offset: number, limit: number, minPublishDate: String, 
      search?: string) => {
      let url = `/narratives?offset=${offset}&limit${limit}&minPublishDate${minPublishDate}`
      if (search) url += `&search=${search}`

      return listBuilderAxios.get<INarrative[]>(url)
    },
    getById: async (narrativeId: number) => {
      const url = `/narratives/${narrativeId}`
      const result = await listBuilderAxios.get<INarrative>(url)
      return narrativeSchema.parse(result.data)
    }
}
