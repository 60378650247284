export const SUBMITTING_ACTIVATIONS = 'Submitting Activations...'
export const ACTIVATIONS_TABLE_CATEGORY_COL_CAPTION = 'Category'
export const ACTIVATIONS_TABLE_CAMPAIGN_COL_CAPTION = 'Campaign'
export const ACTIVATIONS_TABLE_HASHTAG_COL_CAPTION = 'Hashtag'
export const ACTIVATIONS_TABLE_AD_GROUP_COL_CAPTION = 'Ad Group'
export const SELECTION_DROP_DOWN_WIDTH = 280
export const BULK_ASSIGN_TITLE = 'Assign Campaigns and Ad Groups'
export const DISABLED_CREATE_BUTTON = {
  isActivationLoading: 'Activations are being submitted.',
  invalidActivationList: 'One of the campaigns are not assigned either keyword or video ad groups.'
}
export const ACTIVATION_STATUS_MESSAGE = {
  SUCCESS: (count: number) => `${count} Hashtags have been successfully submitted to your campaign.`,
  FAILURE: 'We were unable to successfully add your hashtags to your campaign. Please try again.',
  PARTIAL_FAILURE: (count: number) =>
    `${count} Hashtags have not been successfully submitted to your campaign. Please try again.`
}
