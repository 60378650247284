import React from 'react'
import MomentArticle from './MomentArticle'
import { ClusterType, MomentArticleType } from './../types'
import { LoadingPage } from '@/components/LoadingPage'
import { useFlag } from '@unleash/proxy-client-react'
import SightlyButton from '@/components/Sightly/SightlyButton'

interface TrendArticlesProps {
    moment: ClusterType
    articles: MomentArticleType[]
    isLoading: boolean
}

interface TrendArticlesProps {
    moment: ClusterType
    articles: MomentArticleType[]
    isLoading: boolean;
    page: number;
    pageSize: number;
    totalItems: number;
    handlePageChange: (newPage: number) => void;
}

const MomentArticles = ({ moment, isLoading, articles, page, pageSize, totalItems, handlePageChange }: TrendArticlesProps) => {
    const currentCount = articles.length;
    const loadMoreFlag = useFlag('enable_load_more');
    return (
        <div
            data-testid="moment-modal-body-articles"
            className="w-full flex flex-col items-center"
        >
            {articles?.length === 0 && !isLoading && (
                <div
                    data-testid="moment-modal-articles-noarticle-message"
                    className="h-7 w-fit pr-2.5 rounded-sm bg-[#F2FAFD] pt-0.5 pl-2.5 font-medium text-sm leading-6 mt-6"
                >
                    Currently we have no articles for this Moment.
                </div>
            )}
             {loadMoreFlag && (
                    <>
                    {moment?.articleCount > 0 && !isLoading && (
                        <div className="flex justify-end h-7 pr-2 rounded-md pt-1 pl-2 font-medium text-sm leading-[22px]">
                             Showing {currentCount} of {totalItems} articles.
                        </div>
                    )}
                    </>
                )} 

                {!loadMoreFlag && (
                    <>
                        {moment?.articleCount > 5 && !isLoading && (
                         <div
                            data-testid="moment-modal-articles-count"
                            className="h-7 w-fit pr-2.5 rounded-sm bg-[#F2FAFD] pt-0.5 pl-2.5 font-medium text-sm leading-6 mt-6"
                        >
                         Showing 5 of {moment?.articleCount} articles. To see all articles, please download the moment.
                        </div>
                        )}
                    </>
                )} 
            <div
                id="articlesTab"
                style={{
                    overflowY: 'auto',
                    marginTop: 24,
                    textAlign: 'left'
                }}
            >
                {isLoading && articles.length == 0  ? (
                    <LoadingPage message="Fetching articles" />
                ) : (
                    <>
                       {articles.map((article, index) => (
                                 <MomentArticle
                                 key={index + article.url}
                                 article={article}
                             />
                        ))}
                    </>
                )}
            </div>
            {articles.length != 0 && loadMoreFlag &&  (
                <div className="flex justify-center w-[540px] py-4">
                    <SightlyButton
                        datatestid="load-more-moments-button"
                        block
                        id="loadmorebutton"
                        text="Load More"
                        handleClick={() => handlePageChange(page + 1)}
                        loading={isLoading}
                        disabled={page >= Math.ceil(totalItems / pageSize)}
                    />
                </div>
            )}
        </div>
        
    )
}

export default MomentArticles
