import React from 'react';
import { ReactComponent as NoQueries } from '@/assets/img/noActionJustifications.svg';
import { CategoryActionJustificationType } from '../../types';
import { ActionBadge } from '@/views/Discover/Moments/v2/components/ActionBadge';
import { BrandProfileLink } from '@/views/Discover/Moments/v2/components/BrandProfileLink';
import { LoadingPage } from '@/components/LoadingPage';
import { ReactComponent as GarmFlagIcon } from '@/assets/img/GarmFlagIcon.svg';

interface IProps {
  brandProfileId: number | undefined
  isLoading: boolean
  garmActions: CategoryActionJustificationType[] | undefined
  categories: CategoryActionJustificationType[] | undefined
}

export const UnsafeContentSection = ({ brandProfileId, garmActions, categories, isLoading }: IProps) => {
  const target = garmActions ? garmActions?.filter(category => category.action === 'target') : [];
  const block = garmActions ? garmActions?.filter(category => category.action === 'block') : [];
  const monitor = garmActions ? garmActions?.filter(category => category.action === 'monitor') : [];
  const noaction = garmActions ? garmActions?.filter(category => category.action === 'no action' || category.action === 'noaction') : [];

  const getActionsSection = (label:string, queries:CategoryActionJustificationType[]) => (
    <div className="pb-4">
      <div className="pb-1 font-bold text-gray-400">{label}</div>
      <div className="flex flex-wrap">
        {queries?.map((data, index) => (
          <ActionBadge key={index} action={data.action} label={data.category}/>
        ))}
      </div>
    </div>
  )

  const noQueries =
    <div
      data-testid="div-moment-garm-no-unsafe-actions"
      className="grid justify-items-center p-16 text-center"
    >
      <NoQueries data-testid="no-unsafe-actions-icon"/>
      <h5>No Sensitive Topics</h5>
      <div className="justify-self-center max-h-100 overflow-auto">There are no sensitive topics from your brand profile matching this moment</div>
    </div>

  const hasActions =
    <div
      data-testid="div-moment-has-unsafe-actions"
      className="h-full overflow-auto"
    >
      {target?.length > 0 && getActionsSection('Targeted Content', target)}
      {block?.length > 0 && getActionsSection('Blocked Content', block)}
      {monitor?.length > 0 && getActionsSection('Monitored Content', monitor)}
      {noaction?.length > 0 && getActionsSection('Undecided Content', noaction)}
    </div>

  const actionsComponent = garmActions?.length === 0 ? noQueries : hasActions;

  return (
    <div
      data-testid="div-moment-unsafe-content"
      className="p-4 bg-white rounded-xl mb-4"
    >
      <div className="flex justify-between">
        <h4 className="pb-2">
          <GarmFlagIcon className="inline mr-2" />
          Sensitive Topics
        </h4>
        <BrandProfileLink dataTestId="brand-profile-safeguard-actions-link" brandProfileId={brandProfileId} section="opinions"/>
      </div>
      <p className="mb-3 text-gray-400 font-bold">
        This moment contains sensitive topics:
      </p>
      <div>
        {isLoading
          ? <LoadingPage message="Loading" />
          : actionsComponent
        }
      </div>
    </div>
  )
}
