import React from "react";
import {accentColor, brandPinkColor, purpleSecondaryColor} from "@/assets/jss/colorConstants";

export const ActionBox = ({ text }: { text: string }) => {
    const _bgColor = React.useMemo(() => {
        if (text === 'monitor') return purpleSecondaryColor
        if (text === 'target') return accentColor
        if (text === 'block') return brandPinkColor
        if (text.toLowerCase() === 'no action') return 'slategrey'
        if (text === 'dont monitor') return 'grey'
        return 'white'
    }, [text])

    return (
        <div
            data-testid="moment-modal-moment-action"
            style={{
                width: 78.49,
                    height: 33.15,
                    borderRadius: 3.05,
                    paddingTop: '5px',
                    textAlign: 'center',
                    backgroundColor: _bgColor,
                    textTransform: 'capitalize',
                    color: 'white',
                    fontWeight: 600,
                    fontSize: 12.18,
                    lineHeight: '22.34px',
                    marginTop: 8,
                    marginBottom: 8
            }}
>
    {text}
    </div>
)
}
