import React from "react";
import { NoSymbolIcon, ScaleIcon } from "@heroicons/react/20/solid";
import { ReactComponent as MonitorIcon } from '@/assets/img/monitor.svg';
import { ReactComponent as TargetIcon } from '@/assets/img/target.svg';

interface IProps {
    type: string
}

export const ActionBadgeWithIcon = ({ type }: IProps) => {
    const typeName = type.toLowerCase();
    let bgColor: any;
    let textColor: any;
    let icon;

    if (typeName === 'block') {
        bgColor = '#FBBEBE';
        textColor = '#EA430F';
        icon = (
          <NoSymbolIcon data-testid="no-symbol-icon" className="-ml-0.5 mr-1.5 h-3 w-3" />
        );
    } else if (typeName === 'monitor') {
        bgColor = '#EBE7FC';
        textColor = '#5B32EF';
        icon = (
          <MonitorIcon data-testid="monitor-icon" className="-ml-0.5 mr-1.5 h-3 w-3" />
        );
    } else if (typeName === 'target') {
        bgColor = '#BFE1FB';
        textColor = '#26AAE1';
        icon = (
          <TargetIcon data-testid="target-icon" className="-ml-0.5 mr-1.5 h-3 w-3" />
        );
    } else if (typeName === 'no action' || typeName === 'noaction') {
        bgColor = '#F3F4F6';
        textColor = '#1F2937';
        icon = (
          <ScaleIcon data-testid="scale-icon" className="-ml-0.5 mr-1.5 h-3 w-3" />
        );
    } else {
        bgColor = '#f1f2f7';
        textColor = '';
        icon = '';
    }

    return (
      <div
        data-testid="action-badge-with-icon"
        style={{ backgroundColor: bgColor, color: textColor }}
        className="capitalize flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800 ml-2"
      >
          {icon}
          {type}
      </div>
    )
}
