import React from 'react'
import { Control, UseFormRegister } from 'react-hook-form'
import SightlyInputForm from "@/components/Sightly/SightlyFormElements/SightlyInputForm";
import { WIKIPEDIA_PREFIX } from '../constants'

interface IProps {
    control: Control<any>
    disabled?: boolean
    name: string
    error?: string
    register: UseFormRegister<any>
}

const WikiInputForm = ({ control, disabled, name, error, register }: IProps) => {
    return (
        <SightlyInputForm
            dataTestId={'input-wiki-url'}
            control={control}
            error={error}
            name={name}
            register={register}
            id="WikipediaLink"
            label="Wikipedia Link"
            disabled={disabled}
            prefix={WIKIPEDIA_PREFIX}
        />
    )
}

export default WikiInputForm
