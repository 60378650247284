import React from 'react';
import { Section } from "@/views/OnboardingHub/components/Section";
import { TeamSuccess } from "@/views/OnboardingHub/GettingStarted/TeamSuccess";
import { VideoResources } from "@/views/OnboardingHub/GettingStarted/VideoResources";
import { BrandProfile } from "@/classes/brandProfile";
import { UserCompletionStep } from '@/schemas/schemas';

type GettingStartedProps =  {
  headerName: string
  submittedBrandProfiles: BrandProfile[] | undefined
  completedSteps: UserCompletionStep[] | undefined
  postUserCompletionStep: Function
  refetchUserCompletionSteps: Function
}

export const GettingStarted = ({ headerName, submittedBrandProfiles, completedSteps, postUserCompletionStep, refetchUserCompletionSteps }: GettingStartedProps) => {
  return (
    <Section dataTestId="getting-started" headerName={headerName}>
      <div className="bg-white rounded-md border">
        <TeamSuccess submittedBrandProfiles={submittedBrandProfiles} />
        <VideoResources
          completedSteps={completedSteps}
          postUserCompletionStep={postUserCompletionStep}
          refetchUserCompletionSteps={refetchUserCompletionSteps}
        />
      </div>
    </Section>
  )
}
