import React from 'react'
import { Loader } from 'rsuite'
import { Link, useNavigate, useSearch } from '@tanstack/react-location'

import { MyLocationGenerics } from '@/classes/utils'
import { logError } from '@/utils'
import { InfoPage } from '@/components/InfoPage'
import { MessageTailwind } from '@/components/MessageTailwind'
import PortalModal from '@/components/PortalModal/PortalModal'
import { useTodoActivations } from '@/views/Discover/Activations/v2/hooks/activations-hooks'
import useAssignees from '@/views/Discover/Moments/v2/TargetMoment/hooks/useAssignees'
import ToolBar from '@/views/Discover/Activations/v2/ToDo/ToolBar'
import useUser from '@/hooks/useUser'
import ActivationsTable from '@/views/Discover/Activations/v2/ActivationsTable/index'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'
import { ActivationsManager } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import { useStoredTargetedActivations } from '@/views/Discover/Activations/v2/ActivationsManager/useStoredTargetedActivations'
import { ActivationManagerProvider } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'
import useIos from '@/views/Discover/Moments/v2/TargetMoment/hooks/useIO'
import { useFlag } from '@unleash/proxy-client-react'
import config from '@/config'
import { groupByInsertionOrder } from '@/views/Discover/Activations/v2/utils'


interface IProps {
  defaultBrandProfileId: number | undefined
  handleDownloadActivations: Function
}

type InsertionOrderData = {
  insertionOrderId: number;
  insertionOrderName: string;
};

const getStoredSelectedActivations = () => {
  const storedSelectedMoments = sessionStorage.getItem('selectedToDoActivations')
  return storedSelectedMoments && storedSelectedMoments !== 'undefined' && JSON.parse(storedSelectedMoments)
}

const getDefaultSelectedActivations = (brandProfileId: number | undefined): number[] => {
  const selectedActivationsAll = getStoredSelectedActivations()
  return (brandProfileId && selectedActivationsAll && selectedActivationsAll[brandProfileId]) || []
}

export const Todo = ({ defaultBrandProfileId, handleDownloadActivations }: IProps) => {
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const enableIoFilter = useFlag(`enable_new_activation_mgr_${config.environment}`);

   const getLast60Days = (): [Date, Date] => {
    const now = new Date()
    const sixtyDaysAgo = new Date(now)
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    return [sixtyDaysAgo, now]
}


  const startingRange = getLast60Days();
  const [customStartDate, setCustomStartDate] =  React.useState<Date>(startingRange[0]);
  const [customEndDate, setCustomEndDate] =  React.useState<Date>(startingRange[1]);

  const [insertionOrderId, setInsertionOrderId] = React.useState<string[]>([])
  
  const { startDate, endDate } = React.useMemo(() => {
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    startDate = customStartDate || today;
    endDate = customEndDate || today;
    if (startDate > endDate) {
      endDate = startDate;
    }
  
    return { startDate, endDate };
  }, [customStartDate, customEndDate]);

  const handleDateRangeChange = (value: any) => {
    if (value && value.length === 2) {
      const [start, end] = value;
      setCustomStartDate(start);
      setCustomEndDate(end);
    }
  };

  const handleTimeRangeChange = () => {
    setCustomStartDate(startingRange[0]);
    setCustomEndDate(startingRange[1]);
  };


  const { syncActivationListWithTodo } = useStoredTargetedActivations()
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!brandProfileId && defaultBrandProfileId) {
      navigate({
        search: (old) => {
          return {
            ...old,
            brandProfileId: defaultBrandProfileId
          }
        }
      })
    }
  }, [])

  const [selectedActivationIds, setSelectedActivationIds] = React.useState<number[]>(
    getDefaultSelectedActivations(defaultBrandProfileId ?? brandProfileId)
  )
  const [isEditOpen, setIsEditOpen] = React.useState(false)
  const { activationsQuery, removeItemFromTodo, bulkDeleteFromTodo } = useTodoActivations({
    brandProfileId: Number(brandProfileId),
    startDate,
    endDate,
    insertionOrderId
  })
  const [downloadingActivations, setDownloadingActivations] = React.useState(false)
  const [deletingActivations, setDeletingActivations] = React.useState(false)
  const [activationToBeDeleted, setActivationToBeDeleted] = React.useState<number | null>(null)
  const [isBulkDelete, setIsBulkDelete] = React.useState<boolean>(false)

  const [showTooManyActivationsError, setShowTooManyActivationsError] = React.useState(false)

  const [searchedMomentsIds, setSearchedMomentsIds] = React.useState<number[]>()
  const { user } = useUser()

  const [assignees, setAssignees] = React.useState<string[]>([])

  React.useEffect(() => {
    let selectedActivationsAll = getStoredSelectedActivations()
    // Merges previous stored value with new selectedMoments
    if (selectedActivationsAll && brandProfileId) {
      const prevSelectedActivations: number[] = selectedActivationsAll[brandProfileId]
      if (prevSelectedActivations) {
        if (!selectedActivationIds) {
          setSelectedActivationIds(() => prevSelectedActivations)
        } else {
          selectedActivationsAll[brandProfileId] = [...selectedActivationIds]
        }
      } else {
        selectedActivationsAll[brandProfileId] = selectedActivationIds
      }
    } else
      selectedActivationsAll = (brandProfileId && { [brandProfileId]: selectedActivationIds }) || selectedActivationsAll

    let selectedActivationsSet: Set<number>
    if (brandProfileId) {
      selectedActivationsSet = new Set(selectedActivationsAll[brandProfileId])
      selectedActivationsAll[brandProfileId] = Array.from(selectedActivationsSet)
    }
    syncActivationListWithTodo()
    sessionStorage.setItem('selectedToDoActivations', JSON.stringify(selectedActivationsAll))
  }, [selectedActivationIds])

  React.useEffect(() => {
    if (customStartDate && customEndDate) {
      activationsQuery.refetch();
    }
  }, [customStartDate, customEndDate])

  React.useEffect(() => {
    if (insertionOrderId) {
      activationsQuery.refetch();
    }
  }, [insertionOrderId])

  const allMomentsOnPageAreSelected = React.useMemo(() => {
    if (selectedActivationIds?.length === 0) {
      return false
    }

    const notIncludedInSelectedItems = activationsQuery.data
      ?.map(({ clusterId }) => clusterId)
      ?.filter((id) => !selectedActivationIds?.includes(id))
    if (notIncludedInSelectedItems === undefined) {
      return true
    }
    if (notIncludedInSelectedItems.length > 0) {
      return false
    }
    return true
  }, [activationsQuery.data, selectedActivationIds])

  const { accountId } = useSearch<MyLocationGenerics>()
  const { assigneesQuery } = useAssignees(accountId)

  const iosQuery = enableIoFilter
    ? useIos(brandProfileId, 'active', startDate, endDate).iosQuery
    : { data: [] as InsertionOrderData[], isLoading: false, isError: false };

  const activationsList = React.useMemo(() => {
    if (!activationsQuery.data) return []
    const filteredList = activationsQuery.data.filter((activation) => {
      const hasPersonas = activation.personas.length > 0
      const hasFilterAssigneeValue =
        assignees.length <= 0
          ? true
          : activation.assignees.some((assigneeEmail) => assignees.includes(assigneeEmail.toLowerCase()))
      const hasSearchedValue = searchedMomentsIds?.includes(activation.clusterId) ?? true

      return hasFilterAssigneeValue && hasSearchedValue && hasPersonas
    })
    return filteredList
  }, [activationsQuery.data, assignees, searchedMomentsIds, brandProfileId])

  const activationsGroupedByInsertionOrder = React.useMemo(
    () => groupByInsertionOrder(activationsList),
    [activationsList]
  )
  const defaultExpandedInsertionOrder =
    activationsGroupedByInsertionOrder.size > 0
      ? new Set([Array.from(activationsGroupedByInsertionOrder.keys())[0]])
      : null
      
  const [expandedInsertionOrder, setExpandedInsertionOrder] = React.useState<Set<number> | null>(
    defaultExpandedInsertionOrder
  )

  const ioIdsSize = React.useMemo(() => {
    const allIOIds:Set<number> = new Set(activationsList?.map((a) => a.insertionOrderId) || [])
    return allIOIds.size
  }, [activationsList])
  
  const handleExpandAll = () => {
    const allIOIds:Set<number> = new Set(activationsList?.map((a) => a.insertionOrderId) || [])
    if (expandedInsertionOrder === null || (expandedInsertionOrder && expandedInsertionOrder?.size < ioIdsSize)) {
      setExpandedInsertionOrder(allIOIds)
    } else {
      setExpandedInsertionOrder(new Set())
    }
  }
  
  const expandAllIO = () => {
    const allIOIds:Set<number> = new Set(activationsList?.map((a) => a.insertionOrderId) || [])
    setExpandedInsertionOrder(allIOIds)
  }
  
  const collapseAllIO = () => {
    setExpandedInsertionOrder(new Set([]))
  }

  const assigneesOptions = React.useMemo(() => {
    if (!assigneesQuery.data) return []

    const assigneeDetailLookup = new Map(
      assigneesQuery.data.map(({ email, fullName }) => [email.toLowerCase(), fullName])
    )
    const assigneesEmails =
      activationsQuery.data
        ?.filter((activation) => {
          if (searchedMomentsIds?.length) {
            return searchedMomentsIds.includes(activation.clusterId)
          }
          return true
        })
        .flatMap(({ assignees }) => assignees) ?? []
    const uniqueAssigneesEmails = Array.from(new Set(assigneesEmails))

    const options = uniqueAssigneesEmails.map((email) => {
      const lowerCaseEmail = email.toLowerCase()
      const fullName = assigneeDetailLookup.get(lowerCaseEmail) ?? lowerCaseEmail
      return { email: lowerCaseEmail, fullName }
    })
    return options
  }, [activationsQuery.data, assigneesQuery.data, searchedMomentsIds])

    const insertionOrderOptions = React.useMemo(() => {
      const { data, isLoading, isError } = iosQuery;
    
      if (isLoading) return [];
      if (isError || !data) return [];
    
      const insertionOrderLookup = new Map(
        data.map(({ insertionOrderId, insertionOrderName }) => [insertionOrderId, insertionOrderName])
      );
      const insertionOrderIds = data.map(({ insertionOrderId }) => insertionOrderId);
      const uniqueInsertionOrderIds = Array.from(new Set(insertionOrderIds));
      const options = uniqueInsertionOrderIds.map((id) => {
        const insertionOrderName = insertionOrderLookup.get(id) ?? `ID: ${id}`;
        return { id, name: insertionOrderName };
      });
    
      return options;
    }, [iosQuery.data, iosQuery.isLoading, iosQuery.isError]);

  const isUserInAssigneesOptions = React.useMemo(
    () => assigneesOptions.some((option) => option.email === user?.email),
    [assigneesOptions, user]
  )

  React.useEffect(() => {
    /* 
    As assigneeOptions is dynamic value which changes with the search result,
    !searchedMomentsIds : ensures that we are not setting default assignee when the search is active.
    !deletingActivations : ensures that deleting activations don't trigger the default assignee 
    */
    const canCurrentUserBeDefaultAssignee =
      user && isUserInAssigneesOptions && !deletingActivations && !searchedMomentsIds
    setAssignees((prev) => (canCurrentUserBeDefaultAssignee ? [user?.email] : prev))
    setDeletingActivations(() => false)
  }, [assigneesOptions])

  React.useEffect(() => {
    setAssignees(() => (user && isUserInAssigneesOptions ? [user?.email] : []))
    setSearchedMomentsIds(() => undefined)
  }, [brandProfileId])

  const handleSearchMoments = React.useCallback(
    (searchKey: string) => {
      try {
        if (!activationsQuery.data) return;
  
        const searchResult = activationsQuery.data?.filter(({  clusterName, insertionOrderName }) =>
          clusterName.toLowerCase().includes(searchKey.toLowerCase()) ||
          insertionOrderName.toLowerCase().includes(searchKey.toLowerCase())
        );
        if (searchKey === "") collapseAllIO()
        else expandAllIO();
        setSearchedMomentsIds(searchResult?.map(({ clusterId }) => clusterId) ?? []);
      } catch (error) {
        logError(error);
      }
    },
    [brandProfileId, activationsQuery.data]
  );

  const handleBulkDelete = () => {
    setDeletingActivations(() => true)
    bulkDeleteFromTodo.mutate({ activationListItemIds: new Set(selectedActivationIds), brandProfileId })
    setSelectedActivationIds(() => [])
    setIsBulkDelete(false)
  }

  const handleDeleteActivationsGroupedByIo = (activationsToBeDeleted:number[]) => {
    setDeletingActivations(() => true)
    bulkDeleteFromTodo.mutate({ activationListItemIds: new Set(activationsToBeDeleted), brandProfileId })
  }

  const handleDownload = () => 
    handleDownloadActivations({
      brandProfileId,
      setDownloadingTrends: setDownloadingActivations,
      setShowTooManyTrendsError: setShowTooManyActivationsError,
      selectedMomentIds:
        activationsList.filter((i) => selectedActivationIds?.includes(i.activationItemId)).map((i) => i.clusterId) ||
        [],
      activationState: 'active'
    })

  const areBulkOpsDisabled =
    (selectedActivationIds && selectedActivationIds?.length < 1) ||
    activationsList.length === 0 ||
    activationsQuery.isLoading

  const handleDelete = () => {
    if (!activationToBeDeleted) return
    
    removeItemFromTodo.mutate({
      brandProfileId,
      activationListItemId: activationToBeDeleted
    })
    setDeletingActivations(() => true)
    setActivationToBeDeleted(() => null)
  }

  const handleCancelDeleteMoment = () => setActivationToBeDeleted(() => null)
  const handleCancelBulkDeleteMoment = () => setIsBulkDelete(false)

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedActivationIds(() => activationsList?.map((i) => i.activationItemId) || [])
    } else {
      setSelectedActivationIds([])
    }
  }

  const handleSelect = (checked: boolean, activationItem: ActivationItemType) => {
    const newItems = checked
      ? selectedActivationIds?.concat(activationItem.activationItemId)
      : selectedActivationIds?.filter((id) => id !== activationItem.activationItemId)
    setSelectedActivationIds(newItems)
  }

  const selectedActivationsList = React.useMemo(() => {
    return (
      activationsQuery.data?.filter((activations) => selectedActivationIds?.includes(activations.activationItemId)) ??
      []
    )
  }, [activationsQuery.data, selectedActivationIds])

  const activationsGroupedByPersonas = React.useMemo(() => {
    const groupedActivations = new Map<string, ActivationItemType[]>()
    if (!selectedActivationsList.length) return groupedActivations
    selectedActivationsList.forEach((activation) => {
      activation.personas.forEach((persona) => {
        if (groupedActivations.has(persona)) {
          const relatedActivations = groupedActivations.get(persona) ?? []
          if (
            relatedActivations.length > 0 &&
            relatedActivations.find(({ activationItemId }) => activation.activationItemId === activationItemId)
          ) {
            return
          }
          groupedActivations.set(persona, [...relatedActivations, activation])
          return
        }
        groupedActivations.set(persona, [activation])
      })
    })
    return groupedActivations
  }, [activationsQuery.data, selectedActivationIds])

  if (activationsQuery.isLoading && !assigneesOptions.length) {
    return (
      <div className="absolute top-1/2 left-1/2">
        <Loader
          content="Fetching Activations..."
          className="z-10"
          speed="slow"
        />
      </div>
    )
  }

  return (
    <div
      data-testid="activations-todo-table"
      className="flex flex-col mt-8 fade-in animate-in"
    >
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
            <ToolBar
            {...{
              handleSearchMoments,
              assigneesOptions,
              assignees,
              setAssignees,
              insertionOrderOptions,
              insertionOrderId,
              setInsertionOrderId,
              brandProfileId,
              setIsEditOpen,
              handleTimeRangeChange,
              handleDateRangeChange,
              customStartDate,
              customEndDate,
              handleBulkDelete: () => setIsBulkDelete(true),
              handleDownload,
              areBulkOpsDisabled
            }}
            />
          {downloadingActivations && (
            <div className="flex justify-between w-full h-10 mb-10">
              <div>
                <MessageTailwind
                  show={downloadingActivations}
                  message="Downloading moments"
                  type="loading"
                />
              </div>
            </div>
          )}
          {activationsQuery?.data && activationsQuery?.data?.length === 0 && activationsQuery.isFetched ? (
              <div className="w-full h-80">
                <InfoPage
                  message={
                    <div>
                      <span>
                        No items found.{' '}
                        <span>
                          <Link to={'/app/discover/moments/v2'}>Click here</Link>
                        </span>{' '}
                        to add some
                      </span>
                    </div>
                  }
                />
              </div>
          ) : <ActivationsTable
            {...{
              activationsList,
              handleDelete: (id: number) => setActivationToBeDeleted(id),
              handleDeleteActivationsGroupedByIo,
              handleSelect,
              handleSelectAll,
              selectedActivationIds,
              setSelectedActivationIds,
              allMomentsOnPageAreSelected,

              activationsGroupedByInsertionOrder,
              expandedInsertionOrder,
              setExpandedInsertionOrder,              
              ioIdsSize,
              handleExpandAll,
            }}
          /> }
          <ConfirmationDialog
            show={!!activationToBeDeleted}
            onHide={handleCancelDeleteMoment}
            handleConfirm={handleDelete}
            handleCancel={handleCancelDeleteMoment}
            body={'Are you sure you want to remove this moment?'}
            action="Delete"
          />
          <ConfirmationDialog
            show={isBulkDelete}
            onHide={handleCancelBulkDeleteMoment}
            handleConfirm={handleBulkDelete}
            handleCancel={handleCancelBulkDeleteMoment}
            body={`Are you sure you want to remove ${selectedActivationIds?.length} moments?`}
            action="Delete"
          />
          <PortalModal
            hasClose
            open={showTooManyActivationsError}
            handleClose={() => setShowTooManyActivationsError(false)}
            footerButtons={[]}
          >
            <div className="w-[500px] p-6 flex flex-col gap-12">
              <h4>Unable to download</h4>
              <div>
                Our system can only handle downloading 1,000 moments maximum. Please add more filters and try again.
              </div>
            </div>
          </PortalModal>
        </div>
      </div>
      {isEditOpen && (
        <ActivationManagerProvider activationsGroupedByPersonas={activationsGroupedByPersonas}>
          <ActivationsManager
            activationsGroupedByPersonas={activationsGroupedByPersonas}
            activationsToIoIds={
              new Map(selectedActivationsList.map(({ activationItemId, insertionOrderId }) => [activationItemId, insertionOrderId]))
            }
            open={isEditOpen}
            setOpen={setIsEditOpen}
            setSelectedActivationIds={setSelectedActivationIds}
          />
        </ActivationManagerProvider>
      )}
    </div>
  )
}
