import React from 'react'
import { useSearch } from '@tanstack/react-location'

import SlideOver, { slideOverPlacements } from '@/components/SlideOver'
import SearchBox from '@/components/SearchBox'
import { MyLocationGenerics } from '@/classes/utils'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import {
  ACTIVATIONS_MANAGER_DESCRIPTION,
  ACTIVATIONS_MANAGER_TITLE,
  DISABLED_CREATE_BUTTON,
  SUBMITTING_ACTIVATIONS
} from '@/views/Discover/Activations/v2/ActivationsManager/constants'
import { ActivationItemType, PatchActivationsItemType } from '@/views/Discover/Activations/v2/activations-types'
import { Footer } from '@/views/Discover/Activations/v2/ActivationsManager/Footer/Footer'
import ActivationsTable from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/ActivationsTable'
import { PersonaFilter } from '@/views/Discover/Activations/v2/ActivationsManager/PersonaFilter'
import { useSubmitTargetActivations } from '@/views/Discover/Activations/v2/hooks/activations-hooks'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'
import { mergeDuplicates } from '@/views/Discover/Activations/v2/ActivationsManager/activationManager.helper'
import { Loader } from 'rsuite'

type ActivationsManagerProps = {
  activationsGroupedByPersonas: Map<string, Array<ActivationItemType>>
  open: boolean
  setOpen: (val: boolean) => void
  activationsToIoIds: Map<number, number>
  setSelectedActivationIds: React.Dispatch<React.SetStateAction<number[]>>
}

export type AdGroupsType = {
  keywordAdGroups: SerializableSet<number>
  videoAdGroups: SerializableSet<number>
}

export type TargetedActivationListType = {
  activationItemId: number
  clusterName: string
  campaignsToAdGroups: SerializableMap<number, AdGroupsType>
}

export const ActivationsManager = ({
  activationsGroupedByPersonas,
  open,
  setOpen,
  activationsToIoIds,
  setSelectedActivationIds
}: ActivationsManagerProps) => {
  const [disabledCreateButtonReasons, setDisabledCreateButtonReason] = React.useState<Set<string>>()
  const [personaFilters, setPersonaFilters] = React.useState<string[]>([])
  const [searchedMomentsIds, setSearchedMomentsIds] = React.useState<number[]>([])
  const { brandProfileId } = useSearch<MyLocationGenerics>()
  const targetedActivationList = useTargetedActivationList()

  const submitTargetActivations = useSubmitTargetActivations({ brandProfileId, setOpen, setSelectedActivationIds })

  const handleFilterPersonas = (personaFilters: string[]) => {
    setPersonaFilters(() => personaFilters)
  }

  // Returns the filteredByPersona
  const filteredActivationsByPersonas = React.useMemo(() => {
    if (!personaFilters || personaFilters.length === 0) return activationsGroupedByPersonas

    const filteredActivations = new Map<string, Array<ActivationItemType>>()
    personaFilters.forEach((persona: string) => {
      const activations = activationsGroupedByPersonas.get(persona)
      if (activations) {
        filteredActivations.set(persona, activations)
      }
    })
    return filteredActivations
  }, [activationsGroupedByPersonas, personaFilters])

  const personasFilterOptions = React.useMemo(() => {
    return Array.from(activationsGroupedByPersonas.keys()).map((persona: string) => ({
      personaId: persona,
      personaName: persona
    }))
  }, [activationsGroupedByPersonas])

  const handleSearchMoments = (searchKey: string) => {
    let activationItemIds = new Set<number>()
    filteredActivationsByPersonas.forEach((activations) => {
      activations.forEach(({ clusterName, activationItemId }) => {
        if (clusterName.toLowerCase().includes(searchKey.toLowerCase())) {
          activationItemIds.add(activationItemId)
        }
      })
    })
    setSearchedMomentsIds(() => Array.from(activationItemIds ?? []))
  }

  const disableCreateButton = React.useMemo(() => {
    if (submitTargetActivations.isLoading) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add(DISABLED_CREATE_BUTTON.isActivationLoading)
        return next
      })
      return true
    } else {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.delete(DISABLED_CREATE_BUTTON.isActivationLoading)
        return next
      })
    }

    const isActivationListValid = Array.from(targetedActivationList.state.values()).every((targetActivations) => {
      return targetActivations.every(({ campaignsToAdGroups }) => {
        return Array.from(campaignsToAdGroups.values()).every(
          ({ keywordAdGroups, videoAdGroups }) => videoAdGroups.size || keywordAdGroups.size
        )
      })
    })
    if (!isActivationListValid) {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.add(DISABLED_CREATE_BUTTON.invalidActivationList)
        return next
      })
      return true
    } else {
      setDisabledCreateButtonReason((prev) => {
        const next = new Set(prev)
        next.delete(DISABLED_CREATE_BUTTON.invalidActivationList)
        return next
      })
    }
    return !isActivationListValid
  }, [submitTargetActivations.isLoading, targetedActivationList.state])

  const handleSubmitActivations = () => {
    if (!targetedActivationList.state.size) return
    const targetedActivations = Array.from(targetedActivationList.state.values()).flat()
    const targetActivations: PatchActivationsItemType[] = targetedActivations?.map(
      ({ activationItemId, campaignsToAdGroups }) => {
        const initialValue: { keywordAdGroups: number[]; videoAdGroups: number[] } = {
          keywordAdGroups: [],
          videoAdGroups: []
        }
        //Reducing all the adGroups from the list for each targetedList
        const adGroups = Array.from(campaignsToAdGroups.values())
          .flat()
          .reduce((previousValue, currentValue) => {
            previousValue.keywordAdGroups = previousValue.keywordAdGroups.concat(
              Array.from(currentValue.keywordAdGroups)
            )
            previousValue.videoAdGroups = previousValue.videoAdGroups.concat(Array.from(currentValue.videoAdGroups))
            return previousValue
          }, initialValue)

        const finalTargetActivation: PatchActivationsItemType = { targetListId: activationItemId }
        !!adGroups.keywordAdGroups.length && (finalTargetActivation.keywordAdGroups = adGroups.keywordAdGroups)
        !!adGroups.videoAdGroups.length && (finalTargetActivation.videoAdGroups = adGroups.videoAdGroups)

        return finalTargetActivation
      }
    )
    const finalTargetActivations = targetActivations.filter(
      ({ keywordAdGroups, videoAdGroups }) =>
        (keywordAdGroups && keywordAdGroups.length > 0) || (videoAdGroups && videoAdGroups.length > 0)
    )

    submitTargetActivations.mutate({ activations: mergeDuplicates(finalTargetActivations) })
  }

  return (
    <SlideOver
      dataTestId="activations-manager-drawer"
      headerBackgroundColor="bg-sightlyBlue"
      headerTitle={ACTIVATIONS_MANAGER_TITLE}
      headerDescription={ACTIVATIONS_MANAGER_DESCRIPTION}
      show={open}
      onHide={() => setOpen(false)}
      placement={slideOverPlacements.RIGHT}
      maxOffset="256px - 7em"
      minOffset="7em"
      footer={
        <Footer
          disabledCreateButton={disableCreateButton}
          disabledCreateButtonReasons={disabledCreateButtonReasons}
          handleConfirmClick={handleSubmitActivations}
          setOpen={setOpen}
        />
      }
    >
      <>
        <label className="px-4 pt-3 mb-2 font-medium sm:px-6 text-grey-900">Moments ({activationsToIoIds.size})</label>
        <div className="sticky flex justify-between px-6 py-2">
          <div className="w-[200px]">
            <PersonaFilter
              personaOptions={personasFilterOptions}
              handleFilterPersonas={handleFilterPersonas}
            />
          </div>
          <SearchBox
            handleSearch={handleSearchMoments}
            placeholder="Search Moments"
            dataTestId="act-mgr-search-moments"
          />
        </div>
        <ActivationsTable
          {...{
            activationsToIoIds,
            personaFilters,
            searchedMomentsIds,
            setOpen,
            open
          }}
        />
        {submitTargetActivations.isLoading && (
          <Loader
            backdrop
            content={SUBMITTING_ACTIVATIONS}
            vertical
            className="z-10"
            speed="slow"
          />
        )}
      </>
    </SlideOver>
  )
}
