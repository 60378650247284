import { userAccountAxios } from '@/axiosInstances'
import {
  PatchThemeType,
  PostScenarioType,
  PostThemeType,
  ScenarioSchema,
  ScenariosFromGetAllSchema,
  ThemeSchema,
  ThemesSchema
} from './types'

export const api = {
  scenarios: {
    get: async ({ includeDeleted }: { includeDeleted: boolean }) => {
      const url = `/v1/brand-profiles/scenarios${includeDeleted ? '?includeDeleted=true' : ''}`
      const res = await userAccountAxios.get(url)
      return ScenariosFromGetAllSchema.parse(res.data)
    },
    getById: async ({ scenarioId }: { scenarioId: number }) => {
      const url = `/v1/brand-profiles/scenarios/${scenarioId}`
      const res = await userAccountAxios.get(url)
      return ScenarioSchema.parse(res.data)
    },
    post: async (scenario: PostScenarioType) => {
      const url = '/v1/brand-profiles/scenarios/'
      return await userAccountAxios.post(url, scenario)
    },
    patch: async (scenario: PostScenarioType, scenarioId: number) => {
      const url = `/v1/brand-profiles/scenarios/${scenarioId}`
      return await userAccountAxios.patch(url, scenario)
    },
    delete: async (scenarioId: number) => {
      const url = `/v1/brand-profiles/scenarios/${scenarioId}`
      return await userAccountAxios.delete(url)
    },
    recover: async (scenarioId: number) => {
      const url = `/v1/brand-profiles/scenarios/${scenarioId}/recover`
      return await userAccountAxios.patch(url)
    }
  },
  themes: {
    get: async ({ includeDeleted }: { includeDeleted: boolean }) => {
      const url = `/v1/brand-profiles/scenario-themes${includeDeleted ? '?includeDeleted=true' : ''}`
      const res = await userAccountAxios.get(url)
      return ThemesSchema.parse(res.data)
    },
    getById: async ({ themeId }: { themeId: number }) => {
      const url = `/v1/brand-profiles/scenario-themes/${themeId}`
      const res = await userAccountAxios.get(url)
      return ThemeSchema.parse(res.data)
    },
    post: async (theme: PostThemeType) => {
      const url = '/v1/brand-profiles/scenario-themes'
      return await userAccountAxios.post(url, theme)
    },
    patch: async ({ theme, themeId }: { theme: PatchThemeType; themeId: number }) => {
      const url = `/v1/brand-profiles/scenario-themes/${themeId}`
      return await userAccountAxios.patch(url, theme)
    },
    delete: async (themeId: number) => {
      const url = `/v1/brand-profiles/scenario-themes/${themeId}`
      return await userAccountAxios.delete(url)
    },
    recover: async (themeId: number) => {
      const url = `/v1/brand-profiles/scenario-themes/${themeId}/recover`
      return await userAccountAxios.patch(url)
    }
  }
}
