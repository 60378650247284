// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-dropdown-toggle:hover {
	color: #ffffff;
	background-color: #51bbe7;
}

.rs-dropdown-toggle:focus {
	color: #ffffff;
	background-color: #51bbe7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbars/index.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,yBAAyB;AAC1B;;AAEA;CACC,cAAc;CACd,yBAAyB;AAC1B","sourcesContent":[".rs-dropdown-toggle:hover {\n\tcolor: #ffffff;\n\tbackground-color: #51bbe7;\n}\n\n.rs-dropdown-toggle:focus {\n\tcolor: #ffffff;\n\tbackground-color: #51bbe7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
