import React from 'react';
import {
  MicrophoneIcon,
  QuestionMarkCircleIcon,
  ArrowTopRightOnSquareIcon,
  MegaphoneIcon,
  ChatBubbleOvalLeftEllipsisIcon
} from "@heroicons/react/24/outline";
import { Section } from "@/views/OnboardingHub/components/Section";
import { openZendeskChat } from "@/components/HelpWidgets/ZendeskWidget";

interface ResourcesProps {
  headerName: string
}

interface ResourceItem {
  icon: React.ReactNode;
  title: string;
  description: string;
  url: string
}

const resources: ResourceItem[] = [
  {
    icon: <MicrophoneIcon className="h-8"/>,
    title: 'BREAKING THROUGH THE MAYHEM PODCAST',
    description: 'Listen and subscribe to our podcast',
    url: 'https://www.sightly.com/podcast/',
  },
  {
    icon: <QuestionMarkCircleIcon className="h-8" />,
    title: 'KNOWLEDGE CENTER',
    description: 'Access best practices, FAQs & more',
    url: 'https://sightlyhelp.zendesk.com/hc/en-us',
  },
  {
    icon: <MegaphoneIcon className="h-8" />,
    title: 'FOLLOW US ON LINKEDIN',
    description: 'Get all product updates',
    url: 'https://www.linkedin.com/company/sightly/',
  },
];

export const Resources = ({ headerName }: ResourcesProps) => {
  return (
    <Section dataTestId="resources" headerName={headerName}>
      <div className="grid gap-6">
        {resources.map((item, index) => (
          <div
            key={index}
            data-testid="resource"
            className="flex items-center justify-between border h-24 bg-white rounded-md hover:cursor-pointer"
            onClick={() => {
              window.open(item.url, '_blank', 'noopener,noreferrer');
            }}
          >
            <div className="mx-6">
              {item.icon}
            </div>
            <div className="grid gap-1 flex-1 ">
              <h3 className="text-base font-semibold">{item.title}</h3>
              <p className="text-gray-500">{item.description}</p>
            </div>
            <div className="mx-8">
              <ArrowTopRightOnSquareIcon className="h-6"/>
            </div>
          </div>
        ))}

        <div
          data-testid="resource"
          className="flex items-center justify-between border h-24 bg-white rounded-md hover:cursor-pointer"
          onClick={() => openZendeskChat()}
        >
          <div className="mx-6">
            <ChatBubbleOvalLeftEllipsisIcon className="h-8" />
          </div>
          <div className="grid gap-1 flex-1 ">
            <h3 className="text-base font-semibold">NEED HELP?</h3>
            <p className="text-gray-500">Start a conversation with support</p>
          </div>
          <div className="mx-8">
            <ArrowTopRightOnSquareIcon className="h-6"/>
          </div>
        </div>

      </div>
    </Section>
  );
};
