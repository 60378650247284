import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { competitorObjValidation } from '@/schemas/brandProfiles'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import NameSearchField from '../NameSearchField'
import { Brand } from './brand-types'
import { useMatch, useSearch } from '@tanstack/react-location'
import { rqKeys } from './brand-rqKeys'
import { rqKeys as mainRqKeys } from '@/ReactQueryKeyFactory'
import { saveBrand, updateBrand } from './brand-queries-api'

import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import SightlySelectForm from '@/components/Sightly/SightlyFormElements/SightlySelectForm'
import RelationshipSelectForm from '@/views/BrandProfiles/BrandProfile/components/Queries/components/RelationshipSelectForm'
import WebsiteInputForm from '@/views/BrandProfiles/BrandProfile/components/Queries/components/WebsiteInputForm'
import WikiInputForm from '@/views/BrandProfiles/BrandProfile/components/Queries/components/WikiInputForm'
import TwitterInputForm from '@/views/BrandProfiles/BrandProfile/components/Queries/components/TwitterInputForm'
import ResetFieldsButton from '@/views/BrandProfiles/BrandProfile/components/Queries/components/ResetFieldsButton'
import { MyLocationGenerics } from '@/classes/utils'
import { products } from '@/staticData/products'
import { InputStyleWrapper } from './BrandAIGenerationForm'
import config from '@/config'

interface IProps {
  item: Brand
  open: boolean
  handleClose: () => void
  disabled?: boolean
  type: 'edit' | 'add'
}

const BrandModal: React.FC<IProps> = ({ item, open, handleClose, disabled, type }: IProps) => {
  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = React.useState(false)
  const {
    params: { brandProfileId }
  } = useMatch()
  const { accountId } = useSearch<MyLocationGenerics>()

  const { register, handleSubmit, reset, control, formState, setValue, watch } = useForm<Brand>({
    defaultValues: item,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(competitorObjValidation)
  })

  const _handleClose = () => {
    reset()
    handleClose()
  }

  const onSubmit = async (data: Brand) => {
    setIsSaving(true)
    delete data.relationshipName

    try {
      if (type === 'edit') {
        // disable isAIGenerated to false since it's being modified by the user
        data.isAIGenerated = false
        await updateBrand(Number(brandProfileId), item.id, data)
      } else {
        await saveBrand(Number(brandProfileId), data)
        // to fetch the latest product quota
        await queryClient.fetchQuery(mainRqKeys.subscriptionQuotaQueryKey(accountId, products.QUERIES))
        reset()
      }

      toast.success('Saved')
      await queryClient.invalidateQueries(rqKeys.brandProfileQuery('competitors', Number(brandProfileId)))
      queryClient.invalidateQueries({ queryKey: mainRqKeys.brandProfileBasic(Number(brandProfileId)) })
    } catch (err) {
      toast.error('Error')
      setIsSaving(false)
    }
    setIsSaving(false)
  }

  return (
    <PortalModal
      hasClose={true}
      handleClose={_handleClose}
      open={open}
      footerButtons={[
        <SightlyButton
          id="rename"
          loading={isSaving}
          disabled={!formState.isValid || !formState.isDirty}
          text={type === 'edit' ? 'Save' : 'Add'}
          handleClick={handleSubmit(onSubmit)}
          type="primary"
          datatestid='brand-add-custom-button'
        />
      ]}
    >
      <h4 data-testid='brand-modal-label' className='mb-6 w-96'>{type === 'edit' ? 'Edit Brand' : 'Add New Brand'}</h4>

      {config.featureFlag.aiProfiles ? (
        <div className="grid gap-2 grid-cols-auto">
          <InputStyleWrapper>
            <NameSearchField
              dataTestId='brand-modal-name-search-text'
              name={'Name'}
              disabled={disabled}
              handleSetWikiUrl={(val: never) =>
                setValue('wikiUrl', val, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }
              handleSetName={(val: never) =>
                setValue('name', val, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }
              nameValue={watch('name')}
              error={formState.errors?.name?.message}
            />
          </InputStyleWrapper>
          <InputStyleWrapper>
            <WikiInputForm
              control={control}
              error={formState.errors?.wikiUrl?.message}
              name="wikiUrl"
              register={register}
              disabled={disabled}
            />
          </InputStyleWrapper>
          <InputStyleWrapper>
            <TwitterInputForm
              control={control}
              error={formState.errors?.twitterProfileUrl?.message}
              name="twitterProfileUrl"
              register={register}
              disabled={disabled}
            />
          </InputStyleWrapper>
          <InputStyleWrapper>
            <WebsiteInputForm
              control={control}
              error={formState.errors?.websiteUrl?.message}
              name="websiteUrl"
              register={register}
              disabled={disabled}
            />
          </InputStyleWrapper>
          <InputStyleWrapper>
            <RelationshipSelectForm
              control={control}
              disabled={disabled}
            />
          </InputStyleWrapper>
          <InputStyleWrapper>
            <SightlySelectForm
              dataTestId="select-brand-information-brand-relationship"
              label="Relationship"
              id="brand-type"
              labelKey="brandType"
              valueKey="brandType"
              name={'brandType'}
              options={[
                { id: 1, brandType: 'Competitor' },
                { id: 2, brandType: 'Aspirational Brand' }
              ]}
              control={control}
              disabled={disabled}
            />
          </InputStyleWrapper>
          {type === 'add' && formState.isDirty && <ResetFieldsButton reset={reset} />}
        </div>
      ) : (
        <>
          <div className="form-container">
            <NameSearchField
              name={'Name'}
              disabled={disabled}
              handleSetWikiUrl={(val: never) =>
                setValue('wikiUrl', val, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }
              handleSetName={(val: never) =>
                setValue('name', val, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }
              nameValue={watch('name')}
              error={formState.errors?.name?.message}
            />

            <WikiInputForm
              control={control}
              error={formState.errors?.wikiUrl?.message}
              name="wikiUrl"
              register={register}
              disabled={disabled}
            />

            <TwitterInputForm
              control={control}
              error={formState.errors?.twitterProfileUrl?.message}
              name="twitterProfileUrl"
              register={register}
              disabled={disabled}
            />

            <WebsiteInputForm
              control={control}
              error={formState.errors?.websiteUrl?.message}
              name="websiteUrl"
              register={register}
              disabled={disabled}
            />

            <RelationshipSelectForm
              control={control}
              disabled={disabled}
            />

            <SightlySelectForm
              dataTestId="select-brand-information-brand-relationship"
              label="Relationship"
              id="brand-type"
              labelKey="brandType"
              valueKey="brandType"
              name={'brandType'}
              options={[
                { id: 1, brandType: 'Competitor' },
                { id: 2, brandType: 'Aspirational Brand' }
              ]}
              control={control}
              disabled={disabled}
            />

            {type === 'add' && formState.isDirty && <ResetFieldsButton reset={reset} />}
          </div>

          <style>
            {`
                    .form-container {
                        display: grid;
                        gap: 24px;				
                        grid-template-columns: repeat(auto-fit);			
                    }		
			    `}
          </style>
        </>
      )}
    </PortalModal>
  )
}

export default BrandModal
