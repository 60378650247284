import { sightlyBlue } from '@/assets/jss/colorConstants'
import React, { ReactElement } from 'react'

interface IProps {
  id: string
  checked?: boolean
  handleChange: Function
  label?: string | ReactElement
  disabled?: boolean
  defaultChecked?: boolean
  indeterminate?: boolean
  dataTestId?: string
  type?: 'sightlyBlue' | 'sightlyPurple'
}

const SightlyCheckbox = ({
  id,
  checked,
  handleChange,
  label,
  disabled,
  defaultChecked,
  indeterminate,
  dataTestId,
  type = 'sightlyBlue'
}: IProps) => {
  if (checked === undefined && defaultChecked === undefined) {
    console.error('one of checked or defaultChecked is required')
  }
  if (checked !== undefined && defaultChecked !== undefined) {
    console.error('checked and defaultChecked cannot both be declared')
  }

  const getStyle = () => {
    switch (type) {
      case 'sightlyPurple':
        return 'text-sightlyPurple focus:ring-sightlyPurple';
      case 'sightlyBlue':
      default:
        return 'text-sightlyBlue focus:ring-sightlyBlue';
    }
  };
  
  return (
    <div
      data-testid={dataTestId}
      className="relative flex  items-center"
    >
      {checked !== undefined ? (
        <input
          ref={(input) => {
            if (input) {
              input.indeterminate = indeterminate || false
            }
          }}
          id={id}
          onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(val.target.checked)
          }}
          type="checkbox"
          className={`form-checkbox h-4 w-4 rounded border-gray-300 ${getStyle()} disabled:opacity-40 cursor-pointer`}
          disabled={disabled}
          checked={checked}
        />
      ) : (
        <input
          id={id}
          onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(val.target.checked)
          }}
          type="checkbox"
          className="form-checkbox h-4 w-4 rounded border-gray-300 text-sightly-blue focus:ring-sightly-blue disabled:opacity-40 cursor-pointer"
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
      )}

      {label && (
        <div className="ml-3">
          <label
            style={{ wordBreak: 'break-all' }}
            htmlFor={'checkbox'}
            className="text-sm font-medium text-gray-700 line-clamp-1"
          >
            {label}
          </label>
        </div>
      )}
    </div>
  )
}

export default SightlyCheckbox
