import { useSearch } from '@tanstack/react-location'
import React, { Dispatch, SetStateAction } from 'react'
import { MyLocationGenerics } from '../../../classes/utils'
import { LoadingPage } from '../../../components/LoadingPage'
import SightlyAvatar from '../../../components/SightlyAvatar'
import { SmallBadge } from '../../../components/SmallClosableBadge'
import { ReactComponent as KeywordsIcon } from '../../../assets/img/keywordsKey.svg'
import { ReactComponent as BlackYoutube } from '../../../assets/img/blackYoutube.svg'
import { useImplementedActivations } from './activations-hooks'
import { InfoPage } from '../../../components/InfoPage'
import SightlyToggle from '../../../components/Sightly/SightlyFormElements/SightlyToggle'
import { BoltIcon } from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { MessageTailwind } from '../../../components/MessageTailwind'
import PortalModal from '../../../components/PortalModal/PortalModal'
import SightlyButton from '../../../components/Sightly/SightlyButton'
import SightlyCheckbox from '../../../components/Sightly/SightlyFormElements/SightlyCheckbox'
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

interface IProps {
    handleDownloadActivations:Function
    selectedActivationIds: number[]
    setSelectedActivationIds: Dispatch<SetStateAction<number[]>>
}

export const Implemented = ({
        handleDownloadActivations,
        selectedActivationIds,
        setSelectedActivationIds}:IProps) => {
    const { brandProfileId } = useSearch<MyLocationGenerics>()
    const activationsQuery = useImplementedActivations({
        brandProfileId: Number(brandProfileId)
    })

    const [downloadingTrends, setDownloadingTrends] = React.useState(false)
    const { perms, userPermissions } = useUser()
    const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

    const allMomentsOnPageAreSelected = React.useMemo(() => {
        if (selectedActivationIds.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = activationsQuery.data
            ?.map((m) => m.clusterId)
            ?.filter((id) => !selectedActivationIds.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        }
        if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [activationsQuery.data, selectedActivationIds])

    if (activationsQuery.isLoading) {
        return (
            <div style={{ width: '100%', height: '80vh' }}>
                <LoadingPage message="Fetching Activations" />
            </div>
        )
    }
    if (activationsQuery?.data && activationsQuery?.data.length === 0 && activationsQuery.isFetched) {
        return (
            <div style={{ width: '100%', height: 300 }}>
                {' '}
                <InfoPage
                    message={
                        <div>
                            <span>
                                No items found.
                            </span>
                        </div>
                    }
                />{' '}
            </div>
        )
    }

    return (
        <div className="flex flex-col mt-8 fade-in animate-in">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
                    <div className="w-full flex justify-between mb-10 h-10">
                        <div >
                            <MessageTailwind
                                show={downloadingTrends}
                                message="Downloading moments"
                                type="loading"
                            />
                            </div>
                        {userCanPermissionProductQuota({
                            requiredPermissionValue: perms.TRENDS_DOWNLOAD,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                        }) && (
                            <SightlyButton
                            handleClick={() => handleDownloadActivations({
                                                    brandProfileId,
                                                    setDownloadingTrends,
                                                    setShowTooManyTrendsError,
                                                    selectedMomentIds: selectedActivationIds,
                                                    activationState: 'complete'
                                                })}
                                id="downloadActivation"
                                text="Download"
                                disabled={selectedActivationIds.length < 1 ||
                                            (activationsQuery?.data && activationsQuery?.data.length === 0) ||
                                            activationsQuery.isLoading }
                                icon = {
                                    <ArrowDownTrayIcon
                                        className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                        aria-hidden="true"
                                    />}
                            />
                        )}
                    </div>
                    <div className="relative my-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 table-fixed">
                            <thead className="bg-gray-50">
                                <tr>
                                <th
                                        scope="col"
                                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                                    >
                                        <SightlyCheckbox
                                            indeterminate={selectedActivationIds.length > 0 && !allMomentsOnPageAreSelected}
                                            handleChange={(checked: boolean) => {
                                                if (checked) {
                                                    setSelectedActivationIds(
                                                        activationsQuery?.data?.map((i) => i.clusterId) || []
                                                    )
                                                } else {
                                                    setSelectedActivationIds([])
                                                }
                                            }}
                                            id="selectAllTodo"
                                            checked={activationsQuery?.data?.length === selectedActivationIds.length}
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className=" py-3.5 pr-3 text-left text-sm font-semibold text-gray-600"
                                    ></th>
                                    <th
                                        scope="col"
                                        className="min-w-[12rem] py-3.5 pr-3 pl-4 text-left text-sm font-semibold text-gray-600"
                                    >
                                        MOMENT
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        PERSONAS
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        ADGROUPS
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        KEYWORDS
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        PLACEMENTS
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-600"
                                    >
                                        ASSIGNEES
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left whitespace-nowrap text-sm font-semibold text-gray-600"
                                    >
                                        IMPLEMENTED AT
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {activationsQuery?.data?.map((activationItem) => (
                                    <tr
                                        key={activationItem.clusterId}
                                        className={'bg-gray-50 fade-in animate-in'}
                                    >
                                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                            <SightlyCheckbox
                                                handleChange={(checked: boolean) => {
                                                    const newItems = checked
                                                        ? selectedActivationIds.concat(activationItem.clusterId)
                                                        : selectedActivationIds.filter(
                                                              (id) => id !== activationItem.clusterId
                                                          )
                                                    setSelectedActivationIds(newItems)
                                                }}
                                                id="select"
                                                checked={selectedActivationIds.includes(activationItem.clusterId)}
                                            />
                                        </td>
                                        <td className={'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium max-w-min'}>
                                            <BoltIcon className="h-4 text-sightly-blue" />
                                        </td>
                                        <td
                                            className={
                                                'whitespace-nowrap py-4 pr-3 pl-4 text-sm font-medium truncate max-w-lg'
                                            }
                                        >
                                            <div className="truncate">{activationItem.clusterName}</div>
                                            <div className="mt-2 statsContainer">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <KeywordsIcon />
                                                    <div className="pl-1 text-xs text-gray-500">
                                                        {activationItem.keywordCount === 1
                                                            ? '1 keyword'
                                                            : activationItem.keywordCount + ' keywords'}
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <BlackYoutube />
                                                    <div className="pl-1 text-xs text-gray-500">
                                                        {activationItem.videoCount === 1
                                                            ? '1 video'
                                                            : activationItem.videoCount + ' videos'}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            <div className="flex items-center gap-2">
                                                {activationItem.personas.slice(0, 2).map((persona, index) => {
                                                    return (
                                                        <SmallBadge
                                                            key={`${activationItem.activationItemId}_${persona}_${index}`}
                                                            text={persona}
                                                            onClose={() => undefined}
                                                            disabled={true}
                                                        />
                                                    )
                                                })}
                                                {activationItem.personas.length > 2 && (
                                                    <div className="text-xs">
                                                        + {activationItem.personas.length - 2} others
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            <div className="flex flex-wrap items-center gap-2">
                                                {activationItem.adGroups && activationItem?.adGroups.slice(0, 2).map((adGroup) => {
                                                    return (
                                                        <SmallBadge
                                                        key={adGroup.id}
                                                        text={adGroup.name}
                                                        onClose={() => undefined}
                                                        disabled={true}
                                                        />
                                                    )
                                                })}
                                                {activationItem.adGroups && activationItem?.adGroups.length > 2 && (
                                                    <div className="text-xs">
                                                        + {activationItem.adGroups && activationItem?.adGroups.length - 2} others
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            <SightlyToggle
                                                id="keywords"
                                                enabled={activationItem?.isKeywords}
                                                setEnabled={()=>false}
                                            />
                                        </td>
                                        <td className="py-4 pl-5 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                            <SightlyToggle
                                                id="videos"
                                                enabled={activationItem?.isPlacements}
                                                setEnabled={()=>false}
                                            />
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
                                                <div className="flex -space-x-1 ">
                                                    {activationItem.assignees.slice(0, 2).map((assignee) => {
                                                        return (
                                                            <div
                                                                key={assignee}
                                                                className="inline-block rounded-full ring-2 ring-white"
                                                            >
                                                                <SightlyAvatar
                                                                    text={assignee}
                                                                    size="xs"
                                                                    type={null}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {activationItem.assignees.length > 2 && (
                                                    <div className="text-xs">
                                                        + {activationItem.assignees.length - 2} others
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                            {activationItem?.implementedDate || " "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PortalModal
                    hasClose
                    open={showTooManyTrendsError}
                    handleClose={() => setShowTooManyTrendsError(false)}
                    footerButtons={[]}
                >
                    <div
                        style={{
                            width: 500,
                            padding: 24,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 48
                        }}
                    >
                        <h4>Unable to download</h4>
                        <div>
                            Our system can only handle downloading 1,000 moments maximum. Please add more filters and
                            try again.
                        </div>
                    </div>
                    </PortalModal>
                </div>
            </div>
            <style>
                {`
                  .statsContainer {
                      display: grid;
                      grid-template-columns: 130px 130px 130px;
                      grid-auto-flow: row;	
                  }
                `}
            </style>
        </div>
    )
}
