import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Divider from 'rsuite/lib/Divider';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { ConversationPanelSectionLayout } from '../PanelSectionLayout.';
import {Loader} from "rsuite";


interface ConversationOverview {
  id: string
  name: string
  summary:string
  postCount: number
  themeCount: number
  platformCount: number
  minPublishedDate: string
  maxPublishedDate: string
  totalPostsOverTime: {
    post_count: number,
    publish_date: string
  }[]
}

const SocialOverviewTabContents = ({overview}: { overview: ConversationOverview }) => {

  if (!overview) {
    return <Loader center/>
  }

  const headers = ['Date Published', 'Total Posts', 'Total Platforms', 'Total Themes'];
  const rowData = [
    overview.maxPublishedDate,
    overview.postCount,
    overview.platformCount,
    overview.themeCount
  ];

  return (
    <ConversationPanelSectionLayout headerName="Overview">
      <>
        <div className="social-summary-border-wrapper mb-2">
          <div className="p-4 social-summary-border-inner">
            <h3 className="text-highlightPurple text-sm">
              <SparklesIcon
                className="h-5 w-5 text-highlightPurple inline mr-2"
                aria-hidden="true"
              />
              Mentality AI
            </h3>
            <p
              data-testid="moment-slideover-article-cluster-summary"
              className="mb-8 text-base"
            >
              {overview.summary}
            </p>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="px-4 py-2 text-left text-lg font-bold"
                >
                  {header}
                </th>
              ))}
            </tr>
            </thead>
            <tbody>
            <tr>
              {rowData.map((data, index) => (
                <td
                  key={index}
                  className="px-4 py-2 text-lg font-bold"
                >
                  {data}
                </td>
              ))}
            </tr>
            </tbody>
          </table>
        </div>

        <Divider />
        {overview.totalPostsOverTime && (
          <>
            <h4 className="mb-2 ml-8">Total Posts Over Time</h4>
            <div data-testid="moment-slideover-article-count-chart-div">
              <LineChart
                width={700}
                height={300}
                data={overview.totalPostsOverTime}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="publish_date" tickCount={5} />
                <YAxis dataKey="post_count" tickCount={5} />
                <Tooltip
                  formatter={(value, name, props) => [value, 'Post count']}
                />
                <Line type="monotone" dataKey="post_count" stroke="#7748f9" />
              </LineChart>
            </div>
          </>
        )}
      </>
    </ConversationPanelSectionLayout>
    )
}

export default SocialOverviewTabContents;
