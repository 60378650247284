import React from 'react'
import { CampaignOptionsType } from '@/views/TikTok/ActivationManager/types'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { SerializableMap } from '@/utils/classes/SerializableMap'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/TikTok/ActivationManager/reducers/activationManager.reducer'
import { useTargetedActivationList } from '@/views/TikTok/ActivationManager/ActivationManagerContext'
import CampaignAdGroupRow from '@/views/TikTok/ActivationManager/ActivationsTable/CampaignAdGroupRow'
import useUser from '@/hooks/useUser'
import { perms } from '@/staticData/permissions'
import { products } from '@/staticData/products'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'

type CampaignAdGroupWrapperProps = {
  campaignOptions: CampaignOptionsType | undefined
  campaignsToAdGroups: SerializableMap<number, SerializableSet<number>>
  hashtagId: string
  keyVersion: React.MutableRefObject<number>
  areCampaignOptionsLoading: boolean
}

const CampaignAdGroupWrapper = ({
  campaignOptions,
  campaignsToAdGroups,
  hashtagId,
  keyVersion,
  areCampaignOptionsLoading
}: CampaignAdGroupWrapperProps) => {
  let keyCounter = React.useRef(0.1) //using float to avoid clash with campaignIds starting from integer 1

  const adGroupOptions = React.useMemo(
    () => new Map(campaignOptions?.map(({ campaignId, adGroups }) => [campaignId, adGroups])),
    [campaignOptions]
  )
  const targetedActivationList = useTargetedActivationList()

  const initialValueKeyToCampaignId = React.useMemo(() => {
    const initValue: Record<string, number> = {}
    let rowNum = 0
    campaignsToAdGroups.forEach((adGroups, campaignId) => {
      const key = `${hashtagId}_${rowNum}`
      initValue[key] = campaignId
      rowNum++
    })
    if (!campaignsToAdGroups.size) {
      const key = `${hashtagId}_${rowNum}`
      initValue[key] = 0.1
    }
    keyCounter.current = rowNum + 0.1
    return initValue
  }, [adGroupOptions, campaignsToAdGroups])

  const [keyToCampaignIdMapper, setKeyToCampaignIdMapper] =
    React.useState<Record<string, number>>(initialValueKeyToCampaignId)

  const handleCampaignChange = (
    elementKey: string,
    hashtagId: string,
    nextCampaignId: number,
    prevCampaignId: number,
    setDoesValueExists: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (prevCampaignId !== nextCampaignId && campaignsToAdGroups.has(nextCampaignId)) {
      setDoesValueExists(() => true)
      return
    }
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN,
      payload: {
        hashtagId,
        nextCampaignId,
        prevCampaignId
      }
    })
    setKeyToCampaignIdMapper((prev) => {
      const next = { ...prev }
      next[elementKey] = nextCampaignId
      return next
    })
    setDoesValueExists(() => false)
  }

  const handleAddRow = () =>
    !areCampaignOptionsLoading && setKeyToCampaignIdMapper((prev) => {
      const newValue = keyCounter.current + 1
      const next = { ...prev, [`${hashtagId}_${newValue}`]: newValue }
      keyCounter.current = newValue
      return next
    })

  return (
    <>
      <div
        data-testid="tiktok-act-mgr-campaigns-adGroup-col"
        className="grid w-full grid-cols-2 gap-4 text-sm text-gray-900"
        key={`${hashtagId}_v${keyVersion}`}
      >
        {Array.from(Object.entries(keyToCampaignIdMapper)).map(([elementKey, campaignId]) => {
          return (
            <CampaignAdGroupRow
              {...{
                hashtagId,
                key: elementKey,
                rowKey: elementKey,
                campaignOptions,
                setKeyToCampaignIdMapper,
                adGroupOptions: adGroupOptions.get(campaignId) ?? [],
                campaignValue: campaignId,
                adGroupValue: Array.from(campaignsToAdGroups.get(campaignId) ?? []),
                handleCampaignChange,
                areCampaignOptionsLoading
              }}
            />
          )
        })}

        <style>
          {`
          .rs-picker-default .rs-picker-toggle.rs-btn {
            height: 38px;
          }
        `}
        </style>
      </div>
      <AddRowButton
        handleAddRow={handleAddRow}
        areCampaignOptionsLoading={areCampaignOptionsLoading}
        key={hashtagId}
      />
    </>
  )
}

export default CampaignAdGroupWrapper

const AddRowButton = (props: { handleAddRow: () => void, areCampaignOptionsLoading: boolean }) => {
  const { products, perms, userPermissions, userProducts } = useUser()

  return (
    <div
      data-testid="tiktok-act-mgr-target-moments-table-io-adgroup-addRowButton"
      className="grid grid-cols-2 gap-4 m-2 text-xs font-semibold text-right text-sightlyBlue"
    >
      <div className="w-[280px]">&nbsp;</div>
      <div className="flex w-full gap-2">
        <div className="w-[280px]">
          { userCanPermissionProductQuota({
            requiredPermissionValue: perms.TIKTOK_ACTIVATIONS_UPDATE,
            requiredProductValue: products.TIKTOK_ACTIVATIONS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
          }) &&
            <span
              className={`${props.areCampaignOptionsLoading ? 'cursor-not-allowed text-gray-600': 'cursor-pointer'}`}
              onClick={props.handleAddRow}
            >
              + Add
            </span>
          }
        </div>
        <div className="w-8 h-8">&nbsp;</div>
      </div>
    </div>
  )
}