import { Link } from '@tanstack/react-location'
import React from 'react'
export const AuthErrorPage = ({ message }: { message: string }) => {
    React.useEffect(()=>{
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
    },[])
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
                gap: 8
            }}
        >
            {message}
            <Link to={'/login'}>Back to Login</Link>
        </div>
    )
}
