import React from 'react'
import {
  InsightsPanelProps,
  InsightsType,
  MomentInsightsType,
  TaxonomyCardProps,
  TaxonomyCardSectionProps
} from './types'
import { CAPTIONS, HEADER_DESCRIPTION, TOP_MOMENTS_HEADER_DESCRIPTION, TOP_TRENDING_MOMENTS } from './constants'
import { Progress } from 'rsuite'
import { sightlyBlue } from '@/assets/jss/colorConstants'

const TaxonomyCard = ({ taxonomyName, storyPercentage }: TaxonomyCardProps) => (
  <div
    data-testid={`moment-insights-modal-insights-panel-${taxonomyName}-card`}
    className="flex flex-col gap-1 "
  >
    <div className="flex justify-between">
      <span className="text-[0.65rem] font-semibold py-1 px-2 rounded-full bg-slate-100">{taxonomyName}</span>
      <span className="text-xs">{`${storyPercentage}% of articles mention ${taxonomyName}`}</span>
    </div>
    <Progress.Line
      percent={storyPercentage}
      strokeColor={sightlyBlue}
      showInfo={false}
      style={{ padding: 'unset' }}
    />
  </div>
)

const TaxonomyCardSection = ({ insights, caption }: TaxonomyCardSectionProps) => {
  return (
    <section
      data-testid={`moment-insights-modal-insights-panel-${caption}-section`}
      className="flex flex-col gap-3 p-2"
    >
      <div className="text-xs font-bold text-[#696969">{caption}</div>
      {insights?.map(({ taxonomyName, storyPercentage }) => (
        <TaxonomyCard {...{ taxonomyName, storyPercentage, key: taxonomyName }} />
      ))}
    </section>
  )
}

export default function InsightsPanel({ momentInsights, activeBoardId }: InsightsPanelProps) {
  const [momentInsight, setMomentInsight] = React.useState<MomentInsightsType | undefined>()
  React.useEffect(() => {
    setMomentInsight(momentInsights.find((insights) => insights.boardId === activeBoardId))
  }, [momentInsights, activeBoardId])

  const taxonomies = React.useMemo(() => {
    const _taxonomies = momentInsight?.insights || []
    const taxonomiesMap = new Map<string, InsightsType[]>()
    _taxonomies.forEach((taxonomy) => {
      if (taxonomiesMap.has(taxonomy.taxonomyType)) {
        taxonomiesMap.get(taxonomy.taxonomyType)?.push(taxonomy)
      } else taxonomiesMap.set(taxonomy.taxonomyType, [taxonomy])
    })
    return taxonomiesMap
  }, [momentInsight?.insights])

  return (
    <>
      {momentInsight && (
        <div
          data-testid="moment-insights-modal-insights-panel"
          className="flex flex-col w-2/3 gap-2 p-6 border-b divide-y"
        >
          <header className="text-base font-bold">
            <p className="font-bold">{momentInsight.boardName}</p>
            <p className="text-xs font-normal">
              {!momentInsight.insights.length ? TOP_MOMENTS_HEADER_DESCRIPTION : HEADER_DESCRIPTION}
            </p>
          </header>
          {!momentInsight.insights.length ? (
            <div className="flex flex-col justify-around h-full">
              <p className="text-lg text-center text-gray-800">{TOP_TRENDING_MOMENTS}</p>
            </div>
          ) : (
            <main className="flex flex-col gap-4 pr-4">
              {taxonomies.has('industry') && (
                <TaxonomyCardSection
                  caption={CAPTIONS.industry}
                  key={'industry'}
                  insights={taxonomies.get('industry') || []}
                />
              )}
              {taxonomies.has('category') && (
                <TaxonomyCardSection
                  caption={CAPTIONS.category}
                  key={'category'}
                  insights={taxonomies.get('category') || []}
                />
              )}
              {(taxonomies.has('queries') || taxonomies.has('entity')) && (
                <TaxonomyCardSection
                  caption={CAPTIONS.queries}
                  key={'queries'}
                  insights={taxonomies.get('queries') || taxonomies.get('entity') || []}
                />
              )}
            </main>
          )}
        </div>
      )}
    </>
  )
}
