import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC,
  CONVERSION,
  GenericDimensionOption, MetricOption,
} from "@/views/ActivationReports/types/activation-report.types";

export enum MOMENT_DIMENSION {
  ACTION = 'action',
  BRAND_PROFILE_NAME = 'brandProfileName',
  CATEGORY_ID_LIST = 'categoryIdList',
  CATEGORY_LABEL_LIST = 'categoryLabelList',
  CLUSTER_ID = 'clusterId',
  CLUSTER_NAME = 'clusterName',
  IMPLEMENTED_AT = 'implementedAt',
  MAX_PUBLISHED_AT = 'maxPublishedAt',
  MIN_PUBLISHED_AT = 'minPublishedAt',
  PERSONAS = 'personas',
  STORY_TITLES = 'storyTitles',
}

export enum MOMENT_METRIC {
  STORY_COUNT = 'storyCount',
  VIDEO_COUNT = 'videoCount',
}

interface MomentDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | MOMENT_DIMENSION
}

export interface MomentMetricOption extends Omit<MetricOption, 'value'> {
  name: string
  value: METRIC | MOMENT_METRIC
}

export interface MomentReport extends Omit<ReportTemplate, 'columns' | 'dimensions' | 'metrics'> {
  columns: (GENERIC_DIMENSION | MOMENT_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | MomentDimensionOption)[]
  metrics: (MetricOption | MomentMetricOption)[]
}

export const dimensionOptions: Option<MomentDimensionOption> = {
  [MOMENT_DIMENSION.ACTION]: {
    name: 'Action',
    value: MOMENT_DIMENSION.ACTION
  },
  [MOMENT_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: MOMENT_DIMENSION.BRAND_PROFILE_NAME
  },
  [MOMENT_DIMENSION.CATEGORY_ID_LIST]: {
    name: 'Category Ids',
    value: MOMENT_DIMENSION.CATEGORY_ID_LIST
  },
  [MOMENT_DIMENSION.CATEGORY_LABEL_LIST]: {
    name: 'Category Labels',
    value: MOMENT_DIMENSION.CATEGORY_LABEL_LIST
  },
  [MOMENT_DIMENSION.CLUSTER_ID]: {
    name: 'Moment Id',
    value: MOMENT_DIMENSION.CLUSTER_ID
  },
  [MOMENT_DIMENSION.CLUSTER_NAME]: {
    name: 'Moment Name',
    value: MOMENT_DIMENSION.CLUSTER_NAME
  },
  [MOMENT_DIMENSION.IMPLEMENTED_AT]: {
    name: 'Moment Implemented At',
    value: MOMENT_DIMENSION.IMPLEMENTED_AT
  },
  [MOMENT_DIMENSION.MAX_PUBLISHED_AT]: {
    name: 'Max Published At',
    value: MOMENT_DIMENSION.MAX_PUBLISHED_AT
  },
  [MOMENT_DIMENSION.MIN_PUBLISHED_AT]: {
    name: 'Min Published At',
    value: MOMENT_DIMENSION.MIN_PUBLISHED_AT
  },
  [MOMENT_DIMENSION.PERSONAS]: {
    name: 'Personas',
    value: MOMENT_DIMENSION.PERSONAS
  },
  [MOMENT_DIMENSION.STORY_TITLES]: {
    name: 'Story Titles',
    value: MOMENT_DIMENSION.STORY_TITLES
  },
}

export const metricOptions: Option<MomentMetricOption> = {
  [MOMENT_METRIC.STORY_COUNT]: {
    name: 'Story Count',
    value: MOMENT_METRIC.STORY_COUNT
  },
  [MOMENT_METRIC.VIDEO_COUNT]: {
    name: 'Video Count',
    value: MOMENT_METRIC.VIDEO_COUNT
  },
}

export const baseMomentReport: MomentReport = {
  name: 'Moment Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.MOMENT,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: [
    ...templateMetricOptions,
    ...Object.keys(metricOptions).map(key => metricOptions[key])
  ],
  conversions: templateConversionOptions
}
