import { TOGGLE_MENU, ToggleMenuAction } from "../actions/layout";


export interface LayoutState {
layoutReducer: {
    isMinimized: boolean;
}
}
  
export interface State {
  isMinimized: boolean;
}

const initialState: State = {
  isMinimized: false
};

const layoutReducer = (
    state = initialState, 
    action: ToggleMenuAction
): State => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isMinimized: !state.isMinimized
      };
    default:
      return state;
  }
};

export default layoutReducer;