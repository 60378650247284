import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum PARENTAL_STATUS_DIMENSION {
    PARENTAL_STATUS = 'parentalStatus',
    DEVICE = 'device',
}

interface ParentalStatusDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | PARENTAL_STATUS_DIMENSION
}

export interface ParentalStatusReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | PARENTAL_STATUS_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | ParentalStatusDimensionOption)[]
}

export const parentalStatusDimensions: Option<ParentalStatusDimensionOption> = {
  [PARENTAL_STATUS_DIMENSION.DEVICE]: {
    name: 'Device',
    value: PARENTAL_STATUS_DIMENSION.DEVICE
  },
  [PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS]: {
    name: 'Parental Status',
    value: PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS
  },
}

export const baseParentalStatusReport: ParentalStatusReport = {
  name: 'Parental Status Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.PARENTAL_STATUS,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(parentalStatusDimensions).map(key => parentalStatusDimensions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const parentalStatusReportTemplate: ParentalStatusReport = {
  ...baseParentalStatusReport,
  name: 'Parental Status Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
