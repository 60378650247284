import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'

import { FormLabel } from '@/components/Sightly/SightlyFormElements/SightlyFormLabel'
import { SightlyModernFormError } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormError'

interface IProps {
  control: Control<any, any>
  name: string
  prefix?: string
  autoFocus?: boolean
  disabled?: boolean
  errors?: FieldErrorsImpl<Record<string, any>>
  showAllErrors?: boolean
  label?: string
  additionalLabelClassName?:string
  placeholder?: string
  required?: boolean
  type?: 'text' | 'password'
  value?: any
}

export const SightlyModernFormInput: React.FC<IProps> = (props) => {
  let errors: string[] = []
  if (props.errors && props.errors[props.name]) {
    const propertyError = props.errors[props.name]!
    if (props.showAllErrors) {
      for (const key in propertyError.types) {
        errors = errors.concat((propertyError.types as unknown as Record<string, string[]>)[key])
      }
    } else {
      errors = [propertyError.message as string]
    }
  }

  return (
    <div
      data-testid={`modern-field-${props.name}`}
      style={{
        textAlign: 'left',
        position: 'relative'
      }}
    >
      <Controller
        name={props.name}
        defaultValue={''}
        control={props.control}
        rules={{ required: true }}
        render={({ field }) => (
          <div>
            {props.label && (
              <FormLabel
                id={props.name}
                text={props.label}
                required={props.required}
                additionalClass={props.additionalLabelClassName}

              />
            )}
            <div className="flex mt-1 rounded-md shadow-sm">
              {props.prefix && (
                <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
                  {props.prefix}
                </span>
              )}
              <input
                type={props.type ?? 'text'}
                {...field}
                id={props.name}
                data-testid={`modern-input-${props.name}`}
                className={`block w-full text-sm border-gray-300 rounded-md form-input focus:border-sightly-blue focus:ring-sightly-blue disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500
                                ${props.prefix && 'rounded-l-none'}`}
                placeholder={props.disabled ? '' : props.placeholder}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
              />
            </div>
            {errors.map((error, index) => (
              <SightlyModernFormError
                key={index}
                id={props.name}
                error={error ? error : undefined}
              />
            ))}
          </div>
        )}
      />
    </div>
  )
}
