import React from 'react'

interface iProps {
	id: string
	handleChangeAction: Function
	disabled: boolean
	active: boolean
	activeColor: string
	text: string
}

const ActionButton: Function = ({
	id,
	handleChangeAction,
	disabled,
	active,
	activeColor,
	text
}: iProps) => {
	return (
		<div
			id={id}
			onClick={() => handleChangeAction()}
			style={{
				cursor: disabled ? 'not-allowed' : 'pointer',
				backgroundColor: active ? activeColor : 'white',
				borderRadius: 3,
				padding: '5px 16px 5px 16px',
				boxShadow: '0px 0px 0px 1px #D4D9D9 inset',
				color: active ? 'white' : '#696969',
				lineHeight: '22px',
				marginRight: 4,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				maxHeight: 32
			}}
		>
			{text}
		</div>
	)
}

export default ActionButton
