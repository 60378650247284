import React, { useState, useEffect } from "react";
import { CheckTreePicker, TagPicker } from 'rsuite'
import { multiSelectValues } from "@/views/ActivationReports/ActivationReportsNewReport";
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";

interface IProps {
  insertionOrders: multiSelectValues[]
  handleOnChange: Function
  formValues: ActivationReportConfiguration
}

export default function InsertionOrder({ insertionOrders = [], handleOnChange, formValues }: IProps) {
  const [insertionOrderIds, setInsertionOrderIds] = useState<number[]>([]);

  useEffect(() => {
    const ids = formValues.insertionOrders.map(io => io.id)
    setInsertionOrderIds(ids);
  }, [formValues.insertionOrders])


  return (
    <div data-testid='div-report-generator-select-insertion-orders'>
      <h5 className='mt-3 mb-2 text-base'>Insertion Orders</h5>
      <div className='mb-4 custom-tag-picker-color custom-tag-picker-color-checked'>
        <CheckTreePicker
          placeholder='Select Insertion Orders'
          value={insertionOrderIds}
          data={insertionOrders}
          onChange={(values) => {
            const selectedInsertionOrders = insertionOrders
              .filter((io) => values?.includes(io.value))
              .map((io) => ({ name: io.label, id: io.value }));
            handleOnChange('insertionOrders', selectedInsertionOrders);
          }}
          style={{ width: 350 }}
          preventOverflow
          virtualized
          data-testid='insertion-order-picker'
          />
      </div>
    </div>
  )
}
