import { userAccountAxios } from '../../axiosInstances'
import {
    ReportConfiguration
} from './reportGenerator-types'

type GetInsertionOrdersByAccountQueryKey = [string, number | undefined]

export const reportGeneratorApi = {
    saveReportConfiguration: async (accountId: any, reportConfiguration: ReportConfiguration) => {
        try {
            const configuration = {
                accountId,
                configuration: reportConfiguration
            }

            await userAccountAxios.post('/report-generator/configuration', configuration)
        } catch (error) {
            console.error('API error:', error)
        }
    },
    getReportConfigurations: async ({ queryKey }: { queryKey: GetInsertionOrdersByAccountQueryKey }) => {
        try {
            const [, accountId] = queryKey
            const url = `/report-generator/configuration?accountId=${accountId}`

            const { data } = await userAccountAxios.get(url)
            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    deleteReportConfiguration: async (id: number) => {
        try {
            return await userAccountAxios.delete(`/report-generator/configuration/${id}`)
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    downloadReport: async (reportConfiguration: ReportConfiguration) => {
        try {
            const response = await userAccountAxios.post(
                '/report-generator',
                reportConfiguration, {
                responseType: 'blob'
            })

            // Create a URL from the response data (Excel file)
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            // Set the file link properties
            fileLink.href = fileURL

            const fileExtension =
                (reportConfiguration.splitAdFormat || reportConfiguration.insertionOrderIds.length > 1)
                ? 'zip'
                : 'xlsx'

            const fileName = `${reportConfiguration.reportName}.${fileExtension}`

            fileLink.setAttribute('download', fileName)

            // Append the link to the DOM, click it to download the file, and then remove it
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
        } catch (error) {
            console.error('API error:', error)
        }
    }
}

export const getInsertionOrdersByAccount = async ({ queryKey }: { queryKey: GetInsertionOrdersByAccountQueryKey }) => {
    const [, accountId] = queryKey
    const url = `/account/${accountId}/insertion-orders?mapped=true`

    try {
        const { data } = await userAccountAxios.get(url)
        return data
    } catch (error) {
        // Handle the error, maybe throw the error
        console.error(error)
        throw error
    }
}
