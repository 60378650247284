export const NEW_BRAND_PROFILE = {
  HEADER: "Every brand needs a single source of truth to unlock its full power",
  DETAIL: "Codify and centralize your brand's unique perspective",
  WEBSITE_REGEX:/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
  ERROR_MESSAGE: "We're facing some issues. Please try again later or reach out to Support Desk.",
  WEBSITE_PREFIX: 'https://'
}

export const BASIC_INFO = {
  KEY_TERMS_CAPTION: 'Enter key terms, phrases and words that come to mind to capture your brand.',
  KEY_TERMS_AI_CAPTION: 'Mentality AI Recommendations',
  KEY_TERMS_INPUT_CAPTION: 'Type keyword(s) separated by commas and press Enter to add',
  ADD_ALL_LABEL: 'Add all',
  CLEAR_KEYWORDS_LABEL: 'Clear Keywords',
  VALIDATION_SUMMARY_MAX_CHAR_LIMIT: 1500,
  VALIDATION_SUMMARY_MAX_CHAR: (charLimit:number) => `Maximum characters of ${charLimit} accepted`,
  VALIDATION_BRAND_PROFILE_NAME:{
    MAX: 'Must be less than 50 characters',
    MIN: 'Must be greater than 1 character'
  },
  VALIDATION_INDUSTRY_VERTICAL:'Industry Vertical is required',
  VALIDATION_WEBSITE: 'Invalid website',
}

export const BRAND_PROFILE = {
  STATUS: {
    SUBMITTED:"SUBMITTED",
    DRAFT:"DRAFT"
  }
}

export const SCENARIOS_LABELS = {
  MAIN_TITLE: "Scenarios Section",
  MAIN_DESCRIPTION: "Tell us how your brand would like to approach the following emerging news and scenarios",
}

export const OPINIONS_LABELS = {
  MAIN_TITLE: "Opinions Section",
  MAIN_DESCRIPTION: "These selections work in tandem with your existing campaign targeting. For example, media would only run \
  across these content types if the user watching fits within the audience parameters for the campaign.",
}

export const AI_WARNING_LABELS = {
  TITLE: "Hello, I’m Mentality AI",
  FIRST_DESCRIPTION:"Heads up! I’m currently in my early stages of development, so be on the lookout for many updates in the future.",
  SECOND_DESCRIPTION:"Quick warning! My recommendations will override your previous answers. No worries because you can just change them if you don’t agree."
}

export const SUGGESTED_BOARD_NAMES = {
  TRENDING_MOMENTS: 'Trending Moments',
  INDUSTRY: 'Industry',
  COMPETITORS: 'Competitors',
  INFLUENCERS: 'Influencers',
  SAFEGUARD: 'Sensitive Topics',
  RELEVANCE: 'My Top Moments',
}

export const GENERAL_BOARD_DEFAULT_TYPES = {
  TRENDING_MOMENTS: 'trendingMoments',
  INDUSTRY: 'industry',
  COMPETITORS: 'competitors',
  INFLUENCERS: 'influencers',
  SAFEGUARD: 'safeguard',
  RELEVANCE: 'relevance',
}

export const HEADER_TEXT = "Please enter the brand name and the URL of the website and click \"Generate\" to generate the fields below. If the information we gather from your website or the open web does not hit the mark, feel free to tweak things manually."

export const COMPANY_SUMMARY_PLACEHOLDER = "Ex: Brand Mentality is a tool that helps businesses centralize their brand, harness real-time data, and navigate controversies with unified and consistent communication. It allows businesses to define their brand's mentality and codify its unique DNA, making decision-making simpler. Powered by AI, Brand Mentality provides personalized recommendations and helps businesses deploy and optimize their strategies across various platforms.";

export const COMPANY_MENTALITY_PLACEHOLDER = "Ex: Brand Mentality is a platform that values authenticity, profitability, and real-time decision-making, empowering businesses to navigate controversies, identify opportunities, and optimize marketing strategies through centralized branding, real-time data, and AI-generated recommendations.";
