import React, { SyntheticEvent } from 'react'
import { Modal } from 'rsuite';
import { ReactComponent as WarningIcon } from '@/assets/img/warning-large.svg';
import SightlyButton from '@/components/Sightly/SightlyButton';

interface IProps {
    onHide: (event: SyntheticEvent<Element, Event>) => void
    show: boolean
}


export const TokenExpirationAlertModal = ({onHide, show}: IProps) => {
    return (
        <Modal
            data-testid='token-expiration-modal'
            className='no-padding-modal justify-center items-center p-0'
            show={show}
            onHide={onHide}
            style={{
                display: "flex",
            }}
        >
            <div
                className='flex flex-col items-center gap-12 p-12 self-center'
            >
                <WarningIcon />
                <div className='font-medium text-xl text-[#7a7a7a]'>
                    Session Expired. Please refresh your screen.
                </div>
                <SightlyButton
                    id="closeRefreshModal"
                    data-testid='close-token-expiration-modal'
                    handleClick={() => window.location.reload()}
                    text="Refresh"
                />
            </div>
        </Modal>
    )
}
