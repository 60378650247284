import { number, z } from 'zod'

export type ActivationStatusesType = 'active' | 'pending' | 'complete'

export type GetActivationsPropsType = {
  brandProfileId: number | undefined
  page: number
  size: number
  status: ActivationStatusesType
}

const ActivationItemSchema = z.object({
  action: z.enum(['target']),
  activationItemId: z.number(),
  adGroups: z.array(z.object({ id: z.number(), name: z.string() })).nullable(),
  assignees: z.array(z.string()),
  clusterId: z.number(),
  clusterName: z.string(),
  isKeywords: z.boolean(),
  isPlacements: z.boolean(),
  keywordCount: z.number(),
  personas: z.array(z.string()),
  submittedDate: z.string(),
  implementedDate: z.string().nullable(),
  videoCount: z.number(),
  insertionOrderId: z.number(),
  insertionOrderName: z.string()
})

export const ActivationItemsSchema = z.array(ActivationItemSchema)

export type ActivationItemType = z.infer<typeof ActivationItemSchema>

export const ActivationAdGroupsResultSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    searchName: z.string(),
    children: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        searchName: z.string(),
        children: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
            isCampaign: z.literal(true),
            searchName: z.string(),
            children: z.array(
              z.object({
                id: z.number(),
                name: z.string(),
                searchName: z.string(),
                isAdGroup: z.literal(true)
              })
            )
          })
        )
      })
    )
  })
)
export const ActivationAdGroupsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    searchName: z.string(),
    isAdGroup: z.literal(true)
  })
)
export const ActivationCampaignsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    searchName: z.string(),
    children: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        searchName: z.string(),
        children: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
            isCampaign: z.literal(true),
            searchName: z.string(),
          })
        )
      })
    )
  })
)

export const ActivationCampaignAdGroupSchema = z.object({
    id: z.number(),
    name: z.string(),
    isCampaign: z.literal(true),
    searchName: z.string(),
    children: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        searchName: z.string(),
        isAdGroup: z.literal(true)
      })
    )
  })

export type ActivationAdGroupsResultType = z.infer<typeof ActivationAdGroupsResultSchema>
export type ActivationCampaignsSchemaType = z.infer<typeof ActivationCampaignsSchema>
export type ActivationAdGroupsSchemaType = z.infer<typeof ActivationAdGroupsSchema>
export type ActivationCampaignAdGroupSchemaType = z.infer<typeof ActivationCampaignAdGroupSchema>

export type ActivationAdGroupType = {
  id: number
  name: string
}

export type PatchActivationsItemType = {
  targetListId: number
  keywordAdGroups?: number[]
  videoAdGroups?: number[]
}