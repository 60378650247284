import React, { ReactElement } from 'react'
import Message from 'rsuite/lib/Message'
export const InfoPage = ({
    message,
    type
}: {
    message: string | ReactElement
    type?: 'error' | 'info' | 'success' | 'warning'
}) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
            }}
        >
            <Message
                showIcon
                type={type || 'info'}
                description={message}
            />
        </div>
    )
}
