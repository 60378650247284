import React from 'react'
import useUser from '@/hooks/useUser'
import { useProductUsage } from '@/hooks/subscription/useProductUsage'
import { Progress } from 'rsuite'
import { accentColor } from '@/assets/jss/colorConstants'
import Tooltip from '@/components/TailwindTooltip'

const BUTTON_LABEL = 'Upgrade'
const QUOTA_LABEL = 'Total'
export const ProductCountTracker = (props: {
  productName: string
  title: string
  direction?: 'row' | 'col'
  additionalStyle?: string,
  dataTestId?: string
}) => {
  const { currentAccount } = useUser()
  const { productQuotaTally } = useProductUsage({
    accountId: currentAccount?.accountId,
    productName: props.productName
  })

  const getCurrentProgress = () => {
    if (!productQuotaTally) return 100
    const { currentProductCount, productQuota } = productQuotaTally
    return (currentProductCount / productQuota) * 100
  }
  return (
    <div
      data-testid={`${props.dataTestId}-banner`}
      className={`${props.additionalStyle}
       flex justify-between gap-4 text-white bg-sightlySlate
      ${props.direction ? 'flex-col' : 'flex-row'}
      `}
    >
      <div className="flex flex-col flex-1 gap-1">
        <p
          data-testid='tracker-text-value'
          className={`font-semibold`}
        >
          {props.title}
        </p>
       
        {productQuotaTally && (
          <div data-testid="tracker-progress-line">
            <Tooltip
              content={
                productQuotaTally.currentProductCount === productQuotaTally.productQuota
                  ? "This account has reached it’s limit"
                  : ""
              }
            >
              <div>
                <Progress.Line
                  percent={getCurrentProgress()}
                  showInfo={false}
                  strokeColor={accentColor}
                  style={{ padding: 0 }}
                />
                <div className="flex justify-between">
                  <p data-testid="current-count">{productQuotaTally.currentProductCount}</p>
                  <p data-testid="max-limit">
                    {productQuotaTally.productQuota} {QUOTA_LABEL}
                  </p>
                </div>
              </div>
            </Tooltip>
          </div>
        )}


      </div>

      <a
        data-testid='tracker-btn-link'
        className="font-medium p-2 w-max rounded-md ring-1 bg-white text-sightlyBlue 
        no-underline h-1/2 hover:text-white hover:bg-sightlyBlue hover:no-underline"
        target="_blank"
        rel="noopener"
        href="https://www.sightly.com/contact-us/"
      >
        {BUTTON_LABEL}
      </a>
    </div>
  )
}
