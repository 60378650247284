import React from 'react'
import { Button, Modal } from 'rsuite'

export const ConfirmationDialog = (props: {
  show: boolean
  onHide: () => void
  handleConfirm: () => void
  handleCancel: () => void
  body: string
  action: 'Delete' | 'Confirm'
  style?: React.CSSProperties
}) => {
  const { show, onHide, handleCancel, handleConfirm, style, body, action } = props
  return (
    <div data-testid ="act-mgr-target-moment-drawer-confirm-dialog">
    <Modal
      show={show}
      onHide={onHide}
      backdrop
      style={style || { position: 'fixed', top: '35%', left: '30%' }}
    >
      <Modal.Header>
        <Modal.Title>{action === 'Delete' ? 'Warning' : 'Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <div className='flex justify-end gap-6'>
        <div 
          data-testid ="act-mgr-target-moment-drawer-confirm-dialog-confirm-btn"
          id ="act-mgr-target-moment-drawer-confirm-dialog-confirm-btn"
        >
        <Button
          onClick={handleConfirm}
          appearance="primary"
          color={action === 'Delete' ? 'red' : 'blue'}
        >
          {action}
        </Button>
        </div>
        <div 
          data-testid ="act-mgr-target-moment-drawer-confirm-dialog-cancel-btn"
          id ="act-mgr-target-moment-drawer-confirm-dialog-cancel-btn"
        >
          <Button
            onClick={handleCancel}
            appearance="subtle"
          >
            Cancel
          </Button>
        </div>
        </div>
      </Modal.Footer>
    </Modal>
    </div>
  )
}
