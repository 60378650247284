import { z } from 'zod'

//scenarios:
export const ScenarioSchema = z.object({
    id: z.number(),
    deletedAt: z.string().nullable(),
    name: z.string().min(1, 'Must have at least one character'),
    themes: z.array(z.any()) // We cannot validate the type of the themes array because we need to transform it from an array of objects to an array of numbers and vice versa for reading, populating the CheckTreePicker and patching the scenario
})
export type ScenarioType = z.infer<typeof ScenarioSchema>

const GetScenarioSchema = ScenarioSchema.pick({
    id: true,
    name: true,
    deletedAt: true
})
export const ScenariosFromGetAllSchema = z.array(GetScenarioSchema)
export type ScenarioFromGetAllType = z.infer<typeof GetScenarioSchema>

export const PostScenarioSchema = ScenarioSchema.pick({
    name: true,
    themes: true,
})
export type PostScenarioType = z.infer<typeof PostScenarioSchema>

export const PatchScenarioSchema = ScenarioSchema.pick({
    name: true,
    themes: true,
    deletedAt: true
})
export type PatchScenarioType = z.infer<typeof PatchScenarioSchema>

//themes:
export const ThemeSchema = z.object({
    id: z.number(),
    description: z.string().min(1, 'Must have at least one character'),
    name: z.string().min(1, 'Must have at least one character'),
    deletedAt: z.string().nullable()
})
export const ThemesSchema = z.array(ThemeSchema)
export type ThemeType = z.infer<typeof ThemeSchema>

export const PostThemeSchema = ThemeSchema.pick({
    description: true,
    name: true
})
export type PostThemeType = z.infer<typeof PostThemeSchema>

export const PatchThemeSchema = ThemeSchema.pick({
    description: true,
    name: true,
    deletedAt: true
})
export type PatchThemeType = z.infer<typeof PatchThemeSchema>
