import React from 'react'
import { ReactComponent as NoQueries } from '@/assets/img/noActionJustifications.svg'
import { QueryActionJustificationType} from '../../types'
import { ActionBadge } from "@/views/Discover/Moments/v2/components/ActionBadge";
import { BrandProfileLink } from "@/views/Discover/Moments/v2/components/BrandProfileLink";
import { LoadingPage } from "@/components/LoadingPage";
interface IProps {
    brandProfileId: number | undefined
    isLoading: boolean
    queries: QueryActionJustificationType[] | undefined,
}

export const QueriesSection = ({ brandProfileId, queries, isLoading }: IProps) => {
    const target = queries ? queries?.filter(query => query.action === 'target') : []
    const block = queries ? queries?.filter(query => query.action === 'block') : []
    const monitor = queries ? queries?.filter(query => query.action === 'monitor') : []
    const noaction = queries ? queries?.filter(query => query.action === 'no action' || query.action === 'noaction') : [];

    const getQueriesSection = (label:string, queries:QueryActionJustificationType[]) => (
        <div className="pb-4">
            <div className="pb-1 font-bold text-gray-400">{label}</div>
            <div className="flex flex-wrap">
                {queries?.map((data, index) => (
                    <ActionBadge key={index} action={data.action} label={data.name}/>
                ))}
            </div>
        </div>
    )

    const noQueries =
        <div
            data-testid="div-moment-queries-no-queries"
            className="grid justify-items-center p-16 text-center"
        >
            <NoQueries data-testid="no-queries-icon"/>
            <h5>No Queries</h5>
            <div className="justify-self-center max-h-100 overflow-auto">There are no queries from your brand profile matching this moment</div>
        </div>

    const hasQueries =
        <div
            data-testid="div-moment-queries-has-queries"
            className="h-full overflow-auto"
        >
            {target?.length > 0 && getQueriesSection('Targeted Queries', target)}
            {block?.length > 0 && getQueriesSection('Blocked Queries', block)}
            {monitor?.length > 0 && getQueriesSection('Monitored Queries', monitor)}
            {noaction?.length > 0 && getQueriesSection('Undecided Categories', noaction)}
        </div>

    const queriesComponent = queries?.length === 0 ? noQueries : hasQueries

    return (
        <div
            data-testid="div-moment-queries"
            className="p-4 bg-white rounded-xl"
        >
            <div className="flex justify-between">
                <h4 className="pb-2">Queries</h4>
                <BrandProfileLink dataTestId="brand-profile-queries-link" brandProfileId={brandProfileId} section="queries/brands"/>
            </div>
            <div>
                {isLoading
                    ? <LoadingPage message="Loading" />
                    : queriesComponent
                }
            </div>
        </div>
    )
}
