import React from 'react'

interface IProps {
    text: string
    handleClick: () => void
    dataTestId?: String
}

export const ActionButton = ({ text, handleClick, dataTestId }: IProps) => {

    return (
        <button
            data-testid={dataTestId}
            className="flex items-center gap-3 px-4 py-1 text-xs font-bold bg-white rounded-md shadow-md hover:bg-slate-100 text-sightlySlate"
            onClick={handleClick}
        >
            {text}
        </button>
    )
}
