import { listBuilderAxios } from "@/axiosInstances"
import { IMoment, momentSchema } from "@/views/Trends/types"

export const moments = {
    get: async (offset: number, limit: number, minPublishDate: String, 
      search?: string) => {
      let url = `/moments?offset=${offset}&limit${limit}&minPublishDate${minPublishDate}`
      if (search) url += `&search=${search}`

      return listBuilderAxios.get<IMoment[]>(url)
    },
    getById: async (momentId: number) => {
      const url = `/moments/${momentId}`
      const result = await listBuilderAxios.get<IMoment>(url)
      return momentSchema.parse(result.data)
    }
}
