import React from "react";
import CheckBoxButton from "@/components/Sightly/SightlyFormElements/CheckBoxButton";
import { MessageTailwind } from "@/components/MessageTailwind";

export default function ReportTypesSection({ reports, handleCheckboxChange, selectedReports }) {
    const ref = React.useRef()

    return (
      <div data-testid='div-report-generator-select-report-types'>
        <h5 className="pt-2">2. Choose Report Type(s)</h5>
        <label className="pl-4 text-gray-500">
          {' '}
          This determines the key data that is represented in each tab of your report
        </label>
        <div className="space-y-4 pl-4" ref={ref}>
          {reports.map((report) => (
            <>
              <CheckBoxButton
                container={() => ref.current}
                key={report.label}
                id={`${report.value} report`}
                handleChange={(checked) => handleCheckboxChange(report.value, checked)}
                label={report.label}
              />
              <MessageTailwind
                show={report.showMessage && selectedReports.includes(report.value)}
                type="info"
                message={report.messageText}
              />
            </>
          ))}
        </div>
      </div>
    )
}
