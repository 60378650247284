import React from 'react'
import { ReactComponent as WarningImage } from '../../../../assets/img/warning.svg'
import SightlyAlert from "../../../../components/SightlyAlert"

export default function UploadErrorAlert({
      uploadStats,
      showWarningPopup,
      setShowWarningPopup,
      setShowSuccessPopup
    }) {

    const {
        invalidIds,
        invalidActions,
        duplicateIds,
        channelIds,
        videoIds
    }  = uploadStats

    const buildTextBody = () => {
        return [
          'Invalid IDs will not be processed but will still be available in your list. ' +
          'Invalid Actions automatically default to the Block Action. ' +
          'Duplicate IDs will be removed.',
          <p key="p"></p>,
          ...addInvalidIdsText(),
          ...addInvalidActionsText(),
          ...addDuplicatedIdsText(),
          ...addChannelIdsText(),
          ...addVideoIdsText()
        ]
    }

    const addInvalidIdsText =  () => {
        return [<br key="br1" />, <b key="b1">Invalid IDs:</b>, ' ', invalidIds?.length || 0]
    }
    const addInvalidActionsText =  () => {
        return [<br key="br2" />, <b key="b2">Invalid Actions:</b>, ' ', invalidActions?.length || 0]
    }
    const addDuplicatedIdsText =  () => {
        return [<br key="br3" />, <b key="b3">Duplicate IDs:</b>, ' ', duplicateIds?.length || 0]
    }
    const addChannelIdsText =  () => {
        return [<br key="br4" />, <b key="b4">Channel IDs:</b>, ' ', channelIds?.length || 0]
    }
    const addVideoIdsText =  () => {
        return [<br key="brg" />, <b key="b5">Video IDs:</b>, ' ', videoIds?.length || 0]
    }

    return (
      <SightlyAlert
        headerColor="#EE7731"
        headerIcon={<WarningImage />}
        text={buildTextBody()}
        headerText={'Invalid IDs Found'}
        topHeaderText="Warning"
        closeButtonText="Continue"
        show={showWarningPopup}
        setShow={setShowWarningPopup}
        onClose={() => setShowSuccessPopup(true)}
      />
    )
}
