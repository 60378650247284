import { InfoPage } from '@/components/InfoPage';
import { isDateBetween } from '@/utils';
import TopicTrendCard from '@/views/Trends/components/TopicTrendCard';
import { TopicTrendCardType, ICategory, ITopic, ITrend, RelevanceClassification } from '@/views/Trends/types';
import React, { useMemo, useState, useEffect } from 'react';
import { Placeholder } from 'rsuite';

interface IProps {
  cardType: TopicTrendCardType;
  query: any;
  isLoading: boolean;
  category: ICategory;
  startDate: Date;
  endDate: Date;
}

const TopicTrendCardList = ({ cardType, query, isLoading, category, startDate, endDate }: IProps) => {
  const [isCalculatingFinalData, setIsCalculatingFinalData] = useState(true);
  const [time, setTime] = useState(0);

  useEffect(() => {
    setIsCalculatingFinalData(true);
    setTime(performance.now())
  }, [query?.data, cardType, startDate, endDate, category]);

  const filterByCategory = (selectedCategory: ICategory, data: ITopic | ITrend) => {
    return data.categories.some((category) => 
      selectedCategory.codes?.includes(category.taxonomyCode) &&
       !(selectedCategory.exclude && selectedCategory.exclude.includes(category.taxonomyCode))
    );
 }

  const finalData = useMemo(() => {
    if (query && query.data) {
      const data = query.data
        .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .filter((d: any) => {
          const isCategoryValid = category.id === -1 || filterByCategory(category, d)
          const isCreatedAtValid = isDateBetween(d.updatedAt, startDate, endDate);
          return isCategoryValid && isCreatedAtValid;
        });
      return data;
    }
    return [];
  }, [query?.data, startDate, endDate, category]);

  useEffect(() => {
    if (performance.now() - time < 2000) {
      const timer = setTimeout(() => setIsCalculatingFinalData(false), 250);
      return () => clearTimeout(timer);
    } else {
      setIsCalculatingFinalData(false)
    }
  }, [finalData]);

  if (isLoading || isCalculatingFinalData) {
    return (
      <div className="grid grid-flow-row gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        {[...Array(6)].map((_, index) => (
          <Placeholder.Graph active key={index} />
        ))}
      </div>
    );
  }

  if (query && query.data) {
    if (query.data.length === 0) {
      return (
        <InfoPage message={`There are no ${cardType}s for your filters`} />
      )
    } else {
      return (
        <div className="grid grid-flow-row gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
          {query.data.map((d: any) => (
            <TopicTrendCard
              key={`topic-trend-card-${d.id}`}
              id={d.id}
              type={cardType}
              title={d.name}
              description={d.overview}
              relevance={d.metrics.sentimentScore > 0 ? RelevanceClassification.High 
                : d.metrics.sentimentScore < 0 ? RelevanceClassification.Low
                : RelevanceClassification.Medium}
              date={d.createdAt}
              image={d.imageUrl}
              lazy={true}
            />
          ))}
        </div>

      );
    }
  }

  return (
    <div className="grid grid-flow-row gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
      {finalData.map((d: ITopic | ITrend) => (
        <TopicTrendCard
          key={`topic-trend-card-${d.id}`}
          id={d.id}
          type={cardType}
          title={d.name}
          description={d.overview}
          relevance={
            d.metrics.sentimentScore > 0
              ? RelevanceClassification.High
              : d.metrics.sentimentScore < 0
              ? RelevanceClassification.Low
              : RelevanceClassification.Medium
          }
          date={typeof d.updatedAt === 'string' ? d.updatedAt : d.updatedAt.toISOString()}
          image={d.imageUrl}
          lazy={true}
        />
      ))}
    </div>
  );
};

export default TopicTrendCardList;
