import { Link, Navigate, Outlet, useSearch } from '@tanstack/react-location';
import React from 'react';
import { InfoPage } from '@/components/InfoPage';
import useUser from '@/hooks/useUser';
import useThirdPartyInitializer from '@/useInitializeThirdParty.js';
import { Layout } from '@/layouts/Layout';

export const AuthRoute = () => {
    const search = useSearch();
    const { accounts, user, currentAccount } = useUser();
    useThirdPartyInitializer(user, currentAccount);
    let authToken = localStorage.getItem('token');

    if (authToken) {
        if (accounts?.length === 0) {
            return (
                <div style={{ height: '100vh' }}>
                    <InfoPage
                        message={
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 24 }}>
                                You have no accounts, please contact your admin to have an account assigned to your
                                user.
                                <Link to="/login">Back to Login</Link>
                            </div>
                        }
                    />
                </div>
            )
        }
        return (
            <Layout>
                <Outlet />
            </Layout>
        )
    }
    if (window.location.pathname !== '/login') {
        search.redirect = window.location.pathname + window.location.search + window.location.hash
        delete search.brandProfileId
    }

    return (
        <Navigate
            to="/login"
            search={search}
        />
    )
}
