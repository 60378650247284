import { useQuery } from '@tanstack/react-query'
import { logError } from '../../../utils'
import { activationsApi } from './activations-api'
import { activationsRqKeys } from './activationsRqKeys'

interface IProps {
    brandProfileId: number | undefined
}
export const useActivationStatusCount = ({ brandProfileId }: IProps) => {
    const statusQuery = useQuery(
        activationsRqKeys.activationStatusCount(brandProfileId),
        () => activationsApi.getStatusCounts({ brandProfileId }),
        {
            onError: (err) => {
                logError(err)
            },
            enabled: !!brandProfileId,
            refetchInterval: 30000
        }
    )
    return statusQuery
}
