// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai_generate_modal_root  .rs-modal-header-close {
  position: absolute; /* Makes the button positioning relative to the nearest positioned ancestor */
  top: 2px; /* Distance from the top of the modal content */
  right: 8px; /* Distance from the right of the modal content */
  z-index: 1051; /* Ensure it's above other modal content (rsuite's modal z-index is usually around 1050) */
}

.ai_generate_modal_root  .rs-modal-content {
  background: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/AIGenerate/GenerateAIModalCustom.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,6EAA6E;EACjG,QAAQ,EAAE,+CAA+C;EACzD,UAAU,EAAE,iDAAiD;EAC7D,aAAa,EAAE,0FAA0F;AAC3G;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".ai_generate_modal_root  .rs-modal-header-close {\n  position: absolute; /* Makes the button positioning relative to the nearest positioned ancestor */\n  top: 2px; /* Distance from the top of the modal content */\n  right: 8px; /* Distance from the right of the modal content */\n  z-index: 1051; /* Ensure it's above other modal content (rsuite's modal z-index is usually around 1050) */\n}\n\n.ai_generate_modal_root  .rs-modal-content {\n  background: transparent;\n  border: none;\n  padding: 0;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
