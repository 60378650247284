import React, { useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, LabelList, CartesianGrid } from 'recharts'
import { SelectPicker } from 'rsuite';

const GraphsOverview = () => {
    const [timeRange, setTimeRange] = useState<number>(1)

    const dummyData = [
        { date: '2024-10-01', posts: 10 },
        { date: '2024-10-02', posts: 5 },
        { date: '2024-10-03', posts: 7 },
        { date: '2024-10-04', posts: 12 },
    ];

    const timeRangeOptions = [
        { value: 1, label: '15 Days' },
        { value: 2, label: '30 Days' },
        { value: 4, label: '60 Days' }
    ]

    return (
        <>
            {/* Date range filter */}
            <div className='flex gap-2 items-center justify-between'>
                <span className="text-sm">Topics by day</span>
                <SelectPicker
                    data={timeRangeOptions}
                    value={timeRange}
                    searchable={false}
                    cleanable={false}
                    onChange={(value) => setTimeRange(value)}
                />
            </div>

            {/* Bar chart */}
            <ResponsiveContainer width="100%" height="20%">
                <BarChart
                    margin={{ bottom: -10 }}
                    data={dummyData}
                >
                    <CartesianGrid horizontal={true} vertical={false} /> {/* Add horizontal grid lines */}
                    <XAxis
                        dataKey="date"
                        axisLine={false}
                        tickLine={false}
                        style={{
                            fontSize: '8px'
                        }}
                    />
                    <Bar
                        dataKey="posts"
                        fill="#651AEB"
                    >
                        <LabelList dataKey="posts" position="center" style={{ fontSize: '1rem', fontWeight: 'bold', fill: '#fff' }} /> 
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            <div className='text-white flex flex-col gap-6 items-center'>
                <div className='rounded-3xl py-4 px-20 flex flex-col justify-center items-center bg-[#4F46E5]'>
                    <span className='text-xl'>Topics</span>
                    <span className='text-2xl font-bold'>41</span>
                </div>
                <div className='rounded-3xl py-4 px-12 flex flex-col justify-center items-center bg-[#7B61FF]'>
                    <span className='text-xl'>Trends</span>
                    <span className='text-2xl font-bold'>41</span>
                </div>
                <div className='flex gap-8'>
                    <div className='rounded-3xl p-4 flex flex-col justify-center items-center bg-[#48C294]'>
                        <span className='text-xl'>Moments</span>
                        <span className='text-2xl font-bold'>41</span>
                    </div>
                    <div className='rounded-3xl p-4 flex flex-col justify-center items-center bg-[#48C294]'>
                        <span className='text-xl'>Narratives</span>
                        <span className='text-2xl font-bold'>41</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GraphsOverview;