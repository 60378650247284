import PortalModal from '@/components/PortalModal/PortalModal';
import React from 'react';

interface AylienBoardPortalModalProps {
  showTooManyTrendsError: boolean
  setShowTooManyTrendsError: Function
}

export default function AylienBoardPortalModal(props:AylienBoardPortalModalProps) {
  const {
    showTooManyTrendsError,
    setShowTooManyTrendsError
  } = props;

  return <PortalModal
    hasClose
    open={showTooManyTrendsError}
    handleClose={() => setShowTooManyTrendsError(false)}
    footerButtons={[]}
  >
    <div
        style={{
            width: 500,
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            gap: 48
        }}
    >
        <h4>Unable to download</h4>
        <div>
            Our system can only handle downloading 1,000 moments maximum. Please add more filters and
            try again.
        </div>
    </div>
  </PortalModal>;
}