/**
 * SerializableSet class:
 * A subclass of the built-in Set class that provides a custom serialization method.
 * When the set is serialized to JSON, it is represented as an array.
 */
export class SerializableSet<T> extends Set<T> {
  /**
   * toJSON method:
   * Overrides the default toJSON method to convert the set into an array.
   *
   * @returns {Array<T>} - The set elements as an array.
   */
  toJSON(): Array<T> {
    return Array.from(this);
  }
}