import React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { logError } from '@/utils'
import { activationsApi } from '@/views/Discover/Activations/v2/hooks/activations-api'
import { activationsRqKeys } from '@/views/Discover/Activations/v2/hooks/activationsRqKeys'
import { ActivationItemType, PatchActivationsItemType } from '@/views/Discover/Activations/v2/activations-types'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import { TEN_MINUTES } from '@/views/Discover/Activations/v2/ActivationsManager/constants'

interface IProps {
  brandProfileId: number | undefined,
  startDate?: Date
  endDate?: Date
  insertionOrderId?: string[]
}

const formatDateyyyyMMdd = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}


export const useTodoActivations = ({ brandProfileId, startDate, endDate, insertionOrderId}: IProps) => {
  const QUERY_KEY = activationsRqKeys.todoActivations({ brandProfileId })
  const bpQuery = useBrandProfiles({
    submittedOnly: true
  })
  const brandProfileIds = React.useMemo(
    () => bpQuery.data?.map(({ brandProfileId }) => brandProfileId) || [],
    [bpQuery.data]
  )
  const queryClient = useQueryClient()
  const startDateString = startDate ? formatDateyyyyMMdd(startDate) : undefined
  const endDateString = endDate ? formatDateyyyyMMdd(endDate) : undefined
  const insertionOrderIdString = insertionOrderId ? insertionOrderId : undefined


  const activationsQuery = useQuery(QUERY_KEY, () => activationsApi.getTodoActivations({ brandProfileId, startDateString, endDateString, insertionOrderIdString }), {
    onError: (err) => {
      logError(err)
    },
    enabled: !!brandProfileId
  })

  const removeItemFromTodo = useMutation(activationsApi.removeItemFromTodo, {
    onSettled: () => {
      queryClient.invalidateQueries(activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds))
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onSuccess: () => {
      toast.success('Changes saved')
    },
    onMutate: (args) => {
      queryClient.setQueryData(QUERY_KEY, (old: any) => {
        return old.filter((i: ActivationItemType) => i.activationItemId !== args.activationListItemId)
      })
      queryClient.setQueryData(
        activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds),
        (
          old:
            | Map<
                number,
                {
                  activeCount: number
                  pendingCount: number
                  completeCount: number
                }
              >
            | undefined
        ) => {
          if (!old) return undefined
          const next = new Map(old)
          if (!brandProfileId) return undefined

          const targetValue = next.get(brandProfileId)
          if (!targetValue) return old

          targetValue.activeCount -= 1
          next.set(brandProfileId, targetValue)
          return next
        }
      )
    },
    onError: (err) => {
      logError(err)
    }
  })

  const bulkDeleteFromTodo = useMutation(activationsApi.bulkDeleteFromTodo, {
    onSettled: () => {
      queryClient.invalidateQueries(activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds))
      queryClient.invalidateQueries(QUERY_KEY)
    },
    onSuccess: () => {
      toast.success('Activation/s deleted')
    },
    onMutate: (args) => {
      queryClient.setQueryData(QUERY_KEY, (old: any) => {
        return old.filter(
          (activationItem: ActivationItemType) => !args.activationListItemIds.has(activationItem.activationItemId)
        )
      })
      queryClient.setQueryData(
        activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds),
        (
          old:
            | Map<
                number,
                {
                  activeCount: number
                  pendingCount: number
                  completeCount: number
                }
              >
            | undefined
        ) => {
          if (!old) return undefined
          const next = new Map(old)
          if (!brandProfileId) return undefined
          const targetValue = next.get(brandProfileId)
          if (!targetValue) return old

          targetValue.activeCount -= args.activationListItemIds.size
          next.set(brandProfileId, targetValue)
          return next
        }
      )
    },
    onError: (err) => {
      logError(err)
    }
  })

  return {
    activationsQuery,
    removeItemFromTodo,
    bulkDeleteFromTodo
  }
}

export const useInProcessActivations = ({ brandProfileId, startDate, endDate, insertionOrderId }: IProps) => {
  const QUERY_KEY = activationsRqKeys.inProcessActivations({ brandProfileId })
  const startDateString = startDate ? formatDateyyyyMMdd(startDate) : undefined
  const endDateString = endDate ? formatDateyyyyMMdd(endDate) : undefined
  const insertionOrderIdString = insertionOrderId ? insertionOrderId : undefined
  return useQuery(QUERY_KEY, () => activationsApi.getInProcessActivations({ brandProfileId, startDateString, endDateString, insertionOrderIdString }), {
    onError: (err) => {
      logError(err)
    },
    enabled: !!brandProfileId
  })
}

export const useImplementedActivations = ({ brandProfileId, startDate, endDate, insertionOrderId }: IProps) => {
  const QUERY_KEY = activationsRqKeys.implementedActivations({ brandProfileId })
  const startDateString = startDate ? formatDateyyyyMMdd(startDate) : undefined
  const endDateString = endDate ? formatDateyyyyMMdd(endDate) : undefined
  const insertionOrderIdString = insertionOrderId ? insertionOrderId : undefined
  return useQuery(QUERY_KEY, () => activationsApi.getImplementedActivations({ brandProfileId, startDateString, endDateString, insertionOrderIdString  }), {
    onError: (err) => {
      logError(err)
    },
    staleTime: 500,
    enabled: !!brandProfileId
  })
}

export const useInsertionOrderHierarchy = (props: { ioIds: string[] }) => {
  const { ioIds } = props
  const QUERY_KEY = activationsRqKeys.activationInsertionOrderHierarchy({ ioIds })
  return useQuery(QUERY_KEY, () => activationsApi.getInsertionOrderHierarchy({ ioIds }), {
    onError: (err) => {
      logError(err)
    },
    refetchOnMount: true,
    staleTime: TEN_MINUTES,
    enabled: !!ioIds.length
  })
}

export const useSubmitTargetActivations = (props: {
  brandProfileId: number | undefined
  setOpen: (val: boolean) => void
  setSelectedActivationIds: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  const { brandProfileId, setOpen, setSelectedActivationIds } = props
  const queryClient = useQueryClient()
  const bpQuery = useBrandProfiles({
    submittedOnly: true
  })
  const brandProfileIds = React.useMemo(
    () => bpQuery.data?.map(({ brandProfileId }) => brandProfileId) || [],
    [bpQuery.data]
  )
  return useMutation(
    async ({ activations }: { activations: PatchActivationsItemType[] | undefined }) => {
      try {
        if (!activations) return
        await activationsApi.patchActivations({ brandProfileId, activations })
        return activationsApi.changeStatusToPending({
          brandProfileId,
          activations: activations.map((a) => a.targetListId)
        })
      } catch (error) {
        logError(error)
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(activationsRqKeys.brandProfilesActivationStatusCount(brandProfileIds))
        queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
      },
      onSuccess: () => {
        toast.success('Targeted Activations are submitted.')
        setOpen(false)
        setSelectedActivationIds([])
      },
      onError: (err) => {
        logError(err, { info: 'error submitting todo items' })
      }
    }
  )
}
