import * as Yup from 'yup'

export const listsObjValidation = Yup.array()
	.of(
		Yup.object().shape({
			targetType: Yup.string().defined(),
			targetTypeId: Yup.number().defined(),
			createdDate: Yup.date().required(),
			objectiveId: Yup.number().required(),
			objectiveName: Yup.string().required(),
			smartListId: Yup.number().required(),
			smartListName: Yup.string().required(),
			versionId: Yup.number().required(),
			archived: Yup.bool().required(),
			hasEnrichedData: Yup.bool().required(),
			active: Yup.bool().required(),
			uploaded: Yup.bool().required()
		})
	)
	.test(
		'idTest',
		'The api sent smartlists that have duplicate versionIds. Please address in api or database as this breaks the UI.',
		(lists) => {
			let seen = new Set()
			var hasDuplicates = lists.some(function(currentObject) {
				return seen.size === seen.add(currentObject.versionId).size
			})

			return !hasDuplicates
		}
	)

export const versionFilter = Yup.object().shape({
	actionIds: Yup.array().defined(),
	categories: Yup.array().defined(),
	countries: Yup.array().defined(),
	enrichedKids: Yup.bool().required(),
	iabCategories: Yup.array().defined(),
	smartListLanguages: Yup.array().defined(),
	uploadDate: Yup.string().defined(),
	videoDurationSeconds: Yup.object().shape({
		max: Yup.number().defined(),
		min: Yup.number().defined()
	}),
	views: Yup.object().shape({
		max: Yup.number().defined(),
		min: Yup.number().defined()
	}),
	youtubeKids: Yup.bool().required()
})

export const uploadedVersionFilter = Yup.object().shape({
	actionIds: Yup.array().defined(),
	categories: Yup.array().defined(),
	countries: Yup.array().defined(),
	enrichedKids: Yup.bool().required(),
	smartListLanguages: Yup.array().defined(),
	uploadDate: Yup.string().defined(),
	videoDurationSeconds: Yup.object().shape({
		max: Yup.number().defined(),
		min: Yup.number().defined()
	}),
	views: Yup.object().shape({
		max: Yup.number().defined(),
		min: Yup.number().defined()
	}),
	youtubeKids: Yup.bool().required()
})

export const versionObj = Yup.object().shape({
	blockVeto: Yup.number().required(),
	brandProfileName: Yup.string().required(),
	brandProfileId: Yup.number().required(),
	blockedKeywords: Yup.array()
		.of(Yup.string())
		.defined(),
	createdBy: Yup.number().required(),
	createdDate: Yup.date().required(),
	uploaded: Yup.bool().required(),
	isVideo: Yup.bool().required(),
	objectiveName: Yup.string().required(),
	smartListId: Yup.number().required(),
	smartListName: Yup.string().required(),
	versionId: Yup.number().required(),
	filter: Yup.object().required(),
	stats: Yup.object().required(),
	updatedDate: Yup.date().required()
})

export const uploadedStatsObj = Yup.object().shape({
	blockChannelCount: Yup.number().required(),
	blockVideoCount: Yup.number().required(),
	blockViewCount: Yup.number().required(),
	channelCount: Yup.number().required(),
	monitorChannelCount: Yup.number().required(),
	monitorVideoCount: Yup.number().required(),
	monitorViewCount: Yup.number().required(),
	targetChannelCount: Yup.number().required(),
	targetVideoCount: Yup.number().required(),
	targetViewCount: Yup.number().required(),
	videoCount: Yup.number().required()
})

export const nonUploadedVideoStatsObj = Yup.object().shape({
	blockItemCount: Yup.number().required(),
	blockViewCount: Yup.number().required(),
	itemCount: Yup.number().required(),
	monitorItemCount: Yup.number().required(),
	monitorViewCount: Yup.number().required(),
	targetItemCount: Yup.number().required(),
	targetViewCount: Yup.number().required()
})

export const nonUploadedChannelStatsObj = Yup.object().shape({
	blockItemCount: Yup.number().required(),
	itemCount: Yup.number().required(),
	monitorItemCount: Yup.number().required(),
	targetItemCount: Yup.number().required()
})

export const uploadedListObjValidation = Yup.array()
	.of(Yup.object())
	.max(50000, 'Your file must have no more than 50,000 IDs')

export const postListVersionResult = Yup.object()
	.shape({
		versionId: Yup.number().required()
	})
	.required()

export const postUploadedListVersionResult = Yup.object().shape({
	invalidIds: Yup.array().defined(),
	invalidActions: Yup.array().defined(),
	duplicateIds: Yup.array().defined(),
	channelIds: Yup.array().defined(),
	videoIds: Yup.array().defined(),
	versionId: Yup.number().required()
})

export const channelsSchema = Yup.array().of(
	Yup.object().shape({
		actionId: Yup.number()
			.strict(true)
			.defined(),
		created: Yup.string()
			.strict(true)
			.required(),
		description: Yup.string()
			.strict(true)
			.defined(),
		id: Yup.string()
			.strict(true)
			.required(),
		name: Yup.string()
			.strict(true)
			.required(),
		subscribers: Yup.number()
			.strict(true)
			.defined(),
		thumbnail: Yup.string()
			.strict(true)
			.defined(),
		views: Yup.number()
			.strict(true)
			.required(),
		latestVideoUploadDate: Yup.date()
			.strict(true)
			.defined(),

		//added for sprint-gan:
		videoCount: Yup.number()
			.strict(true)
			.required(),
		lastTimeActionWasChanged: Yup.date()
			.strict(true)
			.defined(),
		topTargetCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		topBlockCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		topMonitorCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		targetPercentage: Yup.number()
			.strict(true)
			.defined(),
		blockPercentage: Yup.number()
			.strict(true)
			.defined(),
		monitorPercentage: Yup.number()
			.strict(true)
			.defined()
	})
)

export const videosSchema = Yup.array().of(
	Yup.object().shape({
		topTargetCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		topMonitorCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		topBlockCategories: Yup.array()
			.of(Yup.string())
			.defined(),
		garmFeedbackIds: Yup.array()
			.of(Yup.number())
			.defined(),
		actionId: Yup.number()
			.strict(true)
			.defined(),
		categoryName: Yup.string()
			.strict(true)
			.defined(),
		channelName: Yup.string()
			.strict(true)
			.defined(),
		published: Yup.string()
			.strict(true)
			.required(),
		description: Yup.string()
			.strict(true)
			.defined(),
		id: Yup.string()
			.strict(true)
			.required(),
		name: Yup.string()
			.strict(true)
			.required(),
		thumbnail: Yup.string()
			.strict(true)
			.defined(),
		views: Yup.number()
			.strict(true)
			.defined()
	})
)
