import React from 'react'
import { accentColor, sightlyBlue } from '../../../assets/jss/colorConstants'
import { ItemDetail } from './types'

type PillProps = {
  name: string
  handleSelect: () => void
  selected?: boolean
  maxReached: boolean
  alwaysDisabled?: boolean
}
const Pill = ({ name, handleSelect, selected, maxReached, alwaysDisabled }: PillProps) => {
  const disabled = (maxReached && !selected) || alwaysDisabled

  return (
    <div
      data-testid='board-pill'
      onClick={() => (disabled ? undefined : handleSelect())}
      style={{
        height: 40,
        borderRadius: 50,
        boxShadow: disabled ? undefined : `0px 0px 0px 1px ${accentColor} inset`,
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 600,
        paddingLeft: 28,
        paddingRight: 28,
        cursor: disabled ? 'not-allowed' : 'pointer',
        marginRight: 12,
        marginBottom: 16,
        color: selected ? 'white' : disabled ? 'grey' : undefined,
        backgroundColor: selected ? accentColor : maxReached || alwaysDisabled ? 'lightgrey' : 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          zIndex: 99999
        }}
      >
        {name}

        <div
          style={{
            color: selected ? 'white' : disabled ? 'black' : accentColor,
            fontWeight: 600,
            fontSize: 14,
            marginLeft: 10
          }}
        >
          +
        </div>
      </div>
    </div>
  )
}

type IProps = {
  group: {
    items: ItemDetail[]
    name: string
  }
  handleSelect: (id: number) => void
  maxReached: boolean
  searchTerm: string
}

const Pills = ({ group, handleSelect, maxReached, searchTerm }: IProps) => {
  const [viewAll, setViewAll] = React.useState(false)

  const visiblePills = React.useMemo(() => {
    if (!viewAll && !searchTerm) {
      return group.items.filter((i) => i.targeted).slice(0, 10)
      //	return group.items.slice(0, 10)
    }
    return group.items
  }, [group, viewAll])

  if (group.items.length < 1) {
    return null
  }

  const showViewAllButton =
    (group.items.length > group.items.filter((i) => i.targeted).length || group.items.length > 10) && !searchTerm
  return (
    <div data-testid='board-pill-group'>
      <div
        style={{
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '22px',
          textTransform: 'capitalize',
          color: 'black'
        }}
      >
        {group.name === 'competitors'
          ? 'Competitor & Aspirational Brands'
          : group.name === 'socialAndEnvironmental'
          ? 'Social & Environmental Causes'
          : group.name}
      </div>
      <div style={{ marginTop: 16 }}>
        {visiblePills.map((item) => (
          <Pill
            alwaysDisabled={item.alwaysDisabled}
            maxReached={maxReached}
            key={item.id}
            name={item.name}
            selected={item.selected}
            handleSelect={() => handleSelect(item.id)}
          />
        ))}
        {showViewAllButton && (
          <div
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: sightlyBlue,
              cursor: 'pointer',
              width: 'fit-content',
              marginBottom: 24
            }}
            onClick={() => setViewAll((prev) => !prev)}
          >
            {!viewAll ? 'View All' : 'View Less'}
          </div>
        )}
      </div>
    </div>
  )
}

export default Pills
