import React from "react";

interface IProps {
    action: string
    label: string
}

export const ActionBadge = ({ action, label }: IProps) => {

    const color = {
        textColor: '',
        backgroundColor: ''
    }

    switch(action) {
        case 'target':
            color.textColor = 'text-blue-800'
            color.backgroundColor = 'bg-blue-100'
            break
        case 'block':
            color.textColor = 'text-red-800'
            color.backgroundColor = 'bg-red-100'
            break
        case 'monitor':
            color.textColor = 'text-purple-800'
            color.backgroundColor = 'bg-purple-100'
            break
        case 'no action':
        case 'noaction':
            color.textColor = 'text-gray-800'
            color.backgroundColor = 'bg-gray-100'
            break
    }

    return (
        <div
            data-testid="action-badge"
            className={`flex m-1 px-4 py-1 justify-center align-middle rounded-lg font-bold ${color.textColor} ${color.backgroundColor}`}
        >
        {label}
        </div>
    )
}
