import React, {ReactNode} from 'react'
import { Popover } from '@headlessui/react'

export const SightlyPopover = ({ items, icon, disabledButton=false, popoverButtonClass }: {items:any, icon:ReactNode, disabledButton?:boolean, popoverButtonClass?:string}) => {
  return (
    <Popover className="flex" data-testid="popover">

      <Popover.Button data-testid="popover-button" className={popoverButtonClass} disabled={disabledButton}>
        {icon}
      </Popover.Button>

      <Popover.Panel data-testid="popover-panel" className="absolute bg-white ml-8 z-10 border rounded">
        <div className="rounded text-sm font-medium">
          {items.map((item:any, index:number) => (
            <div id={item.id} key={`popover-item-${index}`} data-testid="popover-item">
              <div
                className="flex m-1 py-1.5 px-5 gap-2 rounded hover:cursor-pointer hover:bg-gray-200"
                onClick={item.handler}
              >
                {item.icon}
                {item.name}
              </div>
              {item.divider && <div className="w-full border border-solid border-gray-100"/>}
            </div>
          ))}
        </div>
      </Popover.Panel>

    </Popover>
  )
}
