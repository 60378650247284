export const getLast24Hrs = (): [Date, Date] => {
    const now = new Date()
    const yesterday = new Date(now)
    yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
    return [yesterday, now]
}

export const getLast7Days = (): [Date, Date] => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const sevenDaysAgo = new Date(now)
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);
    return [sevenDaysAgo, now]
}

export const getLast30Days = (): [Date, Date] => {
    const now = new Date()
    const thirtyDaysAgo = new Date(now)
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    thirtyDaysAgo.setHours(0, 0, 0, 0);
    return [thirtyDaysAgo, now]
}

export const getLast60Days = (): [Date, Date] => {
    const now = new Date()
    const sixtyDaysAgo = new Date(now)
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    return [sixtyDaysAgo, now]
}

export type BoardsDateRangeType = {
    label: string
    value: () => [Date, Date]
}
export const ranges: BoardsDateRangeType[] = [
    {
        label: 'Last 24 Hrs',
        value: () => getLast24Hrs()
    },
    {
        label: 'Last 7 Days',
        value: () => getLast7Days()
    },
    {
        label: 'Last 30 Days',
        value: () => getLast30Days()
    },
    {
        label: 'Last 60 Days',
        value: () => getLast60Days()
    }
]
