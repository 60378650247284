/**
 * SerializableMap class:
 * A subclass of the built-in Map class that provides a custom serialization method.
 * When the map is serialized to JSON, it is represented as a plain JavaScript object.
 */
export class SerializableMap<K, V> extends Map<K, V> {
  /**
   * toJSON method:
   * Overrides the default toJSON method to convert the map into a plain object.
   *
   * @returns {Object} - The map entries as a plain JavaScript object.
   */
  toJSON(): Object {
    return Object.fromEntries(this);
  }
}
