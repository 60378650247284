import React, { SVGProps, useState,  useEffect } from 'react';
import { Tabs } from 'flowbite-react';
import { HandThumbUpIcon, HandThumbDownIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Whisper from 'rsuite/lib/Whisper';
import Tooltip from 'rsuite/lib/Tooltip';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { useSearch, useNavigate } from '@tanstack/react-location';
import { MyLocationGenerics } from '@/classes/utils';
import { NewBoardNlpPromptFormSchema, NewBoardNlpPromptFormType } from '@/views/Discover/Boards/types';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { SightlyModernFormTextarea } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormTextarea';
import MomentPreviewList from '@/views/Discover/Boards/components/MomentPreviewList';
import { ConversationsPreviewList } from '@/views/Discover/Boards/components/ConversationsPreviewList';
import useMomentPreviews from '@/views/Discover/Moments/v2/useMomentPreviews';
import useSocialConversationPreviews from '@/views/Discover/Moments/v2/useSocialConversationPreviews';
import { MomentPreviewType } from '@/views/Discover/Boards/types';
import { api } from '@/api/api';
import { BoardType } from '@/api/boardsV1';
import { PATH_MOMENTS_V2, PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes';
import PreviewPageTabs from '@/views/Discover/Boards/PreviewPageTabs';
import useUser from '@/hooks/useUser';
import { MomentSortBy } from '@/views/Discover/Moments/v2/types';
import useBoardsV1, { BoardV1 } from '@/views/Discover/Boards/useBoardsV1';
import { THUMBS_UP_LIMIT, THUMBS_DOWN_LIMIT } from '@/views/Discover/Boards/NarrativesTab/constants';
import _ from 'lodash';

const ThumbUpIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return <HandThumbUpIcon className="text-green-600 w-6 inline mr-2" />;
}

const ThumbDownIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return <HandThumbDownIcon className="text-red-600 w-6 inline mr-2" />
}

interface originRouteProps {
  boardsView?: string
  brandProfileId?: number
  accountId?: number
  timeRange?: number
  searchTerm?: string
  boardIds?: number[]
  sortBy?: MomentSortBy
}

const AI = () => {
  const navigate = useNavigate();

  const {
    aiPromptText,
    boardsView,
    accountId,
    searchTerm,
    boardIds,
    boardId,
    sortBy,
    brandProfileId,
    ideasToLeanInto,
    ideasToAvoid,
    editingBoard
  } = useSearch<MyLocationGenerics>();
  const {
    data: boardValues,
    error,
    isLoading: boardQueryLoading,
    isFetching,
    refetch
  } = useBoardsV1(boardId, editingBoard);

  const prompt = boardValues?.prompt.prompt || aiPromptText || '';
  const [boardName, setBoardName] = useState(boardValues?.name || aiPromptText?.slice(0, 250) || '');

  const [momentsThumbUp, setMomentsThumbUp] = useState<MomentPreviewType[]>([]);
  const [momentsThumbDown, setMomentsThumbDown] = useState<MomentPreviewType[]>([]);

  const [displayedMomentsThumbUp, setDisplayedMomentsThumbUp] = useState<MomentPreviewType[]>([]);
  const [displayedMomentsThumbDown, setDisplayedMomentsThumbDown] = useState<MomentPreviewType[]>([]);

  const [originRoute, setOriginRoute] = useState<originRouteProps>({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [thumbUpLimitReached, setThumbUpLimitReached] = useState(false);
  const [thumbDownLimitReached, setThumbDownLimitReached] = useState(false);
  const [updatedBtn, setUpdatedBtn] = useState(false);

  useEffect(() => {
    setOriginRoute({
      boardsView,
      brandProfileId,
      accountId,
      searchTerm,
      boardIds,
      sortBy
    })
  }, [])

  const { currentAccount } = useUser();
  const currentAccountId = currentAccount?.accountId;

  const {
    sightlyRegister,
    handleSubmit,
    formState: { isValid, errors, isDirty, dirtyFields },
    setValue,
    getValues,
    reset
  } = useSightlyForm<NewBoardNlpPromptFormType>(NewBoardNlpPromptFormSchema, {
    defaultValues: {
      prompt,
      ideasToLeanInto: editingBoard &&
       boardValues?.prompt.ideasToleanInto || editingBoard && ideasToLeanInto || '',
      ideasToAvoid: editingBoard &&  boardValues?.prompt.ideasToAvoid || editingBoard && ideasToAvoid || '',
      thumbsUpMoments: editingBoard &&  boardValues?.prompt.thumbsUpMoments || [],
      thumbsDownMoments: editingBoard &&  boardValues?.prompt.thumbsDownMoments || []
    }
  });

  setValue(
    'thumbsUpMoments',
    momentsThumbUp,
    { shouldDirty: getValues('thumbsUpMoments')?.length !== momentsThumbUp.length }
  );
  setValue(
    'thumbsDownMoments',
    momentsThumbDown,
    { shouldDirty: getValues('thumbsDownMoments')?.length !== momentsThumbDown.length }
  );

  // determine if thumbs feedback limits are reached on initial load
  useEffect(() => {
    setThumbUpLimitReached((getValues('thumbsUpMoments')?.length || 0) >= THUMBS_UP_LIMIT);
    setThumbDownLimitReached((getValues('thumbsDownMoments')?.length || 0) >= THUMBS_DOWN_LIMIT);
  }, [momentsThumbUp, momentsThumbDown]);

  const mutationValues = {
    prompt: getValues('prompt'),
    ideasToLeanInto: getValues('ideasToLeanInto'),
    ideasToAvoid: getValues('ideasToAvoid'),
    thumbsUpMoments: getValues('thumbsUpMoments'),
    thumbsDownMoments: getValues('thumbsDownMoments')
  };

  const momentPreviewsQuery = useMomentPreviews(mutationValues);
  const socialConversationPreviewsQuery = useSocialConversationPreviews(mutationValues);

  const removeFromThumbsUp = (moment: MomentPreviewType) => {
    setMomentsThumbUp(momentsThumbUp => momentsThumbUp.filter(m => m.name !== moment.name));
  };

  const removeFromThumbsDown = (moment: MomentPreviewType) => {
    setMomentsThumbDown(momentsThumbDown => momentsThumbDown.filter(m => m.name !== moment.name));
  };

  useEffect(() => {
    if (momentPreviewsQuery.data) {
      setDisplayedMomentsThumbUp(momentsThumbUp);
      setDisplayedMomentsThumbDown(momentsThumbDown);
    }
  }, [momentPreviewsQuery.data, momentsThumbUp, momentsThumbDown])

  const handleGoBack = () => {
    let navigationRoute = '';
    let navigationParams = {...originRoute};

    switch (navigationParams.boardsView) {
      case 'My Boards':
        navigationRoute = PATH_MOMENTS_V2;
        break;
      case 'Manage Boards':
        navigationRoute = PATH_MOMENTS_V2_SETTINGS_REDESIGN;
        break;

      default:
        navigationRoute = PATH_MOMENTS_V2;
        navigationParams.boardsView = 'My Boards';
        break;
    }

    navigate({
        to: navigationRoute,
        search: () => (navigationParams)
    });
  };

  // fetch previews if not editing
  useEffect(() => {
    if (!isDirty && !editingBoard && !!mutationValues.prompt && !updatedBtn) {
      momentPreviewsQuery.mutate(mutationValues);
      socialConversationPreviewsQuery.mutate(mutationValues);
    }
  }, [editingBoard, mutationValues.prompt]);

  // This useEffect plugs in retrieved prompt data for the edit board flow
  useEffect(() => {
    if (boardValues && !boardQueryLoading && !isFetching && editingBoard) {
      setBoardName(boardValues.name === '' ? aiPromptText?.slice(0, 250) : boardValues.name );
      setDisplayedMomentsThumbUp(boardValues.prompt.thumbsUpMoments || []);
      setDisplayedMomentsThumbDown(boardValues.prompt.thumbsDownMoments || []);
      setMomentsThumbUp(boardValues.prompt.thumbsUpMoments || []);
      setMomentsThumbDown(boardValues.prompt.thumbsDownMoments || []);

      reset({ ...boardValues.prompt }); // Populate the form with fetched data

      // get the preview moments
      const updatedData = {
        prompt: getValues('prompt'),
        ideasToLeanInto: getValues('ideasToLeanInto'),
        ideasToAvoid: getValues('ideasToAvoid'),
        thumbsUpMoments: getValues('thumbsUpMoments'),
        thumbsDownMoments: getValues('thumbsDownMoments')
      };

      if (updatedData.prompt && !boardQueryLoading && !isFetching) {
        momentPreviewsQuery.mutate(updatedData);
        socialConversationPreviewsQuery.mutate(updatedData);
      }
    }
  }, [boardValues, reset, boardQueryLoading, isFetching, editingBoard]);

  // method to handle form submit
  const onSubmit = handleSubmit(async (formData) => {
    const updatedData = {
      prompt: getValues('prompt'),
      ideasToLeanInto: getValues('ideasToLeanInto'),
      ideasToAvoid: getValues('ideasToAvoid'),
      thumbsUpMoments: getValues('thumbsUpMoments'),
      thumbsDownMoments: getValues('thumbsDownMoments')
    };

    if (updatedData.prompt) {
      setUpdatedBtn(true)
      momentPreviewsQuery.mutate(updatedData);
      socialConversationPreviewsQuery.mutate(updatedData);
    }
    reset(formData);

    // update url params
    const newParams = {
      aiPromptText: updatedData.prompt,
      ideasToLeanInto: updatedData.ideasToLeanInto,
      ideasToAvoid: updatedData.ideasToAvoid,
      thumbsUpMoments: updatedData.thumbsUpMoments,
      thumbsDownMoments: updatedData.thumbsDownMoments
    };

    navigate({
      search: (search) => ({
        ...search,
        ...newParams
      })
    });

    return;
  });

  const handleBoardCreate = async () => {
    const prompt = getValues();
    if (!brandProfileId) return;

    let nlBoard: { id: any; };

    if (editingBoard) {
      const updatedBoard:BoardV1 = _.cloneDeep(boardValues);
      const promptId = updatedBoard.prompt.id;
      const updatedPrompt:any = prompt;
      updatedPrompt.id = promptId;
      updatedBoard.prompt = updatedPrompt;
      updatedBoard.name = boardName;
      nlBoard = await api.boardsV1.update({ board: updatedBoard });
    } else {
      nlBoard = await createNlBoard(
        boardName,
        prompt,
        brandProfileId
      );
    }

    if (nlBoard) {
      refetch();
      // brandProfileId should already be in search
      navigate({
        to: PATH_MOMENTS_V2,
        search: (search) => ({
          ...search,
          boardIds: [nlBoard.id],
          accountId: currentAccountId,
          timeRange: 1,
          searchTerm: '',
          sortBy: 'ranking',
          boardsView: 'My Boards'
        })
      })
    }
  }

  const handleBoardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoardName(e.target.value);
  }

  return (
    <div className="h-full bg-slate-50">
      <div className="h-[calc(100%-54px)]">
      <input
        data-testid="nl-board-page-board-name-input"
        type="text"
        placeholder="New Board name"
        className={`w-full border-bottom border border-solid border-slate-200 bg-slate-50 p-4`}
        value={boardName}
        onChange={handleBoardNameChange}
        maxLength={250}
      />

      <div className="flex flex-row grow h-[calc(100%-57px)]">
        <div className="nlp-sidebar flex flex-col w-96 p-2 border border-solid border-slate-200 shrink-0">
          <div className="overflow-auto">
            {/* Prompt */}
            <div className="mb-3 border border-solid border-slate-200 rounded-md shadow-lg">
              <label htmlFor="ai-board-prompt" className="block p-3 bg-white">
                Prompt
                <Whisper
                  delayShow={0}
                  delayHide={0}
                  trigger='hover'
                  placement='auto'
                  speaker={
                    <Tooltip className="inline">
                      Describe the type of moments for this board in a few words or phrases
                    </Tooltip>
                  }
                >
                  <InformationCircleIcon className="h-5 ml-2 inline"
                    data-testid="ai-board-prompt-tooltip"
                  />
                </Whisper>
              </label>
              <SightlyModernFormTextarea
                {...sightlyRegister('prompt')}
                placeholder="Build a board about"
                dataTestId="nlp-prompt-textarea"
                id="ai-board-prompt-textarea"
                border={false}
              />
            </div>

            {/* Ideas to lean into */}
            <div className="mb-3 border border-solid border-slate-200 rounded-md shadow-lg">
              <label htmlFor="ai-board-lean-into" className="block p-3 bg-white">
                Ideas to lean into
                <Whisper
                  delayShow={0}
                  delayHide={0}
                  trigger='hover'
                  placement='auto'
                  speaker={
                    <Tooltip className="inline">
                      Narrow results to specific words or phrases
                    </Tooltip>
                  }
                >
                  <InformationCircleIcon className="h-5 ml-2 inline"
                    data-testid="ai-board-board-lean-into-tooltip"
                  />
                </Whisper>
              </label>
              <SightlyModernFormTextarea
                {...sightlyRegister('ideasToLeanInto')}
                id="ai-board-lean-into-textarea"
                placeholder="Optionally add ideas to lean into"
                dataTestId="nlp-ideas-lean-into-textarea"
                border={false}
              />
            </div>

            {/* Ideas to avoid */}
            <div className="mb-3 border border-solid border-slate-200 rounded-md shadow-lg">
              <label htmlFor="ai-board-avoid" className="block p-3 bg-white">
                Ideas to avoid
                <Whisper
                  delayShow={0}
                  delayHide={0}
                  trigger='hover'
                  placement='auto'
                  speaker={
                    <Tooltip className="inline">
                      Avoid results containing specific words or phrases
                    </Tooltip>
                  }
                >
                  <InformationCircleIcon className="h-5 ml-2 inline"
                    data-testid="ai-board-avoid-tooltip"
                  />
                </Whisper>
              </label>
              <SightlyModernFormTextarea
                {...sightlyRegister('ideasToAvoid')}
                placeholder="Optionally add ideas to avoid"
                dataTestId="nlp-ideas-avoid-textarea"
                id="ai-board-avoid-textarea"
                border={false}
              />
            </div>

            {/* Moment feedback */}
            <div data-testid="moment-feedback-module"
              className="border border-solid border-slate-200 rounded-md shadow-lg mb-3">
              <label htmlFor="ai-board-avoid" className="block p-3 bg-white">
                Moment feedback
                <Whisper
                  delayShow={0}
                  delayHide={0}
                  trigger='hover'
                  placement='auto'
                  speaker={
                    <Tooltip className="inline">
                      To provide feedback on the moments displayed, click the thumbs up or thumbs down icon by hovering on the moment.
                    </Tooltip>
                  }
                >
                  <InformationCircleIcon className="h-5 ml-2 inline"
                    data-testid="ai-board-avoid-tooltip"
                  />
                </Whisper>
              </label>
              <div >
                <Tabs aria-label="Default tabs"
                  style="underline"
                  theme={{
                    tablist: {
                      tabitem: {
                        base: 'flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0  focus:outline-none rounded-t-lg border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600',
                      },
                    },
                  }}
                  >
                  <Tabs.Item active title={`${displayedMomentsThumbUp.length} Moments`} icon={ThumbUpIcon} data-testid="moment-thumb-up-tab">
                    <div>
                      {displayedMomentsThumbUp.map((momentThumbUp) => {
                        return <div className='flex flex-row justify-between p-2 pl-3' key={momentThumbUp.id}>
                          <p className=''>{momentThumbUp.name}</p>
                          <button
                            className='mx-2 delete-moment-thumb-up'
                            onClick={() => removeFromThumbsUp(momentThumbUp)}
                            data-testid="delete-moment-thumb-up"> X </button>
                        </div>
                      })
                      }
                    </div>
                  </Tabs.Item>
                  <Tabs.Item active title={`${displayedMomentsThumbDown.length} Moments`} icon={ThumbDownIcon} data-testid="moment-thumb-down-tab">
                    <div>
                      {displayedMomentsThumbDown.map((momentThumbDown) => {
                        return <div className='flex flex-row justify-between p-2 pl-3'  key={momentThumbDown.id}>
                          <p className=''>{momentThumbDown.name}</p>
                          <button
                            className="mx-2 delete-moment-thumb-down"
                            onClick={() => removeFromThumbsDown(momentThumbDown)}
                            data-testid="delete-moment-thumb-down"> X </button>
                        </div>
                      })
                      }
                    </div>
                  </Tabs.Item>
                </Tabs>
              </div>
            </div>
          </div>
          <SightlyButton datatestid="update-nlp-preview-moments-button"
            selectorClass="mt-auto"
            id="pendo-update-nlp-preview-moments-button"
            text="Update"
            type="gradient-primary"
            disabled={!isDirty || !isValid}
            handleClick={onSubmit}
          />
        </div>

        {/* Preview Feeds */}
        <div className="max-h-full w-full my-4 ml-4">
          <PreviewPageTabs
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />

          {selectedTabIndex === 0 ?
            <MomentPreviewList
              previewMoments={momentPreviewsQuery?.data?.data.previews}
              isLoading={momentPreviewsQuery.isLoading}
              previewsCount={momentPreviewsQuery?.data?.data.previewsCount}
              previewsCountLongRange={momentPreviewsQuery?.data?.data.previewsCountLongRange}
              previewsCountMediumRange={momentPreviewsQuery?.data?.data.previewsCountMediumRange}
              setMomentsThumbUp={setMomentsThumbUp}
              momentsThumbUp={momentsThumbUp}
              setMomentsThumbDown={setMomentsThumbDown}
              momentsThumbDown={momentsThumbDown}
              thumbUpLimitReached={thumbUpLimitReached}
              thumbDownLimitReached={thumbDownLimitReached}
            />
            :
            <ConversationsPreviewList
              previews={socialConversationPreviewsQuery?.data?.data.previews}
              isLoading={socialConversationPreviewsQuery.isLoading}
              previewsCount={socialConversationPreviewsQuery?.data?.data.previewsCount}
              previewsCountLongRange={socialConversationPreviewsQuery?.data?.data.previewsCountLongRange}
              previewsCountMediumRange={socialConversationPreviewsQuery?.data?.data.previewsCountMediumRange}
            />
          }
        </div>
      </div>
      </div>


      {/* Footer */}
      <div
        className="py-2 px-4 w-full flex justify-end bg-slate-100 border-bottom border border-solid border-slate-200 shrink-0">
        <div className="mr-8">
          <SightlyButton
            text="Cancel"
            selectorClass="pendo-cancel-nlp-board-button"
            datatestid="cancel-nlp-board-button"
            id="cancel-nlp-board-button"
            handleClick={() => {
              handleGoBack()
            }}
            type="transparent"
          />
        </div>
        <div className="mr-8">
          <SightlyButton
            text="Confirm Board"
            selectorClass="pendo-confirm-nlp-board"
            datatestid="confirm-nlp-board-button"
            id="confirm-nlp-board-button"
            handleClick={handleBoardCreate}
            disabled={isDirty || !isValid || boardName.length < 1}
            type="gradient-primary"
          />
        </div>
      </div>
    </div>
  )
}

const createNlBoard = (boardName: string, prompt: NewBoardNlpPromptFormType, brandProfileId: number) => {
  if (!brandProfileId) return;
  return api.boardsV1.create({
    boardName,
    boardType: BoardType.NaturalLanguage,
    prompt,
    brandProfileId
  });
}

export default AI;
