import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum NEWS_CATEGORY_DIMENSION {
    BRAND_PROFILE_NAME = 'brandProfileName',
    NEWS_CATEGORY = 'newsCategory',
}

interface NewsCategoryDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | NEWS_CATEGORY_DIMENSION
}

export interface NewsCategoryReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | NEWS_CATEGORY_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | NewsCategoryDimensionOption)[]
}

export const dimensionOptions: Option<NewsCategoryDimensionOption> = {
  [NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME
  },
  [NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY]: {
    name: 'News Category',
    value: NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY
  },
}

export const baseNewsCategoryReport: NewsCategoryReport = {
  name: 'News Category Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.NEWS_CATEGORY,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
