import { z } from 'zod'

const ImportedCampaignSchema = z.object({
    id: z.number(),
    campaignName: z.string(),
    advertiserName: z.string(),
    operationStatus: z.string(),
    createTime: z.string()
})

export const GetImportedCampaignSchema = z.object({
    campaigns: z.array(ImportedCampaignSchema),
    total: z.string(),
    page: z.number(),
    pageSize: z.number(),
})

const NotImportedCampaignSchema = z.object({
    id: z.number(),
    campaignName: z.string(),
    operationStatus: z.string(),
    createTime: z.string()
})

export const GetNotImportedCampaignSchema = z.object({
    campaigns: z.array(NotImportedCampaignSchema),
    total: z.string(),
    page: z.number(),
    pageSize: z.number()
})

export const ImportedCampaignsStatusSchema = z.object({
    total: z.number(),
});

export type INotImportedCampaign = z.infer<typeof NotImportedCampaignSchema>

export type ICampaign = z.infer<typeof ImportedCampaignSchema>
