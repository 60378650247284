import React from 'react'

import { CheckPicker, Icon } from 'rsuite'
import DataPickerFooter from '@/components/DataPickerFooter'

export type ListedPersonaType = Array<{ personaId: string; personaName: string }>

export const PersonaFilter = (props: {
  personaOptions: ListedPersonaType
  handleFilterPersonas: (personaFilters: string[]) => void
}) => {
  const [filteredPersonas, setFilteredPersonas] = React.useState<string[]>()
  const handleRenderMenu = (menu: React.ReactNode) => {
    if (!props.personaOptions || props.personaOptions.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Icon
            icon="spinner"
            spin
          />{' '}
          Loading...
        </p>
      )
    }
    return menu
  }

  const handleChange = (value: any[]) => {
    setFilteredPersonas((prev) => value)
    props.handleFilterPersonas(value)
  }

  const renderFooter = () => {
    if (!props.personaOptions.length) return
    return (
      <DataPickerFooter
        rowCount={props.personaOptions.length}
        handleClear={() => {
          handleChange([])
        }}
        handleSelectAll={() => {
          handleChange(props.personaOptions.map(({personaId})=>personaId))
        }}
      />
    )
  }

  return (
    <div
      data-testid="act-mgr-personas-filter"
      id="act-mgr-personas-filter"
    >
      <CheckPicker
        data={props.personaOptions}
        renderMenu={(menu: React.ReactNode) => handleRenderMenu(menu)}
        appearance="default"
        placement="autoVerticalStart"
        placeholder="Personas"
        value={filteredPersonas}
        labelKey="personaName"
        valueKey="personaId"
        onChange={handleChange}
        onClean={() => handleChange([])}
        renderExtraFooter={renderFooter}
      />
      <style>
        {`
          .rs-picker-toggle .rs-picker-toggle-placeholder{
            color: unset;
          }
        `}
      </style>
    </div>
  )
}
