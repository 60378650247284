import React from 'react'
import { ReactComponent as AddIcon } from '@/assets/img/add_20x20.svg'
import { ScenarioSelect } from './ScenarioSelect'
import MomentScenarioComponent from '../../MomentModal/MomentScenarioComponent'
import { accentColor } from '@/assets/jss/colorConstants'
import { NoneApplyId } from '@/services/trends_ts'
import Whisper from 'rsuite/lib/Whisper'
import Tooltip from 'rsuite/lib/Tooltip'
import { ClusterType, MomentScenarioType } from '../../types'
import { api } from '../../api'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import {useIsMutating, useMutation, UseMutationResult} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { logError } from '@/utils'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyButton from "@/components/Sightly/SightlyButton";

interface MomentScenariosProps {
    isLoading: boolean
    scenarios: MomentScenarioType[]
    adding: boolean
    setAdding: any
    scenariosOptions: MomentScenarioType[]
    moment: ClusterType
    handleAddMomentScenario: (scenarioId: number) => void
    handleRemoveMomentScenario: (scenarioId: number) => void
    handlePatchMomentScenarios: UseMutationResult<any, unknown, void, unknown>
    hasUnsavedChanges: boolean
}

export const ScenariosSection = ({
    isLoading,
    scenarios,
    scenariosOptions,
    adding,
    setAdding,
    moment,
    handleAddMomentScenario,
    handleRemoveMomentScenario,
    handlePatchMomentScenarios,
    hasUnsavedChanges
}: MomentScenariosProps) => {
    const isMutating = useIsMutating()

    const { brandProfileId } = useSearch<MyLocationGenerics>()

    const _visibleScenarioOptions = React.useMemo(() => {
        if (!scenarios || !scenariosOptions) return []
        const scenarioIds = scenarios.map((_s: MomentScenarioType) => {
            return _s.scenarioId
        })

        let final = scenariosOptions.filter((_scen: MomentScenarioType) => {
            return !scenarioIds.includes(_scen.scenarioId)
        })

        if (scenarioIds.length > 0) {
            //remove none apply option
            final = final.filter((t: MomentScenarioType) => t.scenarioId !== NoneApplyId)
        }

        return final
    }, [scenarios, scenariosOptions])

    const hasNoneApply = React.useMemo(() => {
        if (!scenarios || scenarios.length !== 1) {
            return false
        }
        const noneApply = scenarios.filter((scen: MomentScenarioType) => scen.scenarioId === NoneApplyId)
        if (noneApply && noneApply.length > 0) {
            return true
        }
        return false
    }, [scenarios])

    const hasNoScenarioOptions = _visibleScenarioOptions.length === 0 || hasNoneApply

    const handleScenarioFeedback = useMutation(api.moments.feedback.scenarioFeedback, {
        onSuccess: () => {
            toast.success('Changes saved')
        },
        onError: (err) => {
            logError(err, { info: 'error in scenario feedback' })
        }
    })

    return (
        <div className="p-4 overflow-auto">
            {isLoading ? (
                <div style={{ height: 150 }}>
                    <LoadingPage message="Loading" />
                </div>
            ) : (
                <div>
                    {scenarios.length > 0 &&
                        scenarios.slice(0, 5).map((_scenario, index) => {
                            return (
                                <MomentScenarioComponent
                                    key={_scenario.scenarioId}
                                    scenario={_scenario}
                                    index={index + 1}
                                    handleFeedbackQuestion={(feedback: string | null, scenarioName: string) => {
                                        if (!brandProfileId) return
                                        const args = {
                                            clusterId: moment.clusterId,
                                            feedback,
                                            clusterHeadline: moment.clusterName,
                                            scenarioId: _scenario.scenarioId,
                                            question: scenarioName,
                                            brandProfileId
                                        }
                                        handleScenarioFeedback.mutate(args)
                                    }}
                                    handleDeleteTrendScenario={() => {
                                        handleRemoveMomentScenario(_scenario.scenarioId)
                                    }}
                                />
                            )
                        })}
                    {adding ? (
                        <ScenarioSelect
                            scenarios={_visibleScenarioOptions}
                            index={scenarios.length + 1}
                            handleAdd={handleAddMomentScenario}
                            handlePatchMomentScenarios={handlePatchMomentScenarios.mutate}
                        />
                    ): (
                        <Whisper
                            delayShow={100}
                            delayHide={0}
                            placement="topStart"
                            trigger={hasNoScenarioOptions || scenarios.length >= 5 ? 'hover' : 'none'}
                            speaker={
                                <Tooltip>
                                    {scenarios.length >= 5
                                        ? 'You cannot have more than 5.'
                                        : 'This Brand Profile has no Scenarios linked to it'}
                                </Tooltip>
                            }
                        >
                            <div
                                data-testid="add-moment-scenario"
                                style={{
                                    display: 'flex',
                                    marginLeft: 10,
                                    marginTop: 24,
                                    cursor:
                                        hasNoScenarioOptions || scenarios.length === 5 || isMutating > 0
                                            ? 'not-allowed'
                                            : 'pointer'
                                }}
                                onClick={() =>
                                    hasNoScenarioOptions || scenarios.length === 5 || isMutating > 0
                                        ? undefined
                                        : setAdding(true)
                                }
                            >
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        lineHeight: '18px',
                                        color: '#8F8F8F',
                                        marginBottom: 2
                                    }}
                                >
                                    Add another relevant scenario (Up to 5 Scenarios)
                                </div>
                                <AddIcon
                                    fill={accentColor}
                                    style={{
                                        marginLeft: 8
                                    }}
                                />
                            </div>
                        </Whisper>
                    )}

                    <div className="pt-10">
                        <div className="flex justify-end flex-shrink-0">
                            {scenarios.length < 1 && hasUnsavedChanges && (
                                <div
                                    data-testid="minimum-one-scenario-message"
                                    className="flex flex-col justify-end pr-4 text-xs font-medium text-neutral-400"
                                >
                                    Please add at least one Scenario to save your changes
                                </div>
                            )}
                            <div className="flex-shrink-0">
                                <SightlyButton
                                    datatestid="update-moment-button"
                                    block
                                    text="Update Moment"
                                    handleClick={() => handlePatchMomentScenarios.mutate()}
                                    loading={handlePatchMomentScenarios.isLoading}
                                    disabled={scenarios.length < 1 || !hasUnsavedChanges}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
