import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum HOUSEHOLD_INCOME_DIMENSION {
  DEVICE = 'device',
  INCOME_RANGE = 'incomeRange',
}

interface HouseholdIncomeDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | HOUSEHOLD_INCOME_DIMENSION
}

export interface HouseholdIncomeReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | HOUSEHOLD_INCOME_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | HouseholdIncomeDimensionOption)[]
}

export const dimensionOptions: Option<HouseholdIncomeDimensionOption> = {
  [HOUSEHOLD_INCOME_DIMENSION.DEVICE]: {
    name: 'Device',
    value: HOUSEHOLD_INCOME_DIMENSION.DEVICE
  },
  [HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE]: {
    name: 'Household Income',
    value: HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE
  },
}

export const baseHouseholdIncomeReport: HouseholdIncomeReport = {
  name: 'Household Income Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.HOUSEHOLD_INCOME,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
