import React from 'react'
import { ActivationItemType } from '@/views/Discover/Activations/v2/activations-types'
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import SightlyAvatar from '@/components/SightlyAvatar'
import { ConfirmationDialog } from '@/views/Discover/Moments/v2/TargetMoment/ConfirmationDialog'

const InsertionOrderGroupRow = (props: {
  expandedInsertionOrder: Set<number> | null
  setExpandedInsertionOrder: React.Dispatch<React.SetStateAction<Set<number> | null>>
  insertionOrderId: number
  activations: Array<ActivationItemType>
  selectedActivationIds: number[]
  setSelectedActivationIds: (value: React.SetStateAction<number[]>) => void
  handleDeleteActivationsGroupedByIo: (activationsToBeDeleted: number[]) => void
  showTrashcan?: boolean,
  itemsSelectable?: boolean,
}) => {
  const {
    activations,
    selectedActivationIds,
    setSelectedActivationIds,
    setExpandedInsertionOrder,
    expandedInsertionOrder,
    insertionOrderId,
    handleDeleteActivationsGroupedByIo,
    showTrashcan,
    itemsSelectable,
  } = props

  const [isInsertionOrderRowDelete, setIsInsertionOrderRowDelete] = React.useState(false)

  const toggleInsertionOrder = (insertionOrderId: number) =>
    setExpandedInsertionOrder((prev) => {
      const next = new Set(prev)
      next?.has(insertionOrderId) ? next.delete(insertionOrderId) : next.add(insertionOrderId)
      return next
    })

  const getSelectedActivationsInIOGroupState = (
    activations: Array<ActivationItemType>,
    selectedActivationIds: number[]
  ) => {
    const selectedActivation = activations.filter(({ activationItemId }) =>
      selectedActivationIds.includes(activationItemId)
    )
    return {
      selectAll: selectedActivation.length === activations.length,
      indeterminate: selectedActivation.length > 0 && selectedActivation.length < activations.length,
      empty: selectedActivation.length === 0
    }
  }

  const handleIOGroupSelection = (checked: boolean, activations: Array<ActivationItemType>) => {
    if (checked)
      setSelectedActivationIds((prev) => [...prev, ...activations.map(({ activationItemId }) => activationItemId)])
    else
      setSelectedActivationIds((prev) =>
        prev.filter((activationId) => !activations.some(({ activationItemId }) => activationId === activationItemId))
      )
  }

  const personaCount = React.useCallback(
    (activations: Array<ActivationItemType>) => {
      const personasOfActivations = activations.reduce<string[]>(
        (acc, activation) => [...acc, ...activation.personas],
        []
      )
      return new Set(personasOfActivations).size
    },
    [activations]
  )

  return (
    <tr data-testid="todo-activation-io-group-row" id="todo-activation-io-group-row">
      <td>
        <div className="flex gap-1 pl-3">
          <div
            className="cursor-pointer"
            data-testid="todo-activation-io-group-row-expand-btn"
            id="todo-activation-io-group-row-expand-btn"
            onClick={() => toggleInsertionOrder(insertionOrderId)}
          >
            {expandedInsertionOrder?.has(insertionOrderId) ? (
              <ChevronDownIcon
                height={20}
                width={20}
              />
            ) : (
              <ChevronRightIcon
                height={20}
                width={20}
              />
            )}
          </div>
          { itemsSelectable && (
            <SightlyCheckbox
            dataTestId="activations-todo-activation-checkbox"
            handleChange={(checked: boolean) => handleIOGroupSelection(checked, activations)}
            id="selectIo"
            checked={getSelectedActivationsInIOGroupState(activations, selectedActivationIds).selectAll}
            indeterminate={getSelectedActivationsInIOGroupState(activations, selectedActivationIds).indeterminate}
            disabled={activations.length <= 0}
          />
          )}
        </div>
      </td>
      <td
        className="py-4 pr-6 text-sm font-semibold text-left text-gray-600 truncate whitespace-nowrap"
        onClick={() => toggleInsertionOrder(insertionOrderId)}
        data-testid="todo-activation-io-group-row-io-name"
        id="todo-activation-io-group-row-io-name"
      >
        {activations[0].insertionOrderName}
      </td>
      <td
        data-testid="io-group-row-created-date"
        id="io-group-row-created-date"
        className="text-sm text-gray-500 whitespace-nowrap"
      >
        {activations[0].submittedDate?.split(' ')[0]}
      </td>
      <td className="text-sm text-gray-500 whitespace-nowrap">{personaCount(activations)}</td>
      <td className="text-sm text-gray-500 whitespace-nowrap">
        <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
          <div
            className="flex -space-x-1 "
            data-testid="activations-todo-assignees"
            id="activations-todo-assignees"
          >
            {activations[0].assignees.slice(0, 2).map((assignee) => {
              return (
                <div
                  key={assignee}
                  className="inline-block rounded-full ring-2 ring-white"
                  data-testid="activations-implemented-assignee-avatar"
                  id="activations-implemented-assignee-avatar"
                >
                  <SightlyAvatar
                    text={assignee}
                    size="xs"
                    type={null}
                  />
                </div>
              )
            })}
          </div>
          {activations[0].assignees.length > 2 && (
            <div
              className="text-xs"
              data-testid="activations-implemented-assignees-others"
              id="activations-implemented-assignees-others"
            >
              + {activations[0].assignees.length - 2} others
            </div>
          )}
        </div>
      </td>

      { showTrashcan && (<td className="text-sm font-medium text-right whitespace-nowrap sm:pr-6">
        <div
          className={`hover:cursor-pointer`}
          data-testid={'activations-todo-delete-btn'}
          id={'activations-todo-delete-btn'}
        >
          <TrashIcon
            key={insertionOrderId}
            onClick={() => setIsInsertionOrderRowDelete(true)}
            className="w-6 h-6 rounded hover:bg-slate-100"
          />
          <ConfirmationDialog
            show={isInsertionOrderRowDelete}
            onHide={() => setIsInsertionOrderRowDelete(false)}
            handleConfirm={() =>{
              handleDeleteActivationsGroupedByIo(activations.map(({ activationItemId }) => activationItemId))
              setIsInsertionOrderRowDelete(false)
            }
            }
            handleCancel={() => setIsInsertionOrderRowDelete(false)}
            body={`Are you sure you want to remove ${activations?.length} moments?`}
            action="Delete"
          />
        </div>
      </td>
    )}
    </tr>
  )
}

export default InsertionOrderGroupRow
