import React from 'react'
import { CheckPicker, SelectPicker } from 'rsuite'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { TargetviewIo } from '@/classes/brandProfile'
import Tooltip from '@/components/TailwindTooltip'
import { IoOptionsType, TargetListType } from '@/views/Discover/Moments/v2/TargetMoment/index'
import DataPickerFooter from '@/components/DataPickerFooter'

const INSERTION_ORDER_EXISTS = 'Insertion Order already exists.'
type InsertionOrderPersonasProps = {
  iosOptions: IoOptionsType[]
  targetList: Map<number, TargetListType>
  setIOsToTargetList: (clusterId: number, ioId: number, prevIoId: number | undefined) => void
  removeIOsFromTargetList: (clusterId: number, ioId: number) => void
  setPersonasToTargetList: (clusterId: number, ioId: number, personas: string[]) => void
  clusterId: number
  handleRemoveRow: (rowKey: string) => void
  rowKey: string
  selectedIoId?: number
  selectedPersonas?: string[]
}

const DROP_DOWN_WIDTH = 280

/**
 * InsertionOrderPersonasRow Component:
 * Represents a row in the Insertion Order Personas table.
 *
 * @param {InsertionOrderPersonasProps} props - Component props
 * @param {TargetviewIo[]} props.iosOptions - List of available Insertion Orders
 * @param {Map<number, TargetListType>} props.targetList - Map of Target Lists for different clusters
 * @param {(clusterId: number, ioId: number) => void} props.setIOsToTargetList - Function to update Insertion Orders
 * @param {(clusterId: number, ioId: number) => void} props.removeIOsFromTargetList - Function to remove Insertion Orders
 * @param {(clusterId: number, ioId: number, personas: string[]) => void} props.setPersonasToTargetList - Function to set personas for an Insertion Order
 * @param {number} props.clusterId - ID of the cluster
 * @param {string} props.rowKey - Unique key for the row
 * @param {(rowKey: string) => void} props.handleRemoveRow - Function to handle row removal
 * @param {number | undefined} props.selectedIoId - Selected Insertion Order ID
 * @param {string[]} props.selectedPersonas - Selected personas for the Insertion Order
 * @returns {JSX.Element} - JSX representation of the component
 */
export const InsertionOrderPersonasRow = (props: InsertionOrderPersonasProps): JSX.Element => {
  const {
    iosOptions,
    targetList,
    setIOsToTargetList,
    removeIOsFromTargetList,
    setPersonasToTargetList,
    clusterId,
    rowKey,
    handleRemoveRow,
    selectedIoId,
    selectedPersonas
  } = props
  const [ioId, setIoId] = React.useState<number | undefined>(selectedIoId)
  const [ioValueExists, setIoValueExists] = React.useState<boolean>()
  const insertionOrder = React.useMemo(() => iosOptions.find((io) => io.id === ioId), [ioId])
  const ioName = insertionOrder?.name
  const personaOptions =
    insertionOrder?.personas?.map((persona) => ({ label: persona.name, value: persona.name })) || []
  React.useEffect(() => {
    setIoId(() => selectedIoId)
  }, [selectedIoId])

  return (
    <div className="flex flex-col">
      {ioValueExists && (
        <div className="flex justify-evenly">
          <div className="inline-block w-80 ">
            <p
              data-testid="act-mgr-target-moments-table-io-persona-duplicate-error"
              className="pl-3 text-red-600"
            >
              {INSERTION_ORDER_EXISTS}
            </p>
          </div>
          <div className="inline-block w-80 ">&nbsp;</div>
        </div>
      )}
      <div
        data-testid="act-mgr-target-moments-table-io-persona-wrapper"
        className="flex justify-evenly"
      >
        <InsertionOrderSelector
          {...{
            clusterId,
            targetList,
            setIOsToTargetList,
            ioId,
            ioName,
            iosOptions,
            setIoId,
            ioValueExists,
            setIoValueExists
          }}
        />
        <div className="flex gap-1">
          <PersonaSelector
            {...{ clusterId, ioId, personaOptions, setPersonasToTargetList, selectedPersonas, ioValueExists }}
          />
          <RemoveButton {...{ clusterId, handleRemoveRow, ioId, removeIOsFromTargetList, rowKey, ioValueExists }} />
        </div>
      </div>
    </div>
  )
}

const InsertionOrderSelector = (props: {
  ioId: number | undefined
  setIoId: React.Dispatch<React.SetStateAction<number | undefined>>
  ioName: string | undefined
  clusterId: number
  iosOptions: IoOptionsType[]
  targetList: Map<number, TargetListType>
  ioValueExists: boolean | undefined
  setIOsToTargetList: (clusterId: number, ioId: number, prevIoId: number | undefined) => void
  setIoValueExists: React.Dispatch<React.SetStateAction<boolean | undefined>>
}) => {
  const {
    ioId,
    setIoId,
    clusterId,
    setIOsToTargetList,
    ioValueExists,
    setIoValueExists,
    ioName,
    iosOptions,
    targetList
  } = props

  /**
   * Handles the change event when the Insertion Order (IO) ID value is modified.
   * Updates state variables, checks for duplicate values, and invokes the setIOsToTargetList function.
   *
   * @param {number} ioIdValue - The new value of the Insertion Order (IO) ID.
   * @returns {void}
   */
  const handleChange = (ioIdValue: number): void => {
    if (!ioIdValue) return
    // Check if the current Target List for the cluster has IO ID with personas to determine duplicate values
    if (targetList.get(clusterId)?.ioIdWithPersonas.has(ioIdValue)) {
      if (!ioId) setIoValueExists(true)
      return
    }
    // Set state to indicate no duplicate values
    setIoValueExists(false)
    setIoId((prev) => ioIdValue)
    // Invoke the function to update the Target List with the new IO ID
    setIOsToTargetList(clusterId, ioIdValue, ioId)
  }

  const selectPickerStyle = ioValueExists
    ? { width: DROP_DOWN_WIDTH, border: '1px solid red', borderRadius: '6px' }
    : { width: DROP_DOWN_WIDTH }

  return (
    <Tooltip
      disabled={!ioId}
      content={
        ioName && (
          <div
            data-testid="act-mgr-target-moments-table-io-persona-io-selector-tooltip"
            className="text-left"
          >
            {ioName}
          </div>
        )
      }
    >
      <div
        data-testid="act-mgr-target-moments-table-io-persona-io-selector"
        className="px-3 py-1 text-sm text-gray-500 whitespace-nowrap"
      >
        <SelectPicker
          data={iosOptions}
          style={selectPickerStyle}
          placement="autoVerticalStart"
          id="insertionOrderInput"
          labelKey="name"
          valueKey="id"
          cleanable={false}
          placeholder="Insertion Order"
          onChange={handleChange}
          value={ioId}
        />
      </div>
    </Tooltip>
  )
}

const PersonaSelector = (props: {
  personaOptions: Array<Record<string, string>>
  ioId: number | undefined
  clusterId: number
  ioValueExists: boolean | undefined
  setPersonasToTargetList: (clusterId: number, ioId: number, personas: string[]) => void
  selectedPersonas?: string[]
}) => {
  const { ioId, personaOptions, ioValueExists, clusterId, setPersonasToTargetList, selectedPersonas } = props
  const [personas, setPersonas] = React.useState<string[]>(selectedPersonas || [])

  React.useEffect(() => {
    selectedPersonas && setPersonas(() => selectedPersonas)
  }, [selectedPersonas])

  const handleChange = (value: any[]) => {
    setPersonas((prev) => value)
    ioId && setPersonasToTargetList(clusterId, ioId, value)
  }

  const renderFooter = () => {
    if (!personaOptions.length) return
    return (
      <DataPickerFooter
        rowCount={personaOptions.length}
        handleClear={() => {
          handleChange([])
        }}
        handleSelectAll={() => {
          handleChange(personaOptions.map(({ value }) => value))
        }}
      />
    )
  }

  return (
    <>
      <div
        data-testid="act-mgr-target-moments-table-io-persona-persona-selector"
        className="w-full px-3 py-1 text-sm text-gray-500 whitespace-nowrap"
      >
        <CheckPicker
          data={personaOptions}
          disabled={!ioId || ioValueExists}
          appearance="default"
          placement="autoVerticalStart"
          placeholder="Personas"
          className={`${ioId && personas.length < 1 && 'rounded-md ring-1 ring-red-600'}`}
          style={{ width: DROP_DOWN_WIDTH }}
          value={personas}
          onChange={handleChange}
          onClean={() => handleChange([])}
          renderExtraFooter={renderFooter}
        />
      </div>
      <style>
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn {
            height: 38px;
          }
        `}
      </style>
    </>
  )
}

const RemoveButton = (props: {
  handleRemoveRow: (rowKey: string) => void
  rowKey: string
  clusterId: number
  ioId: number | undefined
  removeIOsFromTargetList: (clusterId: number, ioId: number) => void
}) => {
  const { handleRemoveRow, rowKey, clusterId, ioId, removeIOsFromTargetList } = props
  return (
    <>
      {/* Remove button if it's not the first row */}
      {!rowKey.endsWith('_0') ? (
        <button
          data-testid="act-mgr-target-moments-table-io-persona-remove-btn"
          type="button"
          className="rounded-md focus:outline-none "
          onClick={() => {
            handleRemoveRow(rowKey)
            ioId && removeIOsFromTargetList(clusterId, ioId)
          }}
        >
          <span className="sr-only">Close panel</span>
          {/* XMarkIcon component */}
          <XMarkIcon
            className="w-8 h-8"
            aria-hidden="true"
          />
        </button>
      ) : (
        // Placeholder if it's the first row
        <div className="w-8 h-8 "></div>
      )}
    </>
  )
}
