import React from 'react'
import { FormLabel } from './SightlyFormLabel'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

type valueType = string | number | null | undefined

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface IProps {
    label?: string
    showLabelStar?: boolean
    options: any
    value: valueType
    placeholder?: string
    width?: number
    labelKey: string
    valueKey: string
    onChange: Function
    disabled?: boolean
    id: string
    loading?: boolean
    searchable?: boolean
    buttonClass?: string
}

const SightlySelect = ({
    label,
    showLabelStar,
    options = [],
    value,
    placeholder,
    width,
    labelKey,
    valueKey,
    onChange,
    disabled,
    id,
    loading,
    buttonClass
}: IProps) => {
    const selectedItem = React.useMemo(() => {
        if (!options || !value) {
            return {}
        }
        return options.filter((option: any) => option[valueKey] == value)[0] || {}
    }, [value, options])

    return (
        <Listbox
            value={selectedItem}
            onChange={(val) => {
                onChange(val[valueKey])
            }}
            disabled={loading || disabled}
        >
            {({ open }) => (
                <div className="block">
                    {label && (
                        <FormLabel
                            id={id}
                            text={label}
                            required={showLabelStar}
                        />
                    )}
                    <div
                        className="relative"
                        style={{ width: width || undefined }}
                    >
                        <Listbox.Button className={`${buttonClass} form-select focus:border-sightly-blue focus:ring-sightly-blue relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`}>
                            <span
                                className={`block truncate ${
                                    !selectedItem[labelKey] && placeholder && 'text-slate-500'
                                }`}
                            >
                                {selectedItem[labelKey] || placeholder}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="w-full absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((option: any) => {
                                    return (
                                        <Listbox.Option
                                            key={option[valueKey]}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-sightly-blue-light' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9 text-left'
                                                )
                                            }
                                            value={option}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'block truncate'
                                                        )}
                                                    >
                                                        {option[labelKey]}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-slate-500' : 'text-slate-400',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    )
                                })}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export default SightlySelect
