import { z } from 'zod';
import { INarrative as Narrative } from '@/views/Discover/Boards/types';

const areaChartDataSchema = z.object({
  date: z.string(),
  posts: z.number(),
})

export type IAreaChartData = z.infer<typeof areaChartDataSchema>

export enum RelevanceClassification {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low'
}

const resultSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  overview: z.string().optional(),
  imageUrl: z.string().optional(),
  totalTrends: z.number(),
  totalMoments: z.number(),
  totalSocialPosts: z.number(),
  relevance: z.enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low]),
  growth: z.number()
})

export type IResult = z.infer<typeof resultSchema>

const MetricsPerDaySchema = z.object({
  count: z.number(),
  date: z.string(), 
});

const MetricsSchema = z.object({
  moment: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema),
    articleCount: z.number(),
    articleCountPerDay: z.array(MetricsPerDaySchema),
  }),
  narrative: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema),
    postCount: z.number(),
    postCountPerDay: z.array(MetricsPerDaySchema),
  }),
  trend: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema),
  }),
  sentimentScore: z.number(),
});

export const peopleSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type IPeople = z.infer<typeof peopleSchema>

export const brandsSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type IBrand = z.infer<typeof brandsSchema>

const MomentArticlesOnDateSchema = z.object({
});

const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  taxonomyCode: z.string(),
  taxonomyLevel: z.number(),
  garmFlagged: z.boolean(),
});

export const topicSchema = z.object({
  id: z.string(), 
  name: z.string(),
  overview: z.string(), 
  bulletsText: z.array(z.string()).optional(),      
  imageUrl: z.string().optional(), 
  categories: z.array(CategorySchema),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  metrics: MetricsSchema,
  relevance: z.enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low]).optional(), 
  growth: z.number().optional()
})

export type ITopic = z.infer<typeof topicSchema>

export const trendSchema = z.object({
  id: z.string(),                       
  name: z.string(),                     
  overview: z.string(),                 
  bulletsText: z.array(z.string()).optional(),      
  imageUrl: z.string().optional(),                
  categories: z.array(CategorySchema),
  createdAt: z.date(),                  
  updatedAt: z.date(),
  metrics: MetricsSchema,
  relevance: z.enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low]).optional(), 
  growth: z.number().optional()
})

export type ITrend = z.infer<typeof trendSchema>

export const momentSchema = z.object({
  id: z.string(),                                     
  name: z.string(),                                  
  summary: z.string(),                                
  articleCount: z.number(),                          
  videoCount: z.number(),                            
  minPublishedAt: z.date(),                          
  maxPublishedAt: z.date(),                           
  imageUrl: z.string().optional(),                   
  articleCountPerDay: z.array(MomentArticlesOnDateSchema).optional(),
  categories: z.array(CategorySchema).optional()     
});

export type IMoment = z.infer<typeof momentSchema>

const metricSchema = z.object({
  type: z.string(),
  totalCount: z.number(),
  countByDay: z.array(z.object({day: z.string(), count: z.number()})),
  createdAt: z.string(),
  updatedAt: z.string()
})

export const trendMetricSchema = metricSchema.extend({
  trendId: z.number()
})

export type ITrendMetric = z.infer<typeof trendMetricSchema>

export const topicMetricSchema = metricSchema.extend({
  topicId: z.number()
})

export type ITopicMetric = z.infer<typeof topicMetricSchema>

export const NarrativePostSchema = z.object({
  id: z.string(),                        
  name: z.string(),                     
  summary: z.date(),                    
  themes: z.array(z.string()),         
  sources: z.array(z.string())        
});
export const NarrativePostsOnDateSchema = z.object({
  count: z.number(),   
  date: z.date()      
});

export const NarrativePostsFromSourceSchema = z.object({
  count: z.number(),   
  source: z.string()   
});

// to do: this schema does not have the right information. Fields like sourceTypeList are missing
export const narrativeSchema = z.object({
  id: z.string(),                                
  name: z.string(),                              
  sources: z.array(z.string()),                 
  minPublishedAt: z.date(),                      
  maxPublishedAt: z.date(),                      
  topPost: NarrativePostSchema,                  
  postCount: z.number(),                        
  postCountPerDay: z.array(NarrativePostsOnDateSchema).optional(), 
  postCountPerSource: z.array(NarrativePostsFromSourceSchema).optional() 
});

export type INarrative = z.infer<typeof narrativeSchema>

export const momentTimelineSchema = z.array(
  z.object({
    title: z.string(),
    date: z.string(),
    type: z.string(),
    count: z.number().optional(),
    avatar: z.string()
  })
)

export type IMomentTimeline = z.infer<typeof momentTimelineSchema>

export const articleSchema = z.object({
  id: z.number(),
  imageUrl: z.string(),
  title: z.string(),
  source: z.string(),
  timestamp: z.string(),
})

export type IArticle = z.infer<typeof articleSchema>

export interface metaData {
  total: number,
  page?: number
}

export interface ArticleResultsResponse {
  articles: IArticle[],
  meta: metaData
}

export interface NarrativeResultsResponse {
  narratives: Narrative[],
  meta: metaData
}

export interface ICategory { 
  id: number, 
  name: string, 
  codes?: string[], 
  exclude?: string[]
}

export type TopicTrendCardType = 'Topic' | 'Trend';