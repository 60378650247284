import axios from 'axios'

import config from '@/config'

const userAccountAxios = axios.create({
    baseURL: config.api.userAccountUrl
})

const listBuilderAxios = axios.create({
    baseURL: config.api.listBuilderUrl
})

const interceptorRequestConfig = (config) => {
    const token = localStorage.getItem('token')
    if (token && !config.url.includes('authenticate')) {
        config.headers.common = { Authorization: `${token}` }
    }

    return config
}
const interceptorRequestError = (error) => {
    Promise.reject(error)
}

const interceptorResponse = (response) => {
    return response
}
const interceptorResponseError = (error) => {
    if (axios.isCancel(error)) {
        return Promise.reject(error)
    }

    if (error.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        return Promise.reject(new Error('Unauthorized access'))
    }

    return Promise.reject(error)
}

userAccountAxios.interceptors.request.use(interceptorRequestConfig, interceptorRequestError)
userAccountAxios.interceptors.response.use(interceptorResponse, interceptorResponseError)

listBuilderAxios.interceptors.request.use(interceptorRequestConfig, interceptorRequestError)
listBuilderAxios.interceptors.response.use(interceptorResponse, interceptorResponseError)

export { userAccountAxios, listBuilderAxios }
