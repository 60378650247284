import React from 'react'
import { routes } from '@/routes'
import { Link, ReactLocation, Route, Router } from '@tanstack/react-location'
import ProtectedRoute from '@/auth/ProtectedRoute'
import Dashboard from '@/views/Discover/Boards/Dashboard'
import Custom from '@/views/Discover/Boards/Custom'
import AI from '@/views/Discover/Boards/AI'
import AylienBoard from '@/views/Discover/Boards/AylienBoard'
import { AuthRoute } from '@/auth/AuthRoute'
import BasicInfo from '@/views/BrandProfiles/BrandProfile/components/Information/BasicInfo'
import QueryTypeOneSection from '@/views/BrandProfiles/BrandProfile/components/Queries/QueryTypeOneSection'
import BrandSection from '@/views/BrandProfiles/BrandProfile/components/Queries/Brand/BrandSection'
import { typeOneSections } from '@/views/BrandProfiles/BrandProfile/components/Queries/constants'
import SocialSection from '@/views/BrandProfiles/BrandProfile/components/Queries/SocialSection'
import EventSection from '@/views/BrandProfiles/BrandProfile/components/Queries/EventSection'
import Opinions from '@/views/BrandProfiles/BrandProfile/components/Opinions/Opinions'
import MediaResponsibility from '@/views/BrandProfiles/BrandProfile/components/MediaResponsibility'
import IabCategories from '@/views/BrandProfiles/BrandProfile/components/IabCategories/IabCategories'
import Topics from '@/views/BrandProfiles/BrandProfile/components/Topics'
import GoogleAds from '@/views/BrandProfiles/BrandProfile/components/GoogleAds/GoogleAds'
import Aylien from '@/views/BrandProfiles/BrandProfile/components/Aylien/Aylien'
import { aylienTypes } from '@/views/BrandProfiles/BrandProfile/components/Aylien/useBrandProfileAylien'
import Keywords from '@/views/BrandProfiles/BrandProfile/components/Keywords/Keywords'
import Targetview from '@/views/BrandProfiles/BrandProfile/components/Targetview/Targetview'
import Review from '@/views/BrandProfiles/BrandProfile/components/Review/Review'
import { MyLocationGenerics } from '@/classes/utils'
import PortalModal from '@/components/PortalModal/PortalModal'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ReactComponent as WarningIcon } from '@/assets/img/warning-large.svg'
import { perms } from '@/staticData/permissions'
import { AuthErrorPage } from '@/components/AuthErrorPage'
import { ScenariosAdmin } from '@/views/Admin/Scenarios/ScenariosAdmin'
import { CreateScenario } from '@/views/Admin/Scenarios/CreateScenario'
import { Themes } from '@/views/Admin/Scenarios/Themes'
import { CreateTheme } from '@/views/Admin/Scenarios/CreateTheme'
import { EditScenario } from '@/views/Admin/Scenarios/EditScenario'
import { EditTheme } from '@/views/Admin/Scenarios/EditTheme'
import { Activations } from '@/views/Discover/Activations/Activations'
import { Integrations } from '@/views/Integrations/page'
import Trends from '@/views/Trends/Trends'
import { isIframe } from '@/validations'
import ReportGenerationForm from '@/views/ReportGenerator/ReportGeneratorForm'
import { Signup } from '@/views/Signup/page'
import { Login } from '@/views/Login/page'
import { ResetPassword } from '@/views/ResetPassword/page'
import { CheckYourEmail } from '@/views/CheckYourEmail/page'
import { ChangePassword } from '@/views/ChangePassword/page'
import BrandProfile from '@/views/BrandProfiles/BrandProfile/BrandProfile'
import NewBrandProfile from '@/views/BrandProfiles/NewBrandProfile'
import { StripeCheckout } from '@/views/Stripe/checkout/page'
import { StripePricing } from '@/views/Stripe/pricing/page'
import { products, userActions } from '@/staticData/products'
import { Queries } from '@/views/BrandProfiles/BrandProfile/components/Queries'

import { useUnleashContext } from '@unleash/proxy-client-react'
import { OnboardingHub } from '@/views/OnboardingHub'
import { SocialTopics } from '@/views/Discover/SocialTopics'
import { ScenariosV2 } from '@/views/BrandProfiles/BrandProfile/components/ScenariosV2/ScenariosV2'
import { Hashtags } from '@/views/TikTok/Hashtags/page'
import { TikTokActivations } from '@/views/TikTok/Activations/page'
import { Campaigns } from '@/views/TikTok/Campaigns/Campaigns'
import { ActivationReportsNewReport } from '@/views/ActivationReports/ActivationReportsNewReport'
import { ActivationReportMainView } from '@/views/ActivationReports/ActivationReportMainView'
import { TrendDetails } from './views/Trends/TrendDetails'
import { TrendsResult } from './views/Trends/Results/page'
import { TopicDetails } from './views/Trends/TopicDetails'
import {TargetViewSummaryReportView} from "@/views/TargetViewSummaryReport/TargetViewSummaryReportView";


const AylienNews = () => {
  return (
    <Aylien
      key={aylienTypes.news}
      aylienType={aylienTypes.news}
    />
  )
}
const AylienIndustries = () => {
  return (
    <Aylien
      key={aylienTypes.industries}
      aylienType={aylienTypes.industries}
    />
  )
}

const BrandProfileQueries = () => (
  <Queries
    key={'default'}
    path={'default'}
  />
)
const BrandProfileQueriesBrands = () => (
  <Queries
    key={'brands'}
    path={'brands'}
  />
)
const BrandProfileQueriesInfluencers = () => (
  <Queries
    key={'influencers'}
    path={'influencers'}
  />
)
const BrandProfileQueriesPhilanthropic = () => (
  <Queries
    key={'philanthropic'}
    path={'philanthropic'}
  />
)
const BrandProfileQueriesEvents = () => (
  <Queries
    key={'events'}
    path={'events'}
  />
)
const BrandProfileQueriesSocial = () => (
  <Queries
    key={'social'}
    path={'social'}
  />
)

const PageNotFound = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-full ">
        <p className="text-2xl">404: Page not found</p>
        <p className="text-base">The page you are looking for doesn't exist.</p>
      </div>
    </>
  )
}

export const location = new ReactLocation<MyLocationGenerics>()

const RouteContainer = () => {
  const updateContext = useUnleashContext()
  updateContext({ userId: localStorage.getItem('email')?.toString() })

  const newProfileAccessList = [
    {
      requiredPermission: perms.BRAND_PROFILE_CREATE,
      requiredProduct: products.BRAND_PROFILES,
      userAction: userActions.CREATE
    },
    {
      requiredPermission: perms.BRAND_PROFILE_CREATE,
      requiredProduct: products.BRAND_PROFILE_INFO_ONLY
    }
  ]

  const rlRoutes: Route<MyLocationGenerics>[] = [
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/stripe',
      children: [
        {
          path: '/checkout',
          element: <StripeCheckout />
        },
        {
          path: '/pricing',
          element: <StripePricing />
        }
      ]
    },
    {
      path: '/signup',
      element: isIframe() ? <Signup /> : <Login />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/resetPassword',
      element: <ResetPassword />
    },
    {
      path: routes.checkYourEmail.path,
      element: <CheckYourEmail />
    },
    {
      path: routes.changePassword.path,
      element: <ChangePassword />
    },
    {
      path: '/userNotFound',
      element: <AuthErrorPage message="We were unable to find your user information. Please contact support." />
    },
    {
      path: '/admin',
      element: (
        <div className="flex items-center justify-center pt-10">
          <p>This route was moved to /app/admin</p>
          <Link to={'/app/admin/roles'}>Click here to go there now</Link>
        </div>
      )
    },
    {
      path: '/reporting',
      element: <AuthRoute />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute
              component={routes.reporting.component}
              requiredAccessList={[{ requiredPermission: perms.REPORTING_GENERATOR_CREATE }]}
            />
          )
        },
        {
          path: '/new',
          element: (
            <ProtectedRoute
              component={ReportGenerationForm}
              requiredAccessList={[{ requiredPermission: perms.REPORTING_GENERATOR_CREATE }]}
            />
          )
        }
      ]
    },
    {
      path: 'app',
      element: <AuthRoute />,
      searchFilters: [
        (search) => ({
          ...search,
          accountId: search.accountId
        })
      ],
      children: [
        {
          path: 'activation-reports',
          children: [
            {
              path: '/',
              element: (
                <ProtectedRoute
                  component={ActivationReportMainView}
                  requiredAccessList={[{ requiredPermission: perms.REPORTING_GENERATOR_CREATE }]}
                />
              )
            },
            {
              path: '/new',
              element: (
                <ProtectedRoute
                  component={ActivationReportsNewReport}
                  requiredAccessList={[{ requiredPermission: perms.REPORTING_GENERATOR_CREATE }]}
                />
              )
            },
          ]
        },
        {
          path: 'summary-report',
          children: [
            {
              path: '/',
              element: (
                <ProtectedRoute
                  component={TargetViewSummaryReportView}
                  requiredAccessList={[{ requiredPermission: perms.REPORTING_GENERATOR_CREATE }]}
                />
              )
            }
          ]
        },
        {
          path: 'onboardingHub',
          element: (
            <ProtectedRoute
              component={OnboardingHub}
              requiredAccessList={[]}
              checkAccountActive={false}
            />
          )
        },
        {
          path: 'admin',
          children: [
            {
              path: 'scenarios',
              children: [
                {
                  path: '/',
                  element: (
                    <ProtectedRoute
                      component={ScenariosAdmin}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                },
                {
                  path: '/create',
                  element: (
                    <ProtectedRoute
                      component={CreateScenario}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                },
                {
                  path: 'edit',
                  children: [
                    {
                      path: ':scenarioId',
                      element: (
                        <ProtectedRoute
                          component={EditScenario}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    }
                  ]
                },
                {
                  path: '/themes',

                  children: [
                    {
                      path: '/',
                      element: (
                        <ProtectedRoute
                          component={Themes}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    },
                    {
                      path: 'create',
                      element: (
                        <ProtectedRoute
                          component={CreateTheme}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    },
                    {
                      path: 'edit',
                      children: [
                        {
                          path: ':themeId',
                          element: (
                            <ProtectedRoute
                              component={EditTheme}
                              requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                            />
                          )
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'opinions',
              children: [
                {
                  path: '/',
                  element: (
                    <ProtectedRoute
                      component={routes.admin.opinions.component}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                },
                {
                  path: 'types',
                  children: [
                    {
                      path: '/',
                      element: (
                        <ProtectedRoute
                          component={routes.admin.opinions.types.component}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    },
                    {
                      path: 'edit/:opinionTypeId',
                      element: (
                        <ProtectedRoute
                          component={routes.admin.opinions.types.edit.component}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    }
                  ]
                },
                {
                  path: 'create',
                  element: (
                    <ProtectedRoute
                      component={routes.admin.opinions.create.component}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                },
                {
                  path: 'edit',
                  children: [
                    {
                      path: ':opinionId',
                      element: (
                        <ProtectedRoute
                          component={routes.admin.opinions.edit.component}
                          requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                        />
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'permissions',
              element: (
                <ProtectedRoute
                  component={routes.admin.permissions.component}
                  requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                />
              )
            },
            {
              path: 'roles',
              element: (
                <ProtectedRoute
                  component={routes.admin.roles.component}
                  requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                />
              )
            },
            {
              path: 'iabCategories',
              element: (
                <ProtectedRoute
                  component={routes.admin.iabCategories.component}
                  requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                />
              )
            },
            {
              path: 'evergreenChannels',
              children: [
                {
                  path: '/',
                  element: (
                    <ProtectedRoute
                      component={routes.admin.evergreenChannels.component}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                },
                {
                  path: 'create',
                  element: (
                    <ProtectedRoute
                      component={routes.admin.evergreenChannels.create.component}
                      requiredAccessList={[{ requiredPermission: perms.ADMIN_READ }]}
                    />
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'discover',
          children: [
            {
              path: 'moments',
              children: [
                {
                  path: '/v2',
                  children: [
                    {
                      path: '/',
                      element: (
                        <ProtectedRoute
                          component={AylienBoard}
                          requiredAccessList={[
                            { requiredPermission: perms.AYLIEN_TRENDS_READ, requiredProduct: products.BOARDS }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'settings/redesign',
                      element: (
                        <ProtectedRoute
                          component={Dashboard}
                          requiredAccessList={[
                            { requiredPermission: perms.AYLIEN_TRENDS_READ, requiredProduct: products.BOARDS }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'boards',
                      children: [
                        {
                          path: 'create',
                          children: [
                            {
                              path: 'custom',
                              element: (
                                <ProtectedRoute
                                  component={Custom}
                                  requiredAccessList={[
                                    {
                                      requiredPermission: perms.AYLIEN_BOARD_CREATE,
                                      requiredProduct: products.BOARDS,
                                      userAction: userActions.CREATE
                                    }
                                  ]}
                                />
                              )
                            },
                            {
                              path: 'ai',
                              element: (
                                <ProtectedRoute
                                  component={AI}
                                  requiredAccessList={[
                                    {
                                      requiredPermission: perms.AYLIEN_BOARD_CREATE,
                                      requiredProduct: products.BOARDS,
                                      userAction: userActions.CREATE
                                    }
                                  ]}
                                />
                              )
                            }
                          ]
                        },
                        {
                          path: 'edit',
                          children: [
                            {
                              path: 'custom/:boardId',
                              element: (
                                <ProtectedRoute
                                  component={Custom}
                                  requiredAccessList={[
                                    { requiredPermission: perms.AYLIEN_BOARD_CREATE, requiredProduct: products.BOARDS }
                                  ]}
                                />
                              )
                            },
                            {
                              path: 'ai/:boardId',
                              element: (
                                <ProtectedRoute
                                  component={AI}
                                  requiredAccessList={[
                                    {
                                      requiredPermission: perms.AYLIEN_BOARD_CREATE,
                                      requiredProduct: products.BOARDS,
                                      userAction: userActions.UPDATE
                                    }
                                  ]}
                                />
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                { element: <PageNotFound /> }
              ]
            },
            {
              path: 'activations',
              children: [
                {
                  path: 'google-ads',
                  element: (
                    <ProtectedRoute
                      component={Activations}
                      requiredAccessList={[
                        { requiredPermission: perms.ACTIVATIONS_READ, requiredProduct: products.GOOGLE_ADS_ACTIVATIONS }
                      ]}
                    />
                  )
                },
                {
                  path: 'campaigns',
                  element: (
                    <ProtectedRoute
                      component={Campaigns}
                      requiredAccessList={[{ requiredPermission: perms.TIKTOK_CAMPAIGNS_READ }]}
                    />
                  )
                },
                {
                  path: 'tiktok',
                  element: (
                    <ProtectedRoute
                      component={TikTokActivations}
                      requiredAccessList={[{ requiredPermission: perms.TIKTOK_ACTIVATIONS_READ }]}
                    />
                  )
                }
              ]
            },
            {
              path: 'measure',
              element: (
                <ProtectedRoute
                  component={routes.app.discover.measure.component}
                  requiredAccessList={[{ requiredPermission: perms.MEASURE_READ }]}
                />
              )
            },
            {
              path: 'reporting',
              element: (
                <ProtectedRoute
                  component={routes.app.discover.reporting.component}
                  requiredAccessList={[
                    { requiredPermission: perms.REPORTING_READ, requiredProduct: products.REPORTING }
                  ]}
                />
              )
            }
          ]
        },
        {
          path: 'momentIntelligence',
          element: (
            <ProtectedRoute
              component={routes.app.momentIntelligence.component}
              requiredAccessList={[
                { requiredPermission: perms.MOMENTS_INTELLIGENCE_READ, requiredProduct: products.MOMENTS_INTELLIGENCE }
              ]}
            />
          )
        },
        {
          path: 'socialTopics',
          element: (
            <ProtectedRoute
              component={SocialTopics}
              requiredAccessList={[{ requiredPermission: perms.REPORTING_READ }]}
            />
          )
        },
        {
          path: 'brandProfiles',
          children: [
            {
              path: '/',
              searchFilters: [
                (search) => {
                  delete search.viewOnly
                  return {
                    ...search
                  }
                }
              ],
              element: (
                <ProtectedRoute
                  component={routes.app.brandProfiles.component}
                  atleastOneAccessList={[
                    { requiredPermission: perms.BRAND_PROFILES_PAGE_READ, requiredProduct: products.BRAND_PROFILES },
                    {
                      requiredPermission: perms.BRAND_PROFILES_PAGE_READ,
                      requiredProduct: products.BRAND_PROFILE_INFO_ONLY
                    }
                  ]}
                />
              )
            },
            {
              path: '/new',
              element: (
                <ProtectedRoute
                  component={NewBrandProfile}
                  atleastOneAccessList={newProfileAccessList}
                />
              )
            },
            {
              path: 'brandProfile',
              children: [
                {
                  path: ':brandProfileId',
                  element: (
                    <ProtectedRoute
                      component={BrandProfile}
                      atleastOneAccessList={[
                        { requiredPermission: perms.BRAND_PROFILE_READ, requiredProduct: products.BRAND_PROFILES },
                        {
                          requiredPermission: perms.BRAND_PROFILE_READ,
                          requiredProduct: products.BRAND_PROFILE_INFO_ONLY
                        }
                      ]}
                    />
                  ),
                  children: [
                    {
                      path: 'brandInformation',
                      element: (
                        <ProtectedRoute
                          component={BasicInfo}
                          atleastOneAccessList={[
                            { requiredPermission: perms.BRAND_PROFILE_READ, requiredProduct: products.BRAND_PROFILES },
                            {
                              requiredPermission: perms.BRAND_PROFILE_READ,
                              requiredProduct: products.BRAND_PROFILE_INFO_ONLY
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'queries',
                      children: [
                        {
                          path: '/',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueries}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        },
                        {
                          path: '/brands',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueriesBrands}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        },
                        {
                          path: '/influencers',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueriesInfluencers}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        },
                        {
                          path: '/philanthropic',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueriesPhilanthropic}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        },
                        {
                          path: '/events',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueriesEvents}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        },
                        {
                          path: '/social',
                          element: (
                            <ProtectedRoute
                              component={BrandProfileQueriesSocial}
                              requiredAccessList={[
                                {
                                  requiredPermission: perms.BRAND_PROFILE_READ,
                                  requiredProduct: products.BRAND_PROFILES
                                }
                              ]}
                            />
                          )
                        }
                      ]
                    },
                    {
                      path: 'brands',
                      element: <BrandSection key="1" />
                    },
                    {
                      path: 'influencers',
                      element: (
                        <QueryTypeOneSection
                          key="2"
                          queryType={typeOneSections.influencers}
                        />
                      )
                    },
                    {
                      path: 'events',
                      element: <EventSection />
                    },
                    {
                      path: 'philanthropic',
                      element: (
                        <QueryTypeOneSection
                          key="4"
                          queryType={typeOneSections.philanthropic}
                        />
                      )
                    },
                    {
                      path: 'social',
                      element: <SocialSection />
                    },
                    {
                      path: 'opinions',
                      element: <Opinions />
                    },
                    {
                      path: 'scenarios',
                      element: <ScenariosV2 />
                    },
                    {
                      path: 'mediaResponsibility',
                      element: (
                        <ProtectedRoute
                          component={MediaResponsibility}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_AD_FONTES_MEDIA_RESPONSIBILITY,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'iabCategories',
                      element: (
                        <ProtectedRoute
                          component={IabCategories}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_CATEGORIES_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'topics',
                      element: (
                        <ProtectedRoute
                          component={Topics}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_TOPICS_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'googleAds',
                      element: (
                        <ProtectedRoute
                          component={GoogleAds}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_TOPICS_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'news',
                      element: (
                        <ProtectedRoute
                          component={AylienNews}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_AYLIEN_NEWS_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'industries',
                      element: (
                        <ProtectedRoute
                          component={AylienIndustries}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_AYLIEN_INDUSTRY_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'keywords',
                      element: <Keywords />
                    },
                    {
                      path: 'targetview',
                      element: (
                        <ProtectedRoute
                          component={Targetview}
                          requiredAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_IOS_READ,
                              requiredProduct: products.BRAND_PROFILES
                            }
                          ]}
                        />
                      )
                    },
                    {
                      path: 'submit',
                      element: (
                        <ProtectedRoute
                          component={Review}
                          atleastOneAccessList={[
                            {
                              requiredPermission: perms.BRAND_PROFILE_UPDATE,
                              requiredProduct: products.BRAND_PROFILES
                            },
                            {
                              requiredPermission: perms.BRAND_PROFILE_UPDATE,
                              requiredProduct: products.BRAND_PROFILE_INFO_ONLY
                            }
                          ]}
                        />
                      )
                    },
                    { element: <PageNotFound /> }
                  ]
                },
                { element: <PageNotFound /> }
              ]
            },
            { element: <PageNotFound /> }
          ]
        },
        {
          path: 'tiktok',
          children: [
            {
              path: 'hashtags',
              element: (
                <ProtectedRoute
                  component={Hashtags}
                  requiredAccessList={[
                    {
                      requiredPermission: perms.TIKTOK_HASHTAGS_DASHBOARD_READ,
                      requiredProduct: products.TIKTOK_HASHTAGS_DASHBOARD
                    }
                  ]}
                />
              )
            },
            {
              path: 'activations',
              element: (
                <ProtectedRoute
                  component={TikTokActivations}
                  requiredAccessList={[]}
                />
              )
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'users',
              children: [
                {
                  path: '/',
                  element: (
                    <ProtectedRoute
                      component={routes.app.settings.users.component}
                      requiredAccessList={[{ requiredPermission: perms.USER_READ, requiredProduct: products.USERS }]}
                      checkAccountActive={false}
                    />
                  )
                },
                {
                  path: 'create',
                  element: (
                    <ProtectedRoute
                      component={routes.app.settings.users.create.component}
                      requiredAccessList={[
                        {
                          requiredPermission: perms.USER_CREATE,
                          requiredProduct: products.USERS,
                          userAction: userActions.CREATE
                        }
                      ]}
                      checkAccountActive={false}
                    />
                  )
                },
                {
                  path: 'edit',
                  children: [
                    {
                      path: ':userId',
                      element: (
                        <ProtectedRoute
                          component={routes.app.settings.users.edit.component}
                          requiredAccessList={[
                            { requiredPermission: perms.USER_READ, requiredProduct: products.USERS }
                          ]}
                          checkAccountActive={false}
                        />
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'profile',
              element: (
                <ProtectedRoute
                  component={routes.app.settings.profile.component}
                  requiredAccessList={[]}
                  checkAccountActive={false}
                />
              )
            },
            {
              path: 'account',
              element: (
                <ProtectedRoute
                  component={routes.app.settings.account.component}
                  requiredAccessList={[{ requiredPermission: perms.ACCOUNT_READ }]}
                  checkAccountActive={false}
                />
              )
            }
          ]
        },
        {
          path: 'engage',
          children: [
            {
              path: 'lists',
              children: [
                {
                  path: '/',
                  element: (
                    <ProtectedRoute
                      component={routes.app.engage.lists.lists.component}
                      requiredAccessList={[
                        { requiredPermission: perms.ENGAGE_READ },
                        { requiredPermission: perms.SMARTLIST_READ }
                      ]}
                    />
                  )
                },
                {
                  path: 'createList',
                  element: (
                    <ProtectedRoute
                      component={routes.app.engage.lists.createList.component}
                      requiredAccessList={[
                        { requiredPermission: perms.ENGAGE_READ },
                        { requiredPermission: perms.SMARTLIST_CREATE }
                      ]}
                    />
                  )
                },
                {
                  path: 'uploadList',
                  element: (
                    <ProtectedRoute
                      component={routes.app.engage.lists.uploadList.component}
                      requiredAccessList={[
                        { requiredPermission: perms.ENGAGE_READ },
                        { requiredPermission: perms.SMARTLIST_CREATE_UPLOAD }
                      ]}
                    />
                  )
                },
                {
                  path: 'channelListBuilder',
                  children: [
                    {
                      path: ':versionId',
                      children: [
                        {
                          path: 'view',
                          element: (
                            <ProtectedRoute
                              component={routes.app.engage.lists.channelListBuilder.component}
                              requiredAccessList={[{ requiredPermission: perms.SMARTLIST_READ }]}
                            />
                          )
                        },
                        {
                          path: 'edit',
                          element: (
                            <ProtectedRoute
                              component={routes.app.engage.lists.channelListBuilder.component}
                              requiredAccessList={[{ requiredPermission: perms.SMARTLIST_EDIT }]}
                            />
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'videoListBuilder',
                  children: [
                    {
                      path: ':versionId',
                      children: [
                        {
                          path: 'view',
                          element: (
                            <ProtectedRoute
                              component={routes.app.engage.lists.videoListBuilder.component}
                              requiredAccessList={[{ requiredPermission: perms.SMARTLIST_READ }]}
                            />
                          )
                        },
                        {
                          path: 'edit',
                          element: (
                            <ProtectedRoute
                              component={routes.app.engage.lists.videoListBuilder.component}
                              requiredAccessList={[{ requiredPermission: perms.SMARTLIST_EDIT }]}
                            />
                          )
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'integrations',
          element: (
            <ProtectedRoute
              component={Integrations}
              requiredAccessList={[
                {
                  requiredPermission: perms.TIKTOK_INTEGRATIONS_READ,
                  requiredProduct: products.TIKTOK_INTEGRATIONS
                }
              ]}
            />
          )
        },
        {
          path: 'trends',
          children: [
            {
              path: 'overview',
              element: (
                <ProtectedRoute
                  component={Trends}
                  requiredAccessList={[
                    {
                      requiredPermission: perms.AYLIEN_TRENDS_READ,
                      requiredProduct: products.BOARDS
                    }
                  ]}
                />
              )
            },
            {
              path: '/trend-details/:trendId',
              element: (
                <ProtectedRoute
                  component={TrendDetails}
                  requiredAccessList={[
                    {
                      requiredPermission: perms.AYLIEN_TRENDS_READ,
                      requiredProduct: products.BOARDS
                    }
                  ]}
                />
              )
            },
            {
              path: 'search',
              element: (
                <ProtectedRoute
                  component={TrendsResult}
                  requiredAccessList={[
                    {
                      requiredPermission: perms.AYLIEN_TRENDS_READ,
                      requiredProduct: products.BOARDS
                    }
                  ]}
                />
              )
            },
            {
              path: 'topic-details/:topicId', //routes.app.trends.topicDetails.path,
              element: (
                <ProtectedRoute
                  component={routes.app.trends.topicDetails.component}
                  requiredAccessList={[
                    {
                      requiredPermission: perms.AYLIEN_TRENDS_READ,
                      requiredProduct: products.BOARDS
                    }
                  ]}
                />
              )
            }
          ]
        },
        { element: <PageNotFound /> }
      ]
    }
  ]

  const [showModal, setShowModal] = React.useState(false)

  return (
    <>
      <PortalModal
        cannotClose
        centerFooterButtons
        footerButtons={[]}
        handleClose={() => setShowModal(false)}
        open={showModal}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 48,
            padding: 48
          }}
        >
          <WarningIcon />
          <div style={{ fontWeight: 600, color: '#333d47', fontSize: 24 }}>This site has been updated</div>
          <div style={{ fontWeight: 500, color: '#7a7a7a', fontSize: 20 }}>
            Please refresh your page to apply the updates.
          </div>
          <SightlyButton
            id="closeRefreshModal"
            handleClick={() => window.location.reload()}
            text="Refresh"
          />
        </div>
      </PortalModal>

      <Router
        location={location}
        routes={rlRoutes}
      />
    </>
  )
}

export default RouteContainer
