import React from 'react'

interface IProps {
    tabs: { id: string, name: string, count: number }[]
    currentTab: string
    setCurrentTab: (id: string) => void
}

const Tabs = ({ tabs, currentTab, setCurrentTab }: IProps) => {
    return (
        <nav
            className="flex gap-8"
            aria-label="Tabs"
            data-testid="activations-main-tabs"
            id="pendo-activations-main-tabs"
        >
            {tabs.map((tab) => (
                <div
                    data-testid='activations-main-tab'
                    id={`pendo-activations-main-tab-${tab.id}`}
                    key={tab.name}
                    className={`whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer ${currentTab === tab.id
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'}`}
                    aria-current={currentTab === tab.id ? 'page' : undefined}
                    onClick={() => {
                        setCurrentTab(tab.id)
                    }}
                >
                    {tab.name}
                    <span
                        className={`ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block ${currentTab === tab.id ? 'bg-sightly-blue-light text-blue-600' : 'bg-slate-300 text-gray-900'}`}
                    >
                        {tab.count}
                    </span>

                </div>
            ))}
        </nav>
    )
}

export default Tabs