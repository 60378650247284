import React from 'react'
import Panel from 'rsuite/lib/Panel'
import { connect } from 'react-redux'
import { fetchBrandProfileIabCategories } from '../../../../../redux/actions/brandProfiles'
import '../../../../../views/Engage/Lists/ListBuilder/components/listbuilder.css'
import Icon from 'rsuite/lib/Icon'
import Input from 'rsuite/lib/Input'
import Tree from 'rsuite/lib/Tree'
import Button from 'rsuite/lib/Button'
import debounce from 'just-debounce-it'
import InputGroup from 'rsuite/lib/InputGroup'
import { listActions } from '../../../../../views/Engage/Lists/constants'
import { accentColor, neutralColor } from '../../../../../assets/jss/colorConstants'
import ButtonGroup from 'rsuite/lib/ButtonGroup'
import {
    patchBrandProfileIabCategories,
    setBrandProfileIabCategories
} from '../../../../../redux/actions/brandProfiles'
import FiltersLabel from '../../../../Engage/Lists/ListBuilder/components/FiltersLabel'
import TagPicker from 'rsuite/lib/TagPicker'
import { useMatch, useSearch } from '@tanstack/react-location'
import { LoadingPage } from '../../../../../components/LoadingPage'
import SightlyToggle from '../../../../../components/Sightly/SightlyFormElements/SightlyToggle'
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter';
import useSteps from '@/hooks/brandProfile/useSteps';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';


const actionIdOptions = [
    {
        label: 'Target',
        id: 'target'
    },
    {
        label: 'Block',
        id: 'block'
    },
    {
        label: 'No Action',
        id: 'noaction'
    }
]

const mapStateToProps = (state) => {
    return {
        brandProfile: state.brandProfileUnderEdit,
        brandProfileIabCategoriesLoading: state.brandProfileIabCategoriesLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBrandProfileIabCategories: (args) => dispatch(fetchBrandProfileIabCategories(args)),
        setBrandProfileIabCategories: (o) => dispatch(setBrandProfileIabCategories(o)),
        patchBrandProfileIabCategories: (params) => dispatch(patchBrandProfileIabCategories(params))
    }
}

class IabCategories extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cascadeUp: false,
            componentIabCategories: [],
            search: '',
            actionFilter: [],
            sort: 'asc'
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.brandProfile.iabCategories) {
            this.setState({
                componentIabCategories: newProps.brandProfile.iabCategories
            })
        }
    }

    componentWillMount() {
        this.fetchIabCategoriesFunction()
    }

    fetchIabCategoriesFunction = () => {
        let params = {
            brandProfileId: this.props.brandProfileId,
            sort: this.state.sort,
            filter: this.state.actionFilter,
            searchTerm: this.state.search
        }

        this.props.fetchBrandProfileIabCategories(params)
    }

    handleActionButtonClick = (actionId, item) => {
        let copy = JSON.parse(JSON.stringify(this.state.componentIabCategories))
        let node = getTreeNodeById(copy, item.iabCategoryId)
        if (node.actionId == actionId) {
            actionId = null
        }
        node.actionId = actionId

        markAllChildren(node, actionId)
        if (this.state.cascadeUp) {
            addressParent(node, actionId, copy)
            addressGrandParent(node, actionId, copy)
            addressGreatGrandParent(node, actionId, copy)
        }

        this.setState({ componentIabCategories: copy })

        let newCopy = JSON.parse(JSON.stringify(copy))
        this.props.setBrandProfileIabCategories(newCopy)

        if (this.state.cascadeUp) {
            formatForApi(newCopy)
        }

        let params = {
            iabCategories: [
                {
                    iabCategoryId: node.iabCategoryId,
                    iabCategoryResponseId: node.actionId
                }
            ],
            brandProfileId: this.props.brandProfileId
        }
        this.props.patchBrandProfileIabCategories(params)
    }

    handleSearch = debounce((text) => {
        this.setState({ search: text }, () => {
            this.fetchIabCategoriesFunction()
        })
    }, 700)

    handleSort = (bool) => {
        let val = bool ? 'asc' : 'desc'
        this.setState({ sort: val }, () => {
            this.fetchIabCategoriesFunction()
        })
    }

    render() {
        return (
            <Panel header={<div className="sightlyPanelHeader">Categories</div>}>
                <div className="flex flex-col gap-8">
                    <div className="descriptionText">
                        Categories available from YouTube can be Targeted or Blocked on an individual level below. These
                        settings affect the SmartLists and are available to internal admin users only.
                    </div>
                    <div>
                        <FiltersLabel text="Actions Taken" />
                        <TagPicker
                            block
                            preventOverflow
                            data={actionIdOptions}
                            labelKey={'label'}
                            valueKey={'id'}
                            value={this.state.actionFilter}
                            placeholder="Filter by action"
                            onChange={(val) => {
                                this.setState({ actionFilter: val }, () => {
                                    this.fetchIabCategoriesFunction()
                                })
                            }}
                        />
                    </div>

                    {this.state.actionFilter?.length > 0 && (
                        <SightlyToggle
                            id="toggle"
                            setEnabled={this.handleSort}
                            enabled={this.state.sort === 'asc'}
                            enabledText="sort a-z"
                            disabledText="sort z-a"
                        />
                    )}

                    <div>
                        <InputGroup>
                            <Input
                                placeholder="Search..."
                                onChange={(val) => this.handleSearch(val)}
                            />

                            <InputGroup.Button
                                onClick={() => {}}
                                style={{ backgroundColor: 'transparent' }}
                            >
                                <Icon
                                    style={{ color: '#0092d1' }}
                                    icon="search"
                                />
                            </InputGroup.Button>
                        </InputGroup>

                        {this.props.brandProfileIabCategoriesLoading ? (
                            <div style={{ height: 200 }}>
                                <LoadingPage message="Fetching Categories" />
                            </div>
                        ) : (
                            <Tree
                                expandAll={false}
                                placement="bottomStart"
                                virtualized={true}
                                defaultExpandAll={false}
                                data={this.state.componentIabCategories}
                                labelKey={'iabCategoryName'}
                                valueKey={'iabCategoryId'}
                                block
                                renderTreeNode={(item) => {
                                    let even = item.refKey[item.refKey.length - 1] % 2 === 0

                                    return (
                                        <div
                                            style={{
                                                backgroundColor: even ? 'rgb(247, 247, 250)' : undefined,
                                                display: 'flex',
                                                width: 700,
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <div style={{ flex: 3 }}>
                                                {item.iabCategoryName + ' ' + item.iabCategoryId}
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                {!item.children && (
                                                    <TreeButtonGroup
                                                        handleActionButtonClick={this.handleActionButtonClick}
                                                        item={item}
                                                        viewOnly={this.props.viewOnly}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        )}
                    </div>
                </div>
                <BrandProfileFooter activeStep={this.props.stepProps.activeStep}
                  disabled={false}
                  handlePrevious={this.props.stepProps.handlePreviousClick}
                  handleContinue={this.props.stepProps.handleContinueClick}
                />
            </Panel>
        )
    }
}

const TreeButtonGroup = ({ handleActionButtonClick, item, viewOnly }) => {
    const { perms, userPermissions } = useUser()
    let targetStyle = {
        backgroundColor: item.actionId == listActions.target.actionId ? accentColor : '',
        color: item.actionId == listActions.target.actionId ? neutralColor : ''
    }
    return (
        <ButtonGroup
            vertical={false}
            size="xs"
        >
            <Button
                appearance={'ghost'}
                disabled={!userCanPermissionProductQuota({
                    requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                }) || viewOnly}
                style={targetStyle}
                onClick={() => {
                    handleActionButtonClick(listActions.target.actionId, item)
                }}
            >
                Target
            </Button>

            <Button
                appearance={'ghost'}
                disabled={!userCanPermissionProductQuota({
                    requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                }) || viewOnly}
                style={{
                    backgroundColor: item.actionId === listActions.block.actionId ? accentColor : '',
                    color: item.actionId === listActions.block.actionId ? neutralColor : ''
                }}
                onClick={() => {
                    handleActionButtonClick(listActions.block.actionId, item)
                }}
            >
                Block
            </Button>
        </ButtonGroup>
    )
}

const getTreeNodeById = (tree, id) => {
    for (const item of tree) {
        if (item.iabCategoryId == id) return item
        if (item.children) {
            for (const child of item.children) {
                if (child.iabCategoryId == id) return child
                if (child.children) {
                    for (const gChild of child.children) {
                        if (gChild.iabCategoryId == id) return gChild
                        if (gChild.children) {
                            for (const ggChild of gChild.children) {
                                if (ggChild.iabCategoryId == id) return ggChild
                                if (ggChild.children) {
                                    for (const gggChild of ggChild.children) {
                                        if (gggChild.iabCategoryId == id) return gggChild
                                        if (gggChild.children) {
                                            for (const ggggChild of gggChild.children) {
                                                if (ggggChild.iabCategoryId == id) return ggggChild
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

const markAllChildren = (node, actionId) => {
    node.actionId = actionId
    if (node.children) {
        for (const child of node.children) {
            markAllChildren(child, actionId)
        }
    }
}

const addressParent = (node, actionId, tree) => {
    let parent = getTreeNodeById(tree, node.parentId)
    if (parent) {
        if (parent.actionId != actionId) {
            parent.actionId = null
        }
        let sibActionId = parent.children[0].actionId
        let childrenAllSame = true
        for (const sib of parent.children) {
            // if all children are the same then mark parent
            if (sib.actionId != sibActionId) {
                childrenAllSame = false
            }
        }
        if (childrenAllSame) {
            parent.actionId = sibActionId
        }
    }
}

const addressGrandParent = (node, actionId, tree) => {
    let parent = getTreeNodeById(tree, node.parentId)
    if (parent) {
        let grandParent = getTreeNodeById(tree, parent.parentId)
        if (grandParent) {
            if (grandParent.actionId != actionId) {
                grandParent.actionId = null
            }
            let sibActionId = grandParent.children[0].actionId
            let childrenAllSame = true
            for (const sib of grandParent.children) {
                // if all children are the same then mark parent
                if (sib.actionId != sibActionId) {
                    childrenAllSame = false
                }
            }
            if (childrenAllSame) {
                grandParent.actionId = sibActionId
            }
        }
    }
}

const addressGreatGrandParent = (node, actionId, tree) => {
    let parent = getTreeNodeById(tree, node.parentId)
    if (parent) {
        let grandParent = getTreeNodeById(tree, parent.parentId)
        if (grandParent) {
            let greatGrandParent = getTreeNodeById(tree, grandParent.parentId)
            if (greatGrandParent) {
                if (greatGrandParent.actionId != actionId) {
                    greatGrandParent.actionId = null
                }
                let sibActionId = greatGrandParent.children[0].actionId
                let childrenAllSame = true
                for (const sib of greatGrandParent.children) {
                    // if all children are the same then mark parent
                    if (sib.actionId != sibActionId) {
                        childrenAllSame = false
                    }
                }
                if (childrenAllSame) {
                    greatGrandParent.actionId = sibActionId
                }
            }
        }
    }
}

const formatForApi = (tree) => {
    //look through each item and if marked and has children then remove actionIds from all children

    for (const row of tree) {
        for (const child of row.children) {
            if (child.children) {
                for (const gChild of child.children) {
                    if (gChild.actionId && gChild.children) {
                        for (const ggChild of gChild.children) {
                            ggChild.actionId = null
                        }
                    }
                }
            }
        }
    }

    for (const row of tree) {
        for (const child of row.children) {
            if (child.actionId && child.children) {
                for (const gChild of child.children) {
                    gChild.actionId = null
                }
            }
        }
    }

    for (const row of tree) {
        if (row.actionId && row.children) {
            for (const child of row.children) {
                child.actionId = null
            }
        }
    }

    //return tree
}

const IabCategoriesWrapper = ({ ...rest }) => {
  const stepProps = useSteps();
  const {
      params: { brandProfileId }
  } = useMatch()
  const { viewOnly: viewOnlyParam } = useSearch()
  const viewOnly = viewOnlyParam || false
  return (
    <IabCategories
      viewOnly={viewOnly}
      brandProfileId={brandProfileId}
      stepProps={stepProps}
      {...rest}
    />
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(IabCategoriesWrapper)
