import React, { useState } from 'react';
import Button from 'rsuite/lib/Button';
import Form from 'rsuite/lib/Form';
import FormGroup from 'rsuite/lib/FormGroup';
import FormControl from 'rsuite/lib/FormControl';
import ControlLabel from 'rsuite/lib/ControlLabel';
import HelpBlock from 'rsuite/lib/HelpBlock';
import Schema from 'rsuite/lib/Schema';
import Table from 'rsuite/lib/Table';
import Loader from 'rsuite/lib/Loader';
import useBrandProfileOpinionTypes from '@/hooks/brandProfile/useBrandProfileOpinionTypes';
import toast from 'react-hot-toast';
import PortalModal from '@/components/PortalModal/PortalModal';
import IconButton from 'rsuite/lib/IconButton'
import { useNavigate } from '@tanstack/react-location';
import { Icon } from 'rsuite';
import { routes } from '@/routes';

function Types() {
    let form
    const [adding, setAdding] = React.useState(false)
    const [formValues, setFormValues] = useState()

    const typesObj = useBrandProfileOpinionTypes({ excludeArchived: false })

    const { StringType } = Schema.Types
    const model = Schema.Model({
        typeName: StringType().isRequired('Type Name is required.')
    })

    const navigate = useNavigate()

    const handleSubmit = async () => {
        if (!form.check()) {
            console.error('Form Error')
            return
        } else {
            await typesObj.createOpinionType(formValues)
            toast.success('Created!')
            setAdding(false)
        }
    }

    return (
        <div style={{ padding: 24 }}>
            {typesObj.opinionTypes && typesObj.opinionTypes.length > 0 ? (
                <div>
                    <Button
                        appearance="primary"
                        onClick={() => setAdding(true)}
                    >
                        Create Opinion Type
                    </Button>

                    <PortalModal
                        open={adding}
                        handleClose={() => setAdding(false)}
                        footerButtons={[
                            <Button
                                onClick={() => handleSubmit()}
                                appearance="primary"
                            >
                                Save
                            </Button>,
                            <Button
                                onClick={() => setAdding(false)}
                                appearance="subtle"
                            >
                                Cancel
                            </Button>
                        ]}
                    >
                        <div style={{ width: 500 }}>
                            <Form
                                fluid
                                ref={(ref) => (form = ref)}
                                model={model}
                                onChange={(formValue) => {
                                    setFormValues(formValue)
                                }}
                            >
                                <h4 style={{ marginBottom: 24 }}>Add Opinion Type</h4>
                                <div>
                                    <FormGroup>
                                        <ControlLabel>Type Name</ControlLabel>
                                        <FormControl name="typeName" />
                                        <HelpBlock>Required</HelpBlock>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Type Description Text</ControlLabel>
                                        <FormControl name="descriptionText" />
                                        <HelpBlock>Required</HelpBlock>
                                    </FormGroup>
                                </div>
                            </Form>
                        </div>
                    </PortalModal>

                    <Table
                        virtualized
                        autoHeight
                        rowHeight={50}
                        data={typesObj.opinionTypes}
                        shouldUpdateScroll={false}
                    >
                        <Table.Column
                            verticalAlign={'middle'}
                            width={500}
                        >
                            <Table.HeaderCell>Type Name</Table.HeaderCell>
                            <Table.Cell                            
                                dataKey="typeName"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                            width={500}
                        >
                            <Table.HeaderCell>Description Text</Table.HeaderCell>
                            <Table.Cell
                                dataKey="descriptionText"
                                style={{ color: 'grey' }}
                            />
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                            width={100}
                        >
                            <Table.HeaderCell>Archived</Table.HeaderCell>
                            <Table.Cell style={{ color: 'grey' }}>
                                {(rowData) => {
                                    return rowData.archived ? 'True' : 'False'
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column
                            verticalAlign={'middle'}
                            width={80}
                        >
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    const { typeId } = rowData;
                                    return (
                                        <IconButton
                                            onClick={() =>
                                                navigate({
                                                    to: `/app/admin/opinions/types/edit/${typeId}`
                                                })
                                            }
                                            icon={<Icon icon="edit" />}
                                            appearance="ghost"
                                            size="xs"
                                            id="editOpinionButton"
                                        >
                                            Edit
                                        </IconButton>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            ) : typesObj.opinionTypesIsLoading ? (
                <Loader
                    center
                    vertical
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 200px)',
                        color: 'white'
                    }}
                >
                    <Button
                        appearance="primary"
                        onClick={() => setAdding(true)}
                    >
                        Create Opinion Type
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Types
