import { z } from 'zod'

const ActivationReportReportSchema = z.object({
  name: z.string(),
  type: z.string(),
  columns: z.array(z.string()),
  templateType: z.string(),
});

const  ActivationReportInsertionOrderSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const ActivationReportConfigurationSchema = z.object({
  name: z.string(),
  endDate: z.string().refine(date => !isNaN(Date.parse(date)), {
    message: "Invalid date format",
  }),
  reports: z.array(ActivationReportReportSchema),
  startDate: z.string().refine(date => !isNaN(Date.parse(date)), {
    message: "Invalid date format",
  }),
  granularity: z.string(),
  grouping: z.string(),
  insertionOrders: z.array(ActivationReportInsertionOrderSchema),
});

export const ActivationReportEditSchema = z.object({
  id: z.number(),
  configuration: ActivationReportConfigurationSchema,
  accountId: z.number(),
  isActivationReport: z.boolean(),
  createdAt: z.string().refine(date => !isNaN(Date.parse(date)), {
    message: "Invalid date format",
  }),
  updatedAt: z.string().refine(date => !isNaN(Date.parse(date)), {
    message: "Invalid date format",
  }),
  deletedAt: z.string().nullable(),
});

const ActivationReportUserSchema = z.object({
  id: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  userName: z.string(),
  company: z.string(),
  phoneNumber: z.string(),
  userType: z.string(),
  isDeleted: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().nullable(),
});

const ActivationReportResultSchema = z.object({
  createdBy: ActivationReportUserSchema,
  updatedBy: ActivationReportUserSchema,
  id: z.number(),
  configuration: ActivationReportConfigurationSchema,
  accountId: z.number(),
  isActivationReport: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().nullable(),
});

export const ActivationReportMainSchema = z.object({
  count: z.number(),
  totalCount: z.number(),
  currentPage: z.number(),
  results: z.array(ActivationReportResultSchema),
});

export type ActivationReportResult = z.infer<typeof ActivationReportResultSchema>;
export type ActivationReportMain = z.infer<typeof ActivationReportMainSchema>;
export type ActivationReportReport = z.infer<typeof ActivationReportReportSchema>;
