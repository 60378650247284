
import React, { useState, useEffect, useRef } from 'react';
import { Icon, IconButton } from 'rsuite';
import SightlyButton from './Sightly/SightlyButton';

type Props = {
  label: string;
  initialValue: string;
  type: string;
  textSize: string;
  width: string;
  maxLength?: number;
  onUpdate: (newValue: string) => void;
};

const EditableField: React.FC<Props> = ({ label, initialValue, type, textSize, width, maxLength, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isMaxLength, setIsMaxLength] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    onUpdate(value);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setValue(initialValue);
    setIsEditing(false);
  };

  // Auto-focus cursor in input box when entering "edit mode"
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (maxLength && newValue.length >= maxLength) {
      setIsMaxLength(true);
    } else {
      setIsMaxLength(false);
    }
  };

  return (
    <div>
      {isEditing ? (
        <div data-testid="" className={`${width} flex items-center gap-3 transform -translate-y-2`}>
          <h3 className={`${textSize} mr-3`}>{label}</h3>
          <input 
            ref={inputRef}
            className={`${textSize} mr-3 rounded-md border border-gray-200 font-bold flex-grow`}
            type={type} 
            value={value}
            onChange={handleInputChange}
            onKeyDown={e => { 
              if (e.key === 'Enter') handleSave();
              if (e.key === 'Escape') handleCancel();
            }}
            maxLength={maxLength}
          />
          {isMaxLength && <p>You've reached the maximum length</p>}
          <SightlyButton
            data-testid="cancel-button"
            type="secondary"
            text="Cancel"
            id="cancelButton"
            handleClick={handleCancel}
          />
          <SightlyButton
            data-testid="save-button"
            text="Save"
            id="saveButton"
            handleClick={handleSave}
          />
        </div>
      ) : (
        <div className="flex items-center gap-3">
          <h3 className={`${textSize} mr-3`}>{label} {value}</h3>
          <IconButton
            data-testid="edit-button"
            onClick={handleEdit}                
            icon={<Icon icon="edit" />}
            appearance="ghost"
            size="xs"
            id="editButton">
          Edit</IconButton>
        </div>
      )}
    </div>
  );
}

export default EditableField;